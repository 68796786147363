// @flow

// ================= Libs
import styled, { type StyledComponent } from 'styled-components'

// ================= Components
import { colors, schemes, shadows } from 'components/styled/tokens'

import { FeedbackContainer } from './../feedback.styles'
// ================= STYLED COMPONENTS
type OptionContainerProps = { disabled?: boolean, ... }
export const OptionContainer: StyledComponent<OptionContainerProps, *, *> = styled.label`
  display: inline-flex;
  align-items: center;
  width: auto !important;
  margin: 0;
  cursor: ${(p: OptionContainerProps) => (p.disabled ? 'not-allowed' : 'pointer')};

  /* Checkbox Option - label */
  .styled-option-label {
    margin-left: 9px;
    font-size: 14px;
    color: ${colors.textLight};
    letter-spacing: normal;
  }

  /* Checkbox Option - symbol */
  .styled-option-symbol {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    line-height: 30px;
    background-color: ${colors.fill};
    box-shadow:
      0 0 0 1px ${colors.stroke},
      ${shadows.raised};

    &:after {
      display: none;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      content: '';
      background-color: ${colors.textContrast};
    }
  }

  input:checked + .styled-option-symbol {
    background-color: ${colors.fillAction};
    box-shadow: 0 0 0 1px ${colors.fillAction};

    &:after {
      display: block;
    }
  }
  input:checked ~ .styled-option-label {
    color: ${colors.text};
  }

  input:disabled + .styled-option-symbol {
    background-color: ${colors.fillDisabled} !important;
    box-shadow: 0 0 0 1px ${colors.strokeHover} !important;
  }
  input:disabled + .styled-option-symbol:after {
    background-color: ${colors.textDisabled} !important;
  }
  input:disabled ~ .styled-option-label {
    color: ${colors.textDisabled} !important;
  }

  &:hover .styled-option-symbol {
    background-color: ${schemes.grayscale['02']};
    box-shadow:
      0 0 0 1px ${colors.strokeHover},
      ${shadows.raised};
  }
  &:hover input:checked + .styled-option-symbol {
    background-color: ${colors.fillActionHover};
    box-shadow: 0 0 0 1px ${colors.fillActionHover};
  }

  /* Checkbox Option - input */
  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  & + ${FeedbackContainer} {
    margin: 10px 0 0 0;
  }
`
