// @flow
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { platforms } from 'com.batch/profile/constants/platforms'
import { type InstallRecord } from 'com.batch/profile/models/profile.records'
import {
  Debug,
  LastVisitDate,
  PlatformButtonContainer,
  PlatformName,
  PlatformDetails,
  PlatformStatus,
} from 'com.batch/profile/ui/components/profile-hero/platform-button/platform-button.styles'
import { ProfileMockupOptin } from 'com.batch/profile/ui/components/profile-hero/profile-mockup-optin/profile-mockup-optin'

type Props = {
  install: InstallRecord,
  onClick?: () => void,
  href?: string,
  ...
}

export const PlatformButton = ({ install, onClick, href }: Props): React.Node => {
  const deviceName = React.useMemo(
    () => (install?.marketingName ? install?.marketingName : install.type),
    [install]
  )
  const platform = React.useMemo(() => platforms[install.platform], [install.platform])

  const containerProps = href ? { as: 'a', href, target: '_blank' } : { onClick }

  return (
    <PlatformButtonContainer {...containerProps}>
      <PlatformDetails>
        <PlatformName platform={platform.icon}>
          <Icon icon={platform.icon} />
          <span>{deviceName}</span>
        </PlatformName>
        <LastVisitDate $noDate={!(install && install.lastActivity)}>
          {install && dayjs(install.lastActivity).isValid()
            ? `Last visit ${dayjs(install.lastActivity).fromNow()}`
            : 'No last visit'}
        </LastVisitDate>
      </PlatformDetails>
      <PlatformStatus>
        <ProfileMockupOptin
          size="short"
          device={deviceName}
          subscriptionStatus={install.subscriptionStatus}
        />
        <Debug>
          <div>
            {install.platform === 'WEBPUSH' ? 'View in debug' : 'Inspect'}
            <Icon icon="arrow-long-right" />
          </div>
        </Debug>
      </PlatformStatus>
    </PlatformButtonContainer>
  )
}
