// @flow

import * as React from 'react'

import { EmailWhenTiming } from 'components/project/campaign/common/when/when-timing'
import { EmailWhenTrigger } from 'components/project/campaign/common/when/when-trigger'

import { type settingsTabKind } from 'com.batch/orchestration-journey/ui/pages/journey-canvas-form'

type EmailWhenTriggerProps = {
  tab?: settingsTabKind,
}

export const EmailWhen = (props: EmailWhenTriggerProps): React.Node => {
  return (
    <React.Fragment>
      <EmailWhenTrigger />
      <EmailWhenTiming {...props} />
    </React.Fragment>
  )
}
