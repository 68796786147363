// @flow

import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from 'components/campaign/app'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}
