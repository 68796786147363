// @flow

import * as React from 'react'
import Styled from 'styled-components'

import { Box, BoxBody, BoxHeader, HeaderBoxActions, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon, IconContainer, type availableIcons } from 'components/common/svg-icon'

import { generateCampaignUrl } from 'com.batch.common/router'

import { type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'

type RetargetingProps = {
  app: AppRecord,
  campaign: CampaignRecord,
  opened: boolean,
  close: () => void,
}
type RetargetingState = {
  hovered: ?number,
  ...
}

type Option = { name: string, i: availableIcons, value: string, url: [string, boolean], ... }
const Options: Array<Option> = [
  { name: 'Targeted', i: 'target', value: 'were targeted by', url: ['send', true] },
  { name: 'Not targeted', i: 'target-false', value: 'were not targeted by', url: ['send', false] },
  { name: 'Opened', i: 'click', value: 'opened', url: ['opened', true] },
  { name: 'Not opened', i: 'click-false', value: 'did not open', url: ['opened', false] },
]

// ================================================================================================
class Retargeting extends React.PureComponent<RetargetingProps, RetargetingState> {
  constructor(props: RetargetingProps) {
    super(props)
    this.state = { hovered: null }
  }
  render(): React.Node {
    const { close, opened } = this.props
    const { hovered } = this.state
    return (
      <Popin close={close} opened={opened}>
        <Box style={{ width: 640 }} noBorder>
          <BoxHeader>
            <HeaderBoxTitle title="Retarget users based on this campaign" />
            <HeaderBoxActions>
              <Button onClick={close}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody $padding>
            {typeof hovered === 'number' && (
              <RetargetInfos>
                Create a <strong>new campaign</strong> that targets users who{' '}
                <strong>{Options[hovered].value}</strong> this campaign
              </RetargetInfos>
            )}
            {hovered === null && (
              <RetargetInfos>
                You can preview retargeting patterns by hovering the different options.
              </RetargetInfos>
            )}
            <RetargetButtons>
              {Options.map((o, i) => (
                <RetargetButton
                  href={this._getLink(o)}
                  target="_blank"
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseOver={() => this._onMouseOver(i)}
                  onMouseOut={this._onMouseOut}
                  key={i}
                >
                  <Icon icon={o.i} />
                  <label>{o.name}</label>
                </RetargetButton>
              ))}
            </RetargetButtons>
          </BoxBody>
        </Box>
      </Popin>
    )
  }

  _onMouseOver: (index: number) => void = (index): void => this.setState({ hovered: index })
  _onMouseOut: () => void = () => this.setState({ hovered: null })

  _getLink: (option: Option) => string = option => {
    const { app } = this.props
    return generateCampaignUrl('form', this.props.campaign.schedulingType, {
      companyId: app.companyId,
      appId: app.id,
      campaignType: 'push',
      id: 'new',
      ret: this.props.campaign.token,
      w: option.url[0],
      n: option.url[1] ? 0 : 1,
    })
  }
}

const RetargetInfos = Styled.p`
  margin: -6px 0 22px 0;
  font-size: 14px;
  color: #545454;

  strong{
    font-weight: 500;
    color: #454545;
  }
`

const RetargetButton = Styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  border-radius: 3px;
  font-size: 22px;
  color: #7f8691;
  text-align: center;
  background-color: rgba(218, 218, 218, 0.22);
  cursor: pointer;

  &:hover, &:active{
    color: #2c3747;
    background-color: rgba(218, 218, 218, 0.46);
  }

  label{
    margin: 0 8px 12px 8px;
    font-size: 13px;
    font-weight: 500;
  }
  ${IconContainer} {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 6px 0 0 0;
  }
`

const RetargetButtons = Styled.div`
  display: flex;

  ${RetargetButton} {
    flex: 1;
    margin: 0 4px;

    &:first-child{ margin: 0 4px 0 0; }
    &:last-child{ margin: 0 0 0 4px; }
  }
`

export default Retargeting
