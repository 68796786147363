// @flow

import * as React from 'react'

import { Switch } from 'components/common/button'
import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'

import { type FieldRecord } from 'com.batch.redux/theme.records'

type FieldProps = {
  field: FieldRecord,
  toggle: () => void,
  select: () => void,
  tooltip?: string,
}

const Field = ({ field, select, tooltip = '', toggle }: FieldProps): React.Node => {
  const onChange = React.useCallback(() => {
    toggle()
  }, [toggle])

  return (
    <div className="itemline" key={field.id} onClick={select}>
      {field.id !== 'general' && (
        <Switch disabled={!field.removable} isActive={!field.hidden} onChange={onChange} />
      )}
      <div className="itemline__image">{field.icon && <Icon icon={field.icon} size={12} />}</div>
      <div className="itemline__text">
        {field.label}
        {tooltip && <Hint maxTooltipWidth={280}>{tooltip}</Hint>}
      </div>
    </div>
  )
}

export default Field
