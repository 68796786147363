// @flow

import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SelectMulti } from 'components/form'

import { OptionAudience } from './audience-option'

import { allProfileAudiencesSelector } from 'com.batch/audience/store/audience-profile.selector'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'
import { fetchAudiencesList } from 'com.batch/audience/usecases/fetch-audience-list'
import { fetchAudiencesByName } from 'com.batch/audience/usecases/fetch-audiences-by-name'
import { setDeletedAudiences } from 'com.batch/audience/usecases/set-deleted-audience'

type AudiencePickerProps = {
  value: Set<string>,
  onChange: (Set<string>) => void,
  ...
}

const optionToStringDisplayName = (opt: ?ProfileAudienceRecord) => opt?.displayName ?? ''

export const AudiencePicker = ({ onChange, value }: AudiencePickerProps): React.Node => {
  const allProfileAudiences = useSelector(allProfileAudiencesSelector)
  const [loaded, setLoaded] = React.useState(false)
  const [firstRun, setFirstRun] = React.useState(true)
  const dispatch = useDispatch()

  const selectedAudiences = React.useMemo(() => {
    return allProfileAudiences.filter(audience => value.has(audience.name))
  }, [allProfileAudiences, value])

  React.useEffect(() => {
    /*
      we need records to display our select, so we fetch missing audiences
      (records not already in redux)
      if needed, and set a loaded flag when we are good
      firstRun is a hack because recursion, should not be needed.
    */

    const missingAudiences = value.filter(
      audienceName => !selectedAudiences.find(a => a.name === audienceName)
    )

    if (firstRun && missingAudiences.size > 0) {
      dispatch(fetchAudiencesByName({ names: missingAudiences.toArray() }))
    } else {
      if (missingAudiences.size > 0) {
        dispatch(setDeletedAudiences(missingAudiences.toArray()))
      }
      setLoaded(true)
    }

    setFirstRun(false)
  }, [value, dispatch, onChange, firstRun, allProfileAudiences, selectedAudiences])

  const handleChange = React.useCallback(
    audiencesValue => {
      onChange(Immutable.Set(audiencesValue.map(a => a.name)))
    },
    [onChange]
  )

  const loadOptions = React.useCallback(
    async (query: string) => {
      try {
        const resp = await dispatch(
          fetchAudiencesList({
            page: 1,
            sortBy: 'name',
            sortDirection: 'asc',
            search: query ? query : '',
            trashCache: false,
          })
        )
        return resp.entities.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      } catch (err) {
        console.log(err)
        return new Immutable.List()
      }
    },
    [dispatch]
  )

  return loaded ? (
    <SelectMulti
      optionFormatter={OptionAudience}
      style={{ minWidth: 150 }}
      placeholder="Pick one or several audiences"
      loadOptions={loadOptions}
      value={selectedAudiences}
      optionToString={optionToStringDisplayName}
      onChange={handleChange}
    />
  ) : (
    <div />
  )
}
