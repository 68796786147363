// @flow

import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import thunk from 'redux-thunk'

import { RefreshAuthProvider } from 'components/account/refresh-auth-provider'
import { SidebarConnected } from 'components/app/nav/sidebar-connected'
import { Banner } from 'components/common/banner'
import ErrorCatcher from 'components/common/error-catcher'
import { PopinContext } from 'components/common/popin/popin.context'

import { generateUrl } from 'com.batch.common/router'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { ProjectRest } from 'com.batch.redux/adapters/project/project.rest'
import { SenderIdentityRest } from 'com.batch.redux/adapters/sender-identity/sender-identity.rest'
import { UCPRest } from 'com.batch.redux/adapters/ucp/ucp.rest'
import { fetchCustomAudienceEstimateEpic } from 'com.batch.redux/audience.epic'
import { loadCampaignsEpic } from 'com.batch.redux/campaign.epic'
import rootReducer from 'com.batch.redux/index'
import { fetchOursqlEpic, parseQueryEpic, replayQueryEpic } from 'com.batch.redux/query/query.epic'
import { SentryMiddleware } from 'com.batch.redux/sentry.middleware'
import { fetchCampaignsStatsEpic } from 'com.batch.redux/stat.epic'
import {
  estimateFetchEpic,
  estimateHashEpic,
  handleChannelChangeEpic,
} from 'com.batch.redux/target/target.epic'
import {
  buildQueryEpic,
  estimateEpic,
  loadRealAudiencesEpic,
  parseQueryEpic as legacyParseQyeryEpic,
  validateConditionEpic,
} from 'com.batch.redux/targeting.epic'
import { templateEpic } from 'com.batch.redux/template.epic'

import { audienceServiceApi } from 'com.batch/audience/infra/audience-service.api'
import { orchestrationService } from 'com.batch/orchestration/infra/orchestration-service'
import { DebugRest } from 'com.batch/profile/infra/debug/debug.rest'
import { ucpDataService } from 'com.batch/profilebase/infra/ucp-data-service.api'
import { settingsServiceApi } from 'com.batch/settings/infra/settings-service.api'
import { FcmMigrationBanner } from 'com.batch/settings/ui/components/settings-fcm/fcm-migration-banner/fcm-migration-banner'
import { dataService } from 'com.batch/shared/infra/data-service.api'
import { metadataService } from 'com.batch/shared/infra/metadata-service/metadata-service.api'
import { ourSqlService } from 'com.batch/shared/infra/oursql.service'
import Notifier from 'containers/notifier-container'

require('com.batch.common/setup-metrics')

type AppWrapperProps = {
  children: React.Node,
  sidebarKind: 'none' | 'account' | 'dashboard',
  epicMode: 'none' | 'audience' | 'campaign',
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const epicMiddleware = createEpicMiddleware()
const middlewares = [
  thunk.withExtraArgument({
    projectGateway: ProjectRest,
    dataService,
    audienceServiceApi,
    ucpGateway: UCPRest,
    ucpDataService,
    debugGateway: DebugRest,
    senderIdentityGateway: SenderIdentityRest,
    settingsServiceApi,
    orchestrationService,
    metadataService,
    ourSqlService,
  }),
  epicMiddleware,
  SentryMiddleware,
]
// data from twig
const isImpersonating = Boolean(document.body?.classList?.contains('banner-impersonate'))
const GdprShowWarning = typeof window._gdpr_hours_left === 'number'
const GdprHoursLeft = typeof window._gdpr_hours_left === 'number' ? window._gdpr_hours_left : 0
const GdprHoursWord = `${GdprHoursLeft} hour${GdprHoursLeft > 0 ? 's' : ''}`

const GdprUserIsAllowedToEdit = Boolean(window._gdpr_allowed)

// data from store initial state (so from twig)
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
const company: CompanyRecord = store.getState().company

export const AppWrapper = ({ children, sidebarKind, epicMode }: AppWrapperProps): React.Node => {
  // --- local state
  const [epicHasStarted, setEpicHasStarted] = React.useState(epicMode === '')
  const [popinCount, setPopinCount] = React.useState(0) // hack to dismiss only the topmost popin when esc is pressed

  const rootEpic = React.useMemo(
    () =>
      combineEpics(
        ...(epicMode === 'audience'
          ? [
              fetchCustomAudienceEstimateEpic,
              estimateHashEpic,
              estimateFetchEpic,
              replayQueryEpic,
              fetchOursqlEpic,
              parseQueryEpic,
            ]
          : epicMode === 'campaign'
            ? [
                loadCampaignsEpic,
                estimateEpic,
                templateEpic,
                buildQueryEpic,
                handleChannelChangeEpic,
                validateConditionEpic,
                loadRealAudiencesEpic,
                estimateHashEpic,
                estimateFetchEpic,
                legacyParseQyeryEpic,
                fetchCampaignsStatsEpic,
                fetchOursqlEpic,
                replayQueryEpic,
                parseQueryEpic,
              ]
            : [])
      ),
    [epicMode]
  )
  React.useEffect(() => {
    epicMiddleware.run(rootEpic)
    setEpicHasStarted(true)
  }, [rootEpic])

  if (!epicHasStarted) {
    return null
  }
  return (
    <ErrorCatcher>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            {sidebarKind !== 'none' && (
              <PopinContext.Provider value={{ count: popinCount, updateCount: setPopinCount }}>
                <SidebarConnected kind={sidebarKind} />
                <RefreshAuthProvider />
                {isImpersonating && (
                  <Banner
                    intent="blocked"
                    title="This is not your company,  all actions will be blocked."
                    link={{ kind: 'basic', name: 'Back to my company', href: '/' }}
                    hasCloseButton
                  />
                )}
                <FcmMigrationBanner />
                {GdprShowWarning && (
                  <Banner
                    intent={GdprHoursLeft > 0 ? 'danger' : 'blocked'}
                    link={
                      GdprUserIsAllowedToEdit
                        ? {
                            kind: 'basic',
                            name: 'GDPR settings',
                            href: generateUrl('company_gdpr', { companyId: company.id }),
                          }
                        : undefined
                    }
                    content={
                      GdprUserIsAllowedToEdit
                        ? `Please fill-in your GDPR form to continue using Batch.
                    ${GdprHoursLeft > 0 ? `It will become mandatory in ${GdprHoursWord}` : ''}`
                        : GdprHoursLeft > 0
                          ? `An action is required by a manager of your company within ${GdprHoursWord} to continue using Batch.`
                          : 'An action is required on this account to continue using Batch. Please contact the person responsible in your company.'
                    }
                  />
                )}
              </PopinContext.Provider>
            )}
            <Notifier />
            {children}
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </ErrorCatcher>
  )
}
