// @flow

import * as React from 'react'

import { Grid } from 'components/common/grid'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { BlurValidation } from 'components/form/blur-validation'

import { requiredString } from 'com.batch.common/validators'

import { type InputProps } from './helper'

const allowedUnits = ['d', 'h']

export const InputAge = ({ condition, updateCondition, isInvalid }: InputProps): React.Node => {
  const showAgo = React.useMemo(
    () =>
      condition.attribute?.type === 'DATE' &&
      condition.functions.filter(f => f.value === 'age' || f.value === 'lastBirthday').size === 1,
    [condition.attribute?.type, condition.functions]
  )
  const onAgeChange = React.useCallback(
    age => updateCondition(condition.set('value', condition.value.set('age', age))),
    [condition, updateCondition]
  )
  return (
    <Grid template={showAgo ? '160px 20px' : 'auto'}>
      <BlurValidation validator={requiredString} value={condition.value.age.inputValue}>
        {({ onBlur, invalid }) => (
          <TimeIntervalInput
            invalid={isInvalid || invalid}
            onBlur={onBlur}
            min={-Infinity}
            age={condition.value.age}
            allowedUnits={allowedUnits}
            onChange={onAgeChange}
          />
        )}
      </BlurValidation>
      {showAgo && <span>ago</span>}
    </Grid>
  )
}
