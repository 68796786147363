// @flow
import Immutable from 'immutable'

import {
  orchestrationCampaignSelector,
  orchestrationLabelSelector,
  orchestrationMetaSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { getAPIQueryForIdSelector } from 'com.batch.redux/query/query.selector'

import { formatOrchestration } from 'com.batch/orchestration/infra/formats/orchestration.format'
import {
  journeySettingsSelector,
  journeyTreeSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'

export type SaveOrchestrationAction = ReduxAction<'SAVE_ORCHESTRATION', {}>
export type SaveOrchestrationSuccessAction = ReduxAction<
  'SAVE_ORCHESTRATION_SUCCESS',
  { token: string },
>
export type SaveOrchestrationFailureAction = ReduxAction<'SAVE_ORCHESTRATION_FAILURE', string>

export const persistOrchestration =
  ({
    forceRunning,
    incomplete,
  }: {
    forceRunning: boolean,
    incomplete: boolean,
  }): DispatchExtraBoundFn<Promise<string>> =>
  async (dispatch, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    const settings = journeySettingsSelector(state)
    const meta = orchestrationMetaSelector(state)
    const selectedOrchestrationLabels = orchestrationLabelSelector(state)
    const orchestrationCampaign = orchestrationCampaignSelector(state)
    const getQuery = getAPIQueryForIdSelector(state)
    let queries: Array<[string, string]> = []
    state.query.forEach((_, queryId) => {
      const query = getQuery(queryId)
      if (query) queries.push([queryId, JSON.stringify(query, null, 2)])
    })
    const { rootNodeId, nodesMap } = journeyTreeSelector(state)
    const orchestration = formatOrchestration({
      project,
      settings,
      rootNodeId,
      messageIdsToReplace:
        meta.state === 'RUNNING' || meta.state === 'STOPPED'
          ? state.message.updatedMessageIds
          : Immutable.Set(),
      incomplete,
      campaign: orchestrationCampaign,
      nodes: nodesMap,
      targets: state.target,
      name: meta.name,
      id: meta.id,
      state:
        forceRunning || meta.state === 'RUNNING'
          ? 'RUNNING'
          : meta.state === 'STOPPED'
            ? 'STOPPED'
            : 'DRAFT',
      message: state.message,
      queries: Immutable.Map(queries),
      labelCodes: Immutable.Set(selectedOrchestrationLabels.map(label => label.code)),
    })
    const method = orchestration.id ? orchestrationService.update : orchestrationService.create
    return promiseActionCreator({
      dispatch,
      actionName: 'SAVE_ORCHESTRATION',
      promise: method(orchestration),
    })
  }
