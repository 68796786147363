// @flow

import { type availableIcons } from 'components/common/svg-icon'

import { type NodeType } from 'com.batch/orchestration-journey/models/journey.records'

export const NODE_TYPES: Array<{
  nodeType: NodeType,
  channel: ?ChannelUntilCleanup,
  icon: availableIcons,
  label: string,
}> = [
  {
    nodeType: 'MESSAGE',
    channel: 'email',
    icon: 'mail',
    label: 'Email',
  },
  {
    nodeType: 'MESSAGE',
    channel: 'push',
    icon: 'push',
    label: 'Push',
  },
  {
    nodeType: 'MESSAGE',
    channel: 'sms',
    icon: 'sms',
    label: 'SMS',
  },
  {
    nodeType: 'TIMER',
    channel: undefined,
    icon: 'wait',
    label: 'Delay',
  },
  {
    nodeType: 'YESNO',
    channel: undefined,
    icon: 'yesno',
    label: 'Yes/No split',
  },
  {
    nodeType: 'RANDOM',
    channel: undefined,
    icon: 'split',
    label: 'Random split',
  },
]
