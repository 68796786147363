// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import { AppMenu } from 'components/app/app-menu'
import { AudienceList } from 'components/app/audience/audience-list'
import { CustomData } from 'components/app/custom-data/custom-data'
import { Debug } from 'components/app/debug/debug'
import { GlobalSettings } from 'components/app/global'
import { PushSettings } from 'components/app/push/push-settings'
import { Banner } from 'components/common/banner'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { ThemeEditor } from 'components/theme/theme-editor'
import { ThemeList } from 'components/theme/theme-list'

import { AppTeam } from './app-team'
import { Labels } from './label/label'
import { PressureContainer } from './pressure/pressure-container'
import { GDPRApp } from './privacy/gdpr-app'

import { currentAppSelector } from 'com.batch.redux/app'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const AppSettingsWrapper = ({ setting }: { setting: string, ... }): React.Node => {
  const app = useSelector(currentAppSelector)
  const user = useSelector(currentUserSelector)
  const { themeId } = useParams()
  const [searchParams] = useSearchParams()
  const page = React.useMemo(() => {
    switch (setting) {
      case 'privacy':
      case 'privacy/new':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.gdpr}>
            <GDPRApp app={app} newPopinOpened={setting === 'privacy/new'} />
          </PageTracker>
        )
      case 'labels':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.label}>
            <Labels app={app} />
          </PageTracker>
        )
      case 'team':
      case 'team/edit':
        return (
          <PageTracker
            category={trackingCategories.appSettings}
            name={setting === 'team/edit' ? trackingPages.teamEdit : trackingPages.team}
          >
            <AppTeam app={app} edit={setting === 'team/edit'} />
          </PageTracker>
        )
      case 'cappings':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.capping}>
            <PressureContainer
              categories={app.cappingCategories}
              app={app}
              hasCappingGlobal={
                (app.platform !== 'webpush' && !!app.features.has('capping_global')) ||
                (app.platform === 'webpush' && !!app.features.has('webpush_capping_global'))
              }
              hasCappingLabels={
                (app.platform !== 'webpush' && !!app.features.has('capping_labels')) ||
                (app.platform === 'webpush' && !!app.features.has('webpush_capping_labels'))
              }
              hasInAppCapping={!!app.features.has('inapp-capping') && app.platform !== 'webpush'}
            />
          </PageTracker>
        )
      case 'custom-data':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.customData}>
            <CustomData app={app} />
          </PageTracker>
        )
      case 'audiences':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.audience}>
            <AudienceList app={app} />
          </PageTracker>
        )
      case 'push':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.push}>
            <PushSettings
              advanced
              readonly={!user.permissionsForCurrentApp.has('push:config:write')}
            />
          </PageTracker>
        )
      case 'themes':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.themes}>
            <ThemeList />
          </PageTracker>
        )
      case 'themes/new':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.themeNew}>
            <ThemeEditor id="new" />
          </PageTracker>
        )
      case 'themes/:themeId':
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.themeEdit}>
            <ThemeEditor id={parseInt(themeId)} />
          </PageTracker>
        )
      case 'debug': {
        let mode = app.platform === 'webpush' ? 'custom_id' : 'advertising_id'
        let query = ''
        if (searchParams.has('custom_id')) {
          mode = 'custom_id'
          query = decodeURIComponent(searchParams.get('custom_id') ?? '')
        }
        if (searchParams.has('installation_id')) {
          mode = 'installation_id'
          query = decodeURIComponent(searchParams.get('installation_id') ?? '')
        }
        if (searchParams.has('advertising_id')) {
          mode = 'advertising_id'
          query = decodeURIComponent(searchParams.get('advertising_id') ?? '')
        }
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.debugUser}>
            <Debug app={app} tab="user" query={query} mode={mode} />
          </PageTracker>
        )
      }
      case 'debug/transactional': {
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.debugTransac}>
            <Debug
              app={app}
              tab="transac"
              mode="advertising_id"
              query={decodeURIComponent(searchParams.get('response_token') ?? '')}
            />
          </PageTracker>
        )
      }
      default:
        return (
          <PageTracker category={trackingCategories.appSettings} name={trackingPages.general}>
            <GlobalSettings app={app} />
          </PageTracker>
        )
    }
  }, [setting, app, searchParams, themeId, user])

  return (
    <React.Fragment>
      {!user.permissionsForCurrentCompany.has('group:app') && (
        <Banner
          intent="limited"
          icon="view"
          title="You are on read-only mode, some features may be unavailable."
        />
      )}
      <AppMenu settingsMenuApp={app} user={user} channel={{ appId: app.id }}>
        {page}
      </AppMenu>
    </React.Fragment>
  )
}
