// @flow
import styled, { type StyledComponent } from 'styled-components'

export const TimelineContent: StyledComponent<{ ... }, *, *> = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 12px;
    background: rgb(225, 225, 225);
    width: 1.5px;
    top: 0;
    right: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    width: 1.5px;
    top: 40px;
    bottom: 0;
    right: 50%;
  }

  svg,
  div {
    margin: 17px 0;
  }
`
