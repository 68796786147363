// @flow

import { animated, useTransition } from '@react-spring/web'
import * as React from 'react'
import styled from 'styled-components'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

const Pill = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: grid;
  margin: 0 auto;
  position: relative;
  padding: 0 10px;
  width: 400px;
  width: fit-content;
  height: 44px;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-radius: 12px;
  color: ${colors.textContrast};
  border: 1px solid ${colors.stroke};
  background: ${colors.fillContrast};
  /* overlay */
  box-shadow:
    0px 1px 12px 0px rgba(15, 15, 15, 0.08),
    0px 1px 4px 0px rgba(15, 15, 15, 0.06);
  p {
    padding: 4px 4px 4px 8px;
  }
`
type CancelPastePillProps = {
  onCancel: () => void,
  shown: boolean,
}
export const CancelPastePill = ({ onCancel, shown }: CancelPastePillProps): React.Node => {
  const transformProps = React.useMemo(() => {
    const initial = {
      opacity: 0,
      bottom: shown ? -90 : 20,
    }
    return {
      initial,
      config: {
        tension: 220,
        friction: 16,
      },
      from: { ...initial },
      enter: {
        opacity: 1,
        bottom: 20,
      },
      leave: { ...initial },
    }
  }, [shown])

  const transitions = useTransition(shown, transformProps)
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') onCancel()
    },
    [onCancel]
  )
  React.useEffect(() => {
    if (shown) document.addEventListener('keydown', handleKeyDown)
    else document.removeEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, shown])

  return transitions((style, item) =>
    item ? (
      <animated.div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 20,
          ...style,
        }}
      >
        <Pill>
          <p>
            <Icon icon="click" style={{ marginRight: 8 }} />
            Select a destination for your duplication
          </p>
          <Button kind="discreetDark" onClick={onCancel}>
            Cancel
          </Button>
        </Pill>
      </animated.div>
    ) : null
  )
}
