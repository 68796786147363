// @flow
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Separator } from 'components/styled/utils'

import { kformat } from 'com.batch.common/utils'

import { ChannelMetricsBox } from './channels-metrics.styles'

import { ChannelMetric } from 'com.batch/profilebase/ui/components/channels-metrics/channel-metric'

type Props = {
  email: number,
  sms: number,
  push: number,
}
export const ChannelsMetrics = ({ email, sms, push }: Props): React.Node => (
  <ChannelMetricsBox>
    <Grid gap={0} template="1fr 1px 1fr 1px 1fr" alignItems="stretch">
      <ChannelMetric channel="push" metric={kformat(push)} label="profiles with a mobile device" />
      <Separator fullHeight />
      <ChannelMetric channel="email" metric={kformat(email)} label="profiles with email address" />
      <Separator fullHeight />
      <ChannelMetric channel="sms" metric={kformat(sms)} label="profiles with phone number" />
    </Grid>
  </ChannelMetricsBox>
)
