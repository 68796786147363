// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Title } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

export const ChannelSectionTitle: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 28px;
  width: 100%;

  & > div:first-of-type {
    display: flex;
    align-items: center;

    & > button {
      margin-left: 16px;
    }
  }

  & ${Title} {
    display: flex;
    align-items: center;
    margin: 0;

    color: ${colors.textNeutral};

    i {
      font-size: 22px;
      margin-right: 8px;
      margin-top: -2px;
    }
  }
`
