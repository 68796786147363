// @flow
import Immutable, { type List, type RecordFactory, type RecordOf } from 'immutable'

export type BounceClassificationProps = { name: string, count: number }
export type BounceClassificationRecord = RecordOf<BounceClassificationProps>

export const BounceClassificationFactory: RecordFactory<BounceClassificationProps> =
  Immutable.Record({
    name: '',
    count: 0,
  })

export type BounceCategoryName = 'HARD' | 'SOFT' | 'BLOCK' | 'UNDETERMINED'
export type BounceCategoryProps = {
  name: BounceCategoryName,
  count: number,
  rate: number,
  classifications: List<BounceClassificationRecord>,
}

export type BounceCategoryRecord = RecordOf<BounceCategoryProps>
export const BounceCategoryFactory: RecordFactory<BounceCategoryProps> = Immutable.Record(
  ({
    name: 'UNDETERMINED',
    count: 0,
    rate: 0,
    classifications: new Immutable.List(),
  }: BounceCategoryProps)
)

export type BounceProviderCategoryDataProps = {
  count: number,
}
export type BounceProviderCategoryDataRecord = RecordOf<BounceProviderCategoryDataProps>
export const BounceProviderCategoryDataFactory: RecordFactory<BounceProviderCategoryDataProps> =
  Immutable.Record({ count: 0 })

export type BounceProviderCategoryProps = {
  [BounceCategoryName]: BounceProviderCategoryDataRecord,
}
export type BounceProviderCategoryRecord = RecordOf<BounceProviderCategoryProps>
export const BounceProviderCategoryFactory: RecordFactory<BounceProviderCategoryProps> =
  Immutable.Record({
    SOFT: BounceProviderCategoryDataFactory(),
    HARD: BounceProviderCategoryDataFactory(),
    BLOCK: BounceProviderCategoryDataFactory(),
  })

export type BounceProviderProps = {
  name: string,
  sent: number,
  count: number,
  rate: number,
  categories: BounceProviderCategoryRecord,
}

export type BounceProviderRecord = RecordOf<BounceProviderProps>
export const BounceProviderFactory: RecordFactory<BounceProviderProps> = Immutable.Record(
  ({
    name: '',
    sent: 0,
    count: 0,
    rate: 0,
    categories: BounceProviderCategoryFactory(),
  }: BounceProviderProps)
)

type DataProps = {
  value: number,
  rate: number,
  unique: ?number,
  categories: ?List<BounceCategoryRecord>,
  providers: ?List<BounceProviderRecord>,
}
type DataRecord = RecordOf<DataProps>
const DataFactory: RecordFactory<DataProps> = Immutable.Record(
  ({
    value: 0,
    rate: 0,
    unique: null,
    categories: null,
    providers: null,
  }: DataProps)
)

export type StatsProps = {
  bounce: DataRecord,
  click: DataRecord,
  mpp: DataRecord,
  delivered: DataRecord,
  open: DataRecord,
  unsubscribe: DataRecord,
  skippedSuppressionList: DataRecord,
  sending: DataRecord,
  injection: number,
  spam: number,
  sent: number,
}
export const StatsFactory: RecordFactory<StatsProps> = Immutable.Record({
  bounce: DataFactory({
    value: 0,
    rate: 0,
    categories: new Immutable.List(),
    providers: new Immutable.List(),
  }),
  click: DataFactory(),
  mpp: DataFactory(),
  delivered: DataFactory(),
  open: DataFactory(),
  unsubscribe: DataFactory(),
  skippedSuppressionList: DataFactory(),
  sending: DataFactory(),
  injection: 0,
  spam: 0,
  sent: 0,
})
export type StatsRecord = RecordOf<StatsProps>

export type OrchestrationStatsProps = {
  base: StatsRecord,
  email: ?StatsRecord,
  sms: ?StatsRecord,
  push: ?StatsRecord,
}
export const OrchestrationStatsFactory: RecordFactory<OrchestrationStatsProps> = Immutable.Record({
  base: StatsFactory(),
  email: null,
  sms: null,
  push: null,
})
export type OrchestrationStatsRecord = RecordOf<OrchestrationStatsProps>
