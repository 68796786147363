// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  inlineEditorConfigSelector,
  isFieldActiveSelector,
} from 'com.batch/message-builder/store/inline-editor.selectors'
import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'
import { getSmsContentForActiveLanguageSelector } from 'com.batch/sms/store/sms.selector'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'
import { TemplateCacheSelector } from 'com.batch.redux/template'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { InlineEditor } from 'com.batch/message-builder/ui/components/inline-editor'
import {
  blurInlineEditorAction,
  setInlineEditor,
} from 'com.batch/message-builder/usecases/manage-inline-editor'
import { updatePushMessageContent } from 'com.batch/push/usecases/update-push-content'
import { updateSmsContent } from 'com.batch/sms/usecases/update-sms-content'

type EditableFieldProps = {
  messageId: string,
  field: 'smsMessage' | 'pushTitle' | 'pushBody',
}

export const EditableField = ({ messageId, field }: EditableFieldProps): React.Node => {
  const dispatch = useDispatch()
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
  const lang = useSelector(activeLanguageValueSelector)
  const templateCache = useSelector(TemplateCacheSelector)
  const isActive = useSelector(isFieldActiveSelector(field))
  const getSmsContent = useSelector(getSmsContentForActiveLanguageSelector)
  const getPushContent = useSelector(getPushContentForActiveLanguageSelector)

  const onBlur = React.useCallback(
    selectionTuple => {
      dispatch(blurInlineEditorAction({ field, variant: 'a', selection: selectionTuple }))
    },
    [dispatch, field]
  )

  const setEditing = React.useCallback(
    (editing: MessageBuilderEditableField, caret: number) => {
      let newConfig = inlineEditorConfig
        .set('field', editing)
        .set('variant', 'a')
        .set('selection', [caret, caret])

      dispatch(setInlineEditor(newConfig))
    },
    [inlineEditorConfig, dispatch]
  )

  const updateContent = React.useCallback(
    (value: string) => {
      switch (field) {
        // SMS Fields
        case 'smsMessage':
          dispatch(
            updateSmsContent({
              messageId,
              lang,
              field,
              value,
              isInstant: false,
            })
          )
          break
        // Push fields
        case 'pushTitle':
        case 'pushBody':
          dispatch(
            updatePushMessageContent({
              messageId,
              lang,
              parent: 'content',
              field,
              value,
            })
          )
          break
        default:
          return
      }
    },
    [field, lang, dispatch, messageId]
  )

  const pushContent = React.useMemo(() => getPushContent(messageId), [getPushContent, messageId])
  const smsContent = React.useMemo(() => getSmsContent(messageId), [getSmsContent, messageId])

  const onFocus = React.useCallback(() => {
    setEditing(field, 0)
  }, [field, setEditing])

  const onActivateAtPos = React.useCallback(
    (pos: number) => {
      setEditing(
        field,
        (field === 'smsMessage' ? smsContent.get(field) : pushContent.content.get(field)) ? pos : 0
      )
    },
    [setEditing, field, smsContent, pushContent.content]
  )

  const onChange = React.useCallback(
    (computedText: string) => {
      updateContent(computedText)
    },
    [updateContent]
  )

  const fieldValue = React.useMemo(() => {
    switch (field) {
      case 'smsMessage':
        return (
          (isActive ? smsContent.get(field).trim() : smsContent.getIn(['templates', field])) ?? ''
        )
      case 'pushTitle':
      case 'pushBody':
        return (
          (isActive
            ? pushContent.content.get(field).trim()
            : pushContent.content.getIn(['templates', field])) ?? ''
        )
      default:
        return ''
    }
  }, [field, isActive, smsContent, pushContent.content])

  const placeholder = React.useMemo(
    () => (field === 'pushTitle' ? 'Click to add title' : 'Click to add text'),
    [field]
  )

  return (
    <InlineEditor
      messageId={messageId}
      selection={inlineEditorConfig.selection}
      onBlur={onBlur}
      canUseTemplating
      field={field}
      onFocus={onFocus}
      variant="a"
      isActive={isActive}
      onActivateAtPos={onActivateAtPos}
      onChange={onChange}
      placeholder={placeholder}
      value={fieldValue}
      templateCache={templateCache}
    />
  )
}
