// @flow

import { type Dayjs } from 'dayjs'
import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import { Button, useBlurOnClickCallback } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'

import { dayjs, parseDate, formatDate } from 'com.batch.common/dayjs.custom'

import { Icon } from './svg-icon'

type DateTimePickerProps = {
  value?: ?Dayjs,
  update: (date: Dayjs | null) => any,
  disabledDays?: (day: Date) => boolean,
  id?: string,
  ...
}

const DAY_FORMAT = 'DD/MM/YYYY'

const NavBarElement = ({
  onPreviousClick,
  onNextClick,
}: {
  onPreviousClick: () => any,
  onNextClick: () => any,
  ...
}) => {
  const onPreviousClickBlur = useBlurOnClickCallback(() => {
    onPreviousClick()
  }, [onPreviousClick])
  const onNextClickBlur = useBlurOnClickCallback(() => {
    onNextClick()
  }, [onNextClick])

  return (
    <div className="DayPicker-NavBar">
      <Button
        onClick={onPreviousClickBlur}
        style={{ height: 28, position: 'absolute', left: 16, top: 0 }}
      >
        <Icon icon="chevron-left" />
      </Button>
      <Button
        onClick={onNextClickBlur}
        style={{ height: 28, position: 'absolute', right: 16, top: 0 }}
      >
        <Icon icon="chevron-right" />
      </Button>
    </div>
  )
}
export const checkAndBroadcastRaw = (
  day: Dayjs,
  hours: number,
  minutes: number,
  update: Dayjs => void
) => {
  hours = parseInt(hours)
  minutes = parseInt(minutes)
  if (hours === 24) hours = 0
  if (
    dayjs.isDayjs(day) &&
    !isNaN(hours) &&
    !isNaN(minutes) &&
    hours >= 0 &&
    hours <= 23 &&
    minutes >= 0 &&
    minutes <= 59
  ) {
    const md = dayjs.utc(new Date(Date.UTC(day.year(), day.month(), day.date(), hours, minutes)))
    update(md)
  }
}

const DateTimePicker: React.AbstractComponent<DateTimePickerProps, HTMLElement> = React.forwardRef(
  ({ value, update, disabledDays, id }: DateTimePickerProps, ref) => {
    // ====================== CALLBACKS
    const checkAndBroadcast = React.useCallback(
      (day: Dayjs, hours: number, minutes: number) =>
        checkAndBroadcastRaw(day, hours, minutes, update),
      [update]
    )

    const onDayChange = React.useCallback(
      (selectedDay: Date) => {
        if (typeof selectedDay === 'undefined') {
          update(null)
        } else {
          const hours = value ? value.hour() : dayjs().hour() + 1
          const minutes = value ? value.minute() : 15
          checkAndBroadcast(dayjs.utc(selectedDay), hours, minutes)
        }
      },
      [checkAndBroadcast, update, value]
    )

    const onHoursChange = React.useCallback(
      (event: SyntheticInputEvent<any>) => {
        const hours = Number(event.target.value)
        if (value && hours >= 0 && hours < 24) {
          checkAndBroadcast(value, hours, value.minute())
        }
      },
      [checkAndBroadcast, value]
    )

    const onMinutesChange = React.useCallback(
      (event: SyntheticInputEvent<any>) => {
        const minutes = Number(event.target.value)
        if (value && minutes >= 0 && minutes < 60) {
          checkAndBroadcast(value, value.hour(), minutes)
        }
      },
      [checkAndBroadcast, value]
    )

    const dayPickerProps = {
      firstDayOfWeek: 1,
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      enableOutsideDays: true,
      disabledDays: disabledDays,
      navbarElement: NavBarElement,
      modifiers: {
        monday: { daysOfWeek: [1] },
      },
    }

    const selectedDay = value ? value : null
    const hours = value ? value.hour() : ''
    const minutes = value ? value.minute() : ''

    const formattedDay = selectedDay ? selectedDay.format(DAY_FORMAT) : ''

    return (
      <FlexLine style={{ minHeight: '32px' }}>
        <FlexLineItem grow={1} style={{ minWidth: '140px', flexShrink: 0, flexBasis: '140px' }}>
          <DayPickerInput
            value={formattedDay}
            inputProps={{
              ref,
              className: 'form-control',
              'data-testid': `input-date-picker-date-${id ?? 'unknown'}`,
              placeholder: `E.g. ${dayjs().format(DAY_FORMAT)}`,
            }}
            onDayChange={onDayChange}
            format={DAY_FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={dayPickerProps}
          />
        </FlexLineItem>
        <FlexLineItem width={60}>
          <input
            type="number"
            data-testid={`input-date-picker-hour-${id ?? 'unknown'}`}
            className="form-control"
            onChange={onHoursChange}
            placeholder="hh"
            min="0"
            max="23"
            value={hours}
            disabled={!value}
          />
        </FlexLineItem>
        <FlexLineItem>:</FlexLineItem>
        <FlexLineItem width={60}>
          <input
            type="number"
            data-testid={`input-date-picker-min-${id ?? 'unknown'}`}
            placeholder="mm"
            onChange={onMinutesChange}
            className="form-control"
            min="0"
            max="59"
            value={minutes}
            disabled={!value}
          />
        </FlexLineItem>
      </FlexLine>
    )
  }
)

export default DateTimePicker
