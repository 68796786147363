// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Button, type ButtonProps } from 'components/common/button/button.styles'
import { schemes, colors } from 'components/styled/tokens'

type TimerButtonProps = {
  ...ButtonProps,
  $hasError: boolean,
  $isEditing: boolean,
  ...
}
export const TimerButtonBase: StyledComponent<TimerButtonProps, {}, *> = styled(Button)`
  background-color: ${schemes.purple['00']};
  color: ${schemes.purple['50']};
  display: inline-flex;
  font-weight: 500;
  align-items: flex-start;
  height: auto;
  ${(p: TimerButtonProps) => {
    if (p.$hasError)
      return `
      border: 2px solid ${colors.strokeDanger};
      color: ${colors.textDanger};
      margin: -2px;`

    if (p.$isEditing)
      return `
      border: 2px solid ${schemes.purple['60']};
      margin: -2px;
      `

    return ''
  }}
  &:hover {
    color: ${schemes.purple['60']} !important;
    background-color: ${schemes.purple['10']};
  }
  &:focus {
    color: ${schemes.purple['70']} !important;
    background-color: ${schemes.purple['20']} !important;
  }

  i {
    padding-top: 3px;
  }
  span {
    max-width: 160px;
    min-width: 30px;
    width: auto;
    flex: 1 1 60px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
