// @flow

import { useSelect } from 'downshift'
import Immutable from 'immutable'
import * as React from 'react'

import { useWidthObserver, usePopper } from 'components/_hooks'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Separator } from 'components/styled/utils'

import { type BaseSelectProps } from './select'
import { SelectDropdown } from './select-dropdown'
import {
  selectPopperConfig,
  type SelectMenuPropsType,
  useSelectValueOverflow,
} from './select.helper'
import {
  SelectContainer,
  SelectField,
  SelectValueContainer,
  SelectClear,
  SelectIndicator,
  SelectPlaceholder,
} from './select.styles'

export function SelectNoSearch<T>({
  placeholder,
  isClearable,
  options,
  optionToString,
  optionMenuShownCount,
  menuOffset = 20,
  optionMenuStyle,
  optionMenuHeight,
  isDisabled,
  onFocus,
  onBlur,
  value,
  optionFormatter,
  onChange,
  tooltip,
  ...rest
}: BaseSelectProps<T>): React.Node {
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)

  const items = React.useMemo(() => (options ? options.toArray() : []), [options])

  const {
    getToggleButtonProps,
    getMenuProps,
    isOpen,
    closeMenu,
    highlightedIndex,
    setHighlightedIndex,
    openMenu,
    getItemProps,
  } = useSelect({
    items,
    itemToString: optionToString,
    circularNavigation: false,
    selectedItem: value ?? null,
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        popperInstance?.update()
      }
      if (onBlur && !isOpen) {
        onBlur()
      }
      if (onFocus && isOpen) {
        onFocus()
      }
    },
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem)
    },
  })
  const showClearButton = isClearable && !!value
  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })
  const buttonProps = getToggleButtonProps({
    isDisabled: Boolean(isDisabled),
  })
  const isOptionSelected = React.useCallback((option: T) => option === value, [value])
  const width = useWidthObserver(triggerRef, 200)

  const openMneuIfNotAlreadyOpened = React.useCallback(() => {
    if (!isOpen) openMenu()
  }, [isOpen, openMenu])
  const onClear = React.useCallback(
    evt => {
      evt?.stopPropagation()
      onChange(null)
      closeMenu()
    },
    [closeMenu, onChange]
  )

  const { valueRef, isValueOverflowing } = useSelectValueOverflow<T>(value)

  return (
    <SelectContainer
      {...rest}
      ref={triggerRef}
      isFocused={isOpen}
      isDisabled={Boolean(isDisabled)}
      onClick={openMneuIfNotAlreadyOpened}
    >
      <Tooltip
        tooltip={tooltip}
        placement="bottom"
        maxWidth={width + menuOffset}
        minWidth={width + menuOffset}
        isTooltipEmpty={!tooltip || !isValueOverflowing || isOpen}
        arrow={false}
        noPadding={false}
        toggle={false}
      >
        <SelectField
          isDisabled={Boolean(isDisabled)}
          isFocused={isOpen}
          template={`minmax(10px, 1fr) ${showClearButton ? '33px 1px' : ''} 30px`}
          gap={0}
        >
          <SelectValueContainer ref={valueRef}>
            {value ? (
              optionFormatter ? (
                optionFormatter(value, { context: 'value' })
              ) : (
                optionToString(value)
              )
            ) : (
              <SelectPlaceholder isDisabled={Boolean(isDisabled)}>{placeholder}</SelectPlaceholder>
            )}
          </SelectValueContainer>
          {showClearButton && (
            <React.Fragment>
              <SelectClear onClick={onClear}>
                <Icon icon="close" />
              </SelectClear>
              <Separator _margin="0" />
            </React.Fragment>
          )}
          <SelectIndicator {...buttonProps}>
            <Icon icon="select" />
          </SelectIndicator>
        </SelectField>
      </Tooltip>
      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        getItemProps={getItemProps}
        optionMenuStyle={optionMenuStyle}
        optionMenuHeight={optionMenuHeight}
        optionMenuShownCount={optionMenuShownCount}
        optionToString={optionToString}
        optionFormatter={optionFormatter}
        options={options ?? new Immutable.List()}
        isOpen={isOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isOptionSelected}
      />
    </SelectContainer>
  )
}
