// @flow

import Immutable from 'immutable'

import {
  BounceDetailFactory,
  type BounceDetailRecord,
  DataFactory,
  type DataRecord,
} from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type BounceDetail } from 'com.batch/shared/infra/types/grpc-stats-service'

export const extractBounceCategories = (
  bounceDetail: BounceDetail
): {
  total: number,
  soft: DataRecord,
  hard: DataRecord,
  block: DataRecord,
  ...
} => {
  let soft = 0
  let hard = 0
  let block = 0
  bounceDetail.categories.forEach(cat => {
    if (cat.name === 'BLOCK') {
      block = cat.count
    }
    if (cat.name === 'SOFT') {
      soft = cat.count
    }
    if (cat.name === 'HARD') {
      hard = cat.count
    }
  })
  return {
    total: bounceDetail.count,
    soft: DataFactory({
      value: soft,
      rate: bounceDetail.count > 0 ? soft / bounceDetail.count : 0,
    }),
    hard: DataFactory({
      value: hard,
      rate: bounceDetail.count > 0 ? hard / bounceDetail.count : 0,
    }),
    block: DataFactory({
      value: block,
      rate: bounceDetail.count > 0 ? block / bounceDetail.count : 0,
    }),
  }
}

export const parseBounceByDays = (
  bounceByDays: Array<{
    bounceDetail: BounceDetail,
    date: string,
    token: string,
  }>
): Immutable.Map<string, BounceDetailRecord> => {
  return bounceByDays.reduce(
    (acc: Immutable.Map<string, BounceDetailRecord>, { date, bounceDetail }) => {
      if (bounceDetail.count > 0) {
        return acc.set(date, BounceDetailFactory(extractBounceCategories(bounceDetail)))
      }
      return acc
    },
    Immutable.Map()
  )
}
