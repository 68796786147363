// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

type DataProps = {
  value: number,
  rate: number,
  unique: ?number,
}
type DataRecord = RecordOf<DataProps>
const DataFactory = Immutable.Record(
  ({
    value: 0,
    rate: 0,
    unique: null,
  }: DataProps)
)

export type EmailProviderStatsProps = {
  delivered: DataRecord,
  open: DataRecord,
  click: DataRecord,
  bounce: DataRecord,
  unsubscribe: DataRecord,
  mpp: DataRecord,
  sent: number,
}
export const EmailProviderStatsFactory: RecordFactory<EmailProviderStatsProps> = Immutable.Record({
  delivered: DataFactory(),
  open: DataFactory(),
  click: DataFactory(),
  bounce: DataFactory(),
  unsubscribe: DataFactory(),
  mpp: DataFactory(),
  sent: 0,
})
export type EmailProviderStatsRecord = RecordOf<EmailProviderStatsProps>

export type EmailProviderProps = {
  name: string,
  stats: EmailProviderStatsRecord,
}
export const EmailProviderFactory: RecordFactory<EmailProviderProps> = Immutable.Record({
  name: '',
  stats: EmailProviderStatsFactory(),
})
export type EmailProviderRecord = RecordOf<EmailProviderProps>
