// @flow

/* attempt to avoid circular dependencies
-----------------------------------------
I'll try to have a .composed file for selectors that depends
on other things that the "root" state
*/

import * as React from 'react'
import { connect } from 'react-redux'

import { type EstimateMode, TargetingForm } from 'components/targeting/targeting-form'

import { type State, type AppRecord } from 'com.batch.redux/_records'
import {
  appCanQueryNative,
  appCanUseGeoloc,
  appCanUseCustomAudiences,
  appCanQueryEventData,
  appCanQueryAttribute,
  appCanUseEventCountPeriod,
  appCanRetarget,
  appCanRetargetInApp,
  appCanUseCluster,
  currentAppSelector,
} from 'com.batch.redux/app'
import {
  targetingAttributeFilteredSelector,
  attributesCategoriesSelector,
  termSelector,
  categorySelector,
  updateAttributeFilterCat,
  updateAttributeFilterTerm,
  usedAttributesValuesLoadingSelector,
  usedAttributesValuesSelector,
} from 'com.batch.redux/attribute'
import { createAttributeValue } from 'com.batch.redux/attributeValue'
import {
  currentCampaign,
  targetingRegionSelector,
  targetingLangSelector,
} from 'com.batch.redux/campaign'
import { toggleJIT } from 'com.batch.redux/campaign.action'
import {
  addCondition,
  addLogical,
  deleteCondition,
  deleteLogical,
  estimateSelector,
  fullTreeSelector,
  toggleCondition,
  toggleLogical,
  updateClusterActiveState,
  updateCustomAudiences,
  updateCondition,
  refreshEstimate,
  updateLangRegion,
} from 'com.batch.redux/targeting'
import {
  customAudiencesSelector,
  clustersTargeting,
  pickedLanguagesSelector,
  pickedRegionsSelector,
} from 'com.batch.redux/targeting.selector.composed'

type StateProps = {
  app: AppRecord,
  ttlRetargeting: number,
  conditionsCount: number,
  canUseCluster: ?boolean,
  canUseCustomAudiences: boolean,
  canQueryEventData: boolean,
  canQuery: boolean,
  canQueryAttribute: boolean,
  platform: string,
  canUseGeoloc: boolean,
  canUseEventCountPeriod: boolean,
  canRetargetInApp: boolean,
  canRetarget: boolean,
  loading: boolean,
  query: string,
  queryError: boolean,
  attributes: any,
  clusters: any,
  estimate: any,
  languages: any,
  regions: any,
  pickedAudiences: any,
  pickedLanguages: any,
  pickedRegions: any,
  tree: any,
  attributesCategories: any,
  filterTerm: string,
  filterCat: ?string,
  attributesValuesLoading: any,
  attributesValues: any,
  JIT: boolean,
}

type DispatchProps = {
  addCondition: (condition: any) => void,
  updateCustomAudiences: () => void,
  updateFilterTerm: () => void,
  updateLangRegion: () => void,
  toggleJIT: () => void,
  addLogical: () => void,
  deleteLogical: () => void,
  toggleLogical: () => void,
  toggleCondition: () => void,
  refreshEstimate: () => void,
  updateCondition: () => void,
  deleteCondition: () => void,
  updateFilterCat: () => void,
  updateClusterActiveState: () => void,
  createAttributeValue: () => void,
}

type OwnProps = {
  header: string,
  estimateMode: EstimateMode,
}

type Props = { ...StateProps, ...OwnProps, ...DispatchProps }

const mapStateToProps = (state: State) => {
  const campaign = currentCampaign(state)
  return {
    app: state.app.current,
    JIT: campaign.inappJustInTime,
    ttlRetargeting: state.app.current.ttlRetargeting ?? 14,
    conditionsCount: state.targeting.get('conditions')?.size,
    clusters: clustersTargeting(state),
    platform: currentAppSelector(state).platform,
    pickedAudiences: customAudiencesSelector(state),
    languages: targetingLangSelector(state),
    regions: targetingRegionSelector(state),
    pickedLanguages: pickedLanguagesSelector(state),
    estimate: estimateSelector(state),
    loading: state.targeting.get('awaitingParse'),
    pickedRegions: pickedRegionsSelector(state),
    tree: fullTreeSelector(state),
    queryError: state.targeting.get('queryError', false),
    query: state.targeting.get('query')
      ? JSON.stringify(state.targeting.get('query'), null, 2)
      : '',
    canQuery: appCanQueryNative(state),
    canQueryAttribute: appCanQueryAttribute(state),
    canRetarget: appCanRetarget(state),
    canRetargetInApp: appCanRetargetInApp(state),
    canQueryEventData: appCanQueryEventData(state),
    canUseCustomAudiences: appCanUseCustomAudiences(state),
    canUseCluster: appCanUseCluster(state),
    canUseGeoloc: appCanUseGeoloc(state),
    canUseEventCountPeriod: appCanUseEventCountPeriod(state),
    attributes: targetingAttributeFilteredSelector(state),
    attributesValues: usedAttributesValuesSelector(state),
    attributesValuesLoading: usedAttributesValuesLoadingSelector(state),
    filterTerm: termSelector(state),
    filterCat: categorySelector(state),
    attributesCategories: attributesCategoriesSelector(state),
  }
}

class TargetingFormContainerRaw extends React.Component<Props> {
  render(): React.Node {
    return <TargetingForm {...this.props} />
  }
}

const mapDispatchToProps = {
  addCondition,
  addLogical,
  createAttributeValue,
  deleteCondition,
  deleteLogical,
  updateCustomAudiences,
  toggleLogical,
  toggleJIT,
  toggleCondition,
  refreshEstimate,
  updateClusterActiveState,
  updateCondition,
  updateFilterCat: updateAttributeFilterCat,
  updateFilterTerm: updateAttributeFilterTerm,
  updateLangRegion,
}

export const TargetingFormContainer = (connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetingFormContainerRaw): React.AbstractComponent<OwnProps>)
