// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { useUserHasPermission } from 'components/_hooks/use-allowed'
import { useGetCompanyIdAndFeatureActivated } from 'components/_hooks/use-company-feature'
import { useToggle } from 'components/_hooks/use-toggle'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import { BoxBody } from 'components/common/box/box.styles'
import { PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Wrapper, GlobalErrorOverlayProps, SettingsEmptyIcon } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Table } from 'components/common/table'
import { TableTemplateCell } from 'components/common/table/table'
import { TableFooter } from 'components/common/table/table.styles'
import { FilterSearch } from 'components/filter/filter-search/filter-search'
import { Title } from 'components/styled/text'

import { profileAudienceStateSelector } from 'com.batch/audience/store/audience-profile.selector'
import { type AudienceListSortTable } from 'com.batch/audience/store/audience-profile.state'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import {
  ProfileAudienceFactory,
  type ProfileAudienceRecord,
} from 'com.batch/audience/model/audience-profile.records'
import { AudienceUpload } from 'com.batch/audience/ui/components/audience-upload-popin'
import { Body } from 'com.batch/audience/ui/components/body'
import { Header } from 'com.batch/audience/ui/components/header'
import { deleteAudience } from 'com.batch/audience/usecases/delete-audience'
import { fetchAudiencesList } from 'com.batch/audience/usecases/fetch-audience-list'
import { fetchEstimateForAudienceName } from 'com.batch/audience/usecases/fetch-estimate-audience'

export const AudienceListEmptyTemplate = (): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="end" />
      <TableTemplateCell template="1fr" align="end" />
      <TableTemplateCell template="1fr" align="end" />
    </React.Fragment>
  )
}
const coerceOrder = (value: ?string) => (value === 'asc' || value === 'dsc' ? value : 'dsc')
export const AudienceList = (): React.Node => {
  const dispatch = useDispatch()
  const user = useSelector(currentUserSelector)

  const { featureActivated, companyId } = useGetCompanyIdAndFeatureActivated('custom-audience')

  const [searchParams, setSearchParams] = useSearchParams()
  const pageParam = parseInt(searchParams.get('page')) || 1
  const sortDirectionParam = coerceOrder(searchParams.get('orderDirection'))
  const searchParam = searchParams.get('search') || ''
  const rawSortByParam = searchParams.get('orderBy')

  const sortByParam =
    rawSortByParam === 'name'
      ? 'name'
      : rawSortByParam === 'display_name'
        ? 'display_name'
        : rawSortByParam === 'nb_ids'
          ? 'nb_ids'
          : 'updated'

  const userAllowedToWrite = useUserHasPermission(user, ['app', 'custom-audiences:write'])

  const { idsPerPage, entities, creatingState, search } = useSelector(
    state => state.audienceProfile
  )

  const { loadingState, nbPerPage, count, estimatesForAudiences } = useSelector(
    profileAudienceStateSelector
  )
  const uploaderState = useToggle()
  const audiences = React.useMemo(
    () =>
      idsPerPage
        .get(pageParam, new Immutable.List())
        .map(id => entities.get(id, ProfileAudienceFactory())),
    [entities, idsPerPage, pageParam]
  )

  const isError = React.useMemo(() => loadingState === 'ERROR', [loadingState])

  const isEmpty = React.useMemo(
    () =>
      loadingState === 'ERROR' ||
      (count === 0 && loadingState === 'LOADED' && !search) ||
      !featureActivated,
    [loadingState, count, search, featureActivated]
  )

  const createOnSortChange = React.useCallback(
    (column: AudienceListSortTable) => (): void => {
      const direction: 'asc' | 'dsc' =
        sortByParam === column ? (sortDirectionParam === 'asc' ? 'dsc' : 'asc') : 'asc'

      setSearchParams(params => {
        params.set('orderDirection', direction)
        params.set('orderBy', column)
        return params
      })
    },
    [sortByParam, sortDirectionParam, setSearchParams]
  )

  const remove = React.useCallback(
    (audience: ProfileAudienceRecord) => {
      confirm({
        sensitive: true,
        message: (
          <article>
            <p>
              This will delete audience <ConfirmHighlight>{audience.name}</ConfirmHighlight> app.
            </p>
            <p>Campaigns and automations already targeting this audience won't reach anyone.</p>
          </article>
        ),
        title: 'Delete this audience?',
        smallFooter: true,
      }).then(
        () =>
          dispatch(deleteAudience({ name: audience.name })).catch(e => {
            console.log('err', e)
          }),
        () => {}
      )
    },
    [dispatch]
  )
  const onPageChange = React.useCallback(
    page => {
      setSearchParams(params => {
        params.set('page', page.toString())
        return params
      })
    },
    [setSearchParams]
  )

  const onSearchChange = React.useCallback(
    searchTerm => {
      setSearchParams(params => {
        params.set('search', searchTerm)
        return params
      })
    },
    [setSearchParams]
  )

  React.useEffect(() => {
    dispatch(
      fetchAudiencesList({
        trashCache: true,
        page: pageParam,
        sortDirection: sortDirectionParam,
        search: searchParam,
        sortBy: sortByParam,
      })
    ).catch(e => {
      if (!e.aborted) console.error('Error while fetching audiences list:', e)
    })
  }, [dispatch, pageParam, sortByParam, searchParam, sortDirectionParam])

  /* 
    when audiences or missingAudiences changes, fetchMissingAudiences changes, 
    and this effect triggers again, loading the next missing audience
  */
  const fetchNextMissingAudience = React.useCallback(() => {
    const missinEstimate = audiences.filter(a => !estimatesForAudiences.has(a.name))
    const currentlyLoadingAudiences = estimatesForAudiences.filter(a => a.loading === 'LOADING')

    if (missinEstimate.size > 0 && currentlyLoadingAudiences.size === 0) {
      dispatch(fetchEstimateForAudienceName({ audienceName: missinEstimate.first().name })).catch(
        e => {
          console.error('Error :', e)
        }
      )
    }
  }, [dispatch, audiences, estimatesForAudiences])

  React.useEffect(() => {
    fetchNextMissingAudience()
  }, [fetchNextMissingAudience])

  return (
    <React.Fragment>
      <Wrapper
        isEmpty={isEmpty}
        isLoading={loadingState === 'LOADING'}
        isOverlayShown={loadingState !== 'LOADING' && (isError || !featureActivated || isEmpty)}
        overlayProps={
          !featureActivated
            ? {
                status: 'upgrade-page',
                companyId: companyId ?? 0,
                title: 'Activate this feature',
                description:
                  'Audiences allows you to target and engage with customers more effectively.',
              }
            : isError
              ? GlobalErrorOverlayProps
              : {
                  status: 'empty-page',
                  title: 'No audience saved yet',
                  description: (
                    <div>
                      <p style={{ marginBottom: 10 }}>
                        Our audience feature lets you send static lists of users to Batch and then
                        target those with email orchestration.
                      </p>
                      <PermissionButton
                        intent="action"
                        kind="primary"
                        onClick={uploaderState.open}
                        disabled={loadingState === 'LOADING'}
                        isAllowed={userAllowedToWrite}
                      >
                        Add audience
                      </PermissionButton>
                    </div>
                  ),
                  content: <SettingsEmptyIcon />,
                  links: [
                    {
                      name: 'Audiences',
                      href: 'https://doc.batch.com/dashboard/push/user-targeting#custom-audiences',
                    },
                    {
                      name: 'Install the SDK',
                      href: 'https://doc.batch.com/api/audience/create/',
                    },
                  ],
                }
        }
      >
        <React.Fragment>
          <Grid
            template={`1fr auto 9px ${!userAllowedToWrite ? '150px' : 'auto'}`}
            gap={8}
            margin={[0, 0, 38, 0]}
          >
            <Title mb={0} overEmptyState>
              Audiences
            </Title>

            <FilterSearch
              identifier="audiences"
              placeholder="Search audiences..."
              value={searchParam}
              onChange={onSearchChange}
              disabled={isEmpty && !searchParam && loadingState === 'LOADING'}
              expandedMaxWidth={280}
            />

            <Separator />

            <PermissionButton
              intent="action"
              kind="primary"
              addOn="prefix"
              onClick={uploaderState.open}
              disabled={isEmpty && !searchParam && loadingState === 'LOADING'}
              isAllowed={userAllowedToWrite}
            >
              <Icon icon="upload" />
              Add audience
            </PermissionButton>
          </Grid>
          <BoxBody>
            <Table template="minmax(100px, 1fr) 190px 150px 120px 140px 126px" rowHeight={44}>
              <Header onSortChange={createOnSortChange} />
              <Body
                search={searchParam}
                emptyTemplate={<AudienceListEmptyTemplate />}
                templateSize={nbPerPage}
                audiences={audiences}
                remove={remove}
                estimate={estimatesForAudiences}
              />
              {(count > 10 || (count === 0 && loadingState === 'LOADING' && !search)) && (
                <TableFooter>
                  <Pager
                    forceLoading={loadingState === 'LOADING'}
                    page={pageParam}
                    total={count}
                    nbPerPage={nbPerPage}
                    selectPage={onPageChange}
                  />
                </TableFooter>
              )}
            </Table>
          </BoxBody>
        </React.Fragment>
      </Wrapper>
      <Popin
        opened={uploaderState.value}
        close={uploaderState.close}
        style={{ width: 880, margin: 0, padding: 0 }}
      >
        <AudienceUpload creatingState={creatingState} closeModal={uploaderState.close} />
      </Popin>
    </React.Fragment>
  )
}
