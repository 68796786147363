// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { UserAvatar as Avatar } from 'components/account/team/user-avatar'
import { ButtonNavLink, Button, IconDropdown } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Checkbox } from 'components/form'
import LandingPreview from 'components/landing/landing-preview'

import { generateUrl } from 'com.batch.common/router'

import { ThemeFormatsList } from './theme-utils'
import {
  ThemeThumbContainer,
  ThemeThumbHeader,
  ThemeTumbContainer,
  ThemeThumbOverlay,
  ThemeThumbPreview,
} from './theme.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { type InAppContentRecord } from 'com.batch.redux/content.records'
import { LinkTheme, duplicateTheme } from 'com.batch.redux/theme'
import { type ThemeRecord } from 'com.batch.redux/theme.records'
import { UserFactory } from 'com.batch.redux/user.records'

type ThemeThumbProps = {
  mode?: 'main' | 'modal',
  app: AppRecord,
  theme: ThemeRecord,
  data: InAppContentRecord,
  archive?: () => any,
  isChecked?: boolean,
  check?: ThemeRecord => any,
  userNotAllowed?: boolean,
  ...
}

export const ThemeThumb = ({
  mode = 'main',
  theme,
  data,
  app,
  archive,
  isChecked = false,
  check,
  userNotAllowed = false,
}: ThemeThumbProps): React.Node => {
  // ====================== Component constants
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const campaignRunning: boolean = theme.nbCampaignsRunning > 0
  const checkboxId: string = `input_${theme.code}`
  const format = ThemeFormatsList.find(f => f.value === theme.payloadVars.kind).label

  const disabledMainAction: boolean = campaignRunning || userNotAllowed

  // ======================
  const ButtonEditTheme: React.Node = (
    <ButtonNavLink
      kind="inlineDark"
      intent="neutral"
      addOn={disabledMainAction ? 'prefix' : undefined}
      disabled={disabledMainAction}
      to={generateUrl('theme_editor', {
        companyId: app.companyId,
        appId: app.id,
        id: theme.id,
      })}
    >
      {disabledMainAction ? <Icon icon="lock" /> : null}
      Edit {format} theme
    </ButtonNavLink>
  )

  // ====================== Render
  return (
    <ThemeProvider theme={{ themeThumbMode: mode }}>
      <ThemeThumbContainer
        isChecked={isChecked}
        onClick={e => {
          if (mode === 'modal') {
            e.target.id === checkboxId ? e.preventDefault() : !!check && check(theme)
          }
        }}
      >
        <ThemeThumbHeader
          template={mode === 'modal' ? '20px minmax(50px, 1fr) ' : 'minmax(50px, 1fr) 28px'}
          noCampaignsRunning={!campaignRunning}
          gap={mode === 'modal' ? 7 : 6}
        >
          {mode === 'modal' && (
            <div className="styled_theme-thunk-checkbox">
              <Checkbox
                id={checkboxId}
                checked={isChecked}
                disabled={false}
                handleIndeterminate={false}
              />
            </div>
          )}

          <div className="styled_theme-thunk-title">
            <h3 title={theme.name}>{theme.name}</h3>
            <span>
              {theme.nbCampaignsRunning > 0 ? theme.nbCampaignsRunning : 'No'} running campaign
              {theme.nbCampaignsRunning > 1 && 's'}
            </span>
          </div>

          {mode === 'main' && (
            <div>
              <IconDropdown
                offset={[-162, -29]}
                forcedWidth={158}
                buttonProps={{
                  style: { width: 28, height: 28 },
                  kind: 'inline',
                  intent: 'neutral',
                }}
              >
                <Button
                  disabled={userNotAllowed}
                  onClick={() => {
                    let url = generateUrl('theme_editor', {
                      companyId: app.companyId,
                      appId: app.id,
                      id: 'new',
                    })
                    navigate(url)
                    dispatch(duplicateTheme(theme))
                  }}
                >
                  Replicate theme
                </Button>
                <ButtonNavLink
                  addOnGap={48}
                  disabled={disabledMainAction}
                  addOn={disabledMainAction ? 'suffix' : undefined}
                  to={generateUrl('theme_editor', {
                    companyId: app.companyId,
                    appId: app.id,
                    id: theme.id,
                  })}
                >
                  Edit theme
                  {disabledMainAction && <Icon icon="lock" />}
                </ButtonNavLink>
                <Button
                  addOnGap={26}
                  addOn={disabledMainAction ? 'suffix' : undefined}
                  disabled={disabledMainAction}
                  onClick={() => dispatch(LinkTheme(theme, false, app.id))}
                >
                  Detach theme
                  {disabledMainAction && <Icon icon="lock" />}
                </Button>
                <Button
                  intent="danger"
                  addOn={disabledMainAction ? 'suffix' : undefined}
                  disabled={disabledMainAction}
                  onClick={archive}
                  addOnGap={30}
                >
                  Delete theme
                  {disabledMainAction && <Icon icon="lock" />}
                </Button>
              </IconDropdown>
            </div>
          )}
        </ThemeThumbHeader>

        <ThemeTumbContainer>
          <ThemeThumbPreview>
            <div>
              <LandingPreview theme={theme} platform={app.platform} data={data} variant={null} />
            </div>
          </ThemeThumbPreview>

          {mode !== 'modal' && (
            <ThemeThumbOverlay>
              <div>
                {!userNotAllowed && campaignRunning ? (
                  <Tooltip
                    tooltip={
                      <div style={{ maxWidth: 196 }}>
                        You cannot edit this theme since there are running campaign
                        {theme.nbCampaignsRunning > 1 && 's'}. <br />
                        You can replicate it.
                      </div>
                    }
                    placement="left"
                  >
                    <div>{ButtonEditTheme}</div>
                  </Tooltip>
                ) : (
                  ButtonEditTheme
                )}
              </div>

              <div>
                {!!theme.updatedBy && (
                  <Tooltip
                    tooltip={`${
                      theme.updatedBy.firstName
                    } ${theme.updatedBy.lastName.toUpperCase()}`}
                    placement="left"
                  >
                    <div>
                      <Avatar
                        user={theme.updatedBy ?? UserFactory()}
                        size={20}
                        style={{ marginRight: 6 }}
                      />
                    </div>
                  </Tooltip>
                )}
                Last edit on {!!theme.updated && theme.updated.format('DD/MM/YYYY')}
              </div>
            </ThemeThumbOverlay>
          )}
        </ThemeTumbContainer>
      </ThemeThumbContainer>
    </ThemeProvider>
  )
}
