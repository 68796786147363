// @flow

import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Button, PermissionButton } from 'components/common/button'
import { Code } from 'components/common/code'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow } from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { formatAudienceType, humanizeDayjs, kformat } from 'com.batch.common/utils'

import { AudienceEstimate, AudienceEstimateData } from './audience-estimate'
import { TokensIdsTooltip } from './audience.styles'

import { type CustomAudienceRecord } from 'com.batch.redux/_records'

type AudienceEditorProps = {
  aud: CustomAudienceRecord,
  isEditing: boolean,
  save: (aud: CustomAudienceRecord) => Promise<null>,
  remove: (aud: CustomAudienceRecord) => any,
  setEditing: () => any,
  closeEditing: () => any,
  index: number,
  ...
}

const AudienceEditorRaw = ({
  aud,
  isEditing,
  save,
  remove,
  setEditing,
  closeEditing,
  index,
}: AudienceEditorProps) => {
  const [currentAud, setCurrentAud] = React.useState<CustomAudienceRecord>(aud)

  React.useEffect(() => {
    if (isEditing) setCurrentAud(aud)
  }, [isEditing, aud])

  const renderTokensIds = (
    <React.Fragment>
      <TokensIdsTooltip isTokens>
        <div>
          {aud.estimate ? <AudienceEstimateData estimate={aud.estimate} name={aud.name} /> : 0}
        </div>
        <div>token{!!aud.estimate && 's'} (estimated)</div>
      </TokensIdsTooltip>
      <TokensIdsTooltip>
        <div>{kformat(aud.idsCount)}</div>
        <div>
          {aud.type === 'custom_ids' && 'custom user ID'}
          {aud.type === 'install_ids' && 'installation ID'}
          {aud.type === 'advertising_ids' && 'advertising ID'}
          {aud.idsCount > 1 && 's'}
        </div>
      </TokensIdsTooltip>
    </React.Fragment>
  )

  const isAllowedToUpdate = useIsCurrentUserAllowedTo(['app', 'custom-audiences:write'])
  const onSubmit = React.useCallback(
    (evt: SyntheticEvent<HTMLFormElement>) => {
      evt.preventDefault()
      save(currentAud.set('estimate', aud.estimate)).then(closeEditing)
    },
    [aud.estimate, closeEditing, currentAud, save]
  )
  const onChange = React.useCallback(
    v => setCurrentAud(currentAud.set('description', v.target.value)),
    [currentAud]
  )
  const onRemove = React.useCallback(() => remove(currentAud), [currentAud, remove])
  return (
    <TableRow as={isEditing && 'form'} isEditing={isEditing} onSubmit={onSubmit}>
      {isEditing ? (
        <ThemeProvider theme={{ size: 'small' }}>
          <TableCell kind="input">
            <Input
              autoFocus
              value={currentAud.description}
              placeholder={currentAud.name}
              onChange={onChange}
            />
          </TableCell>
        </ThemeProvider>
      ) : (
        <TableCell>
          {aud.isIndexing && (
            <Tooltip
              tooltip="Audience is being processed"
              key={aud.name + 'isIndexing'}
              placement="top"
            >
              <Icon
                icon="waiting-fill"
                size={13}
                color={colors.textAction}
                style={{ marginRight: 8 }}
              />
            </Tooltip>
          )}

          <Ellipsis style={{ whiteSpace: 'pre' }} title={aud.description || aud.name}>
            {aud.description || aud.name}
          </Ellipsis>
        </TableCell>
      )}

      <TableCell>
        <Code size="small">{aud.name}</Code>
      </TableCell>

      <TableCell>{formatAudienceType(aud.type)}</TableCell>

      <TableCell align="right" style={{ marginRight: '28px' }}>
        <Tooltip tooltip={renderTokensIds} key={aud.name} placement="right">
          <div>
            <AudienceEstimate
              estimate={aud.estimate}
              name={aud.name}
              count={aud.idsCount}
              index={index}
            />
          </div>
        </Tooltip>
      </TableCell>

      <TableCell>
        <Tooltip
          tooltip={
            <React.Fragment>
              {currentAud.updatedAt.format('DD/MM/YYYY')}{' '}
              <span style={{ fontWeight: 300 }}>
                at {currentAud.updatedAt.format('HH:mm:ss')} UTC
              </span>
            </React.Fragment>
          }
          placement="right"
        >
          <span>{humanizeDayjs({ date: currentAud.updatedAt })}</span>
        </Tooltip>
      </TableCell>

      <TableCellActions>
        {isEditing ? (
          <React.Fragment>
            <Button
              style={{ width: 50 }}
              type="submit"
              disabled={currentAud.description === aud.description}
            >
              Save
            </Button>
            <Button onClick={closeEditing} type="button">
              <Icon icon="close" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PermissionButton
              kind="inline"
              intent="neutral"
              onClick={setEditing}
              isAllowed={isAllowedToUpdate}
            >
              <Icon icon="edit" size={13} />
            </PermissionButton>
            <PermissionButton kind="inline" onClick={onRemove} isAllowed={isAllowedToUpdate}>
              <Icon icon="delete" />
            </PermissionButton>
          </React.Fragment>
        )}
      </TableCellActions>
    </TableRow>
  )
}

export const AudienceEditor: React.AbstractComponent<AudienceEditorProps> =
  React.memo<AudienceEditorProps>(AudienceEditorRaw)
