// @flow

import * as React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled, { css, type StyledComponent } from 'styled-components'

import { Icon, IconContainer, type availableIcons } from 'components/common/svg-icon'
import { LabelPlaceholder } from 'components/styled/placeholder'
import { colors, schemes } from 'components/styled/tokens'

type ReviewBigTextProps = { fat?: boolean, color?: string, disabled?: boolean, ... }
export const ReviewBigText: StyledComponent<ReviewBigTextProps, *, HTMLElement> = styled.span`
  font-size: ${(props: ReviewBigTextProps) => (props.fat ? '24px' : '18px')};
  color: ${(p: ReviewBigTextProps) =>
    p.color ? p.color : p.disabled ? colors.textDisabled : colors.text};
  font-weight: ${(props: ReviewBigTextProps) => (props.fat ? 'normal' : '400')};
  line-height: ${(props: ReviewBigTextProps) => (props.fat ? '29px' : 'normal')};
`

export const TextEmpty: StyledComponent<*, *, HTMLElement> = styled.div`
  color: ${colors.textDisabled};
`

export const ReviewSmallText: StyledComponent<*, *, HTMLElement> = styled.span`
  font-size: 13px;
  display: inline-block;
  padding-left: 6px;
  color: ${colors.textAction};
  opacity: 0.5;
`
export const Title3: StyledComponent<*, *, HTMLElement> = styled.h3`
  font-size: 16px;
  font-weight: 500;
`
type SeparatorTopProps = { disabled?: boolean, ... }
export const SeparatorTop: StyledComponent<SeparatorTopProps, *, HTMLElement> = styled.div`
  border-top: ${(props: SeparatorTopProps) =>
    props.disabled ? 'none' : `1px solid ${colors.stroke}`};
`
type VariantTitleProps = { disabled?: boolean, variant: 'a' | 'b', ... }
export const VariantTitle: StyledComponent<VariantTitleProps, *, HTMLElement> = styled.h4`
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 14px;
  .styled-variant-text {
    width: calc(100% - 22px);
    color: ${(props: VariantTitleProps) => {
      if (props.disabled) {
        return colors.textDisabled
      } else {
        return props.variant === 'a' ? colors.textAction : schemes.red['40']
      }
    }};
  }
`
type PaddedBoxProps = { first: boolean, ... }
export const PaddedBox: StyledComponent<PaddedBoxProps, *, HTMLElement> = styled.div`
  position: relative;
  margin-top: ${(props: PaddedBoxProps) => (props.first ? 'auto' : '35px')};
  padding: 30px;
  border-top: ${(props: PaddedBoxProps) => (props.first ? 'none' : `1px solid ${colors.stroke}`)};
`
type VariantPreviewProps = { active?: boolean, ... }
export const VariantPreview: StyledComponent<VariantPreviewProps, *, HTMLElement> = styled.button`
  z-index: 5;
  outline: none;
  position: absolute;
  text-align: center;
  top: calc(50% - 14px);
  right: -14px;
  display: flex;
  flex-direction: column;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  color: ${(props: VariantPreviewProps) => (props.active ? colors.textAction : colors.textLight)};
  border: 1px solid
    ${(props: VariantPreviewProps) => (props.active ? colors.textAction : colors.strokeDark)};
  background: #fff;
  border-radius: 50%;
  font-weight: bold;
`

export const ListCampaignTitle: StyledComponent<*, *, HTMLElement> = styled.div`
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 1.4em;
  overflow: hidden;
`

export const Subtitle: StyledComponent<*, *, HTMLElement> = styled.h5`
  font-size: 16px;
  color: ${colors.text};
`
type TitleProps = { overEmptyState?: boolean, mb?: number, ... }
export const Title: StyledComponent<TitleProps, *, HTMLElement> = styled.h4`
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  color: ${colors.text};
  margin-bottom: ${(props: TitleProps) =>
    typeof props.mb === 'number' ? `${props.mb}px` : '38px'};
  margin-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${(p: TitleProps) =>
    p.overEmptyState &&
    css`
      position: relative;
      z-index: 12;
    `}

  & + ${Subtitle} {
    margin-top: 12px;
  }
`

export const CapsLabel: StyledComponent<*, *, *> = styled(
  // $FlowFixMe
  LabelPlaceholder.withComponent('label')
)`
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  color: #9ca4b5;
  font-weight: 500;
`
export const Value: StyledComponent<*, *, *> = styled(
  // $FlowFixMe
  LabelPlaceholder.withComponent('span')
).attrs({
  placeholder_width: '26%',
})`
  font-size: 22px;
  font-weight: 300;
  color: ${colors.text};
`
export const Chapo: StyledComponent<*, *, HTMLElement> = styled.h3`
  letter-spacing: 1px;
  color: #9ca4b5;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 6px;
`
export const TagLabel: StyledComponent<*, *, HTMLElement> = styled.div`
  display: inline-block;
  min-width: 0;
  border-radius: 2px;
  padding: 6px 6px 6px 6px;
  text-transform: uppercase;
  background: #00a1e0;
  font-size: 9px;
  color: #fff;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
`

export const TextFeedback: StyledComponent<*, *, HTMLElement> = styled.div`
  font-weight: 500;
  color: ${colors.textNeutral};
`

export const TextFeedbackWarning: StyledComponent<*, *, HTMLElement> = styled.div`
  font-weight: 500;
  color: ${colors.textWarning};
`

export const Timezone: StyledComponent<*, *, HTMLElement> = styled.label`
  display: inline-block;
  padding: 4px 4px;
  margin: 0;
  font-size: 10px;
  line-height: 1em;
  font-weight: 600;
  color: ${colors.textNeutralContrast};
  letter-spacing: -0.005em;
  text-transform: uppercase;
  background: ${colors.fillNeutral};
  border-radius: 4px;
`

const linkStyle = css`
  display: inline-block;
  line-height: 13px;
  font-weight: 500;

  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    font-weight: 500;
    letter-spacing: 0;
    color: ${(p: any) =>
      p.intent === 'action'
        ? colors.textAction
        : p.intent === 'dark'
          ? colors.textContrast
          : colors.textLight};
    cursor: pointer;
  }

  ${(p: any) =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${IconContainer} {
    margin: 0 0 0 6px;
    color: ${(p: any) =>
      p.intent === 'action'
        ? colors.textAction
        : p.intent === 'dark'
          ? colors.textContrast
          : colors.textNeutral};
    color: currentColor;
  }
`
export const Link: StyledComponent<
  { intent?: 'action' | 'neutral' | 'dark', disabled?: boolean, ... },
  { ... },
  HTMLElement,
> = styled.a`
  ${linkStyle}
`

export const RouterLink: StyledComponent<
  { intent?: 'action' | 'neutral' | 'dark', disabled?: boolean, ... },
  { ... },
  typeof ReactRouterLink,
> = styled(ReactRouterLink)`
  ${linkStyle}
`

export const LinkArrow: React.AbstractComponent<{ children: React.Node, ... }, HTMLElement> =
  React.forwardRef<{ children: React.Node, ... }, *>(
    ({ children, ...rest }: { children: React.Node, ... }, ref) => (
      <Link {...rest} ref={ref}>
        {children}
        <Icon icon="arrow-long-right" style={{ margin: '0 0 0 6px' }} />
      </Link>
    )
  )

export const LinkDoc: React.AbstractComponent<{ children: React.Node, ... }, HTMLElement> =
  React.forwardRef<{ children: React.Node, ... }, *>(
    ({ children, ...rest }: { children: React.Node, ... }, ref) => (
      <Link {...rest} ref={ref}>
        <Icon icon="documentation" style={{ margin: '0 6px 0 0' }} />
        {children}
      </Link>
    )
  )

export const LinkDocumentation: React.AbstractComponent<
  { children: React.Node, ... },
  HTMLElement,
> = React.forwardRef<{ children: React.Node, ... }, *>(
  ({ children, ...rest }: { children: React.Node, ... }, ref) => (
    <Link {...rest} ref={ref}>
      <span style={{ display: 'inline-flex', alignItems: 'center', lineHeight: '18px' }}>
        <Icon icon="documentation" style={{ margin: '0 8px 0 0' }} />
        {children}
      </span>
    </Link>
  )
)

// Doc Link
const DocLinkIcon: StyledComponent<*, *, *> = styled.span`
  margin-right: 8px;

  & > i {
    margin: 0 !important;
  }
`
export const DocLink = ({ children, ...rest }: { children: React.Node, ... }): React.Node => {
  return (
    <Link {...rest}>
      <DocLinkIcon>
        <Icon icon="details" />
      </DocLinkIcon>
      {children}
    </Link>
  )
}

export const TextLabelWithIcon = ({
  icon,
  children,
}: {
  icon: availableIcons,
  children: React$Node,
  ...
}): React.Node => {
  return (
    <TextLabel>
      <Icon icon={icon} style={{ marginRight: 8 }} />
      {children}
    </TextLabel>
  )
}

export const LightText: StyledComponent<*, *, HTMLElement> = styled.span`
  color: ${colors.textLight};
`

export const TextLabel: StyledComponent<*, *, typeof LightText> = styled(LightText)`
  font-weight: 500;
  letter-spacing: -0.005em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Ellipsis: StyledComponent<*, *, HTMLElement> = styled.div`
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`

export const FeatureBadge: StyledComponent<*, *, HTMLElement> = styled.span`
  padding: 4px 5px;
  font-weight: 500;
  margin-right: 7px;
  color: ${colors.textContrastLight};
  letter-spacing: -0.01em;
  background-color: ${colors.fillAction};
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.02);
`

export const StyledDesc: StyledComponent<*, *, HTMLElement> = styled.div`
  padding: 0.6em 1em;
  background-color: ${schemes.blue['10']};
  border: 2px solid ${schemes.blue['20']};
  margin-bottom: 1em;
  color: ${colors.text};
  border-radius: 1em;
  h1 {
    font-size: 1.4em;
  }
  h1,
  p {
    line-height: 1.5em;
    margin-bottom: 0.4em;
  }
`
