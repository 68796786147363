// @flow

import styled, { type StyledComponent } from 'styled-components'

type MessageGridProps = { $verticalMessage: boolean, ... }
export const MessageGrid: StyledComponent<MessageGridProps, { ... }, HTMLDivElement> = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: ${(props: MessageGridProps) =>
    props.$verticalMessage ? '1fr' : '400px 1fr'};

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
  }
`
