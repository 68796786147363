// @flow

import * as React from 'react'

import { schemes } from 'components/styled/tokens'

import { Graduation } from './scale-line.styles'

type Props = {
  max: number,
  height: number,
  width: number,
  nbLines: number,
  nbParts: number,
  lineMax: number,
}

const prefixes = [
  {
    symbol: 'P',
    min: 1000000000000,
    div: 1000000000000,
  },
  {
    symbol: 'T',
    min: 1000000000,
    div: 1000000000,
  },
  {
    symbol: 'M',
    min: 1000000,
    div: 1000000,
  },
  {
    symbol: 'k',
    min: 10000,
    div: 1000,
  },
]

export const ScaleLine = ({ max, width, height, nbLines, nbParts, lineMax }: Props): React.Node => {
  const scaleLineRefs = React.useRef([])
  const onRefSet = React.useCallback(index => el => (scaleLineRefs.current[index] = el), [])

  const convertToKformat = React.useCallback(number => {
    let found = prefixes.find(f => Math.round(number) > f.div)
    if (found) console.log(Math.round(number / found.div))
    return found ? Math.round(number / found.div) + found.symbol : number
  }, [])

  const scaleLine = React.useMemo(() => {
    const valueInterval = max / lineMax
    const yInterval = height / nbParts

    return Array.from({ length: nbLines }, (l, i) => ({
      value: i * valueInterval,
      y: height - i * yInterval,
    }))
  }, [max, height, nbParts, lineMax, nbLines])

  return (
    <g>
      {scaleLine.map((line, index) => {
        const y = line.value === 0 ? line.y - 2 : line.y
        return (
          <g key={`scaleLine-${index}`}>
            <line
              strokeDasharray="5, 2"
              x1="0"
              y1={y}
              x2={width}
              y2={y}
              style={{ strokeWidth: 1 }}
              stroke={schemes.darklucent['10']}
            />
            <Graduation ref={onRefSet(index)} x={width} y={line.y + 20}>
              {convertToKformat(line.value)}
            </Graduation>
          </g>
        )
      })}
    </g>
  )
}
