// @flow

import { convertNumberToDayOfWeek } from 'com.batch.common/utils'

import {
  generateEdge,
  buildSourceAndHandleFromBranchId,
  handleAddOrSourceBranch,
} from '../reactflow.tools'
import { type FormatterProps, NoPosition } from '../reactflow.types'

import { type TimerNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

export const formatTimer = ({
  node,
  ...props
}: {
  node: TimerNodeRecord,
  ...FormatterProps,
}): FormatterProps => {
  const owner = props.ownedBy
  const skipRejoinButton = owner.type === 'REJOIN'
  const hasExitEvent = node.onEvents.size > 0
  const onTimerClick = () => props.callbacks.openTimerSheet(node.id)

  const generateTimerNodeLabel = (node: TimerNodeRecord): string => {
    if (node.mode === 'before' || node.mode === 'after') {
      return `${node.timer.fullText} ${node.mode} attribute`
    }

    if (node.mode === 'for' && node.timer.valid) {
      return node.timer.fullText
    }

    if (
      node.mode === 'until' &&
      node.waitUntilTime.minute !== undefined &&
      node.waitUntilTime.hour !== undefined &&
      node.waitUntilTime.daysOfWeek.size > 0
    ) {
      if (node.waitUntilTime.daysOfWeek.size === 7) {
        return `${node.waitUntilTime.hour.toString().length === 1 ? '0' : ''}${
          node.waitUntilTime.hour
        }:${node.waitUntilTime.minute.toString().length === 1 ? '0' : ''}${
          node.waitUntilTime.minute
        }`
      }

      return `${node.waitUntilTime.hour.toString().length === 1 ? '0' : ''}${
        node.waitUntilTime.hour
      }:${node.waitUntilTime.minute.toString().length === 1 ? '0' : ''}${
        node.waitUntilTime.minute
      } on ${convertNumberToDayOfWeek(node.waitUntilTime.daysOfWeek.first())}`
    }

    return ''
  }

  /* when we have an (exit) event, we add a buttonNode that will be
     the source of the 2 linked nodes (exit events with final on the left branch,
     and timer and message on the right branch)
  */
  if (hasExitEvent) {
    /*
                      |
                     [+]  <- this button 
                     / \
            (events)    (timer)          
                |
              exit                       

          
        */

    // the button [+] from which our 2 edges originate

    // we don't have to add it when previous reactflow node is a fake yesno rejoin button

    if (!skipRejoinButton) {
      props.reactflowNodes.push({
        id: node.id + '_rejoin',
        type: 'buttonNode',
        sizingType: 'rejoin',
        data: {
          reduxNodeId: node.id,
          isActive: false,
          flowRestrictions: props.flowRestrictions,
          pasteNode: () => props.callbacks.onPasteNode([props.ownedBy]),
          insertNode: (nodeType, channel) => {
            props.callbacks.onInsertNode({
              branchIds: [props.ownedBy],
              nodeType,
              channel,
            })
          },
        },
        position: { x: 0, y: 0 },
      })
      // previous node to [+]
      props.reactflowEdges.push(
        generateEdge({
          ...buildSourceAndHandleFromBranchId(props.ownedBy),
          target: node.id + '_rejoin',
        })
      )
    }
    // (timer)
    props.reactflowNodes.push({
      id: node.id,
      type: 'timerNode',
      sizingType: 'TIMER',
      position: { x: 0, y: 0 },
      data: {
        stickToEdge: true,
        reduxNodeId: node.id,
        errors: node.errors,
        onRemove: () => props.callbacks.onRemoveNode(node.id),
        value: generateTimerNodeLabel(node),
        onClick: onTimerClick,
      },
    })
    // [+] --- (timer)
    props.reactflowEdges.push(
      generateEdge({
        ...(skipRejoinButton
          ? buildSourceAndHandleFromBranchId(props.ownedBy)
          : { source: node.id + '_rejoin', sourceHandle: 'source' }),
        target: node.id,
      })
    )

    /* 
          we could have multiple branches, each triggered by multiple events
          placement is not handled yet
        */
    node.onEvents.forEach(onEvent => {
      // -- add (events) node
      props.reactflowNodes.push({
        id: node.id + 'onEvents',
        type: 'eventNode',
        sizingType: 'TIMER',
        position: { x: 0, y: 0 },
        data: {
          stickToEdge: true,
          onRemove: () => props.callbacks.removeCancelEvents(node.id),
          reduxNodeId: node.id,
          value: onEvent.triggers.map(trigger => ({
            eventName: trigger.name,
            hasQuery: trigger.query !== '',
          })),
          onClick: onTimerClick,
        },
      })
      // [+] --- (events)
      props.reactflowEdges.push(
        generateEdge({
          ...(skipRejoinButton
            ? buildSourceAndHandleFromBranchId(props.ownedBy)
            : { source: node.id + '_rejoin', sourceHandle: 'source' }),
          target: node.id + 'onEvents',
        })
      )
      /* 
            later on we could recursively parse, right now it's only exit event
            so we just add a final node and its edge

            (exit)
          */
      props.reactflowNodes.push({
        id: node.id + 'exit',
        sizingType: 'FINAL',
        type: 'exitNode',
        style: { cursor: 'default' },
        position: { x: 0, y: 0 },
        data: { reduxNodeId: node.onEvents.first().nextNodeId },
      })
      // (events) --- (exit)
      props.reactflowEdges.push(
        generateEdge({
          source: node.id + 'onEvents',
          target: node.id + 'exit',
          targetHandle: 'enter-top',
          withArrow: true,
        })
      )
    })
  } else {
    /*
      TIMER ALONE / NO EVENTS :
      when we don't have events, we just add a timer and link it to the previous node
      the link should allow message insertion
    */

    props.reactflowNodes.push({
      id: node.id,
      type: 'timerNode',
      sizingType: 'TIMER',
      position: { x: 0, y: 0 },
      data: {
        reduxNodeId: node.id,
        errors: node.errors,
        onRemove: () => props.callbacks.onRemoveNode(node.id),
        value: generateTimerNodeLabel(node),
        onClick: onTimerClick,
      },
    })
    let addButtonId: ?string = null
    if (props.ownedBy.type !== 'REJOIN') {
      addButtonId = node.id + '_add'
      props.reactflowNodes.push({
        ...NoPosition,
        id: addButtonId,
        sizingType: 'rejoin',
        type: 'buttonNode',
        data: {
          reduxNodeId: node.id,
          flowRestrictions: props.flowRestrictions,
          isActive: false,
          pasteNode: () => props.callbacks.onPasteNode([props.ownedBy]),
          insertNode: (nodeType, channel) => {
            props.callbacks.onInsertNode({
              branchIds: [props.ownedBy],
              nodeType,
              channel,
            })
          },
        },
      })
      props.reactflowEdges.push(
        generateEdge({
          ...buildSourceAndHandleFromBranchId(props.ownedBy),
          target: addButtonId,
        })
      )
    }
    props.reactflowEdges.push(
      generateEdge({
        withArrow: false,
        ...handleAddOrSourceBranch(props.ownedBy, addButtonId),
        target: node.id,
      })
    )
  }
  return props
}
