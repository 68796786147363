// @flow

import {
  type ReachabilityTimeserieStateRecord,
  ReachabilityTimeserieStateFactory,
} from 'com.batch/profilebase/models/reachability-timeserie.records'
import { type FetchSmsReachabilityTimeserieActions } from 'com.batch/profilebase/usecases/fetch-reachability-timeserie'
import { STATUS } from 'constants/common'

const initialState = ReachabilityTimeserieStateFactory()

export function smsReachabilityReducer(
  state: ReachabilityTimeserieStateRecord = initialState,
  action: FetchSmsReachabilityTimeserieActions
): ReachabilityTimeserieStateRecord {
  switch (action.type) {
    case 'FETCH_SMS_REACHABILITY':
      return state.set('loadingState', STATUS.LOADING)

    case 'FETCH_SMS_REACHABILITY_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .set('counter', action.payload.counter)
        .set('timeserie', action.payload.timeserie)

    case 'FETCH_SMS_REACHABILITY_FAILURE':
      return state.set('loadingState', STATUS.ERROR)

    default:
      return state
  }
}
