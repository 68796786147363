// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type DeleteCampaignAction = ReduxAction<'DELETE_CAMPAIGN', string>
export type DeleteCampaignSuccessAction = ReduxAction<'DELETE_CAMPAIGN_SUCCESS', string>
export type DeleteCampaignFailureAction = ReduxAction<
  'DELETE_CAMPAIGN_FAILURE',
  { error: string, token: string, ... },
>

export type DeleteCampaignActions =
  | DeleteCampaignAction
  | DeleteCampaignSuccessAction
  | DeleteCampaignFailureAction

export const deleteCampaign = (token: string): DispatchExtraBoundFn<Promise<any>> => {
  return (dispatch, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: orchestrationService.delete({ project, token }).then(
        () => token,
        error => {
          throw { token, error: error?.error?.errors?.[0]?.message ?? 'Unknown error' }
        }
      ),
      payload: token,
      actionName: 'DELETE_CAMPAIGN',
    })
  }
}
