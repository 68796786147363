// @flow
import styled, { css, type StyledComponent, type CSSRules } from 'styled-components'

import { Button } from 'components/common/button'
import { DropdownContainer } from 'components/common/button/dropdown.styles'
import { colors } from 'components/styled/tokens'

type RemovableBlockWrapperProps = { forceOpen?: boolean, isRemovable: boolean, ... }
export const RemovableBlockWrapper: StyledComponent<RemovableBlockWrapperProps, { ... }, *> =
  styled.div`
    cursor: pointer;
    position: relative;
    .styled-remove {
      opacity: ${(p: RemovableBlockWrapperProps) => (p.forceOpen ? 1 : 0)};
      top: 0;
      right: -38px;
      padding: 0 0 20px 26px;
      width: 60px;
      height: 60px;
      display: block;
      position: absolute;
      margin-left: 4px;
    }
    .styled-menu {
      opacity: ${(p: RemovableBlockWrapperProps) => (p.forceOpen ? 1 : 0)};
      top: 0;
      right: -50px;
      padding: 0 0 20px 26px;
      width: 70px;
      height: 60px;
      display: block;
      position: absolute;
      ${DropdownContainer} {
        padding: 0;
        width: 37px;
        margin: 0;
        overflow: hidden;
        height: 73px;
      }
      ${Button} {
        width: 36px;
        height: 36px;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid ${colors.stroke};
        &:last-child {
          border-bottom: none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          display: block;
        }
      }
    }
    ${(p: RemovableBlockWrapperProps) =>
      p?.isRemovable !== false &&
      css`
        &:hover {
          .styled-remove,
          .styled-menu {
            opacity: 1;
          }
        }
      `}
  `
export const BlockTitle: CSSRules = css`
  overflow: hidden;
  color: ${colors.text};
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-align: left;
`
