// @flow

import Immutable, { type Map } from 'immutable'
import { get as _get } from 'lodash-es'

import { dayjs } from 'com.batch.common/dayjs.custom'
import languages from 'com.batch.common/languages'
import regions from 'com.batch.common/regions'

import { type Install, type Profile, type ComputedNatives } from './models/profile.model'

import { LanguageFactory, RegionFactory } from 'com.batch.redux/_records'
import { parseNotif } from 'com.batch.redux/stat.api.debug'

import { type CustomEvent } from 'com.batch/profile/infra/debug/models/custom-event.model'
import {
  type RawAttributeValue,
  type QueryValue,
} from 'com.batch/profile/infra/debug/models/shared.model'
import { getPlatformName } from 'com.batch/profile/infra/formats/get-platform-name'
import {
  IdentityFactory,
  ProfileDataFactory,
  CustomEventFactory,
  LastVisitFactory,
  SubscriptionFactory,
  SubscriptionEmailFactory,
  SubscriptionSmsFactory,
  type CustomEventRecord,
  type ProfileDataRecord,
  InstallFactory,
  SubscriptionPushFactory,
} from 'com.batch/profile/models/profile.records'

export const parseEventToRecord = (event: CustomEvent): CustomEventRecord => {
  let customEvent = CustomEventFactory({
    name: event.name.name,
    label: event.label,
    attributes: Object.keys(event.richAttributes || {}).reduce(
      (cAttr: Map<string, QueryValue>, caName) => {
        const queryValue = event.richAttributes[caName]
        return cAttr.set(caName, parseAttributeValueToQueryValue(queryValue))
      },
      Immutable.Map()
    ),
    tags: Immutable.Map(),
    sendDate: event.sendDate,
    source: event.source === 'TRIGGER_EVENTS_API' ? 'API' : event.source,
  })

  if (event.source !== 'PROFILE_API' && event.apiKey.key && event.apiKey.platform !== 'WINDOWS') {
    customEvent = customEvent.set('platform', event.apiKey.platform)
  }

  return customEvent
}

export const parseAttributeValueToQueryValue = (attributeValue: RawAttributeValue): QueryValue => {
  if (attributeValue.valueAttr) {
    return {
      type: attributeValue.valueAttr.type,
      stringValue: attributeValue.valueAttr.stringValue,
      boolValue: attributeValue.valueAttr.boolValue,
      longValue:
        attributeValue.valueAttr?.type === 'LONG'
          ? parseInt(attributeValue.valueAttr.longValue, 10)
          : undefined,
      doubleValue:
        attributeValue.valueAttr?.type === 'DOUBLE'
          ? parseFloat(attributeValue.valueAttr.doubleValue)
          : undefined,
      versionValue: attributeValue.valueAttr?.versionValue,
      urlValue: attributeValue.valueAttr?.urlValue,
      dateValue:
        attributeValue.valueAttr?.type === 'DATE' && attributeValue.valueAttr?.dateValue
          ? dayjs.utc(attributeValue.valueAttr.dateValue)
          : undefined,
    }
  }
  const objectV = attributeValue.objectAttr?.objectV
  if (objectV) {
    const obj: { [string]: QueryValue } = {}
    Object.keys(objectV).map(key => {
      const attr = objectV[key]
      obj[key] = parseAttributeValueToQueryValue(attr)
    })
    return {
      type: 'OBJECT',
      objectValue: obj,
    }
  }
  return {
    type: 'ARRAY',
    arrayValue: attributeValue.arrayAttr?.arrayV.map(av => parseAttributeValueToQueryValue(av)),
  }
}

export const parseProfileModelToRecord = (
  profile: Profile,
  computedNatives: ComputedNatives,
  installs: Array<Install>
): ProfileDataRecord => {
  let pushSubscriptionStatus = 'UNSUBSCRIBED'

  let installsMap = profile.identifiersWithData?.reduce((acc, current) => {
    const currentInstall = current.identifier.install
    if (currentInstall) {
      const device = installs.find(f => f.installID.installId === currentInstall.installId)
      const recipient = profile.recipientsWithData?.find(
        f => f.recipient.pushToken?.install.installId === currentInstall.installId
      )

      const type =
        currentInstall?.apiKey.platform === 'WEBPUSH'
          ? 'Web'
          : device?.deviceType ?? getPlatformName(currentInstall.apiKey.platform)
      const notifType = recipient?.data.pushTokenData.subscription.notifType.value
        ? parseNotif(recipient?.data.pushTokenData.subscription.notifType.value.toString())
        : []
      const subscriptionStatus =
        currentInstall?.apiKey.platform === 'WEBPUSH'
          ? 'UNKNOWN'
          : recipient?.recipient.pushToken?.pushToken && notifType.length > 0
            ? 'SUBSCRIBED'
            : 'UNSUBSCRIBED'

      if (subscriptionStatus === 'SUBSCRIBED') pushSubscriptionStatus = 'SUBSCRIBED'

      return acc.set(
        currentInstall.installId,
        InstallFactory({
          type,
          marketingName: device?.deviceMarketingName ?? '',
          installID: currentInstall.installId,
          pushToken: recipient?.recipient.pushToken?.pushToken ?? '',
          platform: currentInstall?.apiKey.platform,
          lastActivity: current.data.lastActivity ?? '',
          subscriptionStatus,
          notifType,
        })
      )
    }

    return acc
  }, Immutable.Map())

  return ProfileDataFactory({
    id: profile.id.data,
    identity: IdentityFactory({
      customUserId: profile.identifiers.customID,
      region: RegionFactory({
        value: computedNatives.profileRegion,
        label: _get(regions, computedNatives.profileRegion, computedNatives.profileRegion),
      }),
      language: LanguageFactory({
        value: computedNatives.profileLanguage,
        label: _get(languages, computedNatives.profileLanguage, computedNatives.profileLanguage),
      }),
    }),
    email: profile.recipients.email,
    phoneNumber: profile.recipients.phoneNumber,
    lastVisit: LastVisitFactory({
      platform: profile.lastVisitPlatform,
      date: profile.lastVisitDate,
    }),
    lastActivity: profile.lastActivity ?? '',
    subscription: SubscriptionFactory({
      emailMarketing: SubscriptionEmailFactory({
        status: profile.subscriptionStates?.marketing,
        lastEmailOpened: profile.lastEmailMarketingOpen,
        lastEmailClicked: profile.lastEmailMarketingClick,
      }),
      emailService: SubscriptionEmailFactory({
        status: profile.subscriptionStates?.transactional,
        lastEmailOpened: profile.lastEmailTransactionalOpen,
        lastEmailClicked: profile.lastEmailTransactionalClick,
      }),
      smsMarketing: SubscriptionSmsFactory({
        status: profile.smsSubscriptionStates?.marketing,
      }),
      push: SubscriptionPushFactory({
        status: pushSubscriptionStatus,
      }),
    }),
    installs: installsMap,
    attributes: Object.keys(profile.profileAttributes || {}).reduce(
      (attr: Map<string, QueryValue>, name) => {
        const profileAttribute = profile.profileAttributes[name]
        return attr.set(name, parseAttributeValueToQueryValue(profileAttribute))
      },
      Immutable.Map()
    ),
    timezone: !computedNatives.profileTimezone.includes('GMT')
      ? computedNatives.profileTimezone
      : null,
  })
}
