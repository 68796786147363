// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

type EstimateContainerProps = { $withSeparator: boolean, ... }
export const EstimateContainer: StyledComponent<EstimateContainerProps, { ... }, HTMLDivElement> =
  styled.div`
    ${(p: EstimateContainerProps) =>
      p.$withSeparator
        ? css`
            border-top: 1px solid ${colors.stroke};
          `
        : ''}

    padding: 20px;
  `
export const EstimateTitle: StyledComponent<{ ... }, { ... }, HTMLHeadingElement> = styled.h3`
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 4px;
`
type EstimateCountProps = { $warn: boolean, ... }
export const EstimateCount: StyledComponent<EstimateCountProps, { ... }, HTMLDivElement> =
  styled.div`
    color: ${({ $warn }: EstimateCountProps) => ($warn ? colors.textWarning : 'currentColor')};
    display: inline-block;
    .styled-matching {
      font-size: 1.4em;
    }
    .styled-total {
      font-size: 1em;
      color: ${({ $warn }: EstimateCountProps) => ($warn ? colors.textWarning : colors.textLight)};
    }
    .styled-main {
      padding-bottom: 4px;
      i {
        margin-bottom: -2px;
      }
    }
    .styled-sub {
      display: flex;
    }
    .styled-channel {
      display: grid;
      grid-template-columns: 18px 1fr;
      align-items: center;
      margin-right: 8px;
    }
  `
