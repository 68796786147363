import Immutable from 'immutable'
import { of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, filter, flatMap, switchMap, takeUntil } from 'rxjs/operators' // eslint-disable-line no-unused-vars

import { TemplateResultFactory } from 'com.batch.redux/_records'
import { UPDATE_PUSH_WHEN } from 'com.batch.redux/action-name'
import { currentCampaign, currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { TARGETING_QUERY_CHANGE } from 'com.batch.redux/targeting'
import { parseTemplateFailed, parseTemplateSucceeed } from 'com.batch.redux/template'

const parseTemplateOn = ['PARSE_TEMPLATE', TARGETING_QUERY_CHANGE]

const SEPARATOR = '___|||||___'
// ────────────────────────────────────────────────────────────────────────────────
// watches for action that shall trigger an XHR for template parsing
// ────────────────────────────────────────────────────────────────────────────────
/*
    What we want :
      - we watches for changes in the templateForFields map
      - we watches for changes in the query
    We will concat all templates in one string, and
      - on template change, we query with pinnedInstallIds (if avail.)
      - on query change, we query without pinnedInstallIds & update accordingly pinned and selected
*/
export const templateEpic = (action$, state$) => {
  return action$.pipe(
    filter(
      action =>
        parseTemplateOn.indexOf(action.type) !== -1 ||
        (action.type === UPDATE_PUSH_WHEN && action.payload.what === 'triggerConfig')
    ),
    debounceTime(500),
    switchMap(action => {
      const resetIds = action.type === TARGETING_QUERY_CHANGE || action.type === UPDATE_PUSH_WHEN
      const st = state$.value
      if (st.template.keyForField.size === 0) {
        return of({
          type: 'NOOP',
          payload: null,
        })
      }
      const concatenedValues = st.template.keyForField
        .toArray()
        .map(arr => arr[1])
        .join(SEPARATOR)
      const keys = st.template.keyForField.toArray().map(arr => arr[1])
      const editingCampaign = currentCampaign(st)
      const omnichannelApps = currentCampaignAppsSelector(st)
      const appId = st.app.current.id !== 0 ? st.app.current.id : omnichannelApps.first()?.id ?? 0
      return ajax({
        url: `/api/app/${appId}/push-campaign/template`,
        body: JSON.stringify({
          template: concatenedValues,
          event:
            editingCampaign.sendType === 'trigger' ? editingCampaign.triggerConfig.enterEvent : '',
          query: st.targeting.get('query'),
          installIds:
            !resetIds && st.template.pinnedInstallIds ? st.template.pinnedInstallIds.toArray() : [],
        }),
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).pipe(
        flatMap(e => {
          const resp = e.response
          if (resp === null) {
            throw 'Unable to parse template : API unavailable'
          }
          const dispatches = []
          keys.map((text, index) => {
            let results = new Immutable.List()
            if (typeof resp.installs !== 'undefined' && Array.isArray(resp.installs)) {
              resp.installs.forEach(row => {
                results = results.push(
                  TemplateResultFactory({
                    installId: row.install_id,
                    result: row.result.split(SEPARATOR)[index],
                  })
                )
              })
            } else if (
              typeof resp.found_installs !== 'undefined' &&
              Array.isArray(resp.found_installs)
            ) {
              resp.found_installs.forEach(row => {
                results = results.push(
                  TemplateResultFactory({
                    installId: row.install_id,
                    result: row.result.split(SEPARATOR)[index],
                  })
                )
              })
            } else if (typeof resp.only_values !== 'undefined') {
              results = results.push(
                TemplateResultFactory({
                  installId: '',
                  result: resp.only_values.result.split(SEPARATOR)[index],
                })
              )
            }
            dispatches.push(parseTemplateSucceeed(text, results))
          })

          return dispatches
        }),
        takeUntil(action$.ofType('PARSE_TEMPLATE')), // aborts the XHR on new request
        catchError(error => {
          let cleanError = error.toString()
          if (typeof error === 'object' && error.hasOwnProperty('xhr')) {
            cleanError = error.xhr.response?.message ?? error.xhr.response?.error
          }
          if (action.type === TARGETING_QUERY_CHANGE) {
            return keys.map(k => parseTemplateFailed(k, cleanError))
          } else {
            return [parseTemplateFailed(action.payload.text, cleanError)]
          }
        })
      )
    })
  )
}
