// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { ResetApiKeyModal } from 'components/account/company/reset-api-key-modal'
import { PermissionButton } from 'components/common/button'
import { HideableCode } from 'components/common/code/hideable-code'
import { confirm } from 'components/common/confirm'
import { ConfirmWarning } from 'components/common/confirm.styles'
import { Icon } from 'components/common/svg-icon'
import { Form, InputWrapper, Input, FormActions, Checkbox } from 'components/form'
import { LinkArrow } from 'components/styled/text'

import { Title, AccountSection, SectionTitle, SectionInfo, AccountGrid } from '../account.styles'
import { currentCompanySelector, updateCompany } from 'com.batch.redux/company'

export const CompanySettings = (): React.Node => {
  const resetApiKeyModalState = useToggle()
  const company = useSelector(currentCompanySelector)
  const dispatch = useDispatch()
  const [name, setName] = React.useState<string>(company.name)
  const [enforce2FA, setEnforce2FA] = React.useState<boolean>(company.enforce2FA)
  const [loadingName, setLoadingName] = React.useState<boolean>(false)
  const [loading2FA, setLoading2FA] = React.useState<boolean>(false)
  const [apiKeyRevealed, setApiKeyRevealed] = React.useState<boolean>(false)

  const [err, setErr] = React.useState<string>('')
  const onSubmit = React.useCallback(() => {
    if (name.trim() === '') {
      setErr('Please provide a company name')
    } else {
      setErr('')
      setLoadingName(true)
      dispatch(updateCompany(company.set('name', name.trim()))).then(
        () => setLoadingName(false),
        () => setLoadingName(false)
      )
    }
  }, [company, dispatch, name])
  const actualSecuritySave = React.useCallback(() => {
    setLoading2FA(true)
    dispatch(updateCompany(company.set('enforce2FA', enforce2FA))).then(
      () => setLoading2FA(false),
      () => setLoading2FA(false)
    )
  }, [dispatch, company, enforce2FA])
  const onSubmitSecurity = React.useCallback(() => {
    if (enforce2FA) {
      confirm({
        title: 'Require 2FA to everyone in the company?',
        sensitive: true,
        message: (
          <React.Fragment>
            <p>
              Everyone in your company will be prompted to set up 2FA on login or during their use
              of the Batch dashboard.
            </p>
            <ConfirmWarning>
              Please be aware that any unsaved data or changes may be lost.
            </ConfirmWarning>
          </React.Fragment>
        ),
        confirm: 'Yes, require it',
      }).then(actualSecuritySave, () => {})
    } else {
      actualSecuritySave()
    }
  }, [actualSecuritySave, enforce2FA])

  const handleOnResetApiKey = React.useCallback(() => {
    setApiKeyRevealed(true)
    setTimeout(() => {
      setApiKeyRevealed(false)
    }, 200)
  }, [setApiKeyRevealed])

  const isAllowedToAdministrate = useIsCurrentUserAllowedTo(['company', 'administrate'])
  const isAllowedToSecurity = useIsCurrentUserAllowedTo(['company', 'security'])
  const onNameChange = React.useCallback(evt => setName(evt.target.value), [])
  const onToggle2FA = React.useCallback(() => setEnforce2FA(!enforce2FA), [enforce2FA])
  return (
    <AccountGrid>
      <Title>Company settings</Title>
      <AccountSection>
        <SectionInfo>
          <SectionTitle>Information</SectionTitle>
        </SectionInfo>
        <p>Company Name</p>
        <Form onSubmit={onSubmit} horizontal>
          <InputWrapper feedback={err}>
            <Input invalid={!!err} value={name} onChange={onNameChange} />
          </InputWrapper>
          <FormActions>
            <PermissionButton
              kind="primary"
              intent="action"
              type="submit"
              isLoading={loadingName}
              disabled={company.name === name}
              isAllowed={isAllowedToAdministrate}
            >
              Update
            </PermissionButton>
          </FormActions>
        </Form>
      </AccountSection>
      {!company.saml?.isEnabled && (
        <AccountSection>
          <SectionInfo>
            <SectionTitle>Security</SectionTitle>
            <p>Enhance security and increase protection for your users and your company.</p>
          </SectionInfo>
          <p>Two-factor authentication</p>
          <Form onSubmit={onSubmitSecurity} horizontal>
            <InputWrapper
              additional={
                <LinkArrow
                  style={{ margin: '-8px 0 0 28px' }}
                  intent="action"
                  target="_blank"
                  href="https://doc.batch.com/dashboard/settings/account-settings#two-factor-authentication-2fa"
                >
                  Learn more about 2FA
                </LinkArrow>
              }
            >
              <Checkbox
                label="Require two-factor authentication (2FA) to access Batch for everyone in the company"
                checked={enforce2FA}
                onChange={onToggle2FA}
              />
            </InputWrapper>
            <FormActions>
              <PermissionButton
                kind="primary"
                intent="action"
                type="submit"
                isLoading={loading2FA}
                disabled={company.enforce2FA === enforce2FA}
                isAllowed={isAllowedToSecurity}
              >
                Update
              </PermissionButton>
            </FormActions>
          </Form>
        </AccountSection>
      )}
      <AccountSection>
        <SectionInfo>
          <SectionTitle>APIs</SectionTitle>
          <p>
            The REST API key authenticates the calls made to Batch APIs targeting your apps and
            websites API keys.
          </p>
          <p style={{ marginTop: 15 }}>
            It is sensitive information as it enables you to send data and messages from Batch APIs.
            Do not expose it to your users and avoid sharing it within your organization.
          </p>
        </SectionInfo>
        <p>REST API Key</p>
        <Form horizontal>
          <InputWrapper>
            <div style={{ width: 313 }}>
              <HideableCode reveal={apiKeyRevealed}>
                {company.restKey || '**** Insufficient privileges ****'}
              </HideableCode>
            </div>
          </InputWrapper>
          <FormActions>
            <PermissionButton
              type="button"
              kind="secondary"
              intent="danger"
              addOn="prefix"
              isAllowed={isAllowedToAdministrate}
              onClick={resetApiKeyModalState.open}
            >
              <Icon icon="danger" />
              Reset REST API key
            </PermissionButton>
            <ResetApiKeyModal state={resetApiKeyModalState} onReset={handleOnResetApiKey} />
          </FormActions>
        </Form>
      </AccountSection>
    </AccountGrid>
  )
}
