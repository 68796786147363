// @flow

import setTimeout from 'core-js/features/set-timeout'
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useClickOutsideOnExistingRefs } from 'components/_hooks'
import { Button, ButtonLink, useBlurOnClickCallback } from 'components/common/button'
import { FilterSearch, FilterSelect } from 'components/filter'
import { Header, HeaderTitle, HeaderActions } from 'components/styled/blocs'
import { Title } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'

import DebugTransac from './debug-transac'
import { DebugUser } from './debug-user'

import { type State, type AppRecord } from 'com.batch.redux/_records'
import { queryUser, fetchRecentDevices } from 'com.batch.redux/stat'
import * as apiDebug from 'com.batch.redux/stat.api.debug'
import { DebugTransacFactory, type DebugTransacRecord } from 'com.batch.redux/stat.records'

import { ProfileSearchContainer } from 'com.batch/profile/ui/components/profile-search/profile-search.styles'

type DebugProps = {
  tab: 'user' | 'transac',
  app: AppRecord,
  query: string,
  mode: 'advertising_id' | 'custom_id' | 'installation_id',
  ...
}

type IDOptionType = 'advertising_id' | 'custom_id' | 'installation_id'
type IDOption = { label: string, type: IDOptionType }

const installIdsSelector = (state: State) => state.stat.userDebug.recentInstallationIds

export const Debug = ({ tab, app, query, mode }: DebugProps): React.Node => {
  // ===================== LIBS HOOKS
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // ===================== LOCAL STATE
  const [firstRun, setFirstRun] = React.useState(true)
  const [transac, setTransac] = React.useState<DebugTransacRecord>(DebugTransacFactory())
  const [focusSearch, setFocusSearch] = React.useState(true)
  const [hasSelectedFilter, setHasSelectedFilter] = React.useState(false)

  const debugSearchRef = React.useRef(null)
  const searchRef = React.useRef(null)

  // ===================== REDUX STATE
  const installIds = useSelector(installIdsSelector)

  // ====================== MEMO
  const transacUrl = React.useMemo(
    () =>
      generateUrl('app_debug', {
        companyId: app.companyId,
        debugTab: 'transactional',
        appId: app.id,
      }),
    [app.companyId, app.id]
  )
  const userUrl = React.useMemo(
    () =>
      generateUrl('app_debug', {
        companyId: app.companyId,
        appId: app.id,
      }),
    [app.companyId, app.id]
  )

  // ====================== CALLBACKS
  const gotoTransac = useBlurOnClickCallback(
    () => {
      navigate(transacUrl)
    },
    [navigate, transacUrl],
    true
  )
  const gotoUser = useBlurOnClickCallback(
    () => {
      navigate(userUrl)
    },
    [navigate, userUrl],
    true
  )
  const updateUrl = React.useCallback(
    ({
      query,
      tab,
      mode,
    }: {
      query: string,
      tab: 'user' | 'transac',
      mode: 'advertising_id' | 'custom_id' | 'installation_id',
      ...
    }) => {
      navigate({
        search: `?${tab === 'user' ? mode : 'response_token'}=${encodeURIComponent(query.trim())}`,
      })
    },
    [navigate]
  )

  const launchSearchWhenPossible = React.useCallback(() => {
    if (query) {
      if (tab === 'user') {
        dispatch(
          queryUser({
            appId: app.id,
            mode: mode,
            query: query,
          })
        )
      }
      if (tab === 'transac') {
        let requestId = query.trim()
        setTransac(transac.set('loading', true).set('error', false))

        apiDebug
          .queryTransacRequestId({ appId: app.id, requestId })
          .then(res => {
            setTransac(
              transac
                .set('loading', false)
                .set('query', res.requestId)
                .set('results', apiDebug.nomalizeDebugTransac(res.results))
            )
          })
          .catch(err => setTransac(transac.set('loading', false).set('error', err.payload)))
      }
    }
  }, [app, dispatch, mode, query, tab, transac])

  const refreshInstall = React.useCallback(
    (installId: string) => {
      dispatch(
        queryUser({
          appId: app.id,
          mode: 'installation_id',
          query: installId,
          refresh: true,
        })
      )
    },
    [app.id, dispatch]
  )

  const onSearchBlur = React.useCallback(() => {
    setTimeout(() => {
      if (!query && focusSearch) {
        setFocusSearch(false)
      }
    }, 0)
  }, [query, focusSearch])

  const onSelectBlur = React.useCallback(() => {
    setFocusSearch(true)
  }, [])

  const onFocusSelect = React.useCallback(() => {
    setFocusSearch(true)
  }, [])

  const onChangeSelect = React.useCallback(
    (opt: ?IDOption) => {
      const filter = opt && opt.type
      setFocusSearch(true)
      setHasSelectedFilter(true)
      searchRef.current?.focus()
      if (filter) {
        updateUrl({ query, tab, mode: filter })
        setHasSelectedFilter(true)
      }
    },
    [searchRef, query, tab, updateUrl]
  )

  const optionFormatter = React.useCallback((opt, { context }) => {
    if (context === 'value') return `by ${opt.label}`
    return opt.label
  }, [])

  const onFocusSearch = React.useCallback(() => {
    setTimeout(() => setFocusSearch(true), 0)
  }, [])

  const onBlurSearch = React.useCallback(() => {
    setTimeout(() => setFocusSearch(false), 0)
  }, [])

  // ===================== EFFECTS
  React.useEffect(() => {
    if (firstRun && installIds.length === 0) {
      dispatch(fetchRecentDevices())
      // launch initial search when avail
      launchSearchWhenPossible()
      setFirstRun(false)
    }
  }, [dispatch, firstRun, installIds.length, launchSearchWhenPossible])

  React.useEffect(() => {
    if (!query && !mode && focusSearch) {
      searchRef.current?.focus()
    }
  }, [mode, query, focusSearch])

  // ===================== DERIVED

  const IDOptions: List<IDOption> = React.useMemo(
    () =>
      new Immutable.List().push(
        ...[
          ...[
            { label: 'Custom user ID', type: 'custom_id' },
            { label: 'Installation ID', type: 'installation_id' },
          ],
          ...(app.platform !== 'webpush'
            ? [{ label: 'Advertising ID', type: 'advertising_id' }]
            : []),
        ]
      ),
    [app.platform]
  )

  const selectedValue = React.useMemo(() => IDOptions.find(o => o.type === mode), [IDOptions, mode])
  useClickOutsideOnExistingRefs(onSearchBlur, [debugSearchRef])

  const onFilterSearchChange = React.useCallback(
    v => updateUrl({ query: v, tab, mode }),
    [updateUrl, tab, mode]
  )

  const onSearchSubmit = React.useCallback(
    e => {
      e.preventDefault()
      launchSearchWhenPossible()
    },
    [launchSearchWhenPossible]
  )

  const filterOptionToString = React.useCallback(opt => opt?.label ?? '', [])

  return (
    <React.Fragment>
      <Header style={{ alignItems: 'flex-start' }}>
        <HeaderTitle style={{ display: 'inline-flex' }}>
          <Title style={{ lineHeight: '28px' }}>Debug</Title>
          <ButtonLink
            kind="inline"
            intent="neutral"
            isActive={tab === 'user'}
            onClick={gotoUser}
            href={userUrl}
            style={{ marginRight: 10 }}
          >
            Devices
          </ButtonLink>
          <ButtonLink
            isActive={tab === 'transac'}
            kind="inline"
            onClick={gotoTransac}
            href={transacUrl}
          >
            Transactional
          </ButtonLink>
        </HeaderTitle>
        <HeaderActions grow>
          {tab === 'user' ? (
            <ProfileSearchContainer
              ref={debugSearchRef}
              isEmpty={!query && !focusSearch}
              as="form"
              onSubmit={onSearchSubmit}
            >
              <FilterSearch
                ref={searchRef}
                placeholder="Search devices"
                value={query}
                isSensitive
                isFocusedFilter={focusSearch}
                onChange={onFilterSearchChange}
                autoFocus={query === ''}
                width={firstRun || focusSearch ? 344 : 110}
                expandedMaxWidth={firstRun || focusSearch ? 344 : 110}
                onFocus={onFocusSearch}
              />
              {focusSearch && (
                <FilterSelect
                  isSearchable={false}
                  isClearable={false}
                  value={selectedValue ? selectedValue : null}
                  onChange={onChangeSelect}
                  options={IDOptions}
                  optionFormatter={optionFormatter}
                  optionToString={filterOptionToString}
                  style={
                    query || hasSelectedFilter
                      ? { marginLeft: 8, color: colors.text }
                      : { marginLeft: 8 }
                  }
                  onBlur={onSelectBlur}
                  onFocus={onFocusSelect}
                />
              )}
            </ProfileSearchContainer>
          ) : (
            <div style={{ maxWidth: 400 }}>
              <FilterSearch
                placeholder="Search by transactional API response token "
                value={query}
                isSensitive
                onChange={onFilterSearchChange}
                autoFocus={query === ''}
                expandable
                width={firstRun || focusSearch ? 344 : 96}
                expandedMaxWidth={firstRun || focusSearch ? 344 : 96}
                onFocus={onFocusSearch}
                onBlur={onBlurSearch}
              />
            </div>
          )}

          <Button
            type="submit"
            kind="primary"
            intent="action"
            style={{ marginLeft: 12 }}
            onClick={onSearchSubmit}
          >
            Debug
          </Button>
        </HeaderActions>
      </Header>

      <div style={{ minHeight: '356px' }}>
        {tab === 'user' ? (
          <DebugUser refresh={refreshInstall} app={app} mode={mode} query={query} />
        ) : (
          <DebugTransac app={app} rec={transac} />
        )}
      </div>
    </React.Fragment>
  )
}
