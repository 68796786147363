// ────────────────────────────────────────────────────────────────────────────────
// SUBSCRIBES TO ACTIONS, AND TRIGGERS NEW ACTIONS
// ────────────────────────────────────────────────────────────────────────────────
import Immutable from 'immutable'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import {
  flatMap,
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'

import { CampaignFactory, CustomAudienceFactory } from 'com.batch.redux/_records'
import { allAttrFilteredSelector } from 'com.batch.redux/attribute'
import { matchAudienceType } from 'com.batch.redux/audience.api'
import {
  updateCondition,
  TARGETING_ADD_CLUSTER,
  TARGETING_ADD_CONDITION,
  TARGETING_ESTIMATE_BUSY,
  TARGETING_ESTIMATE_LOADING,
  TARGETING_INIT,
  TARGETING_QUERY_CHANGE,
  TARGETING_REMOVE_CLUSTER,
  TARGETING_REMOVE_CONDITION,
  TARGETING_REMOVE_LOGICAL,
  TARGETING_RETRY,
  TARGETING_TOGGLE_CONDITION,
  TARGETING_TOGGLE_LOGICAL,
  TARGETING_UPDATE_CONDITION,
  TARGETING_UPD_AUDIENCE,
  TARGETING_UPD_LANGUAGE,
  TARGETING_UPD_REGION,
  TARGETING_VALIDATE_CONDITION,
  conditionValidationAction,
  estimateBusyAction,
  estimateFailureAction,
  estimateLoadingAction,
  estimateSucceessAction,
  queryChangeAction,
  queryParseAction,
} from 'com.batch.redux/targeting'
import api from 'com.batch.redux/targeting.api'

const refreshEstimateOn = [
  TARGETING_INIT,
  TARGETING_ADD_CLUSTER,
  TARGETING_REMOVE_CLUSTER,
  TARGETING_QUERY_CHANGE,
  TARGETING_UPD_AUDIENCE,
  TARGETING_UPD_REGION,
  TARGETING_UPD_LANGUAGE,
  TARGETING_ESTIMATE_BUSY,
  TARGETING_RETRY,
]
const validateConditionOn = [TARGETING_UPDATE_CONDITION, TARGETING_ADD_CONDITION]

const parseQueryOn = ['FETCH_ATTRIBUTES_VALUES_SUCCESS', TARGETING_INIT, 'SET_CAMPAIGN_TEMPLATE']

const buildQueryOn = [
  TARGETING_TOGGLE_CONDITION,
  TARGETING_VALIDATE_CONDITION,
  TARGETING_REMOVE_LOGICAL,
  TARGETING_REMOVE_CONDITION,
  TARGETING_TOGGLE_LOGICAL,
]

// ────────────────────────────────────────────────────────────────────────────────
// watches for action that shall trigger an estimate
export const estimateEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => refreshEstimateOn.indexOf(action.type) !== -1),
    debounceTime(500),
    switchMap(() => {
      const st = state$.value
      return merge(
        of(estimateLoadingAction()),
        ajax({
          url: `/api/app/${st.app.getIn(['current', 'id'])}/data/pushtokens`,
          body: JSON.stringify({
            clusters: st.targeting.get('clusters').toJS(),
            regions: st.targeting.get('regions').toJS(),
            customAudiencesOperator: 'UNION',
            customAudiences: st.campaign.entities
              .get(st.campaign.config.editing, CampaignFactory())
              .customAudiences.map(aud => [aud.name, aud.type]),
            languages: st.targeting.get('languages').toJS(),
            query: st.targeting.get('query'),
            forInApp: st.campaign.getIn(['config', 'type']) === 'in-app',
          }),
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }).pipe(
          map(e => {
            const response = e.response
            if (!Object.prototype.hasOwnProperty.call(response, 'results')) {
              return estimateFailureAction('Unable to estimate')
            }
            if (
              Object.prototype.hasOwnProperty.call(response.results, 'error') &&
              response.results.error === 'Estimation busy, please wait'
            ) {
              return estimateBusyAction()
            } else {
              return estimateSucceessAction(response.results)
            }
          }),
          takeUntil(action$.pipe(ofType(TARGETING_ESTIMATE_LOADING))), // aborts the XHR on new request
          catchError(error => of(estimateFailureAction(error)))
        )
      )
    })
  )
}

// ────────────────────────────────────────────────────────────────────────────────
// watches for action that shall trigger a recompute of the valid state of a condition
export const validateConditionEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => validateConditionOn.indexOf(action.type) !== -1),
    delay(0),
    mergeMap(action => {
      const condition = state$.value.targeting.getIn(
        ['conditions', action.payload.id],
        Immutable.Map()
      )
      const valid = api.validateCondition(condition)
      return of(conditionValidationAction({ id: action.payload.id, valid }))
    })
  )
}

// ────────────────────────────────────────────────────────────────────────────────
// watches for actions that might change the json query, and trigger a recompute
export const buildQueryEpic = (action$, state$) =>
  action$.pipe(
    filter(action => buildQueryOn.indexOf(action.type) !== -1),
    mergeMap(() => {
      const st = state$.value
      const res = queryChangeAction(
        api.buildQuery(st.targeting.get('tree'), st.targeting.get('conditions'))
      )
      return of(res)
    })
  )
// ────────────────────────────────────────────────────────────────────────────────
// watches for actions when we might have the required data to parse the query,
// checks the data is indeed there and the parsing is required,
// and then triggers a parse
// (could probably be way better with a smart combinaison rx style)
export const parseQueryEpic = (action$, state$) =>
  action$.pipe(
    filter(action => parseQueryOn.indexOf(action.type) !== -1),
    delay(0),
    mergeMap(() => {
      const curstate = state$.value
      const targeting = curstate.targeting
      const attributes = allAttrFilteredSelector(curstate)
      const values = curstate.attrValue
      return of(
        queryParseAction({
          awaits: targeting.get('awaitingParse', false) && curstate.attribute.config.valuesLoaded,
          attributes,
          values,
          query: targeting.get('query'),
        })
      )
    })
  )
// returns an array of audience code wich are "patial" and need to check for more data
function extractPartialAudiencesId(conditionsMap) {
  return conditionsMap
    .filter(c => c.getIn(['attribute', 'id']) === 'bt.custom_audiences')
    .reduce((prev, curr) => {
      const filteredIds = curr
        .get('value')
        .filter(audience => audience.partial)
        .toJS()
        .map(audience => audience.id)
      return prev.concat(filteredIds)
    }, [])
}
// listen to TARGETING_PARSE actions, and trigger an audience load if we got partial audience (incomplete data)
export const loadRealAudiencesEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      // discard other actions
      if (action.type !== 'TARGETING_PARSE') {
        return false
      }
      // check we got codes to fetch
      const codes = extractPartialAudiencesId(action.payload.conditions)
      return codes.length > 0
    }),
    switchMap(action => {
      const codes = extractPartialAudiencesId(action.payload.conditions)
      const st = state$.value
      // gather all needed audiences
      return ajax({
        url: `/api/app/${st.app.current.id}/custom-audiences-by-code`,
        body: JSON.stringify({ codes }),
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).pipe(
        flatMap(e => {
          const resp = e.response
          // builds a map of fetched audiences
          let fullAudiencesMap = Immutable.Map()
          resp.forEach(raw => {
            fullAudiencesMap = fullAudiencesMap.set(
              raw.name,
              CustomAudienceFactory({
                id: raw.name,
                name: raw.name,
                description: raw.description,
                deleted: false,
                type: matchAudienceType(raw.type),
              })
            )
          })
          // convert conditions Map to dispatch map that will dispatch an update action setting the fetched value
          const dispatchesMap = action.payload.conditions
            .filter(cond => {
              return (
                // only condition concerning audiences
                cond.getIn(['attribute', 'id']) === 'bt.custom_audiences' &&
                // with at least a value with "partial" flag
                cond.get('value', Immutable.Set()).filter(audience => audience.partial).size > 0
              )
            })
            .map((cond, id) => {
              return updateCondition({
                id,
                changes: {
                  what: 'value',
                  value: cond.get('value').map(audience => {
                    if (audience.partial && fullAudiencesMap.get(audience.id, false)) {
                      return fullAudiencesMap.get(audience.id)
                    } else {
                      return audience
                    }
                  }),
                },
              })
            })
          return dispatchesMap.toArray().map(kv => kv[1])
        })
      )
    })
  )
}
