// @flow

import styled, { type StyledComponent, type PropsWithTheme } from 'styled-components'

import { colors, schemes, shadows } from 'components/styled/tokens'

type ThemeProps = { disabledMode?: boolean, ... }
type TimingLabelProps = { isSelected: boolean, ... }
type ThemedTimingLabelProps = PropsWithTheme<TimingLabelProps, ThemeProps>
export const TimingLabel: StyledComponent<TimingLabelProps, ThemeProps, HTMLLabelElement> =
  styled.label`
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 78px;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    pointer-events: ${(p: ThemedTimingLabelProps) => (p.theme.disabledMode ? 'none' : 'unset')};
    background-color: ${(p: ThemedTimingLabelProps) =>
      p.isSelected ? `${colors.fill} !important` : schemes.grayscale['01']};
    box-shadow: ${(p: ThemedTimingLabelProps) =>
      p.isSelected
        ? `0 0 0 2px ${colors.strokeAction} !important`
        : `0 0 0 1px ${colors.stroke}, ${shadows.raised}`};

    label {
      flex: 1 1 auto;
      height: 78px;
      padding: 14px 18px;
    }

    &:hover {
      box-shadow:
        0 0 0 1px ${colors.strokeHover},
        ${shadows.raised};
    }

    svg {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  `
