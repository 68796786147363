// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { Button, useBlurOnClickCallback } from 'components/common/button'
import { DropdownContainer } from 'components/common/button/dropdown.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { percentage, kformat } from 'com.batch.common/utils'

import { RemovableBlockWrapper } from './common.styles'
import {
  IncompleteFlag,
  MessageBlockContainer,
  MessageBlockFrame,
  MessageBlockTitle,
  MessageTitle,
  StatPill,
} from './message-block.styles'

import { copyMessageNode } from '../../../usecases/copy-message-node'
import { NodeIconWrapper } from '../node-icon-wrapper'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { type PushMessageRawRecord } from 'com.batch/message/models/message.records'
import { StepStatsFactory } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { type NodeError } from 'com.batch/orchestration-journey/models/journey.records'
import { DisableQuietHours } from 'com.batch/orchestration-journey/ui/components/icons/disable-quiet-hours.svg'
import { PushPreviewWrapper } from 'com.batch/orchestration-journey/ui/components/nodes/push-preview-wrapper'
import {
  MessageBubble,
  UnsubMessage,
} from 'com.batch/sms/ui/components/sms-preview/sms-preview.styles'

export type MessageBlockProps = {
  content?: string,
  pushContent?: PushMessageRawRecord,
  onClick: () => void,
  hasQuietHours: boolean,
  hasGlobalQuietHours?: boolean,
  onRemove?: () => void,
  channel: ChannelUntilCleanup,
  errors: Set<NodeError>,
  placeholder: string,
  name: string,
  reduxNodeId: string,
  messageReference: string,
  editingNodeId?: string,
}

export const MessageBlock = ({
  onClick,
  content,
  pushContent,
  errors,
  placeholder,
  onRemove,
  channel,
  name,
  reduxNodeId,
  hasQuietHours,
  hasGlobalQuietHours,
  editingNodeId,
}: MessageBlockProps): React.Node => {
  const targetedUserbase = useSelector(subscriptionStatusSelector)

  const hasPreviewablePushContent = React.useMemo(
    () => pushContent?.pushTitle || pushContent?.pushBody,
    [pushContent]
  )

  const dispatch = useDispatch()

  const { id } = useSelector(orchestrationMetaSelector)
  const dataMap = useSelector(orchestrationAnalyticsMapSelector)
  const data = React.useMemo(() => {
    const steps = dataMap
      .get(id, OrchestrationAnalyticsFactory())
      .steps?.filter(s => s.step === reduxNodeId)
    return steps && steps.size > 0 ? steps.first().stats : StepStatsFactory()
  }, [dataMap, id, reduxNodeId])

  const iframePreviewUrl = React.useMemo(() => {
    if (!content) return

    const blobHtml = new Blob([content], {
      type: 'text/html',
    })
    return URL.createObjectURL(blobHtml)
  }, [content])

  const handleClick = useBlurOnClickCallback(onClick)

  const showDisabledQuietHours = React.useMemo(
    () => hasGlobalQuietHours && !hasQuietHours,
    [hasGlobalQuietHours, hasQuietHours]
  )
  const onCopy = React.useCallback(() => {
    dispatch(copyMessageNode(reduxNodeId))
  }, [dispatch, reduxNodeId])
  const isMarketing = React.useMemo(() => targetedUserbase === 'marketing', [targetedUserbase])

  const theme = useTheme()
  return (
    <RemovableBlockWrapper className="nodrag nopan" isRemovable={!theme?.disabledMode}>
      <MessageBlockContainer
        onClick={handleClick}
        data-testid={reduxNodeId}
        $hasError={false}
        $isEditing={editingNodeId === reduxNodeId}
      >
        {errors.includes('INCOMPLETE_MESSAGE') && (
          <IncompleteFlag>
            <Icon icon="edit" />
          </IncompleteFlag>
        )}
        <MessageBlockFrame
          $isShowingStat={!!theme?.viewStats}
          isEmpty={!content && !hasPreviewablePushContent && !theme?.viewStats}
          $channel={theme?.viewStats ? 'email' : channel}
          style={
            channel === 'sms' &&
            content &&
            !theme?.viewStats &&
            content.length > (isMarketing ? 110 : 130) &&
            !theme?.viewStats
              ? { alignItems: 'start', marginTop: 5 }
              : {}
          }
        >
          {theme?.viewStats ? (
            <div
              style={{
                height: '100%',
                background: '#FFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 260,
                  justifyContent: 'start',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <Tooltip tooltip={channel === 'push' ? 'Sent' : 'Delivered'}>
                  <StatPill variant="success">
                    <Icon icon="send" />
                    {kformat(data.delivered.value)}
                  </StatPill>
                </Tooltip>
                {channel !== 'sms' && (
                  <Tooltip tooltip="Opened">
                    <StatPill variant="action">
                      <Icon icon="fullscreen" size={18} />
                      {percentage(data.open.rate)}
                    </StatPill>
                  </Tooltip>
                )}
                {channel === 'email' && (
                  <Tooltip tooltip="Clicked">
                    <StatPill variant="feature">
                      <Icon icon="click" />
                      {percentage(data.click.rate)}
                    </StatPill>
                  </Tooltip>
                )}
                <Tooltip tooltip="Bounce">
                  <StatPill variant="warning">
                    <Icon icon="notfound" size={18} />
                    {kformat(data.bounce.value)}
                  </StatPill>
                </Tooltip>
                {channel !== 'sms' && (
                  <Tooltip tooltip="Unsubscribed">
                    <StatPill variant="danger">
                      <Icon icon="forbidden" size={18} />
                      {kformat(data.unsubscribe.value)}
                    </StatPill>
                  </Tooltip>
                )}
              </div>
            </div>
          ) : (
            <React.Fragment>
              {iframePreviewUrl && channel === 'email' && (
                <iframe
                  sandbox="allow-same-origin"
                  scrolling="no"
                  marginWidth="0"
                  marginHeight="0"
                  frameBorder="0"
                  src={iframePreviewUrl}
                />
              )}
              {content && channel === 'sms' && (
                <MessageBubble border style={{ width: 260, marginTop: 0 }}>
                  {content}
                  {isMarketing && (
                    <UnsubMessage style={{ paddingInline: 0 }}>STOP 36184</UnsubMessage>
                  )}
                </MessageBubble>
              )}
              {pushContent && hasPreviewablePushContent && channel === 'push' && (
                <PushPreviewWrapper content={pushContent} />
              )}
            </React.Fragment>
          )}
        </MessageBlockFrame>
        <MessageBlockTitle>
          <NodeIconWrapper
            kind={channel}
            addon={
              showDisabledQuietHours ? (
                <Tooltip tooltip="Quiet hours are disabled for this message">
                  <span>
                    <DisableQuietHours />
                  </span>
                </Tooltip>
              ) : undefined
            }
          >
            <MessageTitle $isFilled={!!name}>{name ? name : placeholder}</MessageTitle>
          </NodeIconWrapper>
        </MessageBlockTitle>
      </MessageBlockContainer>
      {!theme?.disabledMode && (
        <div className="nodrag nopan styled-menu">
          <DropdownContainer $isOpen $isScrollable={false}>
            <Tooltip tooltip="Duplicate" placement="right" offset={[0, 20]} minWidth={50}>
              <Button
                onClick={onCopy}
                kind="inline"
                tabIndex={-1}
                className="nodrag nopan"
                data-testid="button_copy_node"
              >
                <Icon icon="copy" />
              </Button>
            </Tooltip>
            <Tooltip tooltip="Remove" placement="right" offset={[0, 20]} minWidth={50}>
              <Button
                onClick={onRemove}
                tabIndex={-1}
                intent="danger"
                kind="inline"
                className="nodrag nopan"
                data-testid="button_remove_node"
              >
                <Icon icon="delete" />
              </Button>
            </Tooltip>
          </DropdownContainer>
        </div>
      )}
    </RemovableBlockWrapper>
  )
}
