// @flow

import { type Dayjs } from 'dayjs'
import Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeDayjs } from 'com.batch.common/utils'

import { type BounceDetailRecord } from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type PlotData } from 'com.batch/shared/infra/types/chart-data'

export const getDailyRange = (
  bounces: Immutable.Map<string, BounceDetailRecord>,
  daysBack: number
): PlotData[] => {
  const from = dayjs.utc().subtract(daysBack - 1, 'day')
  const dateRange = Array.from({ length: daysBack }, (_, index) => from.add(index, 'day'))
  let isFirst = true
  return dateRange.reduce((range, date) => {
    const bounce = bounces.get(date.format('YYYY-MM-DD'))
    let label = date.format('DD MMM')
    if (isFirst) {
      label = humanizeDayjs({ date, timeframe: true })
      if (!bounce) return range
    }
    isFirst = false
    if (date.isSame(dayjs.utc(), 'day')) {
      label = humanizeDayjs({ date, timeframe: true })
    }
    range.push({
      dateFrom: date,
      dateTo: date,
      label,
      hard: {
        value: bounce?.hard?.value ?? 0,
        rate: bounce?.hard?.rate ?? 0,
      },
      soft: {
        value: bounce?.soft?.value ?? 0,
        rate: bounce?.soft?.rate ?? 0,
      },
      block: {
        value: bounce?.block?.value ?? 0,
        rate: bounce?.block?.rate ?? 0,
      },
    })
    return range
  }, [])
}

export const getTemporalityRange = (
  bounces: Immutable.Map<string, BounceDetailRecord>,
  durationBack: number,
  unit: 'week' | 'month'
): PlotData[] => {
  const from = dayjs.utc().subtract(durationBack, unit)
  const dateRange = Array.from({ length: dayjs.utc().diff(from, 'day') + 1 }, (_, index) =>
    from.add(index, 'day')
  )
  let total = 0
  let hard = 0
  let soft = 0
  let block = 0
  let hasStarted = false
  let dateFrom: Dayjs
  let isFirstTemporality = true
  return dateRange.reduce((range, date) => {
    const bounce = bounces.get(date.format('YYYY-MM-DD'))
    if (!hasStarted && bounce && bounce?.total > 0) {
      dateFrom = date
      hasStarted = true
    }
    const isStartOfTemporality =
      unit === 'week' ? date.day() === 1 : date.date() === date.startOf(unit).date()
    if (isStartOfTemporality) {
      dateFrom = date
    }
    total += bounce?.total ?? 0
    hard += bounce?.hard?.value ?? 0
    soft += bounce?.soft?.value ?? 0
    block += bounce?.block?.value ?? 0
    const isToday = date.format('YYYYMMDD') === dayjs.utc().format('YYYYMMDD')
    const isEndOfTemporality =
      unit === 'week' ? date.day() === 0 : date.date() === date.endOf(unit).date()
    if (hasStarted && (isToday || isEndOfTemporality)) {
      let label = dateFrom.format(unit === 'week' ? 'DD MMM' : 'MMMM')
      if (isFirstTemporality) {
        isFirstTemporality = false
        label = humanizeDayjs({
          date: date.startOf(unit).subtract(1, unit),
          timeframe: true,
        })
      }
      if (isToday && unit === 'week') {
        label = humanizeDayjs({
          date,
          timeframe: true,
        })
      }
      range.push({
        dateFrom,
        dateTo: date,
        label,
        hard: {
          value: hard,
          rate: total > 0 ? hard / total : 0,
        },
        soft: {
          value: soft,
          rate: total > 0 ? soft / total : 0,
        },
        block: {
          value: block,
          rate: total > 0 ? block / total : 0,
        },
      })
      total = 0
      hard = 0
      soft = 0
      block = 0
    }

    return range
  }, [])
}
