// @flow
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { CampaignSearchContainer } from 'components/campaign/campaign-list.styles'
import { Grid } from 'components/common/grid'
import { FilterSearch } from 'components/filter/filter-search/filter-search'
import * as schemes from 'components/styled/tokens/schemes'

import { ChannelsFilter } from './channels-filter'
import { DataRangeFilter } from './date-range-filter'
import { LabelsFilter } from './labels-filter'
import { StatesFilter } from './states-filter'

import { type CampaignListMetadata } from 'com.batch/orchestration-list/store/campaign-list.selector'

import {
  updateFilterDateRangeAction,
  updateFilterChannels,
  updateFilterLabels,
  updateFilterStates,
  updateSearch,
} from 'com.batch/orchestration-list/usecases/fetch-campaigns-list'

export type FiltersProps = {
  campaignListMetadata: CampaignListMetadata,
  schedulingType: string,
}

export const Filters = ({ campaignListMetadata, schedulingType }: FiltersProps): React.Node => {
  const searchRef = React.useRef()
  const dispatch = useDispatch()

  const handleOnSubmit = React.useCallback(
    e => {
      e.preventDefault()
      searchRef.current?.blur()
    },
    [searchRef]
  )

  const onSearchChange = React.useCallback(
    searchTerm => {
      dispatch(updateSearch(searchTerm))
    },
    [dispatch]
  )

  const onStatesChange = React.useCallback(
    states => {
      dispatch(updateFilterStates(states))
    },
    [dispatch]
  )

  const onChannelsChange = React.useCallback(
    channels => {
      dispatch(updateFilterChannels(channels))
    },
    [dispatch]
  )

  const onLabelsChange = React.useCallback(
    labels => {
      dispatch(updateFilterLabels(labels))
    },
    [dispatch]
  )

  const onDateRangeChange = React.useCallback(
    dateRange => {
      dispatch(updateFilterDateRangeAction(dateRange))
    },
    [dispatch]
  )

  return (
    <Grid
      template="1fr auto auto auto auto"
      style={{
        borderBottom: `1px solid ${schemes.darklucent['20']}`,
        paddingBottom: '12px',
        gap: '8px',
      }}
    >
      <CampaignSearchContainer onSubmit={handleOnSubmit} style={{ marginRight: 0 }}>
        <FilterSearch
          ref={searchRef}
          placeholder={`Search ${schedulingType}...`}
          value={campaignListMetadata.search}
          onChange={onSearchChange}
          expandable={false}
        />
      </CampaignSearchContainer>
      <DataRangeFilter onChange={onDateRangeChange} value={campaignListMetadata.dateRange} />
      <StatesFilter
        isAutomation={schedulingType === 'automations'}
        value={campaignListMetadata.statuses}
        onChange={onStatesChange}
      />
      <ChannelsFilter onChange={onChannelsChange} value={campaignListMetadata.channels} />
      <LabelsFilter onChange={onLabelsChange} value={campaignListMetadata.labels} />
    </Grid>
  )
}
