// @flow

import Immutable from 'immutable'

import { type FetchPushOptinDistributionResponse } from 'com.batch/profilebase/infra/ucp-data-service.interface'
import {
  type SubDistributionRecord,
  SubDistributionFactory,
} from 'com.batch/profilebase/models/sub-distribution.records'

export const parsePushOptinDistributionResponse = (
  distribution: FetchPushOptinDistributionResponse
): SubDistributionRecord => {
  const optin = parseInt(distribution.optIns, 10) || 0
  const total = parseInt(distribution.total, 10) || 0
  const optout = total - optin
  const optinRate = Math.floor((optin / total) * 100) || 0
  const optoutRate = Math.floor((optout / total) * 100) || 0

  return SubDistributionFactory({
    subscribed: Immutable.Record({
      value: optin,
      rate: optinRate,
    })(),
    unsubscribed: Immutable.Record({
      value: optout,
      rate: optoutRate,
    })(),
  })
}
