// @flow

import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  HeaderBoxActions,
  FooterBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Logical } from 'components/query/logical'

import { QueryBuilderContext } from './query-builder.context'

import { Icon } from '../common/svg-icon'
import { type State } from 'com.batch.redux/_records'
import { currentCompanySelector } from 'com.batch.redux/company'
import { QueryFactory } from 'com.batch.redux/query/query.records'
import { availableEventAttributesSelector } from 'com.batch.redux/query/query.selector'

type QueryBuilderProps = {
  closeModal?: () => void,
  isEmailAutomation: boolean,
  validateSaveAndClose?: () => void,
  errors: Set<string>,
  setTouched: () => void,
  touched: boolean,
  eventId?: string,
  queryId: string,
  ...
}
const queryStateSelector = (state: State) => state.query

const theme = { kind: 'capture', isEventFilter: true }
export const QueryBuilder = ({
  queryId,
  touched,
  setTouched,
  errors,
  isEmailAutomation,
  closeModal,
  eventId,
  validateSaveAndClose,
}: QueryBuilderProps): React.Node => {
  // ================= redux
  const queries = useSelector(queryStateSelector)
  const [conditions, tree] = React.useMemo(() => {
    const query = queries.get(queryId, QueryFactory())
    return [query.conditions, query.tree]
  }, [queries, queryId])

  const eventAttributeSelector = useSelector(availableEventAttributesSelector)
  const company = useSelector(currentCompanySelector)
  // ================= derived
  const eventAttributes = React.useMemo(() => {
    return eventId ? eventAttributeSelector(eventId) : new Immutable.List()
  }, [eventAttributeSelector, eventId])

  const providerProps = React.useMemo(
    () => ({
      queryId,
      context: 'event_filter',
      isSample: company.id === 79,
      touched,
      eventId,
      errors,
      isProfileMode: isEmailAutomation,
    }),
    [queryId, touched, eventId, errors, isEmailAutomation, company.id]
  )

  return (
    <ThemeProvider theme={theme}>
      <QueryBuilderContext.Provider value={providerProps}>
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Event Filter" />
            <HeaderBoxActions>
              {closeModal && (
                <Button onClick={closeModal}>
                  <Icon icon="close" />
                </Button>
              )}
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody>
            <Logical
              node={tree}
              parentIsLogicalNot={false}
              conditions={conditions}
              attributes={eventAttributes}
              setTouched={setTouched}
              level={1}
              position="root"
              queryId={queryId}
            />
          </BoxBody>

          <BoxFooter isEditable>
            <Button kind="inline" onClick={closeModal}>
              Cancel
            </Button>
            <FooterBoxActions>
              <Button
                kind="primary"
                onClick={validateSaveAndClose}
                intent="action"
                disabled={!touched}
              >
                Update filter
              </Button>
            </FooterBoxActions>
          </BoxFooter>
        </Box>
      </QueryBuilderContext.Provider>
    </ThemeProvider>
  )
}
