// @flow

export const platforms = {
  IOS: {
    icon: 'ios',
    label: 'iOS',
  },
  ANDROID: {
    icon: 'android',
    label: 'Android',
  },
  WEBPUSH: {
    icon: 'webpush',
    label: 'Web',
  },
  WINDOWS: {
    icon: 'windows',
    label: 'Windows',
  },
}
