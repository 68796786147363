// @flow
import { type Map } from 'immutable'
import * as React from 'react'
import { ThemeProvider, useTheme } from 'styled-components'

import { BoxHeader, BoxTitle, BoxBody } from 'components/common/box'
import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { EmptyTitle, Empty } from 'components/styled/empty'
import { LinkArrow } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeDayjs, randomSize, kformat } from 'com.batch.common/utils'

import { type fetchingState } from 'com.batch.redux/_records'

import { formatChannelName } from 'com.batch/profilebase/infra/formats/format-channel-name'
import {
  type AnalyticsChannel,
  type channelThemeType,
} from 'com.batch/profilebase/infra/types/channel-type'
import { type PushPlatformFilterType } from 'com.batch/profilebase/infra/types/push-platform-filter.type'
import { type ReachabilityTimeserieRecord } from 'com.batch/profilebase/models/reachability-timeserie.records'
import { FilterOverview } from 'com.batch/profilebase/ui/components/filter-onview.styles'
import { SubscriptionGrowthBlockBox } from 'com.batch/profilebase/ui/components/subscription-growth-block/subscription-growth-block.styles'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { Chart, BarChart, Timeline } from 'com.batch/shared/ui/component/charts/modular-bar-chart'
import { Metric } from 'com.batch/shared/ui/component/metric/metric'
import { useModularBarChart } from 'com.batch/shared/ui/hooks/use-modular-bar-chart'
import { STATUS } from 'constants/common'

type Props = {
  loadingState: fetchingState,
  theme: channelThemeType,
  subscriptionsByDay: Map<string, ReachabilityTimeserieRecord>,
  cumulLastMonth: number,
  channel: AnalyticsChannel,
  platformFilter?: PushPlatformFilterType,
}

export const SubscriptionGrowthBlock = ({
  loadingState,
  theme,
  subscriptionsByDay,
  cumulLastMonth,
  platformFilter,
  channel,
}: Props): React.Node => {
  const { isLoading, isEmpty } = useTheme()

  const containerRef = React.useRef(null)
  const charWidth = React.useMemo(
    () => (containerRef.current ? containerRef.current.clientWidth - 24 : 612),
    [containerRef]
  )

  const noNewSub = React.useMemo(
    () => !isEmpty && loadingState === STATUS.LOADED && cumulLastMonth === 0,
    [isEmpty, loadingState, cumulLastMonth]
  )

  const hasData = React.useMemo(() => !isLoading && !noNewSub, [isLoading, noNewSub])

  const groups = [{ color: theme.colors.plotChart, name: 'subs' }]
  const nbBars = 30

  const data: BarChartData[] = React.useMemo(
    () =>
      Array.from({ length: nbBars }, (_, index) => index).map(i => {
        const date = dayjs
          .utc()
          .subtract(nbBars, 'day')
          .add(i + 1, 'day')
        const data = subscriptionsByDay.get(date.format('YYYY-MM-DD'), null)
        const label =
          i === 0
            ? '30 days ago'
            : i === nbBars - 1
              ? humanizeDayjs({
                  date,
                  timeframe: true,
                })
              : date.format('DD MMM')
        const sub = !hasData ? randomSize(40, 140) : data ? data.subscriptions : 0

        return {
          date: date,
          label,
          subs: {
            value: sub,
            rate: sub,
          },
        }
      }),
    [subscriptionsByDay, hasData]
  )

  const modularBarChartProps = useModularBarChart({
    width: charWidth,
    labels: data.map(d => ({ label: d.label, isDisabled: dayjs(d.date).isAfter(dayjs()) })),
    activeScaleLines: true,
  })

  const MetricsValue = React.useMemo(() => {
    const found = data.find(f => f.label === modularBarChartProps.hoveredBar)
    return found
      ? [kformat(found.subs.value), `on the ${found.date.format('MMM Do, YYYY')}`]
      : [kformat(cumulLastMonth), 'new subscriptions over last month']
  }, [modularBarChartProps, data, cumulLastMonth])

  return (
    <SubscriptionGrowthBlockBox ref={containerRef} $color={theme.colors.text} $hasData={hasData}>
      <BoxHeader>
        <BoxTitle>
          <Skeleton w={185} h={18} style={{ marginLeft: 4 }}>
            New {formatChannelName(channel)} subscriptions
            {platformFilter && platformFilter.filter !== 'all' && (
              <FilterOverview>
                on {platformFilter.label.replace('only', '')}{' '}
                {platformFilter.icon && <Icon icon={platformFilter.icon} />}
              </FilterOverview>
            )}
          </Skeleton>
        </BoxTitle>
      </BoxHeader>
      <ThemeProvider theme={{ isEmpty: noNewSub || isEmpty }}>
        <BoxBody>
          {noNewSub && (
            <Empty>
              <EmptyTitle>No new subscriptions in the last 30 days</EmptyTitle>
              {channel === 'email' && (
                <LinkArrow
                  href="https://doc.batch.com/guides/send-emails-with-batch/#collect-email-addresses"
                  target="_blank"
                >
                  How to collect email addresses
                </LinkArrow>
              )}
            </Empty>
          )}
          <Chart>
            <Metric value={MetricsValue[0]} subtitle={MetricsValue[1]} align="row" />
            <BarChart
              {...modularBarChartProps}
              data={data}
              groups={groups}
              height={200}
              marginTop={5}
              paddingTop={0}
            />
            <Timeline {...modularBarChartProps} />
          </Chart>
        </BoxBody>
      </ThemeProvider>
    </SubscriptionGrowthBlockBox>
  )
}
