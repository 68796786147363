// @flow

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router/router'

import { type DispatchBoundFn } from 'com.batch.redux/_records'
import { type QueryParsedAction } from 'com.batch.redux/query/query'
import { recursiveParseOursql } from 'com.batch.redux/query/query.api.parsing'
import { QueryFactory } from 'com.batch.redux/query/query.records'
import { availableAttributesSelector } from 'com.batch.redux/query/query.selector'
import { showToast } from 'com.batch.redux/toaster'

export const insertQueryAtPos = ({
  queryId,
  pos,
  query,
}: {
  queryId: string,
  pos: string,
  query: string,
}): DispatchBoundFn<Promise<void>> => {
  return (dispatch, getState) => {
    const state = getState()
    const appId = state.app.current.id ? state.app.current.id : state.app.entities.first()?.id ?? 0
    const url = generateUrl('campaign_parse_query', { appId })
    const attributes = availableAttributesSelector(state)
    return request.post(url, JSON.parse(query)).then(
      resp => {
        const query = recursiveParseOursql({
          profileDataEnabled: true,
          attributes,
          data: resp,
          parsed: state.query.get(queryId, QueryFactory()),
          isRootNode: false,
          position: pos,
        })

        dispatch(
          ({
            type: 'T_QUERY_PARSED',
            payload: { queryId: queryId, query },
          }: QueryParsedAction)
        )
        dispatch(showToast({ kind: 'success', message: 'Segment applied' }))
      },
      () => {
        dispatch(showToast({ kind: 'error', message: 'Unable to apply your segment' }))
      }
    )
  }
}
