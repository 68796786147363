// @flow

import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import S3Upload from 'react-s3-uploader/s3upload'

import { trackEvent, TrackingContext } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'

import { InputWrapper } from './../../input-wrapper'
import { MediaUrlField } from './media-url-field'
import { MediaUrlHint } from './media-url-hint'
// eslint-disable-next-line import/no-cycle
import { MediaUrlPopin } from './media-url-popin'
import { MediaUrlPreview } from './media-url-preview'
// eslint-disable-next-line import/no-cycle
import { MediaUrlContainer, MediaUrlLoading, MediaUrlText } from './media-url.styles'

export type MediaUrlValueProps = {
  mediaKind: 'image' | 'audio' | 'video',
  mediaUrl: string,
  ...
}
export type MediaUrlMetaProps = {
  mediaSize: number,
  mediaWidth: number,
  mediaHeight: number,
  ...
}
type MediaUrlProps = {
  value: MediaUrlValueProps,
  onChange: MediaUrlValueProps => any,
  label?: string,
  imageMinWidth?: number,
  imageMinHeight?: number,
  imageRequiredRatio?: number,
  maxFileSizeMo?: number,
  imageRecommandation?: {
    message: React.Node,
    docLinkText: string,
    docLinkUrl: string,
    ...
  },
  allowedMedia?: Array<'image' | 'audio' | 'video'>,
  showTemplateWizard?: boolean,
  platforms: Array<string>,
  optional?: boolean,
  style?: Object,
  ...
}

const initialValue = {
  mediaKind: 'image',
  mediaUrl: '',
}
export const initialMeta = {
  mediaSize: 0,
  mediaWidth: 0,
  mediaHeight: 0,
}

export const MediaUrl = ({
  label,
  value,
  onChange,
  platforms,
  imageMinWidth = 0,
  imageMinHeight = 0,
  imageRequiredRatio = 0,
  maxFileSizeMo = 5,
  imageRecommandation,
  allowedMedia = ['image', 'audio', 'video'],
  showTemplateWizard = true,
  optional = false,
  style,
}: MediaUrlProps): React.Node => {
  // ====================== Redux state
  const appId = useSelector(state => state.app.current.id)

  // ====================== Context
  const { eventLocation } = React.useContext(TrackingContext)

  // ====================== Component state
  const [mediaMeta, setMediaMeta] = React.useState<MediaUrlMetaProps>(initialMeta)
  const [opened, setOpened] = React.useState<boolean>(false)
  const [uploading, setUploading] = React.useState<boolean>(false)
  const [valueError, setValueError] = React.useState<MediaUrlValueProps | null>(null)
  const [uploadError, setUploadError] = React.useState<string>('')

  // ====================== Callbacks
  const openPopin = React.useCallback(() => {
    trackEvent('MEDIA_UPL_CLICK', { button: 'use a URL', platforms, location: eventLocation })
    setOpened(true)
  }, [platforms, eventLocation])
  const closePopin = React.useCallback(() => setOpened(false), [])

  const setError = React.useCallback((msg, url) => {
    setUploadError(msg)
    setValueError({
      mediaKind: 'image',
      mediaUrl: url,
    })
  }, [])

  // ====================== Component constants
  const s3Config = React.useMemo(() => {
    return {
      accept: { 'image/*': ['.jpeg', '.png', '.jpg'] },
      onFinishS3Put: file => {
        setUploading(false)
        onChange({
          mediaKind: 'image',
          mediaUrl: file.publicUrl,
        })
      },
      onError: err => {
        setUploadError(err)
        setUploading(false)
      },
      uploadRequestHeaders: {},
      signingUrl: `/api/app/${appId}/s3config`,
    }
  }, [appId, onChange])

  // drop callback
  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>) => {
      const file = acceptedFiles[0]
      if (!file) return

      const sizeMo = typeof file.size === 'number' ? file.size / 1024 / 1024 : 0
      if (sizeMo > (maxFileSizeMo ? maxFileSizeMo : 50)) {
        setUploadError(`Image can't be larger than ${maxFileSizeMo || 50} MB`)
        return
      }
      const dataURL: string = window.URL.createObjectURL(file)
      const img = new Image()
      img.onload = () => {
        if (imageMinWidth && img.width < imageMinWidth) {
          setError(
            `Image does not meet the required minimum width of ${imageMinWidth}px`,
            file.name
          )
        } else if (imageMinHeight && img.height < imageMinHeight) {
          setError(
            `Image does not meet the required minimum height of ${imageMinHeight}px`,
            file.name
          )
        } else if (imageRequiredRatio && img.width / img.height !== imageRequiredRatio) {
          setError(`Image does not meet the required ratio of ${imageRequiredRatio}`, file.name)
        } else {
          setUploadError('')
          setMediaMeta({
            mediaSize: file.size,
            mediaWidth: img.width,
            mediaHeight: img.height,
          })
          setUploading(true)
          const options = {
            ...s3Config,
            files: [file],
          }
          new S3Upload(options) // eslint-disable-line
          window.URL.revokeObjectURL(file)
        }
      }
      img.src = dataURL
    },
    [maxFileSizeMo, imageMinHeight, imageMinWidth, imageRequiredRatio, s3Config, setError]
  )

  // use Dropzone
  const { getRootProps, getInputProps, isDragActive, isDragReject, open } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
    },
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
  })

  const onDeleteMedia = React.useCallback(() => {
    valueError ? setValueError(null) : onChange(initialValue)
    setUploadError('')
    setMediaMeta(initialMeta)
  }, [onChange, valueError])

  const trackAndOpenUpload = React.useCallback(() => {
    open()
    trackEvent('MEDIA_UPL_CLICK', { button: 'upload image', platforms, location: eventLocation })
  }, [open, platforms, eventLocation])

  // ====================== Content media url
  const mode: 'field' | 'preview' | 'loading' | 'active' | 'rejected' = uploading
    ? 'loading'
    : isDragReject
      ? 'rejected'
      : isDragActive
        ? 'active'
        : value.mediaUrl.length > 0 || (!!valueError && valueError.mediaUrl.length > 0)
          ? 'preview'
          : 'field'
  const onMediaChange = React.useCallback(
    (newValue, meta) => {
      setOpened(false)
      onChange(newValue)
      setMediaMeta(meta)
    },
    [onChange]
  )
  const mediaUrlContent = React.useCallback(() => {
    switch (mode) {
      case 'active':
        return (
          <MediaUrlText>
            Drop your image here {(value.mediaUrl.length > 0 || !!valueError) && ' to replace'}
          </MediaUrlText>
        )

      case 'rejected':
        return <MediaUrlText isInvalid>This media can’t be uploaded</MediaUrlText>

      case 'loading':
        return (
          <MediaUrlLoading>
            <Icon icon="spinner" /> <span>Uploading file</span>
          </MediaUrlLoading>
        )

      case 'preview':
        return (
          <MediaUrlPreview
            value={valueError ?? value}
            meta={mediaMeta}
            error={uploadError}
            deleteMedia={onDeleteMedia}
          />
        )

      default:
        return <MediaUrlField openPopin={openPopin} open={trackAndOpenUpload} />
    }
  }, [
    mode,
    value,
    valueError,
    mediaMeta,
    uploadError,
    onDeleteMedia,
    openPopin,
    trackAndOpenUpload,
  ])

  // ====================== Render
  return (
    <React.Fragment>
      <InputWrapper
        label={label}
        hint={
          !!label && (
            <MediaUrlHint
              allowedMedia={allowedMedia}
              minHeight={imageMinHeight}
              isPersonnalizationEnabled={showTemplateWizard}
              minWidth={imageMinWidth}
              ratio={imageRequiredRatio}
            />
          )
        }
        hintMaxSize={500}
        hintMinSize={450}
        optional={optional}
        style={style}
      >
        <MediaUrlContainer
          {...getRootProps()}
          mode={mode}
          isActive={mode === 'active'}
          isDragReject={mode === 'rejected'}
          isInvalid={!!uploadError}
        >
          <input {...getInputProps()} />
          {mediaUrlContent()}
        </MediaUrlContainer>
      </InputWrapper>

      <Popin opened={opened} close={closePopin}>
        <MediaUrlPopin
          allowedMedia={allowedMedia}
          showTemplateWizard={showTemplateWizard}
          platforms={platforms}
          imageRecommandation={imageRecommandation}
          onChange={onMediaChange}
          close={closePopin}
        />
      </Popin>
    </React.Fragment>
  )
}
