// @flow
// ====================== LIBS
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderBoxTitle } from 'components/common/box/box'
import { BoxHeader, HeaderBoxActions } from 'components/common/box/box.styles'
import { Button } from 'components/common/button/button.styles'
import { SideSheet } from 'components/common/sidesheet'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Input } from 'components/form/fields/input'
import { colors } from 'components/styled/tokens'

import { generatePlaceholder } from '../../../helpers/node-formatters/reactflow-message'
import { quietHoursSelector } from '../../../models/journey.selectors'
import { NodeIconWrapper } from '../node-icon-wrapper'
import { getEmailErrorsSelector } from 'com.batch/email/store/email-automation.selector'
import { getPushErrorsSelector } from 'com.batch/push/store/push.selector'
import { getSmsErrorsSelector } from 'com.batch/sms/store/sms.selector'

import { EmailAdvancedSettings } from 'com.batch/email/ui/components/email-advanced-settings'
import { EmailBlock } from 'com.batch/email/ui/pages/email-block'
import { type MessageNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'
import {
  flagIncomplete,
  type FlagIncompleteAction,
} from 'com.batch/orchestration-journey/usecases/flag-incomplete-message-id'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'
import { PushAdvancedSettings } from 'com.batch/push/ui/push-advanced-settings'
import { PushBuilder } from 'com.batch/push/ui/push-builder'
import { AdvancedSettingsPanel } from 'com.batch/shared/ui/component/advanced-settings-panel'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'
import { MessageQuietHours } from 'com.batch/shared/ui/component/message-quiet-hours'
import { SmsBuilder } from 'com.batch/sms/ui/components/sms-builder'

const SHEET_WIDTH = 900

type SheetMessageProps = {
  isOpened: boolean,
  node: MessageNodeRecord,
  dismiss: () => void,
  refocusOnNode: ({ width?: number, zoom?: number }) => void,
}
export const SheetMessage: React$AbstractComponent<SheetMessageProps, *> = React.memo(
  ({ isOpened, node, dismiss, refocusOnNode }: SheetMessageProps): React.Node => {
    // --- local ---
    const lastIncompleteUpdate = React.useRef<?boolean>(null)

    // --- redux ---
    const dispatch = useDispatch()
    const getEmailErrors = useSelector(getEmailErrorsSelector)
    const getSmsErrors = useSelector(getSmsErrorsSelector)
    const getPushErrors = useSelector(getPushErrorsSelector)
    const { hasQuietHours } = useSelector(quietHoursSelector)
    // --- derived ---
    const messageErrors = React.useMemo(() => {
      let method = getPushErrors
      switch (node.channel) {
        case 'sms':
          method = getSmsErrors
          break
        case 'email':
          method = getEmailErrors
          break
      }
      return method(node.messageReference)
    }, [getEmailErrors, getPushErrors, getSmsErrors, node.channel, node.messageReference])
    const messageErrorsCount = React.useMemo(
      () => messageErrors.toArray().flatMap(([, err]) => err).length,
      [messageErrors]
    )

    // --- callbacks ---
    const onLabelChange = React.useCallback(
      (e: SyntheticInputEvent<HTMLInputElement>) => {
        if (e.target && node) dispatch(updateNode(node.set('label', e.target?.value)))
      },
      [dispatch, node]
    )

    // --- focus node on open ---
    React.useEffect(() => {
      if (isOpened) refocusOnNode({ width: SHEET_WIDTH })
    }, [refocusOnNode, isOpened])

    // --- flag node incomplete based on error counts ---
    React.useEffect(() => {
      const incomplete = messageErrorsCount > 0
      if (incomplete !== lastIncompleteUpdate.current) {
        lastIncompleteUpdate.current = incomplete
        dispatch<FlagIncompleteAction>(
          flagIncomplete({ messageId: node.messageReference, incomplete })
        )
      }
    }, [dispatch, messageErrorsCount, node.messageReference])
    return (
      <SideSheet
        scrollable={node.channel === 'push'}
        isOpened={isOpened}
        close={dismiss}
        footerActions={
          <React.Fragment>
            {messageErrors.size > 0 && (
              <Tooltip
                placement="top-start"
                minWidth={200}
                tooltip={
                  <div style={{ textAlign: 'left' }}>
                    Your form has errors:
                    <ul>
                      {messageErrors?.toArray().map(([cat, errorsByCategory]) => (
                        <React.Fragment key={cat}>
                          {errorsByCategory.map(err => (
                            <li key={err} style={{ textAlign: 'left', marginLeft: 18 }}>
                              {err}
                            </li>
                          ))}
                        </React.Fragment>
                      ))}
                    </ul>
                  </div>
                }
              >
                <span style={{ color: colors.textDanger, marginRight: 10, cursor: 'default' }}>
                  {messageErrorsCount} error{messageErrorsCount > 1 ? 's' : ''} &nbsp;
                  <Icon icon="help" />
                </span>
              </Tooltip>
            )}
            <Button kind="secondary" onClick={dismiss} data-testid="button_close_email_sidesheet">
              Continue
            </Button>
          </React.Fragment>
        }
        footer={<Copyable fullWidth={true} notificationText="Step ID copied" value={node.id} />}
        width={SHEET_WIDTH}
        id="sheet-message"
      >
        <BoxHeader>
          <HeaderBoxTitle
            style={{ width: '100%' }}
            title={
              <NodeIconWrapper kind={node.channel}>
                <div style={{ width: 'calc(100% - 10px)', marginTop: 8, marginBottom: 8 }}>
                  <Input
                    placeholder={`${generatePlaceholder(node.channel)} title`}
                    value={node.label}
                    onChange={onLabelChange}
                    inputStyle={{ fontWeight: 500 }}
                  />
                </div>
              </NodeIconWrapper>
            }
          />

          <HeaderBoxActions>
            {dismiss && (
              <Button kind="inline" intent="neutral" onClick={dismiss} type="button">
                <Icon icon="close" />
              </Button>
            )}
          </HeaderBoxActions>
        </BoxHeader>
        {node.channel === 'push' && (
          <React.Fragment>
            <PushBuilder messageId={node.messageReference} />
            <AdvancedSettingsPanel topShadow={false} scrollOnOpen>
              <PushAdvancedSettings messageId={node.messageReference} stepId={node.id} />
            </AdvancedSettingsPanel>
          </React.Fragment>
        )}
        {node.channel === 'sms' && (
          <React.Fragment>
            <SmsBuilder messageId={node.messageReference} />
            {hasQuietHours && (
              <AdvancedSettingsPanel openDirection="up">
                <div style={{ padding: 20 }}>
                  <MessageQuietHours channel="sms" stepId={node.id} />
                </div>
              </AdvancedSettingsPanel>
            )}
          </React.Fragment>
        )}
        {node.channel === 'email' && (
          <React.Fragment>
            <EmailBlock messageId={node.messageReference} stepId={node.id} />
            <AdvancedSettingsPanel openDirection="up">
              <EmailAdvancedSettings messageId={node.messageReference} stepId={node.id} />
            </AdvancedSettingsPanel>
          </React.Fragment>
        )}
      </SideSheet>
    )
  }
)
