// @flow

import { animated, useTransition } from '@react-spring/web'
import { type Set, type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFetchAppFromRouter } from 'components/_hooks/'
import { FullPage } from 'components/common/full-page'

import { generateUrl } from 'com.batch.common/router'

import { StepForm } from './create-step-form'
import { StepKind } from './create-step-kind'
import { StepTeam } from './create-step-team'
import { StepUrl } from './create-step-url'

import {
  type AppRecord,
  type CompanyRecord,
  type State,
  AppFactory,
} from 'com.batch.redux/_records'
import { saveApp, updateAllowedUsers } from 'com.batch.redux/app.action'
import { listUsersByCompany } from 'com.batch.redux/user'
import { type UserRecord } from 'com.batch.redux/user.records'
import { usersListSelector } from 'com.batch.redux/user.selector'

type AppNewProps = {
  company: CompanyRecord,
  kind?: 'mobile' | 'web',
  step: number,
  ...
}

const springConfig = {
  mass: 0.5,
  tension: 240,
  friction: 16,
  precision: 0.01,
  velocity: 0,
}

const AppNewRaw = ({ company, kind, step }: AppNewProps) => {
  const persistedApp = useFetchAppFromRouter()
  const appRestrictionEnabled =
    (company.planFeaturesCode.has('app-user-restriction') ||
      company.additionalFeaturesCode.has('app-user-restriction')) &&
    !company.disabledFeaturesCode.has('app-user-restriction')
  const [app, setApp] = React.useState<AppRecord>(
    AppFactory({ platform: kind === 'web' ? 'webpush' : 'mobile' })
  )
  const dispatch = useDispatch()

  const [previousStep, setPreviousStep] = React.useState(step)

  const toRight = previousStep > step

  // persist app
  const persistApp = React.useCallback(
    (): Promise<{ app: AppRecord, ... }> => saveApp(app)(dispatch),
    [app, dispatch]
  )

  // persist users
  const updateAllowedUsersBound = React.useCallback(
    (allowed: Set<number>): Promise<Map<number, UserRecord>> =>
      updateAllowedUsers(persistedApp ? persistedApp : app, allowed)(dispatch),
    [app, dispatch, persistedApp]
  )

  // load users
  React.useEffect(() => {
    listUsersByCompany(company)(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id])

  const users = useSelector(usersListSelector)
  const hasApps = useSelector(state => state.app.entities.size > 0)
  const previousUrl = useSelector(state => state.app.newAppBackUrl)
  const backUrl = previousUrl ? previousUrl : hasApps ? '/' : undefined
  const sdks = useSelector((state: State) => state.app.sdks)

  const transitions = useTransition(step, {
    initial: {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1,
    },
    from: {
      transform: `translate3d(${toRight ? -200 : 200}px,0,0)`,
      opacity: 0,
    },
    enter: () => async next => {
      await new Promise(resolve => setTimeout(resolve, 50))
      await next({ transform: 'translate3d(0, 0, 0)', opacity: 1 })
    },
    leave: { position: 'absolute', opacity: 0 },
    config: springConfig,
  })

  React.useEffect(() => setPreviousStep(step), [step])

  const getStep = (item: number) => {
    switch (item) {
      case 2:
        return <StepUrl company={company} app={app} setApp={setApp} />
      case 3:
        return (
          <StepForm
            forwardToTeamScreen={appRestrictionEnabled && users.size > 1}
            company={company}
            app={app}
            sdks={sdks}
            setApp={setApp}
            persistApp={persistApp}
          />
        )
      case 4:
        return (
          <StepTeam
            company={company}
            app={persistedApp ? persistedApp : app}
            users={users}
            persistUsers={updateAllowedUsersBound}
          />
        )
      default:
        return <StepKind company={company} app={app} setApp={setApp} />
    }
  }

  return (
    <FullPage
      backUrl={backUrl}
      step={step}
      stepCount={3}
      logoLink={hasApps ? window.location.origin : generateUrl('account_settings')}
    >
      {transitions((props, item) => (
        <animated.div style={props}>{getStep(item)}</animated.div>
      ))}
    </FullPage>
  )
}

export const AppNew: React.AbstractComponent<AppNewProps> = React.memo<AppNewProps>(AppNewRaw)

AppNew.displayName = 'AppNew'
