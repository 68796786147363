// @flow

import * as React from 'react'
import { Route } from 'react-router-dom'

import Userbase from 'components/data/userbase'
import { NavbarConnected } from 'components/project/navigation/navbar-connected'
import { ProjectRouterWrapper } from 'components/project/project-router-wrapper'

export const DataRouter = (): React.Node => {
  return (
    <React.Fragment>
      <NavbarConnected />
      <ProjectRouterWrapper>
        <Route path="apps/:appId/userbase" element={<Userbase />} />
      </ProjectRouterWrapper>
    </React.Fragment>
  )
}
