// @flow

import * as React from 'react'

import { useToggle } from 'components/_hooks'
import { Box, BoxHeader, BoxBody, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Separator } from 'components/styled/utils'

import { JsonQuery } from './json-query'

export const JsonModal = (): React.Node => {
  const modalState = useToggle()

  const ref = React.useRef(null)
  const select = React.useCallback(() => !!ref.current && ref.current.select(), [])

  const copy = React.useCallback(() => {
    select()
    try {
      document.execCommand('copy')
      modalState.close()
    } catch (err) {
      console.log('Oops, unable to copy')
    }
  }, [select, modalState])
  return (
    <React.Fragment>
      <Tooltip tooltip="View targeting query">
        <Button onClick={modalState.open}>
          <Icon icon="code" />
        </Button>
      </Tooltip>
      <Popin
        close={modalState.close}
        opened={modalState.value}
        style={{ minWidth: 600, maxWidth: 'calc(100vw - 150px)', height: 500 }}
      >
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Targeting query" />
            <HeaderBoxActions>
              <Tooltip tooltip="Copy to clipboard and close" minWidth={200} placement="left">
                <Button type="button" style={{ height: 28 }} onClick={copy}>
                  <Icon icon="copy" />
                </Button>
              </Tooltip>
              <Separator _margin="0 3px 0" />
              <Button onClick={modalState.close}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody
            style={{
              minHeight: 300,
              height: 'auto',
              overflow: 'auto',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {modalState.value && <JsonQuery ref={ref} />}
          </BoxBody>
        </Box>
      </Popin>
    </React.Fragment>
  )
}
