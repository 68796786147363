// @flow
import dayjs from 'dayjs'
import Immutable, { type OrderedMap, type List } from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { parseProfileModelToRecord, parseEventToRecord } from './debug.parse'
import { type Profile, type ProfilesWithComputedData } from './models/profile.model'

import { type ProjectRecord } from 'com.batch.redux/project.records'

import { type IdentifierKindCC } from 'com.batch/profile/constants/identifier-kind'
import { type DebugGateway } from 'com.batch/profile/debug.interface'
import { type CustomEvent } from 'com.batch/profile/infra/debug/models/custom-event.model'
import { stringifyIdentifierKind } from 'com.batch/profile/infra/formats/convert-identifier-kind'
import {
  type ProfileDataRecord,
  type CustomEventRecord,
} from 'com.batch/profile/models/profile.records'

type FetchProfilesRestResponse = {
  profiles: Profile[],
  profilesWithComputedData: ProfilesWithComputedData[],
}
type FetchEventRestResponse = {
  events?: CustomEvent[],
}

export const DebugRest: DebugGateway = {
  fetchProfiles: async ({
    project,
    identifier,
    kind,
  }: {
    project: ProjectRecord,
    identifier: string,
    kind: IdentifierKindCC,
    ...
  }): Promise<OrderedMap<string, ProfileDataRecord>> => {
    const { profilesWithComputedData } = await request.post<FetchProfilesRestResponse>(
      generateUrl('api_grpc_debug_service', {
        methodName: 'GetProfiles',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        [stringifyIdentifierKind(kind)]: identifier,
      }
    )

    return profilesWithComputedData.reduce((map, profile) => {
      const profileRecord = parseProfileModelToRecord(
        profile.profile,
        profile.computedNatives,
        profile.installs
      )
      return map.set(profileRecord.id, profileRecord)
    }, Immutable.OrderedMap())
  },
  fetchEvents: async ({
    project,
    profileId,
    abortSignal,
  }: {
    project: ProjectRecord,
    profileId: string,
    abortSignal?: AbortSignal,
    ...
  }): Promise<OrderedMap<string, List<CustomEventRecord>>> => {
    try {
      const { events } = await request.post<FetchEventRestResponse>(
        generateUrl('api_grpc_debug_service', {
          methodName: 'GetEvents',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          profileID: {
            data: profileId,
          },
        },
        abortSignal
      )

      return events
        ? events.reduce((acc, curr) => {
            const formattedDate = dayjs(curr.sendDate).format('YYYY-MM-DD')
            return acc.update(formattedDate, new Immutable.List(), listEvent =>
              listEvent.push(parseEventToRecord(curr))
            )
          }, Immutable.OrderedMap())
        : Immutable.OrderedMap()
    } catch (e) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
}
