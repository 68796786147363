// @flow
// ====================== LIBS
import Immutable from 'immutable'
import * as React from 'react'

import { type InputProps } from './helper'

import { AudiencePicker } from 'com.batch/audience/ui/components/audience-picker'

export const InputAudience = ({ condition, updateCondition }: InputProps): React.Node => {
  const onChangeLocal = React.useCallback(
    value => {
      updateCondition(
        condition?.set(
          'value',
          condition?.value.set(
            'stringList',
            !value ? new Immutable.List() : new Immutable.List().push(...value)
          )
        )
      )
    },
    [condition, updateCondition]
  )

  const conditionValue = React.useMemo(() => {
    return Immutable.Set(condition?.get('value').stringList)
  }, [condition])

  return <AudiencePicker value={conditionValue} onChange={onChangeLocal} />
}
