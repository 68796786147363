// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'

import { ValidatedInput } from 'components/campaign/form/validated-input'
import { LabelPicker } from 'components/campaign/label-picker'
import { Ariane } from 'components/common/ariane'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'

import validators from 'com.batch.common/form.validators'

import { type CampaignRecord, type State } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { updateCampaignName } from 'com.batch.redux/content'
import { customErrorsSelector, type customError } from 'com.batch.redux/targeting.selector.composed'

const campaignNameValidator = [validators.required, validators.min3]

// ====================== WORDING
const subtitles = {
  automations: {
    NEW: 'new message',
    DRAFT: 'edit message',
    RUNNING: 'edit message',
    STOPPED: 'edit message',
    COMPLETED: 'review message',
  },
  campaigns: {
    NEW: 'new campaign',
    DRAFT: 'edit campaign',
    RUNNING: 'edit campaign',
    STOPPED: 'edit campaign',
    COMPLETED: 'review campaign',
  },
  email: {
    NEW: 'new campaign',
    DRAFT: 'edit campaign',
    RUNNING: 'edit campaign',
    STOPPED: 'edit campaign',
    COMPLETED: 'review campaign',
  },
}
const titles = {
  'in-app': 'In-App',
  push: 'Push',
  email: 'Email',
}

type OwnProps = {
  activeStep: ?string,
  campaignState: campaignStateType,
  campaignType: campaignType,
  schedulingType: schedulingType,
  closeErrors: () => void,
  errorReportOpened: boolean,
  pickedLabels: $PropertyType<CampaignRecord, 'pickedLabels'>,
  stuck: boolean,
}
type StateProps = {
  appId: number,
  campaignName: string,
  canAddLabels: boolean,
  errors: List<customError>,
}
type DispatchProps = {
  updateCampaignName: typeof updateCampaignName,
}
type FormHeadProps = { ...OwnProps, ...StateProps, ...DispatchProps }

type FormHeadState = { errorPopoverOpened: boolean, labelPickerOpened: boolean, ... }

class FormHead extends React.PureComponent<FormHeadProps, FormHeadState> {
  constructor(props: FormHeadProps) {
    super(props)
    this.state = {
      errorPopoverOpened: false,
      labelPickerOpened: false,
    }
  }
  showErrors = () => {
    this.setState({ errorPopoverOpened: true })
  }
  hideErrors = () => {
    this.setState({ errorPopoverOpened: false })
  }
  openLabelPicker = () => {
    this.setState({ labelPickerOpened: true })
  }
  closeLabelPicker = () => {
    this.setState({ labelPickerOpened: false })
  }
  render(): React.Node {
    const {
      stuck,
      campaignType,
      schedulingType,
      errors,
      closeErrors,
      errorReportOpened,
      activeStep,
      campaignState,
      campaignName,
      pickedLabels,
      canAddLabels,
    } = this.props
    const isCompleted = campaignState === 'COMPLETED'
    return (
      <React.Fragment>
        <div className={`pf__head pf__head--sticky ${stuck ? 'pf__head--stuck' : ''}`}>
          <div className="pf__head__content">
            <h5 className="pf__head__chapo">
              <span style={{ flexGrow: 1 }}>
                <b>{titles[campaignType]}</b> /{' '}
                <span>{subtitles[schedulingType][campaignState]}</span>
                &nbsp;
              </span>
              <span>
                {errors.size > 0 && (
                  <div className="campaignErrorLabel">
                    <div
                      className="errorPopover"
                      style={{ display: this.state.errorPopoverOpened ? 'block' : 'none' }}
                    >
                      <h3>You need to fix the following :</h3>
                      {errors.map((err, index) => (
                        <p key={index}>
                          <strong>
                            {err.topic}
                            {err.lang ? ` (${err.lang.label})` : ''}
                          </strong>{' '}
                          : {err.message}
                        </p>
                      ))}
                    </div>
                    <span
                      className="label label-warning"
                      onMouseEnter={this.showErrors}
                      onMouseOut={this.hideErrors}
                    >
                      {errors.size} check{errors.size > 1 && 's'}
                    </span>
                  </div>
                )}
              </span>
            </h5>
            <div className="pf__head__title">
              <div
                style={{
                  paddingRight: '8px',
                  minWidth: 0,
                  flex: `0 0 ${isCompleted ? '100%' : '50%'}`,
                  height: isCompleted ? '30px' : '44px',
                }}
              >
                <div style={{ position: 'relative' }}>
                  <ValidatedInput
                    placeholder={
                      schedulingType === 'campaigns' ? 'Name your campaign' : 'Name your automation'
                    }
                    data-testid="input-campaign-name"
                    name="campaignName"
                    type="text"
                    onChange={this.props.updateCampaignName}
                    value={campaignName}
                    valid={
                      campaignNameValidator
                        .map(validator => Boolean(validator(campaignName)))
                        .reduce((a, b) => a && b, true) ?? true
                    }
                    autoFocus
                    touched={false}
                    style={{ fontSize: 18, height: 43, paddingRight: 160 }}
                  />
                  {canAddLabels && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                      }}
                    >
                      <Button style={{ height: 24 }} onClick={this.openLabelPicker}>
                        {pickedLabels.size > 0
                          ? `Manage labels (${pickedLabels.size})`
                          : 'Associate labels'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {!isCompleted && (
                <div className="pf__head__steps">
                  <Ariane steps={['who', 'when', 'what', 'review']} activeStep={activeStep} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Popin
          opened={this.state.labelPickerOpened}
          close={this.closeLabelPicker}
          style={{ padding: 0, paddingBottom: 0 }}
        >
          <LabelPicker
            appId={this.props.appId}
            pickedLabels={pickedLabels}
            schedulingType={schedulingType}
            close={this.closeLabelPicker}
          />
        </Popin>
        <Popin close={closeErrors} opened={errorReportOpened}>
          <Box>
            <BoxHeader>
              <HeaderBoxTitle title="You need to fix the following errors before saving" />
            </BoxHeader>
            <BoxBody $padding>
              {errors.map((err, index) => (
                <p key={index}>
                  <strong>
                    {err.topic} {err.lang ? `(${err.lang.label})` : ''}
                  </strong>{' '}
                  : {err.message}
                </p>
              ))}
            </BoxBody>
            <BoxFooter>
              <FooterBoxActions>
                <Button kind="primary" intent="action" onClick={closeErrors}>
                  OK, I got this
                </Button>
              </FooterBoxActions>
            </BoxFooter>
          </Box>
        </Popin>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state: State) => {
  return {
    appId: currentAppSelector(state).id,
    errors: customErrorsSelector(state),
    canAddLabels: currentAppSelector(state).features.has('label'),
    campaignName: state.content.campaignName,
  }
}
export const FormHeadConnected: React.AbstractComponent<OwnProps> = connect<
  FormHeadProps,
  OwnProps,
  _,
  _,
  _,
  _,
>(mapStateToProps, {
  updateCampaignName,
})(FormHead)
