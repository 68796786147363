// @flow
import {
  generateEdge,
  buildSourceAndHandleFromBranchId,
  handleAddOrSourceBranch,
} from '../reactflow.tools'
import { type FormatterProps, NoPosition } from '../reactflow.types'

import { type FinalNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

export const formatFinal = ({
  node,
  ...props
}: {
  node: FinalNodeRecord,
  ...FormatterProps,
}): FormatterProps => {
  props.reactflowNodes.push({
    id: node.id,
    type: 'exitNode',
    sizingType: 'FINAL',
    position: { x: 0, y: 0 },
    style: { cursor: 'default' },
    data: { reduxNodeId: node.id },
  })

  let addButtonId: ?string = null
  if (props.ownedBy.type !== 'REJOIN') {
    addButtonId = node.id + '_add'
    props.reactflowNodes.push({
      ...NoPosition,
      id: addButtonId,
      type: 'buttonNode',
      sizingType: 'add',
      data: {
        reduxNodeId: node.id,
        flowRestrictions: props.flowRestrictions,
        isActive: true,
        pasteNode: () => props.callbacks.onPasteNode([props.ownedBy]),
        insertNode: (nodeType, channel) => {
          props.callbacks.onInsertNode({
            branchIds: [props.ownedBy],
            nodeType,
            channel,
          })
        },
      },
    })
    props.reactflowEdges.push(
      generateEdge({
        ...buildSourceAndHandleFromBranchId(props.ownedBy),
        target: addButtonId,
      })
    )
  }

  // connect previous node to this final node
  props.reactflowEdges.push(
    generateEdge({
      ...handleAddOrSourceBranch(props.ownedBy, addButtonId),
      withArrow: true,
      target: node.id,
      targetHandle: 'enter-top',
    })
  )

  return props
}
