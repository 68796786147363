// @flow
import {
  type ReachabilityTimeserieStateRecord,
  ReachabilityTimeserieStateFactory,
} from 'com.batch/profilebase/models/reachability-timeserie.records'
import { type FetchPushReachabilityTimeserieActions } from 'com.batch/profilebase/usecases/fetch-reachability-timeserie'
import { STATUS } from 'constants/common'

const initialState = ReachabilityTimeserieStateFactory()

export function pushReachabilityReducer(
  state: ReachabilityTimeserieStateRecord = initialState,
  action: FetchPushReachabilityTimeserieActions
): ReachabilityTimeserieStateRecord {
  switch (action.type) {
    case 'FETCH_PUSH_REACHABILITY':
      return state.set('loadingState', STATUS.LOADING)

    case 'FETCH_PUSH_REACHABILITY_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .set('counter', action.payload.counter)
        .set('timeserie', action.payload.timeserie)

    case 'FETCH_PUSH_REACHABILITY_FAILURE':
      return state.set('loadingState', STATUS.ERROR)

    default:
      return state
  }
}
