// @flow
import * as React from 'react'

import { colors, schemes } from 'components/styled/tokens'

export const CustomEdgeArrow = (): React.Node => {
  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <defs>
        <marker
          id="batch-arrow"
          viewBox="0 0 15 15"
          markerHeight={10}
          markerWidth={10}
          orient="auto-start-reverse"
          refX={13}
          refY={7.7}
        >
          <path
            d="M8 2 L 14 8 L 8 13"
            stroke={schemes.grayscale['30']}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </marker>
        <marker
          id="batch-arrow-error"
          viewBox="0 0 15 15"
          markerHeight={10}
          markerWidth={10}
          orient="auto-start-reverse"
          refX={13}
          refY={7.7}
        >
          <path
            d="M8 2 L 14 8 L 8 13"
            stroke={colors.strokeDanger}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </marker>
      </defs>
    </svg>
  )
}
