// @flow
import { memoize as _memoize } from 'lodash'
import * as React from 'react'

import HighlightMatch from 'components/common/highlight-match'
import { Icon } from 'components/common/svg-icon'
import { Utc } from 'components/common/utc'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { isDms } from 'com.batch.common/utils'

import {
  Tree,
  TreeContent,
  TreeCount,
  TreeHandle,
  TreeHandleButton,
  TreeHandleCount,
  TreeLabel,
  TreeTitle,
  TreeValue,
  TreeValueBool,
  TreeValueInt,
  TreeValueString,
} from './da.styles'

const getValueType = (value: any) => {
  let type = typeof value
  if (type === 'string' && isDms(value)) {
    type = 'dms'
  }
  return type
}

type DebugInstallAttribute = {
  label: string,
  value?: number | string | Array<string> | null | number,
  items: Array<DebugInstallAttribute>,
  isArray: boolean,
  ...
}

type DebugAttributeTreeProps = {
  label: string,
  isArray: boolean,
  items: Array<DebugInstallAttribute>,
  value: any,
  level: number,
  defaultIsOpen: boolean,
  searchTerm: string,
  matchAbove: boolean,
  ...
}
type DebugAttributeTreeState = {
  open: boolean,
  ...
}

class DebugAttributeTree extends React.PureComponent<
  DebugAttributeTreeProps,
  DebugAttributeTreeState,
> {
  getFullText: (items: Array<DebugInstallAttribute>, label: string, value: any) => string
  getValue: (value: any) => string

  static defaultProps: { isArray: boolean, matchAbove: boolean, value: any, ... } = {
    value: null,
    isArray: false,
    matchAbove: false,
  }

  constructor(props: DebugAttributeTreeProps) {
    super(props)
    this.state = {
      open: props.defaultIsOpen,
    }
    const getFullText = (items: Array<DebugInstallAttribute>, label: string, value: any) => {
      if (items.length > 0) {
        return `${JSON.stringify(items)} ${label}`.toLowerCase()
      } else {
        return `${this.getValue(value)} ${label}`.toLowerCase()
      }
    }
    this.getFullText = _memoize(getFullText)
    const getValue = (value: any) => {
      if (value === null) {
        return 'null'
      }
      if (getValueType(value) === 'boolean') {
        return value ? 'true' : 'false'
      }
      if (getValueType(value) === 'number') {
        return (Math.round(value * 100) / 100).toString()
      }
      if (dayjs.isDayjs(value)) {
        return value.format('DD/MM/YYYY HH:mm')
      }
      if (getValueType(value) === 'string') {
        return `"${value}"`
      }
      return value
    }
    this.getValue = _memoize(getValue)
  }

  toggle: () => void = () => {
    this.setState({ open: !this.state.open })
  }

  render(): React.Node {
    const { label, items, level, defaultIsOpen, value, isArray, searchTerm, matchAbove } =
      this.props
    let open = false
    let match = false
    if (!searchTerm || items.length === 0) {
      open = this.state.open
    } else {
      if (matchAbove) {
        match = true
      } else if (
        this.getFullText(items, label, value).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      ) {
        match = true
      }
      open = match
    }
    const dots = level === 0 ? '' : ': '
    const ValueNode = getValueNode(getValueType(value))
    return (
      <Tree open={defaultIsOpen} level={level} final={items.length === 0}>
        {!!label &&
          (level === 0 ? (
            <TreeTitle onClick={this.toggle} open={open}>
              <TreeLabel>
                <HighlightMatch value={label} match={searchTerm} />
              </TreeLabel>
              <TreeCount>
                {items.length}&nbsp; item{items.length > 1 && 's'}
              </TreeCount>
              <Icon icon={open ? 'chevron-up' : 'chevron-down'} size={13} />
            </TreeTitle>
          ) : (
            <TreeLabel>
              <HighlightMatch value={label} match={searchTerm} />
            </TreeLabel>
          ))}
        {items.length > 0 ? (
          <React.Fragment>
            {(level > 0 || open) && (
              <TreeHandle onClick={this.toggle}>
                {isArray ? `${dots}[` : `${dots}{`}
                <TreeHandleButton icon={open ? 'collapse-shrink' : 'collapse-expand'} />
                {level > 0 && !open && (
                  <TreeHandleCount>
                    {items[0].label === 'count' ? items[0].value : items.length}
                  </TreeHandleCount>
                )}
              </TreeHandle>
            )}
            <TreeContent isArray={isArray} open={open}>
              {items.map((item, index) => (
                <DebugAttributeTree
                  key={index}
                  isArray={item.isArray}
                  matchAbove={match}
                  label={item.label}
                  value={item.value}
                  defaultIsOpen={false}
                  items={item.items}
                  searchTerm={searchTerm}
                  level={level + 1}
                />
              ))}
            </TreeContent>

            {(level > 0 || open) && (
              <TreeHandle onClick={this.toggle}>{isArray ? ']' : '}'}</TreeHandle>
            )}
          </React.Fragment>
        ) : (
          <ValueNode className="fs-exclude">
            {(value !== null || !searchTerm) && (
              <React.Fragment>
                {isDms(value) ? (
                  <a
                    href={`https://www.google.com/maps/place/${value.replace(' ', '+')}`}
                    target="_blank"
                  >
                    <HighlightMatch value={this.getValue(value)} match={searchTerm} />
                  </a>
                ) : (
                  <HighlightMatch value={this.getValue(value)} match={searchTerm} />
                )}
                {dayjs.isDayjs(value) && <Utc m={value} />}
              </React.Fragment>
            )}
          </ValueNode>
        )}
      </Tree>
    )
  }
}

const getValueNode = (type: string) => {
  const nodes = {
    string: TreeValueString,
    number: TreeValueInt,
    boolean: TreeValueBool,
    dms: TreeValue,
  }
  if (nodes[type]) return nodes[type]

  return TreeValue
}

export default DebugAttributeTree
