// @flow

import * as React from 'react'

import { type StateToggler } from 'components/_hooks'
import { ChannelFilter } from 'components/campaign/filters/channel-filter'
import { DateRangeFilter } from 'components/campaign/filters/date-range-filter'
import { FiltersButtonsWrapper } from 'components/campaign/filters/filters-buttons.style'
import { useFilters, type ParamsName } from 'components/campaign/filters/filters-provider'
import { StatusFilter } from 'components/campaign/filters/status-filter'
import { ToggleFiltersButton } from 'components/campaign/filters/toggle-filters-button'
import { type DateRange } from 'components/common/date-range-picker'

type FiltersButtonsProps = {
  filtersState: StateToggler,
  isAutomation: boolean,
  isWebPush: boolean,
  ...
}

export const FiltersButtons = ({
  filtersState,
  isAutomation,
  isWebPush,
}: FiltersButtonsProps): React.Node => {
  const {
    filters,
    updateLocalDateRange,
    localActiveFilters,
    goToFilteredURL,
    setLocalActiveFilters,
  } = useFilters()

  const countFilters = React.useMemo(() => {
    const commonsSize = filters.commons.filter(filter => {
      return filter.category !== 'status' && (!isAutomation || filter.category !== 'channels')
    }).size

    return commonsSize + filters.labels.size + (isAutomation && filters.dateRange !== null ? 1 : 0)
  }, [filters.commons, filters.dateRange, filters.labels.size, isAutomation])

  const handleOnDateRangeClose = (range?: DateRange | null) => {
    if (range !== localActiveFilters.dateRange) {
      updateLocalDateRange(range, true)
    }
  }

  const handleOnFilterChange = (filterName: ParamsName, filter: any) => {
    goToFilteredURL(filterName, filter)
  }

  React.useEffect(() => {
    setLocalActiveFilters(filters)
  }, [filters, setLocalActiveFilters])

  return (
    <FiltersButtonsWrapper>
      {isAutomation ? (
        !isWebPush && (
          <ChannelFilter
            localActiveFilters={localActiveFilters}
            onChange={channels => handleOnFilterChange('channels', channels)}
            isEmbedded={false}
          />
        )
      ) : (
        <DateRangeFilter
          isEmbedded={false}
          localActiveFilters={localActiveFilters}
          onChange={handleOnDateRangeClose}
          onClose={handleOnDateRangeClose}
        />
      )}
      <StatusFilter
        localActiveFilters={localActiveFilters}
        onChange={status => handleOnFilterChange('status', status)}
        isEmbedded={false}
        isAutomation={isAutomation}
      />
      <ToggleFiltersButton count={countFilters} onClick={filtersState.open} />
    </FiltersButtonsWrapper>
  )
}
