// @flow

import Immutable, { type RecordFactory, type RecordOf, type Map, type List } from 'immutable'

import { type ClickLinkRecord } from '../models/click-links-detail.record'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import { type StatsRecord } from '../models/orchestration-stats.record'

import { type BounceDetailRecord } from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'
import { STATUS } from 'constants/common'

export type EmailAnalyticsProps = {
  stats: ?StatsRecord,
  bounces: Immutable.Map<string, BounceDetailRecord>,
  providers: Immutable.List<EmailProviderRecord>,
  links: List<ClickLinkRecord>,
}
export type EmailAnalyticsRecord = RecordOf<EmailAnalyticsProps>

export type SmsAnalyticsProps = {
  stats: ?StatsRecord,
}
export type SmsAnalyticsRecord = RecordOf<SmsAnalyticsProps>

export type PushAnalyticsProps = {
  stats: ?StatsRecord,
}
export type PushAnalyticsRecord = RecordOf<PushAnalyticsProps>

export type OrchestrationChannelsStatsProps = {
  email: EmailAnalyticsRecord,
  sms: SmsAnalyticsRecord,
  push: PushAnalyticsRecord,
}
export const OrchestrationChannelsStatsFactory: RecordFactory<OrchestrationChannelsStatsProps> =
  Immutable.Record({
    email: Immutable.Record({
      stats: null,
      bounces: Immutable.Map(),
      providers: Immutable.List(),
      links: Immutable.List(),
    })(),
    sms: Immutable.Record({
      stats: null,
    })(),
    push: Immutable.Record({
      stats: null,
    })(),
  })
export type OrchestrationChannelsStatsRecord = RecordOf<OrchestrationChannelsStatsProps>

export type OrchestrationAnalyticsProps = {
  expire: number,
  stats: ?StatsRecord,
  steps: ?OrchestrationStatsByStepRecord,
  channels: OrchestrationChannelsStatsRecord,
  loadingState: LoadingState,
  bounceLoadingState: LoadingState,
  stepsLoadingState: LoadingState,
  providersLoadingState: LoadingState,
  linksLoadingState: LoadingState,
}

export const OrchestrationAnalyticsFactory: RecordFactory<OrchestrationAnalyticsProps> =
  Immutable.Record({
    expire: Date.now() + 1000 * 60 * 2, // TTL 2 min
    stats: null,
    steps: null,
    channels: OrchestrationChannelsStatsFactory(),
    loadingState: STATUS.INIT,
    bounceLoadingState: STATUS.INIT,
    stepsLoadingState: STATUS.INIT,
    providersLoadingState: STATUS.INIT,
    linksLoadingState: STATUS.INIT,
  })

export type OrchestrationAnalyticsRecord = RecordOf<OrchestrationAnalyticsProps>

export type OrchestrationAnalyticsState = Map<string, OrchestrationAnalyticsRecord>
