// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { EmailUploadingContainer } from './email-choice.styles'
import { EmailDropzoneContainer } from './email-dropzone.styles'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'

type EmailDropzoneProps = {
  getRootProps: <T>(props: T) => T,
  getInputProps: <T>(props: T) => T,
  mode: DropzoneState,
}
export const EmailDropzone = ({
  getRootProps,
  getInputProps,
  mode,
}: EmailDropzoneProps): React.Node => {
  return (
    <EmailDropzoneContainer {...getRootProps()} mode={mode}>
      <input {...getInputProps()} />
      {mode === 'loading' ? (
        <EmailUploadingContainer>
          <p className="loading">Loading...</p>
          <Icon icon="spinner" />
        </EmailUploadingContainer>
      ) : (
        <React.Fragment>
          <h2>Just drop to upload ...</h2>
          <p>Release your .zip file here and we'll start processing your template</p>
        </React.Fragment>
      )}
    </EmailDropzoneContainer>
  )
}
