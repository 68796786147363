import { CampaignABRecord } from 'com.batch.redux/_records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'

import { DELAY_MODE } from 'constants/common'

type whenNative = 'now' | 'next_session'
type whenEvent = {
  event: string,
  label?: string,
}
type whenGeofence = {
  geofence: string,
  action: 'enter' | 'exit',
}

type whenType = whenNative | whenEvent | whenGeofence

type delayMode = 'IMMEDIATE' | 'TIMER'

type landingConfig = {
  theme: string,
  enable_variants: string[],
  contents: Array<landingTrad>,
}

type landingTrad = {
  language: string,
  header?: string,
  title?: string,
  body?: string,
  image_description?: string,
  webview_url?: string,
  link_open_target?: string,
  image?: string,
  tracking_id?: string,
}
type msgTrad = {
  language: string,
  title?: string,
  body: string,
  deeplink?: string,
  media?: { [key: 'picture' | 'audio' | 'video']: string },
}

type triggerConfig = {
  capping: ?number,
  grace_period: ?{
    every: number,
    unit: 'HOURS' | 'DAYS',
  },
  push_timer: ?{
    every: number,
    unit: 'HOURS' | 'DAYS',
  },
  when: whenType,
  exit: ?whenEvent,
  delay_mode: delayMode,
}

type targetingType = {
  segments: Array<string>,
  languages?: Array<string>,
  regions?: Array<string>,
  query?: any,
}

export default class ApiLocalCampaign {
  name: string
  live: boolean
  isTrigger: boolean
  time_to_live: ?number
  landing: ?landingConfig
  trigger: triggerConfig
  targeting: targetingType
  // ================================================>
  constructor() {
    this.isTrigger = false
    this.targeting = {
      segments: [],
    }
    // this.messages = []
  }
  // ================================================>
  setLive(live: boolean) {
    this.live = live
    return this
  }
  // ================================================>
  setName(name: string) {
    this.name = name
    return this
  }

  setLabels(labels: Set<CappingCategoryRecord>) {
    if (labels.size > 0) {
      this.labels = labels.map(l => l.code)
    } else {
      this.labels = null
    }
    return this
  }

  // ================================================>
  activateLanding({
    themes,
    abTesting,
    campaignType,
  }: {
    themes: AbTestedThemeRecord,
    abTesting: CampaignABRecord,
    campaignType: campaignType,
  }) {
    if (abTesting.enabled && campaignType === 'in-app') {
      this.landing = {
        a: { theme: themes.a, contents: [] },
        b: { theme: themes.b, contents: [] },
      }
    } else {
      this.landing = { theme: themes.a, contents: [] }
    }

    return this
  }

  // ================================================>
  addLandingTranslation(translation: landingTrad) {
    if (!this.landing || !Array.isArray(this.landing.contents)) {
      throw 'landing is not active'
    }
    this.landing.contents.push(translation)
    return this
  }

  // ================================================>
  addPushTranslation(translation: msgTrad, variant?: 'a' | 'b' | false, enabledVariant: boolean) {
    if (variant) {
      if (!this.messages) {
        this.messages = {}
      }
      if (!this.messages[variant]) {
        this.messages[variant] = []
      }
      if (!this.messages.enable_variants) {
        this.messages.enable_variants = []
      }
      if (this.messages.enable_variants.indexOf(variant) === -1 && enabledVariant) {
        this.messages.enable_variants.push(variant)
      }
      this.messages[variant].push(translation)
    } else {
      if (!this.messages) {
        this.messages = []
      }
      this.messages.push(translation)
    }
    return this
  }

  addInAppTranslation(
    translation: landingTrad,
    variant?: 'a' | 'b' | false,
    enabledVariant: boolean
  ) {
    if (variant) {
      if (!this.landing.enable_variants) {
        this.landing.enable_variants = []
      }
      if (this.landing.enable_variants.indexOf(variant) === -1 && enabledVariant) {
        this.landing.enable_variants.push(variant)
      }
      this.landing[variant].contents.push(translation)
    } else {
      if (!this.landing || !Array.isArray(this.landing.contents)) {
        throw 'landing is not active'
      }
      this.landing.contents.push(translation)
    }
    return this
  }

  // ================================================>
  setAudiences(audiences: Array<string>) {
    this.targeting.custom_audiences = audiences
    return this
  }
  setJustInTime(jit: boolean) {
    this.targeting.just_in_time = jit
    return this
  }
  // ================================================>
  setRegions(regions: Array<string>) {
    this.targeting.regions = regions
    return this
  }
  // ================================================>
  setLanguages(languages: Array<string>) {
    this.targeting.languages = languages
    return this
  }
  // ================================================>
  setQuery(query: string) {
    this.targeting.query = query
    return this
  }
  // ================================================>
  addCluster(clusterChar: string) {
    const segmentMap = {
      N: 'NEW',
      U: 'ONE_TIME',
      E: 'ENGAGED',
      D: 'DORMANT',
      I: 'IMPORTED',
    }
    this.targeting.segments.push(segmentMap[clusterChar])
    return this
  }
  // ================================================>
  setRange(start, end, local: boolean) {
    this[`${local ? 'local_' : ''}start_date`] = start ? start.format('YYYY-MM-DDTHH:mm:00') : null

    this[`${local ? 'local_' : ''}end_date`] = end ? end.format('YYYY-MM-DDTHH:mm:00') : null
    return this
  }

  setTrigger(config) {
    this.isTrigger = true
    this.start_date = config.hasStart ? config.start.format('YYYY-MM-DDTHH:mm:00') : null
    this.end_date = config.hasEnd ? config.end.format('YYYY-MM-DDTHH:mm:00') : null

    this.trigger = {
      grace_period: config.hasGrace
        ? {
            unit: 'MINUTES',
            value: !config.grace.seconds ? 0 : Math.floor(config.grace.seconds / 60),
          }
        : null,
      when: {
        event: config.enterEvent,
        query: !config.enterEventQuery ? null : config.enterEventQuery,
        reset_timer: config.resetTimerOnEvent,
      },
      instance_id_selector: !config.hasInstanceId || !config.instanceId ? null : config.instanceId, // l'api ne supporte pas les named dans ce cas là
      timer_reference:
        config.pushTimerMode === 'event' || !config.pushTimerReference
          ? null
          : `eventAttr('${config.pushTimerReference}')`,
      cancelTriggersList:
        config.delayMode === DELAY_MODE.TIMER && config.hasExitEvent
          ? config.exitEvents.map(ev => ({
              eventName: ev.eventId,
              query: ev.query,
            }))
          : null,
      timer: {
        unit: 'MINUTES',
        value: !config.pushTimer.seconds
          ? 0
          : Math.floor(
              (config.pushTimer.seconds * (config.pushTimerMode === 'before' ? -1 : 1)) / 60
            ),
      },
      delay_mode: config.delayMode,
    }
    this.capping = config.hasCapping ? config.capping : null
  }
  setWhen(
    sendType: 'now' | 'scheduled' | 'recurring' | 'trigger',
    start,
    end,
    local: boolean,
    repeatUnit,
    repeatFrequency,
    capping
  ) {
    if (sendType === 'trigger') {
      return this
    }
    if (sendType === 'now') {
      this.push_time = 'now'
      return this
    }
    if (sendType === 'scheduled') {
      this[`${local ? 'local_' : ''}push_time`] = start ? start.format('YYYY-MM-DDTHH:mm:00') : null
      return this
    }
    this[`${local ? 'local_' : ''}push_time`] = start ? start.format('YYYY-MM-DDTHH:mm:00') : null
    this.recurrence = {
      repeat_unit: repeatUnit,
      repeat_frequency: repeatFrequency,
    }
    this.recurrence.end_date = end ? end.format('YYYY-MM-DDTHH:mm:00') : null

    if (parseInt(capping)) {
      this.capping = capping
    } else {
      this.capping = null
    }
    return this
  }
  setCustomPayloadForInApp(payload: false | string) {
    this.custom_payload = payload ? payload : null
  }
  setAdvanced(
    priority: false | 'HIGH' | 'NORMAL',
    collapseKey: null | string,
    ttl: ?number,
    payload: false | string
  ) {
    this.custom_payload = payload ? payload : null
    this.deeplink = null
    this.time_to_live = ttl ? ttl * 3600 : ttl
    if (priority) {
      this.priority = priority.toLowerCase()
    }
    if (collapseKey !== null) {
      this.gcm_collapse_key = {
        enabled: collapseKey !== '',
      }
      if (collapseKey) {
        this.gcm_collapse_key.key = collapseKey
      }
    } else {
      this.gcm_collapse_key = {
        enabled: false
      }
    }
    return this
  }
  setMedia(icon, picture, video, audio) {
    if (icon || picture || video || audio) {
      let media = {}
      if (icon) {
        media.icon = icon
      }
      if (picture) {
        media.picture = picture
      } else if (video) {
        media.video = video
      } else if (audio) {
        media.audio = audio
      }

      this.media = media
    }
    return this
  }
  // ================================================>
  // c'est moche parce que j'ai tenté de please flow. TODO FIXME
  setInAppTriggerConfig({
    trigger,
    priority,
    triggerLabel,
    grace,
    capping,
  }: {
    trigger: string,
    priority: ?inAppPriority,
    grace?: number,
    triggerLabel: ?string,
    capping: ?number,
  }) {
    let triggerTmp = {
      capping: null,
      grace_period: null,
      delay_mode: trigger.delayMode,
    }
    if (priority) {
      triggerTmp.priority = priority.toUpperCase()
    }
    if (trigger === 'now' || trigger === 'next_session') {
      triggerTmp.when = trigger
    } else {
      triggerTmp.when = {}
      triggerTmp.when.event = trigger
      if (triggerLabel !== null) {
        triggerTmp.when.label = triggerLabel
      } else {
        triggerTmp.when.label = null
      }
    }
    if (grace !== null && grace !== 0) {
      triggerTmp.grace_period = {
        every: grace,
        unit: 'HOURS',
      }
    }
    if (capping !== null && capping > 0) {
      triggerTmp.capping = capping
    } else {
      triggerTmp.capping = null
    }
    this.trigger = triggerTmp
    return this
  }
}
