// @flow
import Editor from '@monaco-editor/react'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { type StateToggler } from 'components/_hooks'
import { HeaderBoxActions, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { getEmailContentForSelectedLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'

import { loadZipScript } from 'com.batch/email/infra/services/email-uploader.helper'
import { EmailEditorPopin } from 'com.batch/email/ui/components/email-editor-popin'
import { EmailPreview } from 'com.batch/email/ui/components/email-preview/email-preview'
import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'

type CodeEditorProps = {
  popinState: StateToggler,
  messageId: string,
  sendTestEmailPopinState: StateToggler,
}
export const CodeEditor = ({
  popinState,
  messageId,
  sendTestEmailPopinState,
}: CodeEditorProps): React.Node => {
  const dispatch = useDispatch()
  const getContent = useSelector(getEmailContentForSelectedLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
  const lang = useSelector(activeLanguageValueSelector)
  const theme = useTheme()
  const htmlBackup = React.useRef(content?.html ?? '')
  const isPristine = React.useMemo(() => content?.isPristine ?? true, [content?.isPristine])
  const { confirmBeforeAction } = useLeaveConfirmation({ isPristine })

  // since monaco break loading of zip file, we load it before
  const [ready, setReady] = React.useState(false)
  React.useEffect(() => {
    loadZipScript().then(() => setReady(true))
  }, [])

  const handleUpdateHtml = React.useCallback(
    (value: string) => {
      return dispatch(
        updateEmailContent({
          messageId,
          lang,
          field: 'html',
          value,
          htmlEditorConfig: { type: 'CODE' },
          timeout: 1600,
        })
      )
    },
    [dispatch, lang, messageId]
  )

  const dismissPopin = React.useCallback(async () => {
    const confirmed = await confirmBeforeAction()
    if (confirmed) {
      dispatch(
        updateEmailContent({
          messageId,
          lang,
          field: 'html',
          value: htmlBackup.current,
          htmlEditorConfig: { type: 'CODE' },
          isInstant: true,
        })
      )
      popinState.close()
    }
  }, [confirmBeforeAction, dispatch, lang, messageId, popinState])

  const popinHeader = React.useMemo(
    () => (
      <React.Fragment>
        <HeaderBoxTitle title="Email message" />
        <HeaderBoxActions>
          <Button kind="inline" intent="neutral" onClick={dismissPopin} type="button">
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </React.Fragment>
    ),
    [dismissPopin]
  )
  const onBeforeMount = React.useCallback(monaco => {
    monaco.editor.defineTheme('my-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.paddingTop': '16px',
        'editor.foreground': '#C5C8C6',
        'editor.background': '#383838',
        'editor.selectionBackground': '#0863a3',
        'editor.lineHighlightBackground': '#054562',
        'editorCursor.foreground': '#AEAFAD',
        'editorWhitespace.foreground': '#4B4E55',
      },
    })
  }, [])

  if (!ready) return null
  return (
    <EmailEditorPopin
      messageId={messageId}
      header={popinHeader}
      popinState={popinState}
      saveAction={popinState.close}
      cancelAction={dismissPopin}
      sendTestEmailPopinState={sendTestEmailPopinState}
    >
      <div
        style={{
          flexBasis: 600,
          height: '100%',
          flexShrink: 0,
          background: 'rgb(56, 56, 56)',
          paddingTop: 16,
        }}
      >
        <Editor
          height="100%"
          defaultLanguage="html"
          theme="my-theme"
          value={content?.html ?? ''}
          options={{
            minimap: {
              enabled: false,
            },
            scrollBeyondLastLine: false,
            fontSize: 14,
            wordWrap: 'on',
            readOnly: theme?.disabledMode,
          }}
          onChange={handleUpdateHtml}
          beforeMount={onBeforeMount}
        />
      </div>
      <div
        style={{
          minHeight: '100%',
          flexBasis: 680,
          flexGrow: 1,
          overflow: content ? 'auto' : 'hidden',
          background: 'rgb(248, 248, 248)',
        }}
      >
        <EmailPreview messageId={messageId} />
      </div>
    </EmailEditorPopin>
  )
}
