// @flow

import * as React from 'react'
import styled, { css, type StyledComponent } from 'styled-components'

import { builtEmptyStateAnimation } from 'components/common/empty-states'
import { colors, schemes } from 'components/styled/tokens'

const PaginationText = css`
  font-size: 1em;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.02em;
  -webkit-user-select: none;
  user-select: none;
`
type PagerNavProps = { isEmpty?: boolean, isLoading?: boolean, ... }
export const PagerNav: StyledComponent<PagerNavProps, *, *> = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;

  > li {
    a {
      ${PaginationText}
      color: ${colors.textLight};

      padding: 0 8px;
      height: 27px;
      margin: 0 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      min-width: 28px;

      ${(props: PagerNavProps) =>
        (props.isEmpty || props.isLoading) &&
        css`
          pointer-events: none;
          color: ${colors.textDisabled};
        `}

      &:hover, &:focus {
        color: ${schemes.grayscale['70']};
        background: ${schemes.darklucent['20']};
        cursor: pointer;
      }
      &:active {
        color: ${schemes.grayscale['80']};
        background: ${schemes.darklucent['30']};
        cursor: pointer;
      }
      &.styled-icon-pagnation {
        width: 28px;
      }
      &.styled-icon-pagnation i {
        margin-top: -2px;
      }

      &.styled-disabled-pagination {
        &:hover {
          background: transparent;
          cursor: default;
        }
        i {
          color: ${colors.textDisabled};
        }
      }
    }

    &.active a {
      color: ${schemes.grayscale['80']};
      background: ${schemes.darklucent['30']};
    }

    &.styled-dots-pagintation {
      ${PaginationText}

      height: 27px;
      width: 28px;

      margin: 0 2px;

      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.textDisabled};
    }

    &.styled-small-pagination {
      margin: 0 5px;
      display: flex;
      align-items: center;
      height: 27px;
      color: ${colors.textLight};
    }
  }
`

export const EmptyPager = ({ _theme }: { _theme: 'empty' | 'loading', ... }): React.Node => {
  return (
    <React.Fragment>
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
      <EmptyPagerNav _theme={_theme} />
    </React.Fragment>
  )
}
type EmptyPagerNavProps = { _theme: 'empty' | 'loading', ... }
export const EmptyPagerNav: StyledComponent<EmptyPagerNavProps, *, HTMLElement> = styled.div`
  margin: 7px 6px;
  width: 14px;
  height: 14px;
  border-radius: 2px;

  ${(p: EmptyPagerNavProps) =>
    p._theme === 'loading'
      ? builtEmptyStateAnimation()
      : css`
          background-color: ${colors.textDisabled};
        `}
`
