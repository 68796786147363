// @flow

import Immutable from 'immutable'
import req from 'superagent-interface-promise'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import {
  type AudienceServiceApiInterface,
  type fetchAudienceListResponse,
} from './audience-service.interface'
import { parseProjectAudienceList } from './parses/parse-audience-profile'
import { type grpcAudienceListResponse, type AudiencesModel } from './types/grpc-audience-service'

import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'

/*
  Pour des raisons de perf, le service grpc ne fait un count que sur la première page.
  si jamais nous n'avons pas l'info dans le state (le client arrive directement sur une url avec un paramètre page), 
  on fait un second hit juste pour récupérer cette info. on cache cette complexité dans cette méthode, 
  qui nous assure de toujours nous retourner un count. 
*/
export const audienceServiceApi: AudienceServiceApiInterface = {
  fetchAudiencesList: async ({
    projectKey,
    page,
    pageSize,
    orderDirection,
    orderBy,
    search,
    abortSignal,
    currentCount,
  }): Promise<fetchAudienceListResponse> => {
    const fieldSearch =
      orderBy === 'name'
        ? 'NAME'
        : orderBy === 'display_name'
          ? 'DISPLAY_NAME'
          : orderBy === 'nb_ids'
            ? 'NB_IDS'
            : orderBy === 'type'
              ? 'TYPE'
              : 'UPDATED'

    if (currentCount === null && page !== 0) {
      const response = await request.post<grpcAudienceListResponse>(
        generateUrl('api_grpc_audience_service', {
          methodName: 'List',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          page: 0,
          pageSize: 1,
          sortField: fieldSearch,
          sortDirection: orderDirection === 'asc' ? 'ASC' : 'DESC',
          filter: search,
        },
        abortSignal
      )
      currentCount = response.count
    }
    const response = await request.post<grpcAudienceListResponse>(
      generateUrl('api_grpc_audience_service', {
        methodName: 'List',
      }),
      {
        ...generateProjectKeyBlockRequest(projectKey),
        page,
        pageSize,
        sortField: fieldSearch,
        sortDirection: orderDirection === 'asc' ? 'ASC' : 'DESC',
        filter: search,
      },
      abortSignal
    )
    if (page === 0) {
      currentCount = response.count
    }
    return {
      entities: new Immutable.List().push(
        ...(response.audiences?.map(parseProjectAudienceList) ?? [])
      ),
      count: currentCount ?? 0,
    }
  },
  saveAudience: ({ projectKey, name, identifierType, displayName, file, onProgress }) => {
    try {
      let formData = new FormData()
      formData.append('projectKey', projectKey)
      formData.append('name', name)
      formData.append('displayName', displayName)
      formData.append('identifierType', identifierType)
      if (file) formData.append('file', file)

      return req
        .post(
          generateUrl('api_project_audience_create_update', {
            projectKey,
          })
        )
        .send(formData)
        .on('progress', p => {
          !!onProgress && onProgress(p.percent)
        })
    } catch (e) {
      throw e.error.body.errors[0].message ?? 'Unknown error'
    }
  },

  updateAudience: async ({ projectKey, name, displayName }): Promise<any> => {
    try {
      await request.post<void>(
        generateUrl('api_grpc_audience_service', {
          methodName: 'Update',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          name,
          displayName,
        }
      )
    } catch (e) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },

  deleteAudience: async ({ projectKey, name }) => {
    try {
      await request.post<void>(
        generateUrl('api_grpc_audience_service', {
          methodName: 'Remove',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          name: name,
        }
      )
    } catch (e) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  findAudiencesByName: async ({ projectKey, namesAndIdx }): Promise<any> => {
    const response = await request.post<{ audience: Array<AudiencesModel>, ... }>(
      generateUrl('api_grpc_audience_service', {
        methodName: 'View',
      }),
      {
        ...generateProjectKeyBlockRequest(projectKey),
        namesAndIdx,
      }
    )

    return new Immutable.List().push(...(response.audience?.map(parseProjectAudienceList) ?? []))
  },
}
