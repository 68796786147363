// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { useAction } from 'components/_hooks'
import { LangPicker } from 'components/project/campaign/common/lang-picker/lang-picker'
import { MessageLanguages } from 'components/project/campaign/push/message.styles'

import { currentAppSelector } from 'com.batch.redux/app'
import {
  addTranslation,
  removeTranslation,
  setActiveTranslation,
} from 'com.batch.redux/campaign.action'
import {
  activeLanguageSelector,
  pickableLanguagesSelector,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { validTranslationsIdSelector } from 'com.batch.redux/project-error.selector'

const CAPTURE_THEME = { kind: 'capture' }

export const LangPickerConnected = (): React.Node => {
  const language = useSelector(activeLanguageSelector)
  const pickedLanguages = useSelector(pickedLanguagesSelector)
  const pickableLanguages = useSelector(pickableLanguagesSelector)
  const validLanguagesId = useSelector(validTranslationsIdSelector)
  const removeTranslationBound = useAction(removeTranslation)
  const addTranslationBound = useAction(addTranslation)
  const app = useSelector(currentAppSelector)
  const setActiveTranslationBound = useAction(setActiveTranslation)
  return (
    <MessageLanguages>
      <ThemeProvider theme={CAPTURE_THEME}>
        <LangPicker
          defaultLanguageId={app?.defaultLanguageId}
          activeTranslation={language}
          addTranslation={addTranslationBound}
          pickableLanguages={pickableLanguages}
          pickedLanguages={pickedLanguages}
          removeTranslation={removeTranslationBound}
          setActiveTranslation={setActiveTranslationBound}
          validLanguagesId={validLanguagesId}
        />
      </ThemeProvider>
    </MessageLanguages>
  )
}
