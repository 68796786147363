// @flow

import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export type sortDirection = 'asc' | 'dsc'

/**
 * Custom hook to updating/fetching of the search params associated with the sorting
 */
export const useSortParams = (): ({
  sortDirectionParam: sortDirection,
  sortOrderByParam: ?string,
  setSortParams: (column: string, direction: string) => void,
  ...
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const sortDirectionParam: sortDirection = useMemo((): sortDirection => {
    const direction = searchParams.get('orderDirection')
    return direction === 'asc' || direction === 'dsc' ? direction : 'asc'
  }, [searchParams])

  const sortOrderByParam = useMemo(() => searchParams.get('orderBy'), [searchParams])

  const setSortParams = useCallback(
    (column, direction) => {
      setSearchParams(params => {
        params.set('orderDirection', direction)
        params.set('orderBy', column)
        return params
      })
    },
    [setSearchParams]
  )

  return {
    sortDirectionParam,
    sortOrderByParam,
    setSortParams,
  }
}
