// @flow

import {
  type rangeShortcutType,
  type allTimeRangeShortcutType,
} from 'components/form/fields/date-picker/date-picker-shortcuts'

import { dayjs } from 'com.batch.common/dayjs.custom'

export const allTime: allTimeRangeShortcutType = {
  name: 'All time',
  all: true,
}

export const currentMonth: rangeShortcutType = {
  name: 'Current month',
  from: dayjs().startOf('month'),
  to: dayjs(),
}

export const lastWeek: rangeShortcutType = {
  name: 'Last week',
  from: dayjs().subtract(1, 'week').startOf('week').add(1, 'day'),
  to: dayjs().subtract(1, 'week').endOf('week').add(1, 'day'),
}

export const lastMonth: rangeShortcutType = {
  name: 'Last month',
  from: dayjs().subtract(1, 'month').startOf('month'),
  to: dayjs().subtract(1, 'month').endOf('month'),
}

export const last7Days: rangeShortcutType = {
  name: 'Last 7 days',
  from: dayjs().endOf('day').subtract(7, 'day'),
  to: dayjs().endOf('day').subtract(1, 'day'),
}

export const last30Days: rangeShortcutType = {
  name: 'Last 30 days',
  from: dayjs().endOf('day').subtract(30, 'day'),
  to: dayjs().endOf('day').subtract(1, 'day'),
}

export const last90Days: rangeShortcutType = {
  name: 'Last 90 days',
  from: dayjs().endOf('day').subtract(90, 'day'),
  to: dayjs().endOf('day').subtract(1, 'day'),
}

export const last3Months: rangeShortcutType = {
  name: 'Last 3 months',
  from: dayjs().subtract(3, 'month').startOf('month'),
  to: dayjs().subtract(1, 'month').endOf('month'),
}

export const last6Months: rangeShortcutType = {
  name: 'Last 6 months',
  from: dayjs().subtract(6, 'month').startOf('month'),
  to: dayjs().subtract(1, 'month').endOf('month'),
}

export const lastYear: rangeShortcutType = {
  name: 'Last year',
  from: dayjs().subtract(1, 'year').startOf('year'),
  to: dayjs().subtract(1, 'year').endOf('year'),
}
