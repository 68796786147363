// @flow

import { useSelect } from 'downshift'
import Immutable from 'immutable'
import * as React from 'react'

import { useWidthObserver, usePopper } from 'components/_hooks'
import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'
import { FilterButton } from 'components/filter/filter-select/filter-select.styles'
import { SelectDropdown } from 'components/form/fields/select/select-dropdown'
import {
  selectPopperConfig,
  type SelectMenuPropsType,
} from 'components/form/fields/select/select.helper'

import { type BaseFilterSelectProps } from './filter-select'

export function FilterSelectNoSearch<T>({
  options,
  onChange,
  optionToString,
  value,
  placeholder,
  style,
  isClearable = true,
  optionMenuShownCount,
  optionMenuHeight,
  menuOffset = 42,
  optionFormatter,
  isDisabled,
  expandable = false,
  _width = 180,
  variant = 'default',
  ...rest
}: BaseFilterSelectProps<T>): React.Node {
  const [isExpanded, setExpanded] = React.useState(false)
  const [showDropdown, setShowDropdown] = React.useState(false)
  const items = React.useMemo(() => options?.toArray() ?? [], [options])
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)

  React.useEffect(() => {
    if (value) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }

    return () => {}
  }, [value])

  const {
    isOpen,
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
    closeMenu,
    setHighlightedIndex,
    selectedItem,
    highlightedIndex,
  } = useSelect({
    items,
    selectedItem: value ?? null,
    itemToString: optionToString,
    circularNavigation: true,
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem, changes.isOpen)
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen && !value) {
        setShowDropdown(false)
        setExpanded(false)
      }
      if (isOpen) {
        setExpanded(true)

        // Displays the popper after the expanded animation
        setTimeout(() => {
          popperInstance?.forceUpdate()
          setShowDropdown(true)
        }, 200)
      }
    },
  })

  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })

  const containerProps = getToggleButtonProps({
    ref: triggerRef,
    type: 'button',
    ...rest,
    style: {
      ...style,
      padding: '0 0 0 10px',
      minWidth: !expandable ? 180 : 'auto',
      width: expandable ? (isExpanded ? 190 : 108) : _width,
      textAlign: 'left',
      gap: 0,
      onFocus: () => {
        setExpanded(true)
      },
    },
  })

  const width = useWidthObserver(triggerRef, 200)

  const isOptionSelected = React.useCallback((option: T) => option === value, [value])

  const handleOnClickClose = React.useCallback(
    evt => {
      evt?.stopPropagation()
      onChange(null)
      closeMenu()
    },
    [onChange, closeMenu]
  )

  return (
    <React.Fragment>
      {selectedItem ? (
        <FilterButton
          variant={variant}
          addOn="suffix"
          addOnGap={22}
          kind="inline"
          {...containerProps}
        >
          <span>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            {optionFormatter
              ? optionFormatter(selectedItem, { context: 'value' })
              : optionToString(selectedItem)}
          </span>
          {isClearable && (
            <Button as="span" role="button" kind="discreet" onClick={handleOnClickClose}>
              <Icon icon="close" />
            </Button>
          )}
        </FilterButton>
      ) : (
        <FilterButton
          isActive={isOpen}
          disabled={Boolean(isDisabled)}
          style={{ width: 120 }}
          addOn="suffix"
          kind="inline"
          {...containerProps}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            {placeholder ?? 'Filter by'}
          </span>
        </FilterButton>
      )}

      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        optionMenuShownCount={optionMenuShownCount}
        optionMenuHeight={optionMenuHeight}
        getItemProps={getItemProps}
        optionToString={optionToString}
        optionFormatter={optionFormatter}
        options={options ?? new Immutable.List()}
        isOpen={showDropdown && isOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isOptionSelected}
      />
    </React.Fragment>
  )
}
