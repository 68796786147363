// @flow

import * as React from 'react'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  FooterBoxActions,
} from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Input, TabButtonItem, TabButton } from 'components/form'
import { Title } from 'components/styled/text'

import { AppAvatar } from './app-avatar'
import { type CommonGlobalProps } from './global'
import GlobalKeys from './global-keys'
import { GlobalPopinIcon } from './global-popin-icon'
import { AppInfosGrid, AppInfosIconContainer } from './global.styles'

import { type SdkRecord } from 'com.batch.redux/_records'

const GlobalApp = ({
  name,
  setName,
  icon,
  sdk,
  setSdk,
  errors,
  bundleId,
  setOpenPopin,
  iconLoading,
  fetchIcon,
  setIcon,
  openPopin,
  fetchIconError,
  setBundleId,
  buttonLoading,
  userNotAllowedToUpdade,
  remove,
  save,
  sdks,
  hasChanges,
  app,
  company,
  hasDevApiKeyFeature,
}: CommonGlobalProps): React.Node => {
  // ====================== Callbacks

  const isAllowedToDeleteApp = useIsCurrentUserAllowedTo(['company', 'apps:create-delete'])
  const isAllowedToChangeSettings = useIsCurrentUserAllowedTo(['app', 'settings:infos:write'])

  const handleOnChangeCompanyName = React.useCallback(evt => setName(evt.target.value), [setName])

  const handleOnChangeBundleId = React.useCallback(
    evt => setBundleId(evt.target.value),
    [setBundleId]
  )

  const createOnClickSDKHandler = React.useCallback(
    (currentSdk: SdkRecord) => () => setSdk(currentSdk.kind),
    [setSdk]
  )

  const handleOnClickAppAvatar = React.useCallback(() => {
    app.platform !== 'webpush' ? setOpenPopin(true) : fetchIcon(app.set('bundleId', bundleId))
  }, [app, bundleId, fetchIcon, setOpenPopin])

  const handleOnClickDeleteAvatar = React.useCallback(() => setIcon(''), [setIcon])

  const handleOnClosePoppinIcon = React.useCallback(() => setOpenPopin(false), [setOpenPopin])

  return (
    <React.Fragment>
      <Title overEmptyState>General</Title>
      <Box as="form" onSubmit={save}>
        <BoxHeader>
          <HeaderBoxTitle
            title={app.platform === 'webpush' ? 'Website information' : 'App information'}
          />
        </BoxHeader>

        <BoxBody>
          <AppInfosGrid alignItems="stretch">
            <div style={{ padding: 20 }}>
              <InputWrapper
                label={app.platform === 'webpush' ? 'Name' : 'Application name'}
                feedback={errors.get('name')}
              >
                <Input
                  type="text"
                  id="name"
                  label={app.platform === 'webpush' ? 'Name' : 'Application name'}
                  value={name}
                  onChange={handleOnChangeCompanyName}
                  invalid={errors.has('name')}
                  accessNotAllowed={userNotAllowedToUpdade}
                />
              </InputWrapper>

              {app.platform === 'webpush' && (
                <InputWrapper label="URL" feedback={errors.get('bundleId')}>
                  <Input
                    type="text"
                    id="bundleId"
                    value={bundleId || ''}
                    onChange={handleOnChangeBundleId}
                    invalid={errors.has('bundleId')}
                    accessNotAllowed={userNotAllowedToUpdade}
                  />
                </InputWrapper>
              )}

              {(app.platform === 'ios' || app.platform === 'android') && (
                <InputWrapper label="SDK">
                  <TabButton grow accessNotAllowed={userNotAllowedToUpdade}>
                    {sdks
                      .filter(s => !s.legacy || s.kind === sdk)
                      .map(currentSdk => (
                        <TabButtonItem
                          key={currentSdk.kind}
                          isActive={sdk === currentSdk.kind}
                          onClick={createOnClickSDKHandler(currentSdk)}
                          disabled={userNotAllowedToUpdade}
                        >
                          {currentSdk.kind}
                        </TabButtonItem>
                      ))}
                  </TabButton>
                </InputWrapper>
              )}
            </div>

            <AppInfosIconContainer>
              <AppAvatar icon={icon} name={name ? name : 'A'} />
              <PermissionButton
                type="button"
                kind="inline"
                intent="action"
                style={{ height: 28, display: 'inline-flex' }}
                addOn="prefix"
                isLoading={iconLoading}
                isAllowed={isAllowedToChangeSettings}
                onClick={handleOnClickAppAvatar}
              >
                <Icon icon="download" />
                Fetch icon from {app.platform !== 'webpush' ? 'application store' : 'your website'}
              </PermissionButton>
              <PermissionButton
                type="button"
                kind="discreet"
                style={{ marginTop: 9, height: 28 }}
                intent="danger"
                addOn="prefix"
                disabled={!icon || iconLoading}
                onClick={handleOnClickDeleteAvatar}
                isAllowed={isAllowedToChangeSettings}
              >
                <Icon icon="delete" />
                Delete icon
              </PermissionButton>
            </AppInfosIconContainer>
          </AppInfosGrid>
        </BoxBody>

        <BoxFooter isEditable>
          <PermissionButton
            type="submit"
            intent="action"
            kind="primary"
            disabled={!hasChanges || buttonLoading === 'updating'}
            isLoading={buttonLoading === 'updating'}
            isAllowed={isAllowedToChangeSettings}
          >
            Update app
          </PermissionButton>
          <FooterBoxActions>
            <PermissionButton
              type="button"
              intent="danger"
              kind="secondary"
              disabled={buttonLoading === 'removing'}
              isLoading={buttonLoading === 'removing'}
              onClick={remove}
              isAllowed={isAllowedToDeleteApp}
            >
              Delete app
            </PermissionButton>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
      <GlobalKeys app={app} restKey={company.restKey} hasDevApiKeyFeature={hasDevApiKeyFeature} />
      <GlobalPopinIcon
        app={app}
        opened={openPopin}
        fetchIcon={fetchIcon}
        close={handleOnClosePoppinIcon}
        error={fetchIconError}
        loading={iconLoading}
      />
    </React.Fragment>
  )
}
export { GlobalApp }
