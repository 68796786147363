// @flow

import { type Dayjs } from 'dayjs'
import { type Map } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type BounceDetailRecord } from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'

export type FetchBounceByDaysAction = ReduxAction<'FETCH_BOUNCE_BY_DAYS', { token: string, ... }>

export type FetchBounceByDaysSuccessAction = ReduxAction<
  'FETCH_BOUNCE_BY_DAYS_SUCCESS',
  { data: Map<string, BounceDetailRecord>, token: string, ... },
>

export type FetchBounceByDaysFailureAction = ReduxAction<
  'FETCH_BOUNCE_BY_DAYS_FAILURE',
  { token: string, error: Error, ... },
>

export type FetchBounceByDaysActions =
  | FetchBounceByDaysAction
  | FetchBounceByDaysSuccessAction
  | FetchBounceByDaysFailureAction

export const getBounceDateRange = (
  campaign: OrchestrationCampaignRecord
): { dateFrom: Dayjs, dateTo: Dayjs } => {
  const dateTo = dayjs.utc()
  let dateFrom = dayjs.utc()

  if (campaign.sendType === 'trigger' || campaign.sendType === 'scheduled') {
    dateFrom = dateFrom.subtract(13, 'day')
  } else if (campaign.recurrent.recurrence === 'MONTHLY') {
    dateFrom = dateFrom.subtract(1, 'year')
  } else if (campaign.recurrent.recurrence === 'WEEKLY') {
    dateFrom = dateFrom.subtract(3, 'month')
  } else if (campaign.recurrent.recurrence === 'DAILY') {
    dateFrom = dateFrom.subtract(13, 'day')
  }

  return { dateFrom, dateTo }
}

export const fetchBounceByDays =
  ({ token }: { token: string, ... }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()
    const orchestration = state.orchestration.campaign

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.bounceLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity

    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchBounceByDaysAction = {
        type: 'FETCH_BOUNCE_BY_DAYS',
        payload: { token },
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchBounceByDays({
          project,
          token,
          ...getBounceDateRange(orchestration),
        })
        const fetchSuccessAction: FetchBounceByDaysSuccessAction = {
          type: 'FETCH_BOUNCE_BY_DAYS_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchBounceByDaysFailureAction = {
          type: 'FETCH_BOUNCE_BY_DAYS_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
