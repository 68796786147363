// @flow
import styled, { type StyledComponent } from 'styled-components'

import { BoxBody } from 'components/common/box/box.styles'

import { ProfilebaseBox } from 'com.batch/profilebase/ui/pages/profilebase.syles'

export const ChannelMetricsBox: StyledComponent<{}, {}, typeof ProfilebaseBox> = styled(
  ProfilebaseBox
)`
  ${BoxBody} {
    padding: 0 10px 20px 20px !important;
  }
`
