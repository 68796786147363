// @flow

import * as React from 'react'

import { BoxFooter, FooterBoxActions } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { LinkDoc } from 'components/styled/text'

type Props = {
  isEditing: boolean,
  cancel: () => void,
  save: () => void,
  openConfirmEdit: () => void,
  isAllowed: boolean,
  isDisabled: boolean,
  showUpdateBtn: boolean,
  isLoading: boolean,
  nbDeletion: number,
  ...
}

export const SettingsAndroidFcmFooter = ({
  isEditing,
  cancel,
  save,
  openConfirmEdit,
  isAllowed,
  isDisabled,
  showUpdateBtn,
  isLoading,
  nbDeletion,
}: Props): React.Node => {
  const openConfirmDeletionPopup = React.useCallback(() => {
    confirm({
      width: 490,
      message: (
        <article>
          <p>
            You are about to delete {nbDeletion} additional push configuration
            {nbDeletion > 1 ? 's' : ''}.
          </p>
          <p>
            If the push configuration is currently used to send notifications, any sending attempt
            will fail.
          </p>
        </article>
      ),
      sensitive: true,
      title: 'Confirm service account key deletion',
    }).then(
      () => {
        save()
      },
      () => {}
    )
  }, [nbDeletion, save])

  const handleOnclick = React.useCallback(() => {
    if (nbDeletion > 0) {
      openConfirmDeletionPopup()
    } else {
      save()
    }
  }, [nbDeletion, openConfirmDeletionPopup, save])

  return (
    <BoxFooter isEditable={isEditing}>
      {isEditing && (
        <Button kind="inline" onClick={cancel}>
          Cancel
        </Button>
      )}
      <FooterBoxActions>
        <LinkDoc
          intent="action"
          href="https://doc.batch.com/android/prerequisites.html"
          target="_blank"
          style={{ marginRight: 8 }}
        >
          Documentation
        </LinkDoc>
        {showUpdateBtn && (
          <PermissionButton
            intent="action"
            kind="primary"
            onClick={openConfirmEdit}
            isAllowed={isAllowed}
            disabled={isDisabled}
          >
            Update FCM config
          </PermissionButton>
        )}
        {isEditing && (
          <Button
            type="button"
            kind="primary"
            intent="action"
            onClick={handleOnclick}
            disabled={isLoading}
          >
            Save FCM config
          </Button>
        )}
      </FooterBoxActions>
    </BoxFooter>
  )
}
