// @flow
import { type List, type Map, type Set } from 'immutable'

import {
  type JourneyNodeRecord,
  type MessageNodeRecord,
  type RandomNodeRecord,
  type NodeType,
  type BranchId,
} from '../models/journey.records'

import { type HandledNodesType } from 'com.batch/orchestration-journey/ui/components/nodes'
import { type settingsTabKind } from 'com.batch/orchestration-journey/ui/pages/journey-canvas-form'

export type InterractionCallbacks = {
  onInsertNode: ({
    branchIds: Array<BranchId>,
    nodeType: NodeType,
    channel: ?ChannelUntilCleanup,
  }) => void,
  onPasteNode: (Array<BranchId>) => void,
  onRemoveNode: (nodeId: string) => void,
  removeCancelEvents: (nodeId: string) => void,
  openSettingsSheet: (tab: settingsTabKind) => void,
  openYesNoSheet: (nodeId: string) => void,
  openTimerSheet: string => void,
  openMessageSheet: MessageNodeRecord => void,
  openRandomSheet: (RandomNodeRecord, string) => void,
}
export type FlowRestrictions = {
  maxMessageReached: boolean,
  availableChannels: Set<ChannelUntilCleanup>,
}
export type Position = { x: number, y: number, ... }
export const NoPosition: { position: Position } = { position: { x: 0, y: 0 } }
export type ReactFlowNode = {
  ...HandledNodesType,
  id: string,
  sizingType: 'TIMER' | 'MESSAGE' | 'FINAL' | 'YESNO' | 'RANDOM' | 'add' | 'rejoin' | 'ROOT',
  position: Position,
  style?: { [string]: string | number, ... },
  width?: number,
  height?: number,
}
export type ReactFlowEdge = {
  id?: string,
  type: 'default' | 'buttonEdge' | 'smoothstep' | 'step' | 'straight',
  source: string,
  target: string,
  sourceHandle?: string,
  targetHandle?: string,
  pathOptions?: { borderRadius: number, ... },
  style?: { [string]: string | number, ... },
  markerEnd?:
    | {
        width: number,
        height: number,
        type: string,
        ...
      }
    | string,
}

export type FormatterStaticProps = {
  nodes: Map<string, JourneyNodeRecord>,
  callbacks: InterractionCallbacks,
  flowRestrictions: FlowRestrictions,
}

export type FormatterReturnedProps = {
  reactflowNodes: Array<ReactFlowNode>,
  reactflowEdges: Array<ReactFlowEdge>,
}

export type FormatterInternalProps = {
  nodeId: string,
  resumeRenderingAt: ?string,
  stopRenderAt: List<string>,
  ownedBy: BranchId,
  rejoinInsertAfterBranchIds: List<BranchId>,
}

export type FormatterProps = {
  ...FormatterStaticProps,
  ...FormatterInternalProps,
  ...FormatterReturnedProps,
}
