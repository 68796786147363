// @flow

import { List } from 'immutable'

import { computeRate } from './compute-rate'

import { type MailboxProviderDetail } from 'com.batch//shared/infra/types/grpc-stats-service'
import {
  BounceProviderCategoryFactory,
  BounceProviderFactory,
  type BounceProviderRecord,
  BounceProviderCategoryDataFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'

export const parseBouncesByProvider = (
  mailboxProviderDetails: ?MailboxProviderDetail
): List<BounceProviderRecord> =>
  mailboxProviderDetails
    ? new List().push(
        ...mailboxProviderDetails.map(provider => {
          const softBounce = provider.softBounce ?? 0
          const hardBounce = provider.hardBounce ?? 0
          const blockBounce = provider.blockBounce ?? 0
          const sumBounce = softBounce + hardBounce + blockBounce
          const bounceRate = computeRate(sumBounce, provider.sent)
          return BounceProviderFactory({
            name: provider.name,
            count: sumBounce,
            sent: provider.sent,
            rate: bounceRate,
            categories: BounceProviderCategoryFactory({
              SOFT: BounceProviderCategoryDataFactory({ count: softBounce }),
              HARD: BounceProviderCategoryDataFactory({ count: hardBounce }),
              BLOCK: BounceProviderCategoryDataFactory({ count: blockBounce }),
            }),
          })
        })
      )
    : new List()
