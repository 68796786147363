// @flow

import { type Map } from 'immutable'

import { removeInvalidCondition } from './query.api'
import {
  ConditionFactory,
  FunctionParamsFactory,
  LogicalNodeFactory,
  type QueryRecord,
  type ConditionInputRecord,
  type ConditionRecord,
  type LogicalNodeRecord,
} from './query.records'
import * as FUNCTIONS from './query.records.functions'

function getValue(value: ConditionInputRecord): any {
  switch (value.mode) {
    case 'InputInteger':
    case 'InputFloat':
      return value.number
    case 'InputBoolean':
      return value.boolean
    case 'InputAge':
      return `${value.age.value}${value.age.unit}`
    case 'InputDate':
      return value.date.value !== null ? value.date.value.unix() : null
    case 'InputAudience':
    case 'InputStringList':
    case 'InputDeviceList':
      return value.stringList.toArray()
    case 'InputPrettyList':
      return value.numberList.toArray()
    case 'InputGoogleMap':
      return true
    default:
      return value.string
  }
}

function formatLeft(condition: ConditionRecord): string {
  if (condition.value.mode === 'InputGoogleMap') {
    const fp = condition.functionParams ?? FunctionParamsFactory()
    return `isNear(lat:${fp.lat}, lng:${fp.lng}, radius:${fp.radius}m, expiration:12h)`
  }
  let left = condition.attribute?.api ?? ''
  let funcs = condition.functions.toArray()
  while (funcs.length > 0) {
    const func = funcs.pop()
    if (
      (func === FUNCTIONS.CountFunction || func === FUNCTIONS.LastFunction) &&
      condition.attribute?.type === 'EVENT' &&
      condition.functionParams?.eventFilterOn &&
      condition.functionParams?.eventFilterValue
    ) {
      // si on est sur attribut de type event, avec une fonction pouvant prendre un filtre, & qu'un filtre est renseigné :
      const filterOn = condition.functionParams?.eventFilterOn ?? ''
      const filterValue = condition.functionParams?.eventFilterValue ?? ''
      left = `${func.value}(${left}, ${
        filterOn === '__TAG__' ? 'tag' : filterOn === '__LABEL__' ? 'label' : filterOn
      }:'${filterValue.replace(/'/g, "''")}')`
    } else {
      left = `${func.value}(${left})`
    }
  }
  return left
}

function formatCondition(condition: ConditionRecord): ?{ ... } {
  return {
    [formatLeft(condition)]: {
      [`$${condition.operator.value}`]: getValue(condition.value),
    },
  }
}

function formatNodeOrCondition(
  nodeOrConditionId: LogicalNodeRecord | string,
  conditions: Map<string, ConditionRecord>
): ?{ ... } {
  return typeof nodeOrConditionId === 'string'
    ? formatCondition(conditions.get(nodeOrConditionId, ConditionFactory()))
    : formatTree(nodeOrConditionId, conditions)
}

function formatTree(node: LogicalNodeRecord, conditions: Map<string, ConditionRecord>): ?{ ... } {
  if (node.descendants.size === 1) {
    if (node.value === 'not') {
      return {
        $not: formatNodeOrCondition(node.descendants.get(0, LogicalNodeFactory()), conditions),
      }
    }
    // we can't have an array with only 1 element for $or / $and
    return formatNodeOrCondition(node.descendants.get(0, LogicalNodeFactory()), conditions)
  }
  if (node.descendants.size === 0) return
  return {
    [`$${node.value}`]: node.descendants
      .map(nodeOrId => formatNodeOrCondition(nodeOrId, conditions))
      .toArray(),
  }
}

export function formatQueryForAPI(query: QueryRecord): ?{ ... } {
  const clean = removeInvalidCondition(query)
  return formatTree(clean.tree, clean.conditions)
}
