// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { OptionDesc, OptionText } from 'components/campaign/cta-builder.helper'
import { Grid } from 'components/common/grid'
import { InputWrapper, Select } from 'components/form'

import {
  ActionFieldLabel,
  ActionPickerContainer,
} from 'com.batch/push/ui/push-action-picker/push-action-picker.styles'

type ActionType = {
  label: string,
  description: string,
  value: 'nothing' | 'deeplink' | 'landing',
}

type ActionPickerProps = {
  value: ActionType,
  onChange: (option: ?ActionType) => any,
  options: List<ActionType>,
  children?: React.Node,
}

const actionIcons = {
  nothing: '/medias/img/builders/icons/redirect.svg',
  deeplink: '/medias/img/builders/icons/deeplink.svg',
  landing: '/medias/img/builders/icons/redirect.svg',
}

const formatter = (option: ?ActionType, { context }: { context: 'value' | 'menu' }) => {
  if (!option) return null

  return context === 'value' ? (
    <ActionFieldLabel>{option?.label}</ActionFieldLabel>
  ) : (
    <Grid template="30px 1fr">
      <img
        src={actionIcons[option.value]}
        className="styled-icon"
        style={{ width: 30, height: 30 }}
      />
      <div>
        <OptionText>{option?.label}</OptionText>
        <OptionDesc>{option?.description}</OptionDesc>
      </div>
    </Grid>
  )
}

export const PushActionPicker: React.ComponentType<ActionPickerProps> = (
  props: ActionPickerProps
) => {
  const optToString = React.useCallback(opt => opt?.value ?? 'nothing', [])
  return (
    <ActionPickerContainer>
      <InputWrapper label="On click" style={{ width: 220 }}>
        <Select
          value={props.value}
          onChange={props.onChange}
          options={props.options}
          optionFormatter={formatter}
          optionMenuHeight={56}
          optionToString={optToString}
          menuOffset={280}
          style={{ marginRight: 8 }}
        />
      </InputWrapper>

      {props.children}
    </ActionPickerContainer>
  )
}
