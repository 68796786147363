// @flow

import * as React from 'react'

import { useDebounce } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  FooterBoxActions,
  BoxBody,
  BoxFooter,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { FilterSearch } from 'components/filter'

import config from 'com.batch.common/config'

type PopinMapProps = {
  search: string,
  setSearch: string => void,
  lat: number,
  lng: number,
  radius: number,
  closeModal: () => void,
  updatePosition: (number, number) => void,
  ...
}

export const PopinMap = ({
  search,
  setSearch,
  closeModal,
  radius,
  updatePosition,
  lat,
  lng,
}: PopinMapProps): React.Node => {
  const mapElement = React.createRef()
  const map = React.useRef()
  const circle = React.useRef()
  const marker = React.useRef()
  const geocoder = React.useRef()
  const [localLat, setLocalLat] = React.useState(lat)
  const [localLng, setLocalLng] = React.useState(lng)
  const [loading, setLoading] = React.useState(false)

  const currentRadius = React.useRef(radius)
  React.useEffect(() => {
    currentRadius.current = radius
  })

  const updateMapPosition = React.useCallback((position: { lat: number, lng: number, ... }) => {
    if (map.current && !isNaN(position.lat) && !isNaN(position.lng)) {
      map.current?.panTo(position)
      if (!circle.current) {
        circle.current = new window.google.maps.Circle({
          center: position,
          strokeColor: '#00A1E0',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#00A1E0',
          fillOpacity: 0.25,
          radius: currentRadius.current,
          map: map.current,
        })
      } else {
        circle.current?.setCenter(position)
        circle.current?.setRadius(currentRadius.current)
      }
      circle.current?.addListener('click', onPositionChanged)
      if (!marker.current) {
        marker.current = new window.google.maps.Marker({
          position: position,
          map: map.current,
        })
      } else {
        marker.current?.setPosition(position)
      }
      marker.current?.addListener('click', onPositionChanged)

      if (circle.current && typeof circle.current.getBounds === 'function') {
        map.current?.fitBounds(circle.current?.getBounds())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPositionChanged = React.useCallback(
    event => {
      setLocalLat(event.latLng.lat())
      setLocalLng(event.latLng.lng())
      updateMapPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() })
    },
    [updateMapPosition]
  )

  const update = React.useCallback(() => {
    updatePosition(localLat, localLng)
    closeModal()
  }, [closeModal, localLat, localLng, updatePosition])

  const requestGeocode = useDebounce(
    (query: string) => {
      if (geocoder.current) setLoading(true)
      geocoder.current?.geocode({ address: query }, (results, status) => {
        setLoading(false)
        if (status == 'OK') {
          setSearch('')
          onPositionChanged({ latLng: results[0].geometry.location })
        }
      })
    },
    1000,
    true
  )

  const lookup = React.useCallback(
    (search: string) => {
      setSearch(search)
      requestGeocode(search)
    },
    [requestGeocode, setSearch]
  )

  const onMapApiAvailable = React.useCallback(() => {
    // will timeout loop until gmap api is available
    if (typeof window.google?.maps === 'undefined') {
      setTimeout(onMapApiAvailable, 200)
      return () => {}
    }
    if (!map.current) {
      geocoder.current = new window.google.maps.Geocoder()
      const m = new window.google.maps.Map(mapElement.current, {
        zoom: 9,
        center: {
          lat: isNaN(localLat) ? 48.85341 : localLat,
          lng: isNaN(localLng) ? 2.3488 : localLng,
        },
        clickableIcons: false,
        mapTypeControl: false,
        streetViewControl: false,
      })
      m.addListener('click', onPositionChanged)
      map.current = m
      updateMapPosition({ lat: localLat, lng: localLng })
    }
  }, [localLat, localLng, mapElement, updateMapPosition, onPositionChanged])

  React.useEffect(() => {
    // will append the tag if not already present
    if (!document.getElementById('google-map-script')) {
      const tag = document.createElement('script')
      tag.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&libraries=&v=weekly`
      tag.id = 'google-map-script'
      document.body?.appendChild(tag)
    }
    onMapApiAvailable()
  }, [onMapApiAvailable])
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="Targeting" />
      </BoxHeader>
      <BoxBody>
        <form
          onSubmit={evt => {
            evt.preventDefault()
            lookup(search)
          }}
        >
          <Grid template="1fr 100px" style={{ margin: 20 }}>
            <FilterSearch
              placeholder="Search an address"
              value={search}
              onChange={setSearch}
              disabled={loading}
              expandable={false}
            />
            <Button kind="inline" isLoading={loading} type="submit">
              Search
            </Button>
          </Grid>
        </form>
        <div ref={mapElement} style={{ height: 300 }} />
      </BoxBody>
      <BoxFooter isEditable>
        <Button kind="inline" onClick={closeModal}>
          Cancel
        </Button>
        <FooterBoxActions>
          <Button kind="primary" intent="action" onClick={update}>
            Update
          </Button>
        </FooterBoxActions>
      </BoxFooter>
    </Box>
  )
}
