// @flow
import { type Map } from 'immutable'
import * as React from 'react'

import { Separator } from 'components/styled/utils'

import {
  type InstallRecord,
  type SubscriptionRecord,
} from 'com.batch/profile/models/profile.records'
import { EmailChannelBlock } from 'com.batch/profile/ui/components/profile-hero/channel-block/email-channel-block'
import { PushChannelBlock } from 'com.batch/profile/ui/components/profile-hero/channel-block/push-channel-block'
import { SmsChannelBlock } from 'com.batch/profile/ui/components/profile-hero/channel-block/sms-channel-block'
import {
  ProfileBodyContainer,
  ProfileBodyChannels,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { ProfilePlatforms } from 'com.batch/profile/ui/components/profile-hero/profile-platforms'

type Props = {
  isLoading?: boolean,
  subscription: SubscriptionRecord,
  installs: Map<string, InstallRecord>,
  email: ?string,
  phoneNumber: ?string,
  isLoading: boolean,
  ...
}

export const ProfileHeroBody = ({
  isLoading = false,
  subscription,
  installs,
  email,
  phoneNumber,
}: Props): React.Node => (
  <ProfileBodyContainer>
    <ProfileBodyChannels>
      <PushChannelBlock isLoading={isLoading} installs={installs} subscription={subscription} />
      <Separator fullHeight />
      <EmailChannelBlock isLoading={isLoading} email={email} subscription={subscription} />
      <Separator fullHeight />
      <SmsChannelBlock
        isLoading={isLoading}
        phoneNumber={phoneNumber}
        subscription={subscription}
      />
    </ProfileBodyChannels>
    <ProfilePlatforms installs={installs} isLoading={isLoading} />
  </ProfileBodyContainer>
)
