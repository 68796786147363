// @flow
import { type ReduxAction } from 'com.batch.redux/_records'

export type SetEditingNodeIdAction = ReduxAction<'SET_EDITING_NODE_ID', string>

export const setEditingNodeId = (nodeId: string): SetEditingNodeIdAction => {
  const action: SetEditingNodeIdAction = {
    type: 'SET_EDITING_NODE_ID',
    payload: nodeId,
  }

  return action
}
