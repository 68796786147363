// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  profileDataStateSelector,
  lastVisitInstallByPlatformSelector,
} from 'com.batch/profile/store/profile.selector'

import { type IdentityRecord } from 'com.batch/profile/models/profile.records'
import { ProfileHeroBody } from 'com.batch/profile/ui/components/profile-hero/profile-hero-body'
import { ProfileHeroFooter } from 'com.batch/profile/ui/components/profile-hero/profile-hero-footer'
import { ProfileHeroHeader } from 'com.batch/profile/ui/components/profile-hero/profile-hero-header'
import { ProfileHeroContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { CollapsePanel } from 'com.batch/shared/ui/component/collapse/collapse'

type Props = {
  identity: IdentityRecord,
  isLoading: boolean,
  profileId: string,
  isRefresh: boolean,
  dataLoaded: boolean,
  hasError: boolean,
}

export const ProfileHero = ({
  isLoading,
  identity,
  profileId,
  isRefresh,
  dataLoaded,
  hasError,
}: Props): React.Node => {
  const profileData = useSelector(profileDataStateSelector)
  const installs = useSelector(lastVisitInstallByPlatformSelector)
  const [isBodyShown, setIsBodyShown] = React.useState<boolean>(false)
  const [transitionDuration, setTransitionDuration] = React.useState(150)
  const [profilePhoneNumber, setProfilePhoneNumber] = React.useState('')
  React.useEffect(() => {
    if (profileData.phoneNumber)
      import(
        /* webpackChunkName: "libphonenumber" */
        /* webpackMode: "lazy" */
        'libphonenumber-js').then(parsePhoneNumber => {
        setProfilePhoneNumber(parsePhoneNumber(profileData.phoneNumber).formatInternational())
      })
  }, [profileData.phoneNumber])

  const handleOnToggle = React.useCallback((isToggled: boolean) => {
    setIsBodyShown(isToggled)
    setTransitionDuration(150)
  }, [])

  React.useEffect(() => {
    if ((dataLoaded && !isRefresh) || hasError) {
      setIsBodyShown(false)
      setTransitionDuration(0)
    }
  }, [dataLoaded, isRefresh, hasError])

  return (
    <ProfileHeroContainer>
      <ProfileHeroHeader
        isLoading={isLoading}
        identity={identity}
        email={profileData.email}
        profileId={profileId}
        timezone={profileData.timezone}
        lastVisitDate={profileData.lastVisit.date}
      />
      <CollapsePanel isOpened={isBodyShown} duration={transitionDuration}>
        <ProfileHeroBody
          installs={installs}
          email={profileData.email}
          phoneNumber={profilePhoneNumber}
          subscription={profileData.subscription}
          isLoading={isLoading}
        />
      </CollapsePanel>
      <ProfileHeroFooter
        isBodyShown={isBodyShown}
        onToggle={handleOnToggle}
        email={profileData.email}
        phoneNumber={profilePhoneNumber}
        installs={installs}
      />
    </ProfileHeroContainer>
  )
}
