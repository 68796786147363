// @flow

import * as React from 'react'

import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'

import { type settingsTabKind } from '../pages/journey-canvas-form'

type SheetSettingsTabsProps = {
  tab: settingsTabKind,
  handleSelectTab: (tab: settingsTabKind) => () => void,
  ...
}

export const sheetWidthsForTabs: { [settingsTabKind]: number, ... } = {
  enter: 600,
  targeting: 1200,
  timings: 500,
  'quiet-hours': 500,
}

export const SheetSettingsTabs = ({ tab, handleSelectTab }: SheetSettingsTabsProps): React.Node => {
  return (
    <React.Fragment>
      <Button addOn="prefix" onClick={handleSelectTab('enter')} isActive={tab === 'enter'}>
        <Icon icon="mouse" />
        Entry event
      </Button>
      <Button
        style={{ marginLeft: 8 }}
        onClick={handleSelectTab('targeting')}
        data-testid="button_tab_targeting"
        isActive={tab === 'targeting'}
        addOn="prefix"
      >
        <Icon icon="targeting" />
        Targeting
      </Button>

      <Button
        style={{ marginLeft: 8 }}
        onClick={handleSelectTab('timings')}
        data-testid="button_tab_timings"
        isActive={tab === 'timings'}
        addOn="prefix"
      >
        <Icon icon="timer" />
        Timing
      </Button>
      <Button
        onClick={handleSelectTab('quiet-hours')}
        data-testid="button_tab_quiethours"
        isActive={tab === 'quiet-hours'}
        style={{ marginLeft: 8 }}
        addOn="prefix"
      >
        <Icon icon="quiet-hours" />
        Quiet hours
      </Button>
    </React.Fragment>
  )
}
