// @flow
import Immutable, { type Set } from 'immutable'

import { type DateRange } from 'components/common/date-range-picker'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type CampaignListSortableBy } from 'com.batch/orchestration-list/store/campaign-list.state'

export const extractUrlParamsAndRestoreSavedSort = (location: {
  search: string,
  pathname: string,
  ...
}): {
  mode: schedulingType,
  search: string,
  page: number,
  sortBy: CampaignListSortableBy,
  sortDirection: 'asc' | 'dsc',
  statuses: Set<campaignStateType>,
  channels: Set<ChannelUntilCleanup>,
  labels: Set<string>,
  dateRange: ?DateRange,
  ...
} => {
  const qs = new URLSearchParams(location.search)
  const page = parseInt(qs.get('page') || '1')
  const search = qs.get('search') || ''
  const mode: schedulingType = location.pathname?.includes('/campaigns')
    ? 'campaigns'
    : 'automations'

  const statusesString =
    qs
      .get('statuses')
      ?.split(',')
      .filter(status => status) ?? []
  const statuses = Immutable.Set.of(...statusesString.map(parseStatusParam).filter(Boolean))

  const channelString =
    qs
      .get('channels')
      ?.split(',')
      .filter(channel => channel) ?? []
  const channels = Immutable.Set.of(...channelString.map(parseChannelParam).filter(Boolean))

  const labelString = qs.get('labels') ?? ''
  const labels = Immutable.Set.of(...labelString.split(',').filter(label => label))

  const fromString = qs.get('from') ?? ''
  const toString = qs.get('to') ?? ''
  const dateRange = fromString && toString ? { from: dayjs(fromString), to: dayjs(toString) } : null

  const projectId = window?.initialData?.projectId ?? ''
  const defaultSortDirection = localStorage.getItem(`${projectId}-${mode}-sortDirection`) ?? 'dsc'
  const defaultSortBy = localStorage.getItem(`${projectId}-${mode}-sortBy`) ?? 'token'
  const coercedSortDirection = defaultSortDirection === 'asc' ? 'asc' : 'dsc'
  const coersedSortBy =
    defaultSortBy === 'name' ? 'name' : defaultSortBy === 'token' ? 'token' : 'when'
  return {
    page,
    mode,
    sortDirection: qs.get('sortDirection') === 'asc' ? 'asc' : coercedSortDirection,
    sortBy:
      qs.get('sortBy') === 'name' ? 'name' : qs.get('sortBy') === 'when' ? 'when' : coersedSortBy,
    search,
    statuses,
    channels,
    labels,
    dateRange,
  }
}

const parseStatusParam = (status: string): campaignStateType | null => {
  switch (status) {
    case 'draft':
      return 'DRAFT'
    case 'running':
      return 'RUNNING'
    case 'stopped':
      return 'STOPPED'
    case 'completed':
      return 'COMPLETED'
    default:
      return null
  }
}

const parseChannelParam = (channel: string): ChannelUntilCleanup | null => {
  switch (channel) {
    case 'push':
      return 'push'
    case 'sms':
      return 'sms'
    case 'email':
      return 'email'
    default:
      return null
  }
}
