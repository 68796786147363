// @flow

/* legacy navigation */

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { useWidthObserver } from 'components/_hooks'
import { ButtonLink, Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { NavbarContainer, NavbarMenu, Separator } from 'components/project/navigation/navbar.styles'

import { useBuildLegacyNav } from 'com.batch.common/router'

import { type AppRecord } from 'com.batch.redux/_records'
import { openIntegrate } from 'com.batch.redux/app.action'

type NavbarProps = {
  app: AppRecord,
  showIntegrate: boolean,
}

const Navbar: React$AbstractComponent<NavbarProps, *> = React.memo(
  ({ app, showIntegrate }: NavbarProps): React.Node => {
    const dispatch = useDispatch()
    const ref = React.useRef(null)
    const width = useWidthObserver(ref, 1200)
    const breakpoint = React.useMemo(
      () => (width < 380 ? 'small' : width < 680 ? 'medium' : 'large'),
      [width]
    )
    const onIntegrateClick = React.useCallback(() => dispatch(openIntegrate()), [dispatch])

    const navData = useBuildLegacyNav(app)

    return (
      <NavbarContainer ref={ref} $breakpoint={breakpoint}>
        <NavbarMenu className="navbar-navigation styled-react-to-breakpoint">
          {navData.map(({ label, isActive, url, icon }) => (
            <Tooltip key={label} isTooltipEmpty={breakpoint === 'large'} tooltip={label}>
              <ButtonLink
                href={url}
                isActive={isActive}
                kind="inlineDark"
                intent="neutral"
                aria-current="page"
                role="link"
                data-testid={`navbar-${label.toLowerCase()}`}
                addOn={icon ? 'prefix' : undefined}
                addOnGap={icon ? 10 : 0}
              >
                {icon && <Icon icon={icon} />}
                {icon ? <span className="styled-hide-on-medium">{label}</span> : label}
              </ButtonLink>
            </Tooltip>
          ))}
        </NavbarMenu>
        {showIntegrate && (
          <NavbarMenu className="navbar-navigation styled-react-to-breakpoint">
            <Separator />
            <Button
              onClick={onIntegrateClick}
              kind="inlineDark"
              intent="neutral"
              style={{ marginLeft: 12 }}
            >
              Integrate
            </Button>
          </NavbarMenu>
        )}
      </NavbarContainer>
    )
  }
)

export { Navbar }
