// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

import { type JourneySettingsRecord } from 'com.batch/orchestration-journey/models/journey.records'

export type UpdateSettingsAction = ReduxAction<'UPDATE_JOURNEY_SETTINGS', JourneySettingsRecord>

export const updateSettings = (settings: JourneySettingsRecord): UpdateSettingsAction => {
  return {
    type: 'UPDATE_JOURNEY_SETTINGS',
    payload: settings,
  }
}
