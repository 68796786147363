// @flow
import * as Immutable from 'immutable'
import { type List } from 'immutable'
import { createSelector } from 'reselect'

import { type MessageStateRecord } from './message.state'

import {
  LanguageFactory,
  type LanguageRecord,
  type State,
  type Extract,
} from 'com.batch.redux/_records'

export const fakeDefaultLanguage: LanguageRecord = LanguageFactory({
  value: 'default',
  label: 'Default',
})
// @todo Le multi-lang n'est pas encore dispo sur Rome: réécrire ce selecteur quand ce sera le cas
const tempResponseRewriteWhenTranslationAvail = new Immutable.List().push(fakeDefaultLanguage)
export const translationsWithDefaultSelector: (state: State) => List<LanguageRecord> = () =>
  tempResponseRewriteWhenTranslationAvail
export type MessageIdGetterSelector<T> = State => (messageId: string) => T

export const getMessageKey = (lang: LanguageRecord): string =>
  `Message${lang.value === 'default' ? '' : ` (${lang.label.toLowerCase()})`}`

export const messageStateSelector: Extract<MessageStateRecord> = state => state.message
export const previewLanguageSelector: Extract<string> = createSelector(
  messageStateSelector,
  messageState => messageState.previewLanguage
)
