// @flow

import Immutable from 'immutable'
import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import config from 'com.batch.common/config'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router/router'

import {
  type CompanyRecord,
  type GDPRRecord,
  CompanyFactory,
  GDPRRecordFactory,
  type SAMLProps,
  SAMLRecordFactory,
} from 'com.batch.redux/_records'
// eslint-disable-next-line import/no-cycle
import { getPlanFromCode } from 'com.batch.redux/billing.api'
import { BillingFactory } from 'com.batch.redux/billing.records'
import { EntityLogFactory } from 'com.batch.redux/user.records'

export const normalizeCompany: (raw: {
  name: string,
  deletedAt: string,
  avatarUrl: ?string,
  billingCompanyName: ?string,
  id: number,
  externalId: string,
  additionalFeatureCodes: ?Array<FeatureCode>,
  enforce2FA: boolean,
  plan: string,
  overridedSeats: ?number,
  overcharge: ?boolean,
  seats?: number,
  usedSeats?: number,
  trialing: ?string,
  disabledFeatureCodes: ?Array<FeatureCode>,
  planFeatures: ?Array<FeatureCode>,
  logs?: Array<{
    createdAt: string,
    editor: string,
    topic: 'security' | 'info',
    message: string,
    ...
  }>,
  currency: 'USD' | 'EUR',
  subscriptionId: ?string,
  GDPRCategories: Array<string>,
  permissions: Array<string>,
  GDPRcreated: Date,
  billingFirstname: ?string,
  billingLastname: ?string,
  billingAddress: ?string,
  billingAddress2: ?string,
  billingVatNumber: ?string,
  billingVatRate: ?number,
  billingCycle: 'month' | 'year' | null,
  billingZip: ?string,
  billingCity: ?string,
  salesforceId?: ?string,
  billingCountry: ?string,
  subscriptionStatus: ?(
    | 'trialing'
    | 'active'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'canceled'
  ),
  nextInvoiceDate: ?string,
  nextCycleAction: ?string,
  trialingUntil: ?string,
  GDPRupdated: Date,
  GDPRoptOut: boolean,
  GDPRexternal: boolean,
  dpoName: ?string,
  dpoEmail: ?string,
  saml: ?SAMLProps,
  ...
}) => CompanyRecord = raw => {
  const now = new Date()
  let downgradingTo = null
  let downgradingCycle = 'monthly'
  if (raw.nextCycleAction) {
    const [, plan, cycle] = raw.nextCycleAction.split('-')
    downgradingTo = getPlanFromCode(plan)
    downgradingCycle = cycle === 'month' ? 'monthly' : 'yearly'
  }

  const plan = getPlanFromCode(raw.plan)
  const trial = raw.trialing ? getPlanFromCode(raw.trialing) : null
  const trialUntil = raw.trialingUntil
    ? dayjs.utc(raw.trialingUntil ?? '', 'YYYY-MM-DD HH:mm')
    : null
  const trialIsActive =
    trial !== null &&
    typeof trial !== 'undefined' &&
    trial.weight > plan.weight &&
    (!trialUntil || trialUntil.isAfter(now))

  const billing = BillingFactory({
    timestamp: now.getTime(),
    companyName: raw.billingCompanyName || raw.name || '',
    address: raw.billingAddress || '',
    address2: raw.billingAddress2 || '',
    zip: raw.billingZip || '',
    city: raw.billingCity || '',
    country: raw.billingCountry || '',
    overcharge: typeof raw.overcharge === 'boolean' ? raw.overcharge : false,
    firstname: raw.billingFirstname || '',
    lastname: raw.billingLastname || '',
    vatNumber: raw.billingVatNumber || '',
    vatRate: raw.billingVatRate || 0,
    downgradingTo,
    downgradingCycle,
    subscriptionId: raw.subscriptionId,
    subscriptionStatus: raw.subscriptionStatus || 'none',
    plan,
    trial,
    trialIsActive,
    trialUntil,
    cycle: raw.billingCycle === 'month' ? 'monthly' : 'yearly',
    nextInvoice: raw.nextInvoiceDate
      ? dayjs.utc(raw.nextInvoiceDate ?? '', 'YYYY-MM-DD HH:mm')
      : null,
    currency: !!raw.subscriptionId && raw.currency === 'USD' ? 'usd' : 'eur', // we default to EUR, unless locked by stripe
  })
  const gdpr = GDPRRecordFactory({
    name: raw.billingCompanyName || raw.name || '',
    categories: Immutable.Set(
      !Array.isArray(raw.GDPRCategories) || raw.GDPRCategories.length === 0
        ? ['Logs data (eg: last connection)']
        : raw.GDPRCategories
    ),
    created: raw.GDPRcreated ? dayjs.utc(raw.GDPRcreated) : null,
    updated: raw.GDPRupdated ? dayjs.utc(raw.GDPRupdated) : null,
    address: raw.billingAddress || '',
    address2: raw.billingAddress2 || '',
    zip: raw.billingZip || '',
    city: raw.billingCity || '',
    country: raw.billingCountry || '',
    disabled: raw.GDPRoptOut,
    external: raw.GDPRexternal,
    dpoName: raw.dpoName || '',
    dpoEmail: raw.dpoEmail || '',
    companyId: raw.id,
  })
  const saml =
    raw.saml !== null
      ? SAMLRecordFactory({
          ...raw.saml,
          certificateExpiration: raw.saml?.certificateExpiration
            ? dayjs.utc(raw.saml.certificateExpiration)
            : null,
        })
      : null
  let props = {
    name: raw.name,
    deletedAt: raw.deletedAt ? dayjs.utc(raw.deletedAt, 'YYYY-MM-DD') : null,
    avatarUrl: raw.avatarUrl,
    logs: new Immutable.List().push(
      ...(typeof raw.logs !== 'undefined'
        ? raw.logs.reverse().map(rl => {
            return EntityLogFactory({
              when: dayjs.utc(rl.createdAt),
              editor: rl.editor,
              message: rl.message,
              topic: rl.topic,
            })
          })
        : [])
    ),
    billing,
    gdpr,
    sfid: typeof raw.salesforceId === 'string' ? raw.salesforceId : null,
    seats: typeof raw.seats === 'number' ? raw.seats : 0,
    usedSeats: typeof raw.usedSeats === 'number' ? raw.usedSeats : 0,
    overridedSeats: typeof raw.overridedSeats === 'number' ? raw.overridedSeats : null,
    enforce2FA: raw.enforce2FA,
    hasEditorialDashboard:
      typeof raw.permissions !== 'undefined' &&
      Array.isArray(raw.permissions) &&
      raw.permissions.indexOf('editorial:permissions') !== -1,
    id: raw.id,
    externalId: raw.externalId,
    restKey: _get(raw, 'restKey'),
    plan: raw.plan || null,
    planFeaturesCode: Immutable.OrderedSet(raw.planFeatures || []),
    disabledFeaturesCode: Immutable.OrderedSet(raw.disabledFeatureCodes || []),
    additionalFeaturesCode: Immutable.OrderedSet(raw.additionalFeatureCodes || []),
    webpushEnabled:
      (!!raw.additionalFeatureCodes && raw.additionalFeatureCodes.indexOf('webpush') !== -1) ||
      (!!raw.planFeatures && raw.planFeatures.indexOf('webpush') !== -1),
    saml,
  }

  return CompanyFactory(props)
}

export const saveGDPR: (company: CompanyRecord, data: GDPRRecord) => Promise<CompanyRecord> = (
  company,
  data
) => {
  return request.post(generateUrl('api_company_gdpr_update', { companyId: company.id }), data).then(
    response => {
      return normalizeCompany(response.body)
    },
    () => {
      throw { error: 'Unable to save GDPR information' }
    }
  )
}

export const fetch = (id: number): Promise<CompanyRecord> => {
  const url = config.common.urls.fetchCompany.replace('{companyId}', id)
  return request.get(url).then(
    response => {
      return normalizeCompany(response.body)
    },
    () => {
      throw { error: 'Unable to retrieve your company, please reload the page in a few seconds' }
    }
  )
}
export const updateCompany = (company: CompanyRecord): Promise<CompanyRecord> => {
  return request.post(generateUrl('api_company_update', { companyId: company.id }), company).then(
    response => {
      return normalizeCompany(response.body)
    },
    response => {
      throw {
        company,
        errors: response.body.errors,
      }
    }
  )
}

export const resetCompanyRestApiKey = (company: CompanyRecord): Promise<CompanyRecord> => {
  return request.put(generateUrl('api_company_reset_apikey', { companyId: company.id })).then(
    response => {
      return normalizeCompany(response.body)
    },
    response => {
      throw {
        company,
        errors: response.body.errors,
      }
    }
  )
}

export default {
  normalize: normalizeCompany,
  updateCompany,
  resetCompanyRestApiKey,
  saveGDPR,
  fetch,
}
