// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'

import { Button } from 'components/common/button'
import { Form, InputWrapper, FormRow, Input, FormActions } from 'components/form'

import { isEmail } from 'com.batch.common/utils'

import {
  Title,
  AccountSection,
  SectionTitle,
  SectionInfo,
  SectionForm,
  Note,
} from './account.styles'

import { type State } from 'com.batch.redux/_records'
import {
  type updateUserSuccessAction,
  type updateUserFailureAction,
  updateUser,
} from 'com.batch.redux/user'
import { type UserRecord } from 'com.batch.redux/user.records'
import { currentUserSelector } from 'com.batch.redux/user.selector'

type StateProps = {
  user: UserRecord,
}
type DispatchProps = {
  updateUser: (user: UserRecord) => Promise<updateUserFailureAction | updateUserSuccessAction>,
}

type SettingsProps = { ...StateProps, ...DispatchProps }

const SettingsRaw = ({ user, updateUser }: SettingsProps) => {
  const [editedUser, setEditedUser] = React.useState<UserRecord>(user)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const onSubmit = () => {
    let err = []
    if (editedUser.firstName.trim() === '') err.push(['firstName', 'Please enter a valid name.'])
    else if (editedUser.firstName.trim().length < 2)
      err.push(['firstName', 'This first name is too short.'])

    if (editedUser.lastName.trim() === '') err.push(['lastName', 'Please enter a valid name.'])
    else if (editedUser.lastName.trim().length < 2)
      err.push(['lastName', 'This last name is too short.'])
    if (!isEmail(editedUser.email)) err.push(['email', 'Please provide a valid email address.'])
    const errors = Immutable.Map(err)
    if (errors.size === 0) {
      updateUser(editedUser).then(console.log, console.warn)
    }
    setErrors(errors)
  }

  return (
    <React.Fragment>
      <Title>Account settings</Title>
      <AccountSection>
        <SectionInfo>
          <SectionTitle>Information</SectionTitle>
        </SectionInfo>
        <SectionForm>
          <Form horizontal onSubmit={onSubmit}>
            <InputWrapper
              label="Full name"
              htmlFor="first"
              feedback={errors.get('firstName', errors.get('lastName'))}
            >
              <FormRow>
                <Input
                  id="first"
                  placeholder="First name"
                  invalid={errors.has('firstName')}
                  value={editedUser.firstName}
                  onChange={evt => setEditedUser(editedUser.set('firstName', evt.target.value))}
                />
                <Input
                  placeholder="Last name"
                  invalid={errors.has('lastName')}
                  value={editedUser.lastName}
                  onChange={evt => setEditedUser(editedUser.set('lastName', evt.target.value))}
                />
              </FormRow>
            </InputWrapper>
            <InputWrapper label="Email" feedback={errors.get('email')} htmlFor="mail">
              <Input
                id="mail"
                invalid={errors.has('email')}
                placeholder="Email"
                value={editedUser.email}
                onChange={evt => setEditedUser(editedUser.set('email', evt.target.value))}
              />
              {user.nextEmail && (
                <Note>
                  You asked us to change your email to <strong>{user.nextEmail}</strong>. The change
                  will be effective once you haved clicked the confirmation link in the email we
                  sent you.
                </Note>
              )}
            </InputWrapper>

            <FormActions>
              <Button
                intent="action"
                kind="primary"
                type="submit"
                isLoading={user.loading}
                disabled={
                  user.firstName === editedUser.firstName &&
                  user.lastName === editedUser.lastName &&
                  user.email === editedUser.email
                }
              >
                Update
              </Button>
            </FormActions>
          </Form>
        </SectionForm>
      </AccountSection>
    </React.Fragment>
  )
}
const mapStateToProps = (state: State): StateProps => {
  return {
    user: currentUserSelector(state),
  }
}

export const Settings = (connect<SettingsProps, *, _, _, _, _>(mapStateToProps, { updateUser })(
  SettingsRaw
): React.AbstractComponent<{ ... }>)
