// @flow

import { type Set } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { RemovableBlockWrapper } from './common.styles'
import { IncompleteFlag } from './message-block.styles'
import { SplitContainer, SplitBox, SplitLabel, SplitTag, SplitSources } from './split.styles'

import { NodeIconWrapper } from '../node-icon-wrapper'

import { type NodeError } from 'com.batch/orchestration-journey/models/journey.records'

export type YesNoButtonProps = {
  label: string,
  onClick: () => void,
  onRemove?: () => void,
  errors: Set<NodeError>,
  reduxNodeId: string,
  editingNodeId?: string,
}

export const YesNoButton: React.AbstractComponent<YesNoButtonProps> = React.forwardRef(
  (
    { label, onRemove, onClick, errors, reduxNodeId, editingNodeId }: YesNoButtonProps,
    ref
  ): React.Node => {
    return (
      <RemovableBlockWrapper isRemovable={Boolean(onRemove)} className="nodrag nopan">
        <SplitContainer
          ref={ref}
          className="nodrag nopan"
          onClick={onClick}
          data-testid={reduxNodeId}
        >
          {errors.includes('MISSING_TARGETING') && (
            <IncompleteFlag>
              <Icon icon="edit" />
            </IncompleteFlag>
          )}
          <SplitBox $isEditing={editingNodeId === reduxNodeId} className="nodrag nopan">
            <NodeIconWrapper kind="yesno">
              <SplitLabel className="nodrag nopan">{label ? label : 'Yes/No split'}</SplitLabel>
            </NodeIconWrapper>
          </SplitBox>
          <SplitSources className="nodrag nopan">
            <SplitTag>Yes</SplitTag>
            <SplitTag>No</SplitTag>
          </SplitSources>
        </SplitContainer>
        <div className="nodrag nopan styled-remove">
          <Button
            onClick={onRemove}
            intent="danger"
            kind="secondary"
            className="nodrag nopan"
            data-testid="button_remove_node"
          >
            <Icon icon="delete" />
          </Button>
        </div>
      </RemovableBlockWrapper>
    )
  }
)
