// @flow
import styled, { type StyledComponent } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import { TextLabel } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { CopyableText } from 'com.batch/shared/ui/component/copy/copyable.styles'

export const ProfileCopyableIdContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: block;
  max-width: 100%;

  ${CopyableText} {
    margin-top: 4px;
    font-family: 'SF Mono', monospace, serif;
    color: ${colors.textNeutral};
  }

  ${TextLabel} {
    color: ${colors.textNeutral};
  }

  ${EmptyField}:first-child {
    margin-top: 4px;
  }

  ${EmptyField}:nth-child(even) {
    margin-top: 14px;
  }
`

export const NoPushToken: StyledComponent<*, *, HTMLElement> = styled.div`
  margin-top: 6px;
  color: ${colors.textDisabled};
`
