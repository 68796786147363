// @flow
import * as React from 'react'

import { Switch } from 'components/common/button'

import config from 'com.batch.common/config'
import { generateUrl } from 'com.batch.common/router'

import { type AppRecord } from 'com.batch.redux/_records'

type LandingSwitchType = {
  hasLandingFeature: boolean,
  hasThemes: boolean,
  app: AppRecord,
  isLandingVisible: boolean,
  isLandingActive: boolean,
  toggleMode: (mode: 'landing' | 'push') => void,
  toggleLandingActive: () => void,
  ...
}

export const LandingSwitch = (props: LandingSwitchType): React.Node => {
  const {
    hasLandingFeature,
    isLandingVisible,
    isLandingActive,
    toggleMode,
    hasThemes,
    toggleLandingActive,
    app,
  } = props
  const pl15 = { paddingLeft: '15px' }
  return (
    <div style={{ padding: '15px 25px', backgroundColor: '#FAFAFC' }}>
      <ul className="funky">
        <li
          className={`btn funky__tab no-animate ${isLandingVisible ? '' : 'active'}`}
          style={{ borderRight: 0 }}
        >
          <span onClick={() => toggleMode('push')} style={pl15}>
            1. Push notification
          </span>
        </li>
        <li
          className={`btn funky__tab no-animate ${isLandingVisible ? 'active' : ''} ${
            isLandingActive ? '' : 'disabled'
          }`}
        >
          <span onClick={() => toggleMode('landing')} style={pl15}>
            2. Mobile landing (optional)
          </span>
        </li>

        {hasLandingFeature && hasThemes && (
          <span
            style={{ width: 48, borderRadius: 0 }}
            className={`funky-switch ${isLandingVisible ? 'active' : ''} ${
              isLandingActive && hasThemes ? '' : 'disabled'
            }`}
          >
            <Switch isActive={isLandingActive} onChange={toggleLandingActive} />
          </span>
        )}

        {!hasLandingFeature && (
          <span className="funky-upgrade btn btn--action">
            <i className="fa fa-lock" style={{ color: '#fff' }} />{' '}
            <a
              style={{ color: '#fff' }}
              href={generateUrl('company_billing', { companyId: app.companyId })}
            >
              Upgrade
            </a>
          </span>
        )}

        {!hasThemes && hasLandingFeature && (
          <span className="funky-upgrade btn btn--action">
            <a
              style={{ color: '#fff' }}
              href={config.common.urls.appSettingsThemeEditor
                .replace('{companyId}', app.companyId)
                .replace('{appId}', app.id)
                .replace('{id}', 'new')}
            >
              Create a theme
            </a>
          </span>
        )}
      </ul>
    </div>
  )
}
