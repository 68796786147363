// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states'

type BarPathProps = { isLoading?: boolean, ... }
export const BarPath: StyledComponent<BarPathProps, *, *> = styled.path`
  &.not-Hovered {
    transition: opacity 0.2s ease;
  }

  ${(props: BarPathProps) =>
    props.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`
