// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { WrapLabel } from 'components/common/form/wrap-label'
import { FormGroup } from 'components/common/form/wrap-label.styles'
import { Grid } from 'components/common/grid'
import Hint from 'components/common/hint'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { Feedback, Radio, Select, Input, InputWrapper, DateTimePicker } from 'components/form'

import { buildAgeFromInputValue, preventKeyPressNonInteger } from 'com.batch.common/utils'

import { type EventDataAttributeRecord } from 'com.batch.redux/_records'
import { visibleEventsSelector } from 'com.batch.redux/attribute.selector'

import { journeySettingsSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { type settingsTabKind } from 'com.batch/orchestration-journey/ui/pages/journey-canvas-form'
import { updateSettings } from 'com.batch/orchestration-journey/usecases/update-settings'

type EmailWhenTimingProps = {
  tab?: settingsTabKind,
  ...
}

const optToStringName = (opt: ?EventDataAttributeRecord) => opt?.name ?? ''

export const EmailWhenTiming = ({ tab }: EmailWhenTimingProps): React.Node => {
  const journeySettings = useSelector(journeySettingsSelector)
  const dispatch = useDispatch()

  const handleUpdateConfig = React.useCallback(
    (property, bool) => () => {
      dispatch(updateSettings(journeySettings.set(property, bool)))
    },
    [dispatch, journeySettings]
  )
  const enterEvent = React.useMemo(
    () => journeySettings.entryEvents?.first()?.name ?? '',
    [journeySettings]
  )
  const onToggleHasInstanceId = React.useCallback(
    () =>
      dispatch(
        updateSettings(journeySettings.set('hasInstanceId', !journeySettings.hasInstanceId))
      ),
    [dispatch, journeySettings]
  )
  const events = useSelector(visibleEventsSelector)
  const triggerEvent = React.useMemo(
    () => events.find(evt => evt.id === enterEvent),
    [enterEvent, events]
  )
  const triggerInstanceIdOptions = React.useMemo(
    () =>
      triggerEvent
        ? triggerEvent.allowedKeys
            .toList()
            .filter(data => data.type === 'STRING' || data.type === '__LABEL__')
        : new Immutable.List(),
    [triggerEvent]
  )

  const onCappingChange = React.useCallback(
    (cappingEnabled: boolean) => {
      dispatch(
        updateSettings(
          journeySettings.set('hasCapping', cappingEnabled).set('capping', cappingEnabled ? 1 : 0)
        )
      )
    },
    [dispatch, journeySettings]
  )

  const handleCappingChange = React.useCallback(
    evt => {
      dispatch(updateSettings(journeySettings.set('capping', parseInt(evt.target.value))))
    },
    [dispatch, journeySettings]
  )

  const handleGraceEnabled = React.useCallback(
    graceEnabled => {
      dispatch(
        updateSettings(
          journeySettings
            .set('hasGrace', graceEnabled)
            .set('gracePeriod', buildAgeFromInputValue(graceEnabled ? 1 : 0, 'h'))
        )
      )
    },
    [dispatch, journeySettings]
  )

  const handleAgeOfGrace = React.useCallback(
    age => {
      dispatch(updateSettings(journeySettings.set('gracePeriod', age)))
    },
    [dispatch, journeySettings]
  )

  const handleUpdateDatePicker = React.useCallback(
    start => {
      dispatch(updateSettings(journeySettings.set('start', start)))
    },
    [dispatch, journeySettings]
  )

  const handleEndChange = React.useCallback(
    end => {
      dispatch(updateSettings(journeySettings.set('end', end)))
    },
    [dispatch, journeySettings]
  )

  const handleInstanceIdChange = React.useCallback(
    opt => {
      if (opt) {
        dispatch(
          updateSettings(
            journeySettings.set(
              'instanceId',
              opt.type === '__LABEL__' ? 'eventLabel()' : `eventAttr(attr: '${opt.name}')`
            )
          )
        )
      }
    },
    [dispatch, journeySettings]
  )

  return (
    <React.Fragment>
      <div style={{ marginBottom: 28, display: tab === 'timings' ? 'none' : 'block' }}>
        <Grid
          template={journeySettings.hasCapping ? 'auto 50px 1fr' : 'auto 1fr'}
          gap={8}
          style={{ marginBottom: 8 }}
        >
          <Switch
            style={{ marginRight: 8 }}
            onChange={onCappingChange}
            isActive={journeySettings.hasCapping}
          >
            {journeySettings.hasCapping ? 'Capping' : 'No capping'}
            <Hint maxTooltipWidth={142} minTooltipWidth={142}>
              Does not apply in case of user restart.
            </Hint>
          </Switch>
        </Grid>

        {journeySettings.hasCapping ? (
          <Grid template="60px 1fr">
            <Input
              type="number"
              min="1"
              value={journeySettings.capping}
              onKeyPress={preventKeyPressNonInteger}
              onChange={handleCappingChange}
            />
            <span>
              message{!!journeySettings.capping && journeySettings.capping > 1 && 's'} will be able
              to be received by the user each time they enter the automation.
            </span>
          </Grid>
        ) : (
          <Feedback
            style={{ marginBottom: 8 }}
            message="Users will be able to receive all messages from this automation each time they enter."
          />
        )}
      </div>

      <div style={{ marginBottom: 28, display: tab === 'timings' ? 'none' : 'block' }}>
        <Switch
          style={{ marginRight: 8 }}
          onChange={handleGraceEnabled}
          isActive={journeySettings.hasGrace}
        >
          {journeySettings.hasGrace ? 'Grace period' : 'No grace period'}
          <Hint maxTooltipWidth={260} minTooltipWidth={260}>
            Users won't enter into a new automation if they already received a message during the
            grace period. Does not apply in case of user restart.
          </Hint>
        </Switch>
        {journeySettings.hasGrace && (
          <div style={{ margin: '8px 0' }}>
            <span>
              <TimeIntervalInput
                age={journeySettings.gracePeriod}
                min={3600}
                invalid={!journeySettings.gracePeriod.valid}
                max={7 * 24 * 3600}
                allowedUnits={['h', 'd']}
                onChange={handleAgeOfGrace}
                style={{ display: 'inline-flex' }}
              />
            </span>
          </div>
        )}
        <Feedback
          message={
            !journeySettings.hasGrace
              ? 'The message might be sent multiple times in a very short time.'
              : 'Grace period is up to 7 days.'
          }
          type={!journeySettings.gracePeriod.valid ? 'error' : 'insight'}
          style={{
            margin: !journeySettings.hasGrace ? '8px 0' : 'unset',
          }}
        />
      </div>

      {(!tab || tab === 'timings') && (
        <React.Fragment>
          <WrapLabel label="Starting">
            <div style={{ display: 'block' }}>
              <div style={{ display: 'inline-flex' }}>
                <InputWrapper style={{ width: '117px' }}>
                  <Radio
                    checked={!journeySettings.hasStart}
                    label="Now"
                    onChange={handleUpdateConfig('hasStart', false)}
                  />
                </InputWrapper>
                <InputWrapper style={{ margin: 0 }}>
                  <Radio
                    checked={journeySettings.hasStart}
                    label="On a specific date (UTC)"
                    onChange={handleUpdateConfig('hasStart', true)}
                  />
                </InputWrapper>
              </div>
            </div>
            {journeySettings.hasStart && (
              <div style={{ marginTop: 16 }}>
                <DateTimePicker date={journeySettings.start} onChange={handleUpdateDatePicker} />
              </div>
            )}
          </WrapLabel>

          <WrapLabel label="Ending">
            <div style={{ display: 'block' }}>
              <div style={{ display: 'inline-flex' }}>
                <InputWrapper style={{ width: '117px' }}>
                  <Radio
                    checked={!journeySettings.hasEnd}
                    label="Never"
                    onChange={handleUpdateConfig('hasEnd', false)}
                  />
                </InputWrapper>
                <InputWrapper style={{ margin: 0 }}>
                  <Radio
                    checked={journeySettings.hasEnd}
                    label="On a specific date (UTC)"
                    onChange={handleUpdateConfig('hasEnd', true)}
                  />
                </InputWrapper>
              </div>
            </div>
            {journeySettings.hasEnd && (
              <div style={{ marginTop: 16 }}>
                <DateTimePicker date={journeySettings.end} onChange={handleEndChange} />
              </div>
            )}
          </WrapLabel>
        </React.Fragment>
      )}
      <FormGroup style={{ display: tab === 'timings' ? 'none' : 'block' }}>
        <Switch
          disabled={triggerInstanceIdOptions.size === 0}
          isActive={journeySettings.hasInstanceId}
          onChange={onToggleHasInstanceId}
          switchTooltip={
            triggerInstanceIdOptions.size === 0
              ? 'Specify a trigger event with a string attribute first.'
              : undefined
          }
        >
          {journeySettings.hasInstanceId ? 'Parallel automations' : 'No parallel automations'}
          <Hint style={{ padding: 4 }} minTooltipWidth={225} placement="right">
            If enabled, you will be able to pick an attribute to differentiate parallel automations.
          </Hint>
        </Switch>

        {journeySettings.hasInstanceId ? (
          <InputWrapper style={{ marginTop: 12 }}>
            <Select
              isSearchable
              optionToString={optToStringName}
              options={triggerInstanceIdOptions}
              placeholder={
                enterEvent && enterEvent !== ''
                  ? triggerInstanceIdOptions.size === 0
                    ? 'No string attribute available for this trigger event'
                    : 'Select an event attribute'
                  : 'Pick a trigger event first'
              }
              isDisabled={triggerInstanceIdOptions.size === 0}
              value={triggerInstanceIdOptions.find(data => {
                const formated =
                  data.type === '__LABEL__' ? 'eventLabel()' : `eventAttr(attr: '${data.name}')`
                return formated === journeySettings.instanceId
              })}
              onChange={handleInstanceIdChange}
            />
          </InputWrapper>
        ) : (
          <Feedback
            style={{ marginTop: 12 }}
            message="The user restarts automation for each event received before the first message is sent."
          />
        )}
      </FormGroup>
    </React.Fragment>
  )
}
