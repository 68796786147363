// @flow

import { color as D3Color } from 'd3-color'
import { pie as D3Pie, arc as D3Arc } from 'd3-shape'
import * as React from 'react'

import { TooltipMetric, TooltipDescription } from 'components/campaign/review/data/delivery.styles'
import DumbPortal from 'components/common/dumb-portal'
import { TooltipContainer, TooltipInner, TooltipArrow } from 'components/common/tt'
import WidthWatcher from 'components/common/width-watcher'

import { numberFormat } from 'com.batch.common/utils'

type DonutProps = {
  size: number,
  overOffset: number,
  duration: number,
  values: Array<number>,
  ids: Array<number>,
  colors: Array<string>,
  maxWidth: number,
  tooltips: Array<{
    id: number,
    label: string,
    color: string,
    value: number,
    tooltip: string | null,
    ...
  }>,
  ...
}

type DonutState = {
  overId: ?number,
  tooltipX: number,
  tooltipY: number,
  ...
}

class Donut extends React.PureComponent<DonutProps & { width: number, ... }, DonutState> {
  static defaultProps: {
    duration: number,
    height: number,
    maxWidth: number,
    overOffset: number,
    size: number,
    width: number,
  } = {
    width: 280,
    height: 280,
    maxWidth: 400,
    size: 74,
    overOffset: 10,
    duration: 400,
  }
  constructor(props: DonutProps & { width: number, ... }) {
    super(props)
    this.state = {
      overId: null,
      tooltipX: 0,
      tooltipY: 0,
    }
  }

  render(): React.Element<'div'> {
    const { width, values, colors, size, overOffset, maxWidth } = this.props
    const w = Math.min(maxWidth, width)
    const radius = w / 2 - overOffset * 2
    const d3colors = colors.map(D3Color)
    const pie = D3Pie()
      .value(d => d)
      .sort(null)
      .startAngle(-Math.PI - 0.4)
      .endAngle(Math.PI - 0.4)
      .padAngle(w * 0.00004)(values)

    const arc = (d: any) => {
      return D3Arc()
        .outerRadius(radius)
        .innerRadius(radius - size)
        .cornerRadius(1.5)(d)
    }
    const tooltip =
      typeof this.state.overId !== 'undefined' &&
      this.state.overId !== null &&
      this.props.tooltips[this.state.overId]
        ? this.props.tooltips[this.state.overId]
        : null
    return (
      <div style={{ position: 'relative' }}>
        {tooltip && (
          <DumbPortal>
            <TooltipContainer
              placement="left"
              style={{
                position: 'absolute',
                width: 270,
                top: this.state.tooltipY,
                left: this.state.tooltipX,
                opacity: this.state.overId !== null ? 1 : 0,
              }}
            >
              <TooltipArrow placement="left" />
              <TooltipInner style={{ width: 260, maxWidth: 260 }} noPadding>
                <TooltipMetric color={tooltip.color}>
                  <label>{tooltip.label}</label>
                  <strong>{numberFormat(tooltip.value)}</strong>
                </TooltipMetric>
                {tooltip.tooltip && <TooltipDescription>{tooltip.tooltip}</TooltipDescription>}
              </TooltipInner>
            </TooltipContainer>
          </DumbPortal>
        )}
        <svg width={width} height={width}>
          <g transform={`translate(${width / 2},${width / 2})`}>
            {pie.map((d, i) => (
              <path
                d={arc(d)}
                key={i}
                id={`donut-id-${i})`}
                onMouseLeave={() => this.setState({ overId: null })}
                onMouseMove={evt => {
                  this.setState({
                    overId: i,
                    tooltipX: evt.nativeEvent.pageX - 300,
                    tooltipY: evt.nativeEvent.pageY - 60,
                  })
                }}
                opacity={this.state.overId === null || this.state.overId === i ? 1 : 0.7}
                fill={d3colors[i]}
              />
            ))}
          </g>
        </svg>
      </div>
    )
  }
}

export default (WidthWatcher(Donut): React.AbstractComponent<DonutProps>)
