// @flow

import * as React from 'react'

import { CampaignRouter } from 'components/campaign/campaign-router'
import { AppWrapper } from 'components/common/app-wrapper'

export const App = (): React.Node => {
  return (
    <AppWrapper sidebarKind="dashboard" epicMode="campaign">
      <CampaignRouter />
    </AppWrapper>
  )
}
