// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppThumb from 'components/app/app-thumb'
import { PushSettings } from 'components/app/push/push-settings'
import { Box, BoxHeader, HeaderBoxTitle, HeaderBoxActions, BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import {
  PageTracker,
  trackingCategories,
  trackingPages,
  TrackingContext,
} from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Title, LinkDoc } from 'components/styled/text'

import IntegrationChecker from './integration-checker'
import SetupAir from './setup/air'
import SetupAndroid from './setup/android'
import SetupCordova from './setup/cordova'
import SetupFlutter from './setup/flutter'
import SetupIos from './setup/ios'
import SetupReactNative from './setup/react'
import { IntegrateDocContainer } from './setup/temporary-fix'
import SetupUnity from './setup/unity'
import SetupWeb from './setup/web'
import SetupWin from './setup/windows'

import { Separator } from '../custom-data/custom-data.styles'
import { currentAppSelector, currentAppCanPush, currentAppSdk } from 'com.batch.redux/app'
import { closeIntegrate, fetchSdks } from 'com.batch.redux/app.action'
import { devApiKeyFeatureSelector } from 'com.batch.redux/company.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

const TRACKING_CONTEXT_INTEGRATE = {
  eventLocation: 'integrate modal',
  searchEventCode: 'unset',
  pagerEventCode: 'unset',
}

const Integrate = (): React.Node => {
  const [fetched, setFetched] = React.useState<boolean>(false)

  // =================================================== REDUX
  const dispatch = useDispatch()
  const user = useSelector(currentUserSelector)
  const app = useSelector(currentAppSelector)
  const sdk = useSelector(currentAppSdk)
  const hasDevApiKeyFeature = useSelector(devApiKeyFeatureSelector)
  const validPushConfig = useSelector(currentAppCanPush)
  const opened = useSelector(state => state.app.integratePopin)

  const sdkName = React.useMemo(() => (sdk ? sdk.kind.toLowerCase() : 'loading'), [sdk])

  const url = React.useMemo(
    () =>
      app.platform === 'webpush'
        ? 'https://doc.batch.com/web/overview'
        : sdkName === 'cordova'
          ? 'https://doc.batch.com/cordova/prerequisites'
          : app.sdk === 'React Native'
            ? 'https://doc.batch.com/react-native/prerequisites'
            : app.sdk === 'Flutter'
              ? 'https://doc.batch.com/flutter/sdk-integration'
              : `https://doc.batch.com/${app.platform}/prerequisites.html`,
    [app.platform, app.sdk, sdkName]
  )

  const defaultGCMConfig = React.useMemo(
    () => (app.pushConfig.gcm.size > 0 ? app.pushConfig.gcm.get(0) : null),
    [app.pushConfig.gcm]
  )

  React.useEffect(() => {
    if (!fetched && opened) {
      setFetched(true)
      dispatch(fetchSdks())
    }
  }, [fetched, opened, dispatch])

  const onPopinClose = React.useCallback(() => dispatch(closeIntegrate()), [dispatch])

  return (
    <Popin
      opened={opened}
      close={onPopinClose}
      style={{ maxWidth: '1200px', minWidth: '900px', overflowY: 'hidden' }}
    >
      <PageTracker category={trackingCategories.appSettings} name={trackingPages.integrate}>
        <TrackingContext.Provider value={TRACKING_CONTEXT_INTEGRATE}>
          <Box noBorder style={{ overflow: 'hidden' }}>
            <BoxHeader>
              <HeaderBoxTitle title="Integrate the SDK" />
              <HeaderBoxActions>
                <LinkDoc href={url} target="_blank" intent="action">
                  Documentation
                </LinkDoc>
                <Separator style={{ marginLeft: 0 }} />

                <Button style={{ margin: 0 }} kind="inline" onClick={onPopinClose}>
                  <Icon icon="close" />
                </Button>
              </HeaderBoxActions>
            </BoxHeader>
            <BoxBody>
              <FlexLine sameHeight>
                <FlexLineItem container width={250}>
                  <AppThumb
                    name={app.name}
                    platform={app.platform}
                    icon={app.icon}
                    showMeta
                    centered
                  />
                </FlexLineItem>
                <FlexLineItem bl container scrollable style={{ maxHeight: '66vh' }}>
                  <Title>1. Configure Push</Title>
                  <div>
                    <PushSettings
                      readonly={!user.permissionsForCurrentApp.has('push:config:write')}
                    />
                  </div>
                  <span id="code" />
                  <Title>2. Setup the SDK</Title>
                  <IntegrateDocContainer>
                    {app.devApiKey && sdkName === 'ios' && (
                      <SetupIos
                        apiKey={app.apiKey}
                        devApiKey={app.devApiKey}
                        sdk={sdk}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && sdkName === 'windows' && (
                      <SetupWin
                        apiKey={app.apiKey}
                        devApiKey={app.devApiKey}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && sdkName === 'android' && (
                      <SetupAndroid
                        bundleId={app.bundleId}
                        apiKey={app.apiKey}
                        devApiKey={app.devApiKey}
                        packageManagerMinVersion={sdk.packageManagerMinVersion || ''}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && sdkName === 'cordova' && (
                      <SetupCordova
                        bundleId={app.bundleId}
                        platform={app.platform}
                        senderId={
                          !!defaultGCMConfig && defaultGCMConfig.senderId
                            ? defaultGCMConfig.senderId
                            : 'YOUR-GCM-SENDER-ID'
                        }
                        sdk={sdk}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && app.sdk === 'Flutter' && (
                      <SetupFlutter
                        platform={app.platform}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && sdkName === 'unity' && (
                      <SetupUnity
                        bundleId={app.bundleId}
                        apiKey={app.apiKey}
                        platform={app.platform}
                        senderId={
                          !!defaultGCMConfig && defaultGCMConfig.senderId
                            ? defaultGCMConfig.senderId
                            : 'YOUR-GCM-SENDER-ID'
                        }
                        devApiKey={app.devApiKey}
                        sdk={sdk}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && app.sdk === 'React Native' && (
                      <SetupReactNative
                        apiKey={app.apiKey}
                        platform={app.platform}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.devApiKey && sdkName === 'air' && (
                      <SetupAir
                        bundleId={app.bundleId}
                        apiKey={app.apiKey}
                        platform={app.platform}
                        senderId={
                          !!defaultGCMConfig && defaultGCMConfig.senderId
                            ? defaultGCMConfig.senderId
                            : 'YOUR-GCM-SENDER-ID'
                        }
                        devApiKey={app.devApiKey}
                        sdk={sdk}
                        hasDevApiKeyFeature={hasDevApiKeyFeature}
                      />
                    )}
                    {app.platform === 'webpush' && app.pushConfig.vapid && (
                      <SetupWeb
                        apiKey={app.apiKey}
                        webpushAuth={app.webpushAuth ? app.webpushAuth : ''}
                        defaultLanguageId={app.defaultLanguageId || 'default'}
                        sdk={sdk}
                        bundleId={app.bundleId}
                        subdomain={app.pushConfig.vapid.subdomain}
                        vapidPublicKey={app.pushConfig.vapid.publicKey}
                        configSafari={app.pushConfig.safariWebsites}
                        defaultIcon={app.pushConfig.safariWebsiteIcon}
                        smallIcon={app.pushConfig.androidSmallIcon}
                      />
                    )}
                  </IntegrateDocContainer>
                  <br />
                  <Title>3. Test integration</Title>
                  <IntegrationChecker app={app} valid={validPushConfig} />
                </FlexLineItem>
              </FlexLine>
            </BoxBody>
          </Box>
        </TrackingContext.Provider>
      </PageTracker>
    </Popin>
  )
}

export default Integrate
