/* eslint-disable react/jsx-no-bind */
// @flow

import * as React from 'react'
import { Route } from 'react-router-dom'

import { AppSettingsWrapper } from 'components/app/app-settings-wrapper'
import { CreateAppRoute } from 'components/app/create/create-app-route'
import Integrate from 'components/app/integrate/integrate'
import { TrackingContext } from 'components/common/page-tracker'
import { NavbarConnected } from 'components/project/navigation/navbar-connected'
import { ProjectRouter } from 'components/project/project-router'
import { ProjectRouterWrapper } from 'components/project/project-router-wrapper'

const TRACKING_CONTEXT = {
  eventLocation: 'app settings',
  searchEventCode: 'unset',
  pagerEventCode: 'unset',
}

const AppRouterRaw = () => {
  return (
    <React.Fragment>
      <NavbarConnected />
      <Integrate />
      <CreateAppRoute />
      <ProjectRouter />
      <TrackingContext.Provider value={TRACKING_CONTEXT}>
        <ProjectRouterWrapper>
          <Route
            path="apps/:appId/settings/privacy/new"
            element={<AppSettingsWrapper setting="privacy/new" />}
          />
          <Route
            path="apps/:appId/settings/profiles"
            element={<AppSettingsWrapper setting="profiles" />}
          />
          <Route
            path="apps/:appId/settings/privacy"
            element={<AppSettingsWrapper setting="privacy" />}
          />
          <Route
            path="apps/:appId/settings/cappings"
            element={<AppSettingsWrapper setting="cappings" />}
          />
          <Route
            path="apps/:appId/settings/labels"
            element={<AppSettingsWrapper setting="labels" />}
          />
          <Route
            path="apps/:appId/settings/team/edit"
            element={<AppSettingsWrapper setting="team/edit" />}
          />
          <Route path="apps/:appId/settings/team" element={<AppSettingsWrapper setting="team" />} />
          <Route
            path="apps/:appId/settings/custom-data"
            element={<AppSettingsWrapper setting="custom-data" />}
          />
          <Route
            path="apps/:appId/settings/audiences"
            element={<AppSettingsWrapper setting="audiences" />}
          />
          <Route
            path="apps/:appId/settings/themes/new"
            element={<AppSettingsWrapper setting="themes/new" />}
          />
          <Route
            path="apps/:appId/settings/themes/:themeId"
            element={<AppSettingsWrapper setting="themes/:themeId" />}
          />
          <Route
            path="apps/:appId/settings/themes"
            element={<AppSettingsWrapper setting="themes" />}
          />
          <Route path="apps/:appId/settings/push" element={<AppSettingsWrapper setting="push" />} />
          <Route
            path="apps/:appId/settings/debug/transactional"
            element={<AppSettingsWrapper setting="debug/transactional" />}
          />
          <Route
            path="apps/:appId/settings/debug"
            element={<AppSettingsWrapper setting="debug" />}
          />
          <Route
            path="apps/:appId/settings/global"
            element={<AppSettingsWrapper setting="general" />}
          />
          <Route path="*" element={null} />
        </ProjectRouterWrapper>
      </TrackingContext.Provider>
    </React.Fragment>
  )
}

const AppRouter = AppRouterRaw

export default (AppRouter: React.AbstractComponent<{ ... }>)
