// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { BoxCollapse, BoxBody, HeaderBoxTitle } from 'components/common/box'
import { SwitchButton } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper, Radio } from 'components/form'

import { validateJSON } from 'com.batch.common/utils'

import { type PushSettingsRecord } from 'com.batch.redux/content.records'

type SettingsProps = {
  channels: Set<Channel>,
  settings: PushSettingsRecord,
  update: (settings: PushSettingsRecord) => void,
  ...
}

/*
  ios : ttl, payload, priority
  android : ttl, payload, priority, collapse
  web : ttl

*/

export const Settings = ({ settings, channels, update }: SettingsProps): React.Node => {
  const [validJson, setValidJson] = React.useState(true)
  const resetJsonValidation = React.useCallback(() => setValidJson(true), [])
  const validateJSONonBlur = React.useCallback(() => {
    setValidJson(validateJSON(settings.payload))
  }, [settings.payload])

  const onTogglePriority = React.useCallback(
    () => update(settings.set('priority', settings.priority === 'HIGH' ? 'NORMAL' : 'HIGH')),
    [settings, update]
  )
  const onUpdateCollapseKeyEnabled = React.useCallback(
    collapseKeyEnabled => update(settings.set('hasCollapseKey', collapseKeyEnabled)),
    [settings, update]
  )
  const onUpdateCollapseKey = React.useCallback(
    evt => update(settings.set('collapseKey', evt.target.value)),
    [settings, update]
  )
  const onUpdateExpirationEnabled = React.useCallback(
    expirationEnabled => update(settings.set('hasExpiration', expirationEnabled)),
    [settings, update]
  )
  const onExpirationChange = React.useCallback(
    e => {
      const int = parseInt(e.target.value)
      update(settings.set('expiration', !isNaN(int) && int > 0 ? int : null))
    },
    [settings, update]
  )
  const onUpdatePayload = React.useCallback(
    e => update(settings.set('payload', e.target.value)),
    [settings, update]
  )
  const theme = useTheme()
  return (
    <BoxCollapse title={<HeaderBoxTitle title="Advanced settings" suffix={<em>(optional)</em>} />}>
      <BoxBody $padding>
        <Grid template="1fr 1fr" alignItems="start">
          <div>
            <InputWrapper
              label="Priority"
              style={{ marginBottom: 40 }}
              hintMinSize={400}
              hint={
                <div style={{ textAlign: 'left' }}>
                  Default is high. Normal priority send the push message at a time that takes into
                  account power considerations for the device. Notifications with this priority
                  might be grouped and delivered in bursts. They are throttled, and in some cases
                  are not delivered.
                </div>
              }
            >
              <Radio
                label="Normal"
                style={{ marginRight: 20 }}
                checked={settings.priority === 'NORMAL'}
                onChange={onTogglePriority}
              />
              <Radio
                label="High"
                checked={settings.priority === 'HIGH'}
                onChange={onTogglePriority}
              />
            </InputWrapper>
            {channels.has('android') && (
              <InputWrapper
                style={{ marginBottom: 40 }}
                hint={
                  <div style={{ textAlign: 'left' }}>
                    <p style={{ marginBottom: 8 }}>
                      Defines how notifications are managed when an offline device goes online. If
                      enabled, the device will only show the most recent notification.
                    </p>
                    <p style={{ marginBottom: 8 }}>
                      If disabled, it will show all the notifications received when the device was
                      offline.
                    </p>
                    <p style={{ marginBottom: 8 }}>
                      You should disable the collapse key if all your notifications matter (E.g.
                      messages, etc).
                    </p>
                    <p>
                      You can use up to 3 different collapse keys if you want users to get only one
                      notification of each kind when coming online (E.g. marketing, alert, etc).
                    </p>
                  </div>
                }
                hintMinSize={400}
                label={
                  <SwitchButton
                    disabled={theme?.disabledMode}
                    isActive={settings.hasCollapseKey}
                    onChange={onUpdateCollapseKeyEnabled}
                  >
                    <Icon icon="android" />
                    &nbsp; FCM Collapse key
                  </SwitchButton>
                }
              >
                {settings.hasCollapseKey && (
                  <Input
                    style={{ maxWidth: 180 }}
                    value={settings.collapseKey}
                    onChange={onUpdateCollapseKey}
                  />
                )}
              </InputWrapper>
            )}

            <InputWrapper
              hintMinSize={400}
              hint={
                <div style={{ textAlign: 'left' }}>
                  By default, Batch will not set notifications to expire, which means that if the
                  user's device is offline for 2 weeks and then goes back online, it will receive
                  all notifications sent during this period. You can change this behavior here.
                </div>
              }
              label={
                <SwitchButton
                  disabled={theme?.disabledMode}
                  isActive={settings.hasExpiration}
                  onChange={onUpdateExpirationEnabled}
                >
                  Expiration (TTL)
                </SwitchButton>
              }
            >
              {settings.hasExpiration && (
                <Grid template="60px 20px 1fr">
                  <Input type="number" value={settings.expiration} onChange={onExpirationChange} />
                  Hours
                </Grid>
              )}
            </InputWrapper>
          </div>
          <div>
            {(channels.size > 1 || !channels.has('webpush')) && (
              <InputWrapper label="Payload">
                <Input
                  rows={6}
                  placeholder={`{
"valid": "JSON"
}
        `}
                  invalid={!validJson}
                  onFocus={resetJsonValidation}
                  onBlur={validateJSONonBlur}
                  value={settings.payload}
                  onChange={onUpdatePayload}
                />
              </InputWrapper>
            )}
          </div>
        </Grid>
      </BoxBody>
    </BoxCollapse>
  )
}
