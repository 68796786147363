// @flow

import { profileSelector } from 'com.batch/profile/store/profile.selector'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { parseNotif } from 'com.batch.redux/stat.api.debug'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

type RefreshRecipientAction = {
  type: 'REFRESH_RECIPIENT',
  ...
}
type RefreshRecipientSuccessAction = {
  type: 'REFRESH_RECIPIENT_SUCCESS',
  payload: {
    subscriptionStatus: SubscriptionStatus,
    notifType: string[],
    profileId: string,
    installId: string,
    ...
  },
  ...
}
type RefreshRecipientFailureAction = {
  type: 'REFRESH_RECIPIENT_FAILURE',
  payload: {
    error: {
      errors: {
        message: string,
        ...
      }[],
      ...
    },
    ...
  },
  ...
}

export type refreshRecipientAction =
  | RefreshRecipientAction
  | RefreshRecipientSuccessAction
  | RefreshRecipientFailureAction

export const refreshPlatform = ({
  installId,
}: {
  installId: string,
  ...
}): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { ucpGateway }) => {
    const project = currentProjectSelector(getState())
    const { profileId } = profileSelector(getState())

    dispatch({
      type: 'REFRESH_RECIPIENT',
      payload: null,
    })

    try {
      const profile = await ucpGateway.getProfile({
        project,
        profileID: {
          data: profileId,
        },
      })

      const recipient = profile.recipientsWithData.find(r => {
        return r.recipient?.pushToken?.install?.installId === installId
      })

      const notifType = parseNotif(
        `${recipient?.data?.pushTokenData?.subscription?.notifType?.value ?? ''}`
      )

      const subscriptionStatus = notifType.length > 0 ? 'SUBSCRIBED' : 'UNSUBSCRIBED'

      dispatch({
        type: 'REFRESH_RECIPIENT_SUCCESS',
        payload: {
          profileId,
          subscriptionStatus,
          notifType,
          installId,
        },
      })
    } catch (e) {
      console.log(e)
      dispatch({
        type: 'REFRESH_RECIPIENT_FAILURE',
        payload: e,
      })
      throw e
    }
  }
}
