// @flow

import * as React from 'react'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'
import { texts } from 'components/styled/tokens'

import { capitalize, kformat, percentage, pluralizeAndKformat } from 'com.batch.common/utils'

import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  StatsBlocksRow,
  StatsContainer,
} from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'
import { MetricBlockCard } from 'com.batch/shared/ui/component/metric-block/metric-block.styles'
import { STATUS } from 'constants/common'

type PushAnalyticsProps = {
  loadingState: LoadingState,
  stats: ?StatsRecord,
}

export const PushAnalytics = ({ loadingState, stats }: PushAnalyticsProps): React.Node => {
  const isLoading = React.useMemo(
    () => [STATUS.LOADING, STATUS.INIT].includes(loadingState),
    [loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px' }}>
        <Title>
          <Icon icon="push" size={22} />
          Push
        </Title>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        boxed
      >
        <StatsBlocksRow>
          <MetricBlockCard>
            <MetricBlock
              variant="success"
              label={capitalize(STAT_TYPE.SENT)}
              value={kformat(stats?.sent ?? 0)}
              hint="Push notifications are sent to all opted-in devices associated to Profiles"
            />
          </MetricBlockCard>
          <MetricBlockCard>
            <MetricBlock
              variant="action"
              label={capitalize(STAT_TYPE.OPENED)}
              value={
                <span>
                  {percentage(stats?.open.rate ?? 0, 2, false, false)}
                  <span style={texts.metric}>%</span>
                </span>
              }
              subtitle={pluralizeAndKformat('unique open', stats?.open.unique ?? 0)}
            />
          </MetricBlockCard>
          <MetricBlockCard>
            <MetricBlock
              variant="warning"
              label={capitalize(STAT_TYPE.BOUNCED)}
              value={kformat(stats?.bounce.value ?? 0)}
              subtitle={`${percentage(stats?.bounce.rate ?? 0, 2)} of push sent`}
            />
          </MetricBlockCard>
        </StatsBlocksRow>
      </Wrapper>
    </StatsContainer>
  )
}
