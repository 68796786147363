// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  Table,
  TableBody,
  TableHeader,
  TableCellHeader,
  TableCellOrder,
} from 'components/common/table'

import { BouncePerMailboxProviderRow } from './bounce-per-mailbox-provider-row'
import {
  BounceCategoryHeader,
  BouncePerMailboxProviderWrapper,
} from './bounce-per-mailbox-provider.styles'

import { BounceNoData } from '../bounce-no-data'
import { currentCampaign } from 'com.batch.redux/campaign.selector'

import { bouncesColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { type BounceProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { BounceBadge } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'

type BouncePerMailboxProviderProps = {
  providers: List<BounceProviderRecord>,
}
type ProvidersSortOptions = {
  order: 'asc' | 'dsc',
  sortBy: 'sent' | 'rate',
}
export const BouncePerMailboxProvider = ({
  providers,
}: BouncePerMailboxProviderProps): React.Node => {
  const campaign = useSelector(currentCampaign)

  const [sortOptions, setSortOptions] = React.useState<ProvidersSortOptions>({
    order: 'dsc',
    sortBy: 'sent',
  })
  const onSortBy = React.useCallback(
    (sortBy: ProvidersSortOptions['sortBy']) => () => {
      if (sortOptions.sortBy === sortBy)
        setSortOptions(prev => ({
          ...prev,
          order: prev.order === 'asc' ? 'dsc' : 'asc',
        }))
      else
        setSortOptions({
          order: 'dsc',
          sortBy: sortBy,
        })
    },
    [sortOptions]
  )
  const sortedProviders = React.useMemo(
    () =>
      providers.sort((a, b) => {
        const compare = a[sortOptions.sortBy] > b[sortOptions.sortBy]
        if (sortOptions.order === 'asc') return compare ? 1 : -1
        return compare ? -1 : 1
      }),
    [providers, sortOptions]
  )

  if (providers.size === 0) return <BounceNoData schedulingType={campaign.schedulingType} />

  return (
    <BouncePerMailboxProviderWrapper>
      <Table rowHeight={44} template="1fr 78px 137px 90px 84px 101px">
        <TableHeader style={{ backgroundColor: 'white', position: 'sticky', zIndex: 10, top: 0 }}>
          <TableCellHeader>Mailbox provider</TableCellHeader>
          <TableCellOrder
            sort={sortOptions.sortBy === 'sent' ? sortOptions.order : false}
            onClick={onSortBy('sent')}
            align="right"
          >
            Sent
          </TableCellOrder>
          <TableCellOrder
            sort={sortOptions.sortBy === 'rate' ? sortOptions.order : false}
            onClick={onSortBy('rate')}
            align="right"
          >
            Bounced
          </TableCellOrder>
          <TableCellHeader align="right">
            <BounceCategoryHeader>
              <BounceBadge size="small" color={bouncesColors.hard} />
              Hard
            </BounceCategoryHeader>
          </TableCellHeader>
          <TableCellHeader align="right">
            <BounceCategoryHeader>
              <BounceBadge size="small" color={bouncesColors.soft} />
              Soft
            </BounceCategoryHeader>
          </TableCellHeader>
          <TableCellHeader align="right" style={{ marginRight: 20 }}>
            <BounceCategoryHeader>
              <BounceBadge size="small" color={bouncesColors.block} />
              Block
            </BounceCategoryHeader>
          </TableCellHeader>
        </TableHeader>
        <TableBody>
          {sortedProviders.map(provider => (
            <BouncePerMailboxProviderRow key={provider.name} provider={provider} />
          ))}
        </TableBody>
      </Table>
    </BouncePerMailboxProviderWrapper>
  )
}
