// @flow

import { type Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { AttributesTable } from 'com.batch/profile/ui/components/attributes-block/attributes-table'
import { useAttributesSheet } from 'com.batch/profile/ui/components/events-block/attributes-sheet/attributes-sheet-provider'
import { AttributesContainer } from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'

type Props = {
  attributes: Map<string, QueryValue>,
  hasLabel: boolean,
  eventName: string,
  ...
}

export const EventRowAttributes = ({ attributes, hasLabel, eventName }: Props): React.Node => {
  const [attributeNames, setAttributeNames] = React.useState<string[]>([])
  const [isShown, setIsShown] = React.useState<boolean>(false)
  const { open } = useAttributesSheet()

  React.useEffect(() => {
    let names: Array<string> = []
    if (attributes.size > 0) {
      names = names.concat(attributes.keySeq().toArray())
    }
    setAttributeNames(names.sort((a, b) => a.localeCompare(b)))
  }, [attributes])

  const handleClickExpandBtn = React.useCallback(() => setIsShown(!isShown), [isShown])

  const handleClickSheetBtn = React.useCallback(
    () => open(attributes, eventName),
    [attributes, eventName, open]
  )

  return (
    <AttributesContainer>
      <Button onClick={handleClickExpandBtn} kind="inline" intent="neutral">
        {hasLabel && '&'} {attributes.size} {attributes.size > 1 ? 'attributes' : 'attribute'}
        <Icon style={{ marginLeft: '7px' }} icon={isShown ? 'chevron-up' : 'chevron-down'} />
      </Button>
      {isShown && (
        <React.Fragment>
          <AttributesTable
            attributes={attributes}
            event={eventName}
            names={attributeNames}
            isLoading={false}
          />
          <Button kind="inline" intent="neutral" onClick={handleClickSheetBtn}>
            View complete data as JSON
            <Icon style={{ marginLeft: '7px' }} icon="external" />
          </Button>
        </React.Fragment>
      )}
    </AttributesContainer>
  )
}
