/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable from 'immutable'
import * as React from 'react'

import { DateRangePickerContainer } from 'components/campaign/campaign-list.styles'
import { DateRangerContentFilter } from 'components/campaign/filters/date-range-content-filter'
import { Filter } from 'components/campaign/filters/filter'
import { Button } from 'components/common/button'
import { type DateRange } from 'components/common/date-range-picker'
import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { Radio } from 'components/form/fields/radio'

import * as predefinedRanges from 'com.batch.common/predefined-ranges'

import { type CampaignActiveFiltersRecord } from 'com.batch.redux/_records'

type DateRangeFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord,
  onChange?: (?DateRange) => void,
  onClose?: (?DateRange) => void,
  isEmbedded: boolean,
  ...
}

const ranges = new Immutable.List().push(
  predefinedRanges.lastWeek,
  predefinedRanges.lastMonth,
  predefinedRanges.last3Months,
  predefinedRanges.allTime
)

export const DateRangeFilter = ({
  localActiveFilters,
  onChange,
  onClose,
  isEmbedded,
}: DateRangeFilterProps): React.Node => {
  const selectedDateRange = React.useMemo(() => {
    return localActiveFilters.dateRange
  }, [localActiveFilters.dateRange])

  return (
    <Filter
      isEmbedded={isEmbedded}
      title={
        <span>
          Dates
          <Hint minTooltipWidth={260}>
            This will only filter on campaign with statistics. Drafts and scheduled campaigns will
            be excluded.
          </Hint>
        </span>
      }
      clearButton={
        selectedDateRange?.from && selectedDateRange?.to ? (
          <Button
            style={{ height: 28, display: 'inline-flex' }}
            addOn="prefix"
            kind="inline"
            onClick={() => onChange && onChange(null)}
          >
            <Icon icon="close" />
            Clear
          </Button>
        ) : null
      }
    >
      {isEmbedded ? (
        <React.Fragment>
          <DateRangePickerContainer>
            <DateRangerContentFilter
              selectedDateRange={selectedDateRange}
              onChange={onChange}
              onClose={onClose}
              isEmbedded={isEmbedded}
            />
          </DateRangePickerContainer>
          {ranges.map(range => (
            <Radio
              key={range.name}
              style={{ marginTop: 14, height: '19px' }}
              onChange={() => onChange && onChange(range.from && range.to ? range : null)}
              label={range.name}
              className={`dates_${range.name.toLowerCase().replace(/ /g, '').replace(/last/g, '')}`}
              checked={
                (range.all && localActiveFilters.dateRange === null) ||
                (localActiveFilters.dateRange !== null &&
                  localActiveFilters.dateRange?.from !== undefined &&
                  localActiveFilters.dateRange?.to !== undefined &&
                  range.from &&
                  range.from.isSame(localActiveFilters.dateRange.from) &&
                  range.to &&
                  range.to.isSame(localActiveFilters.dateRange?.to ?? ''))
              }
            />
          ))}
        </React.Fragment>
      ) : (
        <DateRangerContentFilter
          selectedDateRange={selectedDateRange}
          onChange={onChange}
          onClose={onClose}
          isEmbedded={false}
          shortcuts={ranges}
        />
      )}
    </Filter>
  )
}
