// @flow
import dayjs from 'dayjs'
import * as React from 'react'

import { Label } from 'components/common/label/label.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Ellipsis } from 'components/styled/text'

import {
  EventRowContent,
  EventRowDetails,
  EventRowDetailsDay,
  EventRowContentInformation,
  EventRowTooltipDay,
  EventRowTooltipDayAt,
  EventRowTooltipDescription,
  EventRowDetailsSource,
} from '../event-row/event-row.styles'
import { APIIcon } from '../icons'
import { Timeline } from '../timeline/timeline'

import { convertAttributesToJson } from 'com.batch/profile/infra/formats/convert-attributes-to-json'
import { type CustomEventRecord } from 'com.batch/profile/models/profile.records'
import { EventRowAttributes } from 'com.batch/profile/ui/components/events-block/event-row/event-row-attributes'

type EventRowProps = {
  event: CustomEventRecord,
  ...
}

const platform = {
  IOS: {
    icon: 'ios',
    label: 'iOS',
  },
  ANDROID: {
    icon: 'android',
    label: 'Android',
  },
  WEBPUSH: {
    icon: 'webpush',
    label: 'Web',
  },
}

export const EventRow = ({ event }: EventRowProps): React.Node => {
  const { attributes } = event
  const hasPropertiesOrLabel = React.useMemo(
    () => attributes.size !== 0 || event.label !== '',
    [attributes.size, event.label]
  )

  convertAttributesToJson(attributes)

  const trackedSrc = React.useMemo(() => {
    return event.platform === 'IOS'
      ? 'iOS app'
      : event.platform === 'ANDROID'
        ? 'Android app'
        : 'website'
  }, [event.platform])

  const trackedDescription = React.useMemo(() => {
    if (event.source === 'SDK') return `Tracked in the ${trackedSrc}`
    if (event.source === 'PROFILE_API') return 'Tracked with the Profile API'
    return 'Tracked with the Trigger Events API'
  }, [event.source, trackedSrc])

  return (
    <EventRowContent>
      <Timeline />
      <EventRowContentInformation expanded={hasPropertiesOrLabel}>
        <EventRowDetails>
          <Ellipsis>{event.name}</Ellipsis>
          <Tooltip
            minWidth={238}
            placement="bottom"
            tooltip={
              <div style={{ textAlign: 'left' }}>
                <EventRowTooltipDay>
                  {dayjs(event.sendDate).format('ddd Do MMMM')}
                </EventRowTooltipDay>
                <EventRowTooltipDayAt> at </EventRowTooltipDayAt>
                <EventRowTooltipDay>{dayjs(event.sendDate).format('h:mma')}</EventRowTooltipDay>
                <EventRowTooltipDescription>{trackedDescription}</EventRowTooltipDescription>
              </div>
            }
          >
            <EventRowDetailsSource>
              <EventRowDetailsDay>{dayjs(event.sendDate).format('h:mma')}</EventRowDetailsDay>
              {event.source === 'SDK' ? (
                <Icon icon={platform[event.platform].icon} style={{ marginLeft: 6 }} />
              ) : (
                <APIIcon />
              )}
            </EventRowDetailsSource>
          </Tooltip>
        </EventRowDetails>
        <div>
          {event.label !== '' && <Label>{event.label}</Label>}
          {event.attributes.size > 0 && (
            <EventRowAttributes
              hasLabel={event.label !== ''}
              attributes={event.attributes}
              eventName={event.name}
            />
          )}
        </div>
      </EventRowContentInformation>
    </EventRowContent>
  )
}
