// @flow

import * as React from 'react'
import { Route } from 'react-router-dom'

import { CreateAppRoute } from 'components/app/create/create-app-route'
import Integrate from 'components/app/integrate/integrate'
import { CampaignList } from 'components/campaign/campaign-list'
import { FiltersProvider } from 'components/campaign/filters/filters-provider'
import { LegacyCampaignForm } from 'components/campaign/form/form-legacy'
import { ReviewScreen } from 'components/campaign/review/review-screen'
import { NavbarConnected } from 'components/project/navigation/navbar-connected'
import { ProjectRouter } from 'components/project/project-router'
import { ProjectRouterWrapper } from 'components/project/project-router-wrapper'

import { CertificateBanner } from 'com.batch/shared/ui/component/certificate-banner/certificate-banner'

// :campaignType can't be optionnal otherwise we match incorrectly

export const CampaignRouter = (): React.Node => {
  return (
    <React.Fragment>
      <CertificateBanner />
      <NavbarConnected />
      <ProjectRouter />
      <CreateAppRoute />
      <Integrate />
      <ProjectRouterWrapper>
        <Route
          path="apps/:appId/:schedulingType/:campaignType?/:token/review"
          element={<ReviewScreen tab="review" />}
        />
        <Route
          path="apps/:appId/:schedulingType/:campaignType?/:token/analytics"
          element={<ReviewScreen tab="analytics" />}
        />
        <Route
          path="apps/:appId/:schedulingType/:campaignType?/:token"
          element={<LegacyCampaignForm />}
        />
        <Route
          path="apps/:appId/:schedulingType"
          element={
            <FiltersProvider>
              <CampaignList />
            </FiltersProvider>
          }
        />
        <Route path="*" element={null} />
      </ProjectRouterWrapper>
    </React.Fragment>
  )
}
