// @flow

import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'

import { ContentOption } from 'components/campaign/form/content-option'
import { LandingSwitch } from 'components/campaign/landing-switch'
import {
  Box,
  BoxBody,
  BoxHeader,
  BoxSection,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { LangPicker } from 'components/project/campaign/common/lang-picker/lang-picker'

import { ContentInApp } from './content-inapp'
import { ContentPush } from './content-push'

import {
  type AppRecord,
  type CampaignRecord,
  LanguageFactory,
  type LanguageRecord,
  type SdkSupportsRecord,
  type State,
} from 'com.batch.redux/_records'
import {
  addTranslation,
  removeTranslation,
  setActiveTranslation,
  setCampaignLanding,
  togglePushAbTesting,
} from 'com.batch.redux/campaign.action'
import {
  activeLanguageSelector,
  activeVariantIdSelector,
  mandatoryLanguageSelector,
  pickableLanguagesSelector,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { invalidLangIdsSelector } from 'com.batch.redux/targeting.selector.composed'
import { type ThemeRecord } from 'com.batch.redux/theme.records'

type OwnProps = {
  sdkSupports: SdkSupportsRecord,
  campaign: CampaignRecord,
  themes: List<ThemeRecord>,
  app: AppRecord,
}
type StateProps = {
  previewedVariant: 'a' | 'b',
  invalidLangIds: Set<string>,
  mandatoryLanguage: LanguageRecord,
  pickableLanguages: List<LanguageRecord>,
  selectedLanguages: List<LanguageRecord>,
  activeLanguage: ?LanguageRecord,
}
type DispatchProps = {
  removeTranslation: typeof removeTranslation,
  setActiveTranslation: typeof setActiveTranslation,
  setCampaignLanding: typeof setCampaignLanding,
  togglePushAbTesting: typeof togglePushAbTesting,
  addTranslation: typeof addTranslation,
}
type ContentProps = { ...OwnProps, ...StateProps, ...DispatchProps }

type ContentState = {
  showLanding: boolean,
  abTestingInitiallyEnabled: boolean,
  ...
}

const defaultLanguage = LanguageFactory({ value: 'default' })

class Content extends React.PureComponent<ContentProps, ContentState> {
  constructor(props: ContentProps) {
    super(props)
    this.state = {
      showLanding: props.campaign.type === 'in-app',
      abTestingInitiallyEnabled: props.campaign.abtesting.enabled,
    }
  }
  toggleViewMode = (mode: 'push' | 'landing') => {
    this.setState({ showLanding: mode === 'landing' })
  }

  toggleLandingActive = () => {
    this.props.setCampaignLanding(!this.props.campaign.hasLanding)
    if (this.props.campaign.hasLanding && this.state.showLanding) {
      this.setState({ showLanding: false })
    }
    if (!this.props.campaign.hasLanding && !this.state.showLanding) {
      this.setState({ showLanding: true })
    }
  }

  render(): React.Node {
    const {
      activeLanguage,
      addTranslation,
      app,
      campaign,
      invalidLangIds,
      mandatoryLanguage,
      pickableLanguages,
      previewedVariant,
      removeTranslation,
      sdkSupports,
      selectedLanguages,
      setActiveTranslation,
      togglePushAbTesting,
      themes,
    } = this.props
    return (
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Message" />
          <HeaderBoxActions>
            <ContentOption
              hasAbTesting={campaign.abtesting.enabled}
              abTestingInitiallyEnabled={this.state.abTestingInitiallyEnabled}
              type={campaign.type}
              togglePushAbTesting={togglePushAbTesting}
              valid={!invalidLangIds.has(activeLanguage?.value ?? 'default')}
              campaignId={campaign.id ? campaign.id : null}
            />
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <React.Fragment>
            <BoxSection style={{ padding: '8px 10px 0 10px' }}>
              <LangPicker
                pickableLanguages={pickableLanguages}
                pickedLanguages={selectedLanguages}
                activeTranslation={activeLanguage}
                validLanguagesId={Immutable.Set(
                  selectedLanguages.map(l => l.value).filter(lid => !invalidLangIds.includes(lid))
                )}
                mandatoryLanguage={mandatoryLanguage}
                addTranslation={addTranslation}
                removeTranslation={removeTranslation}
                setActiveTranslation={setActiveTranslation}
                defaultLanguageId={this.props.app?.defaultLanguageId}
              />
            </BoxSection>
            {campaign.type === 'push' && (app.platform === 'ios' || app.platform === 'android') && (
              <LandingSwitch
                app={app}
                toggleMode={this.toggleViewMode}
                hasThemes={typeof themes !== 'undefined' && themes.size > 0}
                hasLandingFeature={app.features.has('inapp-messaging')}
                toggleLandingActive={this.toggleLandingActive}
                isLandingVisible={this.state.showLanding}
                isLandingActive={campaign.hasLanding}
              />
            )}
            {this.state.showLanding === false && campaign.type === 'push' && (
              <ContentPush
                abtesting={campaign.abtesting}
                app={app}
                lang={activeLanguage ?? defaultLanguage}
                campaignHasLanding={campaign.hasLanding}
                previewedVariant={previewedVariant}
              />
            )}
            {(this.state.showLanding || campaign.type === 'in-app') && (
              <ContentInApp
                app={app}
                abtesting={campaign.abtesting}
                lang={activeLanguage ?? defaultLanguage}
                sdkSupports={sdkSupports}
                isInApp={campaign.type === 'in-app'}
              />
            )}
          </React.Fragment>
        </BoxBody>
      </Box>
    )
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    activeLanguage: activeLanguageSelector(state),
    invalidLangIds: invalidLangIdsSelector(state),
    mandatoryLanguage: mandatoryLanguageSelector(state),
    pickableLanguages: pickableLanguagesSelector(state),
    previewedVariant: activeVariantIdSelector(state),
    selectedLanguages: pickedLanguagesSelector(state),
  }
}

export const ContentConnected: React.AbstractComponent<OwnProps> = connect<
  ContentProps,
  OwnProps,
  StateProps,
  DispatchProps,
  _,
  _,
>(mapStateToProps, {
  addTranslation,
  setCampaignLanding,
  removeTranslation,
  togglePushAbTesting,
  setActiveTranslation,
})(Content)
