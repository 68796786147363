// @flow
import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'
import { TextLabelWithIcon } from 'components/styled/text'

import {
  ProfileCopyableIdContainer,
  NoPushToken,
} from 'com.batch/profile/ui/components/profile-hero/profile-copyable-id/profile-copyable-id.styles'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

type Props = {
  label: string,
  id: string,
  isLoading: boolean,
  ...
}
export const ProfileCopyableId = ({ label, id, isLoading }: Props): React.Node => {
  const isDisabled = React.useMemo(() => id.length === 0, [id])
  return (
    <ProfileCopyableIdContainer>
      {isLoading ? (
        <React.Fragment>
          <EmptyField _width={123} _display="block" />
          <EmptyField _width={84} />
        </React.Fragment>
      ) : isDisabled ? (
        <React.Fragment>
          <TextLabelWithIcon icon="identifier">{label}</TextLabelWithIcon>
          <NoPushToken>No push token</NoPushToken>
        </React.Fragment>
      ) : (
        <Copyable
          fullWidth={true}
          notificationText={`${label} copied`}
          label={<TextLabelWithIcon icon="identifier">{label}</TextLabelWithIcon>}
          value={id}
        />
      )}
    </ProfileCopyableIdContainer>
  )
}
