// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import { Box, BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { ButtonNavLink } from 'components/common/button'
import { Skeleton } from 'components/common/empty-states'
import { LinkDocumentation } from 'components/styled/text'

import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import {
  bounceByDaysRangeSelector,
  orchestrationAnalyticsMapSelector,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { bounceCatToReasons } from 'com.batch/orchestration-analytics/infra/formats/bounce-cat-to-reasons'
import {
  type BounceCategoryRecord,
  BounceCategoryFactory,
  type BounceProviderRecord,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { BounceByDaysChart } from 'com.batch/orchestration-analytics/ui/components/bounce-by-days-chart/bounce-by-days-chart'
import { BouncePerMailboxProvider } from 'com.batch/orchestration-analytics/ui/components/bounce-per-mailbox-provider/bounce-per-mailbox-provider'
import { BounceReasons } from 'com.batch/orchestration-analytics/ui/components/bounce-reasons/bounce-reasons'
import { BounceCategory } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-category'
import {
  BounceBlockHeaderContainer,
  BounceStatsRow,
  SinceDate,
} from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'
import { fetchBounceByDays } from 'com.batch/orchestration-analytics/usecases/fetch-bounce-by-days'
import { type PlotData } from 'com.batch/shared/infra/types/chart-data'
import { STATUS } from 'constants/common'

const emptyCategories: List<BounceCategoryRecord> = new Immutable.List().push(
  ...[
    BounceCategoryFactory({
      name: 'HARD',
      count: 0,
      rate: 0,
      classifications: new Immutable.List(),
    }),
    BounceCategoryFactory({
      name: 'SOFT',
      count: 0,
      rate: 0,
      classifications: new Immutable.List(),
    }),
    BounceCategoryFactory({
      name: 'BLOCK',
      count: 0,
      rate: 0,
      classifications: new Immutable.List(),
    }),
  ]
)

type BounceReportBlockProps = {
  bounceCategories: ?List<BounceCategoryRecord>,
  bounceProviders: ?List<BounceProviderRecord>,
  token: string,
}
export const BounceReportBlock = ({
  bounceCategories,
  bounceProviders,
  token,
}: BounceReportBlockProps): React.Node => {
  const dispatch = useDispatch()

  const orchestration = useSelector(orchestrationStateSelector)
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { campaign, loadingState: orchestrationLoadingState } = orchestration
  const bounces = useSelector(state => bounceByDaysRangeSelector(state, token))

  const bounceLoadingState = useSelector(
    state => state.orchestrationAnalytics.get(token)?.bounceLoadingState
  )
  const [selectedBounce, setSelectedBounce] = React.useState<PlotData | null>(null)

  const location = useLocation()
  const activeTab = React.useMemo(() => {
    if (location.pathname.match('reasons')) return 'REASONS'
    else if (location.pathname.match('mailbox')) return 'MAILBOX'
    else return 'METRICS'
  }, [location])

  const reasons = React.useMemo(() => {
    if (!bounceCategories) return []
    return bounceCatToReasons(bounceCategories).sort((a, b) => (a.count > b.count ? -1 : 1))
  }, [bounceCategories])

  // Selon le type d'orchestration, le wording diffère pour l'affichage de la temporalité
  const sinceDateLabel = React.useMemo(() => {
    if (campaign.sendType === 'recurring') {
      switch (campaign.recurrent.recurrence) {
        case 'DAILY':
          return (
            <SinceDate>
              {selectedBounce
                ? `- on ${selectedBounce.dateTo.format('MMM Do, YYYY')}`
                : '- daily recurring'}
            </SinceDate>
          )
        case 'WEEKLY':
          return (
            <SinceDate>
              {selectedBounce
                ? `- from ${selectedBounce.dateFrom.format(
                    'MMM Do, YYYY'
                  )} to ${selectedBounce.dateTo.format('MMM Do, YYYY')}`
                : '- weekly recurring'}
            </SinceDate>
          )
        case 'MONTHLY':
          return (
            <SinceDate>
              {selectedBounce
                ? `- from ${selectedBounce.dateFrom.format(
                    'MMM Do, YYYY'
                  )} to ${selectedBounce.dateTo.format('MMM Do, YYYY')}`
                : '- monthly recurring'}
            </SinceDate>
          )
        default:
          return
      }
    } else if (campaign.sendType === 'trigger') {
      return (
        <SinceDate>
          {selectedBounce
            ? `- on ${selectedBounce.dateTo.format('MMM Do, YYYY')}`
            : `- since launch ${
                orchestration.triggerSettings.start?.format('(MMM Do, YYYY)') ?? ''
              }`}
        </SinceDate>
      )
    } else if (campaign.sendType === 'scheduled') {
      return (
        <SinceDate>
          - campaign sent on {orchestration.campaign.oneTime.sendDate?.format('MMM Do, YYYY') ?? ''}
        </SinceDate>
      )
    }
    return null
  }, [
    campaign.recurrent.recurrence,
    campaign.sendType,
    orchestration.campaign.oneTime.sendDate,
    orchestration.triggerSettings.start,
    selectedBounce,
  ])

  const displayedCategories = React.useMemo(() => {
    if (bounceLoadingState === STATUS.LOADING) return new Immutable.List()
    return (
      bounceCategories && bounceCategories.size > 0
        ? bounceCategories.filter(category => ['SOFT', 'HARD', 'BLOCK'].includes(category.name))
        : emptyCategories
    ).sort((a, b) => {
      // Hard > Soft > Block
      const order = { HARD: 1, SOFT: 2, BLOCK: 3, UNDETERMINED: 4 }
      return order[a.name] - order[b.name]
    })
  }, [bounceCategories, bounceLoadingState])

  const displayedProviders = React.useMemo(
    () => bounceProviders ?? new Immutable.List(),
    [bounceProviders]
  )

  const bounceCount = React.useMemo(
    () => analyticsMap.getIn([token, 'stats', 'bounce', 'value']) ?? 0,
    [analyticsMap, token]
  )
  const disabledTabs = React.useMemo(
    () => bounceLoadingState === STATUS.LOADING || bounceCount === 0,
    [bounceLoadingState, bounceCount]
  )

  React.useEffect(() => {
    if (token && orchestrationLoadingState === STATUS.LOADED && campaign.sendType !== 'scheduled') {
      dispatch(fetchBounceByDays({ token })).catch(() => {})
    }
  }, [dispatch, token, orchestrationLoadingState, campaign.sendType])

  return (
    <Box
      style={{
        width: 704,
        marginBottom: 0,
        display: 'flex',
        height: 'min-content',
        flexDirection: 'column',
      }}
    >
      <BoxHeader style={{ padding: '0 10px 0 20px' }}>
        <BounceBlockHeaderContainer>
          <BoxTitle>
            Bounces
            <Skeleton w={149} h={22} style={{ marginLeft: 6 }}>
              {sinceDateLabel}
            </Skeleton>
          </BoxTitle>
          <div style={{ display: 'flex', gap: 8 }}>
            <ButtonNavLink to={''} end>
              Metrics
            </ButtonNavLink>
            <ButtonNavLink to={'reasons'} {...(disabledTabs ? { disabled: true } : { end: true })}>
              Reasons
            </ButtonNavLink>
            <ButtonNavLink to={'mailbox'} {...(disabledTabs ? { disabled: true } : { end: true })}>
              Mailbox
            </ButtonNavLink>
          </div>
        </BounceBlockHeaderContainer>
      </BoxHeader>
      <BoxBody
        style={{
          height: 391,
          display: 'flex',
          flexDirection: 'column',
          padding: activeTab === 'METRICS' ? '20px 0 6px' : 0,
        }}
      >
        <Routes>
          <Route
            path={'/'}
            element={
              <React.Fragment>
                <BounceStatsRow>
                  {displayedCategories?.map(category => (
                    <BounceCategory
                      key={category.name}
                      category={category}
                      selectedBounce={selectedBounce}
                    />
                  ))}
                  <LinkDocumentation
                    target="_blank"
                    href="https://help.batch.com/en/articles/8058036-email-analytics-glossary#h_73ec8500eb"
                    intent="action"
                    style={{ marginLeft: 'auto' }}
                  >
                    Help
                  </LinkDocumentation>
                </BounceStatsRow>

                <BounceByDaysChart
                  bounces={bounces}
                  setSelectedBounce={setSelectedBounce}
                  loadingState={bounceLoadingState}
                  sendType={campaign.sendType}
                  recurrence={campaign.recurrent.recurrence}
                  totalBounces={bounceCount}
                />
              </React.Fragment>
            }
          />
          <Route path={'/reasons'} element={<BounceReasons reasons={reasons} />} />
          <Route
            path={'/mailbox'}
            element={<BouncePerMailboxProvider providers={displayedProviders} />}
          />
        </Routes>
      </BoxBody>
    </Box>
  )
}
