// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

type CampaignStateIconProps = { state: campaignStateType, schedulingType: schedulingType, ... }

export const CampaignStateIcon: React$AbstractComponent<CampaignStateIconProps, *> =
  React.forwardRef(({ state, schedulingType }: CampaignStateIconProps, ref): React.Node => {
    return state === 'COMPLETED' ? (
      <Icon
        ref={ref}
        size={14.2}
        icon="success"
        color={colors.textSuccess}
        style={{ paddingBottom: 2 }}
      />
    ) : state === 'RUNNING' ? (
      schedulingType === 'automations' ? (
        <Icon ref={ref} size={16} icon="running-fill" color={colors.fillAction} />
      ) : (
        <Icon ref={ref} size={16} icon="planned" color={colors.textAction} />
      )
    ) : state === 'DRAFT' ? (
      <Icon ref={ref} size={16} icon="draft-fill" color={colors.textDisabled} />
    ) : (
      <Icon ref={ref} size={16} icon="stopped" color={colors.textDanger} />
    )
  })
