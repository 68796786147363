// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { DropdownMenu, useDropdown } from 'components/common/button/dropdown'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { LineButtonWrapper } from './line-button.styles'

import { copiedNodeIdSelector } from '../../../models/journey.selectors'
import { NodeTooltip } from '../node-tooltip'

import { type FlowRestrictions } from 'com.batch/orchestration-journey/helpers/reactflow.types'
import { type NodeType } from 'com.batch/orchestration-journey/models/journey.records'
import { DotSvg } from 'com.batch/orchestration-journey/ui/components/icons/dot.svg'
import { NODE_TYPES } from 'com.batch/orchestration-journey/ui/components/nodes/node-types'

export type LineButtonProps = {
  onClick?: () => void,
  isActive: boolean,
  flowRestrictions: FlowRestrictions,
  reduxNodeId?: string,
  hasError?: boolean,
  className?: string,
  pasteNode: () => void,
  insertNode: (mode: NodeType, channel: ?ChannelUntilCleanup) => void,
}

export const LineButton = ({
  isActive,
  hasError,
  insertNode,
  pasteNode,
  flowRestrictions,
}: LineButtonProps): React.Node => {
  const { dropdownProps, triggerProps, closeDropdown } = useDropdown({
    placement: 'right',
    offset: [0, 6],
  })
  const theme = useTheme()
  const createOnClickForNodeTypeAndChannel = React.useCallback(
    (selectedNode: NodeType, channel: ?ChannelUntilCleanup) => () => {
      insertNode(selectedNode, channel)
      closeDropdown()
    },
    [closeDropdown, insertNode]
  )
  const copiedNodeId = useSelector(copiedNodeIdSelector)

  return copiedNodeId ? (
    <Tooltip tooltip="Duplicate here" placement="right" offset={[0, 10]}>
      <Button
        addOn="prefix"
        kind="secondary"
        intent="action"
        disabled={theme?.disabledMode}
        onClick={pasteNode}
        className="nodrag nopan"
        data-testid="button_step_add_paste"
        style={{ width: 38, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Icon icon="copy" />
      </Button>
    </Tooltip>
  ) : (
    <LineButtonWrapper isActive={isActive || dropdownProps.isOpen} className="nodrag nopan">
      <React.Fragment>
        <Button
          style={{
            width: 24,
            height: 24,
            padding: '2px 5px',
          }}
          {...triggerProps}
          intent="action"
          disabled={theme?.disabledMode}
          kind="secondary"
          data-testid="button_step_add_dropdown"
        >
          <Icon icon="add" />
        </Button>
        <div className="styled-dot">
          <DotSvg $hasError={Boolean(hasError)} />
        </div>
        {dropdownProps.isOpen && (
          <DropdownMenu {...dropdownProps} onMouseLeave={closeDropdown}>
            {NODE_TYPES.filter(
              opt =>
                (opt.channel !== 'sms' || flowRestrictions.availableChannels.has('sms')) &&
                (opt.channel !== 'email' || flowRestrictions.availableChannels.has('email')) &&
                (opt.channel !== 'push' || flowRestrictions.availableChannels.has('push'))
            ).map(({ nodeType, icon, label, channel }, index) => {
              return (
                <NodeTooltip
                  key={index}
                  nodeType={nodeType}
                  channel={channel}
                  maxMessageReached={flowRestrictions.maxMessageReached}
                >
                  <span>
                    <Button
                      addOn="prefix"
                      onClick={createOnClickForNodeTypeAndChannel(nodeType, channel)}
                      data-testid={`button_step_add_${nodeType}${channel ? `_${channel}` : ''}`}
                      disabled={nodeType === 'MESSAGE' && flowRestrictions.maxMessageReached}
                    >
                      <Icon icon={icon} />
                      {label}
                    </Button>
                  </span>
                </NodeTooltip>
              )
            })}
          </DropdownMenu>
        )}
      </React.Fragment>
    </LineButtonWrapper>
  )
}
