// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  BoxBody,
  BoxHeader,
  HeaderBoxActions,
  BoxFooter,
  FooterBoxActions,
} from 'components/common/box'
import { HeaderBoxTitle } from 'components/common/box/box'
import { Button } from 'components/common/button/button.styles'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper } from 'components/form'
import { QueryBuilder } from 'components/query/query-builder-targeting'
import { colors } from 'components/styled/tokens'

import { type Segment } from '../../infra/segments.api'
import { type fetchingState } from 'com.batch.redux/_records'
import { getAPIQueryForIdSelector } from 'com.batch.redux/query/query.selector'

import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'
import { EstimateProfile } from 'com.batch/orchestration/ui/components/estimate-profile'

const fakeErrors = Immutable.Set()
type SegmentModalProps = {
  segment: Segment,
  save: Segment => void,
  reach: EstimateProfileRecord,
  dismiss: () => void,
  savingState: fetchingState,
}
export const SegmentModal = ({
  segment,
  dismiss,
  save,
  reach,
  savingState,
}: SegmentModalProps): React.Node => {
  const [label, setLabel] = React.useState(segment.name)
  const getAPIQueryForId = useSelector(getAPIQueryForIdSelector)
  const queryId = React.useMemo(
    () => (segment.id ? 'segment-' + segment.id : 'segment-new'),
    [segment.id]
  )
  const query = React.useMemo(() => getAPIQueryForId(queryId), [getAPIQueryForId, queryId])
  const onLabelChange = React.useCallback(e => {
    if (e.target && e.target instanceof HTMLInputElement) {
      setLabel(e.target.value)
    }
  }, [])
  const onSave = React.useCallback(() => {
    save({ id: segment.id, name: label, query: JSON.stringify(query, null, 2) ?? '' })
  }, [label, query, save, segment.id])
  return (
    <Popin close={dismiss} opened style={{ width: 1200 }}>
      <Box>
        <BoxHeader style={{ height: 56 }} large>
          <HeaderBoxTitle
            style={{ width: '100%' }}
            title={segment.id ? 'Update segment' : 'New segment'}
          />
          <HeaderBoxActions large>
            <Button onClick={dismiss}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <BoxBody
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxHeight: 450,
            height: 'calc(100vh - 207px)',
            overflowY: 'auto',
          }}
          $padding
        >
          <InputWrapper label="Segment name">
            <Input
              value={label}
              onChange={onLabelChange}
              placeholder="Segment name"
              maxlength="100"
            />
          </InputWrapper>
          <InputWrapper label="Segmentation">
            <QueryBuilder isProfileMode queryId={queryId} errors={fakeErrors} hideQueryViewer />
          </InputWrapper>
          <EstimateProfile
            estimate={reach}
            forceNonOptin
            style={{
              marginRight: -20,
              marginLeft: -20,
              borderTop: `1px solid ${colors.stroke}`,
              height: 90,
              marginTop: 0,
            }}
          />
        </BoxBody>
        <BoxFooter isEditable>
          <Button onClick={dismiss} kind="inline">
            Cancel
          </Button>

          <FooterBoxActions>
            <Button
              onClick={onSave}
              kind="primary"
              intent="action"
              isLoading={savingState === 'LOADING'}
              disabled={!query || !label || label.length > 100}
            >
              Save segment
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
