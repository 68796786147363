// @flow

import * as React from 'react'

import { AppWrapper } from 'components/common/app-wrapper'

import AppRouter from './app-router'

export const App = (): React.Node => {
  return (
    <AppWrapper sidebarKind="dashboard" epicMode="audience">
      <AppRouter />
    </AppWrapper>
  )
}
