// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

export const StepMessageName: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-weight: 500;
  color: ${colors.textNeutral};

  i {
    font-size: 1.1em;
    position: relative;
    top: -1px;
  }
`
