// @flow

import { type List } from 'immutable'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { BreakpointDetector } from 'components/common/breakpoint-detector'

import { Beamer } from './beamer'
import { SidebarDashboard } from './sidebar-dashboard'
import { SidebarSettings } from './sidebar-settings'

import { type State, type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { openNew } from 'com.batch.redux/app.action'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { projectMatchingCurrentAppSelector } from 'com.batch.redux/project.selector'
import { type UserRecord } from 'com.batch.redux/user.records'
import { currentUserSelector } from 'com.batch.redux/user.selector'

type OwnProps = {
  kind: 'dashboard' | 'account',
  ...
}
type StateProps = {
  apps: List<AppRecord>,
  project: ?ProjectRecord,
  currentApp: AppRecord,
  user: UserRecord,
  company: CompanyRecord,
  ...
}
type DispatchProps = {
  openNew: typeof openNew,
  ...
}

type SidebarProps = OwnProps & StateProps & DispatchProps

const Sidebar = ({ kind, openNew, apps, currentApp, user, company, project }: SidebarProps) => {
  const node = document.getElementById('side_nav')
  if (!node) return null

  const placeholder = document.getElementById('sidebar__placeholder')
  placeholder && placeholder.parentNode && placeholder.parentNode.removeChild(placeholder)

  return ReactDOM.createPortal(
    <BreakpointDetector
      render={b => {
        const isMobile = b === 'mobile'

        return kind === 'dashboard' ? (
          <React.Fragment>
            <Beamer />
            <SidebarDashboard
              apps={apps}
              currentApp={currentApp}
              company={company}
              user={user}
              project={project}
              openNew={openNew}
              isMobile={isMobile}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Beamer />
            <SidebarSettings user={user} company={company} isMobile={isMobile} project={project} />
          </React.Fragment>
        )
      }}
    />,
    node
  )
}

const mapStateToProps = (state: State) => {
  return {
    apps: state.app.entities,
    currentApp: state.app.current,
    user: currentUserSelector(state),
    project: projectMatchingCurrentAppSelector(state),
    company: state.company,
  }
}

export const SidebarConnected: React.AbstractComponent<OwnProps> = connect<
  SidebarProps,
  OwnProps,
  _,
  _,
  _,
  _,
>(mapStateToProps, {
  openNew,
})(Sidebar)
