// @flow

import Immutable, { type Map } from 'immutable'
import { get as _get } from 'lodash-es'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from 'components/common/button'
import { Form, InputWrapper, FormRow, Input, Checkbox, Feedback } from 'components/form'
import { LinkArrow } from 'components/styled/text'

import { isEmail } from 'com.batch.common/utils'

import * as api from './api'
import { RegWizard } from './register-containers'
import { RegisterStepFooter, RegisterStep } from './register.styles'

import { Title, Text } from '../auth.styles'

// $FlowFixMe flow does not get svg file
import Illustration from 'illustrations/register_confirm_illustration.svg'

type resendEnum = 'default' | 'sending' | 'sent' | 'error'
type RegInfosProps = {
  forcedEmail: string,
  sso?: boolean,
  ...
}

export const RegisterInfos = ({ forcedEmail, sso = false }: RegInfosProps): React.Node => {
  const { token } = useParams()
  const inviteToken = typeof token === 'string' ? token : undefined
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const [loading, setLoading] = React.useState<boolean>(false)
  const [resendStatus, setResendStatus] = React.useState<resendEnum>('default')
  const [waitForEmail, setWaitForEmail] = React.useState<boolean>(false)
  const [agreed, setAgreed] = React.useState<boolean>(false)
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [email, setEmail] = React.useState(forcedEmail)

  const navigate = useNavigate()

  const onSubmit = () => {
    let err = []
    if (firstname.trim() === '') err.push(['firstname', 'Please enter a valid name.'])
    if (lastname.trim() === '') err.push(['name', 'Please enter a valid name.'])
    if (!isEmail(email)) {
      err.push(['email', 'Please enter a valid email address.'])
    }
    if (!agreed && !inviteToken) {
      err.push(['agreed', 'You have to accept the service agreement in order to register.'])
    }
    const currentErrors = Immutable.Map(err)
    setErrors(currentErrors)
    if (currentErrors.size === 0) {
      setLoading(true)
      api
        .registerInfos({ sso, email, firstname, lastname, confirmationToken: inviteToken })
        .then(() => {
          setLoading(false)
          if (sso) {
            navigate('/register/congrats', { replace: true })
          } else if (!inviteToken) {
            // si c'est pas une invite, on attend le confirm email
            setWaitForEmail(true)
          } else {
            // sinon on bascule sur le choix du password
            navigate('/register/password', { replace: true })
          }
        })
        .catch(errorsRaw => {
          setLoading(false)
          setErrors(
            Immutable.Map(errorsRaw.map(({ field, message }) => [field || 'email', message]))
          )
        })
    }
  }

  const onResend = () => {
    setResendStatus('sending')
    api.resendEmail().then(
      () => setResendStatus('sent'),
      () => setResendStatus('error')
    )
  }

  return (
    <RegWizard noProgress={sso} total={!inviteToken ? 5 : 3} step={waitForEmail ? 2 : 1}>
      <RegisterStep>
        <Title style={{ margin: '0 0 0 -2px' }}>
          {waitForEmail ? 'Confirm email' : 'Create your account'}
        </Title>
        <Text>
          {waitForEmail ? (
            <React.Fragment>
              To continue your registration, please click the link in the verification email sent to{' '}
              <strong>{email}</strong>.
            </React.Fragment>
          ) : (
            inviteToken && (
              <React.Fragment>
                You’re about to join{' '}
                <strong>{_get(window, 'invitationCompany.name', 'No name')}</strong> on Batch.
                Please fill in this registration form to start using Batch.
              </React.Fragment>
            )
          )}
        </Text>
        {waitForEmail ? (
          <React.Fragment>
            <Illustration style={{ display: 'block', height: 92, margin: '14px 0 18px 0' }} />
            {resendStatus === 'default' && (
              <Feedback
                type="insight"
                message={
                  <LinkArrow onClick={onResend}>
                    Still haven't received the email? Request a new one
                  </LinkArrow>
                }
              />
            )}
            {resendStatus === 'error' && (
              <Feedback
                type="error"
                message={
                  <React.Fragment>
                    An error occurred.
                    <LinkArrow onClick={onResend}>Please try again</LinkArrow>
                  </React.Fragment>
                }
              />
            )}
            {resendStatus === 'sent' && (
              <Feedback
                type="success"
                message={
                  <React.Fragment>
                    A new email has been sent.{' '}
                    <LinkArrow onClick={onResend}>Request a new one</LinkArrow>
                  </React.Fragment>
                }
              />
            )}
            {resendStatus === 'sending' && (
              <Feedback type="loading" message="Sending your email..." />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Form onSubmit={onSubmit}>
              <React.Fragment>
                <InputWrapper
                  feedback={errors.get('firstname', errors.get('name'))}
                  htmlFor="firstname"
                >
                  <FormRow>
                    <Input
                      name="firstname"
                      invalid={errors.has('firstname')}
                      placeholder="First name"
                      value={firstname}
                      onChange={evt => setFirstname(evt.target.value)}
                    />
                    <Input
                      name="lastname"
                      invalid={errors.has('name')}
                      placeholder="Last name"
                      value={lastname}
                      onChange={evt => setLastname(evt.target.value)}
                    />
                  </FormRow>
                </InputWrapper>
                <InputWrapper feedback={errors.get('email')} htmlFor="email">
                  <Input
                    name="email"
                    invalid={errors.has('email')}
                    placeholder="Email address"
                    value={email}
                    onChange={evt => setEmail(evt.target.value)}
                    disabled={forcedEmail != ''}
                  />
                </InputWrapper>
                {!inviteToken && (
                  <InputWrapper feedback={errors.get('agreed')} htmlFor="agreed">
                    <Checkbox
                      style={{ marginTop: '28px' }}
                      label={
                        <React.Fragment>
                          I agree to{' '}
                          <a href="https://batch.com/service-agreement" target="_blank">
                            Batch Service Agreement
                          </a>
                        </React.Fragment>
                      }
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                    />
                  </InputWrapper>
                )}
                <RegisterStepFooter>
                  <Button kind="primary" intent="action" type="submit" isLoading={loading}>
                    Continue
                  </Button>
                </RegisterStepFooter>
              </React.Fragment>
            </Form>

            <p
              style={{
                marginTop: '80px',
                width: '200%',
                transform: 'translateX(-25%)',
                textAlign: 'justify',
                opacity: 0.8,
              }}
            >
              The data collected in the present form are processed by Batch, as processor. These
              data are necessary in order to offer the services. These data are processed for the
              purpose of (i) creating and managing your account, (ii), improving the products and
              offers. The data collected can be transferred to (i) Batch team or (i) its
              subcontractors. There is no automated decision-making based on these data. The data
              are stored by Batch inside EU and for up to 5 years after the cancellation of your
              account. You have the right to request access and rectification, limitation, deletion
              of and opposition to your data for legitimate reasons, as well as the right to data
              portability, and the right to decide about your data after your death. You may
              withdraw your consent at any time. In order to exercise these rights, you need to
              provide a valid ID document at any time and you need to contact the person in charge
              of data privacy at Batch (<a href="mailto:support@batch.com">support@batch.com</a>).
              In case of dispute, you may contact the CNIL at any time.
            </p>
          </React.Fragment>
        )}
      </RegisterStep>
    </RegWizard>
  )
}
