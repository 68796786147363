// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper } from 'components/form'

import { validateJSON } from 'com.batch.common/utils'

import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'

import {
  type PushMessageRawRecord,
  type AndroidPushMessageRawRecord,
  type IosPushMessageRawRecord,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'
import { updatePushContentRecord } from 'com.batch/push/usecases/update-push-content'

type Props = { messageId: string }

const usePayloadField = (
  content: PushMessageRawRecord | IosPushMessageRawRecord | AndroidPushMessageRawRecord
): { payload?: string, isValid: boolean } => {
  const payload = React.useMemo(() => content.customPayload, [content])
  const isValid = React.useMemo(() => (payload ? validateJSON(payload) : true), [payload])

  return { payload, isValid }
}

const PayloadInput = ({
  label,
  icon,
  payload,
  isValid,
  onChange,
  margin = true,
}: {
  label: string,
  icon?: 'android' | 'ios',
  payload?: string,
  isValid: boolean,
  onChange: Function,
  margin?: boolean,
}) => {
  return (
    <InputWrapper
      style={{ marginTop: margin ? 20 : 0 }}
      label={
        <span>
          {icon && <Icon icon={icon} style={{ marginRight: 8 }} />}
          {label}
        </span>
      }
      hint="Valid JSON that your app will receive with the in-app message. The root of the JSON must be an Object, and can't have the reserved key com.batch. You can use {BATCH:TITLE}, {BATCH:BODY}, {BATCH:DEEPLINK} & {BATCH:CAMPAIGNID} variables, they will be replaced."
    >
      <Input
        rows={icon ? 4 : 11}
        placeholder={'{\n  "valid": "JSON"\n}'}
        invalid={!isValid}
        value={payload}
        onChange={onChange}
        inputStyle={{ resize: 'none' }}
      />
    </InputWrapper>
  )
}

export const PushPayloadField = ({ messageId }: Props): React.Node => {
  const apps = useSelector(currentProjectAppsSelector)
  const getContent = useSelector(getPushContentForActiveLanguageSelector)
  const dispatch = useDispatch()
  const lang = useSelector(activeLanguageValueSelector)

  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])

  const [hasIos, hasAndroid] = React.useMemo(() => {
    const platforms = apps.map(app => app.platform)
    return [platforms.includes('ios'), platforms.includes('android')]
  }, [apps])

  const [splitPerPlatform, setSplitPerPlatform] = React.useState(
    !!(content.iosContent.customPayload || content.androidContent.customPayload)
  )

  const { payload: customPayload, isValid: isPayloadValid } = usePayloadField(content.content)
  const { payload: iosCustomPayload, isValid: isIosPayloadValid } = usePayloadField(
    content.iosContent
  )
  const { payload: androidCustomPayload, isValid: isAndroidPayloadValid } = usePayloadField(
    content.androidContent
  )

  const updatePushSettings = React.useCallback(
    (record: PushContentRecord) => {
      dispatch(updatePushContentRecord({ messageId, lang, record }))
    },
    [dispatch, messageId, lang]
  )

  const updateSettings = React.useCallback(
    (field: 'ios' | 'android' | 'common') => (e: SyntheticEvent<HTMLTextAreaElement>) => {
      switch (field) {
        case 'ios':
          updatePushSettings(content.setIn(['iosContent', 'customPayload'], e.currentTarget.value))
          break
        case 'android':
          updatePushSettings(
            content.setIn(['androidContent', 'customPayload'], e.currentTarget.value)
          )
          break
        default:
          updatePushSettings(content.setIn(['content', 'customPayload'], e.currentTarget.value))
      }
    },
    [updatePushSettings, content]
  )

  const handleOnSplitPerPlatformChange = React.useCallback(() => {
    setSplitPerPlatform(s => !s)
    if (splitPerPlatform) {
      updatePushSettings(
        content
          .setIn(['androidContent', 'customPayload'], '')
          .setIn(['iosContent', 'customPayload'], '')
      )
    } else {
      updatePushSettings(content.setIn(['content', 'customPayload'], ''))
    }
  }, [splitPerPlatform, updatePushSettings, content])

  return (
    <div style={{ position: 'relative' }}>
      {hasIos && hasAndroid && (
        <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 5 }}>
          <Switch isActive={splitPerPlatform} onChange={handleOnSplitPerPlatformChange}>
            Split by platform
          </Switch>
        </div>
      )}
      {!splitPerPlatform ? (
        <PayloadInput
          label="Payload"
          payload={customPayload}
          isValid={isPayloadValid}
          onChange={updateSettings('common')}
          margin={false}
        />
      ) : (
        <React.Fragment>
          <PayloadInput
            label="iOS Payload"
            icon="ios"
            payload={iosCustomPayload}
            isValid={isIosPayloadValid}
            onChange={updateSettings('ios')}
            margin={false}
          />
          <PayloadInput
            label="Android Payload"
            icon="android"
            payload={androidCustomPayload}
            isValid={isAndroidPayloadValid}
            onChange={updateSettings('android')}
          />
        </React.Fragment>
      )}
    </div>
  )
}
