// @flow

import * as React from 'react'

import { HeaderBoxActions, HeaderBoxTitle, BoxHeader, BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { SideSheet } from 'components/common/sidesheet'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper } from 'components/form'
import { Feedback } from 'components/form/feedback'
import { Input } from 'components/form/fields/input'

import { NodeIconWrapper } from '../node-icon-wrapper'

import {
  SplitBranchFactory,
  type RandomNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

const distributeWeight = (rn: RandomNodeRecord): RandomNodeRecord => {
  const weight = Math.floor(100 / rn.splits.size)
  return rn
    .set(
      'splits',
      rn.splits.map(s => s.set('weight', weight))
    )
    .setIn(['splits', rn.splits.size - 1, 'weight'], 100 - weight * (rn.splits.size - 1))
}

type SheetRandomProps = {
  isOpened: boolean,
  rejoinNodeId: string,
  dismiss: () => void,
  value: ?RandomNodeRecord,
  onChange: RandomNodeRecord => void,
  refocusOnNode: ({ width?: number, zoom?: number }) => void,
}

export const SheetRandom = ({
  isOpened,
  dismiss,
  rejoinNodeId,
  onChange,
  value,
  refocusOnNode,
}: SheetRandomProps): React.Node => {
  const onLabelChange = React.useCallback(
    e => {
      if (e.target && e.target instanceof HTMLInputElement && value)
        onChange(value.set('label', e.target?.value))
    },
    [onChange, value]
  )

  const addBranch = React.useCallback(() => {
    if (value) {
      onChange(
        distributeWeight(
          value.set('splits', value.splits.push(SplitBranchFactory({ nextNodeId: rejoinNodeId })))
        )
      )
    }
  }, [onChange, rejoinNodeId, value])

  const createOnRemoveBranch = React.useCallback(
    (index: number) => () => {
      if (value) onChange(distributeWeight(value.set('splits', value.splits.delete(index))))
    },
    [value, onChange]
  )
  const createOnWeightChange = React.useCallback(
    (index: number) => (evt: SyntheticInputEvent<HTMLInputElement>) => {
      if (value) onChange(value.setIn(['splits', index, 'weight'], parseInt(evt.target.value)))
    },
    [onChange, value]
  )
  const onAutoDistribute = React.useCallback(() => {
    if (value) onChange(distributeWeight(value))
  }, [value, onChange])

  const distributionOk = React.useMemo(
    () => value && value.splits.reduce((acc, split) => acc + split.weight, 0) === 100,
    [value]
  )

  const sheetWidth = 400
  React.useEffect(() => {
    if (isOpened) refocusOnNode({ width: sheetWidth })
  }, [refocusOnNode, isOpened])

  if (!value) return null
  return (
    <SideSheet
      isOpened={isOpened}
      close={dismiss}
      footer={<Copyable fullWidth={true} notificationText="Step ID copied" value={value.id} />}
      footerActions={
        <Button kind="secondary" onClick={dismiss}>
          Continue
        </Button>
      }
      width={400}
    >
      <BoxHeader style={{ height: 56 }} large>
        <HeaderBoxTitle
          style={{ width: '100%' }}
          title={
            <NodeIconWrapper kind="random">
              <Input
                value={value.label}
                onChange={onLabelChange}
                placeholder="Random split title"
                style={{ marginRight: 10 }}
                inputStyle={{ fontWeight: 500 }}
              />
            </NodeIconWrapper>
          }
        />
        <HeaderBoxActions large>
          <Button onClick={dismiss}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <BoxBody $padding>
        {value.splits.map((split, index) => (
          <InputWrapper key={index} label="Branch">
            <Grid template={value.splits.size < 3 ? '1fr' : '1fr 30px'}>
              <Input
                value={split.weight}
                type="number"
                suffix={{ kind: 'text', value: '% ' }}
                style={{ paddingRight: 10 }}
                inputStyle={{ textAlign: 'left' }}
                onChange={createOnWeightChange(index)}
              />
              {value.splits.size > 2 && (
                <Button onClick={createOnRemoveBranch(index)}>
                  <Icon icon="delete" />
                </Button>
              )}
            </Grid>
          </InputWrapper>
        ))}
        <Grid template="auto auto 1fr" style={{ marginTop: 20 }}>
          {value.splits.size < 4 && (
            <Button addOn="prefix" kind="secondary" onClick={addBranch}>
              <Icon icon="add" />
              Add branch
            </Button>
          )}
          {!distributionOk && (
            <Button addOn="prefix" kind="secondary" onClick={onAutoDistribute}>
              <Icon icon="equal" />
              Fix distribution
            </Button>
          )}
        </Grid>
        {!distributionOk && (
          <div style={{ marginTop: 20 }}>
            <Feedback type="error" message={<p>The sum of branches must be 100%</p>} />
          </div>
        )}
      </BoxBody>
    </SideSheet>
  )
}
