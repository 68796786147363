// @flow
import Immutable, { type Map } from 'immutable'

import { type HtmlEditorConfig } from '../../models/html-editor-config'
import { type EmailContent } from '../types/email.message.types'

import {
  EmailContentFactory,
  type EmailContentRecord,
  EmailTemplatesFactory,
} from 'com.batch/message/models/message.records'
import { type DashboardConfigByMessageId } from 'com.batch/orchestration/infra/types/orchestration.types'
import { type EmailContentForCampaign } from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import { getMissingFields } from 'com.batch/orchestration-journey/usecases/check-incomplete-message-node'

export const parseEmail = (
  raw: EmailContent,
  config: ?DashboardConfigByMessageId
): {
  content: Map<string, EmailContentRecord>,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  return {
    content: Immutable.Map(
      raw.localizedEmails.map(({ language, region, message }) => {
        const lang = language && region ? `${language}-${region}` : language ? language : 'default'
        const htmlEditorConfig = config?.langConfigs?.[lang]?.htmlEditorConfig ?? null
        const senderIdAsInt = parseInt(message.from.senderIdentityId)
        const emailContent = EmailContentFactory({
          fromEmail: message.from.address.email,
          replyTo: message.replyTo ?? '',
          name: message.from.address.name,
          subject: message.subject,
          html: message.html,
          templates: EmailTemplatesFactory({
            subject: message.subject,
            html: message.html,
          }),
          htmlEditorConfig:
            htmlEditorConfig && htmlEditorConfig?.type
              ? // flow can't refine type here, hence the any
                ((htmlEditorConfig: any): HtmlEditorConfig)
              : null,
          senderIdentityId: !isNaN(senderIdAsInt) ? senderIdAsInt : undefined,
        })
        const missing = getMissingFields(emailContent)
        if (missing.length === 5) {
          contentFullyEmpty = true
        }
        if (missing.length > 0) {
          contentInvalid = true
        }

        return [lang, emailContent]
      })
    ),
    contentInvalid,
    contentFullyEmpty,
  }
}

export const parseEmailForCampaign = (
  raw: EmailContentForCampaign,
  config: ?DashboardConfigByMessageId
): {
  content: Map<string, EmailContentRecord>,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  return {
    content: Immutable.Map(
      raw.email.localizedEmails.map(({ language, region, subject, html }) => {
        const lang = language && region ? `${language}-${region}` : language ? language : 'default'
        const htmlEditorConfig = config?.langConfigs?.[lang]?.htmlEditorConfig ?? null
        const senderIdAsInt = parseInt(raw.email.from.senderIdentityId)
        const emailContent = EmailContentFactory({
          fromEmail: raw.email.from.address.email,
          replyTo: raw.email.replyTo ?? '',
          name: raw.email.from.address.name,
          subject: subject,
          html: html,
          templates: EmailTemplatesFactory({
            subject: subject,
            html: html,
          }),
          htmlEditorConfig:
            htmlEditorConfig && htmlEditorConfig?.type
              ? // flow can't refine type here, hence the any
                ((htmlEditorConfig: any): HtmlEditorConfig)
              : null,
          senderIdentityId: !isNaN(senderIdAsInt) ? senderIdAsInt : undefined,
        })
        const missing = getMissingFields(emailContent)
        if (missing.length === 5) {
          contentFullyEmpty = true
        }
        if (missing.length > 0) {
          contentInvalid = true
        }

        return [lang, emailContent]
      })
    ),
    contentInvalid,
    contentFullyEmpty,
  }
}
