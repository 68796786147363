// @flow

import { scaleBand, scaleLinear } from 'd3-scale'
import * as React from 'react'

import { roundedRect } from 'components/charts/chart-helper'

import { type PlotData } from 'com.batch/shared/infra/types/chart-data'
import { PlotPath } from 'com.batch/shared/ui/component/charts/plot-chart/plot-bar.styles'

type Props = {
  onOver?: (data: PlotData) => () => void,
  onLeave?: () => void,
  colors: (key: number) => string,
  hoveredBar?: string | null,
  xScale: typeof scaleBand,
  yScale: typeof scaleLinear,
  item: any,
  serieKey: number,
  gap?: number,
  hasBorderRadius?: boolean,
  barWidth: number,
  isLoading: boolean,
  ...
}

const hoveredBarSize = 15

export const Plot = ({
  onOver,
  onLeave,
  colors,
  hoveredBar,
  item,
  xScale,
  yScale,
  serieKey,
  gap = 0,
  hasBorderRadius = false,
  barWidth,
  isLoading,
}: Props): React.Node => {
  const path = React.useMemo(
    () =>
      roundedRect({
        x:
          xScale(item.data.label) +
          (xScale.bandwidth() / 2 - barWidth / 2) -
          (hoveredBar === item.data.label ? (hoveredBarSize - barWidth) / 2 : 0),
        y: yScale(item[1]) - gap,
        w: hoveredBar === item.data.label ? 15 : barWidth,
        h: yScale(item[0]) - yScale(item[1]),
        r: Math.min((yScale(item[0]) - yScale(item[1])) / 2, Math.min(xScale.bandwidth() / 2, 4)),
        tl: hasBorderRadius,
        tr: hasBorderRadius,
        bl: false,
        br: false,
      }),
    [barWidth, gap, hasBorderRadius, hoveredBar, item, xScale, yScale]
  )
  return (
    <PlotPath
      onMouseOver={onOver ? onOver(item.data) : null}
      onMouseLeave={onLeave ? onLeave : null}
      d={path}
      fill={colors(serieKey)}
      opacity={hoveredBar !== null && hoveredBar !== item.data.label ? 0.3 : 1}
      isLoading={isLoading}
    />
  )
}
