// @flow

import { scaleBand } from 'd3-scale'
import * as React from 'react'

import { TimelineItem } from 'com.batch/shared/ui/component/charts/plot-chart/timeline-item'

type Props = {
  labels: string[],
  xScale: typeof scaleBand,
  y: number,
  chartWidth: number,
  barWidth: number,
  labelsGap?: number,
  labelsSize: number,
  incomplete: boolean,
  isLoading: boolean,
  ...
}

export const Timeline = ({
  xScale,
  labels,
  y,
  chartWidth,
  barWidth,
  labelsGap = 3,
  labelsSize,
  incomplete,
  isLoading,
}: Props): React.Node => {
  const displayedLabels = React.useMemo(() => {
    const l = []
    if (labels.length > labelsGap - 1) {
      l.push(labels[0])
    }
    for (let i = labelsGap; i < labels.length - labelsGap; i += labelsGap) {
      l.push(labels[i])
    }
    if (labels.length > 0) {
      l.push(labels[labels.length - 1])
    }
    return l
  }, [labels, labelsGap])

  return (
    <g>
      {displayedLabels.map((label, i) => (
        <TimelineItem
          key={i}
          xScale={xScale}
          label={label}
          labelsSize={labelsSize}
          total={displayedLabels.length}
          y={y}
          chartWidth={chartWidth}
          barWidth={barWidth}
          index={i}
          incomplete={incomplete}
          isLoading={isLoading}
        />
      ))}
    </g>
  )
}
