// @flow

/*
  Provide a select input with label: string & value: number props
  It is used for native attribute where values needed for targeting are not easy to guess :
    - b.city_code, which use a geo code to find a city
    - b.carrier_code
 */

import Immutable, { type Map, type List } from 'immutable'
import * as React from 'react'

import { AutoCompleteMulti } from 'components/form'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type InputProps } from './helper'

type prettyOpt = { label: string, value: number, ... }

const optionToString = (option: ?prettyOpt) => option?.label ?? ''
const optionFormatter = (option: prettyOpt) => option.label
const loadOptions =
  (native: 'city' | 'carrier') =>
  (query: string): Promise<List<prettyOpt>> => {
    const url = generateUrl('api_native_attribute_value_search', { native, query })
    return request.get(url).then(
      response => {
        return new Immutable.List().push(...response)
      },
      () => new Immutable.List()
    )
  }

const getOptions =
  (native: 'city' | 'carrier') =>
  (ids: List<number>): Promise<List<prettyOpt>> => {
    const url = generateUrl('api_native_attribute_value_get_values', { native })
    return request.post(url, ids).then(
      response => {
        return new Immutable.List().push(...response)
      },
      () => new Immutable.List()
    )
  }

const requireNonEmptyList = (value: List<prettyOpt>) => value.size > 0

export const InputPrettyList = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.Node => {
  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)
  // state only holds a list of ids, to show the select we need the label, stored in this cache
  const [cache, setCache] = React.useState<Map<number, prettyOpt>>(Immutable.Map())
  const missingIds = React.useMemo(
    () => condition.value.numberList.filter(id => !cache.has(id)),
    [cache, condition.value.numberList]
  )
  const native: 'city' | 'carrier' = React.useMemo(() => {
    switch (condition.attribute?.api) {
      case 'b.city_code':
        return 'city'
      case 'b.carrier_code':
        return 'carrier'
      default:
        throw new Error('Unsupported attribute in InputPrettyList')
    }
  }, [condition.attribute?.api])

  // we use a cache for query restoration : we list ids we do not know, and trigger a fetch
  // we add not found item to the cache to avoid infinite loop
  React.useEffect(() => {
    if (missingIds.size > 0) {
      getOptions(native)(missingIds).then(options => {
        setCache(cache =>
          missingIds.reduce(
            (acc, curr) =>
              acc.set(
                curr,
                options.find(v => v.value === curr, null, {
                  label: 'not found: ' + curr,
                  value: curr,
                })
              ),
            cache
          )
        )
      })
    }
  }, [native, missingIds])

  const values: List<prettyOpt> = React.useMemo(() => {
    return condition.value.numberList.map(numberValue =>
      cache.get(numberValue, { label: numberValue + ' not found', value: numberValue })
    )
  }, [condition, cache])

  const onChangeLocal = React.useCallback(
    value => {
      setInvalid(!requireNonEmptyList(value))
      setCache(cache => value.reduce((acc, curr) => acc.set(curr.value, curr), cache))
      updateCondition(
        condition.set(
          'value',
          condition.value.set('numberList', !value ? new Immutable.List() : value.map(v => v.value))
        )
      )
    },
    [condition, updateCondition]
  )

  const onBlur = React.useCallback(() => {
    setIsTouched(true)
  }, [])

  return (
    <AutoCompleteMulti
      optionFormatter={optionFormatter}
      style={{ minWidth: 150 }}
      onBlur={onBlur}
      isClearable
      loadOptions={loadOptions(native)}
      invalid={(invalid && isTouched) || isInvalid}
      value={values}
      optionToString={optionToString}
      onChange={onChangeLocal}
    />
  )
}
