// @flow

import Routing from './vendor-fos/router'

// $FlowFixMe The path is unknown if the PHP packages are not installed
const routes = require('../../../public/json/routes.json')
/* eslint-enable import/extensions */
/* eslint-enable import/no-unresolved */

Routing.setRoutingData(routes)

const routesHandlingProjectId = [
  'analytics_stats',
  'analytics_userbase',
  'app_debug',
  'app_settings',
  'app_settings_gdpr_new',
  'app_team_edit',
  'app_team_show',
  'app_show',
  'automation_data',
  'campaign_data',
  'automation_form',
  'campaign_form',
  'campaign_list',
  'dashboard',
  'theme_editor',
]

export const generateUrl = (route: string, params: { [string]: any, ... } = {}): string => {
  const projectId = window?.initialData?.projectId
  let copiedParams = { ...params }
  if (route.substr(0, 8) === 'app_team' && copiedParams?.activeTab === 'team') {
    delete copiedParams.activeTab
    route = 'app_team_show'
  }
  if (
    route.substr(0, 12) !== 'app_settings' &&
    route !== 'project_settings' &&
    copiedParams?.activeTab
  ) {
    route = 'app_settings'
  }
  if (Boolean(projectId) && routesHandlingProjectId.includes(route)) {
    copiedParams.projectId = projectId
    route = `${route}_with_project`
  }
  let url = Routing.generate(route, copiedParams, false)
  url = trimProtocolAndDomainFromUrl(url)
  return url
}

/**
 * @deprecated Please use generateOrchestrationUrl or generateOrchestrationListUrl
 */
export const generateCampaignUrl = (
  route: string,
  schedulingType: string,
  params: { [string]: any, ... } = {}
): string => {
  const baseRoute = schedulingType === 'campaigns' ? 'campaign' : 'automation'
  params.campaignType = baseRoute === 'automation' ? params.campaignType : null
  return generateUrl(`${baseRoute}_${route}`, params)
}

export const urlUsesDashboardSubdomain = (url: string): boolean =>
  new URL(url, window.location).host.startsWith('dashboard.')

/**
 * Removes the protocol and the domain of an URL. Leaves only the path, the query parameters and the hash.
 */
export const trimProtocolAndDomainFromUrl = (url: string): string => {
  const urlObj = new URL(url, window.location)
  return urlObj.pathname + urlObj.search + urlObj.hash
}

/**
 * Transforms the URL to a relative one if the domain is not the same as the window location.
 * Useful for security reasons (e.g. we want to redirect to a user-defined route, so we remove the domain to keep our
 * users on our domain).
 */
export const trimUnsecureDomain = (url: string): string => {
  const requiredDomain = urlUsesDashboardSubdomain(window.location.href)
    ? window.location.host.split('.', 2)[1]
    : window.location.host

  const urlHost = new URL(url, window.location).host
  if (!urlHost.endsWith(requiredDomain)) {
    const { host, protocol } = window.location
    return `${protocol}//${host}${trimProtocolAndDomainFromUrl(url)}`
  }
  return url
}
