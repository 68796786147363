// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { OptionContainer } from './radio.styles'

export type RadioType = {
  label?: React.Node,
  checked?: boolean,
  disabled?: boolean,
  className?: string,
  style?: { [string]: string | number, ... },
  onChange: Function,
  ...
}

const Radio = ({
  label = '',
  checked = false,
  disabled = false,
  className,
  style,
  onChange,
  ...rest
}: RadioType): React.Node => {
  const theme = useTheme()
  return (
    <OptionContainer className={className} style={style}>
      <input
        {...rest}
        disabled={disabled || theme?.disabledMode}
        checked={checked}
        type="checkbox"
        onChange={onChange}
      />
      <span className="styled-option-symbol" />
      <span className="styled-option-label">{label}</span>
    </OptionContainer>
  )
}

export { Radio }
