// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import * as schemes from 'components/styled/tokens/schemes'

export const Notification: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 0px ${schemes.grayscale['10']};
  padding: 12px;
  width: 100%;
  border: 1px solid #e5e5e5;
  max-height: 85px;
  margin: auto;
`

export const NotificationIcon: StyledComponent<{}, {}, HTMLImageElement> = styled.img`
  background-color: #acb1b9;
  border-radius: 8px;
  min-width: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`

export const PushPreviewContainer: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  display: flex;
  height: 100%;
  padding: 0 12px;
`

type NotificationContentProps = { hasMedia?: boolean, ... }
export const NotificationContent: StyledComponent<NotificationContentProps, {}, HTMLDivElement> =
  styled.div`
    display: flex;
    flex-direction: column;
    max-height: 61px;
    max-width: ${(props: NotificationContentProps) => (props.hasMedia ? '142px' : '180px')};
  `

type PropsPlaceholder = { isPlaceholder: boolean, ... }
export const NotificationTitle: StyledComponent<PropsPlaceholder, { ... }, HTMLHeadingElement> =
  styled.h4`
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${(props: PropsPlaceholder) => props.isPlaceholder && `color: ${schemes.grayscale[40]};`}
  `

export const NotificationMessage: StyledComponent<PropsPlaceholder, { ... }, HTMLParagraphElement> =
  styled.p`
    font-size: 14px;
    color: ${schemes.grayscale[80]};
    margin: 5px 0 0 0;
    max-height: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    ${(props: PropsPlaceholder) => props.isPlaceholder && `color: ${schemes.grayscale[40]};`}

    /* truncate text after 2 lines with ellipsis https://css-tricks.com/line-clampin/#aa-the-standardized-way */
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `
type NotificationBodyProps = { $hasMedia: boolean, ... }
export const NotificationBody: StyledComponent<NotificationBodyProps, {}, HTMLDivElement> =
  styled.div`
    width: 186px;
    ${(p: NotificationBodyProps) =>
      p.$hasMedia &&
      css`
        display: grid;
        grid-template-columns: 1fr 36px;
        grid-gap: 12px;
      `};
  `

export const NotificationMedia: StyledComponent<{}, {}, HTMLImageElement> = styled.img`
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
`
