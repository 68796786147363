// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

export type RemoveEmailContentAction = ReduxAction<'REMOVE_EMAIL_CONTENT', string>
export const removeEmailContent = (messageId: string): RemoveEmailContentAction => {
  return {
    type: 'REMOVE_EMAIL_CONTENT',
    payload: messageId,
  }
}
