/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type OrderedSet, type List } from 'immutable'
import * as React from 'react'

import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Popin } from 'components/common/popin/popin'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Select, AutoComplete } from 'components/form'

import config from 'com.batch.common/config'

import {
  type AppRecord,
  type EventDataAttributeRecord,
  EventDataAttributeFactory,
} from 'com.batch.redux/_records'
import { fetchEventData } from 'com.batch.redux/stat.api'

type ConditionEventParamsProps = {
  allowedKeys: OrderedSet<EventDataAttributeRecord>,
  app: AppRecord,
  canUseData: boolean,
  eventId: string,
  eventName: string,
  onEventParamKeyChanged: (key: string) => void,
  onEventParamValueChanged: (value: ?(boolean | string | number)) => void,
  pickedKey: string,
  value: ?(boolean | string | number),
  ...
}
type ConditionEventParamsState = {
  opened: boolean,
  options: Array<{ label: string, value: string, ... }>,
  ...
}

class ConditionEventParam extends React.PureComponent<
  ConditionEventParamsProps,
  ConditionEventParamsState,
> {
  constructor(props: ConditionEventParamsProps) {
    super(props)
    // $FlowFixMe
    this.buttonRef = React.createRef()

    this.state = {
      opened: false,
      options: typeof props.value === 'string' ? [{ value: props.value, label: props.value }] : [],
    }
  }

  newOptionCreator: { ... } = ({
    label,
    labelKey,
    valueKey,
  }: {
    label: string,
    labelKey: string,
    valueKey: string,
    ...
  }) => {
    return {
      [valueKey]: label,
      [labelKey]: label,
    }
  }
  onNewOptionCreated: any = (option: string) => {
    this.setState({ options: [...this.state.options, { value: option, label: option }] })
    this.props.onEventParamValueChanged(option)
  }
  close: () => void = () => this.setState({ opened: false })

  open: () => void = () => {
    this.setState({ opened: true })
    let key = this.getPickedKey()
    if (key.name === '' && !this.props.app.features.has('query-event-data')) {
      this.props.onEventParamKeyChanged('Label|||label')
    }
    // $FlowFixMe
    this.buttonRef?.current.blur()
  }

  getPickedKey: () => EventDataAttributeRecord = () => {
    const found = this.props.allowedKeys.find(
      row => `${row.name}|||${row.kind}` === this.props.pickedKey,
      EventDataAttributeFactory()
    )
    return found ? found : EventDataAttributeFactory()
  }

  valueIsSet: () => boolean = () => {
    return (
      typeof this.props.value !== 'undefined' &&
      this.props.value !== null &&
      this.props.value !== ''
    )
  }

  fetchOptions: string => Promise<List<{ label: string, value: string, ... }>> = (
    query: string
  ) => {
    const key = this.getPickedKey()

    return fetchEventData({
      appId: this.props.app.id,
      eventId: this.props.eventId,
      eventDataType: key.kind,
      eventDataAttributeKey: key.name,
      query,
    }).then(
      res => {
        const options = res.body.results[this.props.eventId] || []
        return new Immutable.List().push(
          ...options
            .filter(row => row.value !== '')
            .map(row => {
              return {
                label: row.value.toString(),
                value: row.value.toString(),
              }
            })
        )
      },
      err => {
        console.warn(err)
        return new Immutable.List()
      }
    )
  }

  render(): React.Node {
    const {
      allowedKeys,
      pickedKey,
      onEventParamKeyChanged,
      canUseData,
      value,
      eventName,
      onEventParamValueChanged,
      app,
    } = this.props
    const key = this.getPickedKey()

    const optionsKey = allowedKeys
      .filter(
        eventAttribute =>
          eventAttribute.kind === 'label' ||
          (canUseData && (eventAttribute.kind !== 'attribute' || eventAttribute.type !== 'FLOAT'))
      )
      .map(eventAttribute => {
        return {
          value: `${eventAttribute.name}|||${eventAttribute.kind}`,
          label: eventAttribute.name,
        }
      })
      .toList()
    const valid = !!key.name && this.valueIsSet()
    const hasLabel = allowedKeys.filter(k => k.type === '__LABEL__').size > 0

    return (
      <div>
        {allowedKeys.size > 0 && (
          <Button
            // $FlowFixMe focus wont let me go
            ref={this.buttonRef}
            onClick={this.open}
            kind="secondary"
            intent="neutral"
            height={24}
            style={{ textTransform: 'none' }}
          >
            {valid
              ? `${key.name} = ${
                  typeof value === 'boolean'
                    ? value
                      ? 'true'
                      : 'false'
                    : typeof value === 'undefined' || value === null
                      ? ''
                      : value
                }`
              : 'Filter'}
          </Button>
        )}
        <Popin close={this.close} opened={this.state.opened}>
          <div style={{ width: '500px', height: '500px' }}>
            <Box>
              <BoxHeader>
                <HeaderBoxTitle title={`Filter event “${eventName}”`} />
              </BoxHeader>
              <BoxBody $padding>
                {(hasLabel || app.features.has('query-event-data')) && (
                  <React.Fragment>
                    <FlexLine>
                      <FlexLineItem width={100}>
                        <label>Property</label>
                      </FlexLineItem>
                      <FlexLineItem grow={1}>
                        {!app.features.has('query-event-data') && allowedKeys.size > 0 ? (
                          <label>Label</label>
                        ) : (
                          <Select
                            isClearable={false}
                            optionToString={option => option?.label ?? ''}
                            onChange={v => {
                              if (v) {
                                onEventParamKeyChanged(v.value)
                              }
                            }}
                            options={optionsKey}
                            placeholder="Pick an event property"
                            value={optionsKey.find(o => o.value === pickedKey)}
                          />
                        )}
                      </FlexLineItem>
                    </FlexLine>
                    <FlexLine style={{ marginTop: '15px' }}>
                      <FlexLineItem width={100}>
                        <label>With value</label>
                      </FlexLineItem>
                      <FlexLineItem grow={1}>
                        {key.type === 'BOOLEAN' && (
                          <TableToggle width={160}>
                            <TableToggleItem
                              active={value === true || value === 'true'}
                              onClick={() => onEventParamValueChanged(true)}
                            >
                              YES
                            </TableToggleItem>
                            <TableToggleItem
                              active={value === false || value === 'false'}
                              onClick={() => onEventParamValueChanged(false)}
                            >
                              NO
                            </TableToggleItem>
                          </TableToggle>
                        )}
                        {key.type === 'INTEGER' && (
                          <input
                            className="form-control"
                            onChange={evt => onEventParamValueChanged(evt.target.value)}
                            type="number"
                            value={typeof value === 'string' ? value : ''}
                          />
                        )}
                        {key.type !== 'BOOLEAN' && key.type !== 'INTEGER' && !!pickedKey && (
                          <AutoComplete
                            loadOptions={this.fetchOptions}
                            optionToString={option => option?.label ?? ''}
                            value={
                              value && typeof value === 'string' ? { label: value, value } : null
                            }
                            optionCreator={txt => ({ label: txt, value: txt })}
                            onChange={opt => {
                              if (opt) onEventParamValueChanged(opt.value)
                            }}
                          />
                        )}
                      </FlexLineItem>
                    </FlexLine>
                  </React.Fragment>
                )}
                <FlexLine>
                  {!canUseData && (
                    <FlexLineItem grow={1}>
                      <br />
                      You can only filter events by labels. Contact your support team to learn more
                      about Event Data.
                    </FlexLineItem>
                  )}
                  {canUseData && allowedKeys.size < 2 && (
                    <FlexLineItem grow={1}>
                      <br />
                      Missing some event data ? Check{' '}
                      <a
                        href={config.common.urls.appSettings
                          .replace('{companyId}', this.props.app.companyId)
                          .replace('{appId}', this.props.app.id)
                          .replace('{activeTab}', 'custom-data')}
                      >
                        {' '}
                        your app's settings
                      </a>{' '}
                      or visit the{' '}
                      <a href="https://doc.batch.com/ios/custom-data/custom-events.html">
                        documentation
                      </a>{' '}
                      to learn more
                    </FlexLineItem>
                  )}
                </FlexLine>
              </BoxBody>
              <BoxFooter isEditable>
                <Button
                  intent="action"
                  kind="inline"
                  onClick={() => {
                    if (!valid) {
                      this.props.onEventParamKeyChanged('')
                    }
                    this.close()
                  }}
                >
                  Close
                </Button>

                <FooterBoxActions>
                  <Button
                    disabled={!valid}
                    kind="primary"
                    intent="danger"
                    onClick={() => {
                      this.props.onEventParamKeyChanged('')
                      this.close()
                    }}
                  >
                    Remove
                  </Button>
                  <Button disabled={!valid} i kind="primary" intent="action" onClick={this.close}>
                    Add filter
                  </Button>
                </FooterBoxActions>
              </BoxFooter>
            </Box>
          </div>
        </Popin>
      </div>
    )
  }
}

export default ConditionEventParam
