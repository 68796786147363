// @flow

import {
  SubDistributionStateFactory,
  type SubDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'
import { type FetchEmailSubDistributionActions } from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import { STATUS } from 'constants/common'

const initialState = SubDistributionStateFactory()

type EmailSubDistributionAction = FetchEmailSubDistributionActions

export function emailSubDistributionReducer(
  state: SubDistributionStateRecord = initialState,
  action: EmailSubDistributionAction
): SubDistributionStateRecord {
  switch (action.type) {
    case 'FETCH_EMAIL_SUB_DISTRIBUTION':
      return state.set('loadingState', STATUS.LOADING)
    case 'FETCH_EMAIL_SUB_DISTRIBUTION_SUCCESS':
      return state.set('loadingState', STATUS.LOADED).set('distribution', action.payload)
    case 'FETCH_EMAIL_SUB_DISTRIBUTION_FAILURE':
      return state.set('loadingState', STATUS.ERROR)
    default:
      return state
  }
}
