// @flow

import * as React from 'react'

import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

import { GridToolTip, ImgStep } from './node-tooltip.styles'

import { type NodeType } from 'com.batch/orchestration-journey/models/journey.records'

type NodeTooltipProps = {
  nodeType: NodeType,
  children: React.Node,
  maxMessageReached: boolean,
  channel: ?ChannelUntilCleanup,
}

export const NodeTooltip = ({
  nodeType,
  channel,
  children,
  maxMessageReached,
}: NodeTooltipProps): React.Node => {
  const nodeDescription = React.useMemo(() => {
    switch (nodeType) {
      case 'MESSAGE':
        switch (channel) {
          case 'email':
            return 'Send an email to your users.'
          case 'sms':
            return 'Send an SMS to your users.'
          case 'push':
            return 'Send a Push notification to your users.'
        }
        break
      case 'TIMER':
        return 'Make the user wait before going to the next step.'
      case 'RANDOM':
        return 'Separate your users in 2 to 4 branches randomly by percentage.'
      case 'YESNO':
        return 'Separate your users simply in 2 branches by targeting rules.'
      case 'FINAL':
        return 'Not offered yet this text will never show'
    }
  }, [nodeType, channel])

  return (
    <Tooltip
      minWidth={200}
      maxWidth={260}
      placement="right"
      offset={[42, 4]}
      tooltip={
        <GridToolTip template={'120px 120px'} alignItems="start">
          <ImgStep $nodeType={nodeType} $channel={channel} />
          <div>
            <p>
              {nodeType === 'MESSAGE' && maxMessageReached ? (
                <React.Fragment>
                  {nodeDescription}
                  <br />
                  <span style={{ color: colors.textDangerContrast }}>
                    Impossible to add more than 20 messages.
                  </span>
                </React.Fragment>
              ) : (
                nodeDescription
              )}
            </p>
          </div>
        </GridToolTip>
      }
    >
      {children}
    </Tooltip>
  )
}
