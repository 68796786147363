// @flow

import styled, { type StyledComponent } from 'styled-components'

export const PreviewSwitchContainer: StyledComponent<*, { ... }, *> = styled.div`
  position: sticky;
  height: fit-content;
  /* margin négatif égal à la largeur du component pour que l'iframe de preview "ignore" l'existance de ce composant pour calculer ses margins auto (pour se centrer) */
  margin-left: -45px;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
`
