// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Box } from 'components/common/box'
import { Button } from 'components/common/button'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

export const SettingsGlobalSubNav: StyledComponent<*, *, *> = styled.nav`
  width: 100%;
  height: 55px;
  background: ${colors.fill};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid ${colors.stroke};
  display: flex;
  align-items: center;
  ${Button} {
    &:first-child {
      margin-left: 14px;
    }
  }
`
export const DragAndDropContainer: StyledComponent<*, *, *> = styled.div`
  width: 552px;
  height: 176px;
  background: ${schemes.grayscale['01']};
  border: 1px dashed ${schemes.darklucent['20']};
  border-radius: 6px;
`
type DragAndDropTextProps = { disabled: boolean, ... }
export const DragAndDropText: StyledComponent<DragAndDropTextProps, *, *> = styled.div`
  text-align: center;
  padding: 15px 82px 0px 71px;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: -0.02em;
  height: 76px;
  border-right: 1px solid ${colorsLegacy.stroke.lighter};
  color: ${(p: DragAndDropTextProps) => (p.disabled ? colors.textDisabled : colors.textLight)};
`
export const DragAndDropIconContainer: StyledComponent<*, *, *> = styled.div`
  padding: 40px;
  .icon-container {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    overflow: hidden;
  }
`
export const Separator: StyledComponent<*, *, *> = styled.div`
  width: 1px;
  height: 76px;
  border: red;
`
export const DangerZone: StyledComponent<*, *, *> = styled(Box)`
  border: 1px solid ${schemes.red['30']};
`
