// @flow

import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type OrchestrationStatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'

export type FetchOrchestrationAnalyticsAction = ReduxAction<'FETCH_ORCHESTRATION_ANALYTICS', string>

export type FetchOrchestrationAnalyticsSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_ANALYTICS_SUCCESS',
  { data: null | OrchestrationStatsRecord, token: string },
>

export type FetchOrchestrationAnalyticsFailureAction = ReduxAction<
  'FETCH_ORCHESTRATION_ANALYTICS_FAILURE',
  { token: string, error: Error },
>

export type FetchOrchestrationAnalyticsActions =
  | FetchOrchestrationAnalyticsAction
  | FetchOrchestrationAnalyticsSuccessAction
  | FetchOrchestrationAnalyticsFailureAction

export const fetchOrchestrationAnalytics =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.loadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity

    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchOrchestrationAnalyticsAction = {
        type: 'FETCH_ORCHESTRATION_ANALYTICS',
        payload: token,
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchOrchestrationData({ project, token })
        const fetchSuccessAction: FetchOrchestrationAnalyticsSuccessAction = {
          type: 'FETCH_ORCHESTRATION_ANALYTICS_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchOrchestrationAnalyticsFailureAction = {
          type: 'FETCH_ORCHESTRATION_ANALYTICS_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
