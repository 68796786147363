// @flow

import { type Set } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { RemovableBlockWrapper } from './common.styles'
import { IncompleteFlag } from './message-block.styles'
import { SplitContainer, SplitBox, SplitLabel, SplitTag, SplitSources } from './split.styles'

import { NodeIconWrapper } from '../node-icon-wrapper'

import {
  type RandomNodeRecord,
  type NodeError,
} from 'com.batch/orchestration-journey/models/journey.records'

export type RandomButtonProps = {
  randomNode: RandomNodeRecord,
  onClick: () => void,
  onRemove?: () => void,
  errors: Set<NodeError>,
  reduxNodeId: string,
  editingNodeId?: string,
}

export const RandomButton: React.AbstractComponent<RandomButtonProps> = React.forwardRef(
  (
    { randomNode, onRemove, onClick, errors, reduxNodeId, editingNodeId }: RandomButtonProps,
    ref
  ): React.Node => {
    return (
      <RemovableBlockWrapper isRemovable={Boolean(onRemove)} className="nodrag nopan">
        <SplitContainer
          ref={ref}
          className="nodrag nopan"
          onClick={onClick}
          data-testid={reduxNodeId}
        >
          {errors.includes('SPLIT_RANDOM_DISTRIBUTION') && (
            <IncompleteFlag>
              <Icon icon="edit" />
            </IncompleteFlag>
          )}
          <SplitBox
            $hasError={false}
            $isEditing={editingNodeId === reduxNodeId}
            className="nodrag nopan"
          >
            <NodeIconWrapper kind="random">
              <SplitLabel className="nodrag nopan">
                {randomNode.label ? randomNode.label : 'Random split'}
              </SplitLabel>
            </NodeIconWrapper>
          </SplitBox>
          <SplitSources className="nodrag nopan">
            {randomNode.splits.map(({ weight }, index) => {
              return <SplitTag key={index}>{weight}%</SplitTag>
            })}
          </SplitSources>
        </SplitContainer>
        <div className="nodrag nopan styled-remove">
          <Button
            onClick={onRemove}
            intent="danger"
            kind="secondary"
            className="nodrag nopan"
            data-testid="button_remove_node"
          >
            <Icon icon="delete" />
          </Button>
        </div>
      </RemovableBlockWrapper>
    )
  }
)
