/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { ThemeProvider, useTheme } from 'styled-components'

import { BarChart } from 'components/charts/bar-chart'
import { DataSetFactory, DataPointFactory, getChartFakeData } from 'components/charts/chart-helper'
import { Box, BoxBody, HeaderBoxTitle, BoxHeader, HeaderBoxActions } from 'components/common/box'
import { EmptyField, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { DateRangePicker } from 'components/form'
import { schemes } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'
import * as predefinedRanges from 'com.batch.common/predefined-ranges'
import { numberFormat } from 'com.batch.common/utils'

import { type urlParams } from './gdpr.types'

import { type GDPRByDayRecord } from 'com.batch.redux/stat.records'

const NOW = dayjs()

type GdprDataProps = {
  hasData: boolean,
  data: List<GDPRByDayRecord>,
  updateUrl: urlParams => void,
  from: string,
  to: string,
  error: boolean,
  ...
}

const ranges = new Immutable.List().push(
  predefinedRanges.last30Days,
  predefinedRanges.last90Days,
  predefinedRanges.currentMonth
)

const categoryColors = {
  api: '#c1b2ff',
  dashboard: '#93c7d1',
  mobile: '#ffc278',
}

const emptyChart = getChartFakeData({ periods: 32, total: 10 })

export const GdprData = ({
  data,
  hasData,
  updateUrl,
  from,
  to,
  error,
}: GdprDataProps): React.Node => {
  // ====================== DERIVED
  const range = React.useMemo(() => {
    return {
      from: dayjs(from, 'YYYY-MM-DD').endOf('day'),
      to: dayjs(to, 'YYYY-MM-DD').endOf('day'),
    }
  }, [from, to])

  // ====================== LOCAL STATE
  const [overIndex, setOverIndex] = React.useState<null | number>(null)

  // ====================== DERIVED STATE DATA
  const total = React.useMemo(
    () => data.reduce((acc, current) => acc + current.dashboard + current.api + current.mobile, 0),
    [data]
  )

  const api = React.useMemo(() => data.reduce((acc, current) => acc + current.api, 0), [data])
  const mobile = React.useMemo(() => data.reduce((acc, current) => acc + current.mobile, 0), [data])
  const dashboard = React.useMemo(
    () => data.reduce((acc, current) => acc + current.dashboard, 0),
    [data]
  )
  const countByOrigin = React.useMemo(() => ({ api, dashboard, mobile }), [api, dashboard, mobile])

  const chartDataSets = React.useMemo(() => {
    return new Immutable.List().push(
      ...Object.keys(categoryColors).map(origin =>
        DataSetFactory({
          color: categoryColors[origin],
          label: origin === 'mobile' ? 'sdk' : origin,
          total: countByOrigin[origin],
          data: data.map(d =>
            DataPointFactory({
              value: d[origin],
              date: d.date,
            })
          ),
        })
      )
    )
  }, [countByOrigin, data])

  // empty state constants
  const theme = useTheme()

  const isLoading = React.useMemo(() => theme && theme.isLoading, [theme])
  const isEmpty = React.useMemo(() => !hasData || total === 0 || error, [error, hasData, total])

  return (
    <Box>
      <BoxHeader
        style={{
          borderBottom: isEmpty || isLoading ? 0 : '1px solid #e9e9e9',
        }}
      >
        <HeaderBoxTitle title="Analytics" />
        <HeaderBoxActions>
          <ThemeProvider theme={{ kind: 'filter' }}>
            <DateRangePicker
              changePlaceholderOnFocus={true}
              placeholder="Last month"
              style={{ maxWidth: 260 }}
              disabledDays={date => NOW.isBefore(date)}
              range={range}
              position="right"
              icon="calendar"
              shortcuts={ranges}
              setRange={range =>
                updateUrl({
                  from: range.from.format('YYYY-MM-DD'),
                  to: range.to.format('YYYY-MM-DD'),
                })
              }
            />
          </ThemeProvider>
        </HeaderBoxActions>
      </BoxHeader>

      <Wrapper
        isLoading={isLoading}
        isEmpty={isLoading || total === 0 || error}
        isOverlayShown={!isLoading && isEmpty && hasData} // si on a pas de data du tout, l'empty sate sera global
        overlayContainerStyle={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        overlayProps={
          error
            ? {
                status: 'error',
                title: 'Something went wrong',
                refresh: () => window.location.reload(),
              }
            : {
                status: 'empty',
                title: 'No data to display',
                description: 'No data for the selected period',
              }
        }
      >
        <BoxBody style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          <Grid template="1fr 140px 140px 140px" gap={0}>
            <div style={{ padding: '17px 24px 16px 24px', borderTop: '1px solid #e9e9e9' }}>
              <div>REQUESTS</div>
              <div style={{ fontSize: '24px' }}>
                {!isEmpty ? (
                  numberFormat(total)
                ) : (
                  <EmptyField _width={94} _height={23} style={{ marginTop: 12 }} />
                )}
              </div>
            </div>
            {Object.keys(categoryColors).map(origin => (
              <div
                key={origin}
                style={{
                  padding: '16px 24px',
                  textAlign: 'right',
                  borderTop: `2px solid ${
                    !isEmpty ? categoryColors[origin] : schemes.grayscale['30']
                  }`,
                }}
              >
                <div>{origin === 'mobile' ? 'SDK' : origin.toUpperCase()}</div>
                <div style={{ fontSize: '24px' }}>
                  {!isEmpty ? (
                    numberFormat(countByOrigin[origin])
                  ) : (
                    <EmptyField _width={78} _height={23} style={{ marginTop: 12 }} />
                  )}
                </div>
              </div>
            ))}
          </Grid>
          <div onMouseOut={() => setOverIndex(null)}>
            <BarChart
              clamp
              height={200}
              setOverIndex={setOverIndex}
              sets={isEmpty ? emptyChart : chartDataSets}
              loading={isLoading}
              timelineMode="bottom"
              fadeOnOver
              overIndex={overIndex}
            />
          </div>
        </BoxBody>
      </Wrapper>
    </Box>
  )
}
