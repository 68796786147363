// @flow

import { debounce } from 'lodash-es'
import * as React from 'react'
import { type Node as ReactNode } from 'react'

export type Breakpoint = 'mobile' | 'desktop'

export type BreakpointDetectorProps = {
  render: (breakpoint: Breakpoint) => ReactNode,
  ...
}

export const BreakpointDetector = (props: BreakpointDetectorProps): React.Node => {
  const [breakpoint, setBreakpoint] = React.useState<Breakpoint>(getBreakpoint(window.innerWidth))

  const onResize = debounce(() => setBreakpoint(getBreakpoint(window.innerWidth)), 500)
  React.useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  })

  return props.render(breakpoint)
}

function getBreakpoint(screenSize: number): Breakpoint {
  if (screenSize <= 980) return 'mobile'
  return 'desktop'
}

export default BreakpointDetector
