// @flow
import dayjs from 'dayjs'
import * as React from 'react'

import { schemes } from 'components/styled/tokens'

import { humanizeDayjs, randomSize } from 'com.batch.common/utils'

import { type pushCampaignSendType } from 'com.batch.redux/_records'

import { bouncesColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { BounceByDaysWrapper } from 'com.batch/orchestration-analytics/ui/components/bounce-by-days-chart/bounce-by-days-chart.styles'
import { type PlotData } from 'com.batch/shared/infra/types/chart-data'
import { PlotChart } from 'com.batch/shared/ui/component/charts/plot-chart/plot-chart'
import { STATUS } from 'constants/common'

type Props = {
  bounces: PlotData[],
  setSelectedBounce: (PlotData | null) => void,
  loadingState?: typeof STATUS,
  recurrence: 'DAILY' | 'WEEKLY' | 'MONTHLY',
  sendType: pushCampaignSendType,
  totalBounces: number,
  ...
}

export const BounceByDaysChart = ({
  bounces,
  setSelectedBounce,
  loadingState,
  sendType,
  recurrence,
  totalBounces,
}: Props): React.Node => {
  const ref = React.useRef(null)
  const [width, setWidth] = React.useState(0)
  const groups = ['block', 'hard', 'soft']
  const handleOnOver = React.useCallback(
    (bounce: PlotData) => {
      setSelectedBounce(bounce)
    },
    [setSelectedBounce]
  )
  const handleOnLeave = React.useCallback(() => setSelectedBounce(null), [setSelectedBounce])
  const nbBars = React.useMemo(() => {
    if (sendType === 'recurring') return 12
    return 14
  }, [sendType])

  const skeletonData = React.useMemo(() => {
    let isFirst = true
    return Array.from({ length: nbBars }, (_, index) => index).map(i => {
      const date = dayjs
        .utc()
        .subtract(nbBars, 'day')
        .add(i + 1, 'day')
      let label = date.format('DD MMM')
      if (isFirst) {
        label = humanizeDayjs({
          date: date,
          timeframe: true,
        })
      }
      isFirst = false
      if (date.isSame(dayjs.utc(), 'day')) {
        label = humanizeDayjs({
          date,
          timeframe: true,
        })
      }
      const hard = randomSize(100, 600)
      const soft = randomSize(100, 600)
      const block = randomSize(100, 600)
      const total = hard + block + soft
      return {
        dateFrom: date,
        dateTo: date,
        label,
        hard: {
          value: hard,
          rate: hard / total,
        },
        soft: {
          value: soft,
          rate: soft / total,
        },
        block: {
          value: block,
          rate: block / total,
        },
      }
    })
  }, [nbBars])

  const hasNoBounces = React.useMemo(() => {
    return (
      sendType === 'scheduled' ||
      (sendType !== 'scheduled' && loadingState === STATUS.LOADED && bounces.length === 0)
    )
  }, [sendType, loadingState, bounces])

  const data = React.useMemo(() => {
    return loadingState === STATUS.LOADING || hasNoBounces ? skeletonData : bounces
  }, [loadingState, hasNoBounces, skeletonData, bounces])

  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.clientWidth - 40)
    }
  }, [])

  const noDataMsg = React.useMemo(() => {
    if (sendType === 'scheduled') return 'No data per day on a campaign.'
    if (totalBounces === 0) return 'No bounces for this automation.'
    if (sendType === 'trigger')
      return 'This graph shows bounces over the last 14 days.\\ANo bounces occurred in the last 14 days.'
    switch (recurrence) {
      case 'DAILY':
        return 'This graph shows bounces over the last 14 days.\\ANo bounces occurred in the last 14 days.'
      case 'WEEKLY':
        return 'This graph shows bounces over the last 11 weeks.\\ANo bounces occurred in the last 11 weeks.'
      case 'MONTHLY':
        return 'This graph shows bounces over the last 11 months.\\ANo bounces occurred in the last 11 months.'
    }
  }, [sendType, recurrence, totalBounces])

  return (
    <BounceByDaysWrapper noDataMsg={noDataMsg} overlay={hasNoBounces} ref={ref}>
      <PlotChart
        groups={groups}
        data={data}
        colors={
          hasNoBounces
            ? [schemes.grayscale['50'], schemes.grayscale['30'], schemes.grayscale['10']]
            : [bouncesColors.block, bouncesColors.hard, bouncesColors.soft]
        }
        width={width}
        height={255}
        margin={{ top: 20, bottom: 2 }}
        onOver={handleOnOver}
        onLeave={handleOnLeave}
        nbBars={nbBars}
        isLoading={loadingState === STATUS.LOADING}
      />
    </BounceByDaysWrapper>
  )
}
