// @flow
import { type Set } from 'immutable'
import * as React from 'react'

import { useBlurOnClickCallback } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { RemovableBlockWrapper } from './common.styles'
import { TimerButtonBase } from './timer-button.styles'

import { type NodeError } from 'com.batch/orchestration-journey/models/journey.records'

export type TimerButtonProps = {
  value: string,
  errors: Set<NodeError>,
  onClick: () => void,
  onRemove?: () => void,
  reduxNodeId: string,
  className?: string,
  editingNodeId?: string,
}
export const TimerButton = ({
  value,
  onClick,
  errors,
  className,
  onRemove,
  reduxNodeId,
  editingNodeId,
}: TimerButtonProps): React.Node => {
  const onClickBlur = useBlurOnClickCallback(onClick)
  const hasError = errors.includes('INVALID_TIMER')
  return (
    <RemovableBlockWrapper isRemovable={Boolean(onRemove)} className="nodrag nopan">
      <TimerButtonBase
        onClick={onClickBlur}
        className={className}
        addOn="prefix"
        data-testid={reduxNodeId}
        $hasError={hasError}
        $isEditing={editingNodeId === reduxNodeId}
      >
        <Icon icon="wait" />
        <span>{hasError ? 'Invalid timer value' : value}</span>
      </TimerButtonBase>
      <div className="nodrag nopan styled-remove">
        <Button
          onClick={onRemove}
          intent="danger"
          kind="secondary"
          className="nodrag"
          data-testid="button_remove_node"
        >
          <Icon icon="delete" />
        </Button>
      </div>
    </RemovableBlockWrapper>
  )
}
