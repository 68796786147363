// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

import { kformat, percentage, randomSize } from 'com.batch.common/utils'

import {
  Cluster,
  ClusterSection,
  ClusterTitle,
  ClusterContent,
  ClusterNumber,
  ClusterSub,
} from './userbase.styles'

// ====================== DATS

import { type ClusterRecord } from 'com.batch.redux/_records'

type ClusterProps = {
  cluster: ClusterRecord,
  sub?: ClusterRecord,
  tokensOnly?: boolean,
  mode: 'alive' | 'all',
  algo: 'tokenRate' | 'optinRate',
  style?: any,
  ...
}

const ClusterBlockRaw = ({ cluster, sub, tokensOnly, mode, algo, style }: ClusterProps) => {
  const currentSub: false | ClusterRecord = sub ? sub : false
  const theme = useTheme()

  const empty = theme.isEmpty
  const hideContent = theme.isEmpty || theme.isLoading

  return (
    <Cluster isEmpty={empty} color={cluster.color} style={style}>
      <ClusterSection>
        <ClusterTitle>
          {!hideContent ? (
            <React.Fragment>
              {cluster.name}{' '}
              {cluster.desc && <Hint style={{ margin: '0 -12px 0 -5px' }}>{cluster.desc}</Hint>}
            </React.Fragment>
          ) : (
            <EmptyField />
          )}
        </ClusterTitle>

        <ClusterContent>
          <ClusterNumber>
            {!hideContent ? (
              <React.Fragment>
                {tokensOnly
                  ? kformat(cluster.get(mode).tokens)
                  : kformat(cluster.get(mode).installs)}
                {tokensOnly && <span>tokens</span>}
              </React.Fragment>
            ) : (
              <EmptyField
                _width={React.useMemo(() => randomSize(38, 60), [randomSize])}
                _height={21}
              />
            )}
          </ClusterNumber>

          {!tokensOnly && (
            <Tooltip
              tooltip={`${kformat(cluster.get(mode).tokens || '')} tokens (${percentage(
                cluster.get(mode).get(algo)
              )})`}
              placement="bottom"
            >
              <ClusterSub>
                {!hideContent ? (
                  <React.Fragment>{kformat(cluster.get(mode).notifsOn)} opt-ins</React.Fragment>
                ) : (
                  <EmptyField _width={React.useMemo(() => randomSize(38, 60), [randomSize])} />
                )}
              </ClusterSub>
            </Tooltip>
          )}
        </ClusterContent>
      </ClusterSection>

      {!!currentSub && (
        <ClusterSection>
          <ClusterTitle>
            {!hideContent ? (
              <React.Fragment>
                <Icon
                  icon="children"
                  style={{ margin: '0 6px 0 -10px', color: colors.textLight }}
                />{' '}
                {currentSub.name}
              </React.Fragment>
            ) : (
              <EmptyField />
            )}
          </ClusterTitle>
          <ClusterContent>
            <ClusterNumber>
              {!hideContent ? (
                <React.Fragment>{kformat(currentSub.get(mode).installs)}</React.Fragment>
              ) : (
                <EmptyField
                  _width={React.useMemo(() => randomSize(38, 60), [randomSize])}
                  _height={21}
                />
              )}
            </ClusterNumber>

            <Tooltip
              tooltip={`${currentSub.get(mode).tokens || '—'} tokens (${
                percentage(currentSub.get(mode).get(algo)) || '—'
              })`}
              placement="bottom"
            >
              <ClusterSub>
                {!hideContent ? (
                  <React.Fragment>{kformat(currentSub.get(mode).notifsOn)} opt-ins</React.Fragment>
                ) : (
                  <EmptyField _width={React.useMemo(() => randomSize(38, 60), [randomSize])} />
                )}
              </ClusterSub>
            </Tooltip>
          </ClusterContent>
        </ClusterSection>
      )}
    </Cluster>
  )
}

export const ClusterBlock: React.AbstractComponent<ClusterProps> =
  React.memo<ClusterProps>(ClusterBlockRaw)
