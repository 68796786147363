// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'
import { updatePushSettings } from 'com.batch.redux/content'

import { type ThemeProps } from 'com.batch/message-builder/ui/components/common'
import { InfoPanelContent } from 'com.batch/message-builder/ui/components/info-panel.styles'
import { updatePushMessageContent } from 'com.batch/push/usecases/update-push-content'

type Props = {
  field: 'pushPicture' | 'androidIcon' | 'image' | 'icon',
  messageId: string,
  clearEditing?: () => void,
}

export const PushMediaInfoPanel = ({ field, messageId, clearEditing }: Props): React.Node => {
  const lang = useSelector(activeLanguageValueSelector)
  const dispatch = useDispatch()
  const { openReplaceMedia } = useTheme<ThemeProps>()

  const updatePushSettingsBound = React.useCallback(
    (field, value) => dispatch(updatePushSettings(field, value)),
    [dispatch]
  )
  const onClose = React.useCallback(
    (evt: SyntheticMouseEvent<HTMLButtonElement>) => {
      evt.preventDefault()
      evt.stopPropagation()
      clearEditing && clearEditing()
    },
    [clearEditing]
  )
  const removeMedia = React.useCallback(() => {
    clearEditing && clearEditing()
    if (field === 'pushPicture') {
      dispatch(
        updatePushMessageContent({
          messageId,
          lang,
          field: 'pushPicture',
          parent: 'content',
          value: '',
        })
      )
    } else if (field === 'androidIcon') {
      dispatch(
        updatePushMessageContent({
          messageId,
          lang,
          field: 'androidIcon',
          parent: 'androidContent',
          value: '',
        })
      )
    } else {
      updatePushSettingsBound(field === 'image' ? 'attachmentUrl' : 'iconUrl', '')
    }
  }, [clearEditing, field, dispatch, messageId, lang, updatePushSettingsBound])

  const onRemove = React.useCallback(
    (evt: SyntheticMouseEvent<HTMLButtonElement>) => {
      onClose(evt)
      removeMedia()
    },
    [onClose, removeMedia]
  )

  const getFieldName = React.useCallback(() => (field.includes('icon') ? 'icon' : 'image'), [field])

  return (
    <InfoPanelContent>
      <Button kind="discreet" addOn="prefix" onClick={openReplaceMedia}>
        <Icon icon="image" />
        Replace {getFieldName()}
      </Button>
      <Button kind="discreet" intent="danger" addOn="prefix" onClick={onRemove}>
        <Icon icon="delete" />
        Remove
      </Button>
    </InfoPanelContent>
  )
}
