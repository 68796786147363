// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { getPushContentError } from './push.selector.helper'

import {
  translationsWithDefaultSelector,
  type MessageIdGetterSelector,
  getMessageKey,
} from 'com.batch/message/store/message.selector'
import { type State } from 'com.batch.redux/_records'
import { activeLanguageIdSelector } from 'com.batch.redux/campaign.selector'

import {
  PushContentFactory,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'

export const getPushContentSelector =
  (state: State): (string => Map<string, PushContentRecord>) =>
  messageId =>
    state.message.push.get(messageId, Immutable.Map())

export const getPushContentEmptySelector =
  (state: State): (string => boolean) =>
  messageId =>
    state.message.push.get(messageId, Immutable.Map()).isEmpty()

export const getPushContentForActiveLanguageSelector: MessageIdGetterSelector<PushContentRecord> =
  createSelector(
    getPushContentSelector,
    activeLanguageIdSelector,
    (getData, langId) => messageId => {
      const data = getData(messageId)
      return data.get(langId ?? 'default', PushContentFactory())
    }
  )

type getErrorsForMessage = (messageId: string) => Map<string, Array<string>>
export const getPushErrorsSelector: State => getErrorsForMessage = createSelector(
  translationsWithDefaultSelector,
  getPushContentSelector,
  (langsWithDefault, getContent) => messageId => {
    const content = getContent(messageId)
    const errors: Array<[string, Array<string>]> = langsWithDefault
      .map(lang => {
        const contentForLang = content.get(lang.value, PushContentFactory())
        let localErrors = getPushContentError(contentForLang)
        return [getMessageKey(lang), localErrors]
      })
      .filter(err => err[1].length > 0)
      .toArray()
    return Immutable.Map(errors)
  }
)
