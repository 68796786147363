// @flow

// ====================== COMPONENT
import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { Button } from 'components/common/button'
import { IconContainer } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

const LEVEL_COLORS = [schemes.grayscale['50'], schemes.blue['60'], schemes.orange['70']]
type ThemeProps = { kind: 'capture' | 'filter', isEventFilter: boolean, ... }
export const LogicalToggleContainer: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  width: 45px;
  height: 18px;
  position: absolute;
  left: -2px;
  margin-top: -9px;

  .styled-value {
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    line-height: 10px;
    flex-grow: 1;
  }
  ${IconContainer} {
    display: block;
  }
  .styled-btn {
    cursor: pointer;
    overflow: hidden;
    border: none;
    background: ${colors.fillNeutral};
    text-transform: uppercase;
    border-radius: 0 5px 5px 0;
    width: 45px;
    height: 18px;
    letter-spacing: -0.01em;
    display: flex;
    padding: 0 2px 0 5px;
    align-items: center;
    &:hover {
      background: ${colors.fillNeutralHover};
    }
  }
`
type LogicalContainerProps = {
  level: number,
  isNot?: boolean,
  isEmpty?: boolean,
  disabledMode?: boolean,
  withSeparator?: boolean,
  ...
}
type LogicalContainerPropsWithTheme = PropsWithTheme<LogicalContainerProps, ThemeProps>
export const LogicalContainer: StyledComponent<LogicalContainerProps, ThemeProps, HTMLElement> =
  styled.section`
    ${(props: LogicalContainerPropsWithTheme) =>
      props.theme.isEventFilter &&
      css`
        .styled-scrollable {
          max-height: min(80vh, 600px);
          overflow-y: auto;
        }
      `}
    .styled-descendants {
      position: relative;
      &.styled-scroll {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
      }
    }
    .styled-empty {
      height: 68px;
      ${(props: LogicalContainerPropsWithTheme) =>
        props.level === 1 &&
        css`
          padding: 0 0px;
          margin-top: -10px;
        `}
      text-align: center;
      font-weight: 500;
      color: ${(p: LogicalContainerPropsWithTheme) =>
        p.disabledMode ? colors.textDisabled : colors.textNeutral};
      display: flex;
      align-items: center;
      justify-content: start;
    }
    .styled-toolbar {
      padding: 12px 10px;
      background: ${colors.fillBackground};
      border-top: 1px solid ${colors.stroke};
    }
    margin-left: ${(props: LogicalContainerPropsWithTheme) =>
      props.level === 1 ? '-1px' : props.isNot ? 0 : '45px'};
    ${(props: LogicalContainerPropsWithTheme) =>
      props.withSeparator &&
      css`
        border-bottom: 1px dashed ${colors.stroke};
        border-bottom: 1px dashed crimson;
      `}
    ${(props: LogicalContainerPropsWithTheme) =>
      !props.theme.isEventFilter &&
      !props.isNot &&
      css`
        border-left: 2px solid
          ${props.isEmpty ? 'transparent' : LEVEL_COLORS[(props.level - 1) % 3]};
        ${LogicalToggleContainer} {
          .styled-btn {
            background: ${(props: any) => LEVEL_COLORS[(props.level - 1) % 3]};
          }
        }
      `}
  `

export const OperatorIcon: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  position: relative;
  width: 19px;
  height: 20px;
  margin-right: 7px;
  background-color: ${colors.fillDisabled};
  border-radius: 3px;

  .OperatorIcon--icon {
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 3px;
    color: ${colors.textLight};
  }
  .OperatorIcon--strikethrough {
    position: absolute;
    z-index: 3;
  }
`

export const ConditionContainer: StyledComponent<
  { isSub?: boolean, withSeparator?: boolean, ... },
  { ... },
  HTMLDivElement,
> = styled.div`
  padding: 16px 14px;
  padding-right: 0;
  display: flex;
  ${(props: PropsWithTheme<{ isSub?: boolean, withSeparator?: boolean, ... }, { ... }>) =>
    props.withSeparator &&
    css`
      border-bottom: 1px dashed ${colors.stroke};
    `}
  ${(props: PropsWithTheme<{ isSub?: boolean, withSeparator?: boolean, ... }, { ... }>) =>
    props.isSub &&
    css`
      .styled-icon {
        color: ${colors.stroke};
        flex-basis: 30px;
      }
      background: rgba(50, 54, 57, 0.05);
      border-radius: 6px;
      margin: 0 14px 14px 14px;
      align-items: center;
      border-top: none;
      padding: 4px 20px 4px 16px;
      height: 56px;
    `}
  &:first-child {
    border-top: none;
  }
`
export const ConditionPart: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  margin-right: 10px;
`
export const ConditionPartAttribute: StyledComponent<{ ... }, { ... }, *> = styled(ConditionPart)`
  flex: 0 0 200px;
`
export const ConditionPartAction: StyledComponent<{ ... }, { ... }, *> = styled(ConditionPart)`
  margin: 0 10px;
`
export const ConditionPartSpacer: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  flex: 1 0 0px;
`

export const LogicalToggleItem: StyledComponent<{ ... }, { ... }, *> = styled(Button)`
  display: flex;
  flex-direction: column;

  padding-left: 10px;
  padding-right: 10px;

  // &:hover {
  //   color: ${colors.textLight};
  // }
`

export const AttributePicker: StyledComponent<
  { isSelected: boolean, ... },
  { ... },
  HTMLLabelElement,
> = styled.label`
  display: inline-flex;
  align-items: center;
  height: 36px;
  margin: 5px;
  padding: 0 13px 0 9px;
  font-weight: 500;
  color: ${(p: PropsWithTheme<{ isSelected: boolean, ... }, { ... }>) =>
    p.isSelected ? `${colors.text} !important` : `${colors.textLight}`};
  letter-spacing: -0.01em;
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: ${(p: PropsWithTheme<{ isSelected: boolean, ... }, { ... }>) =>
    p.isSelected ? `${colors.opacifyAction} !important` : `${colors.fillBackground}`};
  box-shadow: ${(p: PropsWithTheme<{ isSelected: boolean, ... }, { ... }>) =>
    p.isSelected
      ? `0 0 0 2px ${colors.strokeAction}`
      : `0 0 0 1px ${colors.stroke}, 0 1px 4px rgba(0, 0, 0, 0.02)`};

  cursor: pointer;
  overflow: hidden;
  user-select: none;

  label {
    margin-right: 8px;
  }
  > i {
    margin-left: 14px;
    padding-bottom: 2px;
    color: ${colors.textNeutral};
  }

  &:hover,
  &:focus {
    color: ${colors.textNeutral};
    background-color: ${colors.opacifyHover};
  }
`
