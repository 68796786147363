/* eslint-disable react/jsx-no-bind */
// @flow

import { color } from 'd3-color'
import * as React from 'react'

import Donut from 'components/charts/donut'
import { Box, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Wrapper } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { kformat } from 'com.batch.common/utils'

import {
  Container,
  Tabs,
  Tab,
  Content,
  ContentHeader,
  ContentBody,
  ContentDetail,
  TooltipDetail,
  Chart,
} from './delivery.styles'
import Metric from './metric'

import { type deliveryData } from '../review.data.selectors'
import { type CampaignRecord } from 'com.batch.redux/_records'

type DeliveryProps = {
  data: deliveryData,
  platform: string,
  campaign: CampaignRecord,
  hasDevApiKeyFeature: boolean,
  ...
}
type DeliveryState = {
  activeTab: number,
  activeSliceId: number,
  ...
}

const MIN_CHART_RATIO = 0.008

class Delivery extends React.PureComponent<DeliveryProps, DeliveryState> {
  constructor(props: DeliveryProps) {
    super(props)
    this.state = {
      activeSliceId: 0,
      activeTab: 0,
    }
  }
  setActiveSlice: () => void = () => {}

  render(): React.Node {
    const { data, campaign, hasDevApiKeyFeature } = this.props
    const { activeTab } = this.state
    let errorLink = ''
    switch (this.props.platform) {
      case 'android':
        errorLink = 'https://doc.batch.com/android/troubleshooting/#fcminternal-errors'
        break
      case 'ios':
        errorLink = 'https://doc.batch.com/ios/troubleshooting.html#_apns-internal-errors'
        break
    }
    let drawSlices: Array<{
      id: number,
      color: string,
      label: string,
      tooltip: string | null,
      value: number,
      ...
    }> = []
    let lastId = 0
    data.categories.forEach(s => {
      s.metrics.forEach((m, i) => {
        if (m.label !== 'DEV API KEY' || hasDevApiKeyFeature || m.value > 0)
          drawSlices.push({
            id: lastId,
            color: m.color || color(s.color).darker(i * 0.2),
            label: m.label,
            tooltip: m.tooltip ?? '',
            value: m.value,
          })

        lastId++
      })
    })

    const totalChart = drawSlices.reduce((prv, cur) => prv + cur.value, 0)
    drawSlices = drawSlices.filter(s => s.value > 0)

    return (
      <Box style={{ overflow: 'hidden' }}>
        <Wrapper
          isLoading={data.loading}
          isEmpty={data.empty}
          isOverlayShown={data.empty && !data.loading}
          overlayProps={{
            status: 'empty',
            title: 'No data for this campaign.',
            description:
              campaign.state === 'COMPLETED'
                ? 'It might not have targeted anyone.'
                : campaign.state === 'RUNNING'
                  ? 'It is not completed yet.'
                  : campaign.state === 'STOPPED'
                    ? 'It is paused.'
                    : campaign.state === 'DRAFT'
                      ? 'It is still a draft.'
                      : '',
          }}
        >
          <BoxHeader>
            <HeaderBoxTitle title="Delivery Report" />
          </BoxHeader>
          <Container>
            <Tabs>
              {data.categories.map((c, i) => (
                <Tab
                  active={activeTab === i}
                  onClick={() => this.setState({ activeTab: i })}
                  key={i}
                >
                  <Icon icon={c.icon} size={18} color={c.color} />
                  <Metric value={c.value}>{c.label}</Metric>
                </Tab>
              ))}
            </Tabs>
            <Content>
              <ContentHeader>
                <h4>{data.categories[activeTab].title}</h4>
                <p>{data.categories[activeTab].description(this.props.platform)}</p>
              </ContentHeader>
              <ContentBody>
                {data.categories[activeTab].metrics.map((m, j) =>
                  m.label !== 'DEV API KEY' || hasDevApiKeyFeature || m.value > 0 ? (
                    <ContentDetail
                      key={j}
                      index={j}
                      color={m.color || data.categories[activeTab].color}
                      empty={m.value === 0}
                    >
                      <Metric value={m.value} tooltip={m.tooltip} small key={j}>
                        {m.label}
                      </Metric>
                      {m.details && m.details.length > 0 && (
                        <Tooltip
                          placement="right"
                          toggle={m.label === 'ERRORS' || m.label === 'SKIPPED'}
                          noPadding
                          tooltip={
                            <React.Fragment>
                              {m.details.map((d, k) => (
                                <TooltipDetail key={k}>
                                  <strong>{kformat(d.value)}</strong>
                                  <label>{d.label}</label>
                                </TooltipDetail>
                              ))}
                              {!!errorLink && (
                                <TooltipDetail>
                                  <a
                                    href={
                                      m.label === 'ERRORS'
                                        ? errorLink
                                        : 'https://doc.batch.com/dashboard/settings/app-settings#frequency-capping-management'
                                    }
                                    target="_blank"
                                  >
                                    Learn more <Icon icon="arrow-long-right" size={12} />
                                  </a>
                                </TooltipDetail>
                              )}
                            </React.Fragment>
                          }
                        >
                          <Icon icon="details" style={{ margin: '0 8px 0 0' }} />
                        </Tooltip>
                      )}
                    </ContentDetail>
                  ) : null
                )}
              </ContentBody>
            </Content>
            <Chart>
              <Donut
                activeSliceId={this.state.activeSliceId}
                tooltips={drawSlices}
                values={
                  drawSlices.length > 0
                    ? drawSlices.map(s =>
                        s.value < totalChart * MIN_CHART_RATIO
                          ? totalChart * MIN_CHART_RATIO
                          : s.value
                      )
                    : [100, 15, 5]
                }
                colors={
                  drawSlices.length > 0 ? drawSlices.map(s => s.color) : ['#CCC', '#CCC', '#CCC']
                }
                ids={drawSlices.length > 0 ? drawSlices.map(s => s.id) : [0, 1, 2]}
                size={14}
                maxWidth={300}
                overOffset={0}
                duration={800}
                setActiveSlice={this.setActiveSlice}
              />
              <Metric value={data.total}>Total</Metric>
            </Chart>
          </Container>
        </Wrapper>
      </Box>
    )
  }
}

export default Delivery
