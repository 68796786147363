// / @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { visibleEventsSelector } from 'com.batch.redux/attribute.selector'

import { getNodeId } from 'com.batch/orchestration-journey/models/id-generator'
import {
  formatJourneyQuery,
  parseTriggerQuery,
} from 'com.batch/orchestration-journey/models/journey-trigger.helper'
import {
  EventNextFactory,
  EventWithOptionalQueryFactory,
  FinalNodeFactory,
} from 'com.batch/orchestration-journey/models/journey.records'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'

export type TimerSettingsExitEventHooks = {
  exitEvents: Immutable.List<{ eventId: string, query: ?{ ... }, index: number }>,
  onExitEventChange: (exitEventEnabled: boolean) => void,
  createOnQueryChange: (eventId: string, index: number) => (query: ?{ ... }) => void,
  createOnEventChange: (index: number) => (evt: string) => void,
  createOnDeleteExitEvent: (index: number) => () => void,
  onAddExitEvent: () => void,
}

export const useTimerSettingsExitEventHooks = (nodeId: string): TimerSettingsExitEventHooks => {
  const { nodesMap } = useSelector(journeyTreeSelector)
  const events = useSelector(visibleEventsSelector)

  const dispatch = useDispatch()
  const node = React.useMemo(() => {
    const fetchedNode = nodesMap.get(nodeId)
    return fetchedNode?.type === 'TIMER' ? fetchedNode : null
  }, [nodesMap, nodeId])

  const exitEvents = React.useMemo(
    () =>
      node?.onEvents.reduce(
        (eventLists, onEvents) =>
          eventLists.merge(
            onEvents.triggers.reduce(
              (acc, event, index) =>
                acc.push({
                  eventId: event.name,
                  query: formatJourneyQuery(event.query),
                  index,
                }),
              new Immutable.List()
            )
          ),
        new Immutable.List()
      ) ?? new Immutable.List(),
    [node]
  )

  const onExitEventChange = React.useCallback(
    exitEventEnabled => {
      if (node) {
        if (exitEventEnabled) {
          const firstEvent = events.filter(ev => !ev.id.startsWith('be.')).first()
          const finalNode = FinalNodeFactory({ id: getNodeId('FINAL') })
          dispatch(updateNode(finalNode, true))
          dispatch(
            updateNode(
              node.set(
                'onEvents',
                new Immutable.List().push(
                  EventNextFactory({
                    nextNodeId: finalNode.id,
                    triggers: new Immutable.List().push(
                      EventWithOptionalQueryFactory({
                        name: firstEvent.id,
                      })
                    ),
                  })
                )
              )
            )
          )
        } else {
          dispatch(updateNode(node.set('onEvents', new Immutable.List().push())))
        }
      }
    },
    [dispatch, events, node]
  )

  const createOnQueryChange = React.useCallback(
    (eventId: string, index: number) => query => {
      if (node)
        dispatch(
          updateNode(
            node.setIn(
              ['onEvents', 0, 'triggers', index],
              EventWithOptionalQueryFactory({
                name: eventId,
                query: parseTriggerQuery(query),
              })
            )
          )
        )
    },
    [dispatch, node]
  )

  const createOnEventChange = React.useCallback(
    (index: number) => evt => {
      if (node)
        dispatch(
          updateNode(
            node.setIn(
              ['onEvents', 0, 'triggers', index],
              EventWithOptionalQueryFactory({
                name: evt,
              })
            )
          )
        )
    },
    [dispatch, node]
  )
  const createOnDeleteExitEvent = React.useCallback(
    (index: number) => () => {
      if (node) dispatch(updateNode(node.deleteIn(['onEvents', 0, 'triggers', index])))
    },
    [dispatch, node]
  )

  const onAddExitEvent = React.useCallback(() => {
    const firstEvent = events.filter(ev => !ev.id.startsWith('be.')).first()
    const triggers = node?.onEvents.get(0)?.triggers
    if (triggers && node)
      dispatch(
        updateNode(
          node.setIn(
            ['onEvents', 0, 'triggers'],
            triggers.push(
              EventWithOptionalQueryFactory({
                name: firstEvent.id,
              })
            )
          )
        )
      )
  }, [dispatch, node, events])

  return {
    exitEvents,
    onExitEventChange,
    createOnQueryChange,
    createOnEventChange,
    createOnDeleteExitEvent,
    onAddExitEvent,
  }
}
