// @flow
import { createSelector } from 'reselect'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import { type ProfilebaseType } from 'com.batch/profilebase/infra/types/profilebase.type'
import { STATUS } from 'constants/common'

type extract<T> = State => T

const profilebaseSelector = (state: State): ProfilebaseType => {
  return state.profilebase
}

const checkingProfilebaseStates = (
  profilebase: ProfilebaseType,
  wantedState: fetchingState
): boolean =>
  profilebase.profilesDistribution.loadingState === wantedState ||
  profilebase.emailSubDistribution.loadingState === wantedState ||
  profilebase.emailReachability.loadingState === wantedState ||
  profilebase.smsSubDistribution.loadingState === wantedState ||
  profilebase.smsReachability.loadingState === wantedState ||
  profilebase.pushSubDistribution.loadingState === wantedState ||
  profilebase.pushReachability.loadingState === wantedState

export const profilebaseIsLoadedSelector: extract<boolean> = createSelector(
  profilebaseSelector,
  profilebase =>
    profilebase.profilesDistribution.loadingState === STATUS.LOADED &&
    profilebase.emailSubDistribution.loadingState === STATUS.LOADED &&
    profilebase.emailReachability.loadingState === STATUS.LOADED &&
    profilebase.smsSubDistribution.loadingState === STATUS.LOADED &&
    profilebase.smsReachability.loadingState === STATUS.LOADED &&
    profilebase.pushSubDistribution.loadingState === STATUS.LOADED &&
    profilebase.pushReachability.loadingState === STATUS.LOADED
)

export const profilebaseIsLoadingSelector: extract<boolean> = createSelector(
  profilebaseSelector,
  profilebase => checkingProfilebaseStates(profilebase, STATUS.LOADING)
)

export const profilebaseHasErrorSelector: extract<boolean> = createSelector(
  profilebaseSelector,
  profilebase => checkingProfilebaseStates(profilebase, STATUS.ERROR)
)
