// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { useIntercomVerticalPadding } from 'components/_hooks/use-intercom-vertical-padding'
import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import {
  generateOrchestrationListUrl,
  generateOrchestrationUrl,
} from 'com.batch.common/router/url-generator-helper'

import { FooterOrchestrationContainer } from './footer-orchestration.styles'

import {
  orchestrationDraftSelector,
  orchestrationErrorsSelector,
  orchestrationStateSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import {
  estimateProfileGetterSelector,
  subscriptionStatusSelector,
} from 'com.batch.redux/target/target.selector'

import { persistOrchestration } from 'com.batch/orchestration/usecases/persist-orchestration'
import { getConfirmWordings } from 'com.batch/orchestration-list/ui/components/confirm-wording'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'

type FooterOrchestrationProps = {
  isFixed: boolean,
  channel?: ChannelUntilCleanup,
}
export const FooterOrchestration = ({ isFixed, channel }: FooterOrchestrationProps): React.Node => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const estimateGetter = useSelector(estimateProfileGetterSelector)
  const estimate = React.useMemo(() => estimateGetter('default'), [estimateGetter])
  const orchestrationState = useSelector(orchestrationStateSelector)
  const errors = useSelector(orchestrationErrorsSelector)
  const hasUnsavedModifications = React.useMemo(
    () => orchestrationState.version !== orchestrationState.savedVersion,
    [orchestrationState.savedVersion, orchestrationState.version]
  )
  useLeaveConfirmation({ isPristine: !hasUnsavedModifications })
  const targetingMode = useSelector(subscriptionStatusSelector)
  const draftErrors = useSelector(orchestrationDraftSelector)
  const project = useSelector(currentProjectSelector)
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const orchestrationWord = React.useMemo(() => {
    return orchestrationState.campaign.sendType === 'recurring' ||
      orchestrationState.campaign.sendType === 'trigger'
      ? 'automation'
      : 'campaign'
  }, [orchestrationState.campaign.sendType])
  useIntercomVerticalPadding(72)
  const createOnClickSave = React.useCallback(
    (forceRunning: boolean) => () => {
      const showConfirm =
        forceRunning || (Boolean(orchestrationState.id) && orchestrationState.state === 'RUNNING')
      const redirectAfterSave =
        orchestrationState.state !== 'DRAFT' ||
        forceRunning ||
        orchestrationState.campaign.sendType !== 'trigger'
      const onResolve = (token: string) => {
        if (redirectAfterSave) {
          const url = generateOrchestrationListUrl({
            companyId: project.companyId,
            projectId: project.id,
            channel: 'email',
            scheduling: orchestrationWord === 'campaign' ? 'campaigns' : 'automations',
          })
          navigate(url)
        } else {
          const url = generateOrchestrationUrl({
            token,
            page: 'form',
            companyId: project.companyId,
            projectId: project.id,
            channel: channel ?? null,
            sendType: orchestrationState.campaign.sendType,
          })
          if (!orchestrationState.id) navigate(url, { replace: true })
        }
      }
      const onReject = () => {}

      if (!showConfirm) {
        dispatch(
          persistOrchestration({
            incomplete: errors.size > 0,
            forceRunning,
          })
        ).then(onResolve, onReject)
      } else {
        const wordings = getConfirmWordings({
          orchestration: orchestrationState,
          estimate,
          isTransactional: targetingMode === 'fullbase',
          channel: orchestrationState.campaign.sendType === 'trigger' ? null : channel,
        })
        confirm({
          ...wordings.run,
        }).then(() => {
          dispatch(
            persistOrchestration({
              incomplete: false,
              forceRunning,
            })
          ).then(onResolve, onReject)
        }, onReject)
      }
    },
    [
      orchestrationState,
      project.companyId,
      project.id,
      orchestrationWord,
      dispatch,
      errors.size,
      estimate,
      targetingMode,
      navigate,
      channel,
    ]
  )
  const tooltipProps = React.useCallback(
    (errors, minWidth = 300) => {
      return {
        minWidth,
        isTooltipEmpty: errors.size === 0,
        placement: 'top',
        offset: [0, 20],
        tooltip: (
          <div style={{ textAlign: 'left' }}>
            {errors.size === 1 ? (
              errors.first()
            ) : (
              <React.Fragment>
                Your {orchestrationWord} has errors:
                <ul style={{ marginTop: 2 }}>
                  {errors.map((err, index) => (
                    <li key={index} style={{ textAlign: 'left', marginLeft: 18 }}>
                      {err}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )}
          </div>
        ),
      }
    },
    [orchestrationWord]
  )

  return (
    orchestrationState.state !== 'COMPLETED' &&
    userHasWritePermission && (
      <FooterOrchestrationContainer $isFixed={isFixed}>
        {orchestrationState.state !== 'RUNNING' && (
          <Tooltip {...tooltipProps(draftErrors, 150)}>
            <span>
              <Button
                intent="action"
                kind={orchestrationState.state === 'RUNNING' ? 'primary' : 'secondary'}
                isLoading={orchestrationState.savingState === 'LOADING'}
                onClick={createOnClickSave(false)}
                data-testid="btn-save-keep-state"
                style={{ height: 36, width: 90 }}
                disabled={draftErrors.size > 0 || !hasUnsavedModifications}
                addOn={
                  Boolean(orchestrationState.id) && !hasUnsavedModifications ? 'prefix' : undefined
                }
              >
                {Boolean(orchestrationState.id) && !hasUnsavedModifications && (
                  <Icon icon="check" />
                )}
                {Boolean(orchestrationState.id) && !hasUnsavedModifications
                  ? 'Saved'
                  : orchestrationState.id
                    ? 'Save'
                    : 'Save draft'}
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip {...tooltipProps(errors)}>
          <span>
            <Button
              intent="action"
              isLoading={orchestrationState.savingState === 'LOADING'}
              data-testid="btn-save-force-state-running"
              kind={'primary'}
              onClick={createOnClickSave(true)}
              style={{ height: 36 }}
              disabled={
                errors.size > 0 ||
                (!hasUnsavedModifications && orchestrationState.state === 'RUNNING')
              }
              addOn="suffix"
            >
              {orchestrationState.state === 'RUNNING' ? 'Update' : 'Run'}

              <Icon icon="send" />
            </Button>
          </span>
        </Tooltip>
      </FooterOrchestrationContainer>
    )
  )
}
