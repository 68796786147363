// @flow

import Immutable, { type Map } from 'immutable'

import { type ReduxAction } from 'com.batch.redux/_records'

import {
  ProfileAudienceFactory,
  type ProfileAudienceRecord,
} from 'com.batch/audience/model/audience-profile.records'

type FetchDeletedAudiencesAction = ReduxAction<
  'SET_DELETED_AUDIENCES',
  Map<string, ProfileAudienceRecord>
>

export type FetchDeletedAudiencesActions = FetchDeletedAudiencesAction

export const setDeletedAudiences = (
  audiencesNames: Array<string>
): ReduxAction<'SET_DELETED_AUDIENCES', Map<string, ProfileAudienceRecord>> => {
  const deletedAudiences = audiencesNames.map(name => {
    return ProfileAudienceFactory({
      name,
      displayName: `DELETED ${name.toLowerCase()}`,
    })
  })
  const deletedAudiencesMap = Immutable.Map(
    deletedAudiences.map(audience => [audience.name, audience])
  )
  return {
    type: 'SET_DELETED_AUDIENCES',
    payload: deletedAudiencesMap,
  }
}
