// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import { colors } from 'components/styled/tokens'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

type OptionStatusContainerProps = {
  $size?: 'short' | 'expanded',
  status?: SubscriptionStatus,
  ...
}
export const OptinStatusContainer: StyledComponent<OptionStatusContainerProps, *, HTMLDivElement> =
  styled.div`
    position: absolute;
    font-weight: 500;
    line-height: 20px;
    border-radius: ${(props: OptionStatusContainerProps) =>
      props.$size === 'expanded' ? 12 : 6}px;

    & > div.message {
      font-weight: 400;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    & ${EmptyField}:first-child {
      margin-bottom: 8px;
    }

    ${(props: OptionStatusContainerProps) =>
      props.$size === 'short'
        ? css`
            top: 40px;
            left: 22px;
            width: 96px;
            padding: 3px 8px;
            font-size: 12px;

            & > div:first-of-type {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            }
          `
        : css`
            top: 94px;
            left: 48px;
            width: 224px;
            padding: 10px 12px;
            font-size: 14px;

            span {
              margin-right: 6px;
            }
          `}

    ${(props: OptionStatusContainerProps) =>
      props.status === 'SUBSCRIBED'
        ? css`
            color: ${colors.textSuccess};
            background-color: ${colors.opacifySuccess};

            & > div.message {
              color: ${colors.textLight};
            }
          `
        : css`
            color: ${colors.textLight};
            border: 1px dashed ${colors.stroke};
          `}
  `

type ProfileMockupOptinContainerProps = {
  $size: 'short' | 'expanded',
  ...
}
export const ProfileMockupOptinContainer: StyledComponent<ProfileMockupOptinContainerProps, *, *> =
  styled.div`
    position: relative;
    width: ${(props: ProfileMockupOptinContainerProps) => (props.$size === 'short' ? 140 : 320)}px;

    & > img {
      width: 100%;
    }
  `
