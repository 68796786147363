// @flow
import setTimeout from 'core-js/features/set-timeout'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { BoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { LinkArrow } from 'components/styled/text'
import { Separator } from 'components/styled/utils'

import { type InstallRecord } from 'com.batch/profile/models/profile.records'
import {
  PlatformModalBox,
  PlatformModalBoxHeader,
  PlatformModalBody,
  ButtonReload,
} from 'com.batch/profile/ui/components/profile-hero/platform-modal/platform-modal.styles'
import { ProfileCopyableId } from 'com.batch/profile/ui/components/profile-hero/profile-copyable-id/profile-copyable-id'
import { ProfileMockupOptin } from 'com.batch/profile/ui/components/profile-hero/profile-mockup-optin/profile-mockup-optin'
import { refreshPlatform } from 'com.batch/profile/usecases/refresh-platform'

type Props = {
  install: InstallRecord,
  debugHref: string,
  opened: boolean,
  close: () => void,
  ...
}
export const PlatformModal = ({ install, debugHref, opened, close }: Props): React.Node => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const deviceName = React.useMemo(
    () => (install?.marketingName ? install?.marketingName : install.type),
    [install]
  )

  const handleOnClickReload = React.useCallback(() => {
    setIsLoading(true)
    dispatch(refreshPlatform({ installId: install.installID })).finally(() => {
      const loadingTimeout = setTimeout(() => setIsLoading(false), 500)
      return () => clearTimeout(loadingTimeout)
    })
  }, [dispatch, install.installID])

  return (
    <Popin opened={opened} close={close}>
      <PlatformModalBox>
        <PlatformModalBoxHeader>
          <BoxTitle>{deviceName}</BoxTitle>
          <ButtonReload onClick={handleOnClickReload} disabled={isLoading}>
            <Icon icon="reload" />
          </ButtonReload>
          <Separator />
          <Button onClick={close}>
            <Icon icon="close" />
          </Button>
        </PlatformModalBoxHeader>
        <PlatformModalBody>
          <div>
            <ProfileCopyableId label="Installation ID" id={install.installID} isLoading={false} />
            <ProfileCopyableId label="Push token" id={install.pushToken} isLoading={false} />
            <LinkArrow intent="action" target="_blank" href={debugHref}>
              Inspect in app debug
            </LinkArrow>
          </div>
          <ProfileMockupOptin
            size="expanded"
            device={deviceName}
            subscriptionStatus={install.subscriptionStatus}
            notifType={install.notifType}
            isLoading={isLoading}
          />
        </PlatformModalBody>
      </PlatformModalBox>
    </Popin>
  )
}
