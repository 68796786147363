// @flow
// ====================== LIBS

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { useToggle } from 'components/_hooks'
import { BoxSection } from 'components/common/box'
import { BoxBody } from 'components/common/box/box.styles'
import { Popin } from 'components/common/popin/popin'

import { getEmailContentForSelectedLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import {
  activeLanguageValueSelector,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'

import { featureEnabled } from 'com.batch/email/feature-flag'
import { LangPickerConnected } from 'com.batch/email/lang-picker-connected'
import { Message } from 'com.batch/email/message'
import { EmailChoice } from 'com.batch/email/ui/components/email-choice/email-choice'
import { EmailPreview } from 'com.batch/email/ui/components/email-preview/email-preview'
import { PopinSendTestEmail } from 'com.batch/email/ui/components/popins/popin-send-test'
import { CodeEditor } from 'com.batch/email/ui/pages/code-editor'
import { DragAndDropEditor } from 'com.batch/email/ui/pages/drag-and-drop-editor'
import { MessageGrid } from 'com.batch/email/ui/pages/email-block.styles'
import { updateEmailTemplate } from 'com.batch/email/usecases/update-email-template'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'

type EmailBlockProps = {
  campaignFormMode?: boolean,
  messageId: string,
  stepId: ?string,
}
export type MessageField = 'fromEmail' | 'name' | 'replyTo' | 'senderIdentityId'

const ConditionalMessageGrid = ({
  campaignFormMode,
  children,
}: {
  campaignFormMode: boolean,
  children: React.Node,
  ...
}) => (campaignFormMode ? <MessageGrid $verticalMessage>{children}</MessageGrid> : children)

export const EmailBlock: React$AbstractComponent<EmailBlockProps, *> = React.memo(
  ({ campaignFormMode, messageId, stepId }: EmailBlockProps): React.Node => {
    const dispatch = useDispatch()
    const { nodesMap } = useSelector(journeyTreeSelector)
    const pickedLanguages = useSelector(pickedLanguagesSelector)
    const getContent = useSelector(getEmailContentForSelectedLanguageSelector)
    const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
    const lang = useSelector(activeLanguageValueSelector)
    const togglePopinSendTest = useToggle()
    const codeEditorPopinState = useToggle()
    const dragAndDropEditorPopinState = useToggle()
    const dynamicContentPopinState = useToggle()
    const languagePickerState = useToggle(pickedLanguages.size > 0)
    const node = React.useMemo(() => (stepId ? nodesMap.get(stepId) : null), [nodesMap, stepId])

    const updateTemplate = React.useCallback(() => {
      dispatch(updateEmailTemplate({ messageId, lang }))
    }, [dispatch, lang, messageId])

    /**
     * Ouvre en conséquence la modale code ou drag&drop selon le mode d'édition initiale du HTML.
     */
    const editTemplate = React.useCallback(() => {
      if (content?.htmlEditorConfig?.type === 'STRIPO') {
        dragAndDropEditorPopinState.open()
      } else {
        codeEditorPopinState.open()
      }
    }, [codeEditorPopinState, content?.htmlEditorConfig?.type, dragAndDropEditorPopinState])

    React.useEffect(() => {
      if (!campaignFormMode) updateTemplate()
    }, [updateTemplate, campaignFormMode])

    return (
      <React.Fragment>
        <BoxBody
          style={{
            overflow: 'hidden',
            flexGrow: 1,
            ...(campaignFormMode
              ? {}
              : {
                  display: 'flex',
                  flexDirection: 'column',
                }),
            borderRadius: 0,
          }}
        >
          <ConditionalMessageGrid campaignFormMode={campaignFormMode ?? false}>
            <BoxSection
              $padding
              style={{
                borderTop: 'none',
                borderBottom: '2px solid #e2e2e2',
                marginBottom: 0,
              }}
            >
              {languagePickerState.value && featureEnabled('translation') && (
                <LangPickerConnected />
              )}
              <Message
                quietHoursEnabledForNode={node?.type === 'MESSAGE' && node.hasQuietHours}
                campaignFormMode={campaignFormMode}
                messageId={messageId}
              />
            </BoxSection>

            <BoxSection
              style={{
                borderTop: 'none',
                borderBottom: 'none',
                background: '#F8F8F8',
                height: campaignFormMode ? 650 : '100%',
                overflowY: 'auto',
                flexGrow: campaignFormMode ? 1 : 'unset',
              }}
            >
              {content?.templates.html ? (
                <EmailPreview
                  messageId={messageId}
                  showToolbar={true}
                  campaignFormMode={campaignFormMode}
                  editTemplate={editTemplate}
                  sendTestEmailPopinState={togglePopinSendTest}
                  dynamicContentPopinState={dynamicContentPopinState}
                />
              ) : (
                <EmailChoice
                  messageId={messageId}
                  dragAndDropEditorToggleState={dragAndDropEditorPopinState}
                  codeEditorToggleState={codeEditorPopinState}
                  content={content}
                />
              )}
            </BoxSection>
          </ConditionalMessageGrid>
        </BoxBody>
        <Popin
          opened={dragAndDropEditorPopinState.value}
          close={dragAndDropEditorPopinState.close}
          fullscreen={true}
          disableEscapeClose={true}
        >
          <DragAndDropEditor
            messageId={messageId}
            popinState={dragAndDropEditorPopinState}
            sendTestEmailPopinState={togglePopinSendTest}
          />
        </Popin>

        <Popin
          opened={codeEditorPopinState.value}
          close={codeEditorPopinState.close}
          fullscreen={true}
          disableEscapeClose={true}
        >
          <CodeEditor
            messageId={messageId}
            popinState={codeEditorPopinState}
            sendTestEmailPopinState={togglePopinSendTest}
          />
        </Popin>

        <ThemeProvider theme={{ disabledMode: false }}>
          <PopinSendTestEmail togglePopin={togglePopinSendTest} content={content} />

          <PreviewProfilePopin
            togglePopin={dynamicContentPopinState}
            updateTemplate={updateTemplate}
          />
        </ThemeProvider>
      </React.Fragment>
    )
  }
)
