// @flow
import Immutable, { type Map } from 'immutable'

import { type OrchestrationAnalyticsRecord } from './orchestration-analytics.state'

import { type State } from 'com.batch.redux/_records'

import {
  getDailyRange,
  getTemporalityRange,
} from 'com.batch/orchestration-analytics/infra/formats/get-range'
import { type PlotData } from 'com.batch/shared/infra/types/chart-data'

type Extract<T> = State => T

export const orchestrationAnalyticsMapSelector: Extract<
  Map<string, OrchestrationAnalyticsRecord>,
> = state => state.orchestrationAnalytics

export const bounceByDaysRangeSelector = (state: State, token: string): PlotData[] => {
  const bounces =
    state.orchestrationAnalytics.get(token)?.channels.email?.bounces ?? Immutable.Map()
  const orchestration = state.orchestration.campaign
  if (
    orchestration.sendType === 'trigger' ||
    (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'DAILY')
  ) {
    return getDailyRange(bounces, 14)
  }
  if (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'WEEKLY') {
    return getTemporalityRange(bounces, 11, 'week')
  }
  if (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'MONTHLY') {
    return getTemporalityRange(bounces, 11, 'month')
  }
  return []
}
