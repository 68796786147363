// @flow

import { Dayjs } from 'dayjs'
import { type List } from 'immutable'
import * as React from 'react'

import AttributeEditor from 'components/app/custom-data/attribute-editor'
import { Table, TableCellOrder, TableHeader } from 'components/common/table'

import { type AttributeRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

type OwnProps = {
  currentCategory: string,
  editable: boolean,
  page: number,
  nbPerPage: number,
  editingLine: number | null,
  editLine: number => any,
  stopEditLine: () => any,
  user: UserRecord,
}

type DispatchProps = {
  attributes: List<AttributeRecord>,
  save: (a: AttributeRecord) => Promise<{ attribute: AttributeRecord, ... }>,
}

const toLCTrimedString = (s: ?string) => (s ? s.toLowerCase().trim() : '')

type CustomDataListProps = { ...OwnProps, ...DispatchProps }

export const CustomDataList = ({
  currentCategory,
  attributes,
  save,
  editable,
  page,
  nbPerPage,
  editingLine,
  editLine,
  stopEditLine,
  user,
}: CustomDataListProps): React.Node => {
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'dsc'>('asc')
  const [sortField, setSortField] = React.useState<
    'hidden' | 'label' | 'cleanId' | 'type' | 'allowedKeys' | 'lastUpdate',
  >('cleanId')

  const isAttr = currentCategory === 'attribute' || currentCategory === 'user_attribute'
  const isEvent = currentCategory === 'event' || currentCategory === 'user_event'

  const sortedAttributes = React.useMemo(() => {
    const ok = sortDirection === 'asc' ? -1 : 1
    const ko = sortDirection === 'asc' ? 1 : -1

    return attributes.sort((a, b) => {
      switch (sortField) {
        case 'hidden':
          return (a.hidden ? 1 : 0) < (b.hidden ? 1 : 0) ? ok : ko
        case 'lastUpdate':
          const aDay = a.lastUpdate ? a.lastUpdate : new Dayjs().year(1980)
          const bDay = b.lastUpdate ? b.lastUpdate : new Dayjs().year(1980)
          return aDay.isBefore(bDay) ? ok : ko
        case 'allowedKeys':
          return a.allowedKeys.size < b.allowedKeys.size ? ok : ko
        case 'label':
          return toLCTrimedString(a.label) < toLCTrimedString(b.label) ? ok : ko
        case 'cleanId':
          return toLCTrimedString(a.cleanId) < toLCTrimedString(b.cleanId) ? ok : ko
        case 'type':
          return toLCTrimedString(a.type) < toLCTrimedString(b.type) ? ok : ko
      }
    })
  }, [attributes, sortDirection, sortField])

  React.useEffect(() => {
    if (editingLine) stopEditLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const sort = React.useCallback(
    (field: 'hidden' | 'label' | 'cleanId' | 'type' | 'allowedKeys' | 'lastUpdate') => {
      if (field === sortField) {
        setSortDirection(sortDirection => (sortDirection === 'asc' ? 'dsc' : 'asc'))
      } else {
        setSortDirection('asc')
        setSortField(field)
      }
    },
    [sortField]
  )

  return (
    <Table
      template={`minmax(182px, 1fr) minmax(80px, 1fr) ${isAttr ? '138px' : ''} ${
        isEvent ? '138px' : ''
      } 130px 134px`}
    >
      <TableHeader>
        <TableCellOrder
          sort={sortField === 'label' ? sortDirection : undefined}
          onClick={() => sort('label')}
        >
          Display name
        </TableCellOrder>

        <TableCellOrder
          sort={sortField === 'cleanId' ? sortDirection : undefined}
          onClick={() => sort('cleanId')}
        >
          Name
        </TableCellOrder>

        {isAttr && (
          <TableCellOrder
            sort={sortField === 'type' ? sortDirection : undefined}
            onClick={() => sort('type')}
          >
            Type
          </TableCellOrder>
        )}

        {isEvent && (
          <TableCellOrder
            sort={sortField === 'allowedKeys' ? sortDirection : undefined}
            onClick={() => sort('allowedKeys')}
          >
            Properties
          </TableCellOrder>
        )}

        <TableCellOrder
          sort={sortField === 'lastUpdate' ? sortDirection : undefined}
          onClick={() => sort('lastUpdate')}
        >
          Last receipt
        </TableCellOrder>

        <div />
      </TableHeader>

      {sortedAttributes.slice((page - 1) * nbPerPage, page * nbPerPage).map((attr, indice) => {
        let line = indice + 1

        return (
          <AttributeEditor
            key={attr.hashCode()}
            attribute={attr}
            isEditing={editingLine === line && editable}
            editLine={() => editLine(line)}
            stopEditLine={stopEditLine}
            editable={editable}
            save={save}
            isAttr={isAttr}
            isEvent={isEvent}
            user={user}
          />
        )
      })}
    </Table>
  )
}
