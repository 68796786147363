// @flow

import * as React from 'react'
import { createRoot } from 'react-dom/client'

// eslint-disable-next-line import/no-cycle
import { Button } from 'components/common/button'
import {
  ConfirmContainer,
  ConfirmHeader,
  ConfirmTitle,
  ConfirmContent,
  ConfirmFooter,
} from 'components/common/confirm.styles'
import { Popin } from 'components/common/popin/popin'

import { Icon } from './svg-icon'

type ConfirmModalProps = {
  message: React.Node,
  sensitive?: boolean,
  title?: string,
  confirm?: string,
  cancel?: string,
  width?: number,
  ...
}

const ConfirmModal = ({
  title = 'Are you sure?',
  cancel = 'Cancel',
  message = '',
  sensitive = false,
  confirm = sensitive ? 'Yes, delete it' : 'Yes, change it',
  width,
  resolver,
  rejecter,
}: ConfirmModalProps & {
  resolver: () => any,
  rejecter: () => any,
  ...
}): React.Node => {
  return (
    <Popin close={rejecter} opened style={{ maxWidth: '620px', width: width ? width : 'auto' }}>
      <ConfirmContainer>
        <ConfirmHeader>
          <ConfirmTitle>{title}</ConfirmTitle>
          <Button autoFocus kind="inline" onClick={rejecter} style={{ height: 28, width: 28 }}>
            <Icon icon="close" />
          </Button>
        </ConfirmHeader>

        <ConfirmContent>{message}</ConfirmContent>

        <ConfirmFooter>
          <Button kind="inline" onClick={rejecter}>
            {cancel}
          </Button>
          <Button kind="primary" intent={sensitive ? 'danger' : 'action'} onClick={resolver}>
            {confirm}
          </Button>
        </ConfirmFooter>
      </ConfirmContainer>
    </Popin>
  )
}

export const confirm = (props: ConfirmModalProps): Promise<boolean> => {
  const body = document.body
  if (!body) throw new Error('confirm called in a non DOM context')
  const wrapper = body.appendChild(document.createElement('div'))
  const root = createRoot(wrapper)
  let resolver = () => undefined
  let rejecter = () => undefined
  const promise = new Promise<boolean>((resolve, reject) => {
    resolver = () => {
      root.unmount()
      setTimeout(() => wrapper.remove())
      resolve(true)
    }
    rejecter = () => {
      root.unmount()
      setTimeout(() => wrapper.remove())
      reject(false)
    }
  })
  root.render(<ConfirmModal {...props} resolver={resolver} rejecter={rejecter} />)
  return promise
}
