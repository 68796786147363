// @flow
import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type DurationUnit } from 'com.batch/capping/model/capping-rule'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'
import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

export type GetProjectResponse = {
  name: string,
  labels: Array<{
    description: string,
    code: string,
  }>,
  labelCappings?: { [string]: { cappings: Array<CappingRulePayload> } },
  smsProviders?: SmsProviders,
}

export type SetLabelsResponse = {
  labels: Array<{
    description: string,
    code: string,
  }>,
}

export type UnsetLabelsResponse = {
  labelCodes: Set<string>,
}

export type CappingRulePayload = {
  durationUnit: DurationUnit,
  durationValue: number,
  capping: number,
  label: string,
}

export type SmsProviderConfiguration = {
  provider: string,
  subaccountID: string,
  staticKey: {
    key: string,
    httpHeaderName: string,
  },
}

export type SmsCountriesConfiguration = {
  transmissionTypesConfiguration: {
    transactional: {
      transmissionType: TransmissionType,
      providerConfigurations: SmsProviderConfiguration[],
    },
    marketing: {
      transmissionType: TransmissionType,
      providerConfigurations: SmsProviderConfiguration[],
    },
  },
}

export type SmsProviders = {
  alphanumericSenderIDs: string[],
  maxParts: number,
  smsCountriesConfiguration: { [countryCode: string]: SmsCountriesConfiguration },
}

export const metadataService = {
  fetchProject: async ({ projectKey }: { projectKey: string }): Promise<GetProjectResponse> => {
    return request
      .post<GetProjectResponse>(
        generateUrl('api_grpc_metadata_service', {
          methodName: 'GetProject',
        }),
        { projectKey }
      )
      .catch(error => {
        throw new Error(
          `Error while loading project metadata for project ${projectKey}: ${error.message}`
        )
      })
  },
  setLabels: async ({
    projectKey,
    labels,
  }: {
    projectKey: string,
    labels: Array<$ReadOnly<{ description: string, code: string }>>,
  }): Promise<SetLabelsResponse> => {
    return request
      .post<SetLabelsResponse>(
        generateUrl('api_grpc_metadata_service', { methodName: 'SetLabels' }),
        {
          labels,
          ...generateProjectKeyBlockRequest(projectKey),
        }
      )
      .catch(error => {
        throw new Error(
          `Error while loading project metadata for project ${projectKey}: ${error.message}`
        )
      })
  },
  unsetLabels: async ({
    projectKey,
    labelCodes,
  }: {
    projectKey: string,
    labelCodes: Array<string>,
  }): Promise<UnsetLabelsResponse> => {
    return request.post<UnsetLabelsResponse>(
      generateUrl('api_grpc_metadata_service', { methodName: 'UnsetLabels' }),
      {
        labelCodes,
        ...generateProjectKeyBlockRequest(projectKey),
      }
    )
  },
  setCappingRules: async ({
    cappingRules,
    projectKey,
    label,
  }: {
    cappingRules: Map<
      string,
      Array<{
        label: string,
        durationValue: number,
        durationUnit: DurationUnit,
        capping: number,
      }>,
    >,
    projectKey: string,
    label: string,
  }): Promise<void> => {
    return request.post<void>(
      generateUrl('api_grpc_metadata_service', { methodName: 'SetMarketingPressureCappings' }),
      {
        labelCappings: {
          [label]: {
            cappings: cappingRules.get(label) || [],
          },
        },
        ...generateProjectKeyBlockRequest(projectKey),
      }
    )
  },
  unsetCappingRules: async ({
    projectKey,
    labelCodes,
  }: {
    projectKey: string,
    labelCodes: Array<string>,
  }): Promise<void> => {
    return request.post<void>(
      generateUrl('api_grpc_metadata_service', { methodName: 'UnsetMarketingPressureCappings' }),
      {
        labelCodes,
        ...generateProjectKeyBlockRequest(projectKey),
      }
    )
  },
}
