// @flow

import { OrderedMap } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ProfileDataRecord } from 'com.batch/profile/models/profile.records'
import { STATUS } from 'constants/common'

type SwitchProfileAction = {
  type: 'SWITCH_PROFILE',
  payload: {
    profileId: string,
    data: OrderedMap<string, ProfileDataRecord>,
    ...
  },
  ...
}

type SwitchProfileSuccessAction = {
  type: 'SWITCH_PROFILE_SUCCESS',
  payload: {
    data: OrderedMap<string, ProfileDataRecord>,
    ...
  },
  ...
}

type SwitchProfileFailureAction = {
  type: 'SWITCH_PROFILE_FAILURE',
  payload: {
    data: OrderedMap<string, ProfileDataRecord>,
    error: {
      errors: {
        message: string,
        ...
      }[],
      ...
    },
    ...
  },
  ...
}

export type switchProfileAction =
  | SwitchProfileAction
  | SwitchProfileSuccessAction
  | SwitchProfileFailureAction

let abortFetchEventsController: AbortController = new AbortController()

export const switchProfile = (profileIndex: number): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { debugGateway }) => {
    const project = currentProjectSelector(getState())
    const profilesData = getState().profile.data
    const profileId = profilesData.keySeq().get(profileIndex) ?? ''
    const data = profilesData.get(profileId)

    dispatch({
      type: 'SWITCH_PROFILE',
      payload: {
        profileId,
        data: profilesData.set(profileId, data?.set('eventsLoadingState', STATUS.LOADING)),
      },
    })

    abortFetchEventsController.abort()
    abortFetchEventsController = new AbortController()

    try {
      const eventsData = await debugGateway.fetchEvents({
        project,
        profileId,
        abortSignal: abortFetchEventsController.signal,
      })
      dispatch({
        type: 'SWITCH_PROFILE_SUCCESS',
        payload: {
          data: profilesData.set(
            profileId,
            data?.set('customEvents', eventsData)?.set('eventsLoadingState', STATUS.LOADED)
          ),
        },
      })
    } catch (error) {
      if (!error.aborted) {
        console.log(error)
        dispatch({
          type: 'SWITCH_PROFILE_FAILURE',
          payload: {
            error,
            data: profilesData.set(profileId, data?.set('eventsLoadingState', STATUS.ERROR)),
          },
        })
      }
    }
  }
}
