// @flow
import styled, { css, type PropsWithTheme, type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

import { ProfileMockupOptinContainer } from 'com.batch/profile/ui/components/profile-hero/profile-mockup-optin/profile-mockup-optin.styles'

const HOVER_ANIMATION = 'all 0.1s ease-out'

type PlatformNameProps = { platform: string, ... }
export const PlatformName: StyledComponent<PlatformNameProps, *, *> = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: ${schemes.grayscale['70']};

  ${IconContainer} {
    font-size: 12px;
    margin-right: 8px;
    svg {
      fill: ${(props: PropsWithTheme<PlatformNameProps, any>) =>
        props.platform === 'android' ? '#8cc052' : colors.textLight};
    }
  }
`

type LastVisitDateProps = {
  $noDate: boolean,
  ...
}
export const LastVisitDate: StyledComponent<LastVisitDateProps, *, HTMLElement> = styled.div`
  color: ${(props: LastVisitDateProps) => (props.$noDate ? colors.textDisabled : colors.textLight)};
  line-height: 20px;
`

export const PlatformDetails: StyledComponent<*, *, *> = styled.div`
  padding: 14px 0 14px 16px;
`

export const Debug: StyledComponent<*, *, *> = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: transparent;
  backdrop-filter: blur(0px);
  transition: ${HOVER_ANIMATION};

  & > div {
    display: none;
  }

  ${IconContainer} {
    margin-left: 4px;
  }
`

type OptionStatusContainerProps = {
  status: 'SUBSCRIBED' | 'UNSUBSCRIBED',
  ...
}
export const OptionStatusContainer: StyledComponent<OptionStatusContainerProps, *, *> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 3px 8px;
  width: 96px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 6px;

  ${(props: OptionStatusContainerProps) =>
    props.status === 'SUBSCRIBED'
      ? css`
          color: ${colors.textSuccess};
          background-color: ${colors.opacifySuccess};
        `
      : css`
          color: ${colors.textLight};
          border: 1px dashed ${colors.stroke};
        `}
`

export const Mockup: StyledComponent<*, *, *> = styled.div`
  position: relative;

  img {
    width: 100%;
  }

  ${OptionStatusContainer} {
    position: absolute;
    top: 40px;
    left: 22px;
  }
`
export const PlatformStatus: StyledComponent<*, *, *> = styled.div`
  position: relative;

  ${ProfileMockupOptinContainer} {
    max-height: 75px;
  }
`

const PLATFORM_STYLES = css`
  width: 100%;
  max-width: 348px;
  background-color: ${colors.fill};
  border: 1px solid ${colors.stroke};
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(15, 15, 15, 0.06);
  overflow: hidden;
`

export const PlatformButtonSkeleton: StyledComponent<*, *, *> = styled.div`
  ${PLATFORM_STYLES};
  padding: 14px 12px 14px 16px;
`

export const PlatformButtonContainer: StyledComponent<*, *, *> = styled.div`
  ${PLATFORM_STYLES};
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 140px;
  cursor: pointer;
  user-select: none;
  transition: ${HOVER_ANIMATION};

  &:hover {
    background-color: ${schemes.grayscale['05']};
    border-color: ${colors.strokeHover};
    box-shadow: 0px 1px 3px 0px rgba(15, 15, 15, 0.06);
  }

  &:focus,
  &:active {
    background-color: ${schemes.grayscale['10']};
    border-color: ${colors.strokeHover};
  }

  &:hover,
  &:focus,
  &:active {
    ${Debug} {
      backdrop-filter: blur(3px) grayscale(75%);
      color: ${colors.textAction};

      & > div {
        display: block;
      }
    }
  }
`
