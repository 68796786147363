// @flow
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow } from 'components/common/table/table.styles'

import { pluralizeAndKformat } from 'com.batch.common/utils'

import { type Segment } from '../../infra/segments.api'

import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

export const SegmentRow = ({
  estimate,
  segment,
  onEdit,
  onDelete,
}: {
  estimate: EstimateProfileRecord,
  segment: Segment,
  onEdit: () => void,
  onDelete: () => void,
  ...
}): React.Node => {
  return (
    <TableRow key={segment.id}>
      <TableCell style={{ overflow: 'initial' }}>{segment.name}</TableCell>
      <TableCell>
        {pluralizeAndKformat('profile', estimate.matching.reachableProfileCount)}
      </TableCell>
      <TableCellActions>
        <Button onClick={onEdit}>
          <Icon icon="edit" />
        </Button>
        <Button onClick={onDelete}>
          <Icon icon="delete" />
        </Button>
      </TableCellActions>
    </TableRow>
  )
}
