// @flow
import * as React from 'react'

import { roundedRect } from 'components/charts/chart-helper'
import { schemes } from 'components/styled/tokens'

import { BarPath } from './bar.styles'

import { STRIPE_PATTERN_REF } from 'com.batch/shared/ui/component/charts/donut-chart/patterns/stripe-pattern'

type Props = {
  width: number,
  height: number,
  x: number,
  y: number,
  isStriped?: boolean,
  hasBorderRadius?: { top?: boolean, bottom?: boolean },
  color: string,
  currentBar: string,
  hoveredBar?: string | null,
  isLoading: boolean,
}
export const Bar = ({
  width,
  height,
  x,
  y,
  hasBorderRadius,
  isStriped = false,
  color,
  currentBar,
  hoveredBar,
  isLoading = true,
}: Props): React.Node => {
  const plot = React.useMemo(
    () =>
      roundedRect({
        x,
        y,
        w: width,
        h: height,
        r: 5,
        tl: hasBorderRadius?.top ?? false,
        tr: hasBorderRadius?.top ?? false,
        bl: hasBorderRadius?.bottom ?? false,
        br: hasBorderRadius?.bottom ?? false,
      }),
    [width, height, x, y, hasBorderRadius]
  )

  if (height !== 0)
    return (
      <React.Fragment>
        <BarPath fill={color} d={plot} opacity={1} isLoading={isLoading} />
        {isStriped && !isLoading && (
          <BarPath fill={`url(#${STRIPE_PATTERN_REF})`} d={plot} opacity={0.2} />
        )}
        {!isLoading && (
          <BarPath
            fill={
              hoveredBar && hoveredBar === currentBar
                ? schemes.grayscale['100']
                : schemes.grayscale['00']
            }
            opacity={hoveredBar ? (hoveredBar === currentBar ? 0.1 : 0.65) : 0}
            d={plot}
            className={hoveredBar !== currentBar ? 'not-Hovered' : ''}
          />
        )}
      </React.Fragment>
    )

  return null
}
