// @flow

import { generateTypedId } from 'com.batch.common/typed-id'

import { initDefaultAdvancedSettings } from '../../push/usecases/init-default-advanced-settings'
import { type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { showToast } from 'com.batch.redux/toaster'

import { updateEmailSender } from 'com.batch/email/usecases/update-content'
import {
  getNodeId,
  type NodeType,
  type BranchId,
} from 'com.batch/orchestration-journey/models/journey.records'

export type InsertNodeAfterAction = ReduxAction<
  'INSERT_NODE_AFTER',
  {
    branchIds: Array<BranchId>,
    id: string,
    nodeType: NodeType,
    channel: ?ChannelUntilCleanup,
    messageReference: string,
  },
>

const generateToastMessageForMessageNodeType = (channel: ?ChannelUntilCleanup) => {
  switch (channel) {
    case 'email':
      return 'Email added'
    case 'sms':
      return 'SMS added'
    case 'push':
      return 'Push added'
    default:
      return 'Node added'
  }
}

export const insertNodeAfter = ({
  branchIds,
  nodeType,
  channel = 'email',
}: {
  branchIds: Array<BranchId>,
  nodeType: NodeType,
  channel: ?ChannelUntilCleanup,
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const newNodeId = getNodeId(nodeType)
    const messageReference = generateTypedId('message')

    dispatch(
      ({
        type: 'INSERT_NODE_AFTER',
        payload: {
          branchIds,
          id: newNodeId,
          nodeType,
          channel,
          messageReference,
        },
      }: InsertNodeAfterAction)
    )

    dispatch(
      showToast({
        message:
          nodeType === 'MESSAGE'
            ? generateToastMessageForMessageNodeType(channel)
            : nodeType === 'YESNO'
              ? 'Yes/No split added'
              : nodeType === 'RANDOM'
                ? 'Random split added'
                : 'Delay added',
        kind: 'success',
      })
    )
    if (nodeType === 'MESSAGE' && channel === 'push') {
      dispatch(initDefaultAdvancedSettings(messageReference))
    }
    if (nodeType === 'MESSAGE' && channel === 'email') {
      /*
        if we are inserting an email node, we set sender id on the new message content
      */
      const state = getState()
      const emailContentWithSenderId = state.message.email
        .find(byNode => Boolean(byNode.find(content => Boolean(content.senderIdentityId))))
        ?.get('default')

      if (emailContentWithSenderId) {
        dispatch(
          updateEmailSender({
            messageId: messageReference,
            senderIdentityId: emailContentWithSenderId?.senderIdentityId ?? 0,
            fromEmail: emailContentWithSenderId?.fromEmail ?? '',
            name: emailContentWithSenderId?.name ?? '',
          })
        )
      }
    }
  }
}
