// @flow

import styled, { type StyledComponent } from 'styled-components'

import { schemes } from 'components/styled/tokens'
import * as colors from 'components/styled/tokens/colors'
import * as shadows from 'components/styled/tokens/shadows'

import { BlockTitle } from './common.styles'

export const SplitContainer: StyledComponent<{ ... }, *, *> = styled.div`
  width: 280px;
  color: ${colors.textContrast};
`

export const SplitTag: StyledComponent<{ ... }, *, *> = styled.div`
  padding: 3px 8px;
  background: ${schemes.darklucent['80']};
  border-radius: 100px;
  height: 26px;
  font-weight: 500;
  color: ${colors.textContrast};
`
export const SplitLabel: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  ${BlockTitle};
`

type SplitProps = { $isEditing: boolean, ... }
export const SplitBox: StyledComponent<SplitProps, *, *> = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  width: 280px;
  min-height: 52px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  background: ${colors.fill};
  &:hover {
    background-color: ${schemes.grayscale['10']};
  }
  &:active {
    background-color: ${schemes.grayscale['20']};
  }
  margin-bottom: 8px;
  font-weight: 500;
  outline: ${(p: SplitProps) => {
    if (p.$isEditing) return `2px solid ${colors.fillAction}`
    return `1px solid ${colors.stroke}`
  }};
  color: ${colors.text};
  box-shadow: ${shadows.block};
`

export const SplitSources: StyledComponent<{ ... }, *, *> = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  pointer-events: none;
  z-index: 0;
  width: 380px;
  margin-left: -50px;
`
