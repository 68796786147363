// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { Tooltip } from 'components/common/tooltip'
import { Checkbox } from 'components/form'

import { SegmentLabel } from './styles'

import { type ClusterRecord } from 'com.batch.redux/_records'

type SegmentProps = {
  segment: ClusterRecord,
  checked: boolean,
  onChange: boolean => void,
  ...
}

export const Segment = ({ checked, segment, onChange }: SegmentProps): React.Node => {
  const theme = useTheme()
  return (
    <Tooltip
      isTooltipEmpty={!segment.desc}
      tooltip={segment.desc ?? ''}
      minWidth={244}
      offset={[0, 10]}
    >
      <SegmentLabel color={segment.color} checked={checked}>
        <Checkbox
          disabled={theme?.disabledMode}
          checked={checked}
          onChange={() => onChange(!checked)}
          activeColor={segment.color}
        />
        <span>{segment.name}</span>
      </SegmentLabel>
    </Tooltip>
  )
}
