// @flow

import { type List } from 'immutable'
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { trackEvent } from 'components/common/page-tracker'
import { schemes, colors } from 'components/styled/tokens'
import { Scrollbar } from 'components/styled/utils'

import { useAppSwitcher } from 'com.batch.common/router'

import { SidebarApp, Container as AppContainer } from './sidebar-app'
import { SIDEBAR_WIDTH, PADDING } from './sidebar.styles'

import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'

type SidebarAppsListProps = {
  apps: List<AppRecord>,
  currentApp: AppRecord,
  company: CompanyRecord,
  className?: string,
  style?: any,
  ...
}
const trackChangeApp = (app: AppRecord) =>
  trackEvent('SWITCH_APP', { app_id: app.id, app_name: app.name })

export const SidebarAppsList: React.AbstractComponent<SidebarAppsListProps> =
  React.memo<SidebarAppsListProps>((props: SidebarAppsListProps) => {
    const { apps, className, style, currentApp } = props
    const generateUrlForApp = useAppSwitcher(currentApp)
    const cleanApps = React.useMemo(() => {
      return apps.map(app => {
        return {
          app,
          url: generateUrlForApp(app.id),
          track: () => trackChangeApp(app),
        }
      })
    }, [apps, generateUrlForApp])
    return (
      <Container className={className} style={style}>
        {cleanApps.size > 0 ? (
          cleanApps.map(a => (
            <SidebarApp app={a.app} href={a.url} key={a.app.id} onClick={a.track} />
          ))
        ) : (
          <Empty>No apps matching your search</Empty>
        )}
      </Container>
    )
  })

export const Empty: StyledComponent<*, *, HTMLElement> = styled.p`
  margin: 4px 2px 10px 2px;
  color: ${colors.textDisabled}px;

  @media (min-width: 980px) {
    margin-top: 8px;
    padding-left: ${PADDING - 4}px;
  }
`

export const Container: StyledComponent<*, *, HTMLElement> = styled.div`
  flex: 0 1 auto;
  overflow-x: hidden;
  overflow-y: auto;

  ${Scrollbar({ background: schemes.grayscale['10'] })}
  ${AppContainer} + ${AppContainer} {
    margin-top: 1px;
  }

  @media (max-width: 980px) {
    ${Scrollbar({ background: colors.fill })}
  }
  @media (min-width: 980px) {
    ${AppContainer} {
      width: ${SIDEBAR_WIDTH - PADDING * 2 - 4}px;
    }
  }
`

export default SidebarAppsList
