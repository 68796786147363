// @flow

import { type Dayjs } from 'dayjs'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { InputWrapper, Input, Feedback } from 'components/form'

import { dayjs } from 'com.batch.common/dayjs.custom'

import IOSCertReview from './ios-cert-review'
import { TextMuted, UploadArea, CertifSettingsUpload } from './settings.styles'

type SettingsUploadProps = {
  saveHandler: (certificate: Object) => void,
  upload: UploadState,
  setUpload: (value: UploadState) => void,
  parseFile: (file: ?File | null, callback?: (UploadState: any) => void) => void,
  isReadyForSave: (upload: UploadState) => boolean,
  ...
}

export type UploadState = {
  password: string,
  teamId: string,
  keyId: string,
  topic: string,
  isUploaded: boolean,
  isParsing: boolean,
  hasParsedExtra: boolean,
  isValid: boolean,
  isWrongPass: boolean,
  authCheck: boolean,
  file: ?File,
  mode: ?('P8' | 'P12'),
  cn: ?string,
  expire: ?Dayjs,
  ...
}

export const defaultUpload: UploadState = {
  password: '',
  teamId: '',
  keyId: '',
  topic: '',
  isUploaded: false,
  isParsing: false,
  hasParsedExtra: false,
  isValid: false,
  isWrongPass: false,
  authCheck: false,
  file: undefined,
  mode: undefined,
  cn: '',
  expire: undefined,
}

export const SettingsUpload: React.ComponentType<SettingsUploadProps> = React.memo(
  ({ saveHandler, upload, setUpload, parseFile, isReadyForSave }: SettingsUploadProps) => {
    const now = dayjs()

    const validateAndSave = React.useCallback(() => {
      if (
        (upload.mode === 'P12' && !upload.password) ||
        (upload.mode === 'P8' && (!upload.topic || !upload.teamId || !upload.keyId))
      ) {
        return false
      }

      parseFile(upload.file, state => {
        if (isReadyForSave(state)) saveHandler(upload)
      })
    }, [isReadyForSave, parseFile, saveHandler, upload])

    const onKeyPress = React.useCallback(
      evt => {
        if (evt.key !== 'Enter') {
          return false
        }
        validateAndSave()
      },
      [validateAndSave]
    )

    const onFieldChange = React.useCallback(
      (field: 'password' | 'teamId' | 'keyId' | 'topic') => {
        return (evt: SyntheticInputEvent<HTMLInputElement>) =>
          setUpload({ ...upload, [(field: string)]: evt.target.value.trim() })
      },
      [setUpload, upload]
    )

    const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
      onDropAccepted: files => parseFile(files[0]),
      accept: {
        'application/pkcs8': ['.p8'],
        'application/x-pkcs12': ['.p12'],
      },
      multiple: false,
      maxSize: 8192,
      disablePreview: true,
    })

    return (
      <UploadArea>
        {!upload.isUploaded && (
          <CertifSettingsUpload
            isActive={isDragActive}
            isRejected={isDragReject}
            isAccepted={isDragAccept}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <p>Drop your file here or</p>
            <Button type="button" kind="inline" intent="neutral">
              Browse your files
            </Button>
          </CertifSettingsUpload>
        )}

        {upload.isUploaded && !!upload.file ? (
          <UploadArea review>
            <IOSCertReview
              isParsing={upload.isParsing}
              isValid={upload.isValid}
              expire={upload.expire}
              filename={upload.file.name}
              cn={upload.cn}
              mode={upload.mode}
            />

            <React.Fragment>
              {((upload.mode === 'P8' && upload.authCheck) ||
                (upload.isValid && upload.expire && upload.expire.isAfter(now))) && (
                <TextMuted>Your new push configuration seems fine.</TextMuted>
              )}
              {upload.mode === 'P12' && upload.expire && upload.expire.isBefore(now) && (
                <Feedback type="error" message="This certificate is expired and can't be used." />
              )}

              {!upload.isValid &&
                !upload.isParsing &&
                (upload.mode === 'P12' ? (
                  <React.Fragment>
                    <p>
                      We are unable to read this file. If it's a protected p12 certificate, please
                      enter the password below.
                    </p>

                    <InputWrapper
                      label="Password"
                      feedback={
                        upload.isWrongPass
                          ? 'Unable to read certificate with given password. Please try again.'
                          : ''
                      }
                    >
                      <Grid template="1fr auto">
                        <Input
                          type="password"
                          className="fs-exclude"
                          placeholder="Certificate password"
                          invalid={upload.isWrongPass}
                          value={upload.password}
                          onChange={onFieldChange('password')}
                          onKeyPress={onKeyPress}
                          autoComplete
                        />
                        <Button
                          type="button"
                          kind="secondary"
                          intent="action"
                          disabled={!upload.password}
                          onClick={validateAndSave}
                        >
                          Check and save
                        </Button>
                      </Grid>
                    </InputWrapper>
                  </React.Fragment>
                ) : (
                  <Feedback type="error" message="We are unable to read this file." />
                ))}

              {upload.mode === 'P8' && upload.isValid && (
                <React.Fragment>
                  <p style={{ marginBottom: 16 }}>
                    We need a few extra informations before we can continue:
                  </p>

                  <Grid template="1fr 1fr 1fr" style={{ width: '100%', marginBottom: 10 }}>
                    <InputWrapper label="App ID / Bundle ID">
                      <Input
                        placeholder="APNS Topic"
                        value={upload.topic}
                        onChange={onFieldChange('topic')}
                        onKeyPress={onKeyPress}
                      />
                    </InputWrapper>

                    <InputWrapper label="Team ID" style={{ marginTop: 0 }}>
                      <Input
                        placeholder="APNS Team ID"
                        className="fs-exclude"
                        value={upload.teamId}
                        onChange={onFieldChange('teamId')}
                        onKeyPress={onKeyPress}
                      />
                    </InputWrapper>

                    <InputWrapper label="Key ID" style={{ marginTop: 0 }}>
                      <Input
                        placeholder="APNS Key ID"
                        className="fs-exclude"
                        value={upload.keyId}
                        onChange={onFieldChange('keyId')}
                        onKeyPress={onKeyPress}
                      />
                    </InputWrapper>
                  </Grid>

                  {!upload.authCheck &&
                    upload.topic &&
                    upload.teamId &&
                    upload.keyId &&
                    upload.hasParsedExtra && (
                      <Feedback
                        type="error"
                        message="These informations are not matching the key. Please try again."
                      />
                    )}
                </React.Fragment>
              )}
            </React.Fragment>
          </UploadArea>
        ) : null}
      </UploadArea>
    )
  }
)
