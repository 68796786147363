// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

export const SinceDate: StyledComponent<*, *, *> = styled.span`
  color: ${colors.textLight};
  font-weight: 400;
  margin-left: 6px;
`

export const BounceBlockHeaderContainer: StyledComponent<*, *, *> = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const BounceStatLegend: StyledComponent<*, *, *> = styled.div`
  color: ${colors.textNeutral};
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
`

export const BounceStatsRow: StyledComponent<*, *, *> = styled.div`
  display: flex;
  gap: 24px;
  padding: 0 20px;
`

type BounceBadgeProps = { color: string, size?: string, ... }
export const BounceBadge: StyledComponent<BounceBadgeProps, *, *> = styled.div`
  width: ${(p: BounceBadgeProps) => (p.size === 'small' ? '12' : '16')}px;
  height: ${(p: BounceBadgeProps) => (p.size === 'small' ? '12' : '16')}px;
  background: ${(p: BounceBadgeProps) => p.color};
  border-radius: 4px;
`
export const BounceStatText: StyledComponent<*, *, *> = styled.div`
  color: ${colors.text};
  font-size: 28px;
  font-weight: 300;

  & > span {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.textLight};
  }
`
