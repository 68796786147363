// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { darklucent } from 'components/styled/tokens/schemes'

type BounceByDaysWrapperProps = { overlay: boolean, noDataMsg: string, ... }
export const BounceByDaysWrapper: StyledComponent<
  BounceByDaysWrapperProps,
  { ... },
  HTMLDivElement,
> = styled.div`
  margin-top: 18px;
  border-top: 1px solid ${darklucent[20]};
  position: relative;
  padding: 0 20px;

  ${(props: BounceByDaysWrapperProps) =>
    props.overlay &&
    css`
      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.textLight};
        content: '${(props: BounceByDaysWrapperProps) => props.noDataMsg}';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        white-space: pre;
        text-align: center;
      }
    `}
`
