/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PushWhenTriggerEvent } from 'components/campaign/push-when-trigger-event'
import { WrapLabel } from 'components/common/form/wrap-label'
import { Hint } from 'components/common/hint'

import { visibleEventsSelector } from 'com.batch.redux/attribute.selector'

import { parseTriggerQuery } from 'com.batch/orchestration-journey/models/journey-trigger.helper'
import { EventWithOptionalQueryFactory } from 'com.batch/orchestration-journey/models/journey.records'
import { journeySettingsSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { updateSettings } from 'com.batch/orchestration-journey/usecases/update-settings'

export const EmailWhenTrigger = (): React.Node => {
  const dispatch = useDispatch()
  const events = useSelector(visibleEventsSelector)
  const journeySettings = useSelector(journeySettingsSelector)
  const enterEvent = React.useMemo(
    () => journeySettings.entryEvents?.first()?.name ?? '',
    [journeySettings]
  )

  const onQueryChange = React.useCallback(
    query => {
      dispatch(
        updateSettings(
          journeySettings.set(
            'entryEvents',
            new Immutable.List().push(
              ...(enterEvent
                ? [
                    EventWithOptionalQueryFactory({
                      name: enterEvent,
                      query: parseTriggerQuery(query),
                    }),
                  ]
                : [])
            )
          )
        )
      )
    },
    [dispatch, journeySettings, enterEvent]
  )

  const onEventChange = React.useCallback(
    evt => {
      dispatch(
        updateSettings(
          journeySettings
            .set(
              'entryEvents',
              new Immutable.List().push(
                ...[
                  EventWithOptionalQueryFactory({
                    name: evt,
                  }),
                ]
              )
            )
            .set('instanceId', '')
            .set('hasInstanceId', false)
          // pushTimerMode and pushTimerReference seem not to be used when submitting settings changes
          /* .set('pushTimerMode', 'event')
            .set('pushTimerReference', '') */
        )
      )
    },
    [dispatch, journeySettings]
  )

  return (
    <WrapLabel
      label={
        <React.Fragment>
          Trigger event
          <Hint minTooltipWidth={150}>The user enters an automation when this event occurs.</Hint>
        </React.Fragment>
      }
      id="enterEvent"
      style={{ position: 'relative' }}
    >
      <PushWhenTriggerEvent
        queryId="enterEvent"
        isEmailAutomation
        eventId={enterEvent}
        events={events}
        onQueryChange={onQueryChange}
        onEventChange={onEventChange}
      />
    </WrapLabel>
  )
}
