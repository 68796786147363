// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { useWidthObserver } from 'components/_hooks'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { OptionContainer } from './checkbox.styles'

type CheckboxProps = {
  label?: ReactNode,
  name?: string,
  id?: string,
  size?: number,
  className?: string,
  activeColor?: string,
  handleIndeterminate?: boolean,
  checked?: ?boolean,
  disabled?: boolean,
  style?: { [string]: number | string, ... },
  tooltip?: string,
  onChange?: () => void,
}

const Checkbox = ({
  label,
  checked,
  onChange,
  activeColor,
  handleIndeterminate = false,
  size,
  style,
  tooltip,
  disabled = false,
  ...props
}: CheckboxProps): React.Node => {
  const _onChange = React.useCallback(
    e => {
      if (e.currentTarget) e.currentTarget.blur()
      if (onChange) onChange()
    },
    [onChange]
  )

  const spanRef = React.useRef<null | HTMLElement>(null)

  // just used to trigger a component update when width changes, so the isTooltipEmpty checks can re-evaluate
  useWidthObserver(spanRef, 0)

  const indeterminate = (handleIndeterminate && typeof checked === 'undefined') || checked === null
  const tooltipContent = React.useMemo(() => (tooltip ? tooltip : label ?? ''), [tooltip, label])

  return (
    <Tooltip
      tooltip={tooltipContent}
      placement="right"
      minWidth={198}
      isTooltipEmpty={
        !(
          Boolean(tooltip) ||
          (spanRef?.current?.scrollWidth ?? 0) > (spanRef?.current?.clientWidth ?? 0)
        )
      }
    >
      <OptionContainer activeColor={activeColor} disabled={disabled} size={size} style={style}>
        <input
          {...props}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          onChange={_onChange}
        />

        {indeterminate ? (
          <Icon icon="tiret" thickness={2} className="styled-option-novalue" />
        ) : (
          <Icon icon="check" thickness={2} className="styled-option-symbol" />
        )}

        <span ref={spanRef} className="styled-option-label">
          {label}
        </span>
      </OptionContainer>
    </Tooltip>
  )
}

export { Checkbox }
