// @flow

import * as React from 'react'

import { BoxHeader, HeaderBoxActions, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { stroke } from 'components/styled/tokens/colors'

import { CollapsePanel } from './collapse/collapse'

type AdvancedSettingsProps = {
  children: React.Node,
  campaignFormMode?: boolean,
  topShadow?: boolean,
  openDirection?: 'down' | 'up',
  scrollOnOpen?: boolean,
}

export const AdvancedSettingsPanel = ({
  children,
  campaignFormMode,
  topShadow = true,
  openDirection = 'down',
  scrollOnOpen = false,
}: AdvancedSettingsProps): React.Node => {
  const [showAdvanced, setShowAdvanced] = React.useState(false)

  const onToggleAdvancedSettings = React.useCallback(() => {
    setShowAdvanced(prev => !prev)
  }, [])

  React.useEffect(() => {
    if (scrollOnOpen) {
      const sidesheet = document.getElementById('sheet-message')
      if (sidesheet && showAdvanced)
        setTimeout(() => {
          sidesheet.scrollTop = sidesheet.scrollHeight
        }, 200)
    }
  }, [showAdvanced, scrollOnOpen])

  const chevronIcon = React.useMemo(() => {
    if (openDirection === 'up') return showAdvanced ? 'chevron-down' : 'chevron-up'
    else return showAdvanced ? 'chevron-up' : 'chevron-down'
  }, [openDirection, showAdvanced])

  return (
    <React.Fragment>
      <div
        style={{
          overflow: 'hidden',
          paddingTop: 10,
          marginTop: -10,
          flex: '0 0 62px',
        }}
      >
        <BoxHeader
          style={{
            cursor: 'pointer',
            height: 52,
            borderTop: '1px solid rgba(233,233,233,1)',
            borderRadius: campaignFormMode && !showAdvanced ? '0 0 8px 8px' : 0,
            boxShadow: topShadow
              ? '0px 1px 3px 0px rgba(15, 15, 15, 0.08), 0px 1px 8px 0px rgba(15, 15, 15, 0.02)'
              : 'none',
            borderBottom: showAdvanced ? `1px solid ${stroke}` : 'none',
          }}
          onClick={onToggleAdvancedSettings}
          data-testid="button_show_advanced_settings"
        >
          <HeaderBoxTitle title="Advanced settings" style={{ color: colors.text }} />
          <HeaderBoxActions>
            <Button style={{ height: 28, width: 28 }}>
              <Icon icon={chevronIcon} />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
      </div>
      <CollapsePanel isOpened={showAdvanced}>{children}</CollapsePanel>
    </React.Fragment>
  )
}
