// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Box, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { BoxBody } from 'components/common/box/box.styles'
import { Targeting } from 'components/project/campaign/common/targeting'
import { Content } from 'components/styled/blocs'

import {
  orchestrationCampaignSelector,
  orchestrationMetaSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'

import { EmailAdvancedSettings } from 'com.batch/email/ui/components/email-advanced-settings'
import { EmailBlock } from 'com.batch/email/ui/pages/email-block'
import { FooterOrchestration } from 'com.batch/orchestration/ui/components/footer-orchestration'
import { useForceEnabledForm } from 'com.batch/orchestration/ui/components/use-force-enabled-form'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { WhenOrchestration } from 'com.batch/orchestration-campaign/ui/components/when-orchestration'
import { updateOrchestrationCampaign } from 'com.batch/orchestration-campaign/usecases/update-orchestration-campaign'
import { TargetingWrapperCampaign } from 'com.batch/orchestration-journey/ui/components/sheet-settings.styles'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { PushAdvancedSettings } from 'com.batch/push/ui/push-advanced-settings'
import { PushBuilder } from 'com.batch/push/ui/push-builder'
import { AdvancedSettingsPanel } from 'com.batch/shared/ui/component/advanced-settings-panel'
import { SmsBuilder } from 'com.batch/sms/ui/components/sms-builder'

export const CampaignForm = (): React.Node => {
  const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()
  const orchestration = useSelector(orchestrationCampaignSelector)
  const forceEnabled = useForceEnabledForm()

  const params = useParams()
  const channel: ChannelUntilCleanup = React.useMemo(() => {
    switch (params?.channel) {
      case 'email':
        return 'email'
      case 'sms':
        return 'sms'
      case 'push':
        return 'push'
      default:
        throw new Error('Unhandled channel parameter in URL')
    }
  }, [params?.channel])

  const { state } = useSelector(orchestrationMetaSelector)
  const dispatch = useDispatch()
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const formTheme = React.useMemo(
    () => ({
      kind: 'capture',
      disabledMode: (state === 'COMPLETED' || !userHasWritePermission) && !forceEnabled,
    }),
    [state, userHasWritePermission, forceEnabled]
  )
  const onOrchestrationCampaignChange = React.useCallback(
    (campaign: OrchestrationCampaignRecord) => {
      dispatch(updateOrchestrationCampaign(campaign))
    },
    [dispatch]
  )

  return (
    <Content>
      <ThemeProvider theme={formTheme}>
        <TargetingWrapperCampaign>
          {channel && <Targeting canvasMode={false} isProfile id="default" channel={channel} />}
        </TargetingWrapperCampaign>
        <WhenOrchestration
          schedulingType={schedulingTypeFromUrl}
          value={orchestration}
          onChange={onOrchestrationCampaignChange}
        />

        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Message" />
          </BoxHeader>
          <BoxBody
            style={{
              minHeight: '480px',
              overflow: 'hidden',
              borderTopRadius: 0,
            }}
          >
            {channel === 'email' && (
              <React.Fragment>
                <EmailBlock
                  campaignFormMode
                  messageId={orchestration.messageTypedId}
                  stepId={undefined}
                />
                <AdvancedSettingsPanel campaignFormMode>
                  <EmailAdvancedSettings
                    messageId={orchestration.messageTypedId}
                    campaignFormMode
                  />
                </AdvancedSettingsPanel>
              </React.Fragment>
            )}
            {channel === 'sms' && <SmsBuilder messageId={orchestration.messageTypedId} />}
            {channel === 'push' && (
              <React.Fragment>
                <PushBuilder messageId={orchestration.messageTypedId} />
                <AdvancedSettingsPanel campaignFormMode>
                  <PushAdvancedSettings messageId={orchestration.messageTypedId} />
                </AdvancedSettingsPanel>
              </React.Fragment>
            )}
          </BoxBody>
        </Box>
      </ThemeProvider>
      <FooterOrchestration isFixed={false} channel={channel} />
    </Content>
  )
}
