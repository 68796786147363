// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider, useTheme } from 'styled-components'

import { useToggle } from 'components/_hooks'

import { PopinSendTestPush } from './popin-send-test-push'
import { PushLayerBarSendTest } from './push-layer-bar-send-test'

import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { LayerBarDropdown } from 'com.batch/message-builder/ui/components/layerbar-button'
import {
  DoubleLayerBarButton,
  LayerBarDivider,
  LayerBarWrapper,
} from 'com.batch/message-builder/ui/components/layerbar.styles'
import { updatePushTemplate } from 'com.batch/push/usecases/update-push-template'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'
import { PreviewAs } from 'com.batch/shared/ui/component/preview-as'

type PushLayerBarProps = {
  messageId: string,
  hasMedia: boolean,
  hasIcon: boolean,
  showIcon: boolean,
  setEditing: (field: MessageBuilderEditableField, caret: number) => void,
  openFileUploader: () => void,
  openMediaPopin: () => void,
}
const PushLayerBarRaw = ({
  messageId,
  hasMedia,
  hasIcon,
  showIcon,
  setEditing,
  openFileUploader,
  openMediaPopin,
}: PushLayerBarProps): React.Node => {
  const dispatch = useDispatch()
  const previewProfilePopinState = useToggle()
  const sendTestPopinState = useToggle()
  const lang = useSelector(activeLanguageValueSelector)

  const theme = useTheme()

  const handlePreviewAsClick = React.useCallback(() => {
    previewProfilePopinState.open()
  }, [previewProfilePopinState])
  const handleSendTestClick = React.useCallback(() => {
    sendTestPopinState.open()
  }, [sendTestPopinState])

  const updateTemplate = React.useCallback(() => {
    dispatch(
      updatePushTemplate({
        lang,
        messageId,
      })
    )
  }, [dispatch, lang, messageId])

  const handleSetEditing = React.useCallback(() => {
    setEditing('icon', -1)
  }, [setEditing])

  return (
    <LayerBarWrapper $itemsCount={!theme?.disabledMode ? (showIcon ? 4 : 3) : 2}>
      <ThemeProvider theme={{ disabledMode: false }}>
        <PreviewProfilePopin
          togglePopin={previewProfilePopinState}
          updateTemplate={updateTemplate}
        />
        <PopinSendTestPush togglePopin={sendTestPopinState} messageId={messageId} />
      </ThemeProvider>

      {!theme?.disabledMode && (
        <DoubleLayerBarButton>
          <LayerBarDropdown
            embedded
            label={!hasMedia ? 'Add media' : 'Media'}
            icon={<img src="/medias/img/builders/icons/media.svg" />}
            addable={!hasMedia}
            onClick={() => {
              setEditing('image', -1)
            }}
            options={[
              { label: 'From computer', prefix: 'upload', action: openFileUploader },
              { label: 'From link', prefix: 'link', action: openMediaPopin },
            ]}
          />

          {!theme?.disabledMode && showIcon && (
            <React.Fragment>
              <LayerBarDivider />
              <LayerBarDropdown
                embedded
                label={!hasIcon ? 'Add icon' : 'Icon'}
                icon={<img src="/medias/img/builders/icons/icon.svg" />}
                addable={!hasIcon}
                onClick={handleSetEditing}
                options={[
                  { label: 'From computer', prefix: 'upload', action: openFileUploader },
                  { label: 'From link', prefix: 'link', action: openMediaPopin },
                ]}
              />
            </React.Fragment>
          )}
        </DoubleLayerBarButton>
      )}

      <PushLayerBarSendTest handleSendTestClick={handleSendTestClick} messageId={messageId} />
      <PreviewAs handlePreviewAsClick={handlePreviewAsClick} updateTemplate={updateTemplate} />
    </LayerBarWrapper>
  )
}

export const PushLayerBar: React.ComponentType<PushLayerBarProps> = React.memo(PushLayerBarRaw)
