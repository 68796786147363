// @flow

import { type List } from 'immutable'
import * as React from 'react'

import {
  useCurrentCompanyHasFeature,
  useCurrentCompanyHasOneFeatureAmongst,
} from 'components/_hooks/use-company-feature'
import { ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Separator } from 'components/project/navigation/navbar.styles'

import { useBuildChannelNav, type ChannelInUrl } from 'com.batch.common/router'

import { ChannelSubNavContainer } from './channel-sub-nav.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'

type ChannelSubNavProps = {
  apps: List<AppRecord>,
  project: ProjectRecord,
  channel: ChannelInUrl,
  isTransparent?: boolean,
  isCollapsed?: boolean,
  gotBorder?: boolean,
}

export const ChannelSubNav = ({
  apps,
  project,
  channel,
  isTransparent = false,
  isCollapsed = false,
  gotBorder = false,
}: ChannelSubNavProps): React.Node => {
  const hasCepCampaignFeature = useCurrentCompanyHasOneFeatureAmongst([
    'email-campaign',
    'cep-sms-campaign',
    'cep-push-campaign',
  ])

  const navItems = useBuildChannelNav({
    apps,
    project,
    channel,
    hasCepCampaignFeature,
  })
  // we need a hack to hide website on CEP automations pages with only inapp-campaigns
  const hasInapp = useCurrentCompanyHasFeature('inapp-campaigns')
  const hasLegacyPushOnAutomation = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
  ])
  const hideWeb = hasInapp && !hasLegacyPushOnAutomation
  // we hide subnav on form pages
  if (navItems.length === 0) return null

  return (
    <ChannelSubNavContainer
      isTransparent={isTransparent}
      isCollapsed={isCollapsed}
      gotBorder={gotBorder}
    >
      {navItems
        .filter(nav => nav.id !== 'automations' || !(hideWeb && nav.className === 'webpush'))
        .map((item, key) => (
          <React.Fragment key={key}>
            <ButtonLink
              href={item.url}
              isActive={item.isActive}
              kind="inline"
              addOn={item.icon ? 'suffix' : undefined}
              className={(item.className ?? '') + (item.isActive ? ' isActive' : '')}
            >
              {item.icon && <Icon icon={item.icon} />}
              {item.label}
            </ButtonLink>
            {item.isIndex && navItems.length > 1 && <Separator />}
          </React.Fragment>
        ))}
    </ChannelSubNavContainer>
  )
}
