// @flow
import * as React from 'react'

import { ColorPicker } from 'components/common/color-picker'
import { Grid } from 'components/common/grid'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'components/common/tooltip'

import { OpacityEditor } from './opacity-editor'

import { type FieldRecord, type StyleProperty } from 'com.batch.redux/theme.records'

type StyleEditorProps = {
  field: FieldRecord,
  update: (property: StyleProperty, value: any) => void,
  ...
}

const labelForStyle = {
  backgroundColor: 'Background',
  backgroundOpacity: 'Opacity',
  color: 'Color',
  borderRadius: 'Border radius',
  fontSize: 'Font size',
  fontWeight: 'Font weight',
  backgroundSize: 'Size',
  padding: 'Padding',
  margin: 'Margin',
  borderWidth: 'Border width',
  borderColor: 'Border',
}

class StyleEditor extends React.PureComponent<StyleEditorProps> {
  constructor(props: StyleEditorProps) {
    super(props)
  }
  setBorderRadius: (evt: SyntheticInputEvent<HTMLInputElement>) => void = evt => {
    const val = parseInt(evt.target.value)
    if (!isNaN(val) && val >= 0) {
      this.props.update('borderRadius', `${val}px`)
    } else {
      this.props.update('borderRadius', '')
    }
  }
  getBorderRadius: () => number | string = () => {
    const val = this.props.field.style.get('borderRadius', '')
    return val ? parseInt(val.toString().replace(/px/g, '')) : ''
  }
  setOpacity: number => void = val => {
    this.props.update('backgroundOpacity', val)
  }
  getOpacity: () => number = () => {
    return this.props.field.style.get('backgroundOpacity', 100)
      ? parseInt(this.props.field.style.get('backgroundOpacity', 100)) ?? 100
      : 100
  }
  renderStyle(name: StyleProperty): React.Node | null {
    switch (name) {
      case 'borderColor':
      case 'color':
      case 'backgroundColor':
        const color = this.props.field.style.get(name, '') || ''
        return (
          <Grid template="1fr">
            <ColorPicker
              color={color.toString()}
              onChange={color => this.props.update(name, color)}
            />
          </Grid>
        )
      case 'backgroundOpacity':
        return (
          <Grid template="1fr auto">
            <OpacityEditor value={this.getOpacity()} onChange={this.setOpacity} />
            <span className="unit">%</span>
          </Grid>
        )
      case 'borderRadius':
        return (
          <Grid template="1fr auto">
            <input
              type="number"
              onChange={this.setBorderRadius}
              value={this.getBorderRadius()}
              className="form-control"
            />
            <span className="unit">px</span>
          </Grid>
        )
      case 'backgroundSize':
        return (
          <Grid template="1fr">
            <TableToggle>
              <TableToggleItem
                active={this.props.field.style.get(name) === 'cover'}
                onClick={() => this.props.update(name, 'cover')}
              >
                <Tooltip tooltip="Scales the image as large as possible without stretching the image. If the proportions of the image differ from the device, it is cropped either vertically or horizontally so that no empty space remains.">
                  <div>Cover</div>
                </Tooltip>
              </TableToggleItem>
              <TableToggleItem
                active={this.props.field.style.get(name) === 'contain'}
                onClick={() => this.props.update(name, 'contain')}
              >
                <Tooltip tooltip="Scales the image as large as possible without cropping or stretching the image.">
                  <div>Contain</div>
                </Tooltip>
              </TableToggleItem>
            </TableToggle>
          </Grid>
        )
      default:
        return null
    }
  }
  render(): React.Node {
    const field: FieldRecord = this.props.field
    return (
      <div>
        {field &&
          field.editableStyles.size > 0 &&
          field.editableStyles.map(propertyName => {
            return (
              <div key={`${field.id}-${propertyName}`} className="form-group form-group--inline">
                <label className="form-group--inline__label">{labelForStyle[propertyName]}</label>
                {this.renderStyle(propertyName)}
              </div>
            )
          })}
      </div>
    )
  }
}

export default StyleEditor
