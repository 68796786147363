// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { AppMenu } from 'components/app/app-menu'
import { SettingsEmail } from 'components/app/push/settings-email'
import { Banner } from 'components/common/banner'

import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { AudienceList } from 'com.batch/audience/ui/pages/audience-list'
import { CappingRules } from 'com.batch/capping/ui/pages/capping-rules'
import { LabelsList } from 'com.batch/labels/ui/pages/labels-list'
import { Profile } from 'com.batch/profile/ui/pages/profile'
import { SavedSegmentList } from 'com.batch/saved-segment/ui/pages/saved-segment-list'

type EmailSettingsWrapperProps = { setting: string, ... }

export const EmailSettingsWrapper = ({ setting }: EmailSettingsWrapperProps): React.Node => {
  const apps = useSelector(currentProjectAppsSelector)
  const firstApp = React.useMemo(() => apps.first(), [apps])
  const user = useSelector(currentUserSelector)

  const page = React.useMemo(() => {
    switch (setting) {
      case 'email':
        return <SettingsEmail />
      case 'profiles':
        return <Profile />
      case 'audiences':
        return <AudienceList />
      case 'segments':
        return <SavedSegmentList />
      case 'labels':
        return <LabelsList />
      case 'cappings':
        return <CappingRules />
      default:
        return null
    }
  }, [setting])

  return (
    <React.Fragment>
      {!user.permissionsForCurrentCompany.has('group:app') && (
        <Banner
          intent="limited"
          icon="view"
          title="You are on read-only mode, some features may be unavailable."
        />
      )}
      <AppMenu settingsMenuApp={firstApp} user={user} channel="email">
        {page}
      </AppMenu>
    </React.Fragment>
  )
}
