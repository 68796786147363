// @flow
import styled, { css, type StyledComponent } from 'styled-components'

type BarChartSvgProps = {
  $marginTop?: number,
  ...
}
export const BarChartSvg: StyledComponent<BarChartSvgProps, *, Element> = styled.svg`
  overflow: visible !important;

  ${(props: BarChartSvgProps) =>
    props.$marginTop &&
    css`
      margin-top: ${props.$marginTop}px;
    `}
`
