// @flow
import { type Map } from 'immutable'

import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'

import { type EmailContentRecord } from 'com.batch/message/models/message.records'

export const removeDeletedSenderIdentitiesFromEmailContent = (
  email: Map<string, Map<string, EmailContentRecord>>,
  senderIdentities: Map<number, SenderIdentityRecord>
): [Array<string>, Map<string, Map<string, EmailContentRecord>>] => {
  let messageIds = []
  const cleanedEmailMap = email.map((languageMap, messsageTypedId) =>
    languageMap.map(mail => {
      if (!mail.senderIdentityId || senderIdentities.has(mail.senderIdentityId)) {
        return mail
      } else {
        messageIds.push(messsageTypedId)
        return mail.set('senderIdentityId', undefined)
      }
    })
  )
  return [messageIds, cleanedEmailMap]
}
