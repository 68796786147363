// @flow

import { type Map, type List } from 'immutable'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'

type FetchCampaignsDataActions = ReduxAction<'FETCH_CAMPAIGNS_LIST_DATA', List<string>>

export type FetchCampaignsDataSuccessAction = ReduxAction<
  'FETCH_CAMPAIGNS_LIST_DATA_SUCCESS',
  Map<string, OrchestrationListStatsStateRecord>,
>
export type FetchCampaignsDataFailureAction = ReduxAction<
  'FETCH_CAMPAIGNS_LIST_DATA_FAILURE',
  Map<string, OrchestrationListStatsStateRecord>,
>

export type fetchCampaignsDataActions =
  | FetchCampaignsDataActions
  | FetchCampaignsDataSuccessAction
  | FetchCampaignsDataFailureAction

export const fetchCampaignsData = (
  tokens: List<string>
): DispatchExtraBoundFn<Promise<Map<string, OrchestrationListStatsStateRecord>>> => {
  return (dispatch, getState, { dataService }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: dataService.fetchCampaignsData({ project, tokens }),
      actionName: 'FETCH_CAMPAIGNS_LIST_DATA',
      payload: tokens,
    })
  }
}
