// @flow
import { type Map } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { EmptyField, Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { InstallFactory, type InstallRecord } from 'com.batch/profile/models/profile.records'
import { PlatformButton } from 'com.batch/profile/ui/components/profile-hero/platform-button/platform-button'
import { PlatformButtonSkeleton } from 'com.batch/profile/ui/components/profile-hero/platform-button/platform-button.styles'
import { PlatformModal } from 'com.batch/profile/ui/components/profile-hero/platform-modal/platform-modal'
import {
  ProfileBodyTitle,
  ProfilePlatformContainer,
  PlatformsContainer,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  installs: Map<string, InstallRecord>,
  isLoading: boolean,
  ...
}

export const ProfilePlatforms = ({ installs, isLoading }: Props): React.Node => {
  const apps = useSelector(currentProjectAppsSelector)
  const project = useSelector(currentProjectSelector)

  const [selectedInstallId, setSelectedInstallId] = React.useState<string>('')

  const generateDebugUrl = React.useCallback(
    currentInstall =>
      currentInstall.installID
        ? generateUrl('app_settings', {
            appId: apps.find(app => app.platform === currentInstall.platform.toLowerCase()).id,
            companyId: project.companyId,
            activeTab: 'debug',
            installation_id: currentInstall.installID,
          })
        : '',
    [apps, project.companyId]
  )
  const onModalOpened = React.useCallback(
    (install: InstallRecord) => () => setSelectedInstallId(install.installID),
    []
  )
  const onModalClosed = React.useCallback(() => setSelectedInstallId(''), [])

  const installOverview = React.useMemo(() => {
    return installs.get(selectedInstallId, InstallFactory())
  }, [installs, selectedInstallId])

  const platformsRender = React.useMemo(() => {
    if (isLoading) {
      return (
        <React.Fragment>
          {Array.from({ length: installs.size > 0 ? installs.size : 1 }).map((_, i) => (
            <PlatformButtonSkeleton key={`empty_platform-${i}`}>
              <EmptyField _height={16} _width={16} style={{ marginRight: 8 }} />
              <EmptyField _height={16} _width={96} />
              <EmptyField _height={16} _width={200} _display="block" style={{ marginTop: 10 }} />
            </PlatformButtonSkeleton>
          ))}
        </React.Fragment>
      )
    }

    if (installs.size > 0) {
      return (
        <React.Fragment>
          {installs
            .toArray()
            .sort(([, a], [, b]) => {
              const dateA = dayjs(a.lastActivity)
              const dateB = dayjs(b.lastActivity)
              if (dateA.isValid() && dateB.isValid() && dateA.isBefore(dateB)) return 1
              return -1
            })
            .map(([id, install]) => {
              const props =
                install.platform === 'WEBPUSH'
                  ? { href: generateDebugUrl(install) }
                  : { onClick: onModalOpened(install) }
              return <PlatformButton {...props} key={id} install={install} />
            })}
        </React.Fragment>
      )
    }

    return <p>No platforms attached to this profile.</p>
  }, [isLoading, installs, onModalOpened, generateDebugUrl])

  return (
    <React.Fragment>
      <ProfilePlatformContainer>
        <ProfileBodyTitle>
          <Skeleton w={22} h={22} style={{ marginRight: 8 }}>
            <Icon icon="mobile" />
          </Skeleton>
          <Skeleton w={84} h={22} style={{ marginRight: 8 }}>
            <h2>Platforms</h2>
          </Skeleton>
          <Skeleton w={16} h={22}>
            {installs.size > 0 && <span>{installs.size}</span>}
          </Skeleton>
        </ProfileBodyTitle>
        <PlatformsContainer>{platformsRender}</PlatformsContainer>
      </ProfilePlatformContainer>
      <PlatformModal
        install={installOverview}
        debugHref={generateDebugUrl(installOverview)}
        opened={!!installOverview.installID}
        close={onModalClosed}
      />
    </React.Fragment>
  )
}
