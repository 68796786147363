// @flow

import Immutable from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type DataService, type FetchDynamicStatsArgs } from './data-service.interface'
import {
  type GetOrchestrationEmailClicksByUrlBulkResponse,
  type GetBounceByDaysResponse,
  type GetOrchestrationFullStatsBulkResponse,
  type GetOrchestrationStatsBulkResponse,
  type GetOrchestrationStatsDynamicDimensionBulkResponse,
  type FetchMonthlySentResponse,
} from './types/grpc-stats-service'

import { parseMonthlySentResponse } from 'com.batch/account/infra/parses/parse-monthly-sent'
import { parseBaseStats } from 'com.batch/orchestration-analytics/infra/parses/parse-base-stats'
import { parseBounceByDays } from 'com.batch/orchestration-analytics/infra/parses/parse-bounce-by-days'
import { parseClickLinks } from 'com.batch/orchestration-analytics/infra/parses/parse-click-links'
import { parseEmailStats } from 'com.batch/orchestration-analytics/infra/parses/parse-email-stats'
import { OrchestrationStatsFactory } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { parseOrchestrationStatsToListStatsState } from 'com.batch/orchestration-list/infra/parses/parse-orchestration-stats-to-list-stats-state'
import { OrchestrationListStatsStateFactory } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'

export const dataService: DataService = {
  fetchCampaignsData: async ({ project, tokens }) => {
    try {
      const response = await request.post<GetOrchestrationStatsBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationStats',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: tokens,
        }
      )

      return Immutable.Map(
        tokens.map(token => {
          const match = response.stats?.find(raw => raw.base.token === token)

          return [
            token,
            match
              ? parseOrchestrationStatsToListStatsState(match.base)
              : OrchestrationListStatsStateFactory({ loadingState: 'LOADED' }),
          ]
        })
      )
    } catch (err) {
      throw Immutable.Map(
        tokens.map(token => {
          return [token, OrchestrationListStatsStateFactory({ loadingState: 'ERROR' })]
        })
      )
    }
  },
  fetchOrchestrationData: async ({ project, token }) => {
    try {
      const response = await request.post<GetOrchestrationFullStatsBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationFullStats',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: [token],
        }
      )

      const match = response.orchestrationFullStats?.find(raw => raw.base.token === token)

      if (match) {
        return OrchestrationStatsFactory({
          base: parseBaseStats(match.base),
          ...match.channelDetails.reduce((channels, cur) => {
            switch (cur.channel) {
              case 'EMAIL': {
                const { base, bounceDetails, mailboxProviderDetails } = cur.email
                return {
                  ...channels,
                  email: parseEmailStats({
                    base: base,
                    bounceDetails,
                    mailboxProviderDetails,
                  }),
                }
              }
              case 'SMS':
                return {
                  ...channels,
                  sms: parseBaseStats(cur.sms.base),
                }
              case 'PUSH':
                return {
                  ...channels,
                  push: parseBaseStats(cur.push.base),
                }
            }
          }, {}),
        })
      } else {
        return null
      }
    } catch (error) {
      throw new Error(`Error while loading orchestration ${token} stats: ${error.message}`)
    }
  },
  fetchBounceByDays: async ({ project, token, dateFrom, dateTo }) => {
    try {
      const response = await request.post<GetBounceByDaysResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationBouncesDetailsByDays',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: [token],
          dateFrom: dateFrom.format('YYYY-MM-DD'),
          dateTo: dateTo.format('YYYY-MM-DD'),
        }
      )
      return parseBounceByDays(response?.bounceDetails ?? [])
    } catch (error) {
      throw new Error(`Error while loading orchestration ${token} stats: ${error.message}`)
    }
  },
  fetchDynamicStats: async <T>({
    project,
    token,
    groupDimensionNames,
    includeFilterDimensions,
    parser,
  }: FetchDynamicStatsArgs<T>): Promise<T> => {
    try {
      const response = await request.post<GetOrchestrationStatsDynamicDimensionBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationStatsDynamicDimension',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: [token],
          groupDimensionNames,
          includeFilterDimensions,
        }
      )
      return parser(response.stats)
    } catch (error) {
      throw new Error(
        `Error while loading orchestration ${token} dynamic dimension: ${error.message}`
      )
    }
  },
  fetchClicksByUrl: async ({ project, token }) => {
    try {
      const response = await request.post<GetOrchestrationEmailClicksByUrlBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationEmailClicksByUrl',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: [token],
          urlGroupingMode: 'SMART_WITH_LINK_NAME',
          groupLimit: 50,
        }
      )
      const match = response.orchestrationEmailClickDetails?.find(raw => raw.token === token)
      return parseClickLinks({ clickDetails: match?.clickDetail })
    } catch (error) {
      throw new Error(`Error while loading orchestration ${token} click details: ${error.message}`)
    }
  },
  getMonthlySentForBilling: async ({ projectKeys, month }) => {
    try {
      const response = await request.post<FetchMonthlySentResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetMonthlySentForBilling',
        }),
        {
          projectKeys: projectKeys.map(p => ({
            textual: {
              text: p,
            },
          })),
          month,
        }
      )
      return parseMonthlySentResponse(response)
    } catch (error) {
      throw new Error(`Error : ${error.message}`)
    }
  },
}
