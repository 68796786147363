// @flow

import * as React from 'react'
import { Route } from 'react-router-dom'

import { AnalyticsMenu } from 'components/analytics/analytics-menu'
import { CreateAppRoute } from 'components/app/create/create-app-route'
import Integrate from 'components/app/integrate/integrate'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { NavbarConnected } from 'components/project/navigation/navbar-connected'
import { ProjectRouterWrapper } from 'components/project/project-router-wrapper'
import { Content } from 'components/styled/blocs'

import { Analytics } from './analytics'
import { Notification } from './notification'
import { Reach } from './reach'

import { Profilebase } from 'com.batch/profilebase/ui/pages/profilebase'
import { CertificateBanner } from 'com.batch/shared/ui/component/certificate-banner/certificate-banner'

const AnalyticsRouter = () => {
  return (
    <React.Fragment>
      <NavbarConnected />
      <Content top>
        <CreateAppRoute />
        <Integrate />
        <ProjectRouterWrapper>
          <Route
            path="apps/:appId/analytics"
            element={
              <PageTracker category={trackingCategories.data} name={trackingPages.audience}>
                <CertificateBanner />
                <AnalyticsMenu />
                <Analytics />
              </PageTracker>
            }
          />
          <Route
            path="apps/:appId/analytics/reach"
            element={
              <PageTracker category={trackingCategories.data} name={trackingPages.reach}>
                <CertificateBanner />
                <AnalyticsMenu />
                <Reach />
              </PageTracker>
            }
          />
          <Route
            path="apps/:appId/analytics/push/:pushAnalyticsTab?"
            element={
              <PageTracker category={trackingCategories.data} name={trackingPages.pushOverview}>
                <CertificateBanner />
                <AnalyticsMenu />
                <Notification />
              </PageTracker>
            }
          />
          <Route
            path="analytics"
            element={
              <PageTracker category={trackingCategories.data} name={trackingPages.profilebase}>
                <Profilebase />
              </PageTracker>
            }
          />
        </ProjectRouterWrapper>
      </Content>
    </React.Fragment>
  )
}
// $FlowExpectedError
const HotAnalyticsRouter = AnalyticsRouter

export default (HotAnalyticsRouter: React.AbstractComponent<{ ... }>)
