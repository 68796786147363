// @flow
import styled, { type StyledComponent } from 'styled-components'

export const NodePreviewContainer: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  background-color: #f8f8f8;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMiIgaGVpZ2h0PSIyIiByeD0iMSIgZmlsbD0icmdiYSgyMzAsIDIzMCwgMjMwLCAxLjAwMCkiIC8+PC9zdmc+');
  background-repeat: repeat;
  background-size: 18px 18px;
  background-attachment: fixed;
  padding: 16px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const OptionWrapper: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  margin-top: 4px;
`
