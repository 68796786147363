/* eslint-disable react/jsx-no-bind */
// @flow

import { useCombobox, useMultipleSelection } from 'downshift'
import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { usePopper, useToggle, useWidthObserver } from 'components/_hooks'
import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'
import { WrapperSearchInput, SelectSearchInput } from 'components/filter/filter-search.styles'
import { SelectDropdown } from 'components/form/fields/select/select-dropdown'
import {
  selectPopperConfig,
  type SelectMenuPropsType,
} from 'components/form/fields/select/select.helper'

import { type FilterSelectMultiProps } from './filter-select-multi'

export function FilterSelectMultiSearch<T>({
  options,
  onChange,
  optionToString,
  value,
  optionMenuStyle,
  style,
  placeholder = 'Filter by',
  noResultsNode = 'No options found',
  term,
  optionMenuHeight,
  optionMenuShownCount,
  optionFormatter,
  menuOffset = 42,
  isDisabled,
}: FilterSelectMultiProps<T>): React.Node {
  const [isExpanded, setExpanded] = React.useState(false)
  const [showDropdown, setShowDropdown] = React.useState(false)
  const [localOptions, setLocalOptions] = React.useState<List<T>>(options)
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)

  React.useEffect(() => {
    if (value.size !== 0) {
      setExpanded(true)
    } else {
      if (!showDropdown) setExpanded(false)
    }
    return () => {}
  }, [value, showDropdown])

  React.useEffect(() => {
    if (options.size !== 0) {
      setLocalOptions(options)
    }
  }, [options])

  const items = React.useMemo(() => localOptions.toArray(), [localOptions])
  const focusedState = useToggle()

  const { addSelectedItem, removeSelectedItem, getDropdownProps, reset } = useMultipleSelection({
    initialSelectedItems: value.toArray(),
    onSelectedItemsChange: ({ selectedItems }) => {
      onChange(new Immutable.List().push(...selectedItems))
      popperInstance?.forceUpdate()
    },
  })

  // Filtering options by typing in the Search
  const updateLocalOptions = React.useCallback(
    (inputValue: string) => {
      setLocalOptions(
        options.filter(
          option =>
            !inputValue || optionToString(option).toLowerCase().startsWith(inputValue.toLowerCase())
        )
      )
    },
    [optionToString, options]
  )

  const {
    isOpen,
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    closeMenu,
    getInputProps,
    setHighlightedIndex,
    openMenu,
  } = useCombobox({
    items,
    selectedItem: null,
    itemToString: optionToString,
    defaultInputValue: '',
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          updateLocalOptions('')
          return {
            ...changes,
            inputValue: '',
            highlightedIndex: localOptions.findIndex(value => value === changes.selectedItem) ?? 0,
            isOpen:
              !!changes.selectedItem ||
              (type !== useCombobox.stateChangeTypes.InputBlur && state.isOpen),
          }
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          updateLocalOptions('')
          return { ...changes, inputValue: '', isOpen: state.isOpen }
      }
      return changes
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          updateLocalOptions(inputValue)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem && !value.includes(selectedItem)) {
            addSelectedItem(selectedItem)
          } else {
            removeSelectedItem(selectedItem)
            // on first remove from dropdown menu, input loses focus, dunno why. ugly hack to please design team.
            setTimeout(() => inputRef.current?.focus(), 0)
          }
          popperInstance?.forceUpdate()
          break
        default:
          break
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen && value.size === 0) {
        setShowDropdown(false)
        setExpanded(false)
        focusedState.close()
      }
      if (isOpen) {
        setExpanded(true)

        // Displays the popper after the expanded animation
        setTimeout(() => {
          popperInstance?.forceUpdate()
          setShowDropdown(true)
        }, 180)
      }
    },
    onInputValueChange: changes => {
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        setLocalOptions(
          (options ? options : new Immutable.List()).filter(option =>
            optionToString(option).toLowerCase().startsWith(changes.inputValue.toLowerCase())
          )
        )
      }
    },
  })

  // Handle Popper Ref menu
  const inputRef = React.useRef()
  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })
  const toggleButtonProps = getToggleButtonProps({
    style: {
      ...style,
      textAlign: 'left',
      padding: '0 0 0 10px',
      width: isExpanded ? 170 : 120,
    },
    ref: triggerRef,
    autoFocus: false,
    onFocus: () => {
      setExpanded(true)
    },
  })

  const inputProps = getDropdownProps(
    getInputProps({
      ref: inputRef,
      onFocus: () => {
        inputRef.current?.select()
        focusedState.open()
        setExpanded(true)
        if (!isOpen) {
          popperInstance?.update()
          openMenu()
        }
      },
      onBlur: () => {
        setExpanded(value.size !== 0)
      },
    })
  )
  const width = useWidthObserver(triggerRef, 200)
  return (
    <React.Fragment>
      {value.size > 0 ? (
        <Button
          addOn="suffix"
          addOnGap={22}
          kind="inline"
          style={{ width: 180, textAlign: 'left' }}
          {...toggleButtonProps}
          isActive={isOpen || value}
          onClick={evt => {
            evt.preventDefault()
            openMenu()
          }}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 0,
            }}
          >
            <Icon icon="filter" style={{ marginRight: 10 }} />
            <span
              style={{
                minWidth: 0,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {term ? term(value.size) : `${value.size} value${value.size > 1 ? 's' : ''}`}
            </span>
          </span>

          <Button
            kind="discreet"
            onClick={evt => {
              evt?.stopPropagation()
              reset()
              closeMenu()
              setExpanded(false)
            }}
          >
            <Icon icon="close" color="currentColor" />
          </Button>
        </Button>
      ) : (
        <Button
          style={{ width: 80 }}
          onClick={openMenu}
          addOn="suffix"
          kind="inline"
          isActive={isOpen}
          disabled={Boolean(isDisabled)}
          {...toggleButtonProps}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            {placeholder}
          </span>
        </Button>
      )}
      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        getItemProps={getItemProps}
        optionMenuStyle={optionMenuStyle}
        optionToString={optionToString}
        optionMenuHeight={optionMenuHeight}
        optionMenuShownCount={optionMenuShownCount}
        noResultNode={localOptions.size === 0 ? noResultsNode : undefined}
        showCheckbox
        inMenuSearchInput={
          <WrapperSearchInput>
            <div className="styled-input-addons styled-addon-pre">
              <Icon icon="search" size={14} color="currentColor" thickness={1.3} />
            </div>
            <SelectSearchInput {...inputProps} placeholder="Search..." />
          </WrapperSearchInput>
        }
        optionFormatter={optionFormatter}
        options={localOptions}
        isOpen={showDropdown && isOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={(option: T) => value.includes(option)}
      />
    </React.Fragment>
  )
}
