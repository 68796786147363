// @flow
import Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateTypedId } from 'com.batch.common/typed-id'

import { MessageStateFactory } from 'com.batch/message/store/message.state'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'

import { parseMessagesForCampaign } from 'com.batch/message/infra/parses/messages.parse'
import { consistentLocaleCase } from 'com.batch/orchestration/infra/parses/consistent-locale-case'
import { type OrchestrationCampaignParserResult } from 'com.batch/orchestration/infra/parses/orchestration.parse'
import {
  type OrchestrationChannels,
  type DashboardConfig,
} from 'com.batch/orchestration/infra/types/orchestration.types'
import {
  type OrchestrationCampaign,
  type EmailContentForCampaign,
} from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import {
  OrchestrationCampaignFactory,
  OneTimeFactory,
  RecurrentFactory,
} from 'com.batch/orchestration-campaign/models/campaign.records'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

type OrchestrationCampaignParser = ({
  campaign: OrchestrationCampaign,
  messages?: {
    [string]:
      | { emailChannel: EmailContentForCampaign }
      | { smsMessage: SmsContent }
      | { pushMessage: OrchestrationPush },
  },
  config: DashboardConfig,
  state: campaignStateType,
  channels: ?OrchestrationChannels,
  id: string,
  name: string,
  labelCodes: Array<string>,
}) => OrchestrationCampaignParserResult

export const parseOrchestrationCampaign: OrchestrationCampaignParser = ({
  campaign,
  messages,
  config,
  state,
  channels,
  id,
  name,
  labelCodes,
}) => {
  let queries = []

  if (campaign.content.query)
    queries.push({
      queryId: 'targeting',
      query: typeof campaign.content.query === 'string' ? campaign.content.query : '',
      eventId: '',
      retries: 0,
    })

  // incomplete campaign might not have a typedId so generate one for them
  let firstMessageId = generateTypedId('message')
  let subscriptionStatus = 'marketing'
  let message = MessageStateFactory()
  if (messages) {
    const parsed = parseMessagesForCampaign({
      messages,
      config,
    })
    message = parsed.message
    subscriptionStatus = parsed.subscriptionStatus
    firstMessageId = parsed.messageTypedId
  }
  const frequencyUnit = campaign.scheduling.recurrent?.recurrence ?? 'WEEKLY'
  if (frequencyUnit === 'HOURLY') {
    throw new Error('Dashboard does not accept hourly recurrent campaigns')
  }
  return {
    campaign: OrchestrationCampaignFactory({
      messageTypedId: firstMessageId,
      sendType: campaign.scheduling.recurrent ? 'recurring' : 'scheduled',
      tzAware: Boolean(campaign.scheduling.localTimezoneDependant),
      oneTime: OneTimeFactory({
        sendDate: campaign.scheduling.oneTime?.pushDate
          ? dayjs.utc(campaign.scheduling.oneTime?.pushDate, 'YYYYMMDDHHmmss')
          : null,
      }),
      recurrent: RecurrentFactory({
        startDate: campaign.scheduling.recurrent?.startDate
          ? dayjs.utc(campaign.scheduling.recurrent?.startDate, 'YYYYMMDDHHmmss')
          : null,
        endDate: campaign.scheduling.recurrent?.endDate
          ? dayjs.utc(campaign.scheduling.recurrent?.endDate, 'YYYYMMDDHHmmss')
          : null,
        hasCapping: Boolean(campaign.content.capping),
        capping: campaign.content.capping ? campaign.content.capping : 1,
        frequency: campaign.scheduling.recurrent?.frequency ?? 1,
        recurrence: frequencyUnit,
      }),
      channel: channels?.includes('EMAIL') ? 'email' : channels?.includes('PUSH') ? 'push' : 'sms',
    }),
    message,
    state,
    channels: Immutable.Set([
      ...(channels?.includes('EMAIL') ? ['email'] : []),
      ...(channels?.includes('SMS') ? ['sms'] : []),
      ...(channels?.includes('PUSH') ? ['push'] : []),
    ]),
    id,
    name,
    labelCodes: Immutable.Set(labelCodes),
    incomplete: config?.incomplete ?? false,
    queries,
    targets: Immutable.Map([
      [
        'default',
        TargetStateFactory({
          languages: Immutable.Set(campaign.content.targeting?.languages ?? []).map(
            consistentLocaleCase
          ),
          regions: Immutable.Set(campaign.content.targeting?.regions ?? []),
          subscriptionStatus,
        }),
      ],
    ]),
  }
}
