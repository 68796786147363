// @flow

import { scaleBand } from 'd3-scale'
import * as React from 'react'

import { textDisabled } from 'components/styled/tokens/colors'

import { TimelineItemSkeleton } from 'com.batch/shared/ui/component/charts/plot-chart/plot-bar.styles'

type Props = {
  label: string,
  labelsSize: number,
  xScale: typeof scaleBand,
  y: number,
  index: number,
  total: number,
  chartWidth: number,
  barWidth: number,
  incomplete: boolean,
  isLoading: boolean,
  ...
}

export const TimelineItem = ({
  xScale,
  label,
  labelsSize,
  y,
  index,
  total,
  chartWidth,
  barWidth,
  incomplete,
  isLoading,
}: Props): React.Node => {
  const padding = 8
  // no svg element type in flow
  const ref = React.useRef<any>(null)
  const [width, setWidth] = React.useState(0)
  const isFirst = React.useMemo(() => index === 0, [index])
  const isLast = React.useMemo(() => index === total - 1, [index, total])
  const xPos = React.useMemo(() => {
    if (xScale(label) === undefined) return 0
    if (isFirst && total > 1) return padding
    if (isLast && !incomplete) return chartWidth - width - padding
    return xScale(label) + (xScale.bandwidth() / 2 - barWidth / 2)
  }, [xScale, label, isFirst, total, padding, isLast, incomplete, chartWidth, width, barWidth])

  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.getBBox().width)
    }
  }, [])

  return isLoading ? (
    <TimelineItemSkeleton ref={ref} width="44" height="16" rx="4" x={xPos} y={y - 10} />
  ) : (
    <text x={xPos} y={y} fill={textDisabled} ref={ref} style={{ fontSize: labelsSize }}>
      {label}
    </text>
  )
}
