// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'
import request from 'superagent-interface-promise'

import { Button } from 'components/common/button'
import { Form, InputWrapper, Input, Feedback } from 'components/form'
import { LinkArrow } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'
import { isEmail } from 'com.batch.common/utils'

import { AuthPage, AuthStep, AuthStepFooter, Title, Text } from '../auth.styles'

// $FlowFixMe
import Illustration from 'illustrations/register_confirm_illustration.svg'

const requestPasswordApi = (email: string) => {
  const url = generateUrl('api_password_reset_request')
  return request.post(url, { email })
}

export const PasswordRecovery = (): React.Node => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [stepCheckMail, setStepCheckMail] = React.useState<boolean>(false)
  const [invalid, setInvalid] = React.useState<boolean>(false)
  const [feedback, setFeedback] = React.useState<?string>(null)
  const [email, setEmail] = React.useState('')

  const handleOnSubmit = React.useCallback(() => {
    setFeedback(null)
    if (!isEmail(email)) {
      setInvalid(true)
    } else {
      setLoading(true)
      requestPasswordApi(email).then(
        () => {
          setLoading(false)
          setStepCheckMail(true)
          setFeedback(null)
        },
        ({ body }) => {
          setLoading(false)
          setFeedback(body.errors[0].message)
        }
      )
    }
  }, [email])

  const handleOnClickRequest = React.useCallback(() => {
    setStepCheckMail(false)
    setEmail('')
  }, [])

  const handleOnEmailChange = React.useCallback(evt => setEmail(evt.target.value), [])

  return (
    <PasswordRecoveryContainer>
      <AuthStep>
        <Title>{stepCheckMail ? 'Check your emails' : 'Forgot password'}</Title>
        {stepCheckMail ? (
          <Text>
            If there is an account for <strong>{email}</strong>, you should receive a link to reset
            your password shortly.
          </Text>
        ) : (
          <Text>
            If you've forgotten your password, you can request a new one here. Enter your account
            email to continue.
          </Text>
        )}
        {stepCheckMail ? (
          <div style={{ marginTop: 50 }}>
            <Illustration />
            <LinkArrow onClick={handleOnClickRequest}>
              Didn't receive the email? Request a new one
            </LinkArrow>
          </div>
        ) : (
          <Form onSubmit={handleOnSubmit}>
            <InputWrapper
              feedback={invalid ? 'Please enter an email address' : null}
              invalid={invalid}
              additional={feedback && <Feedback message={feedback} kind="error" />}
            >
              <Input
                type="email"
                autoFocus
                placeholder="Email address"
                value={email}
                onChange={handleOnEmailChange}
              />
            </InputWrapper>
            <AuthStepFooter>
              <Button kind="primary" isLoading={loading} intent="action" type="submit">
                Recover
              </Button>
            </AuthStepFooter>
          </Form>
        )}
      </AuthStep>
    </PasswordRecoveryContainer>
  )
}

export const PasswordRecoveryContainer: StyledComponent<*, *, typeof AuthPage> = styled(AuthPage)`
  ${AuthStep} {
    max-width: 360px;
  }

  ${Title} {
    margin-top: 3px;
    margin-bottom: 17px;
    line-height: 1.1;
  }
`

export default PasswordRecovery
