// @flow

import Immutable from 'immutable'
import * as React from 'react'

import { AutoCompleteMulti } from 'components/form'

import { requiredStringList } from 'com.batch.common/validators'

import { useSearchOptions, optionFormatter, type InputProps } from './helper'

const identity = (text: string) => text
const optionToString = (option: ?string) => option ?? ''
export const InputStringList = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.Node => {
  const attributeId = React.useMemo(
    () => condition?.attribute?.api ?? '',
    [condition?.attribute?.api]
  )
  const isTag = condition?.attribute?.type === 'TAG'
  const loadOptions = useSearchOptions(attributeId, isTag)

  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)

  const onChangeLocal = React.useCallback(
    value => {
      setInvalid(!requiredStringList(value))
      updateCondition(
        condition.set(
          'value',
          condition.value.set('stringList', !value ? new Immutable.List() : value)
        )
      )
    },
    [condition, updateCondition]
  )

  const onBlur = React.useCallback(() => {
    setIsTouched(true)
  }, [])

  return (
    <AutoCompleteMulti
      key={attributeId}
      optionFormatter={optionFormatter}
      style={{ minWidth: 150 }}
      onBlur={onBlur}
      isClearable
      loadOptions={loadOptions}
      invalid={(invalid && isTouched) || isInvalid}
      value={condition.get('value').stringList}
      optionCreator={identity}
      optionToString={optionToString}
      onChange={onChangeLocal}
    />
  )
}
