// @flow
import Immutable from 'immutable'

import { MessageStateFactory, type MessageStateRecord } from './message.state'

import {
  EmailContentFactory,
  PushContentFactory,
  SmsContentFactory,
} from '../models/message.records'
import { type RemoveEmailContentAction } from '../usecases/remove-email-content'
import { type FetchSenderIdentitiesSuccessAction } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { removeDeletedSenderIdentitiesFromEmailContent } from 'com.batch.redux/corelogic/usecases/sender-identity/remove-deleted-sender-identities-from-email-content'

import {
  type EmptyAllEmailSendersAction,
  type saveDragDropHtmlActions,
  type saveDragDropOptimizedTemplateActions,
  type UpdateEmailInfoAction,
  type UpdateEmailSenderAction,
} from 'com.batch/email/usecases/update-content'
import { type UpdateEmailContentAction } from 'com.batch/email/usecases/update-email-content'
import { type UpdateEmailTemplateAction } from 'com.batch/email/usecases/update-email-template'
import { type SetIsEmailUploadingAction } from 'com.batch/email/usecases/upload-email'
import { type UpdateAllContentAction } from 'com.batch/message/usecases/update-all-content'
import { type InitFormAction } from 'com.batch/orchestration/usecases/init-form'
import {
  type UpdatePushContentRecordAction,
  type UpdatePushMessageContentAction,
} from 'com.batch/push/usecases/update-push-content'
import { type UpdatePushTemplateAction } from 'com.batch/push/usecases/update-push-template'
import { type UpdateSmsContentAction } from 'com.batch/sms/usecases/update-sms-content'
import { type UpdateSmsTemplateAction } from 'com.batch/sms/usecases/update-sms-template'
import { STATUS } from 'constants/common'

const messageUpdated = (id: string, state: MessageStateRecord): MessageStateRecord =>
  state.set('updatedMessageIds', state.updatedMessageIds.add(id))

type MessageActions =
  | UpdateEmailContentAction
  | UpdateEmailInfoAction
  | UpdateSmsContentAction
  | UpdateSmsTemplateAction
  | UpdateEmailTemplateAction
  | UpdateEmailSenderAction
  | EmptyAllEmailSendersAction
  | UpdateAllContentAction
  | InitFormAction
  | SetIsEmailUploadingAction
  | saveDragDropHtmlActions
  | saveDragDropOptimizedTemplateActions
  | FetchSenderIdentitiesSuccessAction
  | RemoveEmailContentAction
  | UpdatePushContentRecordAction
  | UpdatePushMessageContentAction
  | UpdatePushTemplateAction
export const messageReducer = (
  state: MessageStateRecord = MessageStateFactory(),
  action: MessageActions
): MessageStateRecord => {
  switch (action.type) {
    case 'INIT_FORM':
      return MessageStateFactory()
    case 'UPDATE_ALL_CONTENT':
      return state
        .set('email', action.payload.email)
        .set('sms', action.payload.sms)
        .set('push', action.payload.push)
    case 'UPDATE_SMS_CONTENT':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(['sms', action.payload.messageId, action.payload.lang], smsContent => {
          return (smsContent || SmsContentFactory()).set(action.payload.field, action.payload.value)
        })
      )
    case 'UPDATE_SMS_TEMPLATE':
      return state.updateIn(['sms', action.payload.messageId, action.payload.lang], smsContent => {
        return (smsContent || SmsContentFactory()).setIn(
          ['templates', 'smsMessage'],
          action.payload.template
        )
      })
    case 'UPDATE_EMAIL_SENDER':
      if (!state.email.has(action.payload.messageId)) {
        return state.setIn(
          ['email', action.payload.messageId],
          Immutable.Map([
            [
              'default',
              EmailContentFactory({
                senderIdentityId: action.payload.senderIdentityId,
                fromEmail: action.payload.fromEmail,
                name: action.payload.name,
                isPristine: false,
              }),
            ],
          ])
        )
      }
      return messageUpdated(
        action.payload.messageId,
        state.set(
          'email',
          state.email.map((content, messageId) => {
            if (messageId === action.payload.messageId) {
              return content.map(email =>
                email
                  .set('senderIdentityId', action.payload.senderIdentityId)
                  .set('fromEmail', action.payload.fromEmail)
                  .set('name', action.payload.name)
                  .set('isPristine', false)
              )
            }
            return content
          })
        )
      )
    case 'FETCH_SENDER_IDENTITIES_SUCCESS': {
      /*
        This is only usefull when we fetch orchestration BEFORE sender ids resolve.
        it's not perfect : we won't show toast or update incomplete flags on triggers nodes, but
        deleted sender ids is not really an issue and the XHR call seems to always resolve before
      */

      const [updated, email] = removeDeletedSenderIdentitiesFromEmailContent(
        state.email,
        action.payload
      )
      if (updated.length === 0) return state
      return state.set('email', email)
    }
    case 'UPDATE_EMAIL_INFO':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(['email', action.payload.messageId, action.payload.lang], email => {
          return (email || EmailContentFactory())
            .set(action.payload.field, action.payload.value)
            .set('isPristine', false)
        })
      )
    case 'UPDATE_EMAIL_CONTENT':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(['email', action.payload.messageId, action.payload.lang], email => {
          let updatedContent = (email || EmailContentFactory())
            .set(action.payload.field, action.payload.value)
            .set('isPristine', false)

          if (action.payload.htmlEditorConfig) {
            updatedContent = updatedContent.set('htmlEditorConfig', action.payload.htmlEditorConfig)
          }

          return updatedContent
        })
      )
    case 'UPDATE_EMAIL_TEMPLATE':
      return state.updateIn(['email', action.payload.messageId, action.payload.lang], email => {
        let updatedContent = email || EmailContentFactory()
        if (action.payload.html !== undefined) {
          updatedContent = updatedContent.setIn(['templates', 'html'], action.payload.html)
        }
        if (action.payload.subject !== undefined) {
          updatedContent = updatedContent.setIn(['templates', 'subject'], action.payload.subject)
        }
        return updatedContent
      })
    case 'SET_IS_EMAIL_UPLOADING':
      return state.setIn(
        ['email', action.payload.messageId, action.payload.lang],
        state.email
          .getIn([action.payload.messageId, action.payload.lang], EmailContentFactory())
          .set('isEmailUploading', action.payload.isEmailUploading)
      )
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT':
      return state.setIn(
        ['email', action.payload.messageId, action.payload.lang],
        state.email
          .getIn([action.payload.messageId, action.payload.lang], EmailContentFactory())
          .set('loadingState', STATUS.LOADING)
      )
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_SUCCESS':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_SUCCESS':
      return state.setIn(
        ['email', action.payload.messageId, action.payload.lang],
        state.email
          .getIn([action.payload.messageId, action.payload.lang], EmailContentFactory())
          .set('loadingState', STATUS.LOADED)
          .set('isPristine', false)
      )
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_FAILURE':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_FAILURE':
      return state.setIn(
        ['email', action.payload.messageId, action.payload.lang],
        state.email
          .getIn([action.payload.messageId, action.payload.lang], EmailContentFactory())
          .set('loadingState', STATUS.ERROR)
      )
    case 'REMOVE_EMAIL_CONTENT':
      return state.set('email', state.email.delete(action.payload))
    case 'EMPTY_ALL_EMAIL_SENDERS':
      return state.set(
        'email',
        state.email.map(messageContent =>
          messageContent.map(langContent =>
            langContent.merge({ fromEmail: null, senderIdentityId: undefined, name: null })
          )
        )
      )
    case 'UPDATE_PUSH_MESSAGE_CONTENT':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(['push', action.payload.messageId, action.payload.lang], pushContent => {
          const updatedContent = pushContent || PushContentFactory()
          let { content, androidContent } = updatedContent
          const { field } = action.payload
          switch (field) {
            case 'pushTitle':
            case 'pushBody':
            case 'pushPicture': {
              content = content.set(field, action.payload.value)
              return updatedContent.set('content', content)
            }
            case 'androidIcon': {
              androidContent = androidContent.set(field, action.payload.value)
              return updatedContent.set('androidContent', androidContent)
            }
            default:
              return updatedContent
          }
        })
      )
    case 'UPDATE_PUSH_CONTENT_RECORD':
      return messageUpdated(
        action.payload.messageId,
        state.setIn(['push', action.payload.messageId, action.payload.lang], action.payload.record)
      )
    case 'UPDATE_PUSH_TEMPLATE':
      return state.updateIn(
        ['push', action.payload.messageId, action.payload.lang],
        pushContent => {
          let updatedContent = pushContent || PushContentFactory()
          const { content, androidContent } = action.payload
          if (content?.pushTitle !== undefined) {
            updatedContent = updatedContent.setIn(
              ['content', 'templates', 'pushTitle'],
              content.pushTitle
            )
          }
          if (content?.pushBody !== undefined) {
            updatedContent = updatedContent.setIn(
              ['content', 'templates', 'pushBody'],
              content.pushBody
            )
          }
          if (content?.pushPicture !== undefined) {
            updatedContent = updatedContent.setIn(
              ['content', 'templates', 'pushPicture'],
              content.pushPicture
            )
          }
          if (androidContent?.androidIcon !== undefined) {
            updatedContent = updatedContent.setIn(
              ['androidContent', 'templates', 'androidIcon'],
              androidContent.androidIcon
            )
          }
          return updatedContent
        }
      )
    default:
      return state
  }
}
