// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useMatch } from 'react-router-dom'

import {
  useCurrentCompanyHasAllFeatures,
  useCurrentCompanyHasFeature,
  useCurrentCompanyHasOneFeatureAmongst,
} from 'components/_hooks'
import { ButtonNavLink, ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { generateSettingsUrl } from 'com.batch.common/router'

import { SettingsGlobalSubNav } from './settings-global-styles'

import { ChannelSubNav } from '../project/navigation/channel-sub-nav'
import { type AppRecord } from 'com.batch.redux/_records'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentCompanySelector } from 'com.batch.redux/company'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'
import { type UserRecord } from 'com.batch.redux/user.records'

const HardLinkOrNavLink = ({
  hardReload,
  url,
  isActive,
  children,
  ...rest
}: {
  hardReload: boolean,
  url: string,
  isActive?: boolean,
  children: React.Node,
  ...
}): React.Node => {
  return hardReload ? (
    <ButtonLink {...rest} style={{ marginRight: 12 }} isActive={isActive} href={url}>
      <span className="styled-button-text">{children}</span>
    </ButtonLink>
  ) : (
    <ButtonNavLink {...rest} style={{ marginRight: 12 }} isActive={isActive} to={url}>
      <span className="styled-button-text">{children}</span>
    </ButtonNavLink>
  )
}

type MenuProps = {
  user: UserRecord,
  settingsMenuApp: AppRecord,
  channel: 'email' | { appId: number },
  ...
}

const SETTINGS_TABS_EXCLUDES_APPS = ['global', 'profiles', 'segments']

const MenuRaw = ({ settingsMenuApp, user, channel }: MenuProps): React.Node => {
  // Restriction for team
  const userCanAdministrate = user.permissionsForCurrentCompany.has('administrate')

  const apps = useSelector(currentProjectAppsSelector)
  const company = useSelector(currentCompanySelector)
  const currentProject = useSelector(optionalCurrentProjectSelector)
  const hasProjects = Boolean(currentProject)
  const matchSettings = useMatch('/:companyId/projects/:projectId/apps/:appId/settings/:activeTab')
  const matchProjectSettings = useMatch('/:companyId/projects/:projectId/settings/:activeTab')

  /* when we come from php on a channel page, all links on this page
  will lead to another app ; to make this work, we need a hard reload
  */
  const requireHardReload = React.useMemo(() => channel === 'email', [channel])

  // -----  derived
  const activeTab = React.useMemo(
    () => matchSettings?.params?.activeTab ?? matchProjectSettings?.params?.activeTab ?? '',
    [matchProjectSettings?.params?.activeTab, matchSettings?.params?.activeTab]
  )
  const shouldExcludeAppsTab = React.useMemo(() => {
    return SETTINGS_TABS_EXCLUDES_APPS.includes(activeTab)
  }, [activeTab])

  const projectHasOneChannelConfigured =
    currentProject?.smsConfigured ||
    currentProject?.pushConfigured ||
    Boolean(currentProject?.emailDomain)

  const companyHasOneLegacyForm = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-campaign',
    'cep-show-legacy-campaign-omni',
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
    'inapp-campaigns',
  ])
  const currentCompanyCanUseMobileLandings = useCurrentCompanyHasAllFeatures([
    'cep-show-legacy-campaign',
    'inapp-messaging',
  ])
  const currentCompanyHasInAppCampaigns = useCurrentCompanyHasFeature('inapp-campaigns')

  const companyNeedsThemes = currentCompanyCanUseMobileLandings || currentCompanyHasInAppCampaigns

  const shouldShowChannelsSubNav = React.useMemo(() => {
    return (
      !shouldExcludeAppsTab &&
      hasProjects &&
      (!['audiences', 'cappings', 'labels'].includes(activeTab) || companyHasOneLegacyForm)
    )
  }, [shouldExcludeAppsTab, hasProjects, activeTab, companyHasOneLegacyForm])

  return (
    <React.Fragment>
      <SettingsGlobalSubNav>
        <HardLinkOrNavLink
          hardReload={requireHardReload}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: { appId: settingsMenuApp.id },
            projectId: currentProject?.id,
            activeTab: 'global',
          })}
        >
          General
        </HardLinkOrNavLink>
        <HardLinkOrNavLink
          hardReload={channel !== 'email' && requireHardReload}
          url={generateSettingsUrl({
            companyId: company.id,
            channel,
            projectId: currentProject?.id,
            activeTab: channel === 'email' ? 'email' : 'push',
          })}
        >
          {hasProjects ? 'Channels' : 'Push settings'}
        </HardLinkOrNavLink>
        <HardLinkOrNavLink
          hardReload={requireHardReload}
          disabled={!userCanAdministrate}
          addOn={!userCanAdministrate ? 'suffix' : undefined}
          $withIconSuffix={!userCanAdministrate}
          as={userCanAdministrate ? NavLink : 'button'}
          url={
            !userCanAdministrate
              ? ''
              : generateSettingsUrl({
                  companyId: company.id,
                  channel: { appId: settingsMenuApp.id },
                  projectId: currentProject?.id,
                  activeTab: 'team',
                })
          }
        >
          <span>Team</span>
          {!userCanAdministrate && (
            <Tooltip
              tooltip="Only an administrator can view and edit users access"
              placement="bottom"
            >
              <span className="styled-button-suffix" style={{ marginLeft: 4 }}>
                <Icon icon="lock" size={12} />
              </span>
            </Tooltip>
          )}
        </HardLinkOrNavLink>
        {(companyHasOneLegacyForm || !currentProject) && (
          <HardLinkOrNavLink
            hardReload={requireHardReload}
            url={generateSettingsUrl({
              companyId: company.id,
              channel: { appId: settingsMenuApp.id },
              projectId: currentProject?.id,
              activeTab: 'custom-data',
            })}
          >
            Custom data
          </HardLinkOrNavLink>
        )}
        <HardLinkOrNavLink
          className={channel === 'email' && activeTab === 'audiences' ? 'active' : ''}
          hardReload={requireHardReload}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: currentProject ? 'email' : channel,
            projectId: currentProject?.id,
            activeTab: 'audiences',
          })}
        >
          Audiences
        </HardLinkOrNavLink>
        {hasProjects && projectHasOneChannelConfigured && (
          <HardLinkOrNavLink
            hardReload={false}
            url={generateSettingsUrl({
              companyId: company.id,
              channel: 'email',
              projectId: currentProject?.id,
              activeTab: 'segments',
            })}
          >
            Segments
          </HardLinkOrNavLink>
        )}
        {(companyNeedsThemes || !currentProject) &&
          settingsMenuApp.platform !== 'windows' &&
          settingsMenuApp.platform !== 'webpush' && (
            <HardLinkOrNavLink
              hardReload={requireHardReload}
              url={generateSettingsUrl({
                companyId: company.id,
                channel: { appId: settingsMenuApp.id },
                projectId: currentProject?.id,
                activeTab: 'themes',
              })}
            >
              Themes
            </HardLinkOrNavLink>
          )}
        {hasProjects && projectHasOneChannelConfigured && (
          <HardLinkOrNavLink
            hardReload={false}
            url={generateSettingsUrl({
              companyId: company.id,
              channel: 'email',
              projectId: currentProject?.id,
              activeTab: 'profiles',
            })}
          >
            Profiles
          </HardLinkOrNavLink>
        )}
        {(companyHasOneLegacyForm || !currentProject) && (
          <HardLinkOrNavLink
            hardReload={requireHardReload}
            style={{ marginRight: 12 }}
            url={generateSettingsUrl({
              companyId: company.id,
              channel: { appId: settingsMenuApp.id },
              projectId: currentProject?.id,
              activeTab: 'debug',
            })}
          >
            Debug
          </HardLinkOrNavLink>
        )}
        <HardLinkOrNavLink
          hardReload={requireHardReload}
          style={{ marginRight: 12 }}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: { appId: settingsMenuApp.id },
            projectId: currentProject?.id,
            activeTab: 'privacy',
          })}
        >
          Privacy
        </HardLinkOrNavLink>
        <HardLinkOrNavLink
          className={channel === 'email' && activeTab === 'labels' ? 'active' : ''}
          hardReload={requireHardReload}
          style={{ marginRight: 12 }}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: currentProject ? 'email' : channel,
            projectId: currentProject?.id,
            activeTab: 'labels',
          })}
        >
          Labels
        </HardLinkOrNavLink>
        <HardLinkOrNavLink
          className={channel === 'email' && activeTab === 'cappings' ? 'active' : ''}
          hardReload={requireHardReload}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: currentProject ? 'email' : channel,
            projectId: currentProject?.id,
            activeTab: 'cappings',
          })}
        >
          Cappings
        </HardLinkOrNavLink>
      </SettingsGlobalSubNav>
      {shouldShowChannelsSubNav && !!currentProject && (
        <ChannelSubNav
          apps={apps}
          project={currentProject}
          channel={channel}
          isTransparent
          gotBorder
        />
      )}
    </React.Fragment>
  )
}

export const Menu = MenuRaw

export const AppMenu = ({
  children,
  ...props
}: MenuProps & { children: React.Node, ... }): React.Node => {
  return (
    <React.Fragment>
      <Menu {...props} />
      <div className="recontent">{children}</div>
    </React.Fragment>
  )
}
