// @flow
import { type Set, type List } from 'immutable'

import { type DateRange } from 'components/form/fields/date-picker/date-range-picker'

import { type PartialOrchestrationRecord } from '../models/partial-orchestration.records'
import { type CampaignListSortableBy } from 'com.batch/orchestration-list/store/campaign-list.state'
import {
  type Dispatch,
  type DispatchExtraBoundFn,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type fetchCampaignsResponse = {
  count: number,
  countTotal: number,
  entities: List<PartialOrchestrationRecord>,
}

export type SetPageAction = ReduxAction<'SET_PAGE_CAMPAIGNS_LIST', number>
export const setPage = (page: number): DispatchExtraBoundFn<void> => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PAGE_CAMPAIGNS_LIST', payload: page })
    if (!getState().campaignList.tokensPerPage.has(page)) {
      dispatch(fetchCampaignsList({ trashCache: false })).catch(err => {
        if (!err.aborted) console.log('unable to fetch campaigns', err)
      })
    }
  }
}

let scheduleFetchTimeout: TimeoutID | null = null
export type UpdateSearchAction = ReduxAction<'UPDATE_SEARCH_CAMPAIGNS_LIST', string>
export const updateSearch = (search: string): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_SEARCH_CAMPAIGNS_LIST',
      payload: search,
    })
  }
}

export type UpdateFilterStatesAction = ReduxAction<
  'UPDATE_FILTER_STATES_CAMPAIGNS_LIST',
  Set<campaignStateType>,
>
export const updateFilterStates = (states: Set<campaignStateType>): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_STATES_CAMPAIGNS_LIST',
      payload: states,
    })
  }
}

export type UpdateFilterChannelAction = ReduxAction<
  'UPDATE_FILTER_CHANNELS_CAMPAIGNS_LIST',
  Set<ChannelUntilCleanup>,
>
export const updateFilterChannels = (
  channels: Set<ChannelUntilCleanup>
): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_CHANNELS_CAMPAIGNS_LIST',
      payload: channels,
    })
  }
}

export type UpdateFilterLabelsAction = ReduxAction<
  'UPDATE_FILTER_LABELS_CAMPAIGNS_LIST',
  Set<string>,
>
export const updateFilterLabels = (labels: Set<string>): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_LABELS_CAMPAIGNS_LIST',
      payload: labels,
    })
  }
}

export type UpdateFilterDatesAction = ReduxAction<
  'UPDATE_FILTER_DATE_RANGE_CAMPAIGNS_LIST',
  DateRange,
>
export const updateFilterDateRangeAction = (dateRange: ?DateRange): DispatchExtraBoundFn<void> => {
  return dispatch => {
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_FILTER_DATE_RANGE_CAMPAIGNS_LIST',
      payload: dateRange,
    })
  }
}

type sortDirections = 'asc' | 'dsc'
export type UpdateSortAction = ReduxAction<
  'UPDATE_SORT_CAMPAIGNS_LIST',
  { sortBy: CampaignListSortableBy, sortDirection: sortDirections, ... },
>
export const updateSort = (payload: {
  sortBy: CampaignListSortableBy,
  sortDirection: sortDirections,
  ...
}): DispatchExtraBoundFn<void> => {
  if (scheduleFetchTimeout) clearTimeout(scheduleFetchTimeout)
  return (dispatch, getState) => {
    const mode = getState().campaignList.mode
    const projectId = currentProjectSelector(getState()).id
    localStorage.setItem(`${projectId}-${mode}-sortBy`, payload.sortBy)
    localStorage.setItem(`${projectId}-${mode}-sortDirection`, payload.sortDirection)
    scheduleFetchAndDispatch(dispatch, {
      type: 'UPDATE_SORT_CAMPAIGNS_LIST',
      payload,
    })
  }
}

type FetchCampaignsListAction = ReduxAction<'FETCH_CAMPAIGNS_LIST', null>

export type FetchCampaignsListSuccessAction = ReduxAction<
  'FETCH_CAMPAIGNS_LIST_SUCCESS',
  {
    ...fetchCampaignsResponse,
    trashCache: boolean,
    page: number,
    ...
  },
>

export type FetchCampaignsListFailureAction = ReduxAction<'FETCH_CAMPAIGNS_LIST_FAILURE', string>

export type FetchCampaignsListActions =
  | FetchCampaignsListAction
  | FetchCampaignsListSuccessAction
  | FetchCampaignsListFailureAction
  | SetPageAction
  | UpdateSearchAction
  | UpdateSortAction
  | UpdateFilterStatesAction
  | UpdateFilterChannelAction
  | UpdateFilterLabelsAction
  | UpdateFilterDatesAction

let abortFetchCampaignsController: AbortController = new AbortController()

export const fetchCampaignsList = ({
  trashCache = true,
}: {
  trashCache?: boolean,
}): DispatchExtraBoundFn<
  Promise<{ ...fetchCampaignsResponse, page: number, trashCache: boolean }>,
> => {
  return (dispatch, getState, { orchestrationService }) => {
    abortFetchCampaignsController.abort()
    abortFetchCampaignsController = new AbortController()
    const state = getState()
    const project = currentProjectSelector(state)
    const cls = state.campaignList
    const targetPage = trashCache ? 1 : cls.page
    const promise = orchestrationService
      .fetchOrchestrations({
        project,
        mode: cls.mode,
        search: cls.search,
        orderDirection: cls.sortDirection,
        orderBy: cls.sortBy,
        statuses: cls.statuses.toArray(),
        filterType: [],
        channels: cls.channels.toArray(),
        labels: cls.labels.toArray(),
        dateRange: cls.dateRange,
        offset: (targetPage - 1) * cls.nbPerPage,
        size: cls.nbPerPage,
        count: trashCache ? null : state.campaignList.count,
        countTotal: state.campaignList.countTotal,
        abortSignal: abortFetchCampaignsController.signal,
      })
      .then(response => {
        let qs = new URLSearchParams(window.location.search)
        qs.set('page', String(targetPage))
        qs.set('search', cls.search)
        qs.set('orderBy', cls.sortBy)
        qs.set('orderDirection', cls.sortDirection)
        qs.set(
          'statuses',
          cls.statuses
            .toArray()
            .map(status => status.toLowerCase())
            .join(',')
        )
        qs.set('channels', cls.channels.toArray().join(','))
        qs.set('labels', cls.labels.toArray().join(','))
        qs.set('from', cls.dateRange?.from.toISOString() ?? '')
        qs.set('to', cls.dateRange?.to.toISOString() ?? '')
        history.pushState(null, '', '?' + qs.toString())

        return {
          ...response,
          page: targetPage,
          trashCache,
        }
      })
    return promiseActionCreator<{
      ...fetchCampaignsResponse,
      page: number,
      trashCache: boolean,
    }>({
      dispatch,
      promise,
      actionName: 'FETCH_CAMPAIGNS_LIST',
    })
  }
}

function scheduleFetchAndDispatch<T: string, P>(dispatch: Dispatch, action: ReduxAction<T, P>) {
  if (scheduleFetchTimeout) clearTimeout(scheduleFetchTimeout)
  dispatch(action)
  scheduleFetchTimeout = setTimeout(() => {
    dispatch(fetchCampaignsList({ trashCache: true })).catch(err => {
      if (!err.aborted) console.log('unable to fetch campaigns', err)
    })
  }, 800)
}
