// @flow
import React from 'react'
import { ThemeProvider, ThemeContext } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'

import {
  EmptyCell,
  TableRow,
  TableContainer,
  TableOrderContainer,
  TableEmptyContent,
  TableTemplateCellWrapper,
} from './table.styles'

type TableOrderProps = {
  sort?: false | 'asc' | 'dsc',
  align?: 'left' | 'right',
  children?: React$Node,
  onClick: () => void,
  style?: { ... },
}

type TableBodyProps = {
  emptyTemplate?: React$Node,
  templateSize?: number,
  children?: React$Node,
  ...
}

export const TableBody = ({
  children,
  emptyTemplate,
  templateSize,
  ...rest
}: TableBodyProps): React$Node => {
  const themeContext = React.useContext(ThemeContext)
  const lineList = React.useMemo(() => {
    let emptyTableSize = templateSize ?? 10
    let lineList = []
    for (let i = 0; i < emptyTableSize; i++) lineList.push(emptyTemplate)
    return lineList
  }, [emptyTemplate, templateSize])

  return (
    <div {...rest}>
      {themeContext.isEmpty || themeContext.isLoading
        ? lineList.map((l, e) => <TableRow key={e}>{l}</TableRow>)
        : children}
    </div>
  )
}

export const TableCellOrder: React$AbstractComponent<TableOrderProps, HTMLElement> =
  React.forwardRef(
    ({ children, sort, align = 'left', onClick, style }: TableOrderProps, ref): React$Node => {
      return (
        <TableOrderContainer ref={ref} align={align} isActive={Boolean(sort)} style={style}>
          <Button onClick={onClick} addOn={children ? 'suffix' : undefined} height={28}>
            {children}
            <Icon icon={sort === 'asc' ? 'arrow-long-down' : 'arrow-long-up'} />
          </Button>
        </TableOrderContainer>
      )
    }
  )

type TableProps = {
  template: string,
  children: React$Node,
  style?: Object,
  striped?: boolean,
  ...
}

export const Table = ({ template, children, style, striped }: TableProps): React$Node => {
  return (
    <ThemeProvider theme={{ template }}>
      <TableContainer striped={striped} style={style}>
        {children}
      </TableContainer>
    </ThemeProvider>
  )
}

// ======================= EMPTY STATE

type TableTemplateCellProps = {
  template: string,
  gap?: number,
  align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end',
  minSize?: number,
  maxSize?: number,
  forceLoading?: boolean,
  isMultiline?: boolean,
  ...
}

export const TableTemplateCell = ({
  template,
  gap,
  align,
  minSize = 50,
  maxSize = 80,
  forceLoading,
  isMultiline,
}: TableTemplateCellProps): React$Node => {
  const themeContext = React.useContext(ThemeContext)
  const isLoading = (!!themeContext && themeContext.isLoading) || !!forceLoading
  const templateRows = template.split(',')
  return (
    <TableTemplateCellWrapper isMultiline={isMultiline}>
      {templateRows.map((row, key) => (
        <TableEmptyContent key={key} align={align}>
          <TableTemplateCellContent
            row={row}
            gap={gap}
            minSize={minSize}
            maxSize={maxSize}
            isLoading={isLoading}
          />
        </TableEmptyContent>
      ))}
    </TableTemplateCellWrapper>
  )
}

export type TableTemplateCellContentProps = {
  row: string,
  gap?: number,
  minSize?: number,
  maxSize?: number,
  isLoading: boolean,
  ...
}

const TableTemplateCellContent = ({
  gap,
  row,
  minSize = 50,
  maxSize = 80,
  isLoading,
}: TableTemplateCellContentProps): React$Node => {
  const randomSize = React.useMemo(
    () => Math.floor(Math.random() * (maxSize - minSize)) + minSize,
    [maxSize, minSize]
  )
  const templateElems = row.trim().split(' ')
  return templateElems.map((n, i) => {
    return (
      <EmptyCell
        key={i}
        $width={n === '1fr' ? `${randomSize}%` : n}
        $marginRight={gap && i + 1 !== templateElems.length ? gap : 0}
        $theme={isLoading ? 'loading' : 'empty'}
      />
    )
  })
}
