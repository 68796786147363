// @flow

import { buildAgeFromSeconds } from 'com.batch.common/utils'

import { updatePushContentRecord } from './update-push-content'

import { getPushContentForActiveLanguageSelector } from '../store/push.selector'
import { AgeFactory, AppFactory, type DispatchBoundFn } from 'com.batch.redux/_records'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'

export const initDefaultAdvancedSettings = (messageId: string): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const apps = currentProjectAppsSelector(state)
    const getContent = getPushContentForActiveLanguageSelector(state)
    const lang = activeLanguageValueSelector(state)
    const content = getContent(messageId)
    const hasIos = apps.some(app => app.platform === 'ios')
    const hasAndroid = apps.some(app => app.platform === 'ios')
    const iosApp = apps.find(app => app.platform === 'ios', null, AppFactory())
    const androidApp = apps.find(app => app.platform === 'android', null, AppFactory())
    const defaultTtlInHours: number =
      iosApp.pushConfig.defaultTtl ?? androidApp.pushConfig.defaultTtl ?? 0

    const priority =
      (hasIos ? iosApp : androidApp).getIn(['pushConfig', 'defaultPriority']) === 'NORMAL'
        ? 'PUSH_PRIORITY_NORMAL'
        : 'PUSH_PRIORITY_HIGH'
    let updatedContent = content
      .set('ttlEnabled', Boolean(defaultTtlInHours))
      .set(
        'ttl',
        defaultTtlInHours
          ? buildAgeFromSeconds(defaultTtlInHours * 60 * 60, ['m', 'h', 'd'], 60, 28 * 24 * 3600)
          : AgeFactory({ unit: 'h', valid: true, seconds: 3600, inputValue: '1' })
      )
      .set('priority', priority)
    if (hasAndroid) {
      const collapseKey = androidApp.getIn(['pushConfig', 'defaultCollapseKey'])
      if (collapseKey) {
        updatedContent = updatedContent.setIn(['androidContent', 'collapseKey'], collapseKey)
      }
    }
    dispatch(
      updatePushContentRecord({
        messageId,
        lang,
        record: updatedContent,
      })
    )
  }
}

export const initDefaultAdvancedSettingsForCampaign = (): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    if (
      !state.orchestration.id &&
      state.orchestration.campaign.sendType !== 'trigger' &&
      state.orchestration.campaign.channel === 'push' &&
      state.orchestration.campaign.messageTypedId
    ) {
      dispatch(initDefaultAdvancedSettings(state.orchestration.campaign.messageTypedId))
    }
  }
}
