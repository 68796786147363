// @flow
import styled, { type StyledComponent } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const ConfirmContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  width: 420px;
  min-heigh: 149px;
  padding: 20px;
  background-color: ${colors.fill};
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.03);
`

export const ConfirmHeader: StyledComponent<*, *, HTMLElement> = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) auto;
  grid-gap: 5px;

  & > button {
    margin-top: -7px;
  }
`

export const ConfirmTitle: StyledComponent<*, *, HTMLElement> = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${colors.text};
`

export const ConfirmContent: StyledComponent<*, *, HTMLElement> = styled.div`
  margin: 18px 0;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.text};

  p {
    margin-bottom: 8px;
    ${'' /* margin-bottom: 16px; */}
  }

  code {
    color: #665a32;
    background: rgba(239, 195, 55, 0.12);
  }
`

export const ConfirmFooter: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ConfirmWarning: StyledComponent<*, *, HTMLElement> = styled.p`
  color: ${colors.textWarning};
  font-weight: 500;
`

export const ConfirmHighlight: StyledComponent<*, *, HTMLElement> = styled.span`
  padding: 1px 3px;
  color: ${schemes.grayscale['80']};
  background-color: rgba(172, 177, 185, 0.16);
  border-radius: 3px;
`
