// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { TableTemplateCell } from 'components/common/table/index'
import { colors } from 'components/styled/tokens'
import { CapsLabel } from 'components/styled/text'

import { randomSize } from 'com.batch.common/utils'

import { CertifSettingsInfo, CertifSettingsExtra } from './settings.styles'

export const EmptyDevOriginsRow: React.AbstractComponent<{ ... }> = React.memo<{ ... }>(
  ({}: { ... }): React.Node => {
    return <TableTemplateCell template="1fr" />
  }
)

export const EmptyScopedDevice: StyledComponent<*, *, HTMLElement> = styled.div`
  text-align: center;

  h3 {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.text};
  }
`

export const EmptyScopedIOs = (): React.Node => {
  return (
    <Grid template="55% 1fr" alignItems="stretch">
      <div style={{ borderRight: '1px solid #eff0f4' }}>
        <CertifSettingsInfo>
          <CapsLabel>Name</CapsLabel>
          <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
        </CertifSettingsInfo>

        <CertifSettingsInfo>
          <CapsLabel>Expired</CapsLabel>
          <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
        </CertifSettingsInfo>

        <CertifSettingsInfo>
          <CapsLabel>Format</CapsLabel>
          <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
        </CertifSettingsInfo>

        <CertifSettingsInfo noBorder>
          <CapsLabel>Uploaded on</CapsLabel>
          <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
        </CertifSettingsInfo>
      </div>
      <div style={{ padding: '40px 22px', height: 382, position: 'relative' }}>
        <p style={{ marginBottom: 10 }}>Additional informations</p>

        <div>
          <CertifSettingsExtra>
            <CapsLabel>
              <EmptyField _width="100%" />
            </CapsLabel>{' '}
            <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
          </CertifSettingsExtra>

          <CertifSettingsExtra>
            <CapsLabel>
              <EmptyField _width="100%" />
            </CapsLabel>{' '}
            <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
          </CertifSettingsExtra>

          <CertifSettingsExtra>
            <CapsLabel>
              <EmptyField _width="100%" />
            </CapsLabel>
            <EmptyField _width={React.useMemo(() => `${randomSize(10, 70)}%`, [])} />
          </CertifSettingsExtra>

          <Button kind="secondary" onClick={() => {}} style={{ marginTop: 14 }} disabled>
            Edit informations
          </Button>
        </div>

        <p style={{ position: 'absolute', bottom: '22px' }}>
          Need more help? Let's check the documentation
        </p>
      </div>
    </Grid>
  )
}
