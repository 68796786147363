// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import {
  CampaignHeader,
  CampaignListHeader,
  CampaignListTitle,
  CampaignTitleColumn,
} from 'components/campaign/campaign-list.styles'
import { EmptyField } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'
import { ActionsGroup, HeaderActions } from 'components/styled/blocs'

import { CreateOrchestrationButton } from './create-orchestration-button'
import { Filters } from './filters'

import { type CampaignListMetadata } from 'com.batch/orchestration-list/store/campaign-list.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'

type HeaderProps = {
  loading: boolean,
  isEmpty: boolean,
  children: React.Node,
  count: number,
  campaignListMetadata: CampaignListMetadata,
}

export const Header = ({
  loading,
  children,
  count,
  isEmpty,
  campaignListMetadata,
}: HeaderProps): React.Node => {
  const project = useSelector(currentProjectSelector)
  const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()

  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])

  return (
    <CampaignHeader openFilters={false}>
      <CampaignListHeader openFilters={false}>
        <div className="campaign-header-row">
          <CampaignTitleColumn>
            <CampaignListTitle>{children}</CampaignListTitle>
            <div className="campaigns-results">
              <p className="results">
                {loading ? (
                  <EmptyField _width={80} forceLoading as="span" _display="inline" />
                ) : (
                  <React.Fragment>{count} results</React.Fragment>
                )}
              </p>
            </div>
          </CampaignTitleColumn>

          <HeaderActions style={{ flex: '1', alignItems: 'center' }}>
            <ActionsGroup>
              {schedulingTypeFromUrl === 'campaigns' ? (
                <Tooltip
                  tooltip="You do not have the permissions to create a campaign."
                  isTooltipEmpty={userHasWritePermission}
                  placement="bottom"
                >
                  <div>
                    <CreateOrchestrationButton
                      project={project}
                      testId="button_create_campaign"
                      disabled={!userHasWritePermission}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  tooltip="You do not have the permissions to create an automation."
                  isTooltipEmpty={userHasWritePermission}
                  placement="bottom"
                >
                  <div>
                    <CreateOrchestrationButton
                      project={project}
                      isAutomation
                      testId="button_create_automation"
                      disabled={!userHasWritePermission}
                    />
                  </div>
                </Tooltip>
              )}
            </ActionsGroup>
          </HeaderActions>
        </div>

        {isEmpty ? (
          <div style={{ height: 54 }} />
        ) : (
          <div>
            <ThemeProvider theme={{ kind: 'filter' }}>
              <Grid template="1fr">
                <Filters
                  campaignListMetadata={campaignListMetadata}
                  schedulingType={schedulingTypeFromUrl}
                />
              </Grid>
            </ThemeProvider>
          </div>
        )}
      </CampaignListHeader>
    </CampaignHeader>
  )
}
