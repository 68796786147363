// @flow

import * as React from 'react'

import {
  HeaderBoxActions,
  HeaderBoxTitle,
  BoxHeader,
  BoxBody,
  BoxSection,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { SideSheet } from 'components/common/sidesheet'
import { Icon } from 'components/common/svg-icon'

import { NodeIconWrapper } from 'com.batch/orchestration-journey/ui/components/node-icon-wrapper'
import { TimerSettings } from 'com.batch/orchestration-journey/ui/components/timer-settings'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

type TimerSettingsSheetProps = {
  nodeId: string,
  isOpened: boolean,
  dismiss: () => void,
  refocusOnNode: ({ width?: number, zoom?: number }) => void,
}

export const TimerSettingsSheet = ({
  isOpened,
  dismiss,
  nodeId,
  refocusOnNode,
}: TimerSettingsSheetProps): React.Node => {
  const sheetWidth = 460
  React.useEffect(() => {
    if (isOpened) refocusOnNode({ width: sheetWidth })
  }, [refocusOnNode, isOpened])

  return (
    <SideSheet
      isOpened={isOpened}
      close={dismiss}
      footer={
        nodeId && nodeId !== 'default' ? (
          <Copyable fullWidth={true} notificationText="Step ID copied" value={nodeId} />
        ) : undefined
      }
      footerActions={
        <Button kind="secondary" onClick={dismiss}>
          Continue
        </Button>
      }
      width={sheetWidth}
    >
      <BoxHeader>
        <HeaderBoxTitle title={<NodeIconWrapper kind="delay">Delay</NodeIconWrapper>} />
        <HeaderBoxActions large>
          <Button onClick={dismiss}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody>
        <BoxSection $padding style={{ borderBottom: 'none', height: '100%' }}>
          <TimerSettings nodeId={nodeId} />
        </BoxSection>
      </BoxBody>
    </SideSheet>
  )
}
