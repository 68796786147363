// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { type StateToggler } from 'components/_hooks'
import { useIntercomVerticalPadding } from 'components/_hooks/use-intercom-vertical-padding'
import { Box, BoxBody, BoxFooter, BoxHeader } from 'components/common/box'
import { Button } from 'components/common/button'

import {
  getAreRequiredEmailFieldsFilledSelector,
  getEmailContentLoadingStateSelector,
} from 'com.batch/email/store/email-automation.selector'

import { SendTestEmailButton } from 'com.batch/email/ui/components/send-test-email-button'
import { STATUS } from 'constants/common'

type EmailEditorPopinProps = {
  popinState: StateToggler,
  header: React.Node,
  children: React.Node,
  saveAction: () => void,
  cancelAction?: () => Promise<void> | void,
  sendTestEmailPopinState: StateToggler,
  sendTestClickAddons?: () => void,
  messageId: string,
}
export const EmailEditorPopin = ({
  popinState,
  children,
  header,
  saveAction,
  cancelAction,
  sendTestEmailPopinState,
  sendTestClickAddons,
  messageId,
}: EmailEditorPopinProps): React.Node => {
  const theme = useTheme()
  const getAreRequiredEmailFieldsFilled = useSelector(getAreRequiredEmailFieldsFilledSelector)
  const areRequiredEmailFieldsFilled = React.useMemo(
    () => getAreRequiredEmailFieldsFilled(messageId),
    [getAreRequiredEmailFieldsFilled, messageId]
  )
  const getLoadingState = useSelector(getEmailContentLoadingStateSelector)
  const loadingState = React.useMemo(() => getLoadingState(messageId), [getLoadingState, messageId])
  const isLoading = React.useMemo(() => loadingState === STATUS.LOADING, [loadingState])

  const save = React.useCallback(() => {
    saveAction()
  }, [saveAction])

  useIntercomVerticalPadding(56, 72)

  return (
    <Box style={{ height: '100vh' }}>
      <BoxHeader style={{ flexShrink: 0 }}>{header}</BoxHeader>

      <BoxBody style={{ display: 'flex', height: 'calc(100vh - 56px * 2)' }}>{children}</BoxBody>

      <BoxFooter style={{ flexShrink: 0 }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <Button onClick={cancelAction ?? popinState.close}>Cancel</Button>

          <div style={{ display: 'flex', marginLeft: 'auto', gap: 8 }}>
            <SendTestEmailButton
              messageId={messageId}
              hasMandatoryInfo={areRequiredEmailFieldsFilled}
              togglePopinSendTest={sendTestEmailPopinState}
              onClickAddons={sendTestClickAddons ?? undefined}
            ></SendTestEmailButton>

            <Button
              kind="secondary"
              onClick={save}
              isLoading={isLoading}
              disabled={theme?.disabledMode}
            >
              Continue
            </Button>
          </div>
        </div>
      </BoxFooter>
    </Box>
  )
}
