// @flow

import * as React from 'react'

import { useBlurOnClickCallback } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { Toolbar, ToolbarDivider } from 'com.batch/shared/ui/component/toolbar.styles'

type PreviewToolbarProps = {
  availablePreviewPlatforms: Array<PreviewPlatform>,
  previewPlatform?: PreviewPlatform,
  isExpanded: boolean,
  toggleIsExpanded: () => void,
  setPreviewPlatform: PreviewPlatform => void,
  ...
}

const IconMap = {
  ios: 'ios-smartphone',
  android: 'android-smartphone',
  webWin: 'pc-windows',
  webMac: 'mac',
  webAndroid: 'android-smartphone',
  webIos: 'ios-smartphone',
  sms: 'sms', // no icon
}

const TooltipMap: { [PreviewPlatform]: string, ... } = {
  ios: 'iOS',
  android: 'Android',
  webWin: 'Web Windows',
  webMac: 'Web Mac',
  webAndroid: 'Web Android',
  webIos: 'Web iOS',
  sms: 'SMS',
}

export const PushBuilderToolbar = ({
  toggleIsExpanded,
  previewPlatform,
  isExpanded,
  availablePreviewPlatforms,
  setPreviewPlatform,
}: PreviewToolbarProps): React.Node => {
  const onExpandedClick = useBlurOnClickCallback(toggleIsExpanded, [toggleIsExpanded])
  const onPlatformClick = React.useCallback(
    (p: PreviewPlatform) => () => setPreviewPlatform(p),
    [setPreviewPlatform]
  )

  if (
    (availablePreviewPlatforms.length === 1 && previewPlatform === 'webWin') ||
    availablePreviewPlatforms.length === 0
  )
    return null
  return (
    <Toolbar style={{ position: 'fixed', zIndex: 3, bottom: 32 }}>
      {availablePreviewPlatforms.length > 1 &&
        availablePreviewPlatforms.map((platform, id) => {
          return (
            <Tooltip tooltip={TooltipMap[platform]} key={id}>
              <Button
                isActive={platform === previewPlatform}
                onClick={onPlatformClick(platform)}
                style={{ width: 36 }}
              >
                <Icon icon={IconMap[platform]} />
              </Button>
            </Tooltip>
          )
        })}

      <React.Fragment>
        {availablePreviewPlatforms.length > 1 && <ToolbarDivider />}
        <Tooltip tooltip="Expanded">
          <Button
            isActive={isExpanded}
            disabled={previewPlatform === 'webWin'}
            onClick={onExpandedClick}
            style={{ width: 36 }}
          >
            <Icon icon={isExpanded ? 'expand-invert' : 'expand'} />
          </Button>
        </Tooltip>
      </React.Fragment>
    </Toolbar>
  )
}
