// @flow
import { type List, type OrderedMap } from 'immutable'
import * as React from 'react'

import { EventListContainer } from './event-list.styles'

import { EventRow } from '../event-row/event-row'
import { NoMoreEvents } from '../no-more-events/no-more-events'
import { StickyDay } from '../sticky-day/sticky-day'

import { type CustomEventRecord } from 'com.batch/profile/models/profile.records'

type EventListProps = {
  events: OrderedMap<string, List<CustomEventRecord>>,
  hasEvents: boolean,
  ...
}

export const EventList = ({ events, hasEvents }: EventListProps): React.Node => {
  const datesEvent = events.keySeq().toArray()

  return (
    <EventListContainer>
      {datesEvent.map((date, index) => {
        const eventList = events.get(date)
        return (
          eventList !== undefined &&
          eventList.count() > 0 && (
            <React.Fragment key={date}>
              <StickyDay date={date} key={date} firstElement={index === 0} />
              {events.get(date)?.map(event => {
                return <EventRow event={event} key={event.hashCode()} />
              })}
            </React.Fragment>
          )
        )
      })}
      {hasEvents && <NoMoreEvents />}
    </EventListContainer>
  )
}
