// @flow
import { scaleBand, scaleLinear } from 'd3-scale'
import { stack, stackOrderNone } from 'd3-shape'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { schemes } from 'components/styled/tokens'

import { Bar } from './bar'
import { BarChartSvg } from './bar-chart.styles'
import { ScaleLine } from './scale-line'

import {
  type BarData,
  type BarChartData,
  type Label,
  type Group,
} from 'com.batch/shared/infra/types/chart-data'
import {
  // STRIPE_PATTERN_REF,
  StripePattern,
} from 'com.batch/shared/ui/component/charts/donut-chart/patterns/stripe-pattern'

type Props = {
  data: Array<BarChartData>,
  labels: Array<Label>,
  nbBars: number,
  groups: Array<Group>,
  xScale: typeof scaleBand,
  height: number,
  width: number,
  activeScaleLines: boolean,
  marginTop?: number,
  barPadding?: number,
  emptyBarColor?: string,
  hoveredBar: ?string,
  setHoveredBar: (label: ?string) => void,
  nbScaleLine?: number,
  ...
}

export const BarChart = ({
  data = [],
  nbBars,
  groups = [],
  height = 200,
  marginTop,
  barPadding = 2,
  activeScaleLines,
  width,
  emptyBarColor,
  hoveredBar,
  setHoveredBar,
  xScale,
  labels = [],
  nbScaleLine = 4,
}: Props): React.Node => {
  const { isLoading } = useTheme()

  const shownData = React.useMemo(
    () =>
      data.map(d => {
        const t = labels.find(f => f.label === d.label)
        return { ...d, isDisabled: t ? t.isDisabled : false }
      }),
    [data, labels]
  )

  const stackSeries = stack()
    .keys(groups.map(g => g.name))
    .value((d: BarData, key) => {
      return d[key].value
    })
    .order(stackOrderNone)
  const series = stackSeries(shownData)

  // BAR HEIGHT
  const scaleLineData = React.useMemo(
    () => ({
      nbLines: nbScaleLine,
      nbParts: nbScaleLine - 1,
      lineMax: nbScaleLine - 2,
    }),
    [nbScaleLine]
  )

  const maxBarHeight = React.useMemo(
    () => (height / scaleLineData.nbParts) * scaleLineData.lineMax,
    [height, scaleLineData]
  )

  const maxValue = React.useMemo(() => {
    const sums = data.map(item => groups.reduce((acc, g) => acc + item[g.name].value, 0))
    const max = Math.max(...sums)
    const roundedMax = Math.ceil(max / scaleLineData.lineMax) * scaleLineData.lineMax
    return roundedMax
  }, [data, groups, scaleLineData])

  const yScale = React.useMemo(() => {
    return scaleLinear().domain([0, maxValue]).range([maxBarHeight, 0])
  }, [maxValue, maxBarHeight])

  // BAR DESIGN
  const barZoneWidth: number = React.useMemo(() => width / nbBars, [width, nbBars])
  const barWidth: number = React.useMemo(
    () => barZoneWidth - barPadding * 2,
    [barZoneWidth, barPadding]
  )

  const isFirstBarPart = React.useCallback(
    (serieIndex, index) => series[0][index][0] === series[serieIndex][index][0],
    [series]
  )
  const isLastBarPart = React.useCallback(
    (serieIndex, index) => series[series.length - 1][index][1] === series[serieIndex][index][1],
    [series]
  )

  const onBarHover = React.useCallback(
    (label: string | null) => () => setHoveredBar && setHoveredBar(label),
    [setHoveredBar]
  )

  const hasBarsStiped = React.useMemo(() => groups.some(g => g.isStriped === true), [groups])

  // RENDER
  return (
    <BarChartSvg width={width} height={height} $marginTop={marginTop}>
      {hasBarsStiped && (
        <defs>
          <StripePattern />
        </defs>
      )}

      {activeScaleLines && (
        <ScaleLine
          nbLines={scaleLineData.nbLines}
          nbParts={scaleLineData.nbParts}
          lineMax={scaleLineData.lineMax}
          width={width}
          height={height}
          max={maxValue}
        />
      )}

      <g transform={`translate(0,${maxBarHeight / scaleLineData.lineMax})`}>
        {series.map((serie, sIndex) =>
          serie.map((s, index) =>
            !s.data.isDisabled ? (
              <Bar
                isLoading={isLoading}
                key={`scale_line_${index}`}
                width={barWidth}
                height={yScale(s[0]) - yScale(s[1])}
                x={xScale(s.data.label) + barPadding}
                y={yScale(s[1])}
                color={groups[sIndex].color}
                isStriped={groups[sIndex]?.isStriped ?? false}
                currentBar={s.data.label}
                hoveredBar={hoveredBar}
                hasBorderRadius={{
                  bottom: isFirstBarPart(sIndex, index),
                  top: isLastBarPart(sIndex, index),
                }}
              />
            ) : null
          )
        )}
        {labels
          .filter(f => f.isDisabled)
          .map((item, index) => (
            <Bar
              isLoading={isLoading}
              key={`hidden_label_${index}`}
              width={barWidth}
              height={height}
              x={xScale(item.label) + barPadding}
              y={height}
              color={emptyBarColor ?? schemes.darklucent['20']}
              currentBar={item.label}
              hasBorderRadius={{
                bottom: true,
                top: true,
              }}
            />
          ))}
      </g>

      <g>
        {labels
          .filter(f => !f.isDisabled)
          .map((item, index) => (
            <rect
              key={index}
              x={xScale(item.label)}
              y={0}
              width={barZoneWidth}
              height={height}
              fill="transparent"
              onMouseEnter={onBarHover(item.label)}
              onMouseLeave={onBarHover(null)}
            />
          ))}
      </g>
    </BarChartSvg>
  )
}
