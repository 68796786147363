// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/common/button'
import { Form, Input, InputWrapper } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { getSSOUrl } from './api'
import { LoginPage } from './login.styles'

import { AuthStep, AuthStepFooter, Text, Title } from '../auth.styles'

type Props = {
  email: string,
  ...
}

export const LoginSSORefresh = ({ email }: Props): React.Node => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)
  const onSubmit = React.useCallback(() => {
    setLoading(true)
    getSSOUrl(email, window.location.href).then(
      (url: string) => {
        window.location = url
      },
      err => {
        setLoading(false)
        console.error(err)
      }
    )
  }, [email])

  const handleClickNotYou = React.useCallback(() => {
    navigate(generateUrl('login', { 'redirect-to': window.location.href }))
  }, [navigate])

  return (
    <LoginPage>
      <AuthStep>
        <Title>Login back in</Title>
        <Text>
          Please authenticate again with your single sign-on provider to return to your dashboard.
        </Text>
        <Text light>We require regular authentication to guarantee your safety.</Text>
        <Form onSubmit={onSubmit}>
          <InputWrapper>
            <Input
              type="email"
              value={email}
              disabled
              name="email"
              suffix={{ kind: 'text', value: 'Not you?', handler: handleClickNotYou }}
            />
          </InputWrapper>
          <AuthStepFooter
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button kind="primary" intent="action" type="submit" isLoading={loading}>
              Log me back in
            </Button>
          </AuthStepFooter>
        </Form>
      </AuthStep>
    </LoginPage>
  )
}
