// @flow

import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { textLight } from 'components/styled/tokens/colors'

import { type ThemeProps } from './common'

const isIOS = (p: PreviewPlatform) => p.toLowerCase().includes('ios')
const isAndroid = (p: PreviewPlatform) => p.toLowerCase().includes('android')
const isWeb = (p: PreviewPlatform) => !isIOS(p) && !isAndroid(p) && !isSms(p)
const isSms = (p: PreviewPlatform) => p.toLowerCase().includes('sms')

const fileFomPreviewPlatform = (p: PreviewPlatform, res: string) => {
  return `/medias/img/notif-preview/next/mockup-${
    isIOS(p) ? 'ios' : isAndroid(p) ? 'android' : isSms(p) ? 'ios' : 'web'
  }${res}.png`
}

export const PreviewMockupBackground: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> =
  styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  background-image: url(${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    fileFomPreviewPlatform(p.theme.previewPlatform, '')});
  ${(p: PropsWithTheme<{ ... }, ThemeProps>) => {
    const { previewPlatform, abTestingEnabled } = p.theme
    return ['ios', 'android', 'sms', 'webIos', 'webAndroid'].includes(previewPlatform)
      ? css`
          width: 478px;
          height: 466px;
          padding: 21px 22px 0;
          left: calc(50% - 478px / 2);
          background-size: 478px 466px;
          margin-top: ${abTestingEnabled ? '37' : '-4'}px;
        `
      : css`
          width: 434px;
          height: 425px;
          padding: 0;
          left: calc(50% - 434px / 2);
          background-size: 434px 425px;
          margin-top: ${abTestingEnabled ? '55' : '18'}px;
        `
  }}}
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
      fileFomPreviewPlatform(p.theme.previewPlatform, '@2x')});
  }
  @media screen and (-webkit-min-device-pixel-ratio: 3) {
    background-image: url(${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
      fileFomPreviewPlatform(p.theme.previewPlatform, '@3x')});
  }
`

export const PreviewMockupContainer: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  ${(p: PropsWithTheme<{ ... }, any>) => {
    const { previewPlatform } = p.theme
    if (isSms(previewPlatform)) {
      return css`
        display: grid;
        grid-template-columns: 150px 150px;
        justify-content: space-between;
        margin-top: 19px;
      `
    } else if (isAndroid(previewPlatform) || isIOS(previewPlatform)) {
      return css`
        display: grid;
        grid-template-columns: 1fr auto 1fr;
      `
    } else {
      return css`
        display: flex;
        justify-content: flex-end;
      `
    }
  }}
`

export const DateContainer: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    isAndroid(p.theme.previewPlatform)
      ? 'column-reverse'
      : isIOS(p.theme.previewPlatform)
        ? 'column'
        : 'row-reverse'};
  justify-content: center;
  align-items: center;
  margin-top: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    isAndroid(p.theme.previewPlatform) ? '39px' : isIOS(p.theme.previewPlatform) ? '59px' : '0px'};
  ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    (isAndroid(p.theme.previewPlatform) || isIOS(p.theme.previewPlatform)) && 'grid-column: 2;'}
`

export const DateMain: StyledComponent<{ ... }, ThemeProps, HTMLElement> = styled.p`
  font-size: 16px;
  line-height: 142%;
  font-weight: 500;
  margin-top: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    isAndroid(p.theme.previewPlatform) ? '8px;' : '0px'};
  margin-bottom: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    isAndroid(p.theme.previewPlatform) ? '22px;' : '0px'};
  color: ${textLight};
  display: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    isWeb(p.theme.previewPlatform) || isSms(p.theme.previewPlatform) ? 'none' : 'block'};
`

export const DateSub: StyledComponent<{ ... }, *, HTMLElement> = styled.p`
  line-height: 100%;
  color: ${textLight};
  font-size: 64px;

  ${(p: PropsWithTheme<{ ... }, any>) => {
    const { previewPlatform } = p.theme
    if (isSms(previewPlatform)) {
      return css`
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 0;
      `
    }
    if (isAndroid(previewPlatform)) {
      return css`
        font-weight: 300;
      `
    }
    if (isIOS(previewPlatform)) {
      return css`
        font-weight: 400;
        margin-bottom: 22px;
        margin-top: 8px;
      `
    }
    if (isWeb(previewPlatform)) {
      return css`
        display: none;
      `
    }
  }}
`
export const DateFull: StyledComponent<{ ... }, ThemeProps, HTMLElement> = styled.p`
  margin-top: 16px;
  margin-right: 24px;
  display: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    isWeb(p.theme.previewPlatform) ? 'flex' : 'none'};
`

export const StatusIcons: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  display: ${(p: PropsWithTheme<{ ... }, ThemeProps>) =>
    ['sms', 'ios', 'android'].includes(p.theme.previewPlatform) ? 'flex' : 'none'};
  gap: 6px;
  ${(p: PropsWithTheme<{ ... }, ThemeProps>) => {
    const { previewPlatform } = p.theme
    if (isAndroid(previewPlatform)) {
      return css`
        justify-content: flex-end;
        margin-right: 34px;
        margin-top: 6px;
      `
    } else if (isIOS(previewPlatform)) {
      return css`
        justify-content: center;
        margin-top: 19px;
      `
    } else {
      return css`
        justify-content: center;
      `
    }
  }}
`
