// @flow

import { type OrderedSet } from 'immutable'
import * as React from 'react'

import PreviewToolbar from 'components/campaign/preview/preview-toolbar'
import { ToolbarContainer } from 'components/campaign/review/review/rsr.styles'
import { ReviewNavSecondLinks, SubLink } from 'components/campaign/review/review-screen.styles'
import { FlexLine } from 'components/common/flexline'

import { changePreferedInstall } from 'com.batch.redux/template'

type ReviewReviewMenuProps = {
  activeStep: ?string,
  theme: ?string,
  isPush: boolean,
  installId: ?string,
  toggleFullScreen: () => void,
  installIds: OrderedSet<string>,
  changePreferedInstall: typeof changePreferedInstall,
  templateLoading: boolean,
  platform: string,
  hasLanding: boolean,
  previewMode: 'normal' | 'expanded' | 'landing',
  previewWeb: string,
  platform: string,
  updatePreviewMode: (previewMode: 'normal' | 'expanded' | 'landing') => void,
  updatePreviewWeb: (previewWeb: string) => void,
  ...
}

class ReviewReviewMenu extends React.PureComponent<ReviewReviewMenuProps> {
  render(): React.Node {
    const { activeStep, isPush } = this.props

    return (
      <FlexLine style={{ justifyContent: 'space-between' }}>
        <ReviewNavSecondLinks style={{ flex: 1 }}>
          <SubLink active={activeStep === 'targeting'} href="#targeting">
            Targeting
          </SubLink>
          <SubLink
            active={activeStep === 'timing' || activeStep === 'trigger'}
            href={isPush ? '#timing' : '#trigger'}
          >
            {this.props.isPush ? 'Timing' : 'Trigger'}
          </SubLink>
          <SubLink active={activeStep === 'content'} href="#content">
            Content
          </SubLink>
          {this.props.isPush && this.props.hasLanding && (
            <SubLink active={activeStep === 'landing'} href="#mobile-landing">
              Mobile landing
            </SubLink>
          )}

          <SubLink active={activeStep === 'settings'} href="#settings">
            Settings
          </SubLink>
        </ReviewNavSecondLinks>
        <ToolbarContainer>
          <PreviewToolbar
            hasLanding={this.props.hasLanding}
            isPush={this.props.isPush}
            toggleFullScreen={this.props.toggleFullScreen}
            installLoading={this.props.templateLoading}
            previewMode={this.props.previewMode}
            isWeb={this.props.platform === 'webpush'}
            previewWeb={this.props.previewWeb}
            updatePreviewMode={this.props.updatePreviewMode}
            updatePreviewWeb={this.props.updatePreviewWeb}
            changePreferedInstall={this.props.changePreferedInstall}
            installIds={this.props.installIds}
            installId={this.props.installId}
          />
        </ToolbarContainer>
      </FlexLine>
    )
  }
}

export default ReviewReviewMenu
