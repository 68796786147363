// @flow
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box } from 'components/common/box'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import {
  Table,
  TableBody,
  TableHeader,
  TableCellHeader,
  TableCellOrder,
} from 'components/common/table'
import { TableTemplateCell } from 'components/common/table/table'
import { Title } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { StepsAnalyticsRow } from './steps-analytics-row'

import {
  StatsContainer,
  StatsBlocksRow,
} from '../orchestration-analytics/orchestration-analytics.styles'

import { type OrchestrationStepRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { fetchOrchestrationStatsByStep } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-steps'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { STATUS } from 'constants/common'

type StepsAnalyticsProps = {
  steps: Array<OrchestrationStepRecord>,
}

type StepsSortOptions = {
  order: 'asc' | 'dsc',
  sortBy: 'delivered.value' | 'open.rate' | 'click.rate' | 'bounce.value' | 'unsubscribe.value',
}

const StepsAnalyticsEmptyTemplate = (): React.Node => (
  <React.Fragment>
    <TableTemplateCell template="1fr" />
    {Array(5)
      .fill(0)
      .map((_, i) => (
        <TableTemplateCell template="1fr" align="start" key={i} />
      ))}
  </React.Fragment>
)

export const StepsAnalytics = ({ steps }: StepsAnalyticsProps): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const { nodesMap } = useSelector(journeyTreeSelector)
  const stepsLoadingState = useSelector(
    state => state.orchestrationAnalytics.get(token)?.stepsLoadingState
  )
  const isLoading = React.useMemo(
    () => [STATUS.LOADING, STATUS.INIT].includes(stepsLoadingState),
    [stepsLoadingState]
  )
  const isOverlayShown = React.useMemo(
    () => stepsLoadingState === STATUS.ERROR,
    [stepsLoadingState]
  )

  const [sortOptions, setSortOptions] = React.useState<StepsSortOptions>({
    order: 'dsc',
    sortBy: 'delivered.value',
  })
  const onSortBy = React.useCallback(
    (sortBy: StepsSortOptions['sortBy']) => () => {
      if (sortOptions.sortBy === sortBy)
        setSortOptions(prev => ({
          ...prev,
          order: prev.order === 'asc' ? 'dsc' : 'asc',
        }))
      else
        setSortOptions({
          order: 'dsc',
          sortBy: sortBy,
        })
    },
    [sortOptions]
  )
  const shownSteps = React.useMemo(
    () =>
      steps?.sort((a, b) => {
        const [stat, unit] = sortOptions.sortBy.split('.')
        const compare = a.stats[stat][unit] > b.stats[stat][unit]
        if (sortOptions.order === 'asc') return compare ? 1 : -1
        return compare ? -1 : 1
      }),
    [steps, sortOptions]
  )

  React.useEffect(() => {
    if (token) dispatch(fetchOrchestrationStatsByStep({ token })).catch(() => {})
  }, [dispatch, token])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ marginTop: 2, marginBottom: 24 }}>
        <Title>Step by step</Title>
      </ChannelSectionTitle>
      <StatsBlocksRow>
        <Wrapper
          isLoading={isLoading}
          isEmpty={false}
          isOverlayShown={isOverlayShown}
          overlayProps={GlobalErrorOverlayProps}
          boxed
        >
          <Box
            style={{
              width: 1148,
              overflowY: 'auto',
              maxHeight: 292,
            }}
          >
            <Table rowHeight={56} template="1fr repeat(4, 122px) 133px" style={{ marginBottom: 4 }}>
              <TableHeader
                style={{
                  height: 52,
                  backgroundColor: 'white',
                  position: 'sticky',
                  zIndex: 10,
                  top: 0,
                  paddingRight: 10,
                }}
              >
                <TableCellHeader style={{ color: colors.text }}>Step name</TableCellHeader>
                <TableCellOrder
                  sort={sortOptions.sortBy === 'delivered.value' ? sortOptions.order : false}
                  onClick={onSortBy('delivered.value')}
                >
                  Delivered
                </TableCellOrder>
                <TableCellOrder
                  sort={sortOptions.sortBy === 'open.rate' ? sortOptions.order : false}
                  onClick={onSortBy('open.rate')}
                >
                  Opens
                </TableCellOrder>
                <TableCellOrder
                  sort={sortOptions.sortBy === 'click.rate' ? sortOptions.order : false}
                  onClick={onSortBy('click.rate')}
                >
                  Clicks
                </TableCellOrder>
                <TableCellOrder
                  sort={sortOptions.sortBy === 'bounce.value' ? sortOptions.order : false}
                  onClick={onSortBy('bounce.value')}
                >
                  Bounces
                </TableCellOrder>
                <TableCellOrder
                  sort={sortOptions.sortBy === 'unsubscribe.value' ? sortOptions.order : false}
                  onClick={onSortBy('unsubscribe.value')}
                >
                  Unsubscribes
                </TableCellOrder>
              </TableHeader>
              <TableBody emptyTemplate={<StepsAnalyticsEmptyTemplate />}>
                {shownSteps.map(step => {
                  const node = nodesMap.get(step.step)
                  const label = node?.type === 'MESSAGE' ? node.label : ''
                  return <StepsAnalyticsRow step={step} label={label} key={step.step} />
                })}
              </TableBody>
            </Table>
          </Box>
        </Wrapper>
      </StatsBlocksRow>
    </StatsContainer>
  )
}
