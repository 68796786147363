// @flow

import { scaleBand } from 'd3-scale'
import React from 'react'

import { type Label } from 'com.batch/shared/infra/types/chart-data'

/**
 * Custom hook to managing modular bar charts
 */

type Props = { labels: Array<Label>, width: number, activeScaleLines?: boolean }
type Result = {
  labels: Array<Label>,
  labelsName: Array<string>,
  nbBars: number,
  width: number,
  xScale: typeof scaleBand,
  activeScaleLines: boolean,
  hoveredBar: ?string,
  setHoveredBar: (label: ?string) => void,
}
export const useModularBarChart = ({ labels, width, activeScaleLines = false }: Props): Result => {
  const [hoveredBar, setHoveredBar] = React.useState(null)

  const labelsName = labels.map(d => String(d.label))

  const nbBars = labels.length

  const xScale = scaleBand<String>()
    .domain(labelsName)
    .range([0, width - (activeScaleLines ? 50 : 0)])

  return { labels, labelsName, nbBars, width, xScale, activeScaleLines, hoveredBar, setHoveredBar }
}
