// @flow

import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import { ButtonNavLink } from 'components/common/button'
import {
  FullPageContent,
  FullPageTitle,
  FullPageSubtitle,
  FullPageFooter,
} from 'components/common/full-page'
import { TabButton, TabButtonItem } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

// eslint-disable-next-line import/extensions
import { MobileSvg } from './mobile.svg.js'
// eslint-disable-next-line import/extensions
import { WebSvg } from './web.svg.js'

import { type CompanyRecord, type AppRecord, AppFactory } from 'com.batch.redux/_records'

type StepKindProps = {
  company: CompanyRecord,
  app: AppRecord,
  setApp: AppRecord => any,
  ...
}

const StepKindRaw = ({ company, app, setApp }: StepKindProps) => {
  const [appKindHover, setAppKindHover] = React.useState<?('web' | 'mobile')>(null)
  const isMobile = app.platform !== 'webpush'
  const createAppKindChange = React.useCallback(
    (kind: ?('web' | 'mobile')) => () => setAppKindHover(kind)
  )
  const createAppSelectCallback = React.useCallback(
    (kind: 'web' | 'mobile') => () => {
      if (kind === 'web') setApp(AppFactory({ platform: 'webpush', sdk: 'webpush' }))
      else setApp(AppFactory({ platform: 'mobile' }))
    },
    [setApp]
  )
  return (
    <FullPageContent>
      <Helmet>
        <title>Batch - Add an app or website</title>
      </Helmet>
      <FullPageTitle>Mobile or web</FullPageTitle>
      <FullPageSubtitle style={{ maxWidth: 460 }}>
        Do you want to add a mobile app or a website?
      </FullPageSubtitle>
      <TabButton grow>
        <TabButtonItem
          isActive={isMobile}
          onClick={createAppSelectCallback('mobile')}
          onMouseEnter={createAppKindChange('mobile')}
          onMouseOut={createAppKindChange(null)}
        >
          <MobileSvg mode={isMobile ? 'active' : appKindHover === 'mobile' ? 'hover' : 'normal'} />
          <span style={{ display: 'block', margin: '8px 0 14px 0', pointerEvents: 'none' }}>
            Mobile
          </span>
        </TabButtonItem>
        <TabButtonItem
          isActive={!isMobile}
          onClick={createAppSelectCallback('web')}
          onMouseEnter={createAppKindChange('web')}
          onMouseOut={createAppKindChange(null)}
        >
          <WebSvg mode={!isMobile ? 'active' : appKindHover === 'web' ? 'hover' : 'normal'} />
          <span style={{ display: 'block', margin: '8px 0 14px 0', pointerEvents: 'none' }}>
            Web
          </span>
        </TabButtonItem>
      </TabButton>
      <FullPageFooter>
        <ButtonNavLink
          intent="action"
          kind="primary"
          to={generateUrl('app_new_url', {
            companyId: company.id,
            kind: isMobile ? 'mobile' : 'web',
          })}
        >
          Continue
        </ButtonNavLink>
      </FullPageFooter>
    </FullPageContent>
  )
}
export const StepKind: React.AbstractComponent<StepKindProps> =
  React.memo<StepKindProps>(StepKindRaw)
