// @flow

import dagre from '@dagrejs/dagre'

import { type ReactFlowEdge, type ReactFlowNode, type Position } from './reactflow.types'

const computeNodeDimensions = (node: ReactFlowNode): { width: number, height: number, ... } => {
  if (node.sizingType === 'rejoin') return { width: 40, height: 10 }
  if (node.sizingType === 'add') return { width: 80, height: 10 }
  if (node.sizingType === 'MESSAGE') return { width: 280, height: 190 }
  if (node.sizingType === 'TIMER') return { width: 188, height: 38 }
  if (node.sizingType === 'YESNO') return { width: 280, height: 80 }
  if (node.sizingType === 'RANDOM') return { width: 280, height: 70 }
  if (node.sizingType === 'ROOT') return { width: 280, height: 115 }
  if (node.sizingType === 'FINAL') return { width: 280, height: 30 }
  return { width: 100, height: 20 }
}

export const autoLayout = (
  nodes: Array<ReactFlowNode>,
  edges: Array<ReactFlowEdge>
): Array<ReactFlowNode> => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({ width: 0, height: 38 }))
  dagreGraph.setGraph({
    rankdir: 'TB',
    marginy: 20,
    nodesep: 50,
    ranksep: 10,
    edgesep: 0,
    // ranker: 'longest-path',
    // ranker: 'network-simplex',
    ranker: 'tight-tree',
  })
  nodes.forEach((node: ReactFlowNode) => {
    dagreGraph.setNode(node.id, computeNodeDimensions(node))
  })

  edges.forEach((edge: ReactFlowEdge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)
  return nodes.map(node => {
    const { x, y }: Position = dagreGraph.node(node.id)
    const { height } = computeNodeDimensions(node)
    // $FlowExpectedError(speculation-ambiguous)
    const updatedNode: ReactFlowNode = {
      ...node,
      position: { x: x, y: y - height / 2 },
    }
    return updatedNode
  })
}
