// @flow
import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Separator } from 'components/app/custom-data/custom-data.styles'
import { Alert } from 'components/common/alert'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button, Switch } from 'components/common/button'
import { ConditionLine, FlexLine, FlexLineItem } from 'components/common/flexline'
import Highlight from 'components/common/highlight'
import { Hint } from 'components/common/hint'
import Loader from 'components/common/loader-legacy'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'
import { LinkDoc } from 'components/styled/text'
import { AttributePicker } from 'components/targeting/attribute-picker'
import { CategoryFilter } from 'components/targeting/category-filter'
import { ClusterBtn } from 'components/targeting/cluster-btn'
import { Logical } from 'components/targeting/logical'
import { ReviewTargeting } from 'components/targeting/review-targeting'

import config from 'com.batch.common/config'
import { kformat } from 'com.batch.common/utils'

import { CustomAudiencePicker } from './custom-audience-picker'
import { LangRegionTargeting } from './lang-region-targeting'

import { type AppRecord, type AttributeRecord } from 'com.batch.redux/_records'

const MAX_CONDITIONS = 31

export type EstimateMode = 'installs' | 'tokens'

type Props = {
  app: AppRecord,
  ttlRetargeting: number,
  conditionsCount: number,
  canUseCluster: ?boolean,
  canUseCustomAudiences: boolean,
  canQueryEventData: boolean,
  canQuery: boolean,
  canQueryAttribute: boolean,
  platform: string,
  canUseGeoloc: boolean,
  canUseEventCountPeriod: boolean,
  canRetargetInApp: boolean,
  canRetarget: boolean,
  loading: boolean,
  header: string,
  query: string,
  queryError: boolean,
  estimateMode: EstimateMode,
  attributes: any,
  clusters: any,
  estimate: any,
  languages: any,
  regions: any,
  pickedAudiences: any,
  pickedLanguages: any,
  pickedRegions: any,
  tree: any,
  attributesCategories: any,
  filterTerm: string,
  filterCat: ?string,
  attributesValuesLoading: any,
  attributesValues: any,
  addCondition: (condition: any) => void,
  updateCustomAudiences: () => void,
  updateFilterTerm: () => void,
  updateLangRegion: () => void,
  toggleJIT: () => void,
  JIT: boolean,
  addLogical: () => void,
  deleteLogical: () => void,
  toggleLogical: () => void,
  toggleCondition: () => void,
  refreshEstimate: () => void,
  updateCondition: () => void,
  deleteCondition: () => void,
  updateFilterCat: () => void,
  updateClusterActiveState: () => void,
  createAttributeValue: () => void,
}

type State = {
  picker: boolean,
  display: string,
  attributes: Set<AttributeRecord>,
  pickerPosition?: string,
}

export class TargetingForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      picker: false,
      display: 'edit',
      attributes: Immutable.Set(),
    }
  }

  openPicker: (pickerPosition: string) => void = (pickerPosition = 'root') =>
    this.setState({ picker: true, attributes: Immutable.Set(), pickerPosition })

  closePicker: () => void = () => this.setState({ picker: false, attributes: Immutable.Set() })

  addConditionForSelectedAttrs: () => void = () => {
    this.state.attributes.forEach(attr => {
      this.props.addCondition({
        attribute: attr,
        logicalId: this.state.pickerPosition,
      })
    })
    this.closePicker()
  }

  changeDisplay: (kind: string) => void = kind => this.setState({ display: kind })

  toggleAttr: (attr: AttributeRecord) => void = attr => {
    if (this.state.attributes.has(attr)) {
      this.setState({ attributes: this.state.attributes.remove(attr) })
    } else {
      this.setState({ attributes: this.state.attributes.add(attr) })
    }
  }

  onKeyUp: (event: KeyboardEvent) => void = event => {
    if (event.code === 'Escape') {
      this.closePicker()
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp)
  }

  renderLangRegion(isLang: boolean): React.Node {
    const options = isLang ? this.props.languages : this.props.regions
    const values = isLang ? this.props.pickedLanguages : this.props.pickedRegions
    return (
      <LangRegionTargeting
        loading={false}
        lang={isLang}
        hasQuery={!!this.props.query}
        updateLangRegion={this.props.updateLangRegion}
        options={options}
        values={values}
      />
    )
  }

  renderJIT(): React.Node {
    return (
      <FlexLine>
        <FlexLineItem
          grow={1}
          style={{
            borderBottom: '1px solid #E9E9E9',
            marginBottom: 16,
            paddingBottom: 11,
            paddingTop: 8,
          }}
        >
          <ThemeProvider theme={{ horizontal: true }}>
            <Switch isActive={this.props.JIT} onChange={this.props.toggleJIT}>
              Re-evaluate targeting just before display{' '}
              <Hint minTooltipWidth={300}>
                Use this option for scenarios with sensitive targeting conditions, prone to change
                during user navigation. It will result in a slight delay in display. (SDK version
                1.19 and above)
              </Hint>
            </Switch>
          </ThemeProvider>
        </FlexLineItem>
      </FlexLine>
    )
  }

  renderEstimate(): React.Node {
    const mode = this.props.estimateMode
    const est = this.props.estimate.get(mode)
    const err = this.props.estimate.get('error', false)
    const matchingNotifOn = est.get(mode === 'tokens' ? 'matchingNotifOn' : 'matching', 0)
    const matching = est.get('matching', 0)

    return (
      <React.Fragment>
        {mode === 'installs' &&
          this.props.app.features.has('inapp-just-in-time') &&
          this.renderJIT()}
        <FlexLine>
          <FlexLineItem custom="audience">
            <Tooltip
              tooltip={
                err
                  ? 'Unable to estimate'
                  : `This campaign might reach ${
                      mode === 'tokens' && matchingNotifOn
                        ? matchingNotifOn.toLocaleString()
                        : matching.toLocaleString().toLocaleString()
                    } ${mode === 'tokens' && matchingNotifOn ? 'optin' : ''} users`
              }
            >
              <div>
                <h3 className="audience__title">Estimated reach</h3>
                <div className="audience__reach">
                  <Loader
                    overlay={false}
                    height={26}
                    size="tiny"
                    left
                    loading={this.props.estimate.get('loading')}
                  >
                    <strong>
                      {err ? 'Unable to estimate' : kformat(est.get('matching', ''))} {!err && mode}
                    </strong>{' '}
                    /&nbsp;
                    {!err && kformat(est.get('total', ''))}{' '}
                    {err ? '' : mode === 'tokens' ? 'total push tokens' : 'total installs'}
                    {!!err && (
                      <Button
                        height={24}
                        intent="action"
                        kind="primary"
                        onClick={this.props.refreshEstimate}
                      >
                        <span style={{ marginTop: '-2px' }}>Retry</span>
                      </Button>
                    )}
                  </Loader>
                </div>
              </div>
            </Tooltip>
          </FlexLineItem>
        </FlexLine>
      </React.Fragment>
    )
  }

  render(): React.Node {
    const {
      clusters,
      header,
      app,
      updateClusterActiveState,
      canUseGeoloc,
      canUseCustomAudiences,
      canRetarget,
      canQueryAttribute,
      canRetargetInApp,
      canUseCluster,
      attributesCategories,
      updateFilterCat,
      filterTerm,
      conditionsCount,
      updateFilterTerm,
      attributes,
      canQuery,
    } = this.props

    const count = this.state.attributes.size
    const ratio =
      clusters.size < 6 ? 100 / clusters.size : clusters.size === 7 ? 25 : 200 / clusters.size

    const DisplayMode = (
      <TableToggle>
        <TableToggleItem
          style={{ margin: 0 }}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => this.changeDisplay('edit')}
          active={this.state.display === 'edit'}
        >
          Edit
        </TableToggleItem>
        <TableToggleItem
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => this.changeDisplay('review')}
          active={this.state.display === 'review'}
        >
          Review
        </TableToggleItem>
        <TableToggleItem
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => this.changeDisplay('query')}
          active={this.state.display === 'query'}
          disabled={!this.props.query}
        >
          Query
        </TableToggleItem>
      </TableToggle>
    )
    return (
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title={header} />
          <HeaderBoxActions>{DisplayMode}</HeaderBoxActions>
        </BoxHeader>

        {this.state.display === 'edit' && (
          <div>
            <ConditionLine>
              <FlexLineItem grow={1} style={{ margin: '18px 0px 10px 30px' }}>
                {canUseCluster ? (
                  <div className="clustContainer">
                    {clusters.map((c, i) => {
                      return (
                        <Tooltip tooltip={c.get('desc')} key={`tt-${c.get('code')}`}>
                          <ClusterBtn
                            code={c.get('code')}
                            label={c.get('name')}
                            ratio={clusters.size === 7 && i > 3 ? 33.3333 : ratio}
                            desc={c.get('desc')}
                            handler={updateClusterActiveState}
                            active={c.get('active')}
                          />
                        </Tooltip>
                      )
                    })}
                  </div>
                ) : (
                  <div className="review__upgrade ng-scope" style={{ marginTop: 0 }}>
                    <span>
                      <i className="fa fa-lock mr-2x" />
                      <strong>Smart segments</strong> targeting is only available to Developer plan.
                    </span>{' '}
                    <a
                      className="btn btn--action"
                      href={config.billing.urls.overviewPage.replace('{companyId}', app.companyId)}
                    >
                      Upgrade now
                    </a>
                  </div>
                )}
              </FlexLineItem>
            </ConditionLine>
            {canUseCustomAudiences && (
              <CustomAudiencePicker
                appId={this.props.app.id}
                values={this.props.pickedAudiences}
                updateCustomAudiences={this.props.updateCustomAudiences}
              />
            )}
            {this.renderLangRegion(false)}
            {this.renderLangRegion(true)}
            <Popin
              opened={this.state.picker}
              close={this.closePicker}
              style={{ maxWidth: '998px', width: '100%', minHeight: '600px' }}
            >
              <Box>
                <BoxHeader style={{ borderBottom: 'none' }}>
                  <HeaderBoxTitle title="Pick additional targetings" />
                  <HeaderBoxActions>
                    <LinkDoc
                      href="https://doc.batch.com/guides/custom-data.html"
                      intent="action"
                      target="_blank"
                    >
                      Help
                    </LinkDoc>
                    <Separator style={{ marginLeft: 0 }} />
                    <Button onClick={this.closePicker} style={{ width: 36 }}>
                      <Icon icon="close" />
                    </Button>
                  </HeaderBoxActions>
                </BoxHeader>
                <BoxBody>
                  <FlexLine sameHeight className="popin__content__targeting">
                    <FlexLineItem width={320}>
                      <CategoryFilter
                        app={app}
                        categories={attributesCategories.toJS()}
                        updateCat={updateFilterCat}
                      />
                    </FlexLineItem>
                    <FlexLineItem grow={1}>
                      <div
                        className="tab-content"
                        style={{ height: '100%', width: '100%', overflowY: 'auto' }}
                      >
                        <FilterSearch
                          value={filterTerm}
                          placeholder="Search additional targetings..."
                          onChange={updateFilterTerm}
                          expandable={false}
                          style={{ marginBottom: 10 }}
                        />
                        <AttributePicker
                          app={app}
                          attributes={attributes}
                          canQuery={canQuery}
                          canQueryAttribute={canQueryAttribute}
                          canUseAudience={canUseCustomAudiences}
                          canRetarget={canRetarget}
                          canRetargetInApp={canRetargetInApp}
                          canUseGeoloc={canUseGeoloc}
                          conditionsCount={conditionsCount}
                          selectedSet={this.state.attributes}
                          toggleAttr={this.toggleAttr}
                        />
                      </div>
                    </FlexLineItem>
                  </FlexLine>
                </BoxBody>
                <BoxFooter isEditable>
                  <Button kind="inline" onClick={this.closePicker}>
                    Cancel
                  </Button>
                  <FooterBoxActions>
                    {this.props.conditionsCount >= MAX_CONDITIONS ? (
                      <span>Limit reached : max {MAX_CONDITIONS} conditions</span>
                    ) : null}

                    <Button
                      kind="primary"
                      intent="action"
                      onClick={this.addConditionForSelectedAttrs}
                      disabled={count === 0}
                    >
                      {`Add condition${count > 1 ? `s (${count})` : ''}`}
                    </Button>
                  </FooterBoxActions>
                </BoxFooter>
              </Box>
            </Popin>
            <Loader loading={this.props.loading} height={60}>
              {this.props.queryError ? (
                <div className="stepbox__content">
                  <Alert kind="error" icon="danger">
                    <p>We were unable to parse your query.</p>
                    <p style={{ marginBottom: '20px' }}>
                      This may be an error on your side, or you could be using features only
                      available on the API. You can still edit the campaign, we'll keep the query as
                      it currently is:
                    </p>
                    <Highlight language="js">{this.props.query}</Highlight>
                  </Alert>
                </div>
              ) : (
                <Logical
                  root={this.props.tree}
                  app={this.props.app}
                  ttlRetargeting={this.props.ttlRetargeting}
                  canUseEventCountPeriod={this.props.canUseEventCountPeriod}
                  canQueryEventData={this.props.canQueryEventData}
                  platform={this.props.platform}
                  logical={this.props.tree.get('value')}
                  openPicker={this.openPicker}
                  deleteLogical={this.props.deleteLogical}
                  addLogical={this.props.addLogical}
                  toggleLogical={this.props.toggleLogical}
                  toggleCondition={this.props.toggleCondition}
                  attributesValues={this.props.attributesValues}
                  attributesValuesLoading={this.props.attributesValuesLoading}
                  createAttributeValue={this.props.createAttributeValue}
                  updateCondition={this.props.updateCondition}
                  deleteCondition={this.props.deleteCondition}
                />
              )}
            </Loader>
          </div>
        )}
        {this.state.display === 'review' && <ReviewTargeting />}
        {this.state.display === 'query' && (
          <div className="stepbox__content" style={{ minHeight: '240px' }}>
            <Highlight language="js">{this.props.query}</Highlight>
          </div>
        )}
        {this.props.query &&
          this.props.clusters.filter(c => c.code === 'I' && c.active).size === 1 && (
            <div style={{ margin: '20px 20px 0 20px' }}>
              <Alert icon="danger" kind="warning">
                Your targeting uses both <strong>imported users</strong> and{' '}
                <strong>conditions</strong>. While this is supported, this can lead to unexpected
                results. Please make sure the estimated reach matches your expectations.
              </Alert>
            </div>
          )}
        <BoxFooter
          isEditable
          style={{
            height:
              this.props.estimateMode === 'installs' &&
              this.props.app.features.has('inapp-just-in-time')
                ? 'auto'
                : '76px',
            display:
              this.props.estimateMode === 'installs' &&
              this.props.app.features.has('inapp-just-in-time')
                ? 'block'
                : 'flex',
            padding: '10px 20px',
          }}
        >
          {this.renderEstimate()}
        </BoxFooter>
      </Box>
    )
  }
}
