// @flow

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// eslint-disable-next-line import/no-cycle
import {
  Box,
  BoxBody,
  BoxHeader,
  BoxSection,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Radio } from 'components/form'
import { LightText } from 'components/styled/text'

import { pluralize, ucFirst } from 'com.batch.common/utils'

import { NodePreviewContainer, OptionWrapper } from './delete-node.styles'
import { RandomButton } from './nodes/random-button'
import { YesNoButton } from './nodes/yesno-button'

import { type BranchId } from 'com.batch/orchestration-journey/models/journey.records'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import {
  getAllNodeBranchIds,
  countMessageNodesForSplitBranch,
} from 'com.batch/orchestration-journey/models/tree.helpers'
import { removeNodeAndUpdateTree } from 'com.batch/orchestration-journey/usecases/remove-node'

const emptyFunc = () => {}

type RemoveNodeModalProps = {
  nodeId: string,
  onClose: () => void,
  callbackAfterDelete?: () => void,
}

export const RemoveNodeModal = ({
  nodeId,
  onClose,
  callbackAfterDelete,
}: RemoveNodeModalProps): React.Node => {
  const dispatch = useDispatch()
  const { nodesMap } = useSelector(journeyTreeSelector)
  const node = React.useMemo(() => nodesMap.get(nodeId), [nodesMap, nodeId])

  const branchIdsWithCount = React.useMemo(() => {
    if (!node) return []
    return getAllNodeBranchIds(node).map(branchId => ({
      branchId,
      label:
        branchId.type === 'YESNO'
          ? `Keep the '${ucFirst(branchId.branch)}' branch`
          : branchId.type === 'RANDOM'
            ? `Keep the ${
                node.type === 'RANDOM' ? node.splits.getIn([branchId.splitIndex, 'weight'], '') : ''
              }% branch`
            : 'Next',
      count:
        node.type === 'YESNO' || node.type === 'RANDOM'
          ? countMessageNodesForSplitBranch(nodesMap, branchId)
          : 0,
    }))
  }, [node, nodesMap])
  const [deleteKeep, setDeleteKeep] = React.useState<?BranchId>(
    branchIdsWithCount.length > 0
      ? branchIdsWithCount[0].branchId
      : node?.type === 'TIMER'
        ? {
            type: 'TIMER-NEXT',
            timerNodeId: nodeId,
          }
        : node?.type === 'MESSAGE'
          ? {
              type: 'MESSAGE',
              messageNodeId: nodeId,
            }
          : null
  )

  const showPreviewAndChoice = React.useMemo(() => {
    if (!node) return false
    if (node.type === 'YESNO') return node.yesNodeId !== node.noNodeId
    if (node.type === 'RANDOM') return node.splits.map(s => s.nextNodeId).toSet().size > 1
    return false
  }, [node])

  const createOnDeleteKeepChange = React.useCallback(
    (branchId: ?BranchId) => () => {
      setDeleteKeep(branchId)
    },
    []
  )

  const onConfirm = React.useCallback(() => {
    if (node)
      dispatch(
        removeNodeAndUpdateTree({
          node,
          keep: deleteKeep,
        })
      )
    if (callbackAfterDelete) {
      callbackAfterDelete()
    }
    onClose()
  }, [node, dispatch, deleteKeep, onClose, callbackAfterDelete])
  return (
    <Popin close={onClose} opened>
      <Box style={{ width: showPreviewAndChoice ? 520 : 400 + 'px', position: 'relative' }}>
        <BoxHeader>
          <HeaderBoxTitle title="Delete this step?" />
          <HeaderBoxActions>
            <Button autoFocus kind="inline" onClick={onClose} style={{ height: 28, width: 28 }}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          {showPreviewAndChoice ? (
            <React.Fragment>
              <BoxSection style={{ margin: '-1px 0' }}>
                <NodePreviewContainer>
                  {node && node.type === 'YESNO' && (
                    <YesNoButton
                      onClick={emptyFunc}
                      reduxNodeId=""
                      errors={node.errors}
                      label={node.label}
                    />
                  )}
                  {node && node.type === 'RANDOM' && (
                    <RandomButton
                      onClick={emptyFunc}
                      reduxNodeId=""
                      errors={node.errors}
                      randomNode={node}
                    />
                  )}
                </NodePreviewContainer>
              </BoxSection>
              <BoxSection $padding style={{ margin: '-1px 0' }}>
                {branchIdsWithCount.length > 1 && (
                  <React.Fragment>
                    <p>
                      This condition has several branches, you need to choose what do you want to do
                      with them:
                    </p>
                    {branchIdsWithCount.map(({ branchId, label, count }, index) => (
                      <OptionWrapper key={index}>
                        <Radio
                          label={
                            <React.Fragment>
                              {label}
                              <LightText>
                                &nbsp;&mdash;&nbsp;
                                {pluralize('message', count)}
                              </LightText>
                            </React.Fragment>
                          }
                          checked={deleteKeep === branchId}
                          onChange={createOnDeleteKeepChange(branchId)}
                        />
                      </OptionWrapper>
                    ))}
                    <OptionWrapper>
                      <Radio
                        label={
                          <React.Fragment>
                            Delete all branches
                            <LightText>&nbsp;&mdash;&nbsp;until they reunite</LightText>
                          </React.Fragment>
                        }
                        checked={deleteKeep === null}
                        onChange={createOnDeleteKeepChange(null)}
                      />
                    </OptionWrapper>
                  </React.Fragment>
                )}
              </BoxSection>
            </React.Fragment>
          ) : (
            <BoxSection $padding style={{ margin: '-1px 0' }}>
              This will delete this step.
            </BoxSection>
          )}
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" onClick={onClose}>
            Cancel
          </Button>

          <Button kind="primary" intent="danger" onClick={onConfirm}>
            Confirm and delete
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
