// @flow

import { type Dayjs } from 'dayjs'
import Immutable, {
  type List,
  type Map,
  type RecordOf,
  type RecordFactory,
  type Set,
} from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { AnalyticDataFactory, type AnalyticDataRecord } from 'com.batch.redux/stat.records'
import { UserFactory, type UserRecord } from 'com.batch.redux/user.records'

// ====================== APPS
// app filter
type AppFilterProps = {
  companyId: ?number,
  query: string,
  ...
}
export const AppFilterFactory: RecordFactory<AppFilterProps> = Immutable.Record(
  ({
    companyId: null,
    query: '',
  }: AppFilterProps)
)

export type AppFilterRecord = RecordOf<AppFilterProps>

// app state
type AppStateProps = {
  entities: Map<number, AppRecord>,
  idsPerPage: Map<number, List<number>>,
  loading: boolean,
  page: number,
  count: number,
  query: string,
  idsPerCompany: Map<number, List<number>>,
  nbPerPage: number,
  filters: AppFilterRecord,
  ...
}
export const AppStateFactory: RecordFactory<AppStateProps> = Immutable.Record(
  ({
    entities: Immutable.Map(),
    idsPerPage: Immutable.Map(),
    loading: false,
    page: 0,
    count: 0,
    query: '',
    idsPerCompany: Immutable.Map(),
    nbPerPage: 10,
    filters: AppFilterFactory(),
    codes: new Immutable.List(),
  }: AppStateProps)
)

export type AppStateRecord = RecordOf<AppStateProps>

// ====================== USERS
type UserFilterProps = {
  companyId: ?number,
  search: string,
  ...
}
export const UserFilterFactory: RecordFactory<UserFilterProps> = Immutable.Record(
  ({
    companyId: null,
    search: '',
  }: UserFilterProps)
)

export type UserFilterRecord = RecordOf<UserFilterProps>

export type UserStateProps = {
  filters: UserFilterRecord,
  entities: Map<number, UserRecord>,
  newUser: UserRecord,
  idsPerPage: Map<number, List<number>>,
  loading: boolean,
  count: number,
  page: number,
  nbPerPage: number,
  ...
}

export const UserStateFactory: RecordFactory<UserStateProps> = Immutable.Record(
  ({
    filters: UserFilterFactory(),
    entities: Immutable.Map(),
    newUser: UserFactory(),
    idsPerPage: Immutable.Map(),
    loading: false,
    count: 0,
    page: 0,
    nbPerPage: 10,
  }: UserStateProps)
)

export type UserStateRecord = RecordOf<UserStateProps>

export type State = {
  app: AppStateRecord,
  company: CompanyStateRecord,
  user: UserStateRecord,
  code: CodeStateRecord,
  project: ProjectStateRecord,
  ...
}

// ====================== METADATA
export type Flux =
  | 'ucp_native_events_sdk'
  | 'ucp_custom_events_sdk'
  | 'ucp_custom_events_api'
  | 'ucp_custom_data_api'
  | 'ucp_gdpr_api'
  | 'ucp_gdpr_sdk'
  | 'profile_api_data_compatibility'
  | 'profile_api_event_compatibility'
  | 'ucp_push_subscriptions'

type PlatformFluxProps = {
  ios: Set<Flux>,
  android: Set<Flux>,
  webpush: Set<Flux>,
  ...
}

export const coerceStringToFlux = (s: string): ?Flux => {
  if (
    s === 'ucp_native_events_sdk' ||
    s === 'ucp_custom_events_sdk' ||
    s === 'ucp_custom_events_api' ||
    s === 'ucp_custom_data_api' ||
    s === 'ucp_gdpr_api' ||
    s === 'ucp_gdpr_sdk' ||
    s === 'profile_api_data_compatibility' ||
    s === 'profile_api_event_compatibility' ||
    s === 'ucp_push_subscriptions'
  ) {
    return s
  }
  return undefined
}

export const PlatformFluxFactory: RecordFactory<PlatformFluxProps> = Immutable.Record(
  ({
    ios: Immutable.Set(),
    android: Immutable.Set(),
    webpush: Immutable.Set(),
  }: PlatformFluxProps)
)

export type PlatformFluxRecord = RecordOf<PlatformFluxProps>

// ====================== COMPANY
type CompanyStateProps = {
  count: number,
  loading: boolean,
  entities: Map<number, CompanyRecord>,
  nbPerPage: number,
  idsPerPage: Map<number, List<number>>,
  page: number,
  filter: string,
  ...
}
export const CompanyStateFactory: RecordFactory<CompanyStateProps> = Immutable.Record(
  ({
    count: 0,
    loading: false,
    entities: Immutable.Map(),
    nbPerPage: 0,
    idsPerPage: Immutable.Map(),
    page: 0,
    filter: '',
  }: CompanyStateProps)
)

export type CompanyStateRecord = RecordOf<CompanyStateProps>

// ====================== COMPANY
type ProjectStateProps = {
  count: number,
  loading: boolean,
  entities: Map<string, ProjectRecord>,
  nbPerPage: number,
  idsPerPage: Map<number, List<string>>,
  page: number,
  filters: AppFilterRecord,
  ...
}
export const ProjectStateFactory: RecordFactory<ProjectStateProps> = Immutable.Record(
  ({
    count: 0,
    loading: false,
    entities: Immutable.Map(),
    nbPerPage: 0,
    idsPerPage: Immutable.Map(),
    page: 0,
    filters: AppFilterFactory(),
  }: ProjectStateProps)
)
export type ProjectStateRecord = RecordOf<ProjectStateProps>

// ====================== CONSOLE STATS
type AnalyticByCompanyProp = {
  companyId: number | null,
  data: AnalyticDataRecord,
  ...
}
export const AnalyticByCompanyFactory: RecordFactory<AnalyticByCompanyProp> = Immutable.Record(
  ({
    companyId: null,
    data: AnalyticDataFactory(),
  }: AnalyticByCompanyProp)
)

export type AnalyticByCompanyRecord = RecordOf<AnalyticByCompanyProp>

// ====================== EXPORTS
// Data Export Job
type DataExportJobProps = {
  entity: ?('campaign' | 'in-app-campaign' | 'transactional' | 'userbase' | 'reachability'),
  kind: ?('ids' | 'events'),
  identifier: ?string,
  from: Dayjs | null,
  to: Dayjs | 'now' | null,
  events: Array<
    'push_sent' | 'push_open' | 'push_error' | 'inapp_shown' | 'inapp_clicked' | 'inapp_closed',
  >,
  ids: Array<'install_id' | 'custom_id' | 'advertising_id'>,
  id: string,
  status: string,
  uri: string,
  requestDate: Dayjs,
  ...
}
export const DataExportJobFactory: RecordFactory<DataExportJobProps> = Immutable.Record(
  ({
    entity: 'userbase',
    kind: 'ids',
    identifier: '',
    from: null,
    to: 'now',
    events: [],
    ids: ['install_id', 'custom_id', 'advertising_id'],
    id: '',
    status: '',
    uri: '',
    requestDate: dayjs(),
  }: DataExportJobProps)
)

export type DataExportJobRecord = RecordOf<DataExportJobProps>

// Export job
type ExportJobProps = {
  entities: List<DataExportJobRecord>,
  loading: boolean,
  ...
}
export const ExportJobFactory: RecordFactory<ExportJobProps> = Immutable.Record(
  ({
    entities: new Immutable.List(),
    loading: false,
  }: ExportJobProps)
)

export type ExportJobRecord = RecordOf<ExportJobProps>

// ====================== DEMO CODES
// app demo code
type AppDemoCodeProps = {
  id: number | null,
  name: string,
  apiKey: string,
  ...
}
export const AppDemoCodeFactory: RecordFactory<AppDemoCodeProps> = Immutable.Record(
  ({
    id: null,
    name: '',
    apiKey: '',
  }: AppDemoCodeProps)
)

export type AppDemoCodeRecord = RecordOf<AppDemoCodeProps>

// demo code
type DemoCodeProps = {
  id: string,
  description: string,
  ios: AppDemoCodeRecord,
  android: AppDemoCodeRecord,
  ...
}
export const DemoCodeFactory: RecordFactory<DemoCodeProps> = Immutable.Record(
  ({
    id: '',
    description: '',
    ios: AppDemoCodeFactory(),
    android: AppDemoCodeFactory(),
  }: DemoCodeProps)
)

export type DemoCodeRecord = RecordOf<DemoCodeProps>

// codes state
type CodesStateProps = {
  entities: Map<string, DemoCodeRecord>,
  loading: boolean,
  count: number,
  ...
}
export const CodeStateFactory: RecordFactory<CodesStateProps> = Immutable.Record(
  ({
    entities: Immutable.Map(),
    loading: false,
    count: 0,
  }: CodesStateProps)
)

export type CodeStateRecord = RecordOf<CodesStateProps>
