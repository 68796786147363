// @flow
import { type List } from 'immutable'

import { type ClickLinkRecord } from '../models/click-links-detail.record'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { type ReduxAction, type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchClicksByUrlAction = ReduxAction<'FETCH_CLICKS_BY_URL', { token: string }>
export type FetchClicksByUrlSuccessAction = ReduxAction<
  'FETCH_CLICKS_BY_URL_SUCCESS',
  { token: string, data: List<ClickLinkRecord> },
>
export type FetchClicksByUrlFailureAction = ReduxAction<
  'FETCH_CLICKS_BY_URL_FAILURE',
  { token: string, error: Error },
>

export type FetchClicksByUrlActions =
  | FetchClicksByUrlAction
  | FetchClicksByUrlSuccessAction
  | FetchClicksByUrlFailureAction

export const fetchClicksByUrl =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.linksLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity

    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchClicksByUrlAction = {
        type: 'FETCH_CLICKS_BY_URL',
        payload: { token },
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchClicksByUrl({ project, token })
        const fetchSuccessAction: FetchClicksByUrlSuccessAction = {
          type: 'FETCH_CLICKS_BY_URL_SUCCESS',
          payload: { token, data },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchClicksByUrlFailureAction = {
          type: 'FETCH_CLICKS_BY_URL_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
