// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAction, useImageUploader, useToggle } from 'components/_hooks'
import { Popin } from 'components/common/popin/popin'
import { MediaUrlPopin } from 'components/form/fields/media-url-builder/media-url-popin'
import { Variant } from 'components/project/campaign/push/variant'
import { TemplateWizard } from 'components/template/template-wizard'

import { convertArrayPlatformToPreviewPlatform } from 'com.batch.common/utils'

import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selectors'
import { personalisationAttrSelector } from 'com.batch.redux/attribute.selector'
import { togglePushVariant } from 'com.batch.redux/campaign.action'
import {
  activeLanguageSelector,
  campaignHasAbTesting,
  currentCampaign,
  currentCampaignAppsSelector,
  triggerEventSelector,
} from 'com.batch.redux/campaign.selector'
import { updatePushContentOld, updatePushSettings } from 'com.batch.redux/content'
import { type StringValueRecord } from 'com.batch.redux/content.records'
import { pushSettingsSelector } from 'com.batch.redux/content.selector'
import { pushContentABForActiveLanguageSelector } from 'com.batch.redux/targeting.selector.composed'
import { setTemplateForField } from 'com.batch.redux/template'
import { showToast } from 'com.batch.redux/toaster'

import { VariantContainer } from 'com.batch/message-builder/ui/components/common.styles'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

export const LegacyPushBuilder = (): React.Node => {
  const dispatch = useDispatch()
  const campaign = useSelector(currentCampaign)
  const abTestingEnabled = useSelector(campaignHasAbTesting)
  const variants = React.useMemo(() => (abTestingEnabled ? ['a', 'b'] : ['a']), [abTestingEnabled])
  const contextEventId = useSelector(triggerEventSelector)
  const attributes = useSelector(personalisationAttrSelector)

  const pushSettings = useSelector(pushSettingsSelector)
  const content = useSelector(pushContentABForActiveLanguageSelector)
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
  const language = useSelector(activeLanguageSelector)
  const apps = useSelector(currentCampaignAppsSelector)
  const mediaPopinState = useToggle()
  const s3signingUrl = React.useMemo(() => `/api/app/${apps.first()?.id ?? 1}/s3config`, [apps])

  const toggleVariantBound = useAction(togglePushVariant)
  const setInlineEditorBound = useAction(setInlineEditor)
  const showToastBound = useAction(showToast)

  const targetedPlatforms = React.useMemo(() => Array.from(apps.map(p => p.platform)), [apps])
  const availablePreviewPlatforms = React.useMemo(
    () => convertArrayPlatformToPreviewPlatform(targetedPlatforms),
    [targetedPlatforms]
  )
  const [previewPlatform, setPreviewPlatform] = React.useState<PreviewPlatform>(
    availablePreviewPlatforms.length > 0 ? availablePreviewPlatforms[0] : 'ios'
  )

  const updatePushSettingsBound = React.useCallback(
    (field, value) => dispatch(updatePushSettings(field, value)),
    [dispatch]
  )
  const onMediaUrlChanged = React.useCallback(
    newValue => {
      mediaPopinState.close()
      if (inlineEditorConfig.field === 'icon') {
        updatePushSettingsBound('iconUrl', newValue.mediaUrl)
      } else {
        updatePushSettingsBound('attachmentKind', newValue.mediaKind)
        updatePushSettingsBound('attachmentUrl', newValue.mediaUrl)
      }
    },
    [inlineEditorConfig.field, mediaPopinState, updatePushSettingsBound]
  )

  const closeTemplateWizard = React.useCallback(() => {
    setInlineEditorBound(inlineEditorConfig.set('personalizationModalIsOpen', false))
  }, [inlineEditorConfig, setInlineEditorBound])

  const updateContentAndTemplateOld = React.useCallback(
    ({
      lang,
      variant,
      field,
      value,
    }: {
      lang: string,
      variant: 'a' | 'b',
      field: 'title' | 'message',
      value: StringValueRecord,
      ...
    }) => {
      const key = `${field}-${variant}-${lang}`
      dispatch(setTemplateForField(key, value.value))
      return dispatch(updatePushContentOld({ lang, variant, field, value }))
    },
    [dispatch]
  )

  const updateTemplate = React.useCallback(
    (toAppend: string) => {
      const selection = inlineEditorConfig.selection
      const field = inlineEditorConfig.field
      if (field !== 'message' && field !== 'title') return
      const text = content.get(inlineEditorConfig.variant).get(field).value
      const computedText = text.substring(0, selection[0]) + toAppend + text.substring(selection[1])
      updateContentAndTemplateOld({
        field,
        value: content.get(inlineEditorConfig.variant).get(field).set('value', computedText),
        variant: inlineEditorConfig.variant,
        lang: language?.value ?? 'default',
      })
    },
    [content, inlineEditorConfig, language?.value, updateContentAndTemplateOld]
  )

  const onImageUploadSuccess = React.useCallback(
    url => {
      if (inlineEditorConfig.field === 'icon') {
        updatePushSettingsBound('iconUrl', url)
      } else {
        updatePushSettingsBound('attachmentKind', 'image')
        updatePushSettingsBound('attachmentUrl', url)
      }
    },
    [inlineEditorConfig.field, updatePushSettingsBound]
  )

  const onImageUploadError = React.useCallback(
    txt => showToastBound({ message: txt }),
    [showToastBound]
  )

  const { getUploaderRootProps, uploaderInputField, openUploader, draggingState } =
    useImageUploader({
      onImageUploadSuccess,
      onImageUploadError,
      imageMinWidth: inlineEditorConfig.field === 'icon' ? 192 : 300,
      imageMinHeight: inlineEditorConfig.field === 'icon' ? 192 : 200,
      imageRequiredRatio: inlineEditorConfig.field === 'icon' ? 1 : 0,
      s3signingUrl,
    })

  const imageRecommandation = React.useMemo(
    () =>
      inlineEditorConfig.field === 'icon'
        ? {
            message: 'Image must be square and at least 192px wide',
            docLinkText: 'Learn more',
            docLinkUrl:
              'https://doc.batch.com/android/advanced/customizing-notifications#setting-up-custom-push-icons',
          }
        : {
            message:
              previewPlatform === 'android'
                ? 'Landscape image. Width must be at least 300px, height at least 200px.'
                : 'Width must be at least 300px, height at least 200px.',
            docLinkText: 'Learn more',
            docLinkUrl:
              previewPlatform === 'ios' || previewPlatform === 'android'
                ? 'https://help.batch.com/en/articles/3993058-what-is-the-best-image-size-for-my-push-notification'
                : 'https://help.batch.com/en/articles/4227847-how-can-i-attach-an-image-to-a-web-push-notification',
          },
    [inlineEditorConfig.field, previewPlatform]
  )

  return (
    <VariantContainer {...getUploaderRootProps()} $channel="push" $form="campaign">
      {uploaderInputField}
      <Popin close={mediaPopinState.close} opened={mediaPopinState.value}>
        <MediaUrlPopin
          allowedMedia={
            inlineEditorConfig.field === 'icon'
              ? ['image']
              : apps.filter(app => app.platform !== 'ios').size > 0
                ? ['image']
                : ['image', 'audio', 'video']
          }
          close={mediaPopinState.close}
          onChange={onMediaUrlChanged}
          platforms={apps.map(app => app.platform).toArray()}
          showTemplateWizard={apps.first()?.features?.has('macro') ?? false}
          imageRecommandation={imageRecommandation}
        />
      </Popin>
      <TemplateWizard
        appendText={updateTemplate}
        attributes={attributes}
        close={closeTemplateWizard}
        contextEventId={contextEventId}
        opened={inlineEditorConfig.personalizationModalIsOpen}
      />
      {variants.map(variant => (
        <Variant
          key={variant}
          abtesting={campaign.abtesting}
          apps={apps}
          draggingState={draggingState}
          editorConfig={inlineEditorConfig}
          setInlineEditor={setInlineEditorBound}
          openFileUploader={openUploader}
          openMediaPopin={mediaPopinState.open}
          pushSettings={pushSettings}
          toggleVariant={toggleVariantBound}
          variant={variant}
          previewPlatform={previewPlatform}
          setPreviewPlatform={setPreviewPlatform}
        />
      ))}
    </VariantContainer>
  )
}
