// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { GlobalErrorOverlayProps, Skeleton, Wrapper } from 'components/common/empty-states'
import { Tooltip } from 'components/common/tooltip'
import { HorizontalDivider } from 'components/styled/utils'

import { kformat, percentage, randomSize } from 'com.batch.common/utils'

import { StatsBlocksRow } from '../orchestration-analytics/orchestration-analytics.styles'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { mapLinkName } from 'com.batch/orchestration-analytics/constants/map-link-name'
import {
  ClickLinkFactory,
  type ClickLinkRecord,
} from 'com.batch/orchestration-analytics/models/click-links-detail.record'
import { BounceBlockHeaderContainer } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'
import {
  ClickPerUrlClickDistribution,
  ClickPerUrlRow,
  ClickPerUrlTitle,
  ClickPerUrlUniqueClick,
} from 'com.batch/orchestration-analytics/ui/components/clicks-per-url-block/clicks-per-url-block.styles'
import { fetchClicksByUrl } from 'com.batch/orchestration-analytics/usecases/fetch-clicks-by-url'
import { STATUS } from 'constants/common'

const linksWhileLoading = new Immutable.List().push(
  ClickLinkFactory(),
  ClickLinkFactory(),
  ClickLinkFactory(),
  ClickLinkFactory(),
  ClickLinkFactory()
)
export const ClicksPerUrlBlock = ({
  schedulingType,
}: {
  schedulingType: schedulingType,
}): React.Node => {
  const randomWidths = React.useMemo(
    () => [...linksWhileLoading.map(() => randomSize(100, 600))],
    []
  )

  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const links: ?List<ClickLinkRecord> = React.useMemo(
    () => analyticsMap.getIn([token, 'channels', 'email', 'links']),
    [analyticsMap, token]
  )
  const linksLoadingState = useSelector(
    state => state.orchestrationAnalytics.get(token)?.linksLoadingState
  )
  const isLoading = React.useMemo(
    () => [STATUS.LOADING, STATUS.INIT].includes(linksLoadingState),
    [linksLoadingState]
  )

  React.useEffect(() => {
    dispatch(fetchClicksByUrl({ token })).catch(() => {})
  }, [token, dispatch])

  const shownLinks = React.useMemo(
    () =>
      isLoading
        ? linksWhileLoading
        : links?.sort(
            (link1: ClickLinkRecord, link2: ClickLinkRecord) => link2.unique - link1.unique
          ),
    [links, isLoading]
  )
  const isOverlayShown = React.useMemo(
    () => linksLoadingState === STATUS.ERROR,
    [linksLoadingState]
  )

  return shownLinks && shownLinks.size > 0 ? (
    <StatsBlocksRow marginTop>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={
          linksLoadingState === STATUS.ERROR
            ? GlobalErrorOverlayProps
            : {
                status: 'empty',
                title: `No links for this ${
                  schedulingType === 'campaigns' ? 'campaign' : 'automation'
                }`,
                description: '',
              }
        }
        boxed
      >
        <Box
          style={{
            width: 1148,
            overflowY: 'auto',
            maxHeight: 432,
          }}
        >
          <BoxHeader style={{ position: 'sticky', zIndex: 10, top: 0 }}>
            <BounceBlockHeaderContainer>
              <BoxTitle>Clicks per URL</BoxTitle>
            </BounceBlockHeaderContainer>
          </BoxHeader>
          <BoxBody
            style={
              !isOverlayShown
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }
                : {}
            }
          >
            <div style={{ overflow: 'auto', paddingLeft: 20, paddingRight: 20, height: '100%' }}>
              {shownLinks?.map((link, index) => (
                <React.Fragment key={index}>
                  <ClickPerUrlRow>
                    <ClickPerUrlTitle>
                      <Skeleton h={20} w={randomWidths[index]}>
                        <Tooltip tooltip={mapLinkName(link.name)} placement="top" maxWidth={1000}>
                          <span>{mapLinkName(link.name)}</span>
                        </Tooltip>
                      </Skeleton>
                    </ClickPerUrlTitle>
                    <ClickPerUrlUniqueClick>
                      <Skeleton h={20} w={35}>
                        {kformat(link.unique)}
                      </Skeleton>
                    </ClickPerUrlUniqueClick>
                    <ClickPerUrlClickDistribution>
                      <Skeleton h={20} w={50}>
                        {percentage(link.rate, 1, false, false)}
                      </Skeleton>
                    </ClickPerUrlClickDistribution>
                  </ClickPerUrlRow>
                  {index + 1 !== shownLinks?.size && <HorizontalDivider />}
                </React.Fragment>
              ))}
            </div>
          </BoxBody>
        </Box>
      </Wrapper>
    </StatsBlocksRow>
  ) : null
}
