// @flow
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { CHANNEL_THEMES } from 'com.batch/profilebase/constants/channel-themes'
import { type AnalyticsChannel } from 'com.batch/profilebase/infra/types/channel-type'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'

type Props = {
  channel: AnalyticsChannel,
  metric: string,
  label: string,
}

export const ChannelMetric = ({ channel, metric, label }: Props): React.Node => (
  <MetricBlock
    variant={CHANNEL_THEMES[channel].labelVariant}
    label={
      <React.Fragment>
        <Icon icon={CHANNEL_THEMES[channel].icon} />
        {CHANNEL_THEMES[channel].title}
      </React.Fragment>
    }
    subtitle={label}
    value={metric}
  />
)
