// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'

import {
  LayerBarDropdown,
  LayerBarButton,
} from 'com.batch/message-builder/ui/components/layerbar-button'
import { restoreValuesFromLocalStorage } from 'com.batch/shared/usecases/save-restore-local'
import { savedNumberKey } from 'com.batch/sms/ui/components/popin-send-test-sms'
import { sendTestSms } from 'com.batch/sms/usecases/send-test-sms'

type SmsLayerBarSendTestProps = {
  handleSendTestClick: () => void,
  messageId: string,
  ...
}

export const SmsLayerBarSendTest = ({
  handleSendTestClick,
  messageId,
}: SmsLayerBarSendTestProps): React.Node => {
  const dispatch = useDispatch()
  const savedNumbers = restoreValuesFromLocalStorage<string>(savedNumberKey)
  const [isSendingTo, setIsSendingTo] = React.useState()

  const sendTestOptions = React.useMemo(
    () => [
      ...savedNumbers.map(number => ({
        label: number,
        action: () => {
          setIsSendingTo(number)
          dispatch(sendTestSms({ messageId, recipient: number }))
            .catch(() => {})
            .finally(() => {
              setIsSendingTo('')
            })
        },
        loading: isSendingTo === number,
        disabled: !!isSendingTo,
      })),
    ],
    [messageId, dispatch, savedNumbers, isSendingTo]
  )

  return savedNumbers.length > 0 ? (
    <LayerBarDropdown
      label="Send test"
      icon={<img src="/mediasimg/builders/icons/send-big.svg" />}
      options={sendTestOptions}
      defaultOption={{
        label: 'New phone number',
        action: handleSendTestClick,
        disabled: !!isSendingTo,
        isDefault: true,
      }}
    />
  ) : (
    <LayerBarButton
      label="Send test"
      icon={<img src="/mediasimg/builders/icons/send-big.svg" />}
      onClick={handleSendTestClick}
    />
  )
}
