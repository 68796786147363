// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Button, type ButtonProps } from 'components/common/button/button.styles'
import { schemes, colors } from 'components/styled/tokens'

type EventTriggerProps = { ...ButtonProps, $hasError: boolean, $isEditing: boolean, ... }
export const EventTrigger: StyledComponent<EventTriggerProps, { ... }, *> = styled(Button)`
  background-color: ${schemes.purple['00']};
  color: ${schemes.purple['50']};
  display: inline-flex;
  font-weight: 500;
  &:hover {
    color: ${schemes.purple['60']} !important;
    background-color: ${schemes.purple['10']};
  }
  &:focus:not(:focus-visible) {
    color: ${schemes.purple['50']} !important;
    background-color: ${schemes.purple['00']};
  }
  &:focus-visible {
    color: ${schemes.purple['70']} !important;
    background-color: ${schemes.purple['20']};
  }
  border: ${(p: EventTriggerProps) => {
    if (p.$isEditing) return `2px solid ${schemes.purple['60']}`
    else if (p.$hasError) return `2px solid ${colors.strokeDanger}`
    return 'none'
  }};
  span {
    max-width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

type EventTriggerStartProps = { ...ButtonProps, $isEditing: boolean, ... }
export const EventTriggerStart: StyledComponent<EventTriggerStartProps, { ... }, *> = styled(
  Button
)`
  outline: ${(p: EventTriggerStartProps) =>
    p.$isEditing ? `2px solid ${colors.fillAction}` : 'none'};
`
