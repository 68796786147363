// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderBoxTitle, BoxHeader, BoxBody } from 'components/common/box'
import { HeaderBoxActions } from 'components/common/box/box.styles'
import { Button } from 'components/common/button'
import { SideSheet } from 'components/common/sidesheet'
import { Icon } from 'components/common/svg-icon'
import { Input } from 'components/form/fields/input'
import { Targeting } from 'components/project/campaign/common/targeting'

import { NodeIconWrapper } from '../node-icon-wrapper'
import { TargetingWrapperTrigger } from '../sheet-settings.styles'
import { estimateProfileGetterSelector } from 'com.batch.redux/target/target.selector'

import { EstimateProfile } from 'com.batch/orchestration/ui/components/estimate-profile'
import { triggerEstimate } from 'com.batch/orchestration/usecases/trigger-estimate'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

type SheetYesNoProps = {
  isOpened: boolean,
  id: string,
  dismiss: () => void,
  refocusOnNode: ({ width?: number, zoom?: number }) => void,
}

export const SheetYesNo = ({
  isOpened,
  dismiss,
  id,
  refocusOnNode,
}: SheetYesNoProps): React.Node => {
  const estimateProfileGetter = useSelector(estimateProfileGetterSelector)
  const estimate = React.useMemo(() => estimateProfileGetter(id), [estimateProfileGetter, id])
  const dispatch = useDispatch()
  const { nodesMap } = useSelector(journeyTreeSelector)
  const node = nodesMap.get(id)

  const onLabelChange = React.useCallback(
    e => {
      if (e.target && e.target instanceof HTMLInputElement && node?.type === 'YESNO')
        dispatch(updateNode(node.set('label', e.target?.value)))
    },
    [dispatch, node]
  )

  const sheetWidth = 1200
  React.useEffect(() => {
    if (isOpened) {
      refocusOnNode({ width: sheetWidth })
    }
  }, [refocusOnNode, isOpened, id])

  React.useEffect(() => {
    if (isOpened) {
      dispatch(triggerEstimate(id))
    }
  }, [isOpened, dispatch, id])
  return (
    <SideSheet
      isOpened={isOpened}
      close={dismiss}
      footer={<Copyable fullWidth={true} notificationText="Step ID copied" value={id} />}
      footerActions={
        <Button kind="secondary" onClick={dismiss}>
          Continue
        </Button>
      }
      width={sheetWidth}
    >
      <BoxHeader style={{ height: 56 }} large>
        <HeaderBoxTitle
          style={{ width: '100%' }}
          title={
            <NodeIconWrapper kind="yesno">
              <Input
                value={node?.type === 'YESNO' ? node.label : ''}
                onChange={onLabelChange}
                placeholder="Yes/No split title"
                style={{ marginRight: 10 }}
                inputStyle={{ fontWeight: 500 }}
              />
            </NodeIconWrapper>
          }
        />
        <HeaderBoxActions large>
          <Button onClick={dismiss}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <BoxBody>
        <TargetingWrapperTrigger>
          <Targeting isProfile canvasMode id={id} />
        </TargetingWrapperTrigger>
        <EstimateProfile estimate={estimate} showYesNoWarningPrevious />
      </BoxBody>
    </SideSheet>
  )
}
