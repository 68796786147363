// @flow
import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import { BoxBody } from 'components/common/box/box.styles'
import { PermissionButton } from 'components/common/button/permission-button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Wrapper } from 'components/common/empty-states/wrapper'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import { Table, TableBody, TableTemplateCell } from 'components/common/table/table'
import { TableCellHeader, TableFooter, TableHeader } from 'components/common/table/table.styles'
import { FilterSearch } from 'components/filter/filter-search/filter-search'
import { Title } from 'components/styled/text'

import { fetchSegments, type Segment, saveSegment, deleteSegment } from '../../infra/segments.api'
import { SegmentModal } from '../components/segment-modal'
import { SegmentRow } from '../components/segment-row'
import { type fetchingState } from 'com.batch.redux/_records'
import { fetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { resetQuery } from 'com.batch.redux/query/query'
import { estimateProfileGetterSelector } from 'com.batch.redux/target/target.selector'
import { showToast } from 'com.batch.redux/toaster'

import {
  EstimateProfileFactory,
  type EstimateProfileRecord,
} from 'com.batch/orchestration/models/profile-estimate.records'
import { triggerEstimate } from 'com.batch/orchestration/usecases/trigger-estimate'
import { ourSqlService } from 'com.batch/shared/infra/oursql.service'
import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'

const newSegment: Segment = {
  id: null,
  query: '',
  name: '',
}
const NB_PER_PAGE = 10
const MAX_SEGMENTS = 50
export const SegmentsListEmptyTemplate = (): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="60px" align="end" />
    </React.Fragment>
  )
}
export const SavedSegmentList = (): React.Node => {
  // ---- local state
  const [segments, setSegments] = React.useState<Array<Segment>>([])
  const [fetching, setFetching] = React.useState<fetchingState>('LOADING')
  const [saving, setSaving] = React.useState<fetchingState>('INIT')
  const [editedSegment, setEditedSegment] = React.useState<?Segment>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('search') || ''
  const page = parseInt(searchParams.get('page')) || 1
  const [estimates, setEstimates] = React.useState<Map<number, EstimateProfileRecord>>(
    Immutable.Map()
  )
  // -------------------

  // ---- redux state
  const dispatch = useDispatch()
  const project = useSelector(currentProjectSelector)
  const getProfileReach = useSelector(estimateProfileGetterSelector)
  const userAllowedToWrite = useIsCurrentUserAllowedTo(['app', 'custom-data:write'])
  // -------------------

  // ---- derived state
  const filteredSegments = React.useMemo(
    () => segments.filter(seg => seg.name.toLowerCase().includes(search.toLowerCase())),
    [segments, search]
  )
  const pagedSegments = React.useMemo(
    () => filteredSegments.slice((page - 1) * NB_PER_PAGE, page * NB_PER_PAGE),
    [filteredSegments, page]
  )
  // -------------------

  // ---- callback
  const onSearchChange = React.useCallback(
    searchTerm => {
      setSearchParams(params => {
        params.set('search', searchTerm)
        params.set('page', '1')
        return params
      })
    },
    [setSearchParams]
  )
  const openModalForSegment = React.useCallback(
    (segment: Segment) => () => {
      setEditedSegment(segment)
      dispatch(triggerEstimate('segment-' + (segment.id ?? 'new')))
    },
    [dispatch]
  )
  const onPageChange = React.useCallback(
    (page: number) => {
      setSearchParams(params => {
        params.set('page', page.toString())
        return params
      })
    },
    [setSearchParams]
  )
  const resetSegment = React.useCallback(() => {
    if (editedSegment && !editedSegment.id) dispatch(resetQuery({ queryId: 'segment-new' }))
    setEditedSegment(null)
  }, [dispatch, editedSegment])
  const onSave = React.useCallback(
    (segment: Segment) => {
      setSaving('LOADING')
      saveSegment(project, segment).then(
        updatedSegment => {
          dispatch(showToast({ kind: 'success', message: 'Segment saved' }))
          setSegments(segments => {
            const index = segments.findIndex(s => s.id === updatedSegment.id)

            if (index === -1) {
              return [updatedSegment, ...segments]
            } else {
              return segments.map((s, i) => (i === index ? updatedSegment : s))
            }
          })
          if (segment.id) {
            // create will handle estimate via useEffect, on update we need to refresh
            setEstimates(estimates =>
              estimates.set(updatedSegment?.id ?? 0, EstimateProfileFactory({ loading: 'LOADING' }))
            )
            ourSqlService
              .fetchProfileEstimateFor({
                projectKey: project.projectKey,
                query: updatedSegment.query,
              })
              .then(
                estimate => {
                  setEstimates(estimates => estimates.set(updatedSegment?.id ?? 0, estimate))
                },
                () => {
                  setEstimates(estimates =>
                    estimates.set(
                      updatedSegment?.id ?? 0,
                      EstimateProfileFactory({ loading: 'ERROR' })
                    )
                  )
                }
              )
          }
          setSaving('LOADED')
          resetSegment()
        },
        error => {
          console.error(error)
          dispatch(
            showToast({ kind: 'error', message: 'An error occurred while saving your segments.' })
          )
          setSaving('ERROR')
        }
      )
    },
    [project, dispatch, resetSegment]
  )
  const onDelete = React.useCallback(
    (segment: Segment) => {
      return () => {
        confirm({
          title: 'Delete this segment?',
          message: (
            <React.Fragment>
              <p>
                This will delete the segment <ConfirmHighlight>{segment.name}</ConfirmHighlight>.
              </p>
              <p>Orchestrations where you used this segment will continue to work normally.</p>
            </React.Fragment>
          ),
          confirm: 'Yes, delete it',
          sensitive: true,
        }).then(
          () => {
            deleteSegment(project, segment).then(
              () => {
                dispatch(showToast({ kind: 'success', message: 'Segment deleted' }))
                setSegments(segments => segments.filter(s => s.id !== segment.id))
              },
              () => {
                dispatch(
                  showToast({ kind: 'error', message: 'An error occurred while deleting segment' })
                )
              }
            )
          },
          () => {}
        )
      }
    },
    [dispatch, project]
  )
  // -------------------

  // ---- effects
  React.useEffect(() => {
    dispatch(fetchUnifiedCustomerDataSummary()).catch(() => {})
  }, [dispatch])
  React.useEffect(() => {
    const loadSegments = async () => {
      setFetching('LOADING')
      try {
        const segments = await fetchSegments(project)
        setSegments(segments.sort((a, b) => ((a.id ?? 0) < (b.id ?? 0) ? 1 : -1)))
        setFetching('LOADED')
      } catch (error) {
        setFetching('ERROR')
        console.error(error)
        dispatch(showToast({ type: 'error', message: 'An error occurred while fetching segments' }))
      }
    }
    loadSegments()
  }, [dispatch, project])

  React.useEffect(() => {
    pagedSegments.forEach(segment => {
      // parses queries on fitlered and paged segments
      dispatch({
        type: 'T_NEW_QUERY',
        payload: {
          query: segment.query,
          eventId: '',
          queryId: 'segment-' + (segment.id ?? ''),
          retries: 0,
        },
      })
      // fetch missing estimated on fitlered and paged segments

      if (!estimates.has(segment?.id ?? 0)) {
        setEstimates(estimates =>
          estimates.set(segment?.id ?? 0, EstimateProfileFactory({ loading: 'LOADING' }))
        )
        ourSqlService
          .fetchProfileEstimateFor({ projectKey: project.projectKey, query: segment.query })
          .then(
            estimate => {
              setEstimates(estimates => estimates.set(segment?.id ?? 0, estimate))
            },
            () => {
              setEstimates(estimates =>
                estimates.set(segment?.id ?? 0, EstimateProfileFactory({ loading: 'ERROR' }))
              )
            }
          )
      }
    })
  }, [dispatch, estimates, pagedSegments, project.projectKey])
  // -------------------
  return (
    <React.Fragment>
      {editedSegment && (
        <SegmentModal
          dismiss={resetSegment}
          segment={editedSegment}
          reach={getProfileReach('segment-' + (editedSegment?.id ?? 'new'))}
          save={onSave}
          savingState={saving}
        />
      )}
      <Grid
        template={`1fr auto 9px ${!userAllowedToWrite ? '150px' : 'auto'}`}
        gap={8}
        margin={[0, 0, 38, 0]}
      >
        <Title mb={0} overEmptyState>
          Segments
        </Title>
        {segments.length > 0 && (
          <React.Fragment>
            <FilterSearch
              identifier="audiences"
              placeholder="Search segments..."
              value={search}
              onChange={onSearchChange}
              disabled={false}
              expandedMaxWidth={280}
            />

            <Separator />

            <PermissionButton
              intent="action"
              kind="primary"
              addOn="prefix"
              onClick={openModalForSegment(newSegment)}
              isAllowed={userAllowedToWrite && segments.length < MAX_SEGMENTS}
              notAllowedMessage={
                segments.length >= MAX_SEGMENTS
                  ? 'Impossible to create more than ' + MAX_SEGMENTS + ' segments'
                  : undefined
              }
            >
              <Icon icon="add" />
              Add segment
            </PermissionButton>
          </React.Fragment>
        )}
      </Grid>
      <Wrapper
        style={{ width: '1134px' }}
        isLoading={fetching === 'LOADING'}
        isEmpty={segments.length === 0 && !fetching}
        isOverlayShown={segments.length === 0 && ['LOADED', 'ERROR'].includes(fetching)}
        overlayProps={{
          status: fetching === 'LOADED' ? 'empty-page' : 'error',
          title: fetching === 'LOADED' ? 'No segment to display' : 'Something went wong',
          description:
            fetching === 'LOADED' ? (
              <div>
                <p style={{ marginBottom: 10 }}>
                  You haven’t created any segment for this project for now. You can create your
                  first one right now by filling out the following form.
                </p>
                <PermissionButton
                  intent="action"
                  kind="primary"
                  addOn="prefix"
                  style={{ height: 'auto' }}
                  onClick={openModalForSegment(newSegment)}
                  isAllowed={userAllowedToWrite}
                >
                  <Icon icon="add" />
                  Add segment
                </PermissionButton>
              </div>
            ) : (
              "This is not working properly. We are working to fix the problem and we'll be up and running shortly."
            ),
          links:
            fetching === 'LOADED'
              ? []
              : [
                  {
                    name: 'Check the status page',
                    href: 'https://status.batch.com/',
                  },
                ],
        }}
      >
        <BoxBody
          style={{
            minHeight: fetching === 'ERROR' ? 440 : 'auto',
          }}
        >
          <Table
            rowHeight={44}
            forceEmpty={fetching !== 'LOADED'}
            style={{
              borderRadius: 7,
              overflow: 'hidden',
              width: '100%',
            }}
            template={'1fr 1fr 99px'}
          >
            <TableHeader>
              <TableCellHeader>Name</TableCellHeader>
              <TableCellHeader>Estimated reach</TableCellHeader>
            </TableHeader>

            <TableBody emptyTemplate={<SegmentsListEmptyTemplate />} templateSize={10}>
              <NoResultWrapper isEmpty={pagedSegments.length === 0} entityName="segments">
                {pagedSegments.map(segment => {
                  return (
                    <SegmentRow
                      key={segment.id}
                      segment={segment}
                      estimate={estimates.get(segment?.id ?? 0, EstimateProfileFactory())}
                      onEdit={openModalForSegment(segment)}
                      onDelete={onDelete(segment)}
                    />
                  )
                })}
              </NoResultWrapper>
            </TableBody>
            {filteredSegments.length > NB_PER_PAGE && (
              <TableFooter>
                <Pager
                  nbPerPage={NB_PER_PAGE}
                  total={segments.length}
                  page={page}
                  selectPage={onPageChange}
                />
              </TableFooter>
            )}
          </Table>
        </BoxBody>
      </Wrapper>
    </React.Fragment>
  )
}
