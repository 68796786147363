// @flow

import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { SmsContentFactory, type SmsContentRecord } from '../../message/models/message.records'
import {
  getMessageKey,
  type MessageIdGetterSelector,
  translationsWithDefaultSelector,
} from 'com.batch/message/store/message.selector'
import { type State } from 'com.batch.redux/_records'
import { activeLanguageIdSelector } from 'com.batch.redux/campaign.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields } from 'com.batch/sms/usecases/validate-sms-fields'

export const getSmsContentSelector =
  (state: State): (string => Map<string, SmsContentRecord>) =>
  messageId =>
    state.message.sms.get(messageId, Immutable.Map())

export const getSmsContentForActiveLanguageSelector: MessageIdGetterSelector<SmsContentRecord> =
  createSelector(
    getSmsContentSelector,
    activeLanguageIdSelector,
    (getData, langId) => messageId => {
      const data = getData(messageId)
      return data.get(langId ?? 'default', SmsContentFactory())
    }
  )

type getErrorsForMessage = (messageId: string) => Map<string, Array<string>>
export const getSmsErrorsSelector: State => getErrorsForMessage = createSelector(
  translationsWithDefaultSelector,
  getSmsContentSelector,
  subscriptionStatusSelector,
  (langsWithDefault, getContent, targetedUserbase) => messageId => {
    const content = getContent(messageId)
    const errors: Array<[string, Array<string>]> = langsWithDefault
      .map(lang => {
        const contentForLang = content.get(lang.value, SmsContentFactory())
        let localErrors = []

        const smsMessage = contentForLang.smsMessage

        if (!smsMessage) localErrors.push('SMS message is missing')

        const { parts } = countSmsMessage({
          message: smsMessage,
          countStop: targetedUserbase === 'marketing',
        })

        const smsErrors = validateSmsFields({
          message: smsMessage,
          parts,
        })

        if (smsErrors.has('MSG_TOO_LONG')) localErrors.push('SMS message is too long')

        return [getMessageKey(lang), localErrors]
      })
      .filter(err => err[1].length > 0)
      .toArray()
    return Immutable.Map(errors)
  }
)

export const smsSenderSelector: State => ?string = createSelector(
  currentProjectSelector,
  project => {
    return project.sspConfiguration?.senders.first()
  }
)
