// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { JourneyEntryBlockTabButton } from './journey-entry-block-tab-button'

import { type settingsTabKind } from '../../pages/journey-canvas-form'

import { EventButton } from 'com.batch/orchestration-journey/ui/components/nodes/event-button'

export type JourneyEntryBlockProps = {
  entryEvents: List<{ eventName: string, hasQuery: boolean, ... }>,
  openSettingsSheet: (tab: settingsTabKind) => void,
  hasCustomTargeting?: boolean,
  hasCustomTiming?: boolean,
  hasQuietHours?: boolean,
  reduxNodeId: string,
  editingNodeId?: string,
}

export const JourneyEntryBlock = ({
  entryEvents,
  hasCustomTargeting,
  openSettingsSheet,
  hasCustomTiming,
  hasQuietHours,
  reduxNodeId,
  editingNodeId,
}: JourneyEntryBlockProps): React.Node => {
  return (
    <div
      className="nopan nodrag"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'default',
      }}
    >
      <Icon
        className="nopan nodrag"
        size={34}
        color="#f8f8f8"
        icon="door"
        style={{
          marginBottom: 12,
        }}
      />
      <EventButton
        value={entryEvents}
        onClick={openSettingsSheet}
        isRoot
        reduxNodeId={reduxNodeId}
        editingNodeId={editingNodeId}
      />
      <JourneyEntryBlockTabButton
        onClick={openSettingsSheet}
        hasCustomTargeting={hasCustomTargeting}
        hasCustomTiming={hasCustomTiming}
        hasQuietHours={hasQuietHours}
      />
    </div>
  )
}
