// @flow

import styled, { type StyledComponent } from 'styled-components'

export const IFramePreviewSwitchContainer: StyledComponent<*, { ... }, *> = styled.div`
  display: flex;
  height: auto;
  min-height: 100%;
  background: #f8f8f8;
`
type IFrameContainerProps = { isToolbarShown?: boolean, ... }
export const IFrameContainer: StyledComponent<IFrameContainerProps, { ... }, *> = styled.div`
  height: auto;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0 ${(props: IFrameContainerProps) => (props.isToolbarShown ? 0 : '32px')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
