// @flow

import { getNodeHasTargetingSelector } from '../models/journey.selectors'
import { type ReduxAction, type DispatchBoundFn } from 'com.batch.redux/_records'

export type FlagMissingTargetingAction = ReduxAction<
  'FLAG_MISSING_TARGETING',
  { nodeId: string, targetingMissing: boolean, ... },
>

export const checkIncompleteYesNoNode =
  (nodeId: string): DispatchBoundFn<void> =>
  (dispatch, getState) => {
    const hasTargeting = getNodeHasTargetingSelector(getState())(nodeId)

    dispatch(
      ({
        type: 'FLAG_MISSING_TARGETING',
        payload: { nodeId, targetingMissing: !hasTargeting },
      }: FlagMissingTargetingAction)
    )
  }
