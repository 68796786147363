// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type ReachabilityTimeserieStateRecord,
  type ReachabilityTimeserieRecord,
} from 'com.batch/profilebase/models/reachability-timeserie.records'

type extract<T> = State => T

const emailReachabilitySelector = (state: State): ReachabilityTimeserieStateRecord => {
  return state.profilebase.emailReachability
}

export const emailReachabilityLoadingStateSelector: extract<fetchingState> = createSelector(
  emailReachabilitySelector,
  emailReachability => emailReachability.loadingState
)

export const emailSubCounterLastMonthSelector: extract<number> = createSelector(
  emailReachabilitySelector,
  emailReachability => emailReachability.counter.subscriptionsLastMonth
)
// Map of new subscriptions by day (subscription Growth)
export const emailSubsGrowthSelector: extract<Map<string, ReachabilityTimeserieRecord>> =
  createSelector(emailReachabilitySelector, emailReachability =>
    emailReachability.timeserie
      .filter(f => f.subscriptions > 0)
      .reduce(
        (acc, m) => acc.set(dayjs.utc(m.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), m),
        Immutable.Map()
      )
  )
