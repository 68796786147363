// @flow

import { type Map } from 'immutable'

import { type ReduxAction } from 'com.batch.redux/_records'

import {
  type EmailContentRecord,
  type SmsContentRecord,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'

export type UpdateAllContentAction = ReduxAction<
  'UPDATE_ALL_CONTENT',
  {
    email: Map<string, Map<string, EmailContentRecord>>,
    sms: Map<string, Map<string, SmsContentRecord>>,
    push: Map<string, Map<string, PushContentRecord>>,
  },
>
export const updateAllContent = ({
  email,
  sms,
  push,
}: {
  +email: Map<string, Map<string, EmailContentRecord>>,
  +sms: Map<string, Map<string, SmsContentRecord>>,
  +push: Map<string, Map<string, PushContentRecord>>,
  ...
}): UpdateAllContentAction => {
  return {
    type: 'UPDATE_ALL_CONTENT',
    payload: { email, sms, push },
  }
}
