// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { colors, shadows } from 'components/styled/tokens'

export const ControlsContainerButtons: StyledComponent<{ ... }, { ... }, HTMLDivElement> =
  styled.div`
    border-left: 1px solid ${colors.stroke};
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: stretch;
    width: 37px;
    ${Button} {
      width: 37px;
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 0;
      height: auto;
      border-bottom: 1px solid ${colors.stroke};
      &:last-child {
        border-bottom: none;
      }
      display: flex;
      align-items: center;
      justify-items: center;
      i {
        margin-left: -1px;
      }
    }
  `
export const ControlsContainer: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  border-radius: 16px;
  box-shadow: ${shadows.block};
  background-color: ${colors.fill};
  border: 1px solid ${colors.stroke};
  overflow: hidden;
  ${Grid} .react-flow__panel {
    position: relative;
    right: default;
    margin: 0;
    bottom: default;
    &.react-flow__minimap {
      background: none;
      border-radius: 0;
      border: none;
    }
  }
`
