// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Banner } from 'components/common/banner'

import { generateUrl } from 'com.batch.common/router'

import { currentAppCanPush, currentAppSelector } from 'com.batch.redux/app'

export const CertificateBanner = (): React.Node => {
  const app = useSelector(currentAppSelector)
  const canPush = useSelector(currentAppCanPush)

  return !!app.platform && !canPush ? (
    <Banner
      intent="blocked"
      title="Push settings are not valid"
      content={`You need a valid ${
        app.platform === 'ios'
          ? 'APNS certificate'
          : app.platform === 'android'
            ? 'FCM and/or HMS certificate'
            : app.platform === 'webpush'
              ? 'Web push API config'
              : 'certificate'
      } to edit or create push campaigns.`}
      link={{
        kind: 'basic',
        name: 'Push settings',
        href: generateUrl('app_settings', {
          companyId: app.companyId,
          appId: app.id,
          activeTab: 'push',
        }),
      }}
    />
  ) : null
}
