// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type ReachabilityTimeserieStateRecord,
  type ReachabilityTimeserieRecord,
} from 'com.batch/profilebase/models/reachability-timeserie.records'

type extract<T> = State => T

const pushReachabilitySelector = (state: State): ReachabilityTimeserieStateRecord => {
  return state.profilebase.pushReachability
}

export const pushReachabilityLoadingStateSelector: extract<fetchingState> = createSelector(
  pushReachabilitySelector,
  pushReachability => pushReachability.loadingState
)

export const pushSubCounterLastMonthSelector: extract<number> = createSelector(
  pushReachabilitySelector,
  pushReachability => pushReachability.counter.subscriptionsLastMonth
)

// Map of new subscriptions by day (subscription Growth)
export const pushSubsGrowthSelector: extract<Map<string, ReachabilityTimeserieRecord>> =
  createSelector(pushReachabilitySelector, pushReachability =>
    pushReachability.timeserie
      .filter(f => f.subscriptions > 0)
      .reduce(
        (acc, m) => acc.set(dayjs.utc(m.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), m),
        Immutable.Map()
      )
  )
