// @flow

import { type Set, type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Filter } from 'components/campaign/filters/filter'
import { Icon } from 'components/common/svg-icon'
import { FilterSelectMulti } from 'components/filter'
import * as schemes from 'components/styled/tokens/schemes'

import { pluralize } from 'com.batch.common/utils'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'

import { type LabelRecord } from 'com.batch/labels/models/labels.records'

type LabelsFilterProps = {
  value: Set<string>,
  onChange: (Set<string>) => void,
}

export const LabelsFilter = ({ value, onChange }: LabelsFilterProps): React.Node => {
  const { labels, loadingState } = useSelector(labelsSelector)

  const onSelect = React.useCallback(
    (values: List<LabelRecord>) => {
      onChange(values.map(label => label.code).toSet())
    },
    [onChange]
  )

  const valueAsLabelRecord = React.useMemo(
    () => value.map(code => labels.find(label => label.code === code)),
    [value, labels]
  )

  const formatTerm = React.useCallback((count: number) => pluralize('label', count), [])

  const optToString = React.useCallback((opt: ?LabelRecord) => opt?.description ?? '', [])

  const isLoading = React.useMemo(
    () => loadingState === 'LOADING' || loadingState === 'INIT',
    [loadingState]
  )

  return (
    <Filter title="Labels">
      <FilterSelectMulti
        autoFocus={false}
        isDisabled={isLoading}
        options={labels}
        value={valueAsLabelRecord.toList()}
        onChange={onSelect}
        optionToString={optToString}
        placeholder="All labels"
        noResultsNode={<NoResultsView />}
        isSearchable
        term={formatTerm}
        menuOffset={120}
        style={{ color: value.isEmpty() ? `${schemes.grayscale['60']}` : '' }}
      />
    </Filter>
  )
}

const NoResultsView = (): React.Node => (
  <div>
    <Icon icon="no-result" style={{ marginRight: '6px' }} />
    No labels to display
  </div>
)
