// @flow

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type SendTestSmsResponseType } from './types/send-test.types'

import { type SmsContentRecord } from 'com.batch/message/models/message.records'

export const sendTestSmsRequest = ({
  projectKey,
  content,
  recipient,
  transmissionType,
}: {
  projectKey: string,
  content: SmsContentRecord,
  recipient: string,
  transmissionType: 'marketing' | 'fullbase',
  ...
}): Promise<SendTestSmsResponseType> => {
  return request.post<SendTestSmsResponseType>(
    generateUrl('api_grpc_sms_test_sender_service', {
      methodName: 'Send',
    }),
    {
      projectKey: {
        textual: {
          text: projectKey,
        },
      },
      content: {
        text: content.templates.smsMessage || content.smsMessage,
      },
      isImpersonating: Boolean(document.body?.classList?.contains('banner-impersonate')),
      recipient: {
        phoneNumber: recipient,
      },
      transmissionType: transmissionType === 'marketing' ? 'MARKETING' : 'TRANSACTIONAL',
    }
  )
}
