// @flow

import * as React from 'react'

import Hint from 'components/common/hint'
import { Editor } from 'components/form'

type ValidatedInputProps = {
  forcedFocus?: boolean,
  disabled?: boolean,
  monospaced?: boolean,
  name: string,
  onChange: (value: string) => any,
  placeholder?: string,
  rows?: number,
  touched: boolean,
  type: 'text' | 'template' | 'html',
  valid: boolean,
  value: string,
  warning?: React.Node,
  ...
}
type WrappedValidatedInputProps = {
  label?: string,
  hint?: string,
  hintMaxSize?: number,
  hintMinSize?: number,
  forcedFocus?: boolean,
  optional?: boolean,
  ...
}

export const ValidatedInput = ({
  forcedFocus,
  monospaced,
  name,
  onChange,
  rows,
  touched,
  type,
  valid,

  ...rest
}: ValidatedInputProps): React.Node => {
  let classes = ['form-control', 'v']
  if (touched) classes.push('ng-touched')
  if (!valid && touched) classes.push('ng-invalid')
  if (monospaced) classes.push('form-control--fixed')

  const props = React.useMemo(
    () => ({
      ...rest,
      className: classes.join(' '),
    }),
    [classes, rest]
  )

  switch (type) {
    case 'text':
      if (rows && rows > 1) {
        return (
          <textarea
            {...props}
            name={name}
            rows={rows}
            onChange={evt => onChange(evt.target.value)}
          />
        )
      } else {
        return (
          <input {...props} id={name} type="text" onChange={evt => onChange(evt.target.value)} />
        )
      }

    default:
      return (
        <Editor
          onChange={onChange}
          style={{ minHeight: rows ? rows * 36 : 36 * 3 }}
          id={name}
          value={props.value}
          placeholder={props.placeholder}
          forcedFocus={!!forcedFocus}
        />
      )
  }
}

export const WrappedValidatedInput = (
  props: ValidatedInputProps & WrappedValidatedInputProps
): React.Node => {
  const { label, hint, hintMaxSize, hintMinSize, optional, ...rest } = props

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={props.name} style={{ marginBottom: 5 }}>
          {label} {optional && <span style={{ fontWeight: 400, color: '#818792' }}>optional</span>}
          {hint && (
            <Hint minTooltipWidth={hintMinSize ?? 120} maxTooltipWidth={hintMaxSize ?? 280}>
              {hint}
            </Hint>
          )}
        </label>
      )}
      <ValidatedInput {...rest} />
    </div>
  )
}
