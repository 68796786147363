// / @flow

import * as React from 'react'

import { Radio } from 'components/form'

import { type InputProps } from './helper'

export const InputBoolean = ({ condition, updateCondition }: InputProps): React.Node => {
  return (
    <div style={{ paddingTop: 8 }}>
      <Radio
        label="Yes"
        onChange={() =>
          updateCondition(condition.set('value', condition.value.set('boolean', true)))
        }
        checked={condition.value.boolean}
      />
      <Radio
        label="No"
        style={{ marginLeft: 20 }}
        onChange={() =>
          updateCondition(condition.set('value', condition.value.set('boolean', false)))
        }
        checked={!condition.value.boolean}
      />
    </div>
  )
}
