// @flow

import * as React from 'react'
// import { hot } from 'react-hot-loader/root'
import { Route, Routes } from 'react-router-dom'

import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { Content } from 'components/styled/blocs'

import { GDPR } from './company/gdpr'
import { CompanySettings } from './company/settings'
import { Security } from './security'
import { Settings } from './settings'
import { Team } from './team/team'

import { BillingPage } from 'com.batch/account/ui/pages/billing'

const Router = () => {
  return (
    <Content noPadding>
      <Routes>
        <Route
          path="/account"
          element={
            <PageTracker category={trackingCategories.account} name={trackingPages.settings}>
              <Settings />
            </PageTracker>
          }
        />
        <Route
          path="/account/security"
          element={
            <PageTracker category={trackingCategories.account} name={trackingPages.security}>
              <Security />
            </PageTracker>
          }
        />
        <Route
          path={'/:companyId/billing'}
          element={
            <PageTracker category={trackingCategories.accountCompany} name={trackingPages.billing}>
              <BillingPage />
            </PageTracker>
          }
        />
        <Route
          path={'/:companyId/users'}
          element={
            <PageTracker category={trackingCategories.accountCompany} name={trackingPages.team}>
              <Team />
            </PageTracker>
          }
        />
        <Route
          path={'/:companyId/users/new'}
          element={
            <PageTracker category={trackingCategories.accountCompany} name={trackingPages.team}>
              <Team inviteOpened />
            </PageTracker>
          }
        />
        <Route path={'/:companyId/users/:userId'} element={<Team />} />
        <Route
          path={'/:companyId/settings'}
          element={
            <PageTracker
              category={trackingCategories.accountCompany}
              name={trackingPages.companySettings}
            >
              <CompanySettings />
            </PageTracker>
          }
        />
        <Route
          path={'/:companyId/gdpr'}
          element={
            <PageTracker category={trackingCategories.accountCompany} name={trackingPages.privacy}>
              <GDPR />
            </PageTracker>
          }
        />
      </Routes>
    </Content>
  )
}

const HotAccountRouter = Router

export const AccountRouter = (HotAccountRouter: React.AbstractComponent<{ ... }>)
