// @flow

import { type RecordOf, Record, type RecordFactory } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type DataProps = {
  value: number,
  rate: number,
  ...
}
export const DataFactory: RecordFactory<DataProps> = Record({
  value: 0,
  rate: 0,
})
export type DataRecord = RecordOf<DataProps>

type SubDistributionProps = {
  subscribed: DataRecord,
  unsubscribed: DataRecord,
  unknown: DataRecord,
  ...
}
export const SubDistributionFactory: RecordFactory<SubDistributionProps> = Record({
  subscribed: DataFactory(),
  unsubscribed: DataFactory(),
  unknown: DataFactory(),
})
export type SubDistributionRecord = RecordOf<SubDistributionProps>

type SubDistributionStateProps = {
  loadingState: fetchingState,
  distribution: SubDistributionRecord,
  ...
}

export const SubDistributionStateFactory: RecordFactory<SubDistributionStateProps> = Record(
  ({
    loadingState: STATUS.INIT,
    distribution: SubDistributionFactory(),
  }: SubDistributionStateProps)
)
export type SubDistributionStateRecord = RecordOf<SubDistributionStateProps>
