// @flow
import * as React from 'react'

import { ImageLoader } from 'components/common/image-loader'
import Loader from 'components/common/loader-legacy'

import validators from 'com.batch.common/form.validators'

import { IframeListener } from './iframe-listener'

import { type Variant } from 'com.batch.redux/_records'
import { type InAppContentRecord } from 'com.batch.redux/content.records'
import { SelectThemeField } from 'com.batch.redux/theme'
import { computeRGBA } from 'com.batch.redux/theme.api'
import { FieldFactory, type FieldKind, type ThemeRecord } from 'com.batch.redux/theme.records'

type StateProps = {
  mode: string,
  loadingThemes: boolean,
  focusField: ({ field: ?FieldKind, variant: ?Variant }) => void,
  imageUsesTemplate: boolean,
  focusedField: string,
}
type OwnProps = {
  theme: ?ThemeRecord,
  platform: string,
  data: InAppContentRecord,
  variant: ?Variant,
}
type LandingPreviewProps = { ...OwnProps, ...StateProps }

class LandingPreview extends React.PureComponent<LandingPreviewProps> {
  static defaultProps: StateProps = {
    mode: '',
    loadingThemes: false,
    imageUsesTemplate: false,
    focusField: ({ field, variant }: { field: ?FieldKind, variant: ?Variant }) => undefined,
    focusedField: '',
  }
  computeClasses: (theme: ThemeRecord) => string = theme => {
    let classes = [theme.payloadVars.stack_cta_h ? 'ctaH' : 'ctaV']
    if (
      (!theme.fields.has('image') || theme.fields.get('image', FieldFactory()).hidden) &&
      theme.payloadVars.kind === 'universal'
    ) {
      classes.push('noImage')
    }
    if (theme.payloadVars.kind === 'banner') {
      classes.push('banner')
      if (theme.payloadVars.banner_bottom) classes.push('banner--bottom')
      if (theme.payloadVars.detached) classes.push('banner--detached')
    }
    if (theme.payloadVars.kind === 'image') {
      classes.push(theme.payloadVars.fullscreen ? 'imageFullScreen' : 'image')
    }
    if (theme.payloadVars.kind === 'modal') {
      classes.push('modal')
      if (theme.payloadVars.detached) classes.push('modal--detached')
    }
    if (theme.payloadVars.kind === 'universal') {
      classes.push(theme.payloadVars.flip_hero_v ? 'heroDown' : 'heroUp')
      classes.push(theme.payloadVars.attach_cta_bottom ? 'ctaBottom' : 'ctaBellow')
    }
    return classes
      .filter(v => !!v)
      .map(v => `landingPreview--${v}`)
      .join(' ')
  }
  getTitleFor: (key: FieldKind) => string = key => {
    if (!this.props.theme) {
      return ''
    }
    const fieldOrFalse = this.props.theme.fields.get(key, false)
    if (fieldOrFalse === false) {
      return ''
    } else {
      return fieldOrFalse.label
    }
  }
  getStyleFor: (key: FieldKind) => any = key => {
    if (!this.props.theme) {
      return {}
    }
    const fieldOrFalse = this.props.theme.fields.get(key, false)
    if (fieldOrFalse === false) {
      return {}
    } else {
      let st = fieldOrFalse.style.toJS()
      if (
        key === 'general' &&
        this.props.theme &&
        this.props.theme.payloadVars.kind === 'webview'
      ) {
        st.backgroundColor = computeRGBA(String(st.backgroundColor), parseInt(st.backgroundOpacity))
        delete st.backgroundOpacity
      }
      return st
    }
  }
  isShown: (key: FieldKind) => boolean = key => {
    if (!this.props.theme) {
      return false
    }
    const fieldOrFalse = this.props.theme.fields.get(key, false)
    if (fieldOrFalse === false) {
      return false
    } else {
      return !fieldOrFalse.hidden
    }
  }
  focus: (evt: Event) => void = evt => {
    // $FlowFixMe
    if (evt.target.dataset.field) {
      this.props.focusField({ field: evt.target.dataset.field, variant: this.props.variant })
    }
  }
  render(): React.Node {
    if (this.props.loadingThemes) {
      return <Loader loading height={500} size="fat" />
    }
    if (!this.props.theme) {
      return <h2 style={{ background: 'tomato', padding: '5px', color: '#FFF' }}>Missing theme</h2>
    }
    const { data, theme, mode = 'In-App' } = this.props
    if (typeof theme === 'undefined') {
      return (
        <p
          style={{
            width: '220px',
            backgroundColor: 'crimson',
            color: '#FFF',
            border: '2px solid #FFF',
            borderRadius: '5px',
            margin: '50px auto',
            padding: '15px',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          <p>Missing theme.</p>
        </p>
      )
    }
    const cta1Fix = {
      flexGrow:
        typeof theme !== 'undefined' && theme.payloadVars.stack_cta_h && !this.isShown('cta2')
          ? 2
          : 1,
    }
    let heroStyle = theme.fields.get('image', FieldFactory()).style.toJS()
    let heroContainerStyle: { [string]: string | number, ... } = {}
    if (data && data.imageUrl) {
      heroStyle.backgroundImage = `url(${data.imageUrl})`
    } else {
      heroStyle.backgroundColor = 'rgba(0,0,0,0.2)'
      if (theme.payloadVars.kind === 'image') {
        heroContainerStyle.minHeight = theme.payloadVars.fullscreen ? 490 : 420
      }
    }

    const classes = {
      main: `landingPreview landingPreview--${this.props.platform} ${this.computeClasses(
        theme
      )} landingPreview__content`,
    }

    // specific dismiss styles
    const isWebView = this.props.theme?.payloadVars.kind === 'webview'
    const isModal = this.props.theme?.payloadVars.kind === 'modal'
    const hasImage = !this.props.theme?.fields.get('image')?.hidden
    const hasDismiss = !this.props.theme?.fields.get('dismissIndicator')?.hidden
    const specificDismissStyles =
      isModal && hasImage && hasDismiss
        ? {
            position: 'absolute',
            zIndex: 90,
            width: '100%',
            height: 2,
          }
        : {}

    const specificContainerStyles = isWebView
      ? {
          backgroundImage: `url('${window.location.origin}/medias/img/notif-preview/legacy/theme-webview-sample-img.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '55px center',
          backgroundSize: 230,
        }
      : isModal && hasImage && hasDismiss
        ? {
            position: 'relative',
            overflow: 'hidden',
          }
        : {}

    return (
      <div
        className={classes.main}
        data-field="general"
        onClick={this.focus}
        style={this.getStyleFor('general')}
      >
        <div className="landingPreview__content">
          {this.isShown('close') && (
            <a
              data-field="close"
              className="landingPreview__content__close"
              title={this.getTitleFor('close')}
              style={this.getStyleFor('close')}
            >
              <svg width="30" height="30" viewBox="0 0 30 30">
                <path d="M 10 10 L 20 20 Z M 10 20 L 20 10 Z" />
                {this.isShown('dismissIndicator') && theme.payloadVars.kind === 'image' && (
                  <circle className="auto" cx="15" cy="15" r="13" />
                )}
                {theme.payloadVars.kind === 'image' && <circle cx="15" cy="15" r="14.4" />}
              </svg>
            </a>
          )}

          <div
            data-field="general"
            className="landingPreview__content__container lpcc lpcc--selectable"
            style={{ ...this.getStyleFor('general'), ...specificContainerStyles }}
          >
            {theme.payloadVars.kind === 'webview' &&
              data.webviewUrl.substring(0, 5) === 'https' &&
              !validators.url(data.webviewUrl) && (
                <IframeListener
                  id="js-iframe-preview-webview"
                  trackingId={data.trackingId}
                  src={
                    data.webviewUrl.substring(0, 5) === 'https' && !validators.url(data.webviewUrl)
                      ? data.webviewUrl
                      : `/sample-webview?url=${data.webviewUrl}`
                  }
                  style={{ flexGrow: 1, height: 500, border: 0 }}
                />
              )}
            {this.isShown('dismissIndicator') && (
              <div
                className="landingPreview__content--dismiss"
                style={{ ...this.getStyleFor('dismissIndicator'), ...specificDismissStyles }}
              />
            )}
            {this.isShown('image') && (
              <div className="lpcc__imgcont" data-field="image" style={heroContainerStyle}>
                {data && (data.imageUrl || this.props.imageUsesTemplate) ? (
                  <ImageLoader
                    isDynamic={this.props.imageUsesTemplate}
                    isBackground={
                      theme.payloadVars.kind !== 'image' || theme.payloadVars.fullscreen
                    }
                    imgSrc={data.imageUrl}
                    data-field="image"
                    className={'lpcc__imgcont__img lpcc--selectable'}
                    title={this.getTitleFor('image')}
                    style={heroStyle}
                  />
                ) : (
                  <div
                    data-field="image"
                    className={'lpcc__imgcont__img lpcc--selectable'}
                    title={this.getTitleFor('image')}
                    style={heroStyle}
                  />
                )}
              </div>
            )}
            {theme.payloadVars.kind !== 'webview' && (
              <React.Fragment>
                <div className="lpcc__spacer" />
                <div className="lpcc__main" data-field="general">
                  {this.isShown('header') && (
                    <div
                      data-field="header"
                      className={'lpccItem lpccItem--header mightBeRtl'}
                      style={this.getStyleFor('header')}
                      title={this.getTitleFor('header')}
                    >
                      {data.header || 'HEADER'}
                    </div>
                  )}
                  {this.isShown('title') && (
                    <div
                      data-field="title"
                      className={'lpccItem lpccItem--title mightBeRtl'}
                      style={this.getStyleFor('title')}
                      title={this.getTitleFor('title')}
                    >
                      {data.title || `${mode.toUpperCase()} TITLE`}
                    </div>
                  )}
                  {this.isShown('text') && (
                    <div
                      data-field="text"
                      className={'lpccItem lpccItem--text mightBeRtl'}
                      style={{ ...this.getStyleFor('text'), whiteSpace: 'pre-line' }}
                      title={this.getTitleFor('text')}
                    >
                      {data.text || `Message of your ${mode}`}
                    </div>
                  )}
                </div>
                <div className="lpcc__spacerb" />
                <div className="lpcc__btc" style={this.getStyleFor('separator')}>
                  {this.isShown('cta2') && (
                    <div
                      data-field="cta2"
                      className={'lpcc__btc__ct'}
                      title={this.getTitleFor('cta2')}
                    >
                      <span
                        data-field="cta2"
                        style={this.getStyleFor('cta2')}
                        className="lpcc__btc__ct__bt lpcc__btc__ct__bt--cta2 mightBeRtl"
                      >
                        {data.secondaryButtonLabel || 'DISMISS'}
                      </span>
                    </div>
                  )}
                  {this.isShown('cta1') && (
                    <div
                      data-field="cta1"
                      style={cta1Fix}
                      className={'lpcc__btc__ct'}
                      title={this.getTitleFor('cta1')}
                    >
                      <span
                        data-field="cta1"
                        style={this.getStyleFor('cta1')}
                        className="lpcc__btc__ct__bt lpcc__btc__ct__bt--cta1 mightBeRtl"
                      >
                        {data.mainButtonLabel || 'MAIN BTN'}
                      </span>
                    </div>
                  )}
                </div>
                <div className="lpcc__spacer" />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default LandingPreview
