// @flow
import dayjs from 'dayjs'
import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { isEmail, textUsesTemplating } from 'com.batch.common/utils'

import {
  type JourneyNodeRecord,
  type JourneySettingsRecord,
  type QuietHoursRecord,
} from './journey.records'
import { countNodeType } from './tree.helpers'

import { messageStateSelector } from 'com.batch/message/store/message.selector'
import { type OrchestrationStateRecord } from 'com.batch/orchestration/store/orchestration.state'
import { getPushContentError } from 'com.batch/push/store/push.selector.helper'
import { type fetchingState, type State } from 'com.batch.redux/_records'
import { removeInvalidCondition } from 'com.batch.redux/query/query.api'
import { getQueryRecordForIdSelector } from 'com.batch.redux/query/query.selector'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import {
  subscriptionStatusSelector,
  targetStateSelector,
} from 'com.batch.redux/target/target.selector'

import {
  type PushContentRecord,
  SmsContentFactory,
  type EmailContentRecord,
} from 'com.batch/message/models/message.records'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields } from 'com.batch/sms/usecases/validate-sms-fields'

type Extract<T> = State => T

const orchestrationStateSelector: Extract<OrchestrationStateRecord> = state => state.orchestration
export const copiedNodeIdSelector: Extract<string> = createSelector(
  orchestrationStateSelector,
  s => s.copiedNodeId
)
export const journeySettingsSelector: Extract<JourneySettingsRecord> = createSelector(
  orchestrationStateSelector,
  j => j.triggerSettings
)
export const quietHoursSelector: Extract<{
  hasQuietHours: boolean,
  quietHours: QuietHoursRecord,
}> = createSelector(journeySettingsSelector, settings => {
  return { hasQuietHours: settings.hasQuietHours, quietHours: settings.quietHours }
})
const orchestrationNameSelector: Extract<string> = createSelector(
  orchestrationStateSelector,
  s => s.name
)
export const journeyTreeSelector: Extract<{
  rootNodeId: string,
  nodesMap: Map<string, JourneyNodeRecord>,
}> = createSelector(orchestrationStateSelector, os => ({
  rootNodeId: os.triggerRootId,
  nodesMap: os.triggerNodes,
}))

export const journeyLoadingStateSelector: Extract<fetchingState> = createSelector(
  orchestrationStateSelector,
  s => s.loadingState
)

// incomplete, on a le droit de save
export const journeyIncompleteMessageNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  subscriptionStatusSelector,
  ({ nodesMap }, messageState, targetedUserbase) => {
    const incompletesNodeIds: Array<string> = []
    nodesMap.forEach(node => {
      if (node.type !== 'MESSAGE') return
      if (
        node.channel === 'push' &&
        !isPushContentComplete(
          messageState.push.get(node.messageReference, Immutable.Map<string, PushContentRecord>())
        )
      )
        incompletesNodeIds.push(node.id)
      if (
        node.channel === 'email' &&
        !isEmailContentComplete(
          messageState.email.get(node.messageReference, Immutable.Map<string, EmailContentRecord>())
        )
      )
        incompletesNodeIds.push(node.id)
      if (node.channel === 'sms') {
        const smsContent = messageState.sms.getIn(
          [node.messageReference, 'default'],
          SmsContentFactory()
        )
        const message = smsContent.smsMessage
        const { parts } = countSmsMessage({ message, countStop: targetedUserbase === 'marketing' })

        const smsErrors = validateSmsFields({
          message,
          parts,
        })

        if (smsErrors.size > 0) {
          incompletesNodeIds.push(node.id)
        }
      }
    })
    return Immutable.Set(incompletesNodeIds)
  }
)

export const isPushContentValid = (
  pushContentForLangs: Map<string, PushContentRecord>
): boolean => {
  if (!isPushContentComplete(pushContentForLangs)) return false
  return pushContentForLangs.every(push => getPushContentError(push).length === 0)
}

const isReplyToValid = (replyTo: ?string) => {
  console.log('111')
  return replyTo ? (textUsesTemplating(replyTo) ? true : isEmail(replyTo)) : true
}

export const isEmailContentValid = (
  emailContentForLangs: Map<string, EmailContentRecord>
): boolean => {
  return emailContentForLangs.every(email => {
    const isEmpty =
      !email.fromEmail && !email.name && !email.senderIdentityId && !email.subject && !email.html

    return isReplyToValid(email.replyTo) && !isEmpty
  })
}

export const isEmailContentComplete = (
  emailContentForLangs: Map<string, EmailContentRecord>
): boolean => {
  // au moins une trad, et chaque trad complète
  return (
    emailContentForLangs.size > 0 &&
    emailContentForLangs.every(email => {
      return isReplyToValid(email.replyTo) && email.senderIdentityId && email.subject && email.html
    })
  )
}

export const isPushContentComplete = (
  pushContentForLangs: Map<string, PushContentRecord>
): boolean => {
  // au moins une trad, et chaque trad complète
  return (
    pushContentForLangs.size > 0 &&
    pushContentForLangs.every(push => {
      return push.content.pushTitle && push.content.pushBody
    })
  )
}

// ne check pas incomplete mais les erreurs empêchant de save
export const journeyInvalidMessageEmailNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  ({ nodesMap }, messageState) => {
    const invalidMessageNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (
        node.type === 'MESSAGE' &&
        node.channel === 'email' &&
        !isEmailContentValid(
          messageState.email.get(node.messageReference, Immutable.Map<string, EmailContentRecord>())
        )
      )
        invalidMessageNodes.push(node.id)
    })
    return Immutable.Set(invalidMessageNodes)
  }
)

// ne check pas incomplete mais les erreurs empêchant de save
export const journeyInvalidMessagePushNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  messageStateSelector,
  ({ nodesMap }, messageState) => {
    const invalidMessageNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (
        node.type === 'MESSAGE' &&
        node.channel === 'push' &&
        !isPushContentValid(
          messageState.push.get(node.messageReference, Immutable.Map<string, PushContentRecord>())
        )
      )
        invalidMessageNodes.push(node.id)
    })
    return Immutable.Set(invalidMessageNodes)
  }
)

export const getNodeHasTargetingSelector: Extract<(string) => boolean> = createSelector(
  getQueryRecordForIdSelector,
  targetStateSelector,
  (getQuery, targets) => {
    return (nodeId: string) => {
      const query = removeInvalidCondition(getQuery(nodeId === 'default' ? 'targeting' : nodeId))
      const target = targets.get(nodeId, TargetStateFactory())
      return query.conditions.size + target.languages.size + target.regions.size > 0
    }
  }
)
// check for YESNO nodes without targeting
export const journeyYesNoWithoutTargetingNodeIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  getNodeHasTargetingSelector,
  ({ nodesMap }, getNodeHasTargeting) => {
    const targetLessNodesIds: Array<string> = []
    nodesMap.forEach(node => {
      if (node.type === 'YESNO' && !getNodeHasTargeting(node.id)) targetLessNodesIds.push(node.id)
    })
    return Immutable.Set(targetLessNodesIds)
  }
)
// check for empty YESNO nodes
export const journeyEmptyBranchesIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    const emptyBranchesNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (node.errors.includes('SPLIT_EMPTY_BRANCHES')) emptyBranchesNodes.push(node.id)
    })
    return Immutable.Set(emptyBranchesNodes)
  }
)

// check for invalid timers
export const journeyInvalidTimerIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    const invalidTimers: Array<string> = []
    nodesMap.forEach(node => {
      if (node.errors.includes('INVALID_TIMER')) invalidTimers.push(node.id)
    })
    return Immutable.Set(invalidTimers)
  }
)

// check for distribution errors
export const journeyDistributionErrorIdsSelector: Extract<Set<string>> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    const emptyBranchesNodes: Array<string> = []
    nodesMap.forEach(node => {
      if (node.errors.includes('SPLIT_RANDOM_DISTRIBUTION')) emptyBranchesNodes.push(node.id)
    })
    return Immutable.Set(emptyBranchesNodes)
  }
)
export const journeyErrorDraftSelector: Extract<List<string>> = createSelector(
  orchestrationNameSelector,
  name => {
    const err: Array<string> = []
    if (!name) err.push('Enter a name to save.')
    return new Immutable.List().push(...err)
  }
)
export const journeyErrorSelector: Extract<List<string>> = createSelector(
  journeyErrorDraftSelector,
  journeyIncompleteMessageNodeIdsSelector,
  journeyInvalidMessageEmailNodeIdsSelector,
  journeyInvalidMessagePushNodeIdsSelector,
  journeyDistributionErrorIdsSelector,
  journeyInvalidTimerIdsSelector,
  journeyYesNoWithoutTargetingNodeIdsSelector,
  journeyEmptyBranchesIdsSelector,
  journeyTreeSelector,
  journeySettingsSelector,
  (
    errors,
    incompletesNodeIds,
    invalidEmailMessageIds,
    invalidPushMessageIds,
    invalidRandomSplitIds,
    invalidTimerIds,
    targetLessYesNoNodes,
    emptySplitNodes,
    { nodesMap },
    settings
  ) => {
    let err = errors.toArray()
    if (settings.entryEvents.filter(ev => ev.name).size === 0) err.push('Entry event is required.')
    if (settings.hasStart) {
      if (!settings.start) err.push('Start date is missing.')
      else if (settings.start.isBefore(dayjs.utc() ?? true)) err.push('Start date is in the past.')
    }
    if (settings.hasEnd) {
      if (!settings.end) err.push('End date is missing.')
      else if (settings.end?.isBefore(dayjs.utc() ?? true)) err.push('End date is in the past.')
    }
    if (invalidEmailMessageIds.size > 0 && incompletesNodeIds.size === 0)
      err.push('Some email messages are invalid.')
    if (invalidPushMessageIds.size > 0 && incompletesNodeIds.size === 0)
      err.push('Some push messages are invalid.')
    if (targetLessYesNoNodes.size > 0) err.push('Yes/No split steps must have a targeting.')
    if (invalidRandomSplitIds.size > 0) err.push('Random split distribution total must be 100.')
    if (invalidTimerIds.size > 0) err.push('Timer must be between 1 minute and 30 days.')
    if (emptySplitNodes.size > 0) err.push("Split steps can't have both branches empty.")
    if (countNodeType(nodesMap, 'MESSAGE') === 0) err.push('At least one message is required.')
    if (incompletesNodeIds.size > 0) err.push('Some steps are incomplete.')
    if (invalidEmailMessageIds.size > 0) err.push('Some steps are invalid.')
    return new Immutable.List().push(...err)
  }
)

export const getLabelForMessageIdSelector: Extract<(string) => string> = createSelector(
  journeyTreeSelector,
  ({ nodesMap }) => {
    return (messageId: string) => {
      const node = nodesMap.find(
        node => node.type === 'MESSAGE' && node.messageReference === messageId
      )
      return node && node.type === 'MESSAGE' ? node.label : ''
    }
  }
)

export const getEditingNodeId: Extract<string> = createSelector(
  orchestrationStateSelector,
  s => s.editingNodeId
)
