// @flow
import {
  OrchestrationListStatsStateFactory,
  type OrchestrationListStatsStateRecord,
} from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type BaseOrchestrationStats } from 'com.batch/shared/infra/types/grpc-stats-service'

const computeRate = (value: number, ref: number) => (ref === 0 ? 0 : value / ref)

export const parseOrchestrationStatsToListStatsState = (
  stats: BaseOrchestrationStats
): OrchestrationListStatsStateRecord => {
  // Stats service values
  const delivered = stats.delivered ?? 0
  const uniqueOpen = stats.uniqueOpen ?? 0

  // Computed values
  const openRate = computeRate(uniqueOpen, delivered)

  return OrchestrationListStatsStateFactory({
    loadingState: 'LOADED',
    delivered,
    openRate,
  })
}
