// @flow

import Immutable, { type Set, type List } from 'immutable'
import * as React from 'react'

import { Filter } from 'components/campaign/filters/filter'
import { FilterSelectMulti } from 'components/filter'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import * as schemes from 'components/styled/tokens/schemes'

const options = new Immutable.List().push(...['DRAFT', 'RUNNING', 'STOPPED', 'COMPLETED'])
const optToString = (opt: ?campaignStateType) => opt ?? ''

type StatesFilterProps = {
  value: Set<campaignStateType>,
  onChange: (Set<campaignStateType>) => void,
  isAutomation: boolean,
}

export const StatesFilter = ({ value, onChange, isAutomation }: StatesFilterProps): React.Node => {
  const formatOptionWord = React.useCallback(
    (option: campaignStateType) => {
      switch (option) {
        case 'DRAFT':
          return 'Draft'
        case 'RUNNING':
          return !isAutomation ? 'Planned' : 'Running'
        case 'STOPPED':
          return 'Stopped'
        default:
          return 'Completed'
      }
    },
    [isAutomation]
  )
  const formatTerm = React.useCallback(
    (count: number) => (count === 1 ? formatOptionWord(value.first()) : `${count} statuses`),
    [formatOptionWord, value]
  )
  const onSelect = React.useCallback(
    (values: List<campaignStateType>) => {
      onChange(values.toSet())
    },
    [onChange]
  )
  const formatOption = React.useCallback(
    (option: campaignStateType) => {
      return (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {formatOptionWord(option)}
          <CampaignStateIcon
            state={option}
            schedulingType={isAutomation ? 'automations' : 'campaigns'}
          />
        </span>
      )
    },
    [formatOptionWord, isAutomation]
  )
  return (
    <Filter title="Status">
      <FilterSelectMulti
        options={options}
        value={value.toList()}
        onChange={onSelect}
        placeholder="All statuses"
        term={formatTerm}
        isSearchable={false}
        optionToString={optToString}
        optionFormatter={formatOption}
        style={{ color: value.isEmpty() ? `${schemes.grayscale['60']}` : '' }}
      />
    </Filter>
  )
}
