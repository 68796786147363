// @flow

import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

type SspStaticKeyProps = {
  httpHeaderName: string,
  key: string,
}

export const SspStaticKeyFactory: RecordFactory<SspStaticKeyProps> = Immutable.Record(
  ({
    httpHeaderName: '',
    key: '',
  }: SspStaticKeyProps)
)
export type SspStaticKeyRecord = RecordOf<SspStaticKeyProps>

type SspProviderConfigurationProps = {
  provider: string,
  staticKey: SspStaticKeyRecord,
  subaccountID: string,
}

export const SspProviderConfigurationFactory: RecordFactory<SspProviderConfigurationProps> =
  Immutable.Record(
    ({
      provider: '',
      staticKey: SspStaticKeyFactory(),
      subaccountID: '',
    }: SspProviderConfigurationProps)
  )
export type SspProviderConfigurationRecord = RecordOf<SspProviderConfigurationProps>

type TransmissionTypesConfigurationProps = {
  marketing: Immutable.List<SspProviderConfigurationRecord>,
  transactional: Immutable.List<SspProviderConfigurationRecord>,
}

export const TransmissionTypesConfigurationFactory: RecordFactory<TransmissionTypesConfigurationProps> =
  Immutable.Record(
    ({
      marketing: new Immutable.List(),
      transactional: new Immutable.List(),
    }: TransmissionTypesConfigurationProps)
  )
export type TransmissionTypesConfigurationRecord = RecordOf<TransmissionTypesConfigurationProps>

type SspCountryConfigurationProps = {
  transmissionTypesConfiguration: TransmissionTypesConfigurationRecord,
}

export const SspCountryConfigurationFactory: RecordFactory<SspCountryConfigurationProps> =
  Immutable.Record(
    ({
      transmissionTypesConfiguration: TransmissionTypesConfigurationFactory(),
    }: SspCountryConfigurationProps)
  )
export type SspCountryConfigurationRecord = RecordOf<SspCountryConfigurationProps>

type SspConfigurationProps = {
  senders: Immutable.List<string>,
  maxParts: number,
  smsCountriesConfiguration: Immutable.Map<string, SspCountryConfigurationRecord>,
}

export const SspConfigurationFactory: RecordFactory<SspConfigurationProps> = Immutable.Record(
  ({
    senders: new Immutable.List(),
    maxParts: 0,
    smsCountriesConfiguration: Immutable.Map(),
  }: SspConfigurationProps)
)
export type SspConfigurationRecord = RecordOf<SspConfigurationProps>
