// @flow
import dayjs, { type Dayjs } from 'dayjs'

const advancedFormat = require('dayjs/plugin/advancedFormat')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const duration = require('dayjs/plugin/duration')
const isBetween = require('dayjs/plugin/isBetween')
const isoWeek = require('dayjs/plugin/isoWeek')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const relativeTime = require('dayjs/plugin/relativeTime')
const utc = require('dayjs/plugin/utc')
const weekday = require('dayjs/plugin/weekday')

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isoWeek)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

type ParseDate = (str?: string, format?: string) => Date | void
type FormatDate = (date?: Date, format?: string) => string

export const formatDate: FormatDate = (date, format = 'L') => {
  return date ? dayjs.utc(date).format(Array.isArray(format) ? format[0] : format) : ''
}

export const parseDate: ParseDate = (str, format = 'L') => {
  const m = dayjs.utc(str, Array.isArray(format) ? format[0] : format, 'en', true)

  if (m.isValid()) {
    return m.toDate()
  }
  return undefined
}

export const stringToUtcDayJs = (val: ?string): null | Dayjs => {
  if (typeof val === 'undefined' || val === null) {
    return null
  }
  return dayjs.utc(val.substring(0, 19))
}

export { dayjs }
