// @flow
import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

export const NoMoreEventsContent: StyledComponent<*, *, *> = styled.div`
  background: ${colors.fillDisabled};
  border-radius: 6px;
  margin: 54px 12px 16px 12px;
  height: 120px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  position: relative;
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    top: -59px;
    height: 40px;
    left: 15.5px;
    width: 1.5px;
    background: linear-gradient(180deg, #e0e0e0 0%, rgba(224, 224, 224, 0) 100%);
  }
`

export const NoMoreEventsContentTitle: StyledComponent<*, *, *> = styled.div`
  color: ${colors.textNeutral};
  font-weight: 500;
  font-size: 16px;
`
