// @flow

import * as React from 'react'

import AnalyticsRouter from 'components/analytics/analytics-router'
import { AppWrapper } from 'components/common/app-wrapper'

export const App = (): React.Node => {
  return (
    <AppWrapper sidebarKind="dashboard" epicMode="none">
      <AnalyticsRouter />
    </AppWrapper>
  )
}
