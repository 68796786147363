// @flow

import {
  ProfilesDistributionStateFactory,
  type ProfilesDistributionStateRecord,
} from 'com.batch/profilebase/models/profiles-distribution.records'
import { type FetchProfilesDistributionActions } from 'com.batch/profilebase/usecases/fetch-profiles-distribution'
import { STATUS } from 'constants/common'

const initialState = ProfilesDistributionStateFactory()

type ProfilesDistributionAction = FetchProfilesDistributionActions

export function profilesDistributionReducer(
  state: ProfilesDistributionStateRecord = initialState,
  action: ProfilesDistributionAction
): ProfilesDistributionStateRecord {
  switch (action.type) {
    case 'FETCH_PROFILES_DISTRIBUTION':
      return state.set('loadingState', STATUS.LOADING)
    case 'FETCH_PROFILES_DISTRIBUTION_SUCCESS':
      return state.set('loadingState', STATUS.LOADED).set('distribution', action.payload)
    case 'FETCH_PROFILES_DISTRIBUTION_FAILURE':
      return state.set('loadingState', STATUS.ERROR)
    default:
      return state
  }
}
