// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Editor, InputWrapper, Select } from 'components/form'
import { textLight } from 'components/styled/tokens/colors'

import { capitalize, pluralize } from 'com.batch.common/utils'

import { updateEmailContent } from './usecases/update-email-content'

import {
  getContentHasDeletedSenderIdentitySelector,
  getContentHasUpdatedSenderIdentitySelector,
  getEmailContentForSelectedLanguageSelector,
} from 'com.batch/email/store/email-automation.selector'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'
import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'
import {
  senderIdentitiesSelector,
  senderIdentityLoadingStateSelector,
} from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { updateEmailSender } from 'com.batch/email/usecases/update-content'
import { SenderIdentityOptionFormatter } from 'com.batch/orchestration-journey/ui/components/canvas/canvas.helper'
import { type TransmissionType } from 'com.batch/shared/models/transmission-type'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'
import { STATUS } from 'constants/common'

const identityToString = (opt: ?SenderIdentityRecord) => {
  return opt ? `${opt.sendingName}${opt.sendingPrefix}@${opt.sendingDomain}` : ''
}

type MessageProps = {
  campaignFormMode?: boolean,
  messageId: string,
  onChangeQuietHoursEnabledForNode?: (boolean: boolean) => void,
  quietHoursEnabledForNode?: boolean,
}

export const Message: React$AbstractComponent<MessageProps, *> = React.memo(
  ({ messageId = 'default' }: MessageProps): React.Node => {
    const dispatch = useDispatch()
    const getContent = useSelector(getEmailContentForSelectedLanguageSelector)
    const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
    const lang = useSelector(activeLanguageValueSelector)
    const senderIdentities = useSelector(senderIdentitiesSelector)
    const targetedUserbase = useSelector(subscriptionStatusSelector)
    const requiredTransmissionType: TransmissionType = React.useMemo(
      () => (targetedUserbase === 'marketing' ? 'MARKETING' : 'TRANSACTIONAL'),
      [targetedUserbase]
    )
    const filteredSenderIdentities = React.useMemo(
      () =>
        senderIdentities.filter(
          si =>
            si.transmissionTypes.includes(requiredTransmissionType) ||
            si.transmissionTypes.size === 0
        ),
      [requiredTransmissionType, senderIdentities]
    )
    const getCurrentSenderIdentityHasBeenUpdated = useSelector(
      getContentHasUpdatedSenderIdentitySelector
    )

    const currentSenderIdentityHasBeenUpdated = React.useMemo(
      () => getCurrentSenderIdentityHasBeenUpdated(messageId),
      [getCurrentSenderIdentityHasBeenUpdated, messageId]
    )
    const getCurrentSenderIdentityHasBeenDeleted = useSelector(
      getContentHasDeletedSenderIdentitySelector
    )
    const currentSenderIdentityHasBeenDeleted = React.useMemo(
      () => getCurrentSenderIdentityHasBeenDeleted(messageId),
      [getCurrentSenderIdentityHasBeenDeleted, messageId]
    )
    const senderIdentityLoadingState = useSelector(senderIdentityLoadingStateSelector)
    const isPristine = React.useMemo(() => content?.isPristine ?? true, [content?.isPristine])
    useLeaveConfirmation({ isPristine })

    const onSenderIdentityChange = React.useCallback(
      (identity: ?SenderIdentityRecord) => {
        if (identity) {
          dispatch(
            updateEmailSender({
              messageId,
              name: identity.sendingName,
              senderIdentityId: identity.id,
              fromEmail: `${identity.sendingPrefix}@${identity.sendingDomain}`,
            })
          )
        }
      },
      [dispatch, messageId]
    )

    const formattedSenderIdentity = React.useMemo(
      () =>
        content?.fromEmail && content?.name ? (
          <p>
            <span style={{ fontWeight: 500 }}>{content?.name}</span> (
            <span style={{ whiteSpace: 'nowrap' }}>{content.fromEmail}</span>)
          </p>
        ) : null,
      [content]
    )

    const handleUpdateSubject = React.useCallback(
      (value: string) => {
        return dispatch(updateEmailContent({ messageId, field: 'subject', lang, value }))
      },
      [dispatch, lang, messageId]
    )

    const senderInputFeedback = React.useMemo(() => {
      const current = `${content?.name ?? ''}<${content?.fromEmail ?? ''}>`
      if (senderIdentityLoadingState === STATUS.LOADING) {
        return { label: 'Loading sender identities...', type: 'insight' }
      }
      if (senderIdentityLoadingState === STATUS.ERROR) {
        return { label: 'Unable to load sender identities', type: 'error' }
      }
      if (currentSenderIdentityHasBeenUpdated) {
        return {
          label: `Selected sender has been modified (${current}). Choose again.`,
          type: 'warning',
        }
      }
      if (currentSenderIdentityHasBeenDeleted) {
        return {
          label: `Selected sender has been deleted (${current}). Choose another one.`,
          type: 'error',
        }
      }
      return { label: undefined, type: undefined }
    }, [
      content?.name,
      content?.fromEmail,
      senderIdentityLoadingState,
      currentSenderIdentityHasBeenUpdated,
      currentSenderIdentityHasBeenDeleted,
    ])

    const isLoading = React.useMemo(
      () => senderIdentityLoadingState === STATUS.LOADING,
      [senderIdentityLoadingState]
    )

    const isError = React.useMemo(
      () => senderIdentityLoadingState === STATUS.ERROR,
      [senderIdentityLoadingState]
    )

    const charCount = React.useMemo(() => content?.subject?.length ?? 0, [content?.subject])

    return (
      <div
        style={{
          display: 'grid',
          height: '100%',
          gridTemplateRows: '1fr auto',
        }}
      >
        <Wrapper
          isLoading={isLoading}
          isEmpty={isError}
          style={{ height: '100%' }}
          isOverlayShown={isError}
          overlayProps={GlobalErrorOverlayProps}
        >
          <Grid template="1fr 1fr" alignItems="start" gap={16}>
            <div>
              <InputWrapper
                label={`${capitalize(requiredTransmissionType.toLowerCase())} senders`}
                feedback={senderInputFeedback.label}
                feedbackType={senderInputFeedback.type}
                feedbackWeight={500}
                hint={`Only ${requiredTransmissionType.toLowerCase()} and uncategorized senders are listed. Change your targeting to use ${
                  requiredTransmissionType === 'MARKETING' ? 'transactional' : 'marketing'
                } senders.`}
                hintMinSize={260}
              >
                <Select
                  isSearchable
                  isDisabled={senderIdentityLoadingState === 'LOADING'}
                  options={filteredSenderIdentities}
                  optionFormatter={SenderIdentityOptionFormatter}
                  optionToString={identityToString}
                  onChange={onSenderIdentityChange}
                  localSearchEveryWhere
                  optionMenuHeight={58}
                  optionMenuShownCount={5}
                  noResultsNode="No sender found"
                  noOptionText="No sender settings"
                  placeholder="name@yourcompany.com"
                  data-testid="select_sender_identity"
                  value={
                    currentSenderIdentityHasBeenUpdated || currentSenderIdentityHasBeenDeleted
                      ? undefined
                      : filteredSenderIdentities.find(
                          option => option.id === content?.senderIdentityId
                        )
                  }
                  tooltip={formattedSenderIdentity}
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                label="Subject"
                htmlFor="subject"
                style={{ position: 'relative' }}
                testId="input_email_subject"
              >
                {charCount > 0 && (
                  <div
                    style={{
                      textAlign: 'right',
                      position: 'absolute',
                      top: -2,
                      right: 0,
                      color: textLight,
                    }}
                  >
                    {pluralize('character', charCount)}
                  </div>
                )}
                <Editor
                  id={messageId}
                  key={messageId}
                  onChange={handleUpdateSubject}
                  placeholder="Email subject"
                  value={content?.subject ?? ''}
                />
              </InputWrapper>
            </div>
          </Grid>
        </Wrapper>
      </div>
    )
  }
)
