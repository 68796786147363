// @flow

import { type Map } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { InactiveEditorContainer } from './common.styles'
import { InlineEditorEditor } from './inline-editor.helper'

import { type TemplateRecord } from 'com.batch.redux/_records'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'

type InlineEditorProps = {
  messageId: string,
  isActive: boolean,
  onBlur: ([number, number]) => void,
  placeholder?: string,
  field: MessageBuilderEditableField,
  variant: 'a' | 'b',
  canUseTemplating: boolean,
  selection: [number, number],
  onFocus: () => void,
  onActivateAtPos: number => void,
  onChange: string => void,
  value: string,
  templateCache: Map<string, TemplateRecord>,
}

export const InlineEditor: React$AbstractComponent<InlineEditorProps, *> = React.memo(
  ({
    messageId,
    value,
    onBlur,
    selection,
    templateCache,
    onActivateAtPos,
    variant,
    onFocus,
    field,
    canUseTemplating,
    isActive,
    onChange,
    placeholder,
  }: InlineEditorProps): React.Node => {
    const theme = useTheme()

    const parsing = React.useMemo(
      () => templateCache.getIn([value, 'parsing'], false),
      [templateCache, value]
    )
    const text = React.useMemo(
      () => templateCache.getIn([value, 'results', 0, 'result'], value),
      [templateCache, value]
    )
    const onMouseUp = React.useCallback(() => {
      if (!theme?.disabledMode) {
        const selection = document.getSelection()
        onActivateAtPos(selection?.anchorOffset ?? 0)
      }
    }, [onActivateAtPos, theme?.disabledMode])

    return isActive || parsing ? (
      <InlineEditorEditor
        messageId={messageId}
        isLoading={parsing}
        initValue={value}
        variant={variant}
        field={field}
        canUseTemplating={canUseTemplating}
        onBlur={onBlur}
        selection={selection}
        onChange={onChange}
        placeholder={placeholder}
      />
    ) : (
      <InactiveEditorContainer $isPlaceholder={!value} onMouseUp={onMouseUp}>
        {!value ? placeholder : text}
        {!theme.disabledMode && (
          <input
            type="text"
            style={{
              textIndent: '-9999em',
              width: 0,
              height: 0,
              opacity: 0.01,
              position: 'fixed',
              top: 0,
            }}
            onFocus={onFocus}
          />
        )}
      </InactiveEditorContainer>
    )
  }
)
