// @flow
import { type Map } from 'immutable'

import { getPlatformName } from './get-platform-name'

import { type InstallRecord } from 'com.batch/profile/models/profile.records'

export const getPlatformList = (installs: Map<string, InstallRecord>): string =>
  installs
    .reduce((acc, current) => {
      if (current.platform && !acc.includes(getPlatformName(current.platform)))
        acc.push(getPlatformName(current.platform))
      return acc
    }, [])
    .join(' & ')
