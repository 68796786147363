// @flow

import * as React from 'react'

import { GrabbablePreview } from 'components/campaign/review/review/rsr.styles'

type PreviewScrollerProps = { children: React.Node, height: number, headerStuck: boolean, ... }
type PreviewScrollerState = {
  grabbed: boolean,
  availHeight: number,
  ...
}
function getPosition(element: HTMLElement) {
  let yPosition = 0
  while (element) {
    yPosition += element.offsetTop - element.scrollTop + element.clientTop
    // $FlowFixMe t'es lourd gros
    element = element.offsetParent
  }
  return yPosition
}

const HEADER_MARGIN = 20
const MARGIN_BOTTOM = 20

export class PreviewScroller extends React.PureComponent<
  PreviewScrollerProps,
  PreviewScrollerState,
> {
  element: ?HTMLElement
  header: ?HTMLElement
  offset: number
  ref: number
  lastScrollPos: number

  constructor(props: PreviewScrollerProps) {
    super(props)
    this.state = {
      grabbed: false,
      availHeight: 2000,
    }
    this.offset = 0
    this.ref = 0
    this.lastScrollPos = 0
  }
  componentDidMount() {
    this.header = document.getElementById('fixedReviewHead')

    this.setState({ availHeight: window.innerHeight - this.getVisibleHeaderHeight() })
    window.addEventListener('resize', this.calculateLimit)
    window.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateLimit)
  }
  getVisibleHeaderHeight: () => number = () => {
    let headerTop = 0
    let headerHeight = 0
    if (this.header) {
      const dim = this.header.getBoundingClientRect()
      headerTop = dim.top
      headerHeight = dim.height
    }
    const hh =
      headerTop > 0 ? headerHeight + headerTop : headerHeight + Math.max(-headerHeight, headerTop)
    return hh + HEADER_MARGIN
    // (this.props.headerStuck ? 20 : HEADER_MARGIN) : 20
  }
  componentDidUpdate: () => void = () => {
    this.updatePreviewPosition()
  }
  onScroll: () => void = () => {
    const scrollPos = document.body ? document.body.getBoundingClientRect().top : 0
    const up = scrollPos > this.lastScrollPos
    this.lastScrollPos = scrollPos
    this.offset = this.limitOffset(this.offset, !up ? 'top' : 'bottom')
    this.updatePreviewPosition()
  }
  updatePreviewPosition: () => void = () => {
    const vh = this.getVisibleHeaderHeight()
    if (this.element) {
      this.element.style.top = `${vh + this.offset}px`
    }
  }
  setRef: () => void = (elem: ?HTMLElement) => {
    this.setState({ availHeight: window.innerHeight - this.getVisibleHeaderHeight() })
    this.element = elem ? elem : null
  }
  calculateLimit: () => void = () => {
    this.setState({ availHeight: window.innerHeight - this.getVisibleHeaderHeight() })
    this.offset = 0
    const vh = this.getVisibleHeaderHeight()
    if (this.element) {
      this.element.style.top = `${vh + this.offset}px`
    }
    // Depending on device size, screen size and scroll (header fixed or not ?)
  }
  onMouseDown: (evt: MouseEvent) => void = evt => {
    this.setState({ grabbed: true })
    this.ref = evt.screenY
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }
  onMouseUp: () => void = () => {
    this.setState({ grabbed: false })
    this.ref = 0
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }
  onMouseMove: (evt: MouseEvent) => void = evt => {
    evt.stopPropagation()
    evt.preventDefault()
    if (evt.screenY === this.ref) {
      return
    }
    if (this.state.grabbed) {
      let offset = this.limitOffset(
        this.offset + (evt.screenY - this.ref),
        evt.screenY - this.ref < 0 ? 'top' : 'bottom'
      )
      this.ref = evt.screenY
      this.offset = offset
      const vh = this.getVisibleHeaderHeight()
      if (this.element) {
        this.element.style.top = `${vh + offset}px`
      }
    }
    return
  }
  limitOffset: (offset: number, direction: 'top' | 'bottom') => number = (offset, direction) => {
    const top = this.element ? getPosition(this.element) : 0
    const bottom = top + this.props.height
    const vh = this.getVisibleHeaderHeight()
    const wh = window.innerHeight - MARGIN_BOTTOM
    let hiddenTop = 0
    let hiddenBottom = 0
    if (top < 0) {
      // la preview a scroll plus haut que la page
      hiddenTop = -top + vh
    } else {
      if (top < vh) {
        // sinon, il manque un truc en haut juste si le header visible est plus important
        hiddenTop = vh - top
      }
    }
    if (bottom > wh) {
      hiddenBottom = bottom - wh
    }
    if (direction === 'top') {
      const decalage = Math.abs(offset) - Math.abs(this.offset)
      const grabbable = this.state.availHeight < this.props.height && window.innerWidth >= 680
      const grabOffset = this.offset - Math.min(decalage, hiddenBottom)
      const nextHiddenBottom = grabOffset + this.props.height + vh - wh
      return nextHiddenBottom < 0 && grabbable ? grabOffset - nextHiddenBottom : grabOffset
    } else {
      return Math.min(offset, hiddenTop)
    }
  }

  onMouseOut: (evt: MouseEvent) => void = evt => {
    evt.stopPropagation()
    evt.preventDefault()
    return
  }

  render(): React.Node {
    const grabbable = this.state.availHeight < this.props.height && window.innerWidth >= 680
    // console.log(this.element, `max amplitude => ${this.props.height - this.state.availHeight}, offset = ${this.state.offset}`)
    return (
      <GrabbablePreview
        ref={this.setRef}
        style={{ height: this.props.height }}
        grabbable={grabbable}
        grabbing={this.state.grabbed}
        onMouseDown={grabbable ? this.onMouseDown : null}
      >
        {this.props.children}
      </GrabbablePreview>
    )
  }
}
