// @flow
import Immutable from 'immutable'

import { computeRate } from './compute-rate'

import {
  type StatsRecord,
  StatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { type BaseOrchestrationStats } from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseBaseStats = (stats: BaseOrchestrationStats): StatsRecord => {
  // Stats service values
  const delivered = stats.delivered ?? 0
  const sent = stats.sent ?? 0
  const click = stats.click ?? 0
  const bounce = stats.bounce ?? 0
  const uniqueClick = stats.uniqueClick ?? 0
  const open = stats.open ?? 0
  const spam = stats.spam ?? 0
  const skippedSuppressionList = stats.skippedSuppressionList ?? 0
  const unsubscribe = stats.unsubscribe ?? 0
  const mpp = stats.mpp ?? 0
  const uniqueOpen = stats.uniqueOpen ?? 0
  const uniqueMpp = stats.uniqueMpp ?? 0
  const injection = stats.injection ?? 0

  // Computed values
  let sending = sent - delivered - bounce - skippedSuppressionList
  // C'est possible que le delay soit négatif quand les stats sont décalées / résorbées après coup
  if (sending < 0) sending = 0
  const bounceRate = computeRate(bounce, sent)
  const clickRate = computeRate(uniqueClick, delivered)
  const deliveredRate = computeRate(delivered, sent)
  const skippedRate = computeRate(skippedSuppressionList, sent)
  const unsubscribeRate = computeRate(unsubscribe, delivered)
  const sendingRate = computeRate(sending, sent)
  const openRate = computeRate(uniqueOpen, delivered)
  const uniqueMppRate = computeRate(uniqueMpp, delivered)

  return StatsFactory({
    bounce: Immutable.Record({
      value: bounce,
      rate: bounceRate,
      categories: new Immutable.List(),
      providers: new Immutable.List(),
    })(),
    click: Immutable.Record({
      value: click,
      rate: clickRate,
      unique: uniqueClick,
      links: new Immutable.List(),
    })(),
    mpp: Immutable.Record({ value: mpp, unique: uniqueMpp, rate: uniqueMppRate })(),
    delivered: Immutable.Record({ value: delivered, rate: deliveredRate })(),
    open: Immutable.Record({ value: open, rate: openRate, unique: uniqueOpen })(),
    unsubscribe: Immutable.Record({ value: unsubscribe, rate: unsubscribeRate })(),
    skippedSuppressionList: Immutable.Record({
      value: skippedSuppressionList,
      rate: skippedRate,
    })(),
    sending: Immutable.Record({ value: sending, rate: sendingRate })(),
    injection,
    spam,
    sent,
  })
}
