// @flow
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'

import { type StateToggler } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { EmailDropzone } from './email-dropzone'
import { useDraggingFile } from './use-dragging-file'

import { getSelectEmailIsUploading } from 'com.batch/email/store/email-automation.selector'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'
import {
  ChoiceBox,
  ChoiceContainer,
  DashedDivider,
} from 'com.batch/email/ui/components/email-choice/email-choice.styles'
import { EmailUploader } from 'com.batch/email/ui/components/email-choice/email-uploader'
import { uploadEmail } from 'com.batch/email/usecases/upload-email'
import { type EmailContentRecord } from 'com.batch/message/models/message.records'

type EmailChoiceProps = {
  dragAndDropEditorToggleState: StateToggler,
  codeEditorToggleState: StateToggler,
  content: ?EmailContentRecord,
  messageId: string,
}
export const EmailChoice = ({
  dragAndDropEditorToggleState,
  codeEditorToggleState,
  content,
  messageId,
}: EmailChoiceProps): React.Node => {
  const getIsEmailUploading = useSelector(getSelectEmailIsUploading)
  const isEmailUploading = React.useMemo(
    () => getIsEmailUploading(messageId),
    [getIsEmailUploading, messageId]
  )

  const dispatch = useDispatch()

  const { isDragging: isDraggingFileInWindow, dropped } = useDraggingFile()

  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>) => {
      dispatch(uploadEmail(acceptedFiles[0], messageId))
    },
    [dispatch, messageId]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted: onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/html': ['.htm'],
      'text/htm': ['.html'],
      'application/zip': ['.zip'],
      'application/x-zip-compressed': ['.zip'],
    },
  })

  const value = React.useMemo(() => content?.html ?? '', [content])

  const mode: DropzoneState = React.useMemo(() => {
    if (isEmailUploading) {
      return 'loading'
    } else if (isDragActive) {
      return 'active'
    } else if (value) {
      return 'preview'
    }
    return 'field'
  }, [isDragActive, isEmailUploading, value])

  const showDropzone = React.useMemo(
    () => isDraggingFileInWindow || (mode !== 'field' && dropped),
    [isDraggingFileInWindow, dropped, mode]
  )

  const onSubmit = React.useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      dragAndDropEditorToggleState.open()
    },
    [dragAndDropEditorToggleState]
  )

  return (
    <div
      style={{
        display: 'flex',
        placeContent: 'center',
        height: '100%',
        flexWrap: 'wrap',
        backgroundImage: 'url("/medias/img/builders/email-background.webp")',
        backgroundRepeat: 'repeat',
        backgroundSize: '400px 400px',
        ...(showDropzone ? { padding: 28 } : {}),
      }}
    >
      {showDropzone ? (
        <EmailDropzone getRootProps={getRootProps} getInputProps={getInputProps} mode={mode} />
      ) : (
        <ChoiceContainer>
          <ChoiceBox onClick={dragAndDropEditorToggleState.open} onSubmit={onSubmit}>
            <button type="submit" />
            <h2>Design your message with the Email Composer</h2>
            <p>
              Open the drag-and-drop composer and start designing your email message intuitively
            </p>
            <img src="/medias/img/drag-and-drop-editor.webp" height={152} />
          </ChoiceBox>

          <DashedDivider />

          <section style={{ display: 'flex', flexDirection: 'column' }}>
            <EmailUploader getInputProps={getInputProps} mode={mode} open={open} />

            <Button
              onClick={codeEditorToggleState.open}
              style={{ height: 48, width: '100%' }}
              data-testid="button_open_email_code_editor"
            >
              or open the code editor
              <Icon icon="code" style={{ marginLeft: 8 }} />
            </Button>
          </section>
        </ChoiceContainer>
      )}
    </div>
  )
}
