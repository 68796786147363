// @flow
import { createSelector } from 'reselect'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type SubDistributionRecord,
  type SubDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'

type extract<T> = State => T

const pushOptinDistributionSelector = (state: State): SubDistributionStateRecord => {
  return state.profilebase.pushSubDistribution
}

export const pushOptinDistribLoadingStateSelector: extract<fetchingState> = createSelector(
  pushOptinDistributionSelector,
  pushSubDistribution => pushSubDistribution.loadingState
)

export const pushOptinDistribDataSelector: extract<SubDistributionRecord> = createSelector(
  pushOptinDistributionSelector,
  pushSubDistribution => pushSubDistribution.distribution
)
