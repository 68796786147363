// @flow

import { type List, type Map } from 'immutable'
import * as React from 'react'

import Highlight from 'components/common/highlight'
import { Icon } from 'components/common/svg-icon'
import Text from 'components/common/text'
import { VariantTitle } from 'components/styled/text'

import { ReviewFields } from './text-review-fields'
import { ReviewContainer, ReviewContent, ReviewMessage, ReviewTitle } from './text-review.styles'

import { type CampaignABRecord, type LanguageRecord } from 'com.batch.redux/_records'
import {
  type AbTestedInAppRecord,
  AbTestedPushFactory,
  type AbTestedPushRecord,
  PushContentLegacyFactory,
} from 'com.batch.redux/content.records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'

type TextReviewProps = {
  abtesting: CampaignABRecord,
  variantsThemes: AbTestedThemeRecord,
  hasLanding: boolean,
  inappContent: Map<string, AbTestedInAppRecord>,
  isPush: boolean,
  languages: List<LanguageRecord>,
  payload: string,
  pushContent: Map<string, AbTestedPushRecord>,
  ...
}

const variants = ['a', 'b']

export const TextReviewRaw = ({
  payload,
  languages,
  abtesting,
  variantsThemes,
  pushContent,
  inappContent,
  hasLanding,
}: TextReviewProps): React.Node => {
  let formatedPayload = payload
  if (payload) {
    try {
      formatedPayload = JSON.stringify(JSON.parse(payload), null, 2)
    } catch (err) {
      formatedPayload = payload
    }
  }

  return (
    <ReviewContainer>
      {payload && (
        <React.Fragment>
          <h4 className="review__title">Custom payload</h4>
          <Highlight language="js">{formatedPayload}</Highlight>
        </React.Fragment>
      )}
      {languages.map(lang => {
        return (
          <ReviewContent key={lang.value}>
            <ReviewTitle>{lang.label}</ReviewTitle>
            <ReviewMessage>
              {pushContent.size > 0 &&
                variants
                  .filter(variant => variant === 'a' || abtesting.enabled)
                  .map(variant => {
                    return (
                      <div key={variant} className="review__message" style={{ marginBottom: 10 }}>
                        <div
                          key={variant}
                          style={{
                            marginTop: 0,
                          }}
                        >
                          {abtesting.enabled && (
                            <VariantTitle variant={variant}>
                              Version {variant.toUpperCase()} (
                              {abtesting.activeVariants.has(variant)
                                ? abtesting.activeVariants.size === 2
                                  ? '50'
                                  : '100'
                                : '0'}
                              %)
                            </VariantTitle>
                          )}
                          <Text append={<br />}>
                            {
                              pushContent
                                .get(lang.value, AbTestedPushFactory())
                                .get(variant, PushContentLegacyFactory()).title.value
                            }
                          </Text>
                          <Text append={<br />}>
                            {
                              pushContent
                                .get(lang.value, AbTestedPushFactory())
                                .get(variant, PushContentLegacyFactory()).message.value
                            }
                          </Text>
                          {pushContent
                            .get(lang.value, AbTestedPushFactory())
                            .get(variant, PushContentLegacyFactory()).deeplink && (
                            <React.Fragment>
                              <Icon icon="link" size={10} style={{ margin: '0 6px 0 0' }} />
                              {
                                pushContent
                                  .get(lang.value, AbTestedPushFactory())
                                  .get(variant, PushContentLegacyFactory()).deeplink
                              }
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    )
                  })}
              {hasLanding && (
                <ReviewFields
                  variantsThemes={variantsThemes}
                  abTestedInAppContent={inappContent}
                  lang={lang.value}
                  abtesting={abtesting}
                />
              )}
            </ReviewMessage>
          </ReviewContent>
        )
      })}
    </ReviewContainer>
  )
}

export const TextReview: React.AbstractComponent<TextReviewProps> = React.memo(TextReviewRaw)
