// @flow
import * as React from 'react'

import { colors, schemes } from 'components/styled/tokens'

type DotSvgProps = {
  $hasError?: boolean,
  ...
}
export const DotSvg = (props: DotSvgProps): React.Node => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <circle cx={8} cy={8} r={8} fill="#F8F8F8" />
    <circle
      cx={8}
      cy={8}
      r={3}
      fill={props.$hasError ? colors.strokeDanger : schemes.grayscale['30']}
    />
  </svg>
)
