// @flow

import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'

import { capitalize, formatNumberWithCommas, percentage } from 'com.batch.common/utils'

import { bouncesColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { type BounceCategoryRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  BounceBadge,
  BounceStatLegend,
  BounceStatText,
} from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'
import { type PlotData } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  category: BounceCategoryRecord,
  selectedBounce: PlotData | null,
  ...
}

export const BounceCategory = ({ category, selectedBounce }: Props): React.Node => {
  const name = category.name.toLowerCase()
  const count = React.useMemo(() => {
    if (selectedBounce) return selectedBounce[name].value
    return category?.count ?? 0
  }, [selectedBounce, name, category?.count])
  const rate = React.useMemo(() => {
    if (selectedBounce) return selectedBounce[name].rate
    return category?.rate ?? 0
  }, [selectedBounce, name, category?.rate])
  return (
    <div>
      <BounceStatLegend>
        {capitalize(name)} bounce
        <BounceBadge color={bouncesColors[name]} />
      </BounceStatLegend>
      <BounceStatText>
        <Skeleton w={74} h={33}>
          {formatNumberWithCommas(count)} <span>{count !== 0 ? percentage(rate, 2) : '0%'}</span>
        </Skeleton>
      </BounceStatText>
    </div>
  )
}
