// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type ReachabilityTimeserieStateRecord,
  type ReachabilityTimeserieRecord,
} from 'com.batch/profilebase/models/reachability-timeserie.records'

type extract<T> = State => T

const smsReachabilitySelector = (state: State): ReachabilityTimeserieStateRecord => {
  return state.profilebase.smsReachability
}

export const smsReachabilityLoadingStateSelector: extract<fetchingState> = createSelector(
  smsReachabilitySelector,
  smsReachability => smsReachability.loadingState
)

export const smsSubCounterLastMonthSelector: extract<number> = createSelector(
  smsReachabilitySelector,
  smsReachability => smsReachability.counter.subscriptionsLastMonth
)

// Map of new subscriptions by day (subscription Growth)
export const smsSubsGrowthSelector: extract<Map<string, ReachabilityTimeserieRecord>> =
  createSelector(smsReachabilitySelector, smsReachability =>
    smsReachability.timeserie
      .filter(f => f.subscriptions > 0)
      .reduce(
        (acc, m) => acc.set(dayjs.utc(m.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), m),
        Immutable.Map()
      )
  )
