// @flow
import { validateJSON } from 'com.batch.common/utils'

import { type PushContentRecord } from 'com.batch/message/models/message.records'

/* extracted to an helper file to avoid cycle dep */
export const getPushContentError = (contentForLang: PushContentRecord): Array<string> => {
  let err: Array<string> = []
  if (!contentForLang.content.pushTitle) err.push('Notification title is missing')
  if (!contentForLang.content.pushBody) err.push('Notification body is missing')
  if (contentForLang.content.customPayload) {
    if (!validateJSON(contentForLang.content.customPayload)) {
      err.push('Custom payload is not valid JSON')
    }
  }
  if (contentForLang.ttlEnabled && !contentForLang.ttl.valid)
    err.push('Expiration (TTL) must be between 1 minute & 28 days.')
  if (contentForLang.iosContent.customPayload) {
    if (!validateJSON(contentForLang.iosContent.customPayload)) {
      err.push('iOS custom payload is not valid JSON')
    }
  }
  if (contentForLang.androidContent.customPayload) {
    if (!validateJSON(contentForLang.androidContent.customPayload)) {
      err.push('Android custom payload is not valid JSON')
    }
  }
  return err
}
