// @flow
import Immutable, { type Map, type OrderedSet, type Set } from 'immutable'

import { autoLayout } from './autolayout'
import { formatFinal } from './node-formatters/reactflow-final'
import { formatMessage } from './node-formatters/reactflow-message'
import { formatRandom } from './node-formatters/reactflow-random'
import { formatTimer } from './node-formatters/reactflow-timer'
import { formatYesNo } from './node-formatters/reactflow-yesno'
import { buildSourceAndHandleFromBranchId, generateEdge } from './reactflow.tools'
import {
  type FlowRestrictions,
  type InterractionCallbacks,
  type ReactFlowEdge,
  type ReactFlowNode,
  type FormatterProps,
  type FormatterReturnedProps,
} from './reactflow.types'

import {
  type JourneyNodeRecord,
  type JourneySettingsRecord,
  type YesNoNodeRecord,
  type BranchId,
  type RandomNodeRecord,
} from '../models/journey.records'
import {
  countNodeType,
  getAllDescendantsForBranch,
  getAllNodeBranchIds,
  getNodeById,
} from '../models/tree.helpers'

const getBranchNextNode = (
  branchId: BranchId,
  node: YesNoNodeRecord | RandomNodeRecord
): string => {
  switch (branchId.type) {
    case 'RANDOM':
      if (node.type !== 'RANDOM') throw new Error('Invalid node / branch combo')
      return node.splits.getIn([branchId.splitIndex, 'nextNodeId'], 'NOT EMPTY')
    case 'YESNO':
      if (node.type !== 'YESNO') throw new Error('Invalid node / branch combo')
      return branchId.branch === 'yes' ? node.yesNodeId : node.noNodeId
    default:
      throw new Error('Invalid node / branch combo')
  }
}

const renderSubFlow = ({
  node,
  nodes,
  ...props
}: {
  ...FormatterProps,
  node: YesNoNodeRecord | RandomNodeRecord,
}): FormatterProps => {
  const rejoinButtonTargets: Array<BranchId> = []
  // we will have to recurse on all branchId for this node
  const branchIds = getAllNodeBranchIds(node)
  const meta = branchIds.map(branchId => {
    return {
      branchId,
      descendants: getAllDescendantsForBranch({ nodesMap: nodes, branchId }),
    }
  })
  // get rejoin node to stop recursion for each branch
  const commonDescendants = meta.reduce((acc, { descendants }) => {
    // $FlowExpectedError(incompatible-cast): type refinement issue, diz is fine
    const commons: OrderedSet<JourneyNodeRecord> = acc.intersect(descendants)
    return commons
  }, meta[0].descendants)
  const rejoinNode = commonDescendants.first()
  if (!rejoinNode) {
    throw new Error('rejoinNode is undefined')
  }

  // == LOOP ON BRANCHES ==
  meta.forEach(({ branchId, descendants }, index) => {
    const addButtonTargets: Array<BranchId> = []
    const branchIsEmpty = descendants.first() === rejoinNode

    if (branchIsEmpty) {
      // no need to recursively render descendants, just change the renderBy
      props = {
        ...props,
        ownedBy: branchId,
      }
      addButtonTargets.push(branchId)
    } else {
      props = parseNodeRecursive({
        ...props,
        nodes,
        nodeId: getBranchNextNode(branchId, node),
        stopRenderAt: props.stopRenderAt.push(rejoinNode.id),
        ownedBy: branchId,
      })
      props.rejoinInsertAfterBranchIds.forEach(branchId => {
        addButtonTargets.push(branchId)
      })
    }

    const skipAddButton = props.ownedBy.type === 'REJOIN'
    const addBtnId = `${node.id}__${index}__add`
    if (!skipAddButton) {
      props.reactflowNodes.push({
        id: addBtnId,
        type: 'buttonNode',
        sizingType: 'add',
        data: {
          reduxNodeId: node.id,
          isActive: false,
          hasError: false,
          flowRestrictions: props.flowRestrictions,
          pasteNode: () => props.callbacks.onPasteNode(addButtonTargets),
          insertNode: (nodeType, channel) => {
            props.callbacks.onInsertNode({
              branchIds: addButtonTargets,
              nodeType,
              channel,
            })
          },
        },
        position: {
          x: 0,
          y: 0,
        },
      })
      props.reactflowEdges.push(
        generateEdge({
          ...buildSourceAndHandleFromBranchId(props.ownedBy),
          target: addBtnId,
        })
      )
    }

    // link to rejoin node, not yet added, so we know if we got chain error
    props.reactflowEdges.push(
      generateEdge({
        ...(skipAddButton
          ? buildSourceAndHandleFromBranchId(props.ownedBy)
          : { source: addBtnId, sourceHandle: 'source' }),
        target: node.id + '_rejoin',
      })
    )

    rejoinButtonTargets.push(...addButtonTargets)
  })
  // == END loop on branches ==

  // add rejoin button
  props.reactflowNodes.push({
    id: node.id + '_rejoin',
    type: 'buttonNode',
    sizingType: 'rejoin',
    data: {
      reduxNodeId: node.id,
      isActive: false,
      flowRestrictions: props.flowRestrictions,
      pasteNode: () => props.callbacks.onPasteNode(rejoinButtonTargets),
      insertNode: (nodeType, channel) => {
        props.callbacks.onInsertNode({
          branchIds: rejoinButtonTargets,
          nodeType,
          channel,
        })
      },
    },
    position: { x: 0, y: 0 },
  })

  return {
    ...props,
    ownedBy: {
      type: 'REJOIN',
      nodeId: node.id,
    },
    rejoinInsertAfterBranchIds: new Immutable.List().push(...rejoinButtonTargets),
    resumeRenderingAt: rejoinNode.id,
    nodes,
  }
}

/*
 1. rendre le noeud courant
 2. le lier à ?
 3. récupérer ses branchIds
   3a. si plus d'une branchId, 
    - calculer l'offsetX pour chaque branchId
    - trouver le rejoinNode
    - appeler parseNodeRecursive pour chaque branchId
    - continuer le récursif en partant de rejoin node
   3b. si pas de branchId, on est à la fin de la branche, on retourne
*/
const parseNodeRecursive = ({ ...props }: FormatterProps): FormatterProps => {
  const node = getNodeById({ nodes: props.nodes, nodeId: props.nodeId })
  if (node.id === props.stopRenderAt.last())
    return { ...props, stopRenderAt: props.stopRenderAt.pop() }
  switch (node.type) {
    case 'MESSAGE': {
      const parsedMessage = formatMessage({
        node,
        ...props,
      })
      return parseNodeRecursive({
        ...parsedMessage,
        ownedBy: { messageNodeId: props.nodeId, type: 'MESSAGE' },
        rejoinInsertAfterBranchIds: new Immutable.List().push({
          messageNodeId: props.nodeId,
          type: 'MESSAGE',
        }),

        nodeId: node.nextNodeId,
      })
    }
    case 'TIMER': {
      const parsedTimer = formatTimer({
        node,
        ...props,
      })
      return parseNodeRecursive({
        ...parsedTimer,
        ownedBy: { timerNodeId: props.nodeId, type: 'TIMER-NEXT' },
        rejoinInsertAfterBranchIds: new Immutable.List().push({
          timerNodeId: props.nodeId,
          type: 'TIMER-NEXT',
        }),
        nodeId: node.nextNodeId,
      })
    }
    case 'FINAL': {
      return formatFinal({
        node,
        ...props,
      })
    }
    case 'YESNO': {
      const parsedYesNo = formatYesNo({
        node,
        ...props,
      })
      const parsedSubFlow = renderSubFlow({
        ...parsedYesNo,
        node,
        nodeId: node.yesNodeId,
      })
      if (!parsedSubFlow.resumeRenderingAt) {
        throw new Error('resumeRenderingAt is required after rendering a sublfow')
      }
      return parseNodeRecursive({
        ...parsedSubFlow,
        nodeId: parsedSubFlow.resumeRenderingAt,
      })
    }
    case 'RANDOM': {
      const parsedRandom = formatRandom({
        node,
        ...props,
      })
      const parsedSubFlow = renderSubFlow({
        ...parsedRandom,
        node,
        nodeId: node.splits.first().nextNodeId,
      })
      if (!parsedSubFlow.resumeRenderingAt) {
        throw new Error('resumeRenderingAt is required after rendering a sublfow')
      }
      return parseNodeRecursive({
        ...parsedSubFlow,
        nodeId: parsedSubFlow.resumeRenderingAt,
      })
    }
  }

  return props
}

export const buildNodesAndEdges = ({
  settings,
  rootNodeId,
  availableChannels,
  nodes,
  callbacks,
}: {
  settings: JourneySettingsRecord,
  rootNodeId: string,
  availableChannels: Set<ChannelUntilCleanup>,
  nodes: Map<string, JourneyNodeRecord>,
  callbacks: InterractionCallbacks,
  ...
}): FormatterReturnedProps => {
  if (console.time) console.time('RF build')
  let reactflowNodes: Array<ReactFlowNode> = []
  let edges: Array<ReactFlowEdge> = []
  reactflowNodes.push({
    id: 'ROOT',
    sizingType: 'ROOT',
    type: 'entryNode',
    position: { x: 0, y: 0 },
    data: {
      reduxNodeId: rootNodeId,
      entryEvents: settings.entryEvents.map(evt => {
        return { eventName: evt.name, hasQuery: evt.query !== '' }
      }),
      hasCustomTargeting: false, // overriden by component
      openSettingsSheet: tab => {
        callbacks.openSettingsSheet(tab)
      },
    },
  })
  const flowRestrictions: FlowRestrictions = {
    maxMessageReached: countNodeType(nodes, 'MESSAGE') > 20,
    availableChannels,
  }

  const result = parseNodeRecursive({
    reactflowNodes,
    reactflowEdges: edges,
    callbacks,
    resumeRenderingAt: null,
    rejoinInsertAfterBranchIds: new Immutable.List(),
    ownedBy: { type: 'ROOT' },
    flowRestrictions,
    stopRenderAt: new Immutable.List(),
    nodes,
    nodeId: rootNodeId,
  })
  if (console.timeEnd) console.timeEnd('RF build')

  return {
    reactflowNodes: autoLayout(result.reactflowNodes, result.reactflowEdges),
    reactflowEdges: result.reactflowEdges,
  }
}
