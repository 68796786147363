// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider, useTheme } from 'styled-components'

import EstimateLegacy from 'components/campaign/review/review/estimate-legacy'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  BoxSection,
  HeaderBoxTitle,
} from 'components/common/box'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import { BoxedRadio, InputWrapper } from 'components/form'
import { Segment } from 'components/project/campaign/common/targeting/segment'
import { QueryBuilder } from 'components/query/query-builder-targeting'

import { LangRegion } from './lang-region'

import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import {
  canPickImportedSelector,
  targetingLangSelector,
  targetingRegionSelector,
} from 'com.batch.redux/campaign.selector'
import { clustersRaw } from 'com.batch.redux/cluster'
import { updateLangRegion, updateSegment, updateTargetKind } from 'com.batch.redux/target/target'
import {
  estimateGetterSelector,
  estimateProfileGetterSelector,
  selectedLanguagesIdGetterSelector,
  selectedRegionsIdGetterSelector,
  selectedSegmentCodeGetterSelector,
  subscriptionStatusSelector,
} from 'com.batch.redux/target/target.selector'

import { emptyAllEmailSenders } from 'com.batch/email/usecases/update-content'
import { EstimateProfile } from 'com.batch/orchestration/ui/components/estimate-profile'
import { EstimateProfileMono } from 'com.batch/orchestration/ui/components/estimate-profile-mono'
import { checkIncompleteMessageNodes } from 'com.batch/orchestration-journey/usecases/check-incomplete-message-node'

type TargetingProps = {
  canvasMode?: boolean,
  channel?: ChannelUntilCleanup,
  id: string,
  isProfile?: boolean,
  step?: 'enter' | 'yesno' | 'targeting',
}

const fakeErrors = Immutable.Set()
const nullFunc = () => {}
export const Targeting = ({
  canvasMode,
  channel,
  isProfile,
  step,
  id,
}: TargetingProps): React.Node => {
  const getProfileReach = useSelector(estimateProfileGetterSelector)
  const getInstallReach = useSelector(estimateGetterSelector)
  const reachProfile = React.useMemo(() => getProfileReach(id), [getProfileReach, id])
  const reachInstall = React.useMemo(() => getInstallReach(id), [getInstallReach, id])
  return canvasMode ? (
    <TargetingRaw isProfile={isProfile} step={step} id={id} />
  ) : (
    <Box style={{ overflow: 'inherit' }} id="targeting">
      <BoxHeader>
        <HeaderBoxTitle title="Targeting" />
      </BoxHeader>
      <BoxBody>
        <TargetingRaw isProfile={isProfile} id={id} channel={channel} />
      </BoxBody>
      {isProfile ? (
        <BoxFooter isEditable style={{ height: 100, padding: '0 0px' }}>
          {channel ? (
            <EstimateProfileMono estimate={reachProfile} channel={channel} />
          ) : (
            <EstimateProfile estimate={reachProfile} />
          )}
        </BoxFooter>
      ) : (
        <BoxFooter isEditable style={{ height: 76, padding: '0 20px' }}>
          <EstimateLegacy
            estimate={reachInstall}
            tokenMode
            hideLoader={false}
            refreshEstimate={nullFunc}
          />
        </BoxFooter>
      )}
    </Box>
  )
}

const TargetingRaw = ({ isProfile, step, id = 'default', channel }: TargetingProps): React.Node => {
  // ------ redux
  const dispatch = useDispatch()
  const segmentCodesGetter = useSelector(selectedSegmentCodeGetterSelector)
  const segmentCodes = React.useMemo(() => segmentCodesGetter(id), [segmentCodesGetter, id])
  const allSegments = useSelector(clustersRaw)
  const languages = useSelector(targetingLangSelector)
  const languageIdsGetter = useSelector(selectedLanguagesIdGetterSelector)
  const languageIds = React.useMemo(() => languageIdsGetter(id), [languageIdsGetter, id])
  const regions = useSelector(targetingRegionSelector)
  const hasImported = useSelector(canPickImportedSelector)
  const subscriptionStatus = useSelector(subscriptionStatusSelector)
  // @TODO on avait un indicateur avant qu'on a perdu avec la refonte des Boxes
  // const attributesLoaded = useSelector(attributesLoadedSelector)
  const regionIdsGetter = useSelector(selectedRegionsIdGetterSelector)
  const regionIds = React.useMemo(() => regionIdsGetter(id), [regionIdsGetter, id])
  // ------ derived
  const segments = React.useMemo(
    () => allSegments.filter(segment => segment.related && (!hasImported || segment.code !== 'I')),
    [allSegments, hasImported]
  )
  const { triggerNodes } = useSelector(orchestrationStateSelector)

  const messagesCount: { total: number, email: number, sms: number, ... } = React.useMemo(() => {
    let total = 0
    let email = 0
    let sms = 0
    triggerNodes
      .filter(node => node.type === 'MESSAGE')
      .forEach(node => {
        total++
        if (node.type === 'MESSAGE' && node.channel === 'email') email++
        if (node.type === 'MESSAGE' && node.channel === 'sms') sms++
      })
    return { total, email, sms }
  }, [triggerNodes])
  // ----- callbacks
  const updateLangs = React.useCallback(
    languages => {
      dispatch(
        updateLangRegion({
          what: 'languages',
          id,
          values: Immutable.Set(languages),
        })
      )
    },
    [dispatch, id]
  )
  const updateRegions = React.useCallback(
    regions =>
      dispatch(
        updateLangRegion({
          what: 'regions',
          id,
          values: Immutable.Set(regions),
        })
      ),
    [dispatch, id]
  )
  const onSegmentToggle = React.useCallback(
    segment => {
      return add => dispatch(updateSegment({ add, code: segment.code, id }))
    },
    [dispatch, id]
  )
  const createOnOptedInChange = React.useCallback(
    (value: 'marketing' | 'fullbase') => () => {
      if (subscriptionStatus === value) return
      if (messagesCount.sms > 0 || messagesCount.email > 0) {
        confirm({
          title: value === 'marketing' ? 'Switch to marketing' : 'Switch to transactional',
          confirm: 'Confirm',
          message:
            value === 'marketing' ? (
              <div>
                {messagesCount.email > 0 && (
                  <p>
                    On email steps, you will have to specify marketing senders if you switch to a
                    transactional automation.
                  </p>
                )}
                {messagesCount.sms > 0 && (
                  <p>
                    On SMS messaqes, the "STOP" mention will be added. The number of message parts
                    may change.
                  </p>
                )}
              </div>
            ) : (
              <div>
                {messagesCount.email > 0 && (
                  <p>
                    On email steps, you will have to specify transactional senders if you switch to
                    a transactional automation.
                  </p>
                )}
                {messagesCount.sms > 0 && (
                  <p>
                    On SMS messages, the "STOP" mention will be removed. The number of message parts
                    may change.
                  </p>
                )}
              </div>
            ),
        }).then(
          () => {
            dispatch(updateTargetKind({ value }))
            dispatch(emptyAllEmailSenders())
            dispatch(checkIncompleteMessageNodes())
          },
          () => {}
        )
      } else {
        dispatch(updateTargetKind({ value }))
      }
    },
    [dispatch, messagesCount, subscriptionStatus]
  )

  const parentTheme = useTheme()
  const theme = React.useMemo(
    () => ({
      step: step,
      disabledMode: parentTheme?.disabledMode ?? false,
    }),
    [step, parentTheme?.disabledMode]
  )

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {!isProfile && (
          <BoxSection style={{ padding: '18px 22px 0 22px' }}>
            <InputWrapper label="Segments">
              <div style={{ display: 'flex', height: 43 }}>
                {segments.map(segment => (
                  <Segment
                    key={segment.code}
                    segment={segment}
                    onChange={onSegmentToggle(segment)}
                    checked={segmentCodes.has(segment.code)}
                  />
                ))}
              </div>
            </InputWrapper>
          </BoxSection>
        )}
        {isProfile && id === 'default' && channel !== 'push' && (
          <BoxSection style={{ padding: '24px 20px 0 20px' }}>
            <Grid template="1fr 1fr" gap={20}>
              <BoxedRadio
                checked={subscriptionStatus === 'marketing'}
                title="Marketing"
                description={
                  <React.Fragment>
                    All your marketing communications with your users.
                    <br />
                    Users need to be opt-in to receive it.
                  </React.Fragment>
                }
                onChange={createOnOptedInChange('marketing')}
              />
              <BoxedRadio
                checked={subscriptionStatus === 'fullbase'}
                title="Transactional"
                description={
                  <React.Fragment>
                    Services, orders and other kind of transactions.
                    <br />
                    Users do not need to be opt-in to receive it.
                  </React.Fragment>
                }
                onChange={createOnOptedInChange('fullbase')}
              />
            </Grid>
          </BoxSection>
        )}
        <BoxSection style={{ padding: '0 20px' }}>
          <LangRegion
            verticalForm
            kind="country"
            selected={regionIds}
            values={regions}
            onChange={updateRegions}
          />
        </BoxSection>
        <BoxSection style={{ padding: '0 20px' }}>
          <LangRegion
            verticalForm
            kind="language"
            selected={languageIds}
            values={languages}
            onChange={updateLangs}
          />
        </BoxSection>
      </ThemeProvider>
      <BoxHeader
        style={{
          height: 'auto',
          borderRadius: 0,
          borderTop: 'none',
          borderBottom: 'none',
        }}
      >
        <HeaderBoxTitle
          style={{ fontSize: 14, marginBottom: 4 }}
          title={!isProfile ? 'Advanced targeting' : 'Segmentation'}
        />
      </BoxHeader>
      <QueryBuilder
        isProfileMode={Boolean(isProfile)}
        queryId={id === 'default' ? 'targeting' : id}
        errors={fakeErrors}
      />
    </React.Fragment>
  )
}
