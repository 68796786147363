// @flow
import * as React from 'react'
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow'

import { LineButton, type LineButtonProps } from './nodes/line-button'

export type MarkerType = 'arrow' | 'arrowclosed'
export type Position = 'left' | 'top' | 'right' | 'bottom'

export type EdgeMarker = {
  type: MarkerType,
  color?: string,
  width?: number,
  height?: number,
  markerUnits?: string,
  orient?: string,
  strokeWidth?: number,
  ...
}

export type EdgeMarkerType = string | EdgeMarker
export type EdgeProps<T, D> = {
  id: string,
  type: T,
  data: D,
  path: string,
  labelX: number,
  labelY: number,
  label: string,
  labelStyle: string,
  labelShowBg: boolean,
  labelBgStyle: string,
  labelBgPadding: number,
  labelBgBorderRadius: number,
  style: { [string]: string | number, ... },
  markerStart: EdgeMarkerType,
  markerEnd: string,
  interactionWidth: number,
  sourceX: number,
  sourceY: number,
  sourcePosition?: Position,
  targetX: number,
  targetY: number,
  targetPosition?: Position,
  curvature?: number,
}

export const ButtonEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style,
  markerEnd,
}: EdgeProps<'buttonEdge', LineButtonProps>): React.Node => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 50,
    offset: 1,
  })

  return (
    <React.Fragment>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <LineButton {...data} />
        </div>
      </EdgeLabelRenderer>
    </React.Fragment>
  )
}

export const edgeTypes = {
  buttonEdge: ButtonEdge,
}
