import { combineReducers } from 'redux'

import { emailReachabilityReducer } from 'com.batch/profilebase/store/email-reachability.reducer'
import { emailSubDistributionReducer } from 'com.batch/profilebase/store/email-sub-distribution.reducer'
import { profilesDistributionReducer } from 'com.batch/profilebase/store/profiles-distribution.reducer'
import { pushReachabilityReducer } from 'com.batch/profilebase/store/push-reachability.reducer'
import { pushSubDistributionReducer } from 'com.batch/profilebase/store/push-sub-distribution.reducer'
import { smsReachabilityReducer } from 'com.batch/profilebase/store/sms-reachability.reducer'
import { smsSubDistributionReducer } from 'com.batch/profilebase/store/sms-sub-distribution.reducer'

export const profilebaseReducer = combineReducers({
  profilesDistribution: profilesDistributionReducer,
  emailSubDistribution: emailSubDistributionReducer,
  emailReachability: emailReachabilityReducer,
  smsSubDistribution: smsSubDistributionReducer,
  smsReachability: smsReachabilityReducer,
  pushSubDistribution: pushSubDistributionReducer,
  pushReachability: pushReachabilityReducer,
})
