// @flow
// ====================== LIBS
import { type List } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { RemovableBlockWrapper } from './common.styles'
import { EventTrigger, EventTriggerStart } from './event-button.styles'

import { type settingsTabKind } from 'com.batch/orchestration-journey/ui/pages/journey-canvas-form'

// ====================== COMPONENTS
// ====================== DATA

// ====================== TYPES
export type EventButtonProps = {
  value?: List<{ eventName: string, hasQuery: boolean, ... }>,
  onClick: (tab: settingsTabKind) => void,
  onRemove?: () => void,
  placeholder?: string,
  reduxNodeId: string,
  isRoot?: boolean,
  editingNodeId?: string,
}

export const EventButton = ({
  onClick,
  value,
  onRemove,
  reduxNodeId,
  placeholder = 'Select trigger',
  isRoot,
  editingNodeId,
}: EventButtonProps): React.Node => {
  // First match
  const hasQuery = React.useMemo(() => {
    return value?.find(v => v.hasQuery)
  }, [value])

  // const handleClick = useBlurOnClickCallback(onClick)

  const onClickBlur = React.useCallback(
    param => evt => {
      evt?.currentTarget?.blur()
      onClick(param)
    },
    [onClick]
  )

  return value && value.filter(v => v.eventName).size === 0 ? (
    <EventTriggerStart
      addOn="prefix"
      intent="action"
      kind="secondary"
      data-testid={'button_event_' + (isRoot ? '_ROOT' : reduxNodeId)}
      onClick={onClickBlur('enter')}
      className="nopan"
      $isEditing={!!isRoot && editingNodeId === 'ROOT'}
    >
      <Icon icon="mouse" size={14} />
      {placeholder}
    </EventTriggerStart>
  ) : (
    <Tooltip
      isTooltipEmpty={value?.size === 1}
      placement="bottom"
      minWidth={200}
      tooltip={value?.map(({ eventName, hasQuery }, key) => (
        <p key={key}>
          <span style={{ marginRight: 4, marginBottom: 4 }}>{eventName}</span>{' '}
          {hasQuery && <Icon color="white" icon="filter" size={16} />}
        </p>
      ))}
    >
      <span>
        <RemovableBlockWrapper isRemovable={Boolean(onRemove)} className="nodrag nopan">
          <EventTrigger
            addOn="prefix"
            onClick={onClickBlur('enter')}
            className="nopan"
            data-testid={'button_event_' + (isRoot ? '_ROOT' : reduxNodeId)}
            $hasError={false}
            $isEditing={
              (!isRoot && editingNodeId === reduxNodeId) || (!!isRoot && editingNodeId === 'ROOT')
            }
          >
            <Icon icon="mouse" size={14} />
            <span>
              {value && value?.size > 1
                ? `${value?.size} events`
                : (value?.get(0)?.eventName ?? '').replace('e.', '')}
            </span>
            {hasQuery && <Icon icon="filter" size={14} />}
          </EventTrigger>
          <div className="nodrag nopan styled-remove">
            <Button
              onClick={onRemove}
              intent="danger"
              kind="secondary"
              className="nodrag nopan"
              data-testid="button_remove_node"
            >
              <Icon icon="delete" />
            </Button>
          </div>
        </RemovableBlockWrapper>
      </span>
    </Tooltip>
  )
}
