// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button/switch'
import { Grid } from 'components/common/grid'
import { InputWrapper, TimePicker } from 'components/form'
import { Radio } from 'components/form/fields/radio'
import * as colors from 'components/styled/tokens/colors'

import { QuietHoursFactory } from 'com.batch/orchestration-journey/models/journey.records'
import {
  journeySettingsSelector,
  quietHoursSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'
import { updateSettings } from 'com.batch/orchestration-journey/usecases/update-settings'

export const QuietHoursBlock = (): React.Node => {
  const journeySettings = useSelector(journeySettingsSelector)
  const { hasQuietHours, quietHours } = useSelector(quietHoursSelector)
  const dispatch = useDispatch()

  const handleChange = React.useCallback(
    (active: boolean) => {
      dispatch(
        updateSettings(
          journeySettings.set('hasQuietHours', active).set('quietHours', QuietHoursFactory())
        )
      )
    },
    [dispatch, journeySettings]
  )

  const handleBehavior = React.useCallback(
    behavior => () => {
      dispatch(updateSettings(journeySettings.setIn(['quietHours', 'behavior'], behavior)))
    },
    [dispatch, journeySettings]
  )

  const createOnChangeHandler = React.useCallback(
    field => {
      return value => {
        dispatch(updateSettings(journeySettings.setIn(['quietHours', field], value)))
      }
    },
    [dispatch, journeySettings]
  )

  return (
    <React.Fragment>
      <div style={{ padding: '28px 20px', height: 'fit-content' }}>
        <Switch isActive={journeySettings.hasQuietHours} onChange={handleChange}>
          <span>Quiet Hours</span>
        </Switch>
        <p style={{ marginTop: 4, color: colors.textLight }}>
          By enabling quiet hours, you will be able to specify a time slot during which the profiles
          in this automation won’t be messaged.
        </p>
        <div style={{ height: 1, background: colors.stroke, marginTop: '20px' }} />
      </div>
      <div>
        {hasQuietHours && (
          <React.Fragment>
            <div style={{ padding: '0 20px', height: 'fit-content' }}>
              <p
                style={{
                  marginBottom: 8,
                  color: colors.text,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Time slot
              </p>
              <Grid template="140px 10px 140px">
                <TimePicker
                  onHoursChange={createOnChangeHandler('startHour')}
                  onMinutesChange={createOnChangeHandler('startMin')}
                  value={[quietHours.startHour, quietHours.startMin]}
                />
                to
                <TimePicker
                  onHoursChange={createOnChangeHandler('endHour')}
                  onMinutesChange={createOnChangeHandler('endMin')}
                  value={[quietHours.endHour, quietHours.endMin]}
                />
              </Grid>
            </div>

            <div style={{ marginTop: 28, marginLeft: 20, height: 'fit-content' }}>
              <p
                style={{
                  color: colors.text,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Fallback strategy
              </p>

              <InputWrapper>
                <div style={{ marginBottom: 10, marginTop: 10 }}>
                  <Radio
                    checked={quietHours.behavior === 'wait'}
                    label="Send next available time"
                    onChange={handleBehavior('wait')}
                  />
                </div>
                <div>
                  <Radio
                    checked={quietHours.behavior === 'skip'}
                    label="Skip the message step and continue"
                    onChange={handleBehavior('skip')}
                  />
                </div>
              </InputWrapper>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
