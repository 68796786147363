// @flow

import { type ReactFlowEdge } from './reactflow.types'

import { type BranchId } from 'com.batch/orchestration-journey/models/journey.records'

export const buildSourceAndHandleFromBranchId = (
  branchId: BranchId
): {
  source: string,
  sourceHandle: string,
} => {
  switch (branchId.type) {
    case 'ROOT':
      return { source: 'ROOT', sourceHandle: 'next' }
    case 'RANDOM':
      return {
        source: branchId.randomNodeId,
        sourceHandle: branchId.splitIndex.toString(),
      }
    case 'REJOIN':
      return {
        source: `${branchId.nodeId}_rejoin`,
        sourceHandle: 'next',
      }
    case 'MESSAGE':
      return { source: branchId.messageNodeId, sourceHandle: 'next' }
    case 'YESNO':
      return {
        source: branchId.yesNoNodeId,
        sourceHandle: branchId.branch,
      }
    case 'TIMER-NEXT':
      return { source: branchId.timerNodeId, sourceHandle: 'next' }
    case 'TIMER-EVENT':
      // this must remain a edge case because of the renderring we want for this node
      throw new Error('Not implemented')
  }
}

export const handleAddOrSourceBranch = (
  branchId: BranchId,
  addButtonId: ?string
): {
  source: string,
  sourceHandle: string,
} => {
  return addButtonId
    ? { source: addButtonId, sourceHandle: 'next' }
    : buildSourceAndHandleFromBranchId(branchId)
}
export const generateEdge = ({
  source,
  target,
  targetHandle = 'enter',
  sourceHandle = 'next',
  type = 'smoothstep',
  withArrow = false,
}: {
  source: string,
  target: string,
  sourceHandle?: string,
  targetHandle?: string,
  type?: 'smoothstep' | 'default',
  withArrow?: boolean,
}): ReactFlowEdge => {
  return {
    id: `${source} - ${sourceHandle} > > >  ${target} - ${targetHandle}`,
    source: source,
    sourceHandle: sourceHandle !== 'source' ? sourceHandle : undefined,
    target: target,
    targetHandle: targetHandle === 'enter' ? undefined : targetHandle,
    type,
    pathOptions: { borderRadius: 50, offset: 1 },
    markerEnd: withArrow ? 'batch-arrow' : undefined,
  }
}
