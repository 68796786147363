// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper } from 'components/form'

import { currentProjectAppsSelector } from 'com.batch.redux/app'

import { type PushContentRecord } from 'com.batch/message/models/message.records'
import { PushActionPicker } from 'com.batch/push/ui/push-action-picker/push-action-picker'
import { updatePushContentRecord } from 'com.batch/push/usecases/update-push-content'

type PushAction = 'nothing' | 'deeplink' | 'landing'
const actions = new Immutable.List().push(
  ...[
    {
      label: 'Redirect to app',
      description: 'Head to the app home screen',
      value: 'nothing',
    },
    {
      label: 'Open deeplink',
      description: 'Open a specific link/screen in the app',
      value: 'deeplink',
    },
    // { label: 'Open landing', value: 'landing' }
  ]
)
type Props = {
  content: PushContentRecord,
  messageId: string,
  lang: string,
}
export const PushDeeplinkField = ({ content, messageId, lang }: Props): React.Node => {
  const dispatch = useDispatch()
  const [pushAction, setPushAction] = React.useState<PushAction>(
    content.iosContent.deeplink || content.androidContent.deeplink || content.content.deeplink
      ? 'deeplink'
      : 'nothing'
  )
  const [splitPerPlatform, setSplitPerPlatform] = React.useState(
    !!(content.iosContent.deeplink || content.androidContent.deeplink)
  )

  const apps = useSelector(currentProjectAppsSelector)
  const [hasIos, hasAndroid] = React.useMemo(() => {
    const platforms = apps.map(app => app.platform)
    return [platforms.includes('ios'), platforms.includes('android')]
  }, [apps])

  const handleOnDeeplinkChange = React.useCallback(
    (platform: 'android' | 'ios' | 'all') => evt => {
      if (platform === 'android') {
        dispatch(
          updatePushContentRecord({
            messageId,
            lang,
            record: content.setIn(['androidContent', 'deeplink'], evt.target.value),
          })
        )
      } else if (platform === 'ios') {
        dispatch(
          updatePushContentRecord({
            messageId,
            lang,
            record: content.setIn(['iosContent', 'deeplink'], evt.target.value),
          })
        )
      } else {
        dispatch(
          updatePushContentRecord({
            messageId,
            lang,
            record: content.setIn(['content', 'deeplink'], evt.target.value),
          })
        )
      }
    },
    [content, dispatch, lang, messageId]
  )

  const onPushActionChange = React.useCallback(
    action => {
      // Si on passe de deeplink à rien, on supprime les deeplinks
      if (action?.value === 'nothing') {
        dispatch(
          updatePushContentRecord({
            messageId,
            lang,
            record: content
              .setIn(['iosContent', 'deeplink'], '')
              .setIn(['androidContent', 'deeplink'], ''),
          })
        )
      }
      setPushAction(action ? action.value : 'nothing')
    },
    [content, dispatch, lang, messageId]
  )

  // Quand on passe de split <=> no split, on supprime les deeplinks par plateforme ou le deeplink global
  const handleOnSplitPerPlatformChange = React.useCallback(() => {
    setSplitPerPlatform(s => !s)
    if (splitPerPlatform) {
      dispatch(
        updatePushContentRecord({
          messageId,
          lang,
          record: content
            .setIn(['iosContent', 'deeplink'], '')
            .setIn(['androidContent', 'deeplink'], ''),
        })
      )
    } else {
      dispatch(
        updatePushContentRecord({
          messageId,
          lang,
          record: content.setIn(['content', 'deeplink'], ''),
        })
      )
    }
  }, [content, dispatch, lang, messageId, splitPerPlatform])

  return (
    <InputWrapper>
      <PushActionPicker
        value={actions.find(o => o.value === pushAction)}
        onChange={onPushActionChange}
        options={actions}
      >
        {pushAction === 'deeplink' &&
          (splitPerPlatform ? (
            <React.Fragment>
              <InputWrapper
                style={{ width: 'auto', marginTop: 0, flexGrow: 1 }}
                label={
                  <React.Fragment>
                    <Icon icon="ios" style={{ marginRight: 8 }} /> iOS
                  </React.Fragment>
                }
              >
                <Input
                  value={content.iosContent.deeplink}
                  onChange={handleOnDeeplinkChange('ios')}
                  placeholder="app://deeplink-destination"
                  style={{ marginRight: 8 }}
                  autoFocus
                />
              </InputWrapper>

              <InputWrapper
                style={{ width: 'auto', marginTop: 0, flexGrow: 1 }}
                label={
                  <span>
                    <Icon icon="android" style={{ marginRight: 8 }} /> Android
                  </span>
                }
              >
                <Input
                  value={content.androidContent.deeplink}
                  onChange={handleOnDeeplinkChange('android')}
                  placeholder="app://deeplink-destination"
                />
              </InputWrapper>
            </React.Fragment>
          ) : (
            <InputWrapper style={{ width: 'auto', marginTop: 0, flexGrow: 1 }} label=" ">
              <Input
                value={content.content.deeplink}
                onChange={handleOnDeeplinkChange('all')}
                placeholder="app://deeplink-destination"
                autoFocus
              />
            </InputWrapper>
          ))}

        <div style={{ position: 'absolute', right: 0, top: 0 }}>
          {pushAction === 'deeplink' && hasAndroid && hasIos && (
            <Switch isActive={splitPerPlatform} onChange={handleOnSplitPerPlatformChange}>
              Split by platform
            </Switch>
          )}
        </div>
      </PushActionPicker>
    </InputWrapper>
  )
}
