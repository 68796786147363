// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import styled, { ThemeContext, type StyledComponent } from 'styled-components'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { BoxBody, BoxFooter, BoxSection, FooterBoxActions } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { EmptyField } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Hint } from 'components/common/hint'
import { InputWrapper, Input } from 'components/form'
import { LinkDoc } from 'components/styled/text'
import { schemes, colors } from 'components/styled/tokens'

import { randomSize } from 'com.batch.common/utils'

import { Title } from './settings.styles'

import { type PushConfigRecord } from 'com.batch.redux/_records'
import { openIntegrate } from 'com.batch.redux/app.action'
import { type MessageType } from 'com.batch.redux/toaster'
import { currentUserSelector } from 'com.batch.redux/user.selector'

type SettingsWebPushCommonProps = {
  config: PushConfigRecord,
  advanced: boolean,
  webpushAuth: string,
  openIntegrate: typeof openIntegrate,
  savePushConfig: (config: PushConfigRecord, file: ?File, password: ?string) => Promise<void>,
  showToast: MessageType => any,
  ...
}

export const SettingsWebPushCommon: React.ComponentType<SettingsWebPushCommonProps> = React.memo(
  ({
    config,
    advanced,
    webpushAuth,
    openIntegrate,
    savePushConfig,
    showToast,
  }: SettingsWebPushCommonProps) => {
    // ===================== REDUX STATE
    const user = useSelector(currentUserSelector)

    // ===================== PERMISSION
    const accessNotAllowed = React.useMemo(
      () => !user.permissionsForCurrentApp.has('push:config:write'),
      [user]
    )

    // ===================== GLOBAL STATE
    const editingState = useToggle(false)

    // ===================== FORM STATE
    const [subdomain, setSubdomain] = React.useState<string>(
      config.vapid ? config.vapid.subdomain : ''
    )
    const [publicKey, setPublicKey] = React.useState<string>(
      config.vapid ? config.vapid.publicKey : ''
    )
    const [privateKey, setPrivateKey] = React.useState<string>(
      config.vapid ? config.vapid.privateKey : ''
    )

    const vapidDidChanges =
      config.vapid &&
      (config.vapid.publicKey !== publicKey || config.vapid.privateKey !== privateKey)
    const domainDidChange = config.vapid && config.vapid.subdomain !== subdomain
    const vapidValid = privateKey.length > 11 && publicKey.length > 11

    const canSave = React.useMemo(
      () => (domainDidChange || vapidDidChanges) && vapidValid,
      [domainDidChange, vapidValid, vapidDidChanges]
    )
    const save = React.useCallback(() => {
      if (config.vapid) {
        savePushConfig(
          config.set(
            'vapid',
            config.vapid
              .set('publicKey', publicKey)
              .set('privateKey', privateKey)
              .set('subdomain', subdomain)
          )
        ).then(() => {
          editingState.close()
          showToast({ kind: 'success', message: 'Push API configuration saved' })
        })
      }
    }, [config, editingState, privateKey, publicKey, savePushConfig, showToast, subdomain])
    const saveNewVapid = React.useCallback(() => {
      if (vapidDidChanges) {
        confirm({
          message: (
            <article>
              Push tokens are associated with your VAPID.
              <div style={{ color: colors.textWarning, marginTop: '10px', fontWeight: 500 }}>
                If you change it you won’t be able to push previously collected push tokens.
              </div>
            </article>
          ),
          title: 'Save new VAPID ?',
          confirm: 'Yes, save it',
        }).then(
          () => save(),
          () => {}
        )
      } else {
        save()
      }
    }, [save, vapidDidChanges])

    const isAllowed = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
    const onPublicKeyChange = React.useCallback(evt => setPublicKey(evt.target.value.trim()), [])
    const onPrivateKeyChange = React.useCallback(evt => setPrivateKey(evt.target.value.trim()), [])
    const onSubDomainChange = React.useCallback(evt => setSubdomain(evt.target.value.trim()), [])
    return (
      <React.Fragment>
        <BoxBody>
          {editingState.value && (
            <BoxSection $padding style={{ backgroundColor: schemes.grayscale['01'] }}>
              If you decide to use HTTP (non secure) installation, or if you need to allow your user
              to register on multiple subdomains, they will have to register for push notification
              on this custom subdomain, and will receive push notification from this sender. It
              involves more complex interaction, and we strongly recommend you stick with the
              default SSL integration.
              <br />
              <LinkDoc
                href="https://doc.batch.com/web/overview"
                intent="action"
                style={{ margin: '12px 0 2px 0' }}
                target="_blank"
              >
                Website push configuration
              </LinkDoc>
            </BoxSection>
          )}
          <BoxSection $padding>
            <Grid template="1fr 1fr" style={{ margin: '2px 0' }} gap={22}>
              {editingState.value ? (
                <InputWrapper label="VAPID Public Key">
                  <Input
                    name="public"
                    className="fs-exclude"
                    onChange={onPublicKeyChange}
                    value={publicKey}
                  />
                </InputWrapper>
              ) : (
                <CodeWrapper
                  label="VAPID Public Key"
                  value={config.vapid ? config.vapid.publicKey : ''}
                />
              )}

              {editingState.value ? (
                <InputWrapper label="VAPID Private Key" style={{ margin: 0 }}>
                  <Input
                    name="private"
                    className="fs-exclude"
                    onChange={onPrivateKeyChange}
                    value={privateKey}
                  />
                </InputWrapper>
              ) : (
                <CodeWrapper
                  label="VAPID Private Key"
                  value={config.vapid ? config.vapid.privateKey : ''}
                  accessNotAllowed={accessNotAllowed}
                />
              )}
            </Grid>

            {editingState.value ? (
              <Grid template="1fr" style={{ margin: '22px 0 2px 0' }} gap={22}>
                <InputWrapper
                  label="Subdomain name"
                  hint="Only used for insecure/HTTP configuration."
                >
                  <Input
                    name="subdo"
                    onChange={onSubDomainChange}
                    value={subdomain}
                    suffix={{ kind: 'text', value: '.via.batch.com' }}
                  />
                </InputWrapper>
              </Grid>
            ) : (
              <Grid template="1fr 1fr" style={{ margin: '22px 0 2px 0' }} gap={22}>
                <CodeWrapper
                  label="SDK Auth Key"
                  hint="Authentification key for security purpose by the SDK"
                  value={webpushAuth}
                />

                <CodeWrapper
                  label="Subdomain name"
                  hint="Only used for insecure/HTTP configuration."
                  noCopyCode
                  value={subdomain}
                />
              </Grid>
            )}
          </BoxSection>
        </BoxBody>
        {advanced && (
          <BoxFooter isEditable={editingState.value}>
            {editingState.value && (
              <PermissionButton kind="inline" onClick={editingState.close} isAllowed={isAllowed}>
                Cancel
              </PermissionButton>
            )}

            <FooterBoxActions>
              <Button kind="inline" intent="neutral" onClick={openIntegrate}>
                Get the code
              </Button>

              {editingState.value ? (
                <PermissionButton
                  intent="action"
                  kind="primary"
                  onClick={saveNewVapid}
                  isAllowed={isAllowed}
                  disabled={!canSave}
                >
                  Save configuration
                </PermissionButton>
              ) : (
                <PermissionButton
                  intent="action"
                  kind="primary"
                  onClick={editingState.open}
                  isAllowed={isAllowed}
                >
                  Edit configuration
                </PermissionButton>
              )}
            </FooterBoxActions>
          </BoxFooter>
        )}
      </React.Fragment>
    )
  }
)

export const NoCopy: StyledComponent<*, *, HTMLElement> = styled.div`
  background: rgba(172, 177, 185, 0.16);
  color: #323639;
  padding: 1px 3px;
  width: fit-content;
  border-radius: 3px;
`

const CodeWrapper = ({
  label,
  hint,
  value,
  noCopyCode,
  accessNotAllowed = false,
}: {
  label: string,
  hint?: string,
  value: string,
  noCopyCode?: boolean,
  accessNotAllowed?: boolean,
  ...
}) => {
  // ===================== THEME
  const theme = React.useContext(ThemeContext)
  // ===================== STATE
  const [emptyFieldSize] = React.useState(`${randomSize(20, 90)}%`)
  return (
    <div>
      <Title>
        {label}
        {hint && (
          <Hint minTooltipWidth={120} maxTooltipWidth={280} placement={'top'}>
            {hint}
          </Hint>
        )}
      </Title>
      {theme.isLoading ? (
        <EmptyField _width={emptyFieldSize} />
      ) : noCopyCode ? (
        <NoCopy>{value}</NoCopy>
      ) : (
        <Code size="small" accessNotAllowed={accessNotAllowed}>
          {value}
        </Code>
      )}
    </div>
  )
}
