// @flow

import * as React from 'react'

import { BoxedCheckboxLabel } from 'components/campaign/boxed-checkbox.styles'
import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'
import { Checkbox } from 'components/form'

type BoxedCheckboxProps = {
  isSelected: boolean,
  title?: string,
  description?: string,
  isDisabled?: boolean,
  onChange?: () => void,
  tooltip?: string,
  style?: Object,
  ...
}

export const BoxedCheckbox = ({
  isSelected,
  title,
  description,
  isDisabled,
  onChange,
  tooltip,
  style,
}: BoxedCheckboxProps): React.Node => {
  return (
    <Tooltip isTooltipEmpty={!tooltip} tooltip={tooltip ?? ''} placement="right" minWidth={213}>
      <BoxedCheckboxLabel style={{ ...style }} isSelected={isSelected} isDisabled={isDisabled}>
        <Grid template="20px 1fr" gap={0}>
          <Checkbox
            name={title}
            disabled={isDisabled}
            checked={isSelected}
            onChange={onChange}
            style={{ marginRight: 9 }}
          />

          <span className="styled-boxedcheckbox-title">{title}</span>
        </Grid>
        <div className="styled-boxedcheckbox-text">{description}</div>
      </BoxedCheckboxLabel>
    </Tooltip>
  )
}
