// @flow

import { get as _get } from 'lodash-es'
import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

import { LoginSSORefresh } from 'components/authentication/login/login-sso-refresh'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'

import { Login } from './login/login'
import { Login2FA } from './login/login-2fa'
import { NewPassword } from './login/new-password'
import { PasswordRecovery } from './login/password-recovery'
import { Register2FA } from './register/register-2fa'
import { RegisterCompany } from './register/register-company'
import { RegisterInfos } from './register/register-infos'
import { RegisterNext } from './register/register-next'
import { RegisterPassword } from './register/register-password'

// get default data set in dashboard.html.twig
const email: string = _get(window, 'user.email', '')
const invitationEmail = _get(window, 'invitationEmail', '')
const isInvite: boolean =
  !!invitationEmail || _get(window, 'user.onboardingCompletedSteps', []).indexOf('company') !== -1
const enforce2Fa = _get(
  window,
  'user.hasCompanyWithTwoFactorAuthEnforcement',
  _get(window, 'invitationCompany.enforce2FA', false)
)

const Router = () => (
  <Routes>
    <Route
      path="/register/join/:token"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages.invite}>
          <RegisterInfos forcedEmail={invitationEmail} />
        </PageTracker>
      }
    />
    <Route
      path="/register/sso"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages.invite}>
          <RegisterInfos forcedEmail={email} sso />
        </PageTracker>
      }
    />
    <Route
      path="/register"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages.register}>
          <RegisterInfos forcedEmail="" />
        </PageTracker>
      }
    />
    <Route
      path="/register/password"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages.password}>
          <RegisterPassword isInvite={isInvite} email={email} />
        </PageTracker>
      }
    />
    <Route
      path="/register/congrats"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages.congrats}>
          <RegisterNext isInvite={isInvite} />
        </PageTracker>
      }
    />
    <Route
      path="/register/2fa"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages['2FA']}>
          <Register2FA enforce2Fa={enforce2Fa} isInvite={isInvite} />
        </PageTracker>
      }
    />
    <Route
      path="/register/company"
      element={
        <PageTracker category={trackingCategories.register} name={trackingPages.company}>
          <RegisterCompany isInvite={isInvite} />
        </PageTracker>
      }
    />
    <Route
      path="/login/2fa"
      element={
        <PageTracker category={trackingCategories.login} name={trackingPages.login2FA}>
          <Login2FA />
        </PageTracker>
      }
    />
    <Route
      path="/login/password"
      element={
        <PageTracker category={trackingCategories.login} name={trackingPages.login}>
          <Login isFullForm />
        </PageTracker>
      }
    />
    <Route
      path="/login"
      element={
        <PageTracker category={trackingCategories.login} name={trackingPages.login}>
          <Login />
        </PageTracker>
      }
    />
    <Route
      path="/password-reset"
      element={
        <PageTracker category={trackingCategories.login} name={trackingPages.resetRequest}>
          <PasswordRecovery />
        </PageTracker>
      }
    />
    <Route
      path="/password-reset/change"
      element={
        <PageTracker category={trackingCategories.login} name={trackingPages.reset}>
          <NewPassword
            token={window.recoveryPasswordToken}
            email={window.recoveryPasswordForEmail}
          />
        </PageTracker>
      }
    />
    {/* if the window.ssoEmail is defined its means we should refresh the session */}
    {window.ssoEmail !== undefined && (
      <Route
        path="/"
        element={
          <PageTracker category={trackingCategories.login} name={trackingPages.login}>
            <LoginSSORefresh email={window.ssoEmail} />
          </PageTracker>
        }
      />
    )}
  </Routes>
)

// $FlowExpectedError
const HotAuthRouter = Router

export const AuthRouter = (HotAuthRouter: React.AbstractComponent<{ ... }>)
