// @flow

import Immutable from 'immutable'

import {
  CampaignListStateFactory,
  type CampaignListStateRecord,
} from 'com.batch/orchestration-list/store/campaign-list.state'

import { OrchestrationListStatsStateFactory } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { extractUrlParamsAndRestoreSavedSort } from 'com.batch/orchestration-list/ui/helpers/extract-url-params'
import { type ChangeCampaignStateActions } from 'com.batch/orchestration-list/usecases/change-campaign-state'
import { type DeleteCampaignActions } from 'com.batch/orchestration-list/usecases/delete-campaign'
import { type fetchCampaignsDataActions } from 'com.batch/orchestration-list/usecases/fetch-campaigns-data'
import { type FetchCampaignsListActions } from 'com.batch/orchestration-list/usecases/fetch-campaigns-list'

type Actions =
  | ChangeCampaignStateActions
  | FetchCampaignsListActions
  | fetchCampaignsDataActions
  | DeleteCampaignActions

export const campaignListReducer = (
  state: CampaignListStateRecord = CampaignListStateFactory(
    extractUrlParamsAndRestoreSavedSort(window.location)
  ),
  action: Actions
): CampaignListStateRecord => {
  switch (action.type) {
    case 'UPDATE_FILTER_STATES_CAMPAIGNS_LIST':
      return state.set('statuses', action.payload)
    case 'UPDATE_FILTER_CHANNELS_CAMPAIGNS_LIST':
      return state.set('channels', action.payload)
    case 'UPDATE_FILTER_LABELS_CAMPAIGNS_LIST':
      return state.set('labels', action.payload)
    case 'UPDATE_FILTER_DATE_RANGE_CAMPAIGNS_LIST':
      return state.set('dateRange', action.payload)
    case 'UPDATE_SEARCH_CAMPAIGNS_LIST':
      return state.set('search', action.payload)
    case 'UPDATE_SORT_CAMPAIGNS_LIST':
      return state
        .set('sortBy', action.payload.sortBy)
        .set('sortDirection', action.payload.sortDirection)
    case 'SET_PAGE_CAMPAIGNS_LIST':
      return state.set('page', action.payload)
    case 'FETCH_CAMPAIGNS_LIST':
      return state.set('loadingState', 'LOADING')
    case 'FETCH_CAMPAIGNS_LIST_FAILURE':
      return state.set('loadingState', 'ERROR')
    case 'FETCH_CAMPAIGNS_LIST_SUCCESS':
      return state
        .set('page', action.payload.page)
        .set('loadingState', 'LOADED')
        .set('count', action.payload.count ?? 0)
        .set('countTotal', action.payload.countTotal ?? 0)
        .set(
          'entities',
          state.entities.merge(Immutable.Map(action.payload.entities.map(cpg => [cpg.token, cpg])))
        )
        .set(
          'tokensPerPage',
          action.payload.trashCache
            ? Immutable.Map([[action.payload.page, action.payload.entities.map(cpg => cpg.token)]])
            : state.tokensPerPage.set(
                action.payload.page,
                action.payload.entities.map(cpg => cpg.token)
              )
        )
    case 'FETCH_CAMPAIGNS_LIST_DATA': {
      let data = state.data
      action.payload.forEach(token => {
        data = data.set(
          token,
          data.get(token, OrchestrationListStatsStateFactory()).set('loadingState', 'LOADING')
        )
      })
      return state.set('data', data)
    }
    case 'FETCH_CAMPAIGNS_LIST_DATA_FAILURE':
    case 'FETCH_CAMPAIGNS_LIST_DATA_SUCCESS':
      return state.set('data', state.data.merge(action.payload))
    case 'CHANGE_CAMPAIGN_STATE_SUCCESS':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload.token
            ? campaign.set('state', action.payload.state).set('loadingState', 'LOADED')
            : campaign
        )
      )
    case 'CHANGE_CAMPAIGN_STATE':
    case 'DELETE_CAMPAIGN':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload ? campaign.set('loadingState', 'LOADING') : campaign
        )
      )

    case 'DELETE_CAMPAIGN_SUCCESS':
      return state
        .set(
          'tokensPerPage',
          state.tokensPerPage.map(tokens => tokens.filter(token => token !== action.payload))
        )
        .set(
          'entities',
          state.entities.filter(campaign => campaign.token !== action.payload)
        )
    case 'DELETE_CAMPAIGN_FAILURE':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload.token
            ? campaign.set('loadingState', 'LOADED')
            : campaign
        )
      )

    case 'CHANGE_CAMPAIGN_STATE_FAILURE':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload.token
            ? campaign.set('loadingState', 'LOADED')
            : campaign
        )
      )

    default:
      return state
  }
}
