// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { isEmail, textUsesTemplating } from 'com.batch.common/utils'

import {
  messageStateSelector,
  getMessageKey,
  translationsWithDefaultSelector,
  previewLanguageSelector,
} from '../../message/store/message.selector'
import { type State, type Extract } from 'com.batch.redux/_records'
import { senderIdentitiesSelector } from 'com.batch.redux/corelogic/selectors/sender-identity.selector'

import {
  EmailContentFactory,
  type EmailContentRecord,
} from 'com.batch/message/models/message.records'

type MessageIdGetterSelector<T> = State => string => T

const emailMessageSelector: Extract<Map<string, Map<string, EmailContentRecord>>> = createSelector(
  messageStateSelector,
  messageState => messageState.email
)

const getEmailContentSelector: MessageIdGetterSelector<Map<string, EmailContentRecord>> =
  createSelector(emailMessageSelector, emailState => {
    return messageId => emailState.get(messageId, Immutable.Map())
  })
const defaultEmailContent = EmailContentFactory()
export const getEmailContentForSelectedLanguageSelector: MessageIdGetterSelector<EmailContentRecord> =
  createSelector(
    getEmailContentSelector,
    previewLanguageSelector,
    (getEmailContent, lang) => messageId =>
      getEmailContent(messageId).get(lang, defaultEmailContent)
  )

export const getEmailContentLoadingStateSelector: MessageIdGetterSelector<LoadingState> =
  createSelector(getEmailContentForSelectedLanguageSelector, getData => messageId => {
    const content = getData(messageId)
    return content.loadingState
  })

/**
 * Tells if all the required fields for an email automation are filled or not.
 * @return {boolean} true if all the required fields are filled, false either
 */
export const getAreRequiredEmailFieldsFilledSelector: MessageIdGetterSelector<boolean> =
  createSelector(getEmailContentForSelectedLanguageSelector, getData => messageId => {
    const content = getData(messageId)
    return Boolean(content.fromEmail) && Boolean(content.subject) && Boolean(content.name)
  })

export const getContentHasUpdatedSenderIdentitySelector: MessageIdGetterSelector<boolean> =
  createSelector(
    getEmailContentForSelectedLanguageSelector,
    senderIdentitiesSelector,
    (getContent, senderIdentities) => messageId => {
      const content = getContent(messageId)
      const selectedSenderIdentity = senderIdentities.find(
        option => option.id === content?.senderIdentityId
      )
      if (!selectedSenderIdentity) {
        return false
      }

      return (
        content?.name !== selectedSenderIdentity.sendingName ||
        content?.fromEmail !==
          `${selectedSenderIdentity.sendingPrefix}@${selectedSenderIdentity.sendingDomain}`
      )
    }
  )

export const getContentHasDeletedSenderIdentitySelector: MessageIdGetterSelector<boolean> =
  createSelector(
    getEmailContentForSelectedLanguageSelector,
    senderIdentitiesSelector,
    (getContent, senderIdentities) => messageId => {
      const content = getContent(messageId)
      if (!content?.senderIdentityId || content?.senderIdentityId === -1) {
        return false
      }
      const selectedSenderIdentity = senderIdentities.find(
        option => option.id === content.senderIdentityId
      )
      return !selectedSenderIdentity
    }
  )

export const getSelectEmailIsUploading: MessageIdGetterSelector<boolean> = createSelector(
  getEmailContentForSelectedLanguageSelector,
  getContent => messageId => getContent(messageId)?.isEmailUploading ?? false
)
type getErrorsForMessage = (
  messageId: string,
  allowIncomplete: ?boolean
) => Map<string, Array<string>>
export const getEmailErrorsSelector: State => getErrorsForMessage = createSelector(
  translationsWithDefaultSelector,
  getEmailContentSelector,
  getContentHasUpdatedSenderIdentitySelector,
  getContentHasDeletedSenderIdentitySelector,
  (
    langsWithDefault,
    getContent,
    getContentHasUpdatedSenderIdentity,
    getContentHasDeletedSenderIdentity
  ) =>
    messageId => {
      const content = getContent(messageId)
      const contentHasUpdatedSenderIdentity = getContentHasUpdatedSenderIdentity(messageId)
      const contentHasDeletedSenderIdentity = getContentHasDeletedSenderIdentity(messageId)
      const errors: Array<[string, Array<string>]> = langsWithDefault
        .map(lang => {
          const contentForLang = content.get(lang.value, EmailContentFactory())
          let localErrors = []
          if (!contentForLang.fromEmail || !contentForLang.name || !contentForLang.senderIdentityId)
            localErrors.push('Sender email is missing')
          if (contentForLang.fromEmail && !isEmail(contentForLang.fromEmail))
            localErrors.push('Sender email is invalid')
          if (!contentForLang.subject) localErrors.push('Subject is missing')
          if (!contentForLang.html) localErrors.push('HTML is missing')
          if (contentHasUpdatedSenderIdentity)
            localErrors.push('The sender has recently been modified, please select again.')
          if (contentHasDeletedSenderIdentity)
            localErrors.push('The sender has recently been deleted, please select again.')
          if (
            contentForLang.replyTo &&
            !isEmail(contentForLang.replyTo) &&
            !textUsesTemplating(contentForLang.replyTo)
          )
            localErrors.push('Reply to is invalid')
          return [getMessageKey(lang), localErrors]
        })
        .filter(err => err[1].length > 0)
        .toArray()
      return Immutable.Map(errors)
    }
)
