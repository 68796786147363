// @flow

import { List, OrderedSet } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'

import { PreviewFitter } from 'components/campaign/preview/preview-fitter'
import { PreviewScroller } from 'components/campaign/preview/preview-scroller'
import PreviewToolbar from 'components/campaign/preview/preview-toolbar'
import { SizelessPreviewConnected } from 'components/campaign/preview/standalone-preview'
import { Button, DropdownMenu, useDropdown } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { ScrollTracker } from 'components/common/scroll'
import { Icon } from 'components/common/svg-icon'
import { Content } from 'components/styled/blocs'

import RSRContent from './review/rsr-content'
import RSRMobileLanding from './review/rsr-mobile-landing'
import RSRSettings from './review/rsr-settings'
import { RSRTargeting } from './review/rsr-targeting'
import RSRTiming from './review/rsr-timing'
import {
  FullWdithPreviewContainer,
  PreviewContent,
  PreviewContentSticky,
  ReviewContainer,
  ReviewContent,
} from './review/rsr.styles'

import {
  type AppRecord,
  type CampaignConfigRecord,
  type CampaignRecord,
  type LanguageRecord,
  type State,
} from 'com.batch.redux/_records'
import { pushShowVariant, setActiveTranslation } from 'com.batch.redux/campaign.action'
import {
  advancedPushSettingsSelector,
  campaignConfigSelector,
} from 'com.batch.redux/campaign.selector'
import {
  type AbTestedInAppRecord,
  type AbTestedPushRecord,
  type PushSettingsRecord,
} from 'com.batch.redux/content.records'
import { type CampaignDataRecord } from 'com.batch.redux/dataCampaign.records'
import {
  inAppContentForActiveLanguageSelector,
  pushContentABForActiveLanguageSelector,
} from 'com.batch.redux/targeting.selector.composed'
import { changePreferedInstall } from 'com.batch.redux/template'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'
import { InAppVariantsThemeSelector } from 'com.batch.redux/theme.selector'
// ====================== ACTIONS

export type advancedPushSettings = {
  deeplink: ?string,
  payload: ?string,
  priority: 'HIGH' | 'NORMAL',
  ttl: ?number,
  collapse: ?string,
  isPush: boolean,
  ...
}

const ANCHOR_OFFSET = -180

type OwnProps = {
  activeLanguageId: string,
  app: AppRecord,
  campaign: CampaignRecord,
  changePreferedInstall: typeof changePreferedInstall,
  data: ?CampaignDataRecord,
  fullscreenPreview: boolean,
  headerStuck: boolean,
  installId: ?string,
  installIds: OrderedSet<string>,
  onScrollEnter: (step: string) => void,
  pickedLanguages: List<LanguageRecord>,
  previewMode: 'normal' | 'expanded' | 'landing',
  previewWeb: string,
  templateLoading: boolean,
  toggleFullScreen: () => void,
  updateLanguage: typeof setActiveTranslation,
  updatePreviewMode: (previewMode: 'normal' | 'expanded' | 'landing') => void,
  updatePreviewWeb: (previewWeb: string) => void,
}
type StateProps = {
  advancedPushSettings: advancedPushSettings,
  campaignConfig: CampaignConfigRecord,
  abTestedPushContent: AbTestedPushRecord,
  abTestedInAppContent: AbTestedInAppRecord,
  pushSettings: PushSettingsRecord,
  campaignVariantsThemes: AbTestedThemeRecord,
}
type DispatchProps = {
  updateAbTesting: typeof pushShowVariant,
}
type ReviewScreenReviewProps = { ...OwnProps, ...StateProps, ...DispatchProps }
const ReviewScreenReview = ({
  campaign,
  app,
  data,
  headerStuck,
  onScrollEnter,
  pickedLanguages,
  advancedPushSettings,
  activeLanguageId,
  campaignConfig,
  previewMode,
  campaignVariantsThemes,
  previewWeb,
  fullscreenPreview,
  installIds,
  installId,
  toggleFullScreen,
  abTestedInAppContent,
  pushSettings,
  updatePreviewWeb,
  templateLoading,
  abTestedPushContent,
  updatePreviewMode,
  updateLanguage,
  updateAbTesting,
}: ReviewScreenReviewProps): React.Node => {
  const isPush = React.useMemo(() => campaign.type === 'push', [campaign.type])
  const languagePicker = React.useMemo(
    () => (
      <LanguagePicker
        campaign={campaign}
        app={app}
        pickedLanguages={pickedLanguages}
        selectedLanguage={activeLanguageId}
        onSelectedLanguage={updateLanguage}
      />
    ),
    [activeLanguageId, app, campaign, pickedLanguages, updateLanguage]
  )
  const createOnScrollEnter = React.useCallback(
    (step: string) => () => onScrollEnter(step),
    [onScrollEnter]
  )
  const previewRenderer = React.useCallback(
    ({ width, height }) => (
      <FullWdithPreviewContainer height={height}>
        <div />
        <div style={{ flex: `0 0 ${width}px`, height: `${height}px`, overflow: 'hidden' }}>
          <SizelessPreviewConnected
            width={width}
            height={height}
            variant={campaignConfig.abtesting}
            previewMode={previewMode}
            previewWeb={previewWeb}
          />
        </div>
        <div
          style={{
            flex: '0 0 60px',
            height: `${height}px`,
            paddingLeft: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PreviewToolbar
            vertical
            isFullScreen
            hasLanding={campaign.hasLanding}
            isPush={isPush}
            toggleFullScreen={toggleFullScreen}
            previewMode={previewMode}
            isWeb={app.platform === 'webpush'}
            previewWeb={previewWeb}
            installLoading={templateLoading}
            updatePreviewMode={updatePreviewMode}
            updatePreviewWeb={updatePreviewWeb}
            changePreferedInstall={changePreferedInstall}
            installIds={installIds}
            installId={installId}
          />
        </div>
        <div />
      </FullWdithPreviewContainer>
    ),
    [
      app.platform,
      campaign.hasLanding,
      campaignConfig.abtesting,
      installId,
      installIds,
      isPush,
      previewMode,
      previewWeb,
      templateLoading,
      toggleFullScreen,
      updatePreviewMode,
      updatePreviewWeb,
    ]
  )
  const fitterRenderer = React.useCallback(
    ({ width, height }) => (
      <PreviewScroller height={height} headerStuck={headerStuck}>
        <SizelessPreviewConnected
          width={width}
          height={height}
          variant={campaignConfig.abtesting}
          previewMode={previewMode}
          previewWeb={previewWeb}
        />
      </PreviewScroller>
    ),
    [campaignConfig.abtesting, headerStuck, previewMode, previewWeb]
  )
  React.useEffect(() => window.scrollTo(0, 0), [])
  return (
    <Content style={{ paddingBottom: 0 }}>
      <ReviewContainer>
        <ReviewContent>
          <ScrollTracker
            id="targeting"
            anchorOffset={ANCHOR_OFFSET}
            onScrollEnter={createOnScrollEnter('targeting')}
          >
            <RSRTargeting campaign={campaign} app={app} />
          </ScrollTracker>

          <ScrollTracker
            id={isPush ? 'timing' : 'trigger'}
            anchorOffset={ANCHOR_OFFSET}
            onScrollEnter={createOnScrollEnter(isPush ? 'timing' : 'trigger')}
          >
            <RSRTiming campaign={campaign} app={app} lastPushDate={data ? data.lastSend : null} />
          </ScrollTracker>

          {isPush && (
            <ScrollTracker
              id="content"
              anchorOffset={ANCHOR_OFFSET}
              onScrollEnter={createOnScrollEnter('content')}
            >
              <RSRContent
                campaign={campaign}
                languagePicker={languagePicker}
                abTesting={campaignConfig.abtesting}
                onAbTesting={updateAbTesting}
                deeplink={advancedPushSettings.deeplink}
                platform={app.platform}
                abTestedPushContent={abTestedPushContent}
                pushSettings={pushSettings}
              />
            </ScrollTracker>
          )}
          {campaign.hasLanding && (
            <ScrollTracker
              id={isPush ? 'mobile-landing' : 'content'}
              anchorOffset={ANCHOR_OFFSET}
              onScrollEnter={createOnScrollEnter(isPush ? 'mobile-landing' : 'content')}
            >
              <RSRMobileLanding
                app={app}
                campaign={campaign}
                abTestedInAppContent={abTestedInAppContent}
                languagePicker={languagePicker}
                variantsThemes={campaignVariantsThemes}
              />
            </ScrollTracker>
          )}

          <ScrollTracker
            id="settings"
            anchorOffset={ANCHOR_OFFSET}
            onScrollEnter={createOnScrollEnter('settings')}
          >
            <RSRSettings advancedPushSettings={advancedPushSettings} />
          </ScrollTracker>
        </ReviewContent>
        <PreviewContent webpush>
          <PreviewContentSticky landing={previewMode === 'landing'}>
            <PreviewFitter app={app} previewWeb={previewWeb} fit="width" render={fitterRenderer} />
          </PreviewContentSticky>
        </PreviewContent>
      </ReviewContainer>
      <Popin
        showBackDropClose
        opened={fullscreenPreview}
        close={toggleFullScreen}
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          maxHeight: '100vh',
          width: '100%',
        }}
      >
        <PreviewFitter previewWeb={previewWeb} app={app} fit="height" render={previewRenderer} />
      </Popin>
    </Content>
  )
}

const mapStateToProps = (state: State) => ({
  advancedPushSettings: advancedPushSettingsSelector(state),
  campaignConfig: campaignConfigSelector(state),
  abTestedPushContent: pushContentABForActiveLanguageSelector(state),
  abTestedInAppContent: inAppContentForActiveLanguageSelector(state),
  pushSettings: state.content.pushSettings,
  campaignVariantsThemes: InAppVariantsThemeSelector(state),
})

const mapDispatchToProps = {
  updateAbTesting: pushShowVariant,
}

export default (connect<ReviewScreenReviewProps, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(ReviewScreenReview): React.AbstractComponent<OwnProps>)

type LanguagePickerProps = {
  campaign: CampaignRecord,
  app: AppRecord,
  pickedLanguages: List<LanguageRecord>,
  selectedLanguage: string,
  onSelectedLanguage: (language: string) => any,
  ...
}

export const LanguagePicker = ({
  campaign,
  pickedLanguages,
  selectedLanguage,
  onSelectedLanguage,
}: LanguagePickerProps): React.Node => {
  const { dropdownProps, triggerProps, closeDropdown } = useDropdown({})
  const onLanguagePickedCreator = React.useCallback(
    (value: string) => () => {
      closeDropdown()
      onSelectedLanguage(value)
    },
    [closeDropdown, onSelectedLanguage]
  )
  if (pickedLanguages.size > 1) {
    const pick =
      pickedLanguages.find(({ value }) => value === selectedLanguage) || pickedLanguages.first()
    return (
      <React.Fragment>
        <Button {...triggerProps} addOn="suffix">
          {pick ? pick.label : ''}
          <Icon icon="select" />
        </Button>
        <DropdownMenu {...dropdownProps}>
          {pickedLanguages
            .filter(({ value }) => campaign.translations.includes(value))
            .map(({ label, value }) => (
              <Button
                key={value}
                onClick={onLanguagePickedCreator(value)}
                isActive={value === selectedLanguage}
              >
                {label}
              </Button>
            ))
            .toArray()}
        </DropdownMenu>
      </React.Fragment>
    )
  }
  return <span>Default</span>
}
