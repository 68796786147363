// @flow
import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'

import { type LanguageRecord, type RegionRecord } from 'com.batch.redux/_records'

import {
  EmptyLang,
  ProfileLangContainer,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  language: LanguageRecord,
  region: RegionRecord,
  isLoading: boolean,
  ...
}

export const ProfileLang = ({ language, region, isLoading }: Props): React.Node => {
  const noLangAndLocationAvailable = React.useMemo(
    () => !language.label && !region.label,
    [language, region]
  )

  const langAndRegion = React.useMemo(() => {
    if (language.label && region.label) {
      return `${region.label}, speaks ${language.label}`
    }
    if (language.label) {
      return `Speaks ${language.label}`
    }
    return region.label
  }, [language.label, region.label])

  return (
    <ProfileLangContainer>
      {isLoading && (
        <React.Fragment>
          <EmptyField _width={16} />
          <EmptyField _width={50} />
          <EmptyField _width={50} />
        </React.Fragment>
      )}
      {!isLoading && !noLangAndLocationAvailable && (
        <React.Fragment>
          {region.value && <img src={`/medias/img/flags/${region.value.toLowerCase()}.png`} />}
          {langAndRegion}
        </React.Fragment>
      )}
      {!isLoading && noLangAndLocationAvailable && (
        <EmptyLang>No location & language available</EmptyLang>
      )}
    </ProfileLangContainer>
  )
}
