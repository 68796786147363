// @flow

import * as React from 'react'

import { TableTemplateCell } from 'components/common/table'

export const EmptyTeamRow: React.AbstractComponent<{ ... }> = React.memo<{ ... }>(
  (): React.Node => {
    return (
      <React.Fragment>
        <TableTemplateCell template="1fr" />
        <TableTemplateCell template="1fr" />
      </React.Fragment>
    )
  }
)
