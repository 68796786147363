// @flow

import { updateEmailContent, type UpdateEmailContentAction } from './update-email-content'

import { type Dispatch, type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'

import {
  getStripoOptimizedTemplate,
  getStripoTemplate,
} from 'com.batch/email/infra/services/stripo'

export type UpdateEmailInfoAction = {
  type: 'UPDATE_EMAIL_INFO',
  payload: {
    messageId: string,
    lang: string,
    field: 'fromEmail' | 'name' | 'replyTo' | 'senderIdentityId',
    value: string,
    ...
  },
  ...
}
export const updateEmailInfo = ({
  messageId,
  lang,
  field,
  value,
}: {
  messageId: string,
  lang: string,
  field: 'fromEmail' | 'name' | 'replyTo' | 'senderIdentityId',
  value: string | ?number,
}): DispatchBoundFn<UpdateEmailInfoAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_EMAIL_INFO',
      payload: { messageId, lang, field, value },
    })
  }
}

export type UpdateEmailSenderAction = ReduxAction<
  'UPDATE_EMAIL_SENDER',
  {
    senderIdentityId: number,
    fromEmail: string,
    name: string,
    messageId: string,
    ...
  },
>
export const updateEmailSender = (payload: {
  senderIdentityId: number,
  fromEmail: string,
  name: string,
  messageId: string,
  ...
}): UpdateEmailSenderAction => {
  return {
    type: 'UPDATE_EMAIL_SENDER',
    payload,
  }
}

export type SaveDragDropOptimizedTemplateAction = {
  type: 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT',
  payload: {
    lang: string,
    messageId: string,
    ...
  },
  ...
}
export type SaveDragDropOptimizedTemplateSuccessAction = {
  type: 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_SUCCESS',
  payload: {
    lang: string,
    messageId: string,
    ...
  },
  ...
}
export type SaveDragDropOptimizedTemplateFailureAction = {
  type: 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_FAILURE',
  payload: {
    error: any,
    lang: string,
    messageId: string,
    ...
  },
  ...
}

export type saveDragDropOptimizedTemplateActions =
  | SaveDragDropOptimizedTemplateAction
  | SaveDragDropOptimizedTemplateSuccessAction
  | SaveDragDropOptimizedTemplateFailureAction

/**
 * Sauvegarde uniquement le html optimisé dans le store. Pas la partie editable.
 */
export const saveDragDropOptimizedTemplate = ({
  lang,
  messageId,
}: {
  lang: string,
  messageId: string,
}): ((dispatch: Dispatch) => Promise<UpdateEmailContentAction>) => {
  return async dispatch => {
    try {
      // Récupération du template optimisé
      const { optimizedHtml } = await promiseActionCreator<{
        optimizedHtml: string,
        lang: string,
        messageId: string,
        ...
      }>({
        dispatch,
        promise: getStripoOptimizedTemplate()
          // promiseActionCreator forward le result en tant que payload mais on a tjs besoin de la langue, on la forward.
          .then(optimizedHtml => ({ optimizedHtml, lang, messageId }))
          // Besoin de la langue pour mettre à jour le state concerné même en cas d'erreur
          .catch(error => {
            throw { error, lang, messageId }
          }),
        actionName: 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT',
        payload: { lang, messageId },
      })

      return dispatch(
        updateEmailContent({
          messageId,
          lang,
          field: 'html',
          value: optimizedHtml,
          isInstant: true,
        })
      )
    } catch (error) {
      // Erreur gérée via _FAILURE dans messageReducer
      return Promise.reject(error)
    }
  }
}

export type SaveDragDropHtmlAction = {
  type: 'UPDATE_DRAG_DROP_HTML_CONTENT',
  payload: {
    messageId: string,
    lang: string,
    ...
  },
  ...
}

export type SaveDragDropHtmlSuccessAction = {
  type: 'UPDATE_DRAG_DROP_HTML_CONTENT_SUCCESS',
  payload: {
    template: { html: string, css: string, ... },
    optimizedHtml: string,
    messageId: string,
    lang: string,
    ...
  },
  ...
}
export type SaveDragDropHtmlFailureAction = {
  type: 'UPDATE_DRAG_DROP_HTML_CONTENT_FAILURE',
  payload: {
    error: any,
    messageId: string,
    lang: string,
    ...
  },
  ...
}
export type saveDragDropHtmlActions =
  | SaveDragDropHtmlAction
  | SaveDragDropHtmlSuccessAction
  | SaveDragDropHtmlFailureAction

export const saveDragDropHtml = ({
  lang,
  messageId,
  templateId,
}: {
  lang: string,
  messageId: string,
  templateId: string,
}): ((dispatch: Dispatch) => Promise<UpdateEmailContentAction>) => {
  return async dispatch => {
    try {
      // Récupération du template optimisé et du template editable auprès de StripoAPI
      const { template, optimizedHtml } = await promiseActionCreator<{
        template: { html: string, css: string, ... },
        optimizedHtml: string,
        lang: string,
        ...
      }>({
        dispatch,
        promise: Promise.all([getStripoTemplate(), getStripoOptimizedTemplate()])
          // promiseActionCreator forward le result en tant que payload mais on a tjs besoin de la langue, on la forward.
          .then(([template, optimizedHtml]) => {
            return { template, optimizedHtml, lang, messageId }
          })
          // Besoin de la langue pour mettre à jour le state concerné même en cas d'erreur
          .catch(error => {
            throw { error, lang, messageId }
          }),
        actionName: 'UPDATE_DRAG_DROP_HTML_CONTENT',
        payload: { lang, messageId },
      })

      const { html, css } = template

      // Dispatch la màj du template
      return dispatch(
        updateEmailContent({
          lang,
          messageId,
          field: 'html',
          value: optimizedHtml,
          htmlEditorConfig: { type: 'STRIPO', html, css, templateId },
          isInstant: true,
        })
      )
    } catch (error) {
      // Erreur gérée via _FAILURE dans messageReducer
      return Promise.reject(error)
    }
  }
}

export type EmptyAllEmailSendersAction = ReduxAction<'EMPTY_ALL_EMAIL_SENDERS', null>
export const emptyAllEmailSenders = (): EmptyAllEmailSendersAction => {
  return {
    type: 'EMPTY_ALL_EMAIL_SENDERS',
    payload: null,
  }
}
