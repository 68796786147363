// @flow

import { List } from 'immutable'

import {
  type BounceCategoryRecord,
  BounceCategoryFactory,
  BounceClassificationFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  type BaseOrchestrationStats,
  type BounceDetail,
  type BounceCategory,
} from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseBounceCategories = ({
  base,
  bounceDetails,
}: {
  base: BaseOrchestrationStats,
  bounceDetails: ?BounceDetail,
}): List<BounceCategoryRecord> => {
  if (bounceDetails) {
    for (const catName of ['HARD', 'SOFT', 'BLOCK']) {
      if (!bounceDetails.categories.find(category => category.name === catName))
        bounceDetails.categories.push({ name: catName, count: 0, classifications: [] })
    }

    return new List().push(
      ...bounceDetails.categories
        // Les reasons ADMIN ne sont pas affichées
        .filter((cat: BounceCategory) => cat.name !== 'ADMIN')
        .map(cat =>
          BounceCategoryFactory({
            // $FlowExpectedError[incompatible-call] typeguards
            name: cat.name,
            count: cat.count,
            rate: base.bounce ? cat.count / base.bounce : 0,
            classifications: new List().push(
              ...cat.classifications.map(classification =>
                BounceClassificationFactory({ ...classification })
              )
            ),
          })
        )
    )
  } else return new List()
}
