// @flow

import * as React from 'react'

type Props = { ... }

type State = {
  width: number,
  height: number,
  ...
}

function WidthWatcher(WrappedComponent: React.AbstractComponent<*>): React.AbstractComponent<*> {
  return class WidthWatcherHOC extends React.PureComponent<Props, State> {
    state: State
    req: number
    rqf: number | null
    _elem: HTMLElement | null
    _parent: HTMLElement | null

    constructor(props: Props) {
      super(props)
      this.state = { width: 0, height: 0 }
    }

    componentDidMount() {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize)
      if (this.req) {
        window.cancelAnimationFrame(this.req)
      }
    }

    setRef = (elem: HTMLElement | null) => {
      // $FlowFixMe pfff
      this._parent = elem?.parentNode ?? null
      this._elem = elem ? elem : null
    }
    onResize = () => {
      if (this.rqf) return
      this.rqf = window.requestAnimationFrame(() => {
        this.rqf = null
        this.updateDimensions()
      })
    }

    updateDimensions() {
      if (this._elem) {
        this._elem.style.width = '0'
        this._elem.style.overflow = 'hidden'
        const st = window.getComputedStyle(this._parent, null)
        const pl = parseInt(st.getPropertyValue('padding-left'))
        const pr = parseInt(st.getPropertyValue('padding-right'))
        const pt = parseInt(st.getPropertyValue('padding-top'))
        const pb = parseInt(st.getPropertyValue('padding-bottom'))
        const width = this._parent ? this._parent.clientWidth - pl - pr : 50
        const height = this._parent ? this._parent.clientHeight - pt - pb : 50
        this.setState({ width, height })
        if (this._elem) {
          // wtf (flow)
          this._elem.style.width = `${width}px`
          this._elem.style.overflow = 'visible'
        }
      }
    }

    render(): React.Node {
      return (
        <div ref={this.setRef}>
          <WrappedComponent {...this.props} width={this.state.width} />
        </div>
      )
    }
  }
}

export default WidthWatcher
