// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeProvider, type StyledComponent } from 'styled-components'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { BoxBody, BoxFooter, BoxSection, FooterBoxActions } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
  TableCellActions,
} from 'components/common/table'
import { Empty, EmptyTitle, EmptyText } from 'components/styled/empty'
import { Ellipsis } from 'components/styled/text'

import { Title } from './settings.styles'
import { SafariWebsiteModal } from './webpush/safari-website-modal'
import { SafariWebsiteNameModal } from './webpush/safari-website-name-modal'
import { WebpushSafariExpired } from './webpush-safari-expired'

import {
  SafariWebsitesFactory,
  type PushConfigRecord,
  type AppRecord,
  type SafariWebsitesRecord,
} from 'com.batch.redux/_records'
import {
  addSafariWebsite,
  saveSafariWebsiteName,
  updateSafariWebsite,
  deleteSafariWebsite,
  openIntegrate,
} from 'com.batch.redux/app.action'
import { type MessageType } from 'com.batch.redux/toaster'

type SettingsWebPushSafariProps = {
  config: PushConfigRecord,
  openIntegrate: typeof openIntegrate,
  showToast: MessageType => any,
  app: AppRecord,
  ...
}

export const SettingsWebPushSafari: React.ComponentType<SettingsWebPushSafariProps> = React.memo(
  ({ config, openIntegrate, app }: SettingsWebPushSafariProps) => {
    const dispatch = useDispatch()

    // ================================= STATE GLOBAL
    const websiteNameModalState = useToggle(false)
    const websiteModalState = useToggle(false)

    const [websiteSelected, setWebsiteSelected] =
      React.useState<SafariWebsitesRecord>(SafariWebsitesFactory())
    const [fieldOrder, setFieldOrder] = React.useState<'domain' | 'certificateExpirationDate'>(
      'domain'
    )
    const [sortOrder, setSortOrder] = React.useState<'asc' | 'dsc'>('asc')

    const matchingFilters =
      sortOrder === 'asc'
        ? config.safariWebsites.sortBy(e => e[fieldOrder])
        : config.safariWebsites.sortBy(e => e[fieldOrder]).reverse()

    const isConfigurationOk = React.useMemo(
      () => config.safariWebsites.size !== 0 && config.safariWebsiteName !== '',
      [config.safariWebsites, config.safariWebsiteName]
    )

    const createOnSort = React.useCallback(
      (field: 'domain' | 'certificateExpirationDate') => () => {
        if (field === fieldOrder) {
          setSortOrder(sortOrder === 'asc' ? 'dsc' : 'asc')
        } else {
          setFieldOrder(field)
          setSortOrder('asc')
        }
      },
      [fieldOrder, sortOrder]
    )

    const saveWebsiteName = React.useCallback(
      (newWebsiteName: string) => {
        if (isConfigurationOk) {
          confirm({
            message: (
              <article>Your change will only impact users who subscribe after your change.</article>
            ),
            title: 'Edit website name ?',
            confirm: 'Yes, edit it',
            sensitive: true,
          }).then(() => {
            dispatch(saveSafariWebsiteName({ app, safariWebsiteName: newWebsiteName }))
            websiteNameModalState.close()
          })
        } else {
          if (config.safariWebsiteName !== newWebsiteName) {
            dispatch(saveSafariWebsiteName({ app, safariWebsiteName: newWebsiteName }))
          }
          websiteNameModalState.close()
          websiteModalState.open()
        }
      },
      [
        isConfigurationOk,
        dispatch,
        app,
        websiteNameModalState,
        config.safariWebsiteName,
        websiteModalState,
      ]
    )

    const saveWebsite = React.useCallback(
      (domain: string, file: ?File, password: string) => {
        if (isConfigurationOk) {
          if (websiteSelected.id) {
            dispatch(
              updateSafariWebsite({ app, domain, file, websiteId: websiteSelected.id, password })
            )
            setWebsiteSelected(SafariWebsitesFactory())
          } else {
            if (file) {
              dispatch(addSafariWebsite({ app, domain, file, password }))
            }
          }
        } else {
          if (file) {
            dispatch(
              addSafariWebsite({
                app,
                domain,
                file,
                password,
              })
            )
          }
        }

        websiteModalState.close()
      },
      [app, dispatch, isConfigurationOk, websiteModalState, websiteSelected.id]
    )

    const createOnDeleteWebsite = React.useCallback(
      (website: SafariWebsitesRecord) => () => {
        confirm({
          message: <article>This will stop opt-in collect on this domain.</article>,
          title: 'Delete allowed domain?',
          confirm: 'Yes, delete it',
          sensitive: true,
        }).then(() => {
          dispatch(deleteSafariWebsite({ app, websiteId: website.id }))
        })
      },
      [app, dispatch]
    )
    const createOnEdit = React.useCallback(
      (website: SafariWebsitesRecord) => () => {
        setWebsiteSelected(website)
        websiteModalState.open()
      },
      [websiteModalState]
    )

    const onCloseModal = React.useCallback(() => {
      websiteModalState.close()
      setWebsiteSelected(SafariWebsitesFactory())
    }, [websiteModalState])

    const isAllowed = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
    const onSaveModal = React.useCallback(
      (domain: string, file: ?File, password: string) => saveWebsite(domain, file, password),
      [saveWebsite]
    )
    const onNameModalSave = React.useCallback(
      (newWebsiteName: string) => saveWebsiteName(newWebsiteName),
      [saveWebsiteName]
    )
    return (
      <React.Fragment>
        <BoxBody>
          {isConfigurationOk ? (
            <React.Fragment>
              <BoxSection $padding>
                <Title>Website name</Title>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LabelWebsiteName>{config.safariWebsiteName}</LabelWebsiteName>
                  <PermissionButton
                    type="button"
                    kind="inline"
                    style={{ height: 28 }}
                    onClick={websiteNameModalState.open}
                    isAllowed={isAllowed}
                  >
                    Edit
                  </PermissionButton>
                </div>
              </BoxSection>
              <BoxSection>
                {matchingFilters && matchingFilters.size > 0 && (
                  <Table template="1fr 1fr 1fr 104px">
                    <TableHeader>
                      <TableCellOrder
                        sort={fieldOrder === 'domain' ? sortOrder : undefined}
                        onClick={createOnSort('domain')}
                      >
                        Allowed domain
                      </TableCellOrder>
                      <TableCellHeader>Safari certificate</TableCellHeader>
                      <TableCellOrder
                        sort={fieldOrder === 'certificateExpirationDate' ? sortOrder : undefined}
                        onClick={createOnSort('certificateExpirationDate')}
                      >
                        Expires in
                      </TableCellOrder>
                      <div />
                    </TableHeader>
                    <ThemeProvider theme={{ size: 'small' }}>
                      {matchingFilters.size > 0 &&
                        matchingFilters.map((website, index) => (
                          <TableRow
                            isLastLine={config.safariWebsites.size === index + 1}
                            key={website.id}
                          >
                            <TableCell noOverflow>
                              <Ellipsis title={website.domain}>{website.domain}</Ellipsis>
                            </TableCell>
                            <TableCell noOverflow>
                              <Ellipsis title={website.certificateName}>
                                {website.certificateName}
                              </Ellipsis>
                            </TableCell>
                            <TableCell>
                              <WebpushSafariExpired
                                expire={website.certificateExpirationDate}
                                displayIcon
                              />
                            </TableCell>
                            <TableCellActions>
                              <PermissionButton
                                type="button"
                                onClick={createOnEdit(website)}
                                isAllowed={isAllowed}
                              >
                                <Icon icon="edit" size={13} />
                              </PermissionButton>
                              <PermissionButton
                                type="button"
                                kind="inline"
                                onClick={createOnDeleteWebsite(website)}
                                isAllowed={isAllowed}
                              >
                                <Icon icon="delete" />
                              </PermissionButton>
                            </TableCellActions>
                          </TableRow>
                        ))}
                    </ThemeProvider>
                  </Table>
                )}
              </BoxSection>
            </React.Fragment>
          ) : (
            <SafariIsntConfigured />
          )}

          <BoxFooter>
            <FooterBoxActions>
              {isConfigurationOk ? (
                <React.Fragment>
                  <Button kind="inline" intent="neutral" onClick={openIntegrate}>
                    Get the code
                  </Button>

                  <PermissionButton
                    kind="inline"
                    addOn="prefix"
                    isAllowed={isAllowed}
                    onClick={websiteModalState.open}
                  >
                    <Icon icon="add" />
                    Add allowed domain
                  </PermissionButton>
                </React.Fragment>
              ) : (
                <PermissionButton
                  intent="action"
                  kind="primary"
                  isAllowed={isAllowed}
                  onClick={websiteNameModalState.open}
                >
                  Configure Safari
                </PermissionButton>
              )}
            </FooterBoxActions>
          </BoxFooter>
        </BoxBody>

        {websiteNameModalState.value && (
          <SafariWebsiteNameModal
            opened
            close={websiteNameModalState.close}
            name={config.safariWebsiteName}
            save={onNameModalSave}
            isEditing={Boolean(websiteSelected.id)}
          />
        )}

        {websiteModalState.value && (
          <SafariWebsiteModal
            appId={app.id}
            opened
            close={onCloseModal}
            save={onSaveModal}
            certificateName={websiteSelected.certificateName}
            certificateExpirationDate={websiteSelected.certificateExpirationDate}
            uploadedAt={websiteSelected.uploadedAt}
            domain={websiteSelected.domain}
            isEditing={Boolean(websiteSelected.id)}
            certificatesName={config.safariWebsites.map(safari => safari.certificateName)}
          />
        )}
      </React.Fragment>
    )
  }
)

const SafariIsntConfigured = () => (
  <Empty style={{ position: 'relative', height: 172 }}>
    <EmptyTitle>Safari support isn't set up.</EmptyTitle>
    <EmptyText>Follow configuration steps to send push to Safari users</EmptyText>
  </Empty>
)

const LabelWebsiteName: StyledComponent<*, *, HTMLElement> = styled.div`
  background: rgba(172, 177, 185, 0.16);
  color: #323639;
  padding: 1px 3px;
  width: fit-content;
  border-radius: 3px;
  margin-right: 14px;
`
