// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { HEXtoRGB } from 'components/styled/tokens'
import { textLight, strokeAction } from 'components/styled/tokens/colors'
import { blue } from 'components/styled/tokens/schemes'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'

type EmailDropzoneContainerProps = { mode?: DropzoneState, ... }
export const EmailDropzoneContainer: StyledComponent<EmailDropzoneContainerProps, { ... }, *> =
  styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${strokeAction};
    border-radius: 12px;
    background-color: rgba(${HEXtoRGB(blue['50']).toString()}, 0.02);

    & h2 {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
    }

    & p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: ${textLight};
    }

    ${(props: EmailDropzoneContainerProps) =>
      (props.mode === 'active' || props.mode === 'loading') &&
      css`
        border: 2px dashed ${strokeAction};
        background-color: rgba(${HEXtoRGB(blue['50']).toString()}, 0.04);
      `}
  `
