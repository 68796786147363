// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states'

type PlotPathProps = { isLoading?: boolean, ... }
export const PlotPath: StyledComponent<PlotPathProps, *, *> = styled.path`
  ${(props: PlotPathProps) =>
    props.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`

export const TimelineItemSkeleton: StyledComponent<*, *, *> = styled.rect`
  animation-name: ${LoadingSvgAnim};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`
