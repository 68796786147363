// @flow
import { type availableIcons } from 'components/common/svg-icon'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

type OptinsStatus = {
  title: string,
  icon: availableIcons,
  message: string,
  ...
}

export const getOptinStatusMessages = (
  subscriptionStatus: SubscriptionStatus,
  size: 'short' | 'expanded',
  notifType: string[]
): OptinsStatus => {
  const title =
    subscriptionStatus === 'SUBSCRIBED'
      ? size === 'expanded'
        ? 'Push opt-in'
        : 'Opt-in'
      : size === 'expanded'
        ? 'Push opt-out'
        : 'Opt-out'
  return {
    title,
    icon: subscriptionStatus === 'SUBSCRIBED' ? 'check' : 'close',
    message: subscriptionStatus === 'SUBSCRIBED' ? notifType.join(', ') : 'No push notifications',
  }
}
