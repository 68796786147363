// @flow

import { hsl } from 'd3-color'
import { List } from 'immutable'
import * as React from 'react'

import { type DataSetRecord } from '../chart-helper'

type BarChartDefsProps = {
  sets: List<DataSetRecord>,
  ...
}

const lightenColor = (color: string) => {
  const { h, s, l } = hsl(color)
  return hsl(h, s * 0.9, l * 1.2)
}
const BarChartDefsBase = ({ sets }: BarChartDefsProps): React.Node => {
  return (
    <defs>
      {sets.map((set, k) => (
        <React.Fragment key={k}>
          <pattern
            id={`diagonalHatch-${set.color}`}
            width="10"
            height="10"
            patternTransform="rotate(45 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="10" height="10" fill={lightenColor(set.color)} />
            <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: set.color, strokeWidth: 10 }} />
          </pattern>
        </React.Fragment>
      ))}
      <linearGradient id="barchart-currentLegendGradient">
        <stop offset="0%" stopColor="rgba(252, 252, 253, 0)" />
        <stop offset="30%" stopColor="rgba(252, 252, 253, 1)" />
        <stop offset="70%" stopColor="rgba(252, 252, 253, 1)" />
        <stop offset="100%" stopColor="rgba(252, 252, 253, 0)" />
      </linearGradient>
    </defs>
  )
}

export const BarChartDefs: React.AbstractComponent<BarChartDefsProps> =
  React.memo<BarChartDefsProps>(BarChartDefsBase)
