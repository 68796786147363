// @flow
import Immutable from 'immutable'

import { generateTypedId } from 'com.batch.common/typed-id'

import { type FlagIncompleteAction } from './flag-incomplete-message-id'
import { type InsertNodeAfterAction } from './insert-node-after'
import { updateNode } from './update-node'

import { getNodeId } from '../models/id-generator'
import { type BranchId } from '../models/journey.records'
import { isEmailContentComplete } from '../models/journey.selectors'
import { type langId } from 'com.batch/message/store/message.state'
import { type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'

import { updateEmailInfo } from 'com.batch/email/usecases/update-content'
import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import {
  type EmailContentRecord,
  type PushContentRecord,
  type SmsContentRecord,
} from 'com.batch/message/models/message.records'
import { updatePushContentRecord } from 'com.batch/push/usecases/update-push-content'
import { updateSmsContent } from 'com.batch/sms/usecases/update-sms-content'

export type PasteMessageNodeAction = ReduxAction<'PASTE_MESSAGE_NODE', null>
export const pasteMessageNode = ({
  branchIds,
}: {
  branchIds: Array<BranchId>,
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const newNodeId = getNodeId('MESSAGE')
    const messageReference = generateTypedId('message')
    const state = getState()
    const copiedNode = getState().orchestration.triggerNodes.get(state.orchestration.copiedNodeId)
    if (!copiedNode || copiedNode.type !== 'MESSAGE') {
      return
    }
    dispatch(({ type: 'PASTE_MESSAGE_NODE', payload: null }: PasteMessageNodeAction))
    dispatch(
      ({
        type: 'INSERT_NODE_AFTER',
        payload: {
          branchIds,
          id: newNodeId,
          nodeType: 'MESSAGE',
          channel: copiedNode.channel,
          messageReference,
        },
      }: InsertNodeAfterAction)
    )
    const nodeToUpdate = getState().orchestration.triggerNodes.get(newNodeId)
    if (nodeToUpdate && nodeToUpdate.type === 'MESSAGE') {
      const messageNode = nodeToUpdate
      const placeholder =
        copiedNode.channel === 'push'
          ? 'Push Message'
          : copiedNode.channel === 'email'
            ? 'Email Message'
            : 'SMS Message'
      const label = `Copy - ${copiedNode.label ? copiedNode.label : placeholder}`
      dispatch(
        updateNode(
          messageNode.set('hasQuietHours', Boolean(copiedNode.hasQuietHours)).set('label', label)
        )
      )
      let allComplete = true
      if (copiedNode.channel === 'sms') {
        const smsContent = state.message.sms.get(
          copiedNode.messageReference,
          Immutable.Map<langId, SmsContentRecord>()
        )
        smsContent.forEach((content, lang) => {
          dispatch(
            updateSmsContent({
              lang,
              messageId: messageReference,
              field: 'smsMessage',
              value: content.smsMessage,
              isInstant: true,
            })
          )
          if (!content.smsMessage) allComplete = false
        })
      }
      if (copiedNode.channel === 'push') {
        const pushContent = state.message.push.get(
          copiedNode.messageReference,
          Immutable.Map<langId, PushContentRecord>()
        )
        pushContent.forEach((content, lang) => {
          if (!content.content.pushTitle || !content.content.pushBody) allComplete = false
          dispatch(
            updatePushContentRecord({
              lang,
              messageId: messageReference,
              record: content,
            })
          )
        })
      }
      if (copiedNode.channel === 'email') {
        const emailContent = state.message.email.get(
          copiedNode.messageReference,
          Immutable.Map<langId, EmailContentRecord>()
        )
        if (!isEmailContentComplete(emailContent)) allComplete = false
        emailContent.forEach((content, lang) => {
          dispatch(
            updateEmailInfo({
              lang,
              messageId: messageReference,
              field: 'fromEmail',
              value: content.fromEmail,
            })
          )
          dispatch(
            updateEmailInfo({
              lang,
              messageId: messageReference,
              field: 'name',
              value: content.name,
            })
          )
          dispatch(
            updateEmailInfo({
              lang,
              messageId: messageReference,
              field: 'senderIdentityId',
              value: content.senderIdentityId,
            })
          )
          dispatch(
            updateEmailContent({
              lang,
              messageId: messageReference,
              field: 'subject',
              value: content.subject,
              isInstant: true,
            })
          )
          dispatch(
            updateEmailContent({
              lang,
              messageId: messageReference,
              field: 'html',
              value: content.html,
              htmlEditorConfig: content.htmlEditorConfig ? { ...content.htmlEditorConfig } : null,
              isInstant: true,
            })
          )
        })
      }
      if (!allComplete) {
        dispatch(
          ({
            type: 'FLAG_INCOMPLETE',
            payload: {
              messageId: messageReference,
              incomplete: true,
            },
          }: FlagIncompleteAction)
        )
      }
    }
  }
}
