// @flow

import { type OrderedSet } from 'immutable'
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Hint } from 'components/common/hint'
import { InputWrapper, Checkbox } from 'components/form'
import { colors } from 'components/styled/tokens'

import { permissionNameFormatter, ROLES } from './utils'

import { type groupOnlyPermissionType } from 'com.batch.redux/user.records'

type PermissionsProps = {
  hasEditorialDashboard: boolean,
  errorAbove: boolean,
  permissions: OrderedSet<groupOnlyPermissionType>,
  setPermissions: (p: OrderedSet<groupOnlyPermissionType>) => void,
  ...
}

export const Permissions = ({
  hasEditorialDashboard,
  permissions,
  setPermissions,
}: PermissionsProps): React.Node => {
  return (
    <InputWrapper label="Has permissions to ...">
      {ROLES.filter(role => hasEditorialDashboard || role.id !== 'group:editorial').map(role => (
        <div key={role.id} style={{ marginTop: role.id === 'group:administrate' ? 6 : 12 }}>
          <Checkbox
            onChange={() => {
              setPermissions(
                permissions.has(role.id) ? permissions.remove(role.id) : permissions.add(role.id)
              )
            }}
            label={
              <React.Fragment>
                <CheckboxRole>{permissionNameFormatter(role.id)}</CheckboxRole>
                <CheckboxText>
                  {role.desc}
                  {role.hint && <Hint>{role.hint}</Hint>}
                </CheckboxText>
              </React.Fragment>
            }
            disabled={role.autoGrantedWith.reduce(
              (acc, curr) => permissions.has(curr) || acc,
              false
            )}
            checked={
              role.autoGrantedWith.reduce((acc, curr) => permissions.has(curr) || acc, false) ||
              permissions.has(role.id)
            }
          />
        </div>
      ))}
    </InputWrapper>
  )
}

export const CheckboxRole: StyledComponent<*, *, HTMLSpanElement> = styled.span`
  font-weight: 500;
  user-select: none;
`

export const CheckboxText: StyledComponent<*, *, HTMLParagraphElement> = styled.p`
  margin: 3px 0 0 0;
  color: ${colors.textLight};
  user-select: none;
`
