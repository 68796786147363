// @flow
import * as React from 'react'
import { Panel, MiniMap, useReactFlow } from 'reactflow'

import { useBlurOnClickCallback } from 'components/_hooks/use-blur-on-click'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

import { ControlsContainer, ControlsContainerButtons } from './controls.styles'

export const Controls = (): React.Node => {
  const { zoomIn, zoomOut, fitView } = useReactFlow()
  const onZoomClick = useBlurOnClickCallback(zoomIn, [zoomIn])
  const onZoomOutClick = useBlurOnClickCallback(zoomOut, [zoomOut])
  const onFitViewClick = useBlurOnClickCallback(fitView, [fitView])
  const onKeyPress = React.useCallback(
    (event: SyntheticKeyboardEvent<HTMLDivElement>) => {
      if (event.metaKey || event.ctrlKey) {
        if (event.key === '+' || event.key === '=') {
          zoomIn()
          event.preventDefault()
        } else if (event.key === '-') {
          zoomOut()
          event.preventDefault()
        }
      }
    },
    [zoomIn, zoomOut]
  )
  React.useEffect(() => {
    window.addEventListener('keydown', onKeyPress)
    return () => {
      window.removeEventListener('keydown', onKeyPress)
    }
  }, [onKeyPress])

  return (
    <Panel position="bottom-left">
      <ControlsContainer>
        <Grid template="auto auto" alignItems="stretch" gap={0}>
          <MiniMap
            pannable
            zoomable
            ariaLabel="Minimap"
            nodeBorderRadius={20}
            nodeColor={colors.opacifyActive}
            maskStrokeWidth="0"
          />
          <ControlsContainerButtons>
            <Tooltip delay={1200} placement="right" tooltip="Zoom in">
              <Button onClick={onZoomClick}>
                <Icon icon="add" kind="secondary" />
              </Button>
            </Tooltip>
            <Tooltip delay={1200} placement="right" tooltip="Zoom out">
              <Button onClick={onZoomOutClick}>
                <Icon icon="tiret" kind="secondary" />
              </Button>
            </Tooltip>
            <Tooltip delay={1200} placement="right" tooltip="Fit view">
              <Button onClick={onFitViewClick}>
                <Icon icon="fit" kind="secondary" />
              </Button>
            </Tooltip>
          </ControlsContainerButtons>
        </Grid>
      </ControlsContainer>
    </Panel>
  )
}
