// @flow
import { type List } from 'immutable'

import { parseStatsByProvider } from '../infra/parses/parse-stats-by-provider'
import { type EmailProviderRecord } from '../models/orchestration-stats-by-provider.record'
import { orchestrationAnalyticsMapSelector } from '../store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchOrchestrationStatsByProviderAction = ReduxAction<
  'FETCH_ORCHESTRATION_DATA_BY_PROVIDER',
  string,
>

export type FetchOrchestrationStatsByProviderSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_DATA_BY_PROVIDER_SUCCESS',
  { data: ?List<EmailProviderRecord>, token: string },
>

export type FetchOrchestrationStatsByProviderFailureAction = ReduxAction<
  'FETCH_ORCHESTRATION_DATA_BY_PROVIDER_FAILURE',
  { token: string, error: Error },
>

export type FetchOrchestrationStatsByProviderActions =
  | FetchOrchestrationStatsByProviderAction
  | FetchOrchestrationStatsByProviderSuccessAction
  | FetchOrchestrationStatsByProviderFailureAction

export const fetchStatsByProvider =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.providersLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity

    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchOrchestrationStatsByProviderAction = {
        type: 'FETCH_ORCHESTRATION_DATA_BY_PROVIDER',
        payload: token,
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchDynamicStats({
          project,
          token,
          groupDimensionNames: ['mailbox_provider'],
          includeFilterDimensions: [
            {
              dimensionName: 'channel',
              dimensionValues: ['email'],
            },
          ],
          parser: parseStatsByProvider,
        })
        const fetchSuccessAction: FetchOrchestrationStatsByProviderSuccessAction = {
          type: 'FETCH_ORCHESTRATION_DATA_BY_PROVIDER_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchOrchestrationStatsByProviderFailureAction = {
          type: 'FETCH_ORCHESTRATION_DATA_BY_PROVIDER_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
