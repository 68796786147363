// @flow
import { type Map } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'

import { type fetchingState } from 'com.batch.redux/_records'

import { CHANNEL_THEMES } from 'com.batch/profilebase/constants/channel-themes'
import { type AnalyticsChannel } from 'com.batch/profilebase/infra/types/channel-type'
import { type PushPlatformFilterType } from 'com.batch/profilebase/infra/types/push-platform-filter.type'
import { type ReachabilityTimeserieRecord } from 'com.batch/profilebase/models/reachability-timeserie.records'
import { type SubDistributionRecord } from 'com.batch/profilebase/models/sub-distribution.records'
import { MarketingSubscriptionBlock } from 'com.batch/profilebase/ui/components/marketing-subscription-block/marketing-subscription-block'
import { SubscriptionGrowthBlock } from 'com.batch/profilebase/ui/components/subscription-growth-block/subscription-growth-block'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'

type Props = {
  channel: AnalyticsChannel,
  subsDistribution: SubDistributionRecord,
  subsByDayLoadingState: fetchingState,
  subsByDay: Map<string, ReachabilityTimeserieRecord>,
  cumulSubsLastMonth: number,
  filter?: React.Node,
  platformFilter?: PushPlatformFilterType,
  link?: React.Node,
}

export const ChannelOverview = ({
  channel,
  subsDistribution,
  subsByDay,
  subsByDayLoadingState,
  cumulSubsLastMonth,
  filter,
  platformFilter,
  link,
}: Props): React.Node => {
  const channelTheme = React.useMemo(() => CHANNEL_THEMES[channel], [channel])
  const { isLoading } = useTheme()
  const isEmpty = React.useMemo(() => {
    return (
      !isLoading &&
      subsDistribution.unknown.value === 0 &&
      subsDistribution.subscribed.value === 0 &&
      subsDistribution.unsubscribed.value === 0
    )
  }, [
    isLoading,
    subsDistribution.unknown.value,
    subsDistribution.subscribed.value,
    subsDistribution.unsubscribed.value,
  ])

  if (isEmpty) return null
  return (
    <React.Fragment>
      <ChannelSectionTitle>
        <div>
          <Title>
            <Icon icon={channelTheme.icon} />
            {channelTheme.title}
          </Title>
          {filter && filter}
        </div>
        {link && link}
      </ChannelSectionTitle>
      <Grid gap={16} template="484px minmax(400px, 1fr)" alignItems="stretch">
        <MarketingSubscriptionBlock
          theme={channelTheme}
          distribution={subsDistribution}
          channel={channel}
          platformFilter={platformFilter}
        />
        <SubscriptionGrowthBlock
          theme={channelTheme}
          loadingState={subsByDayLoadingState}
          subscriptionsByDay={subsByDay}
          cumulLastMonth={cumulSubsLastMonth}
          channel={channel}
          platformFilter={platformFilter}
        />
      </Grid>
    </React.Fragment>
  )
}
