// @flow

import * as React from 'react'

import { Input } from 'components/form'

import { type InputProps } from './helper'
import { useNumberInputValidation } from './use-validation'

export const InputInteger = ({ condition, updateCondition, isInvalid }: InputProps): React.Node => {
  const { localValue, onChange, onBlur, isLocalInvalid } = useNumberInputValidation(
    condition,
    updateCondition,
    false
  )

  return (
    <Input
      invalid={isInvalid || isLocalInvalid}
      type="number"
      onBlur={onBlur}
      value={localValue}
      onChange={onChange}
    />
  )
}
