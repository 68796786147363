/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Loader from 'components/common/loader-legacy'
import { Checkbox } from 'components/form'

import { PermissionButton } from '../common/button/permission-button'
import {
  CappingCategoryFactory,
  type CappingCategoryRecord,
  type State,
  type fetchingState,
} from 'com.batch.redux/_records'
import { labelsSelector } from 'com.batch.redux/app'
import { saveCappingCategory, updatePressureCategory } from 'com.batch.redux/app.action'
import { actions } from 'com.batch.redux/campaign'
import { toggleLabel } from 'com.batch.redux/campaign.action'

import { STATUS } from 'constants/common'

const LabelHead = styled.h5`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: #9fa5b5;
  margin: 32px 32px 5px 32px;
`

const LabelCount = styled.span`
  padding: 0 0 0 10px;
  color: #9fa5b5;
  font-size: 12px;
`
const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 200px;
  max-width: 640px;
  @media (min-width: 750px) {
    min-width: 600px;
  }
  label {
    display: flex;
    margin-bottom: 6px;
  }
  padding: 10px 32px 25px 32px;
  ${(props: { sep?: boolean, ... }) =>
    props.sep &&
    css`
      border-bottom: 1px solid #f2f3f6;
    `};
`

const Footer = styled.div`
  padding: 10px 32px 25px 32px;
`

type OwnProps = {
  close: () => void,
  appId: number,
  schedulingType: schedulingType,
  pickedLabels: Set<CappingCategoryRecord>,
}
type StateProps = {
  labels: List<CappingCategoryRecord>,
  loadingState: fetchingState,
}
type DispatchProps = {
  toggleLabel: typeof toggleLabel,
  saveCappingCategory: typeof saveCappingCategory,
  fetchCampaignsLabelsCount: typeof actions.fetchCampaignsLabelsCount,
  updatePressureCategory: typeof updatePressureCategory,
}
type LabelPickerProps = { ...OwnProps, ...StateProps, ...DispatchProps }

type LabelPickerState = {
  toToggle: Set<CappingCategoryRecord>,
  toRemove: Set<CappingCategoryRecord>,
  ...
}

class LabelPickerRaw extends React.PureComponent<LabelPickerProps, LabelPickerState> {
  constructor(props: LabelPickerProps) {
    super(props)
    props.fetchCampaignsLabelsCount(props.appId)
    this.state = {
      toToggle: Immutable.Set(),
      toRemove: Immutable.Set(),
    }
  }
  toggle(label: CappingCategoryRecord) {
    if (this.count() >= 3 && !this.has(label)) {
      return
    }
    if (this.hasLabelInSet(label, this.props.pickedLabels)) {
      this.setState({
        toRemove: this.hasLabelInSet(label, this.state.toRemove)
          ? this.state.toRemove.remove(label)
          : this.state.toRemove.add(label),
      })
    } else {
      this.setState({
        toToggle: this.hasLabelInSet(label, this.state.toToggle)
          ? this.state.toToggle.remove(label)
          : this.state.toToggle.add(label),
      })
    }
  }
  hasLabelInSet(label: CappingCategoryRecord, set: Set<CappingCategoryRecord>): boolean {
    return set.map(label => label.id).contains(label.id)
  }
  dismiss = () => {
    this.setState({ toToggle: Immutable.Set(), toRemove: Immutable.Set() })
    this.props.close()
  }
  confirm = () => {
    this.state.toToggle.forEach(label => {
      this.props.toggleLabel(label)
    })
    this.state.toRemove.forEach(label => {
      this.props.toggleLabel(label)
    })
    this.setState({ toToggle: Immutable.Set(), toRemove: Immutable.Set() })
    this.props.close()
  }
  count(): number {
    return this.props.pickedLabels.size - this.state.toRemove.size + this.state.toToggle.size
  }

  has = (label: CappingCategoryRecord): boolean => {
    const isInProps = this.hasLabelInSet(label, this.props.pickedLabels)
    const isInToRemove = this.hasLabelInSet(label, this.state.toRemove)
    const isInToToggle = this.hasLabelInSet(label, this.state.toToggle)
    return (isInProps && !isInToRemove) || isInToToggle
  }

  render(): React.Node {
    const { labels, schedulingType } = this.props
    const cat = this.props.labels.find(cat => cat.id === 'new', null, CappingCategoryFactory())
    return (
      <Box>
        <BoxHeader>
          <HeaderBoxTitle
            title={`Associate labels to ${
              schedulingType === 'campaigns' ? 'campaign' : 'automation'
            }`}
          />
        </BoxHeader>
        <BoxBody>
          <Loader
            overlay
            loading={
              this.props.loadingState === STATUS.LOADING || this.props.loadingState === STATUS.INIT
            }
          >
            <LabelHead>Select existing labels</LabelHead>
            <Scrollable sep>
              {labels.size === 0 && (
                <p className="text-muted">
                  No label found for this app. Use the form below to create one.
                </p>
              )}
              {labels
                .filter(l => l.id !== 'new')
                .map(label => (
                  <Checkbox
                    key={label.id}
                    size={16}
                    disabled={this.count() >= 3 && !this.has(label)}
                    onChange={() => this.toggle(label)}
                    checked={this.has(label)}
                    label={
                      <React.Fragment>
                        {label.name}
                        <LabelCount>
                          {!label.count ? 'No campaign' : `${label.count} campaigns`}
                        </LabelCount>
                      </React.Fragment>
                    }
                  />
                ))}
            </Scrollable>
            <LabelHead>Create a new label</LabelHead>
            <Footer>
              <FlexLine>
                <FlexLineItem grow={1}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={cat.name}
                    onChange={evt => {
                      this.props.updatePressureCategory({
                        categorieRecord: cat,
                        updated: cat.set('name', evt.target.value.toString()),
                      })
                    }}
                  />
                </FlexLineItem>
                <FlexLineItem grow={1}>
                  <PermissionButton
                    kind="primary"
                    intent="action"
                    isAllowed={this.props.labels.size < 500}
                    notAllowedMessage="You can't create more than 500 labels"
                    disabled={!cat || !cat.name || !!(cat.code && cat.code.length < 4)}
                    onClick={() => {
                      this.props.saveCappingCategory(cat, 'campaign')
                    }}
                  >
                    Create
                  </PermissionButton>
                </FlexLineItem>
              </FlexLine>
            </Footer>
          </Loader>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={this.dismiss}>
            Cancel
          </Button>
          <FooterBoxActions>
            <Button kind="primary" intent="action" onClick={this.confirm}>
              Associate labels ({this.count()})
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    )
  }
}

const mapStateToProps = (state: State) => {
  return {
    labels: labelsSelector(state),
    loadingState: state.app.current.loadingState,
  }
}
export const LabelPicker: React.AbstractComponent<OwnProps> = connect<
  LabelPickerProps,
  OwnProps,
  _,
  _,
  _,
  _,
>(mapStateToProps, {
  saveCappingCategory,
  toggleLabel,
  fetchCampaignsLabelsCount: actions.fetchCampaignsLabelsCount,
  updatePressureCategory,
})(LabelPickerRaw)
