// @flow

import * as React from 'react'

import { CtaBuilder } from 'components/campaign/cta-builder'
import { ValidatedInput, WrappedValidatedInput } from 'components/campaign/form/validated-input'
import { Hint } from 'components/common/hint'
import { MediaUrl, TabButton, TabButtonItem } from 'components/form'

import validators from 'com.batch.common/form.validators'
import { ucFirst } from 'com.batch.common/utils'

import { type AppRecord, type SdkSupportsRecord, type Variant } from 'com.batch.redux/_records'
import { uploadError } from 'com.batch.redux/campaign.action'
import { updateInAppCta, type updateInAppContentActionCreator } from 'com.batch.redux/content'
import { type InAppContentRecord, SdkActionFactory } from 'com.batch.redux/content.records'
import { SelectThemeField } from 'com.batch.redux/theme'
import { type FieldRecord } from 'com.batch.redux/theme.records'

type ContentInAppFieldProps = {
  app: AppRecord,
  content: InAppContentRecord,
  field: FieldRecord,
  lang: string,
  focusThemeField: typeof SelectThemeField,
  sdkSupports: SdkSupportsRecord,
  updateInAppContent: updateInAppContentActionCreator,
  updateInAppCta: typeof updateInAppCta,
  uploadError: typeof uploadError,
  forcedFocus: boolean,
  variant: Variant,
  ...
}

const mt = { marginTop: '10px' }

export const ContentInAppField = ({
  app,
  content,
  field,
  lang,
  focusThemeField,
  updateInAppContent,
  updateInAppCta,
  forcedFocus,
  variant,
}: ContentInAppFieldProps): React.Node => {
  const id = field.id
  const ctaLabelField = id === 'cta1' ? 'mainButtonLabel' : 'secondaryButtonLabel'
  const ctaActionField =
    id === 'cta1'
      ? 'mainButtonAction'
      : id === 'global'
        ? 'globalTapAction'
        : 'secondaryButtonAction'
  const ctaAction = content.get(ctaActionField, SdkActionFactory())
  const hasTemplate = app.features.has('macro') && id !== 'trackingId'

  const updateLabel = React.useMemo(() => {
    return ctaActionField === 'globalTapAction'
      ? undefined
      : (label: string) => updateInAppContent({ lang, field: ctaLabelField, value: label, variant })
  }, [ctaActionField, ctaLabelField, lang, updateInAppContent, variant])

  const updateAction = React.useMemo(
    () => action => updateInAppCta({ lang, cta: ctaActionField, value: action, variant }),
    [ctaActionField, lang, updateInAppCta, variant]
  )

  const createUpdateInAppContent = React.useCallback(
    fieldToUpdate => (value: string) =>
      updateInAppContent({
        lang,
        field: fieldToUpdate,
        value,
        variant,
      }),
    [lang, updateInAppContent, variant]
  )

  switch (id) {
    // ======================================= IMAGE ===
    case 'webviewUrl':
      return (
        <div className="form-group">
          <div style={mt}>
            <WrappedValidatedInput
              value={content.webviewUrl}
              valid={Boolean(
                validators.required(content.webviewUrl) && validators.url(content.webviewUrl)
              )}
              onChange={createUpdateInAppContent('webviewUrl')}
              touched={false}
              label="Webview URL"
              placeholder="URL — https://..."
              name="webviewUrl"
              onFocus={() => {
                if (!forcedFocus) focusThemeField({ field: 'image', variant })
              }}
              type="template"
              rows={1}
              forcedFocus={!!forcedFocus}
            />
            {content.webviewUrl.indexOf('http://') !== -1 && (
              <div style={{ color: '#ED8545', marginTop: -20 }}>
                Please be aware that an unsecure URL may create problems{' '}
              </div>
            )}
          </div>

          <div className="form-group" style={{ marginTop: 32 }}>
            <label>
              Link destination
              <Hint>
                If you use &laquo;_blank&raquo; target to to open Web Page links, you can choose to
                open the provided URLs in an in-app browser within your mobile app or in the default
                mobile app browser.
              </Hint>
            </label>
            <TabButton grow style={{ marginTop: 5 }}>
              <TabButtonItem
                onClick={() =>
                  updateInAppContent({
                    lang,
                    field: 'linkOpenTarget',
                    value: 'in-app',
                    variant,
                  })
                }
                isActive={content.linkOpenTarget === 'in-app'}
              >
                In-app browser
              </TabButtonItem>
              <TabButtonItem
                onClick={() =>
                  updateInAppContent({
                    lang,
                    field: 'linkOpenTarget',
                    value: 'browser',
                    variant,
                  })
                }
                isActive={content.linkOpenTarget === 'browser'}
              >
                External browser
              </TabButtonItem>
            </TabButton>
          </div>
        </div>
      )
    case 'image':
      return (
        <div className="form-group">
          <MediaUrl
            label="Image"
            platforms={[app.platform]}
            value={{ mediaKind: 'image', mediaUrl: content.imageUrl ?? '' }}
            onChange={media => {
              updateInAppContent({
                lang,
                field: 'imageUrl',
                value: media.mediaUrl,
                variant,
              })
            }}
            minImageWidth={600}
            imageRecommandation={{
              message: '',
              docLinkText: 'Learn more',
              docLinkUrl:
                'https://help.batch.com/en/articles/3087406-what-is-the-required-image-size-for-in-app-messages',
            }}
            allowedMedia={['image']}
          />

          <div style={mt}>
            <ValidatedInput
              value={content.imageAlt}
              onChange={createUpdateInAppContent('imageAlt')}
              valid
              touched
              name={id}
              type={'text'}
              rows={id === 'text' ? 6 : 1}
              placeholder="Description (accessibility)"
            />
          </div>
        </div>
      )
    case 'title':
    case 'header':
    case 'text':
    case 'trackingId':
      return (
        <WrappedValidatedInput
          value={content.get(id)}
          onChange={createUpdateInAppContent(id)}
          touched={false}
          valid={Boolean(validators.required(content.get(id)))}
          onFocus={() => {
            if (!forcedFocus) focusThemeField({ field: field.id, variant })
          }}
          forcedFocus={!!forcedFocus}
          name={`${id}-${variant}`}
          type={hasTemplate ? 'template' : 'text'}
          rows={id === 'text' ? 4 : 1}
          label={ucFirst(field.label)}
          hint={
            id === 'trackingId'
              ? 'Only for apps with an event dispatcher solution setup. Tracking ID provides an additional tracking dimension at a campaign-level in your analytics solution.'
              : ''
          }
          placeholder={field.label}
        />
      )
    case 'cta1':
    case 'cta2':
    case 'global':
      return (
        <CtaBuilder
          id={id}
          action={ctaAction}
          platform={app.platform}
          hasTemplate={false}
          label={content.get(ctaLabelField)}
          placeholder={field.label}
          forcedFocus={!!forcedFocus}
          updateLabel={updateLabel}
          updateAction={updateAction}
        />
      )
    default:
      return field.id
  }
}
