// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import {
  quietHoursSelector,
  journeyTreeSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'

type MessageQuietHoursProps = {
  channel: ChannelUntilCleanup,
  stepId?: ?string,
  ...
}

const padHourMin = (hm: number): string => hm.toString().padStart(2, '0')

export const MessageQuietHours = ({ channel, stepId }: MessageQuietHoursProps): React.Node => {
  const dispatch = useDispatch()
  const { hasQuietHours, quietHours } = useSelector(quietHoursSelector)
  const { nodesMap } = useSelector(journeyTreeSelector)
  const node = React.useMemo(() => (stepId ? nodesMap.get(stepId) : null), [nodesMap, stepId])

  const onChangeQuietHoursEnabledForNode = React.useCallback(
    (quietHoursForNode: boolean) => {
      if (node?.type === 'MESSAGE') {
        dispatch(updateNode(node.set('hasQuietHours', quietHoursForNode)))
      }
    },
    [dispatch, node]
  )

  const onUpdateNodeHasQuietHours = React.useCallback(
    (bool: boolean) => {
      if (onChangeQuietHoursEnabledForNode) onChangeQuietHoursEnabledForNode(bool)
    },
    [onChangeQuietHoursEnabledForNode]
  )

  return hasQuietHours ? (
    <div
      style={
        channel === 'email' ? { borderLeft: `1px solid ${colors.stroke}`, paddingLeft: 28 } : {}
      }
    >
      <Switch
        isActive={!!(node?.type === 'MESSAGE' && node.hasQuietHours)}
        onChange={onUpdateNodeHasQuietHours}
      >
        Quiet hours <Icon style={{ marginLeft: 8 }} icon="quiet-hours" />
      </Switch>
      <p style={{ marginTop: 8 }}>
        {`If disabled, users may receive messages between ${padHourMin(
          quietHours.startHour
        )}:${padHourMin(quietHours.startMin)}-${padHourMin(quietHours.endHour)}:${padHourMin(
          quietHours.endMin
        )}.`}
      </p>
      {channel === 'push' && <HorizontalDivider style={{ margin: '20px 0' }} />}
    </div>
  ) : null
}
