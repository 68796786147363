// @flow

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type ProjectRecord } from 'com.batch.redux/project.records'

export type Segment = {
  id: ?number,
  name: string,
  query: string,
}

type fetchSegmentsResponse = { results: Array<Segment> }
export const fetchSegments = (project: ProjectRecord): Promise<Array<Segment>> => {
  const url = generateUrl('api_project_saved_segments_list', { projectKey: project.projectKey })
  return request.get<fetchSegmentsResponse>(url).then(({ results }) => results)
}

export const saveSegment = (project: ProjectRecord, segment: Segment): Promise<Segment> => {
  const url = segment.id
    ? generateUrl('api_project_saved_segments_update', {
        projectKey: project.projectKey,
        id: segment.id,
      })
    : generateUrl('api_project_saved_segments_create', { projectKey: project.projectKey })

  return request.post<{ segment: Segment, ... }>(url, segment).then(({ segment }) => segment)
}

export const deleteSegment = (project: ProjectRecord, segment: Segment): Promise<Segment> => {
  const url = generateUrl('api_project_saved_segments_delete', {
    projectKey: project.projectKey,
    id: segment.id,
  })

  return request.delete<{ segment: Segment, ... }>(url).then(({ segment }) => segment)
}
