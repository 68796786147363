// @flow
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'
import {
  ChoiceBox,
  EmailUploadingContainer,
} from 'com.batch/email/ui/components/email-choice/email-choice.styles'

type EmailUploaderProps = {
  getInputProps: <T>(props: T) => T,
  mode: DropzoneState,
  open: () => void,
}
export const EmailUploader = ({ getInputProps, mode, open }: EmailUploaderProps): React.Node => {
  const onSubmit = React.useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      open()
    },
    [open]
  )

  return (
    <ChoiceBox style={{ paddingBottom: 20 }} mode={mode} onClick={open} onSubmit={onSubmit}>
      <button type="submit" />
      <input {...getInputProps()} />
      {mode === 'field' && (
        <React.Fragment>
          <h2>Upload your (almost) ready-to-use HTML template</h2>
          <p>Click to upload or just drag & drop your .zip file with your HTML and image folder</p>
          <img src="/medias/img/code-editor.webp" height={132} />
        </React.Fragment>
      )}
      {mode === 'loading' && (
        <EmailUploadingContainer>
          <h2>Uploading file</h2>
          <Icon icon="spinner" />
        </EmailUploadingContainer>
      )}
    </ChoiceBox>
  )
}
