/* eslint-disable react/jsx-no-bind */
// @flow

import * as React from 'react'
import request from 'superagent-interface-promise'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { BoxBody, BoxFooter, FooterBoxActions } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import { Input, InputWrapper } from 'components/form'
import { BoxInfo } from 'components/styled/blocs'
import { LinkDoc, Link } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import {
  HMSConfigFactory,
  type PushConfigRecord,
  type HMSConfigRecord,
} from 'com.batch.redux/_records'
import { showToast, type MessageType } from 'com.batch.redux/toaster'

type SettingsAndroidHMSProps = {
  config: PushConfigRecord,
  appId: number,
  savePushConfig: (config: PushConfigRecord, file: ?File, password: ?string) => Promise<void>,
  showToast: MessageType => typeof showToast,
  ...
}

const gridTemplate = 'minMax(20px,1fr) 34px minMax(20px,1fr)'

export const SettingsAndroidHMS = ({
  config,
  savePushConfig,
  appId,
}: SettingsAndroidHMSProps): React.Node => {
  // ====================== LOCAL STATE
  const previousConfig = config.hms ? config.hms : HMSConfigFactory()
  const [loading, setLoading] = React.useState(false)
  const [hms, setHMS] = React.useState<HMSConfigRecord>(previousConfig)
  const [invalid, setInvalid] = React.useState(false)
  const [manual, setManual] = React.useState(false)
  // ====================== CALLBACKS
  const clearErrors = React.useCallback(() => setInvalid(false), [setInvalid])
  const save = React.useCallback(() => {
    setLoading(true)
    request
      .post(generateUrl('api_app_hms_validate', { appId }), {
        appId: hms.appId,
        appKey: hms.appKey,
      })
      .then(
        () => {
          setInvalid(false)
          savePushConfig(config.set('hms', hms)).then(() => {
            setManual(false)
            setLoading(false)
            showToast({ kind: 'success', message: 'Push configuration saved' })
          })
        },
        () => {
          setLoading(false)
          setInvalid(true)
        }
      )
  }, [appId, config, hms, savePushConfig])

  const confirmManual = React.useCallback(() => {
    confirm({
      width: 490,
      message: (
        <article>
          <p>
            Push tokens are associated to your Client ID / Client SECRET pair. If you change these,
            you won’t be able to push previously collected push tokens.
          </p>
        </article>
      ),
      title: 'Confirm push configuration change',
    }).then(
      () => setManual(true),
      () => {}
    )
  }, [setManual])
  const isAllowedToChangeHMSConfig = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
  return (
    <React.Fragment>
      <BoxBody>
        <BoxInfo style={{ padding: '16px 24px' }}>
          <p>
            The Batch dashboard needs your Huawei Client ID and your Huawei Client SECRET. Both are
            available in{' '}
            <Link
              intent="action"
              target="_blank"
              href="https://developer.huawei.com/consumer/en/console"
            >
              your Huawei developer account
            </Link>
            .
          </p>
        </BoxInfo>
        {!config.hms && !manual ? (
          <div style={{ textAlign: 'center', padding: 40 }}>
            <h3 style={{ marginBottom: 10, color: '#242E3B', fontSize: 14 }}>
              No Huawei (HMS) config yet
            </h3>
            <p style={{ marginBottom: 20 }}>
              Set up your HMS configuration to start pushing to Huawei devices
            </p>
            <PermissionButton
              intent="action"
              kind="primary"
              onClick={() => setManual(true)}
              isAllowed={isAllowedToChangeHMSConfig}
            >
              Add configuration
            </PermissionButton>
          </div>
        ) : (
          <Grid
            style={{
              padding: '0 24px 24px 24px',
              borderBottom: config.hms ? '1px solid #E9E9E9' : 'none',
            }}
            template={gridTemplate}
            gap={12}
            alignItems="start"
          >
            <InputWrapper
              label="Client ID"
              feedback={invalid ? 'Client ID / key does not match' : ''}
            >
              {manual ? (
                <Input
                  value={hms.appId}
                  allowedCharsRegex={/[0-9]/}
                  invalid={invalid}
                  className="fs-exclude"
                  onChange={evt => setHMS(hms.set('appId', evt.target.value))}
                  placeholder="Your Huawei Client ID"
                />
              ) : (
                <Code className="fs-exclude">{hms.appId}</Code>
              )}
            </InputWrapper>
            <div />
            <InputWrapper label="Client SECRET" style={{ marginTop: 0 }}>
              {manual ? (
                <Input
                  invalid={invalid}
                  allowedCharsRegex={/[0-9a-z]/}
                  value={hms.appKey}
                  className="fs-exclude"
                  onChange={evt => setHMS(hms.set('appKey', evt.target.value))}
                  placeholder="Your Huawei Client SECRET"
                />
              ) : (
                <Code className="fs-exclude">{hms.appKey}</Code>
              )}
            </InputWrapper>
          </Grid>
        )}
        {!!config.hms && (
          <React.Fragment>
            <Grid style={{ padding: '24px 24px' }} template={gridTemplate} gap={12}>
              <InputWrapper label="Callback address">
                <Code>https://huawei-receipt.batch.com/{previousConfig.callbackUrlToken}</Code>
              </InputWrapper>
              <div />
              <InputWrapper label="HTTPS certificate" style={{ marginTop: 0 }}>
                <Code
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}
                >{`-----BEGIN CERTIFICATE-----
MIIF/TCCA+WgAwIBAgIUZ/lU7Knmog9twj8qqOeratBEyuEwDQYJKoZIhvcNAQEL
BQAwgY0xCzAJBgNVBAYTAkZSMQ4wDAYDVQQHDAVQYXJpczESMBAGA1UECgwJQmF0
Y2guY29tMRcwFQYDVQQLDA5odWF3ZWktcmVjZWlwdDEhMB8GA1UEAwwYaHVhd2Vp
LXJlY2VpcHQuYmF0Y2guY29tMR4wHAYJKoZIhvcNAQkBFg9oZWxsb0BiYXRjaC5j
b20wHhcNMjAwODIwMTMwNzQ4WhcNMzAwODE4MTMwNzQ4WjCBjTELMAkGA1UEBhMC
RlIxDjAMBgNVBAcMBVBhcmlzMRIwEAYDVQQKDAlCYXRjaC5jb20xFzAVBgNVBAsM
Dmh1YXdlaS1yZWNlaXB0MSEwHwYDVQQDDBhodWF3ZWktcmVjZWlwdC5iYXRjaC5j
b20xHjAcBgkqhkiG9w0BCQEWD2hlbGxvQGJhdGNoLmNvbTCCAiIwDQYJKoZIhvcN
AQEBBQADggIPADCCAgoCggIBAMFWiWz16eZ7A/P9ontlqLatmbvBZ8xJSlhlVDEu
aARa8xQfpMtF0zcZZY3ialaKdhN8QqDgCMGPmlUmPMwtmxIW9I0DzTm9yNTNiynL
xYZCaZ+aVlGoPrtlDUT2c9j8kNLJtuQmT9O3XEcsNEPTz0vihcR7OjUuH54EMN8W
A0jyCzBWS9ce0X2y4BOLuq25AMBZUi0ci9XIp7WJt15KeyOjQfQE3laodZ9d3cib
ayjdFUqZeCBWghYNlAR2pIDiKIW6fzI6a04D0u0BG+C5zfZuovgw8LXJckLSfp4g
8o24aTkg6MctaNNen8MNwQPX+dzHopAq0XlMH8yeHtx18PKLSYjBnzwIut08z0d1
+ieSRG05oYDoJy8/zSzPTb6jOEUJqQd8v2jqlxBt4DNnWW2QZgeBiI/yNcEiYoSf
BZbt4+olpl0su9YgXJ4tfLM6aXDDBLpd5vwPESVp0tO9A0iE26nkO/rCjQ/K9E82
mhiafcwdJbGiQ4YusKItkASKmvhDHkNuZ/7HwT+qTOhpr6muS6QXn27NlE5Mx6lc
VSKxTNvKoJzmIdNMKXK8vm1dZfIjFuo36wEkNkAUulrGTWVisxAism0c7TWCkz0f
KdffQK/VE7LqvAJEtjyip5Wez+qlwrCqUNi2xCzCQfvvufvcAjfXbdfepoQ0D/Sw
EGd3AgMBAAGjUzBRMB0GA1UdDgQWBBQZqVkQbrBPIAqgrRUMWnBvl/SqXDAfBgNV
HSMEGDAWgBQZqVkQbrBPIAqgrRUMWnBvl/SqXDAPBgNVHRMBAf8EBTADAQH/MA0G
CSqGSIb3DQEBCwUAA4ICAQAZ5f4Hn/xiIYN8z4sEYx9yuJP4g8Ed9eFkdATh+qx1
6w0fCZkSr82jcgSkd/zw7i7YG+1aShXAuOinq/S8ge3CGVJgUrpEh2eo6s/R/MOW
J97/7UxS5dQpEve7DcwR4wNHRrlZQc/LItm4RjtA/c75dvkOFV7fEhCDaZ6MF6iD
Rib0o6dwKZyfx6AfKAEHQ5b32mibBZ2H3wMb0aHGcNMyBcdok+6FQUPpasPjp1ig
1zVv/Xw7QoT0vuEoi819v2UoQ1zSeDbaA3icqeJQYKJYvBXeUaIY7tpu5WkYDqeU
E0bCnEH/tDyuT2V6PN+GP5UxhmjvsQLvF2hSTMN6pJcFdfb7t6wguPVI7AsfC2hX
AIwDmP0fshPe3tG4ZfWNmWWVLVF5wKbWXrxoUSmgRdz45iNWIhdwxfOC5s6RRcN1
NCbNArsFFRKZikfI8QNUCSUuarHWCr1diUYxRna5fuI9ojYUDIbvFJNPNndcMs8v
JODDZCcF3x7vT303lHq8f1ktgHlRtDYyZNBP1305UIlfHE8oPB1NFJR3WE535EkL
grBN4liZCamffIYxC38zB05pxeCtwA60FjNvYwJkR0Kb4vzyLCga+3l0ROOmfQHA
GmNTRhQS16lVUyYJ/6ZxGDd64/lOtOK6E2Y4N3jwy05oEbF73rmJLVCEMy5Vphna
2w==
-----END CERTIFICATE-----`}</Code>
              </InputWrapper>
            </Grid>
            <Grid style={{ padding: '0 24px 24px 24px' }} template={gridTemplate}>
              <InputWrapper label="Callback user name">
                <Code>{previousConfig.callbackUsername}</Code>
              </InputWrapper>
              <div />
              <InputWrapper label="Callback key" style={{ marginTop: 0 }}>
                <Code>{previousConfig.callbackKey}</Code>
              </InputWrapper>
            </Grid>
          </React.Fragment>
        )}
      </BoxBody>
      <BoxFooter isEditable={manual}>
        {manual && (
          <Button
            forceBlockDisplay
            kind="inline"
            onClick={() => {
              setManual(false)
              clearErrors()
              setHMS(previousConfig)
            }}
          >
            Cancel
          </Button>
        )}
        <FooterBoxActions>
          <LinkDoc
            intent="action"
            href="https://doc.batch.com/android/huawei"
            target="_blank"
            style={{ marginRight: 8 }}
          >
            Documentation
          </LinkDoc>
          {manual ? (
            <Button kind="primary" intent="action" loading={loading} onClick={save}>
              Save HMS config
            </Button>
          ) : (
            (config.hms || manual) && (
              <PermissionButton
                intent="action"
                kind="primary"
                onClick={confirmManual}
                isAllowed={isAllowedToChangeHMSConfig}
              >
                Update HMS config
              </PermissionButton>
            )
          )}
        </FooterBoxActions>
      </BoxFooter>
    </React.Fragment>
  )
}
