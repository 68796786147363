// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'

import { useCurrentCompanyHasOneFeatureAmongst } from 'components/_hooks'
import { Navbar as NavbarLegacy } from 'components/navigation/navbar'
import { useAdminHotkeys } from 'components/project/admin-hotkeys'

import { ChannelSubNav } from './channel-sub-nav'
import { Navbar } from './navbar'

import { appsSelector, currentAppSelector, currentProjectAppsSelector } from 'com.batch.redux/app'
import { optionalCurrentProjectSelector, projectsSelector } from 'com.batch.redux/project.selector'
import { companyStateSelector, currentUserSelector } from 'com.batch.redux/user.selector'

type NavbarConnectedProps = {}
export const NavbarConnected: React$AbstractComponent<NavbarConnectedProps, *> = React.memo(
  (): React.Node => {
    // -----  redux
    const user = useSelector(currentUserSelector)
    const company = useSelector(companyStateSelector)
    const project = useSelector(optionalCurrentProjectSelector)
    const projects = useSelector(projectsSelector)
    const apps = useSelector(currentProjectAppsSelector)
    const allApps = useSelector(appsSelector)
    const app = useSelector(currentAppSelector) // return a new AppRecord with id=0 on project only page
    // -----  router
    const matchSettings = useMatch(
      '/:companyId/projects/:projectId/apps/:appId/settings/:activeTab/*'
    )

    const matchOmnichannelAutomationForm = useMatch(
      '/:companyId/projects/:projectId/automations/:channel/*'
    )
    const matchOmnichannelCampaignForm = useMatch(
      '/:companyId/projects/:projectId/campaigns/:channel/*'
    )
    const matchFakeOmniPushForm = useMatch(
      '/:companyId/projects/:projectId/campaigns/push-multiplatform/new'
    )
    const matchProjectSettings = useMatch('/:companyId/projects/:projectId/settings/:activeTab')
    const matchProjectAnalytics = useMatch('/:companyId/projects/:projectId/analytics')
    const matchProjectCampaigns = useMatch('/:companyId/projects/:projectId/campaigns/*')
    const matchProjectAutomations = useMatch('/:companyId/projects/:projectId/automations/*')
    const showChannelSubNavOnAnalytics = useCurrentCompanyHasOneFeatureAmongst([
      'cep-show-legacy-campaign',
      'cep-show-legacy-campaign-omni',
      'cep-show-legacy-recurring',
      'cep-show-legacy-trigger',
      'inapp-campaigns',
    ])
    const showChannelSubNavOnAutomations = useCurrentCompanyHasOneFeatureAmongst([
      'cep-show-legacy-recurring',
      'cep-show-legacy-trigger',
      'inapp-campaigns',
    ])
    const showChannelSubNavOnCampaigns = useCurrentCompanyHasOneFeatureAmongst([
      'cep-show-legacy-campaign',
      'cep-show-legacy-campaign-omni',
    ])
    // -----  derived
    const isSettings = React.useMemo(() => {
      return !!matchSettings
    }, [matchSettings])

    useAdminHotkeys()

    const channel = React.useMemo(() => {
      if (project) {
        if (
          !window?.location?.pathname?.endsWith('/email') &&
          !/\/apps\/\d+/.test(window?.location?.pathname)
        ) {
          return null
        }
        if (app.id === 0) {
          /* we need this check because on /project/projecId/campaigns/push-multiplatform/new we :
            - do not have an app (app.id==0)
            - do not want to route to /email campaign list
            when we get a real project campaign list this hack will go away
          */
          const projectHasOneChannelConfigured =
            project.smsConfigured || project.pushConfigured || Boolean(project.emailDomain)
          return (window?.location?.pathname?.includes('/email') ||
            window?.location?.pathname?.includes('/automations')) &&
            projectHasOneChannelConfigured
            ? 'email' // on passe email en channel mais en vrai osef ça tombe sur omni, faudrait probablement revoir ChannelInUrl
            : { appId: project.appIds.first() }
        }
      }
      return { appId: app.id }
    }, [app.id, project])
    if (!project) return <NavbarLegacy showIntegrate={app.showIntegrate} app={app} />

    return (
      <React.Fragment>
        <Navbar
          company={company}
          project={project}
          user={user}
          channel={channel}
          allApps={allApps}
          projects={projects}
        />
        {!isSettings &&
          !(matchOmnichannelAutomationForm || matchOmnichannelCampaignForm) &&
          !matchProjectSettings &&
          !matchProjectSettings &&
          (!matchProjectCampaigns || showChannelSubNavOnCampaigns) &&
          (!matchProjectAutomations || showChannelSubNavOnAutomations) &&
          (!matchProjectAnalytics || showChannelSubNavOnAnalytics) && (
            <ChannelSubNav
              apps={apps}
              project={project}
              channel={
                matchFakeOmniPushForm
                  ? { appId: project.appIds.first() }
                  : app.id === 0
                    ? 'email'
                    : { appId: app.id }
              }
              gotBorder={!isSettings}
              isTransparent
            />
          )}
      </React.Fragment>
    )
  }
)
