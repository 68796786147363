// @flow

import { type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { showToast } from 'com.batch.redux/toaster'

import { removeEmailContent } from 'com.batch/message/usecases/remove-email-content'
import {
  type JourneyNodeRecord,
  type BranchId,
} from 'com.batch/orchestration-journey/models/journey.records'

/*
  Deletes a node form the tree
    keep tells us what to keep from the delete node ; 
    if keep is null, we delete the node and all its descendants

    common uses cases : 
      - delete message, keeping its next node
      - delete timer, keeping its next node
      - delete a yesno, keeping its yes branch
      - delete a yesno, keeping its no branch
      - delete a yesno, trashing all branches (keep is undefined)

    future uses cases : 
      - delete a timer, keeping one of its onEvents branch
      - delete big chunck of a journey (timer or message, keep undefined)
 */
export type RemoveNodeAction = ReduxAction<
  'REMOVE_NODE',
  { nodeToRemove: JourneyNodeRecord, branchToKeep: ?BranchId, ... },
>
type removeNodeAndUpdateTreeProps = {
  node: JourneyNodeRecord,
  keep: ?BranchId,
}
export const removeNodeAndUpdateTree = ({
  node,
  keep,
}: removeNodeAndUpdateTreeProps): DispatchBoundFn<void> => {
  return dispatch => {
    dispatch(
      ({
        type: 'REMOVE_NODE',
        payload: {
          nodeToRemove: node,
          branchToKeep: keep,
        },
      }: RemoveNodeAction)
    )
    if (node.type === 'MESSAGE') {
      dispatch(removeEmailContent(node.messageReference))
    }
    if (node.type !== 'YESNO' || node.yesNodeId === node.noNodeId) {
      dispatch(
        showToast({
          message:
            node.type === 'MESSAGE'
              ? node.channel === 'email'
                ? 'Email deleted'
                : node.channel === 'push'
                  ? 'Push deleted'
                  : 'SMS deleted'
              : node.type === 'YESNO'
                ? 'Yes/No split deleted'
                : node.type === 'RANDOM'
                  ? 'Random split deleted'
                  : 'Delay deleted',
          kind: 'success',
        })
      )
    } else {
      dispatch(
        showToast({
          message: `Split & branch${!keep ? 'es' : ''} deleted`,
          kind: 'success',
        })
      )
    }
  }
}
