// @flow

import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { CampaignNameLink } from 'components/campaign/campaign-list.styles'
import { CampaignTableDropDownItems } from 'components/campaign/campaign-table-dropdown'
import { EventFormatter } from 'components/campaign/event-formatter'
import { useDropdown, Button, ButtonNavLink, DropdownMenu } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow, TableTemplateCell } from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { FilterCounterOption } from 'components/filter'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import { Ellipsis, Timezone } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import { textLight } from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

import { dateTime } from 'com.batch.common/date-formats'
import { generateOrchestrationUrl } from 'com.batch.common/router'
import {
  computeRepeatWord,
  kformat,
  percentage,
  numberFormat,
  campaignStateFormatter,
} from 'com.batch.common/utils'

import {
  CampaignFilterFactory,
  type CampaignRecord,
  type CampaignFilterRecord,
  type AppRecord,
} from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'

import { DELAY_MODE } from 'constants/common'

type CampaignListRowProps = {
  companyId: number,
  app: AppRecord,
  project: ?ProjectRecord,
  isUserAllowedToEdit: boolean,
  allowEdit: boolean,
  campaign: CampaignRecord,
  hasDevApiKeyFeature: boolean,
  ...
}

type CampaignListEmptyTemplateProps = {
  isAutomation: boolean,
  ...
}

// ====================== ROW COMPONENT FOR CAMPAIGN LIST
export const CampaignListRow: React.AbstractComponent<CampaignListRowProps> =
  React.memo<CampaignListRowProps>(
    ({
      campaign,
      project,
      allowEdit,
      companyId,
      app,
      isUserAllowedToEdit,
      hasDevApiKeyFeature,
    }: CampaignListRowProps): React.Node => {
      const { triggerProps, dropdownProps, closeDropdown } = useDropdown({
        forcedWidth: 155,
        placement: 'left-start',
        offset: [0, 4],
      })

      const isAutomation = campaign.schedulingType === 'automations'
      const isPush = campaign.type === 'push'
      let flags = []
      if (campaign.api) flags.push('API')
      if (campaign.devOnly) flags.push('Dev')

      const urls = React.useMemo(() => {
        const props = {
          companyId,
          channel: { appId: app.id },
          token: campaign.token,
          sendType: campaign.type === 'in-app' ? 'in-app' : campaign.sendType,
          ...(project ? { projectId: project.id } : {}),
        }

        return {
          data: generateOrchestrationUrl({ ...props, page: 'analytics' }),
          edit: generateOrchestrationUrl({ ...props, page: 'form' }),
          review: generateOrchestrationUrl({ ...props, page: 'review' }),
        }
      }, [project, companyId, app.id, campaign.token, campaign.type, campaign.sendType])

      const value = campaign.stats.loading
        ? 0
        : kformat(
            isPush ? campaign.stats.push.total.sentNotifOn : campaign.stats.inapp.total.display
          )
      const rate = campaign.stats.loading
        ? 0
        : isPush
          ? percentage(campaign.stats.push.total.openRate, 2)
          : percentage(
              campaign.stats.inapp.total.display
                ? campaign.stats.inapp.total.click.total / campaign.stats.inapp.total.display
                : 0
            )

      const deliveryCell = React.useMemo(
        () => (
          <span
            style={
              isAutomation ? { paddingTop: 3, paddingBottom: 3 } : { padding: 3, marginRight: -3 }
            }
          >
            {value !== '-' ? `${value} sent` : value}
          </span>
        ),
        [isAutomation, value]
      )

      const interactionCell = React.useMemo(
        () => (
          <span
            style={
              isAutomation ? { paddingTop: 3, paddingBottom: 3 } : { padding: 3, marginRight: -3 }
            }
          >
            {rate !== '' ? `${rate} opened` : rate}
          </span>
        ),
        [isAutomation, rate]
      )

      return (
        <TableRow key={campaign.token}>
          <TableCell align="center">
            {
              <Tooltip
                tooltip={
                  <React.Fragment>
                    This campaign is{' '}
                    <strong>{campaignStateFormatter(campaign.state, isAutomation)}</strong>
                  </React.Fragment>
                }
              >
                <CampaignStateIcon
                  state={campaign.state}
                  schedulingType={isAutomation ? 'automations' : 'campaigns'}
                />
              </Tooltip>
            }
          </TableCell>
          <TableCell
            isMultiline={isAutomation}
            style={{
              overflow: 'hidden',
            }}
          >
            <Grid template="auto 1fr" gap={0}>
              <Tooltip
                minWidth={Math.min(
                  500,
                  Math.max(
                    50,
                    campaign.name.length * 10,
                    (campaign.defaultTitle?.length ?? 0) * 9,
                    (campaign.defaultMessage?.length ?? 0) * 0
                  )
                )}
                placement="left"
                tooltip={
                  <div style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                    {campaign.name}
                    {isPush && (
                      <div
                        style={{
                          borderTop: `1px solid ${colors.strokeContrast}`,
                          marginTop: 10,
                          paddingTop: 10,
                        }}
                      >
                        {campaign.defaultTitle && <p>{campaign.defaultTitle}</p>}
                        <p>{campaign.defaultMessage}</p>
                      </div>
                    )}
                  </div>
                }
              >
                <CampaignNameLink intent="action" to={urls.review}>
                  {campaign.name}
                </CampaignNameLink>
              </Tooltip>
              {flags.length >= 0 && (
                <div style={{ display: 'inline-flex', marginLeft: 3 }}>
                  {flags.map(f => (
                    <Timezone key={f} style={{ marginLeft: 5 }}>
                      {f}
                    </Timezone>
                  ))}
                </div>
              )}
            </Grid>
            {isAutomation && (
              <Ellipsis style={{ marginTop: '2px' }}>
                {campaign.type === 'in-app' ? 'In-app message' : 'Push notification'}
              </Ellipsis>
            )}
          </TableCell>
          <TableCell>
            <WhenSummary {...getWhenData(campaign, isPush)} />
          </TableCell>
          {campaign.stats.loading ? (
            <React.Fragment>
              <TableTemplateCell template="1fr" forceLoading />
              <TableTemplateCell template="1fr" forceLoading />
            </React.Fragment>
          ) : isPush ? (
            <React.Fragment>
              <TableCell align="left">
                {hasDevApiKeyFeature || campaign.stats.push.total.devSent ? (
                  <Tooltip
                    isTooltipEmpty={app?.platform === 'webpush' || !isPush === 0}
                    tooltip={`${kformat(
                      campaign.stats.push.total.devSent
                    )} sent on the dev api key`}
                    placement="left"
                  >
                    <span
                      style={
                        isAutomation
                          ? { paddingTop: 3, paddingBottom: 3 }
                          : { padding: 3, marginRight: -3 }
                      }
                    >
                      {deliveryCell}
                    </span>
                  </Tooltip>
                ) : (
                  deliveryCell
                )}
              </TableCell>
              <TableCell align="left">
                {hasDevApiKeyFeature || campaign.stats.push.total.devOpen ? (
                  <Tooltip
                    tooltip={`${kformat(
                      campaign.stats.push.total.devOpen
                    )} open on the dev api key`}
                    placement="left"
                  >
                    {interactionCell}
                  </Tooltip>
                ) : (
                  interactionCell
                )}
              </TableCell>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TableCell align="left">{value !== '-' ? `${value} displayed` : value}</TableCell>
              <TableCell align="left">{rate !== '' ? `${rate} clicked` : rate}</TableCell>
            </React.Fragment>
          )}
          <TableCellActions>
            <Tooltip
              tooltip={
                isUserAllowedToEdit ? 'Edit' : 'This feature requires higher permissions level'
              }
              placement="top"
            >
              <ButtonNavLink
                style={{ height: 28, width: 28 }}
                disabled={!allowEdit || !isUserAllowedToEdit}
                to={allowEdit && isUserAllowedToEdit ? urls.edit : ''}
              >
                <Icon icon="edit" />
              </ButtonNavLink>
            </Tooltip>
            <Tooltip tooltip="More actions">
              <Button {...triggerProps} style={{ height: 28, width: 28 }}>
                <Icon icon="options" />
              </Button>
            </Tooltip>
            <DropdownMenu {...dropdownProps}>
              <CampaignTableDropDownItems
                appId={app.id}
                url={urls.data}
                campaign={campaign}
                closeDropdown={closeDropdown}
              />
            </DropdownMenu>
          </TableCellActions>
        </TableRow>
      )
    }
  )

// ====================== WHEN - displays icon & test according to sendType
type WhenSummaryProps = {
  label: React.Node,
  utc: boolean,
  icon: 'campaign-instant-icon' | 'campaign-scheduled' | 'campaign-recurring' | 'campaign-trigger',
  features: { key: React.Node, value: React.Node, ... }[],
  ...
}

export function getWhenData(c: CampaignRecord, isPush: boolean): WhenSummaryProps {
  let data: WhenSummaryProps = {
    label: '',
    utc: c.sendType !== 'trigger' && !c.tzAware,
    icon: 'campaign-instant-icon',
    features: [],
  }
  const type: string = !isPush ? 'in-app' : c.sendType

  switch (type) {
    case 'now':
    case 'scheduled': {
      const isNow = type === 'now'
      const date = (c.start && c.start.format('DD/MM/YYYY [at] HH:mm')) || ''

      data.label = date
      data.icon = isNow ? 'campaign-instant-icon' : 'campaign-scheduled'
      data.features = [{ key: 'Type', value: isNow ? 'Now' : 'Scheduled' }]
      break
    }
    case 'trigger':
      data.label = (
        <React.Fragment>
          On&nbsp;
          <EventFormatter isTooltipDisabled asText eventCode={c.triggerConfig.enterEvent} />
        </React.Fragment>
      )
      data.icon = 'campaign-trigger'
      data.features = [{ key: 'Type', value: 'Trigger' }]
      break
    case 'recurring':
      data.label = `Every ${computeRepeatWord(c.repeatUnit, c.repeatFrequency, c.start)} at ${
        (c.start && c.start.format('HH[h]mm')) || ''
      }`
      data.icon = 'campaign-recurring'
      data.features = [
        { key: 'Type', value: 'Recurring' },
        {
          key: 'Frequency',
          value: `Every ${computeRepeatWord(c.repeatUnit, c.repeatFrequency, c.start)}`,
        },
      ]
      break
    case 'in-app':
      data.label = <EventFormatter eventCode={c.trigger} asText eventLabel="" />
      data.icon = 'campaign-trigger'
      data.features = [
        { key: 'Type', value: 'In-App' },
        {
          key: 'Trigger event',
          value: <EventFormatter eventCode={c.trigger} asText eventLabel={c.triggerLabel} />,
        },
      ]
      break
  }

  if (type === 'trigger') {
    const config = c.triggerConfig
    data.features.push({
      key: 'Trigger event',
      value: <EventFormatter eventCode={config.enterEvent} eventQuery={config.enterEventQuery} />,
    })
    if (config.hasExitEvent)
      config.exitEvents.forEach((exitEvent, index) => {
        data.features.push({
          key: index === 0 ? `Cancellation event${config.exitEvents.size > 1 ? 's' : ''}` : '',
          value: <EventFormatter eventCode={exitEvent.eventId} eventQuery={exitEvent.query} />,
        })
      })
    if (config.hasStart && config.start)
      data.features.push({
        key: 'Starting on',
        value: (
          <span style={{ display: 'flex' }}>
            {config.start.format(dateTime)}
            <Timezone>UTC</Timezone>
          </span>
        ),
      })
    if (config.hasEnd && config.end)
      data.features.push({
        key: 'Ending on',
        value: (
          <span style={{ display: 'flex' }}>
            {config.end.format(dateTime)}
            <Timezone>UTC</Timezone>
          </span>
        ),
      })
    if (config.pushTimer)
      data.features.push({
        key: 'Push timing',
        value:
          config.delayMode === DELAY_MODE.TIMER ? (
            <React.Fragment>
              {config.pushTimer.fullText}
              {!config.pushTimerReference
                ? ''
                : ` (${config.pushTimerMode} ${config.pushTimerReference})`}
            </React.Fragment>
          ) : (
            'Immediately'
          ),
      })

    if (config.hasGrace)
      data.features.push({
        key: 'Grace period',
        value: config.grace.fullText,
      })
  }

  if (c.start)
    data.features.push({
      key: type === 'scheduled' || type === 'now' ? 'Date' : 'Starting on',
      value: (
        <span style={{ display: 'flex' }}>
          {c.start.format(dateTime)}
          {!c.tzAware && <Timezone>UTC</Timezone>}
        </span>
      ),
    })
  if (c.end && type !== 'scheduled')
    data.features.push({
      key: 'Ending on',
      value: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {c.end.format(dateTime)}
          {!c.tzAware && <Timezone>UTC</Timezone>}
        </span>
      ),
    })

  if (c.gracePeriod)
    data.features.push({
      key: 'Grace period',
      value:
        c.gracePeriod % 24 === 0
          ? `${c.gracePeriod / 24} day${c.gracePeriod / 24 > 1 ? 's' : ''}`
          : `${c.gracePeriod} hour${c.gracePeriod > 1 ? 's' : ''}`,
    })

  if (c.capping)
    data.features.push({ key: 'Capping', value: `${c.capping} time${c.capping > 1 ? 's' : ''}` })
  return data
}

export const WhenSummary: React.AbstractComponent<WhenSummaryProps> = React.memo<WhenSummaryProps>(
  ({ label, utc, icon, features }: WhenSummaryProps) => {
    const tooltip = (
      <KeyValueTooltip>
        <tbody>
          {features.map((f, i) => (
            <tr key={i}>
              <td>{f.key}</td>
              <td>{f.value}</td>
            </tr>
          ))}
        </tbody>
      </KeyValueTooltip>
    )

    return (
      <Tooltip tooltip={tooltip} placement="right">
        <Grid
          template={utc ? 'auto 1fr auto' : 'auto 1fr'}
          style={{ display: 'inline-grid', padding: 4, margin: -4 }}
        >
          <Icon icon={icon} style={{ color: textLight }} />
          <Ellipsis style={{ paddingTop: 1 }}>{label}</Ellipsis>
          {utc && <Timezone>UTC</Timezone>}
        </Grid>
      </Tooltip>
    )
  }
)

// ====================== CUSTOM SELECT & FORMATER FOR FILTERING

export const NameCountOptionFormater = (opt: { name: string, count: number, ... }): React.Node => (
  <FilterCounterOption label={opt.name} counter={numberFormat(opt.count)} />
)

// ====================== TOOLTIPS FOR TRIGGERS & IN-APP
const KeyValueTooltip: StyledComponent<*, *, HTMLElement> = styled.table`
  margin: -6px -4px -10px -4px;

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    overflow: hidden;
  }

  td {
    padding: 8px 2px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
  }

  td:first-child {
    font-weight: 600;
    color: ${colors.textContrast};
  }

  td:last-child {
    padding-left: 12px;
    color: ${colors.textContrast};
    font-weight: 400;
    text-overflow: ellipsis;
  }

  ${Timezone} {
    margin: 0 0 0 8px;
    color: ${colors.text};
    background: ${schemes.darklucent['80']};
  }
`

// Empty Template
export const CampaignListEmptyTemplate = ({
  isAutomation,
}: CampaignListEmptyTemplateProps): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell isMultiline={isAutomation} template={isAutomation ? '1fr, 1fr' : '1fr'} />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="start" />
      <TableTemplateCell template="1fr" align="start" />
    </React.Fragment>
  )
}

export const filters: Set<CampaignFilterRecord> = Immutable.OrderedSet([
  CampaignFilterFactory({ category: 'status', name: 'running', label: 'Running' }),
  CampaignFilterFactory({ category: 'status', name: 'stopped', label: 'Stopped' }),
  CampaignFilterFactory({ category: 'status', name: 'draft', label: 'Draft' }),
  CampaignFilterFactory({ category: 'status', name: 'completed', label: 'Completed' }),
  CampaignFilterFactory({ category: 'sources', name: 'apiPrivate', label: 'Dashboard' }),
  CampaignFilterFactory({ category: 'sources', name: 'apiPublic', label: 'API' }),
  CampaignFilterFactory({ category: 'sources', name: 'editorial', label: 'Editorial' }),
  CampaignFilterFactory({ category: 'when', name: 'recurring', label: 'Recurring' }),
  CampaignFilterFactory({ category: 'when', name: 'trigger', label: 'Trigger' }),
  CampaignFilterFactory({ category: 'channels', name: 'push', label: 'Push' }),
  CampaignFilterFactory({ category: 'channels', name: 'in-app', label: 'In-app' }),
])
