// @flow

// eslint-disable-next-line import/no-unresolved
import { animated, useSpring } from '@react-spring/web'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { type StateToggler } from 'components/_hooks'

import { getEmailContentForSelectedLanguageSelector } from 'com.batch/email/store/email-automation.selector'

import {
  IFrameContainer,
  IFramePreviewSwitchContainer,
} from 'com.batch/email/ui/components/email-preview/email-preview.styles'
import { EmailPreviewToolbar } from 'com.batch/email/ui/components/email-preview-toolbar'
import { PreviewSwitch } from 'com.batch/email/ui/components/preview-switch'

type EmailPreviewProps = {
  sendTestEmailPopinState?: StateToggler,
  dynamicContentPopinState?: StateToggler,
  showToolbar?: boolean,
  editTemplate?: () => void,
  messageId: string,
  ...
}
export const EmailPreview = ({
  sendTestEmailPopinState,
  dynamicContentPopinState,
  showToolbar = false,
  editTemplate,
  messageId,
}: EmailPreviewProps): React.Node => {
  const getContent = useSelector(getEmailContentForSelectedLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
  const iFrameRef = React.useRef()

  const [preview, setPreview] = React.useState<'MOBILE' | 'DESKTOP'>('DESKTOP')

  const springProps = useSpring({
    width: preview === 'MOBILE' ? '380px' : '700px',
    config: { duration: 400 },
    onRest: () => resizeIFrameToFitContent(),
  })

  const url = React.useMemo(() => {
    const blobHtml = new Blob([content?.templates.html], {
      type: 'text/html',
    })
    return URL.createObjectURL(blobHtml)
  }, [content?.templates.html])

  /**
   * Renvoie les events mouseup et mousedown au parent de l'iframe.
   * Pour par exemple trigger les dismiss de Dropdown
   */
  const bubbleMouseEvents = React.useCallback(() => {
    iFrameRef.current?.contentWindow.document.addEventListener('mouseup', () => {
      iFrameRef.current?.contentWindow.parent?.document.dispatchEvent(new Event('mouseup'))
    })

    iFrameRef.current?.contentWindow.document.addEventListener('mousedown', () => {
      iFrameRef.current?.contentWindow?.parent?.document.dispatchEvent(new Event('mousedown'))
    })
  }, [])

  /**
   * Resize l'iframe pour qu'elle prenne la hauteur de son contenu.
   * Cela permet que la scrollbar s'affiche sur le parent de l'iframe et non l'iframe elle même
   */
  const resizeIFrameToFitContent = React.useCallback(() => {
    if (iFrameRef.current) {
      iFrameRef.current.style.height = `${
        iFrameRef.current.contentWindow?.document.body.scrollHeight + 40
      }px`
    }
  }, [])

  const onLoadIFrame = React.useCallback(() => {
    bubbleMouseEvents()
    resizeIFrameToFitContent()
  }, [bubbleMouseEvents, resizeIFrameToFitContent])

  if (!content?.templates.html) {
    return (
      <IFrameContainer shouldHideOverflow={true}>
        <img src="/medias/img/canvas/email-preview.svg" style={{ width: '100%', height: '100%' }} />
      </IFrameContainer>
    )
  }

  return (
    <IFramePreviewSwitchContainer>
      <IFrameContainer isToolbarShown={showToolbar}>
        <animated.iframe
          sandbox="allow-same-origin"
          ref={iFrameRef}
          title="Email preview"
          src={url}
          style={{
            ...springProps,
            border: 'none',
            overflow: 'hidden',
            flexGrow: 1,
          }}
          onLoad={onLoadIFrame}
        />
        {showToolbar &&
          !!sendTestEmailPopinState &&
          !!dynamicContentPopinState &&
          !!editTemplate && (
            <EmailPreviewToolbar
              sendTestEmailPopinState={sendTestEmailPopinState}
              dynamicContentPopinState={dynamicContentPopinState}
              editTemplate={editTemplate}
              messageId={messageId}
            />
          )}
      </IFrameContainer>
      <PreviewSwitch preview={preview} setPreview={setPreview} />
    </IFramePreviewSwitchContainer>
  )
}
