// @flow

import * as React from 'react'

import { PushTester } from 'components/project/campaign/common/push-tester'

import { LayerBarDropdown } from 'com.batch/message-builder/ui/components/layerbar-button'
import { LayerBarWrapper } from 'com.batch/message-builder/ui/components/layerbar.styles'

type LayerBarProps = {
  setEditing: ('image' | 'message' | 'title' | 'icon' | null, number) => void,
  hasMedia: boolean,
  showIcon: boolean,
  hasIcon: boolean,
  openMediaPopin: () => void,
  openFileUploader: () => void,
}

export const PushLayerBar = ({
  openFileUploader,
  hasIcon,
  showIcon,
  openMediaPopin,
  setEditing,
  hasMedia,
}: LayerBarProps): React.Node => {
  return (
    <LayerBarWrapper $itemsCount={showIcon ? 3 : 2}>
      <LayerBarDropdown
        label={!hasMedia ? 'Add media' : 'Media'}
        icon={<img src="/medias/img/builders/icons/media.svg" />}
        addable={!hasMedia}
        onClick={() => {
          setEditing('image', -1)
        }}
        options={[
          { label: 'From computer', prefix: 'upload', action: openFileUploader },
          { label: 'From link', prefix: 'link', action: openMediaPopin },
        ]}
      />

      {showIcon && (
        <LayerBarDropdown
          label={!hasIcon ? 'Add icon' : 'Icon'}
          icon={<img src="/medias/img/builders/icons/icon.svg" />}
          $rightAdd={22}
          addable={!hasIcon}
          onClick={() => {
            setEditing('icon', -1)
          }}
          options={[
            { label: 'From computer', prefix: 'upload', action: openFileUploader },
            { label: 'From link', prefix: 'link', action: openMediaPopin },
          ]}
        />
      )}

      <PushTester />
    </LayerBarWrapper>
  )
}
