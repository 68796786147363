// @flow
import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale'
import { stack, stackOrderNone } from 'd3-shape'
import * as React from 'react'

import { darklucent } from 'components/styled/tokens/schemes'

import { type PlotData } from 'com.batch/shared/infra/types/chart-data'
import { Plot } from 'com.batch/shared/ui/component/charts/plot-chart/plot'
import { Timeline } from 'com.batch/shared/ui/component/charts/plot-chart/timeline'

type Props = {
  width: number,
  data: Array<PlotData>,
  groups: Array<string>,
  height: number,
  margin?: { top?: number, right?: number, bottom?: number, left?: number, ... },
  colors: Array<string>,
  ySpacingPlot?: number,
  onOver?: (data: PlotData) => void,
  onLeave?: () => void,
  legends?: boolean,
  nbBars?: number, // if this property is set, so we can keep the same spacing between each bars
  barWidth?: number,
  isLoading?: boolean,
  labelsGap?: number,
  labelsSize?: number,
  labelsMarginTop?: number,
  ...
}

export const PlotChart = ({
  width,
  height,
  data,
  groups,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  colors,
  ySpacingPlot = 2,
  onOver,
  onLeave,
  legends = true,
  nbBars,
  barWidth = 8,
  isLoading = false,
  labelsGap,
  labelsSize = 14,
  labelsMarginTop = 23,
}: Props): React.Node => {
  let mouseLeaveTimeoutId = React.useRef<TimeoutID | null>(null)
  const [hoveredBar, setHoveredBar] = React.useState<?string>(null)
  const boundsHeight = height - (margin?.top ?? 0) - (margin?.bottom ?? 0)
  const labels = data.map(d => String(d.label))
  const stackSeries = stack()
    .keys(groups)
    .value((d: PlotData, key) => {
      return d[key].value
    })
    .order(stackOrderNone)

  const series = stackSeries(data)

  const svgHeight = React.useMemo(() => {
    if (legends) return height + 28
    return height
  }, [height, legends])

  const max = React.useMemo(() => {
    const sums = data.map(item => groups.reduce((acc, g) => acc + item[g].value, 0))
    return Math.max(...sums)
  }, [data, groups])

  const yScale = React.useMemo(() => {
    return scaleLinear().domain([0, max]).range([boundsHeight, 0])
  }, [max, boundsHeight])

  const xScale = React.useMemo(() => {
    return scaleBand<string>()
      .domain([
        ...labels,
        ...(nbBars !== undefined
          ? Array.from({ length: nbBars - labels.length }, (_, index) => String(index + 1))
          : []),
      ])
      .range([0, width])
  }, [labels, nbBars, width])

  const scaleColors = React.useMemo(
    () => scaleOrdinal<string>().domain(groups).range(colors),
    [colors, groups]
  )

  const handleOnOver = React.useCallback(
    (data: PlotData) => () => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current)
      setHoveredBar(data.label)
      if (onOver) onOver(data)
    },
    [onOver]
  )

  const handleOnLeave = React.useCallback(() => {
    // Le leave est debounce si on re-enter un autre plo pour éviter un clignotement inutile des valeurs quand on passe d'un plot à un autre
    mouseLeaveTimeoutId.current = setTimeout(() => {
      setHoveredBar(null)
      if (onLeave) onLeave()
    }, 80)
  }, [onLeave])

  const hasAnotherPlot = React.useCallback(
    (serieIndex, index) => {
      for (let i = serieIndex + 1; i < series.length; i++) {
        if (series[i] && yScale(series[i][index][0]) - yScale(series[i][index][1]) > 1) {
          return true
        }
      }
      return false
    },
    [series, yScale]
  )

  return (
    <svg width={width} height={svgHeight}>
      <g width={width} height={boundsHeight} transform={`translate(${[0, margin.top].join(',')})`}>
        {series.map((serie, sIndex) => {
          return (
            <g key={sIndex}>
              {serie.map((item, index) => {
                return (
                  <Plot
                    key={index}
                    colors={scaleColors}
                    hoveredBar={hoveredBar}
                    xScale={xScale}
                    yScale={yScale}
                    item={item}
                    serieKey={serie.key}
                    gap={sIndex * ySpacingPlot}
                    hasBorderRadius={!hasAnotherPlot(sIndex, index)}
                    onOver={handleOnOver}
                    onLeave={handleOnLeave}
                    barWidth={barWidth}
                    isLoading={isLoading}
                  />
                )
              })}
            </g>
          )
        })}
      </g>
      {legends && (
        <React.Fragment>
          <line x1="0" x2={width} y1={height} y2={height} stroke={darklucent['20']} />
          <Timeline
            xScale={xScale}
            labels={labels}
            y={height + labelsMarginTop}
            chartWidth={width}
            barWidth={barWidth}
            labelsGap={labelsGap}
            labelsSize={labelsSize}
            incomplete={(nbBars ?? 0) > labels.length}
            isLoading={isLoading}
          />
        </React.Fragment>
      )}
    </svg>
  )
}
