// @flow
import styled, { type StyledComponent } from 'styled-components'

import { schemes, texts } from 'components/styled/tokens'

export const Graduation: StyledComponent<*, *, Element> = styled.text`
  ${texts.legend}
  fill: ${schemes.darklucent['30']};
  text-anchor: end;
`
