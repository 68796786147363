/* eslint-disable react/jsx-no-bind */
// @flow
import * as React from 'react'
import { type ChromePickerProps } from 'react-color'

import { useClickOutside } from 'components/_hooks'

import { hexToHsl, hslToHex } from 'com.batch.common/utils'

import { ColorPickerContent, ColorPickerPreview } from './color-picker.styles'

import { InputContainer } from '../form'

type ColorPickerProps = {
  color: string,
  onChange: (color: string) => void,
}

function isColorValid(value: any) {
  const checker = /^#(?:[0-9a-f]{6})$/i
  return checker.test(value) || value === 'transparent'
}
const capLightness = 1

type Comp = Class<React$Component<ChromePickerProps>>

type FakeModule = {
  ChromePicker: Comp,
  ...
}

export const ColorPicker: React.AbstractComponent<ColorPickerProps> = React.memo<ColorPickerProps>(
  ({ color, onChange }: ColorPickerProps) => {
    const [open, setOpen] = React.useState(false)
    const [currentColor, setCurrentColor] = React.useState(color)
    const [valid, setValid] = React.useState(isColorValid(color))
    const [ref] = useClickOutside(() => setOpen(false))
    const [colorPicker, setColorPicker] = React.useState<?FakeModule>(null)

    React.useEffect(() => {
      if (open) {
        import('react-color').then(module => {
          setColorPicker(module)
        })
      }
    }, [open])
    const validateAndPropagate = (value: string) => {
      const valid = isColorValid(value)
      setCurrentColor(valid ? capColor(value) : value)
      setValid(valid)
      valid && onChange(valid ? capColor(value) : '')
    }

    const openPicker = () => {
      setOpen(true)
    }

    const onInputChange = (evt: SyntheticInputEvent<HTMLElement>) => {
      validateAndPropagate(evt.target.value)
    }

    const capColor = (hexColor: string) => {
      if (!!hexColor && hexColor !== 'transparent') {
        let bc = hexToHsl(hexColor)
        if (bc.l > capLightness) {
          bc.l = capLightness
          return hslToHex(bc)
        }
      }
      return hexColor
    }

    const onPickerChange = (color: { hex: string, ... }) => {
      validateAndPropagate(color.hex)
    }

    return (
      <ColorPickerContent ref={ref}>
        <ColorPickerPreview color={color} onClick={openPicker} />
        <InputContainer invalid={!valid}>
          <input type="text" value={currentColor || ''} onChange={onInputChange} />
        </InputContainer>
        {open && colorPicker ? (
          <colorPicker.ChromePicker
            color={valid ? currentColor : color}
            disableAlpha
            onChangeComplete={onPickerChange}
          />
        ) : null}
      </ColorPickerContent>
    )
  }
)
