// @flow
import Immutable, { type Map } from 'immutable'

import { countSmsMessage } from '../../usecases/count-sms-message'
import { validateSmsFields } from '../../usecases/validate-sms-fields'
import { type SmsContent } from '../types/sms.message.types'

import { SmsContentFactory, type SmsContentRecord } from 'com.batch/message/models/message.records'

export const parseSms = (
  raw: SmsContent,
  isMarketing: boolean
): {
  content: Map<string, SmsContentRecord>,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  const tuplesLangValueArray: Array<[string, SmsContentRecord]> = raw.localizedSms.map(
    ({ language, region, message }) => {
      const lang = language && region ? `${language}-${region}` : language ? language : 'default'
      const smsRecord = SmsContentFactory({
        smsMessage: message?.content?.text ?? '',
      })
      if (!smsRecord.smsMessage) {
        contentFullyEmpty = true
      }

      const { parts } = countSmsMessage({
        message: smsRecord.smsMessage,
        countStop: isMarketing,
      })

      if (
        validateSmsFields({
          message: smsRecord.smsMessage,
          parts,
        }).size > 0
      ) {
        contentInvalid = true
      }
      return [lang, smsRecord]
    }
  )
  return { content: Immutable.Map(tuplesLangValueArray), contentFullyEmpty, contentInvalid }
}
