// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { FilterSelect } from 'components/filter'

import { PUSH_PLATFORM_FILTER } from 'com.batch/profilebase/constants/push-platform-filter'
import { type PushPlatformFilterType } from 'com.batch/profilebase/infra/types/push-platform-filter.type'

export const PushFilter = (): React.Node => {
  const navigate = useNavigate()
  const [search] = useSearchParams()

  const onUserKindChange = React.useCallback(
    opt => {
      navigate({
        search: encodeURI(`pushPlatform=${opt?.filter ?? 'all'}`),
      })
    },
    [navigate]
  )
  const optToString = React.useCallback((opt: any) => opt?.label ?? '', [])

  const options: List<PushPlatformFilterType> = React.useMemo(
    () => PUSH_PLATFORM_FILTER.reduce((acc, current) => acc.push(current), new Immutable.List()),
    []
  )

  const selectedValue = React.useMemo(
    () =>
      options.find(opt => opt.filter === search.get('pushPlatform')) ??
      PUSH_PLATFORM_FILTER.find(f => f.filter === 'all'),
    [search, options]
  )

  return (
    <FilterSelect
      isSearchable={false}
      isClearable={false}
      options={options}
      value={selectedValue}
      optionToString={optToString}
      onChange={onUserKindChange}
      expandable={true}
    />
  )
}
