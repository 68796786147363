// @flow
import { type Set, type Map } from 'immutable'

import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'

import { type Orchestration } from 'com.batch/orchestration/infra/types/orchestration.types'
import { parseOrchestrationCampaign } from 'com.batch/orchestration-campaign/infra/parses/orchestration-campaign.parse'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { parseOrchestrationJourney } from 'com.batch/orchestration-journey/infra/parses/orchestration-journey.parse'
import {
  type JourneySettingsRecord,
  type JourneyNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'

type queryToParse = { queryId: string, query: string, eventId: string, retries: number, ... }
export type OrchestrationJourneyParserResult = {
  settings: JourneySettingsRecord,
  nodes: Map<string, JourneyNodeRecord>,
  rootId: string,
  queries: Array<queryToParse>,
  targets: Map<string, TargetStateRecord>,
  message: MessageStateRecord,
  state: campaignStateType,
  channels: Set<ChannelUntilCleanup>,
  incomplete: boolean,
  id: string,
  name: string,
  labelCodes: Set<string>,
}

export type OrchestrationCampaignParserResult = {
  campaign: OrchestrationCampaignRecord,
  queries: Array<queryToParse>,
  targets: Map<string, TargetStateRecord>,
  message: MessageStateRecord,
  state: campaignStateType,
  channels: Set<ChannelUntilCleanup>,
  incomplete: boolean,
  id: string,
  name: string,
  labelCodes: Set<string>,
}
export type OrchestrationParserResult =
  | OrchestrationJourneyParserResult
  | OrchestrationCampaignParserResult

type OrchestrationParser = Orchestration => OrchestrationParserResult

export const parseOrchestration: OrchestrationParser = orc => {
  if (orc.journey) {
    return parseOrchestrationJourney({
      journey: orc.journey,
      config: orc.dashboardConfig,
      state: orc.orchestrationState ?? 'DRAFT',
      channels: orc.orchestrationChannels,
      id: orc.id ?? '',
      name: orc.name,
      labelCodes: orc.journey.settings.labels ?? [],
    })
  } else if (orc.campaign) {
    return parseOrchestrationCampaign({
      campaign: orc.campaign,
      messages: orc.messages,
      config: orc.dashboardConfig,
      state: orc.orchestrationState ?? 'DRAFT',
      channels: orc.orchestrationChannels,
      id: orc.id ?? '',
      name: orc.name,
      labelCodes: orc.campaign.content.labels ?? [],
    })
  }

  throw new Error('Unhandled orchestration kind')
}
