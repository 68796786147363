// @flow

import { type Dayjs } from 'dayjs'
import Immutable, {
  type RecordOf,
  type RecordFactory,
  type Set,
  type Map,
  type OrderedSet,
  type List,
} from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

export type onboardingStepType = 'password' | '2fa' | 'company'

// @warning : this is dependant on the permissions setup PHP side in service.yaml, both needs to be updated

export type groupOnlyPermissionType =
  | 'group:review'
  | 'group:campaign'
  | 'group:app'
  | 'group:privacy'
  | 'group:editorial'
  | 'group:administrate'

export type companyPermissionType =
  | 'administrate'
  | 'apps'
  | 'users'
  | 'security'
  | 'billing'
  | 'gdpr'
  | 'editorial'
  | 'apps:create-delete'
  | groupOnlyPermissionType

export type appPermissionType =
  | 'analytics:audience'
  | 'analytics:reach'
  | 'analytics:notifications'
  | 'userbase'
  | 'push:read'
  | 'push:write'
  | 'in-app:read'
  | 'in-app:write'
  | 'settings:infos:read'
  | 'settings:infos:write'
  | 'api-keys:read'
  | 'push:config:read'
  | 'push:config:write'
  | 'test-devices:read'
  | 'custom-data:read'
  | 'custom-data:write'
  | 'custom-audiences:read'
  | 'custom-audiences:write'
  | 'themes:read'
  | 'themes:write'
  | 'debug:read'
  | 'debug:write'
  | 'gdpr:read'
  | 'gdpr:write'
  | 'labels:read'
  | 'labels:write'
  | 'cappings:read'
  | 'cappings:write'

export type CompanyUserPermissionsProps = {
  apps: Set<number>,
  permissions: OrderedSet<groupOnlyPermissionType>,
  ...
}

export const CompanyUserPermissionsFactory: RecordFactory<CompanyUserPermissionsProps> =
  Immutable.Record({
    permissions: Immutable.OrderedSet(),
    apps: Immutable.Set(),
  })

export type CompanyUserPermissionsRecord = RecordOf<CompanyUserPermissionsProps>

type EntityLogProps = {
  when: Dayjs,
  editor: string,
  topic: 'security' | 'info',
  message: string,
  ...
}
export const EntityLogFactory: RecordFactory<EntityLogProps> = Immutable.Record(
  ({
    when: dayjs(),
    editor: '',
    topic: 'security',
    message: '',
  }: EntityLogProps)
)

export type EntityLogRecord = RecordOf<EntityLogProps>

type UserProps = {
  id: ?number,
  externalId: string,
  email: string,
  nextEmail: ?string,
  isInvite: boolean,
  lastInvite: ?Dayjs,
  firstName: string,
  lastName: string,
  roles: OrderedSet<string>,
  avatarUrl: ?string,
  onboardingStep: OrderedSet<onboardingStepType>,
  lastLogin: ?Dayjs,
  lastAccess: ?Dayjs,
  createdAt: ?Dayjs,
  permissionsForCurrentCompany: Set<companyPermissionType>,
  permissionsForCurrentApp: Set<appPermissionType>,
  restrictedLanguages: Set<string>,
  restrictedRegions: Set<string>,
  companiesPermissions: Map<number, CompanyUserPermissionsRecord>,
  securedBy2FA: boolean,
  mustBeSecuredBy2FA: boolean,
  loading: boolean,
  logs: List<EntityLogRecord>,
  tokens: Map<string, string>,
  ...
}

export const UserFactory: RecordFactory<UserProps> = Immutable.Record(
  ({
    id: null,
    externalId: '',
    email: '',
    nextEmail: null,
    isInvite: false,
    lastInvite: null,
    firstName: '',
    lastName: '',
    lastLogin: null,
    lastAccess: null,
    createdAt: null,
    avatarUrl: null,
    roles: Immutable.OrderedSet(),
    onboardingStep: Immutable.OrderedSet(['password', '2fa', 'company']),
    permissionsForCurrentCompany: Immutable.Set(),
    permissionsForCurrentApp: Immutable.Set(),
    restrictedLanguages: Immutable.Set(),
    restrictedRegions: Immutable.Set(),
    companiesPermissions: Immutable.Map(),
    securedBy2FA: false,
    mustBeSecuredBy2FA: false,
    loading: false,
    logs: new Immutable.List(),
    tokens: Immutable.Map(),
  }: UserProps)
)

export type UserRecord = RecordOf<UserProps>
type UserStateProps = {
  entities: Map<number, UserRecord>,
  loadingState: fetchingState,
  currentUserId: ?number,
  ...
}

export const UserStateFactory: RecordFactory<UserStateProps> = Immutable.Record(
  ({
    entities: Immutable.Map(),
    loadingState: STATUS.INIT,
    currentUserId: null,
  }: UserStateProps)
)

export type UserStateRecord = RecordOf<UserStateProps>
