// @flow

import * as React from 'react'

import { AppWrapper } from 'components/common/app-wrapper'

import { DataRouter } from './data-router'

export const App = (): React.Node => {
  return (
    <AppWrapper sidebarKind="dashboard" epicMode="none">
      <DataRouter />
    </AppWrapper>
  )
}
