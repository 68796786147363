// @flow
import { type ReduxAction } from 'com.batch.redux/_records'

import { type JourneyNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

export type RemoveSingleNodeAction = ReduxAction<'REMOVE_SINGLE_NODE', string>
export type UpdateJourneyNodeAction = ReduxAction<
  'UPDATE_JOURNEY_NODE',
  { node: JourneyNodeRecord, dangerousSkipChecks: boolean, ... },
>

export const updateNode = (
  node: JourneyNodeRecord,
  dangerousSkipChecks?: boolean = false
): UpdateJourneyNodeAction => {
  return {
    type: 'UPDATE_JOURNEY_NODE',
    payload: { node, dangerousSkipChecks: Boolean(dangerousSkipChecks) },
  }
}
