// @flow

import Immutable, { type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { validateJSON } from 'com.batch.common/utils'

import { getEmailErrorsSelector } from 'com.batch/email/store/email-automation.selector'
import { LanguageFactory, type LanguageRecord, type State } from 'com.batch.redux/_records'
import {
  contentSelector,
  currentCampaign,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'

type extract<T> = State => T

const fakeDefaultLanguage = LanguageFactory({ value: 'default', label: 'Default' })

const translationgsWithDefaultSelector = createSelector(pickedLanguagesSelector, langs =>
  langs.splice(0, 0, fakeDefaultLanguage)
)

const getMessageKey = (lang: LanguageRecord) =>
  `Message${lang.value === 'default' ? '' : ` (${lang.label.toLowerCase()})`}`

export const pushErrorsSelector: extract<Map<string, Array<string>>> = createSelector(
  translationgsWithDefaultSelector,
  contentSelector,
  currentCampaign,
  (langsWithDefault, content, campaign) => {
    const variants: Array<'a' | 'b'> = campaign.abtesting.enabled
      ? campaign.abtesting.activeVariants.toArray()
      : ['a']

    const errors: Array<[string, Array<string>]> = langsWithDefault
      .map(lang => {
        let localErrors = []
        variants.map(variant => {
          if (!content.push.getIn([lang.value, variant])?.title?.value)
            localErrors.push(
              `Title${campaign.abtesting.enabled ? ` ${variant.toUpperCase()}` : ''} is missing`
            )
          if (!content.push.getIn([lang.value, variant])?.message?.value)
            localErrors.push(
              `Message${campaign.abtesting.enabled ? ` ${variant.toUpperCase()}` : ''}  is missing`
            )
        })
        return [getMessageKey(lang), localErrors]
      })
      .filter(err => err[1].length > 0)
      .toArray()
    return Immutable.Map(errors)
  }
)

export const globalErrorsSelector: extract<Map<string, Array<string>>> = createSelector(
  contentSelector,
  currentCampaign,
  (content, campaign) => {
    let errors: Array<[string, Array<string>]> = []
    if (content.campaignName === '')
      errors.push([
        'Global',
        [`${campaign.schedulingType === 'campaigns' ? 'Campaign' : 'Automation'} name is missing`],
      ])

    return Immutable.Map(errors)
  }
)

export const whenErrorsSelector: extract<Map<string, Array<string>>> = createSelector(
  currentCampaign,
  campaign => {
    let errors: Array<[string, Array<string>]> = []
    let localErrors: Array<string> = []

    const allowPastStart = campaign.state !== 'NEW' && campaign.state !== 'DRAFT'
    const now = dayjs()

    if (
      !allowPastStart &&
      campaign.sendType === 'scheduled' &&
      (!campaign.start || campaign.start.isBefore(now))
    )
      localErrors.push('Scheduled campaign requires a valid start date')
    if (campaign.sendType === 'trigger') {
      if (!campaign.triggerConfig.enterEvent) {
        localErrors.push('Trigger campaign requires a trigger event')
      }
      if (!campaign.triggerConfig.pushTimer.valid)
        localErrors.push('“Wait” (delay/timer) must be between 1 minute & 30 days')

      if (
        campaign.triggerConfig.hasStart &&
        campaign.triggerConfig.start &&
        campaign.triggerConfig.start.isBefore(now)
      )
        localErrors.push('Trigger campaign requires a valid start date')
      if (
        campaign.triggerConfig.hasEnd &&
        campaign.triggerConfig.end &&
        campaign.triggerConfig.hasStart &&
        campaign.triggerConfig.start &&
        campaign.triggerConfig.end.isSameOrBefore(campaign.triggerConfig.start, 'day')
      )
        localErrors.push('End date must be after start date')
    }
    if (campaign.triggerConfig.hasInstanceId && !campaign.triggerConfig.instanceId) {
      localErrors.push('Automation entrance needs a valid event attribut')
    }
    if (campaign.sendType === 'recurring') {
      if (!campaign.start || (!allowPastStart && campaign.start.isBefore(now)))
        localErrors.push('Recurring campaign requires a valid start date')
      if (campaign.end && campaign.start && campaign.end.isSameOrBefore(campaign.start, 'day'))
        localErrors.push('End date must be after start date')
      if (campaign.repeatFrequency < 1) localErrors.push("Repeat frequency can't be lower than 1")
    }
    if (localErrors.length > 0) errors.push(['When', localErrors])
    return Immutable.Map(errors)
  }
)

export const settingsErrorSelector: extract<Map<string, Array<string>>> = createSelector(
  contentSelector,
  content => {
    let errors: Array<[string, Array<string>]> = []
    if (!validateJSON(content.pushSettings.payload))
      errors.push(['Settings', ['Payload must be a valid JSON']])
    return Immutable.Map(errors)
  }
)

export const campaignErrorSelector: extract<Map<string, Array<string>>> = createSelector(
  globalErrorsSelector,
  whenErrorsSelector,
  pushErrorsSelector,
  settingsErrorSelector,
  (globalErr, whenErr, pushErr, settingsErr) => {
    return globalErr.merge(whenErr).merge(pushErr).merge(settingsErr)
  }
)

export const validTranslationsIdSelector: extract<Set<string>> = createSelector(
  translationgsWithDefaultSelector,
  pushErrorsSelector,
  getEmailErrorsSelector,
  currentCampaign,
  (langs, pushErrors, getEmailErr, campaign) => {
    const emailErr = getEmailErr('default')
    const errors = campaign.type === 'email' ? emailErr : pushErrors
    return Immutable.Set(langs.filter(lang => !errors.has(getMessageKey(lang)))).map(
      lang => lang.value
    )
  }
)
