// @flow
import * as React from 'react'

import { colors } from 'components/styled/tokens'

export const DisableQuietHours = (props: { ... }): React.Node => (
  <svg
    {...props}
    width="15"
    height="14"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.250023 3C0.250023 2.58579 0.58581 2.25 1.00002 2.25H6.58067C6.88518 2.25 7.15946 2.43412 7.27479 2.71594C7.39013 2.99776 7.32361 3.32134 7.10647 3.53482L5.03294 5.57337C4.45164 5.10635 3.64134 5.06501 3.0195 5.44933L4.748 3.75H1.00002C0.58581 3.75 0.250023 3.41421 0.250023 3ZM2.46466 5.99481L0.474224 7.95166C0.25708 8.16514 0.190567 8.48873 0.305899 8.77055C0.421232 9.05237 0.695515 9.23649 1.00002 9.23649H3.76101L2.70104 8.17653C2.1085 7.58399 2.02971 6.67224 2.46466 5.99481ZM2.73224 11.7905H2.16136C1.74715 11.7905 1.41136 12.1263 1.41136 12.5405C1.41136 12.9547 1.74715 13.2905 2.16136 13.2905H2.20375C2.13459 12.7691 2.30035 12.2224 2.70104 11.8217L2.73224 11.7905ZM3.2081 14.65L1.59498 16.5083C1.40242 16.7302 1.35709 17.044 1.479 17.3112C1.60091 17.5785 1.86762 17.75 2.16136 17.75H6.03233C6.44654 17.75 6.78233 17.4142 6.78233 17C6.78233 16.5858 6.44654 16.25 6.03233 16.25H3.80553L6.59871 13.0322C6.68071 12.9377 6.736 12.8266 6.76306 12.7095L5.17591 14.2966C4.64378 14.8287 3.85421 14.9465 3.2081 14.65ZM11.7933 13.2905H14C14.4142 13.2905 14.75 12.9547 14.75 12.5405C14.75 12.1263 14.4142 11.7905 14 11.7905H11.8822L14.5425 9.00442C14.7498 8.7873 14.8076 8.46748 14.6896 8.19148C14.5715 7.91548 14.3002 7.73648 14 7.73648H11.6169C11.5357 7.89503 11.4287 8.04379 11.296 8.17653L10.2361 9.23648H12.2469L10.5197 11.0454L11.296 11.8217C11.6967 12.2224 11.8625 12.7691 11.7933 13.2905Z"
      strokeWidth="0"
      fill={colors.textDisabled}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46881 6.40876C4.17591 6.11586 3.70104 6.11586 3.40815 6.40876C3.11525 6.70165 3.11525 7.17653 3.40815 7.46942L5.93786 9.99912L3.40815 12.5288C3.11525 12.8217 3.11525 13.2966 3.40815 13.5895C3.70104 13.8824 4.17591 13.8824 4.46881 13.5895L6.99853 11.0598L9.52825 13.5895C9.82114 13.8824 10.296 13.8824 10.5889 13.5895C10.8818 13.2966 10.8818 12.8217 10.5889 12.5288L8.05919 9.99912L10.5889 7.46942C10.8818 7.17653 10.8818 6.70165 10.5889 6.40876C10.296 6.11586 9.82114 6.11586 9.52825 6.40876L6.99853 8.93847L4.46881 6.40876Z"
      strokeWidth="0"
      fill={colors.textDisabled}
    />
  </svg>
)
