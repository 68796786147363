// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type ProfileStateRecord,
  type ProfileDataRecord,
  type InstallRecord,
  ProfileDataFactory,
} from 'com.batch/profile/models/profile.records'

type extract<T> = State => T

export const profileSelector = (state: State): ProfileStateRecord => {
  return state.profile
}

export const profileLoadingStateSelector: extract<fetchingState> = createSelector(
  profileSelector,
  profileState => profileState.loadingState
)

export const profileDataStateSelector: extract<ProfileDataRecord> = createSelector(
  profileSelector,
  profileState => profileState.data.get(profileState.profileId) ?? ProfileDataFactory()
)

export const eventsLoadingStateSelector: extract<fetchingState> = createSelector(
  profileDataStateSelector,
  profileDataState => profileDataState.eventsLoadingState
)

export const nbProfilesSelector: extract<number> = createSelector(
  profileSelector,
  profileState => profileState.data.size
)

export const profileIndexSelector: extract<number> = createSelector(profileSelector, profileState =>
  profileState.data.keySeq().indexOf(profileState.profileId)
)

export const lastVisitInstallByPlatformSelector: extract<Map<string, InstallRecord>> =
  createSelector(profileDataStateSelector, profileDataState =>
    profileDataState.installs
      .reduce((acc, current) => {
        const accCurrentPlatform = acc.get(current.platform)
        if (
          !accCurrentPlatform ||
          dayjs(accCurrentPlatform.lastActivity).isBefore(dayjs(current.lastActivity))
        )
          return acc.set(current.platform, current)
        return acc
      }, Immutable.Map())
      .reduce((acc, current) => acc.set(current.installID, current), Immutable.Map())
  )
