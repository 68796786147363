// @flow

import { type Dayjs } from 'dayjs'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  DebugDevice,
  PreviewDevice,
  PreviewDevicePadding,
  PreviewContent,
  StatusBar,
  AndroidSvg,
  StatusBarOperator,
  AndroidTime,
  AndroidBattery,
  CommandCenter,
  CommandCenterTitle,
  CommandCenterBar,
  CommandCenterButton,
  CommandCenterHandle,
  StatusBarMain,
  StatusBarBattery,
  OpBar,
} from './preview.styles'
import * as themes from './preview.theme'

import { type AppRecord } from 'com.batch.redux/_records'

type DefaultProps = {
  mode: 'light' | 'dark',
  hideStatus: boolean,
  time: Dayjs,
  webviewBackground: boolean,
  webPreviewMode: string,
}
type PreviewContainerProps = {
  app: AppRecord,
  previewMode: 'normal' | 'expanded' | 'landing',
  lightStatusBar: boolean,
  children: React.Node,
}
type PreviewContainerState = {
  charging: boolean,
  level: number,
  ...
}

export class PreviewContainer extends React.PureComponent<
  React.Config<{ ...PreviewContainerProps, ...DefaultProps }, DefaultProps>,
  PreviewContainerState,
> {
  mounted: boolean
  static defaultProps: DefaultProps = {
    mode: 'light',
    webPreviewMode: 'chrome-win',
    webviewBackground: false,
    hideStatus: false,
    time: dayjs(),
  }
  constructor(props: PreviewContainerProps) {
    super(props)
    this.state = { level: 0.7, charging: false }
  }
  componentDidMount() {
    this.mounted = true
    if (typeof navigator.getBattery === 'function') {
      navigator.getBattery().then(battery => {
        if (this.mounted) this.setState({ charging: battery.charging, level: battery.level })
        battery.onchargingchange = () => {
          if (this.mounted) this.setState({ charging: battery.charging })
        }
        battery.onlevelchange = () => {
          if (this.mounted) this.setState({ level: battery.level })
        }
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    if (typeof navigator.getBattery === 'function') {
      navigator.getBattery().then(battery => {
        battery.onchargingchange = null
        battery.onlevelchange = null
      })
    }
  }

  render(): React.Node {
    const expanded = this.props.previewMode === 'expanded'
    const mode = this.props.mode
    let theme =
      this.props.app.platform === 'ios' ||
      this.props.app.platform === 'android' ||
      this.props.app.platform === 'windows' ||
      this.props.app.platform === 'webpush'
        ? { ...themes[this.props.app.platform], expanded, mode }
        : { expanded: expanded }
    if (this.props.webPreviewMode === 'android') {
      theme = { ...themes.android, expanded: expanded, mode }
    }
    const contentStyle = this.props.webviewBackground
      ? {
          backgroundImage: 'url(/medias/img/notif-preview/legacy/sample_app_background.png)',
          backgroundSize: 'cover',
        }
      : {}
    return (
      <ThemeProvider theme={theme}>
        <DebugDevice>
          <PreviewDevice />
          {this.props.app.platform === 'ios' && !this.props.hideStatus && (
            <StatusBar light={this.props.lightStatusBar}>
              <StatusBarOperator>
                <OpBar num={1} />
                <OpBar num={2} />
                <OpBar num={3} />
                <OpBar num={4} off />
              </StatusBarOperator>
              <StatusBarMain>
                {expanded ? this.props.time?.format('HH:mm') ?? '' : ''}
              </StatusBarMain>
              <StatusBarBattery
                level={this.state.level}
                charging={this.state.charging}
                light={this.props.lightStatusBar}
              >
                <div />
              </StatusBarBattery>
            </StatusBar>
          )}
          {(this.props.app.platform === 'android' || this.props.webPreviewMode === 'android') &&
            !this.props.hideStatus && (
              <StatusBar light={this.props.lightStatusBar}>
                {expanded && <AndroidTime>{this.props.time?.format('HH:mm')}</AndroidTime>}
                <StatusBarMain />
                {!expanded && (
                  <div>
                    <AndroidSvg
                      width="12"
                      height="12"
                      style={{ marginRight: '8px', paddingTop: '1px' }}
                    >
                      <path d="M0 3 L6 11 L12 3 C9 0, 3 0, 0 3" />
                    </AndroidSvg>
                  </div>
                )}
                <div>
                  <AndroidBattery />
                </div>
                <div>{Math.floor(this.state.level * 100)}&nbsp;%</div>
              </StatusBar>
            )}
          {(this.props.app.platform === 'android' || this.props.webPreviewMode === 'android') &&
            expanded && (
              <CommandCenter>
                <CommandCenterTitle>
                  <div>{this.props.time?.format('ddd. DD MMM.')}</div>
                  <span>
                    <AndroidSvg width="12" height="12">
                      <path d="M0 3 L6 11 L12 3 C9 0, 3 0, 0 3" />
                    </AndroidSvg>
                  </span>
                </CommandCenterTitle>
                <CommandCenterBar>
                  <CommandCenterButton active>
                    <AndroidSvg width="18" height="16">
                      <path d="M0 5 L9 16 L18 5 C14 0, 4 0, 0 5" />
                    </AndroidSvg>
                  </CommandCenterButton>
                  <CommandCenterButton disabled />
                  <CommandCenterButton />
                  <CommandCenterButton />
                  <CommandCenterButton />
                  <CommandCenterButton />
                </CommandCenterBar>
                <CommandCenterHandle />
              </CommandCenter>
            )}
          <PreviewDevicePadding>
            <PreviewContent style={contentStyle}>{this.props.children}</PreviewContent>
          </PreviewDevicePadding>
        </DebugDevice>
      </ThemeProvider>
    )
  }
}
