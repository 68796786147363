// @flow
import * as React from 'react'

import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'components/common/tooltip'

import { type BatchChoice } from 'com.batch.redux/_records'

type ChoiceProps = {
  onChange: (value: string) => any,
  label?: string,
  hint?: string,
  value: ?string,
  choices: Array<BatchChoice>,
  lock?: false | string,
  empty?: false | string,
  ...
}

let id = 0
class Choice extends React.PureComponent<ChoiceProps> {
  constructor(props: ChoiceProps) {
    super(props)
    id++
  }
  render(): React.Node {
    const { label, hint, choices } = this.props
    return (
      <div className="form-group">
        {label && (
          <label htmlFor={'-tt' + id} style={{ paddingBottom: 5 }}>
            {label}
            {hint && <Hint minTooltipWidth={240}>{hint}</Hint>}
          </label>
        )}
        <TableToggle>
          {choices.map(opt => {
            return (
              <TableToggleItem
                key={opt.label}
                col={!!opt.icon}
                disabled={!!opt.lock || !!opt.empty}
                onClick={() => this.props.onChange(opt.value)}
                active={this.props.value === opt.value}
              >
                {opt.icon && (
                  <Tooltip
                    tooltip={opt.empty ? opt.empty : false}
                    placement="right"
                    minWidth={300}
                    isTooltipEmpty={Boolean(!opt.empty)}
                  >
                    <Icon
                      // $FlowFixMe
                      icon={opt.icon}
                      size={16}
                      style={{ display: 'block', margin: '8px auto 14px auto' }}
                    />
                  </Tooltip>
                )}
                <span style={{ display: 'inline-flex' }}>
                  {opt.label}
                  {!!opt.lock && (
                    <Tooltip tooltip={opt.lock} placement="right" minWidth={300}>
                      <div>
                        <Icon size={8} icon="lock" style={{ marginLeft: 5, marginTop: 3 }} />
                      </div>
                    </Tooltip>
                  )}
                </span>
              </TableToggleItem>
            )
          })}
        </TableToggle>
      </div>
    )
  }
}

export default Choice
