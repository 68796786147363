// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { Box, BoxHeader, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
// eslint-disable-next-line import/no-cycle
import { Icon } from 'components/common/svg-icon'

import { validateUrl, capitalize, textUsesTemplating, percentage } from 'com.batch.common/utils'

import { Form } from './../../form'
import { InputWrapper } from './../../input-wrapper'
import { ValidatedInput } from './../validated-input'
import { type MediaUrlValueProps, type MediaUrlMetaProps } from './media-url'
import { SectionFormat, SectionHosting, SectionDynamicUrl } from './media-url-sections'
import { MediaUrlPopupBody, MediaUrlPopupFooter, MediaUrlWarning } from './media-url.styles'

import { TabButton } from '../tab-button'
import { TabButtonItem } from '../tab-button-item'
import { sdkMatchingTargetVersionSelector } from 'com.batch.redux/sdk.selector'

const hasSplitChar = (txt: string) =>
  txt.indexOf('?') != -1 || txt.indexOf('=') != -1 || txt.indexOf('&') != -1

/*
  Android SDK API lvl < 33 has a bug where they split urls params using ? = & and re-encode each part
  this function should detect affected urls
*/
const urlAffectedByBug = (txt: string) => {
  let issue = false
  try {
    const url = new URL(txt)
    const qs = url.searchParams
    if (txt.indexOf('?') !== -1) {
      const [base, ...rest] = txt.split('?')
      const rebuild =
        base +
        '?' +
        rest
          .map(part =>
            part
              .split('&')
              .map(part => part.split('=').map(encodeURI).join('='))

              .join('&')
          )
          .join('?')
      if (rebuild !== url.href && rebuild !== url.href.replace(/\/\?/, '?')) issue = true
    }
    for (let pair of qs.entries()) {
      if (hasSplitChar(pair[0]) || hasSplitChar(pair[1])) issue = true
    }
  } catch (_) {}
  return issue
}

type MediaUrlPopinProps = {
  allowedMedia: Array<'image' | 'audio' | 'video'>,
  onChange: (MediaUrlValueProps, MediaUrlMetaProps) => any,
  platforms: Array<string>,
  showTemplateWizard: boolean,
  imageRecommandation?: {
    message: React.Node,
    docLinkText: string,
    docLinkUrl: string,
    ...
  },
  close: () => any,
  ...
}

// ====================== MEDIA URL POPIN COMPONENT
export const MediaUrlPopin = ({
  allowedMedia,
  close,
  platforms,
  showTemplateWizard,
  imageRecommandation,
  onChange,
}: MediaUrlPopinProps): React.Node => {
  // ====================== Redux state
  const getMatchingTargetVersion = useSelector(sdkMatchingTargetVersionSelector)

  // ====================== Component state
  const [mediaKind, setMediaKind] = React.useState<'image' | 'audio' | 'video'>('image')
  const [mediaUrl, setMediaUrl] = React.useState<string>('')
  const [mediaWidth, setMediaWidth] = React.useState<number>(0)
  const [mediaHeight, setMediaHeight] = React.useState<number>(0)

  // ====================== Component constants
  const extensionFile = mediaKind === 'image' ? 'jpg' : mediaKind === 'audio' ? 'mp3' : 'mp4'
  const ratioAffected = 1 - getMatchingTargetVersion(33)
  const hasTemplate = textUsesTemplating(mediaUrl)
  const showWarningMessage =
    platforms.includes('android') &&
    ratioAffected > 0.3 &&
    (hasTemplate || (validateUrl(mediaUrl) && urlAffectedByBug(mediaUrl)))

  // ====================== Callbacks
  const handleSubmit = () => {
    let error: boolean = false
    if (!validateUrl(mediaUrl) && !hasTemplate) error = true

    if (!error) {
      let newValue = { mediaKind, mediaUrl }
      let meta = { mediaSize: 0, mediaWidth, mediaHeight }
      onChange(newValue, meta)
    }
  }

  // ====================== Render
  return (
    <Box style={{ width: 800, border: 0 }}>
      <Form onSubmit={handleSubmit}>
        <BoxHeader>
          <HeaderBoxTitle title="Add media from URL" />
          <HeaderBoxActions>
            <Button onClick={close} type="button">
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <MediaUrlPopupBody>
          <div>
            {allowedMedia.length > 1 && (
              <InputWrapper label="Media type">
                <TabButton>
                  {allowedMedia.map((kind, i) => {
                    return (
                      <TabButtonItem
                        key={i}
                        style={{ textTransform: 'capitalize' }}
                        isActive={mediaKind === kind}
                        onClick={() => setMediaKind(kind)}
                      >
                        {kind}
                      </TabButtonItem>
                    )
                  })}
                </TabButton>
              </InputWrapper>
            )}
            <InputWrapper label={`${capitalize(mediaKind)} URL`}>
              <ValidatedInput
                type={showTemplateWizard ? 'template' : 'text'}
                name="media-url"
                disabled={false}
                placeholder={`https://domain.tld/image.${extensionFile}`}
                value={mediaUrl}
                onChange={url => {
                  if (validateUrl(url)) {
                    var img = new Image()
                    img.src = url
                    img.onload = function (this: { width: number, height: number, ... }) {
                      setMediaWidth(this.width)
                      setMediaHeight(this.height)
                    }
                    img.onerror = function () {
                      setMediaWidth(0)
                      setMediaHeight(0)
                    }
                  } else {
                    setMediaWidth(0)
                    setMediaHeight(0)
                  }
                  setMediaUrl(url)
                }}
                touched={false}
                valid={false}
              />
            </InputWrapper>
            {showWarningMessage && (
              <MediaUrlWarning>
                This URL {hasTemplate ? 'may contain' : 'contains'} some characters that will not
                work on {percentage(ratioAffected, 0)} of your Android users devices. Update Batch
                SDK to fix this issue.
              </MediaUrlWarning>
            )}
          </div>

          <div>
            <SectionFormat
              kind={mediaKind}
              isDynamic={textUsesTemplating(mediaUrl)}
              imageRecommandation={imageRecommandation}
            />
            <SectionHosting kind={mediaKind} isDynamic={textUsesTemplating(mediaUrl)} />
            {showTemplateWizard && <SectionDynamicUrl />}
          </div>
        </MediaUrlPopupBody>

        <MediaUrlPopupFooter>
          <Button type="button" kind="inline" onClick={close}>
            Cancel
          </Button>
          <Button
            type="submit"
            kind="primary"
            intent="action"
            disabled={!validateUrl(mediaUrl) && !hasTemplate}
          >
            Add this media
          </Button>
        </MediaUrlPopupFooter>
      </Form>
    </Box>
  )
}
