// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  useIsCurrentUserAllowedTo,
  useToggle,
  useCurrentCompanyHasOneFeatureAmongst,
} from 'components/_hooks'
import { BoxBody } from 'components/common/box'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'

import { GCMConfigFactory, type GCMConfigRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

import { type FCMConfigRecord } from 'com.batch/settings/models/fcm-config.records'
import { AddAnotherBtn } from 'com.batch/settings/ui/components/settings-fcm/add-another-btn/add-another-btn'
import { AddBlock } from 'com.batch/settings/ui/components/settings-fcm/add-block/add-block'
import { ConfigGrid } from 'com.batch/settings/ui/components/settings-fcm/config-grid/config-grid'
import { ConfigGridContainer } from 'com.batch/settings/ui/components/settings-fcm/config-grid/config-grid.styles'
import { LegacyConfigGrid } from 'com.batch/settings/ui/components/settings-fcm/config-grid/legacy-config-grid'
import { LegacyBanner } from 'com.batch/settings/ui/components/settings-fcm/legacy-banner/legacy-banner'
import { LegacyBlock } from 'com.batch/settings/ui/components/settings-fcm/legacy-block/legacy-block'
import { InitialNotice } from 'com.batch/settings/ui/components/settings-fcm/notice/initial-notice'
import { Notice } from 'com.batch/settings/ui/components/settings-fcm/notice/notice'
import { ServiceAccountKeyDropzone } from 'com.batch/settings/ui/components/settings-fcm/service-account-key-dropzone/service-account-key-dropzone'
import { SettingsAndroidFcmFooter } from 'com.batch/settings/ui/components/settings-fcm/settings-android-fcm-footer'
import { ConfigsWrapper } from 'com.batch/settings/ui/components/settings-fcm/settings-android-fcm.styles'
import { savePushSettings } from 'com.batch/settings/usecases/save-push-settings'

export const SettingsAndroidFcm = (): React.Node => {
  const dispatch = useDispatch()
  const { pushConfig } = useSelector(currentAppSelector)
  const isAllowedToChangeFCMConfig = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
  const editToggleState = useToggle(false)

  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const cepCompanyCanUseLegacyPush = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-trigger',
    'cep-show-legacy-recurring',
    'cep-show-legacy-campaign',
  ])

  const hasLegacyPush = React.useMemo(() => {
    return cepCompanyCanUseLegacyPush || !maybeProject
  }, [cepCompanyCanUseLegacyPush, maybeProject])

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [nbDeletion, setNbDeletion] = React.useState<number>(0)
  const [isDropzoneShown, setIsDropzoneShown] = React.useState<boolean>(true)
  const [configs, setConfigs] = React.useState<List<FCMConfigRecord>>(
    pushConfig.fcm.size === 0 ? new Immutable.List() : pushConfig.fcm
  )
  const [legacyConfigs] = React.useState<List<GCMConfigRecord>>(
    pushConfig.gcm.size === 0 ? new Immutable.List().push(GCMConfigFactory()) : pushConfig.gcm
  )

  const hasConfigs = React.useMemo(() => pushConfig.fcm.size > 0, [pushConfig.fcm])
  const hasLegacyConfigs = React.useMemo(() => pushConfig.gcm.size > 0, [pushConfig.gcm])
  const isNewConfigRequired = React.useMemo(
    () => !hasConfigs && hasLegacyConfigs,
    [hasLegacyConfigs, hasConfigs]
  )
  const isLegacyConfigShown = React.useMemo(
    () => hasLegacyConfigs && hasConfigs && !editToggleState.value && hasLegacyPush,
    [hasLegacyConfigs, hasConfigs, editToggleState.value, hasLegacyPush]
  )
  const isAddBlockShown = React.useMemo(
    () => !hasLegacyConfigs && !hasConfigs && !editToggleState.value,
    [editToggleState.value, hasConfigs, hasLegacyConfigs]
  )
  const existingPrivateKeyIds = React.useMemo(() => {
    return pushConfig.fcm.map(fcm => fcm.serviceAccountKey.privateKeyId)
  }, [pushConfig.fcm])

  const handleOnCancel = React.useCallback(() => {
    editToggleState.close()
    setConfigs(pushConfig.fcm)
    setNbDeletion(0)
  }, [editToggleState, pushConfig.fcm])

  const onAddNewConfig = React.useCallback(
    (config: FCMConfigRecord) => {
      setConfigs(configs.push(config))
      setIsDropzoneShown(false)
    },
    [configs]
  )

  const save = React.useCallback(() => {
    dispatch(savePushSettings(pushConfig.set('fcm', configs)))
      .catch(() => {
        setConfigs(pushConfig.fcm)
      })
      .finally(() => {
        editToggleState.close()
        setIsLoading(false)
        setNbDeletion(0)
      })
  }, [pushConfig, configs, editToggleState, dispatch])

  const handleOnSave = React.useCallback(() => {
    setIsLoading(true)
    save()
  }, [save])

  const handleOnClickAddNewConfig = React.useCallback(() => {
    setIsDropzoneShown(true)
  }, [])

  const handleOnClickRemoveConfig = React.useCallback(
    (index: number, privateKeyId: string) => () => {
      setConfigs(configs.delete(index))
      if (existingPrivateKeyIds.includes(privateKeyId)) {
        setNbDeletion(nb => nb + 1)
      }
    },
    [configs, existingPrivateKeyIds]
  )

  const openConfirmEdit = React.useCallback(() => {
    confirm({
      width: 490,
      message: (
        <article>
          <p>
            If you're replacing an old push configuration, please make sure to use the same FCM
            project ID to be able to target previously collected push tokens.
          </p>
        </article>
      ),
      title: 'Confirm push configuration change',
    }).then(
      () => {
        editToggleState.open()
        setIsDropzoneShown(true)
      },
      () => {}
    )
  }, [editToggleState])

  return (
    <React.Fragment>
      <BoxBody>
        {isNewConfigRequired && !editToggleState.value && (
          <LegacyBanner onClick={openConfirmEdit} isAllowed={isAllowedToChangeFCMConfig} />
        )}
        {hasLegacyConfigs && !hasConfigs && !editToggleState.value ? <InitialNotice /> : <Notice />}
        {isAddBlockShown && (
          <AddBlock onClick={editToggleState.open} isAllowed={isAllowedToChangeFCMConfig} />
        )}

        {hasLegacyConfigs && !hasConfigs && !editToggleState.value && (
          <Grid
            alignItems="start"
            template="fit-content(33.33%) 0px 1fr"
            gap={16}
            style={{ margin: '0 20px 4px 20px' }}
          >
            {legacyConfigs.map((conf, index) => (
              <LegacyConfigGrid conf={conf} index={index} key={index} />
            ))}
          </Grid>
        )}

        <ConfigsWrapper>
          {configs.size > 0 && (
            <ConfigGridContainer template="fit-content(33.33%) fit-content(33.33%) 1fr" gap={32}>
              {configs.map(({ serviceAccountKey }, index) => (
                <React.Fragment key={index}>
                  <ConfigGrid
                    index={index}
                    serviceAccountKey={serviceAccountKey}
                    onRemoveConfig={handleOnClickRemoveConfig}
                    isEditing={editToggleState.value}
                    isDeletable={configs.size > 1 && editToggleState.value}
                    isLoading={isLoading}
                  />
                  <div className="separator"></div>
                </React.Fragment>
              ))}
            </ConfigGridContainer>
          )}
          {editToggleState.value && (
            <React.Fragment>
              {isDropzoneShown && (
                <ServiceAccountKeyDropzone
                  configs={configs}
                  addNewConfig={onAddNewConfig}
                  isDisabled={isLoading}
                  onLoading={setIsLoading}
                />
              )}
              <AddAnotherBtn isDisabled={isDropzoneShown} onClick={handleOnClickAddNewConfig} />
            </React.Fragment>
          )}
          {isLegacyConfigShown && <LegacyBlock configs={legacyConfigs} />}
        </ConfigsWrapper>
      </BoxBody>
      <SettingsAndroidFcmFooter
        isEditing={editToggleState.value}
        cancel={handleOnCancel}
        save={handleOnSave}
        openConfirmEdit={openConfirmEdit}
        showUpdateBtn={(hasConfigs || hasLegacyConfigs) && !editToggleState.value}
        isAllowed={isAllowedToChangeFCMConfig}
        isDisabled={isNewConfigRequired}
        isLoading={isLoading}
        nbDeletion={nbDeletion}
      />
    </React.Fragment>
  )
}
