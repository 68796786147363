// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { Wrapper } from 'components/common/empty-states'
import { Logical } from 'components/query/logical'

import { QueryBuilderContext } from './query-builder.context'

import { Button } from '../common/button/button.styles'
import { Icon } from '../common/svg-icon'
import { type State } from 'com.batch.redux/_records'
import { currentCompanySelector } from 'com.batch.redux/company'
import { resetQuery } from 'com.batch.redux/query/query'
import { QueryFactory } from 'com.batch.redux/query/query.records'
import {
  availableAttributesSelector,
  profileTargetingAttributesSelector,
} from 'com.batch.redux/query/query.selector'

type QueryBuilderProps = {
  errors: Set<string>,
  eventId?: string,
  queryId: string,
  hideQueryViewer?: boolean,
  isProfileMode: boolean,
}
const theme = { kind: 'capture', isEventFilter: false }
const emptyfunc = () => {}
const queryStateSelector = (state: State) => state.query

export const QueryBuilder = ({
  queryId,
  errors,
  eventId,
  isProfileMode,
  hideQueryViewer,
}: QueryBuilderProps): React.Node => {
  // ================= callbacks

  const queries = useSelector(queryStateSelector)
  const [conditions, tree, error] = React.useMemo(() => {
    const query = queries.get(queryId, QueryFactory())
    return [query.conditions, query.tree]
  }, [queries, queryId])

  // ================= redux
  const dispatch = useDispatch()
  const allAttributes = useSelector(availableAttributesSelector)
  const profileUsableAttributes = useSelector(profileTargetingAttributesSelector)
  const company = useSelector(currentCompanySelector)

  // ================= derived
  const attributes = React.useMemo(
    () => (isProfileMode ? profileUsableAttributes : allAttributes),
    [isProfileMode, profileUsableAttributes, allAttributes]
  )
  const providerProps = React.useMemo(
    () => ({
      queryId,
      context: 'targeting',
      isSample: company.id === 79,
      touched: true,
      eventId,
      errors,
      isProfileMode: isProfileMode,
    }),
    [eventId, errors, queryId, isProfileMode, company.id]
  )
  const removeQuery = React.useCallback(() => {
    dispatch(resetQuery({ queryId, query: QueryFactory() }))
  }, [queryId, dispatch])

  return (
    <ThemeProvider theme={theme}>
      <QueryBuilderContext.Provider value={providerProps}>
        <Wrapper
          isOverlayShown={Boolean(error)}
          isLoading={false}
          isEmpty={false}
          style={error ? { minHeight: 200 } : {}}
          overlayProps={{
            title: 'Unable to parse advanced segmentation',
            description: error,
            status: 'error',
            content: (
              <Button addOn="prefix" kind="secondary" intent="danger" onClick={removeQuery}>
                <Icon icon="delete" />
                Remove targeting
              </Button>
            ),
          }}
        >
          <Logical
            node={tree}
            isProfile={isProfileMode}
            parentIsLogicalNot={false}
            conditions={conditions}
            attributes={attributes}
            setTouched={emptyfunc}
            level={1}
            position="root"
            queryId={queryId}
            hideQueryViewer={hideQueryViewer}
          />
        </Wrapper>
      </QueryBuilderContext.Provider>
    </ThemeProvider>
  )
}
