// @flow
import { Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { type InstallRecord } from 'com.batch/profile/models/profile.records'
import { ProfileHeroFooterItem } from 'com.batch/profile/ui/components/profile-hero/profile-hero-footer-item'
import {
  ProfileFooterContainer,
  Reachability,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { ProfilePlatformsCounter } from 'com.batch/profile/ui/components/profile-hero/profile-platforms-counter'

type Props = {
  isBodyShown: boolean,
  onToggle: (isToggled: boolean) => void,
  email: ?string,
  phoneNumber: ?string,
  installs: Map<string, InstallRecord>,
  ...
}

export const ProfileHeroFooter = ({
  onToggle,
  isBodyShown,
  email,
  phoneNumber,
  installs,
}: Props): React.Node => {
  const handleOnClickToggle = React.useCallback(() => {
    onToggle(!isBodyShown)
  }, [isBodyShown, onToggle])
  return (
    <ProfileFooterContainer isBodyShown={isBodyShown} role="button" onClick={handleOnClickToggle}>
      {!isBodyShown && (
        <Reachability>
          <Skeleton w={130} h={19}>
            <ProfileHeroFooterItem
              item={installs.size > 0 ? <ProfilePlatformsCounter installs={installs} /> : null}
              emptyMessage="No platform"
            />
          </Skeleton>

          <Skeleton w={108} h={19}>
            <ProfileHeroFooterItem
              icon="mail-rounded"
              item={email}
              emptyMessage="No email address"
            />
          </Skeleton>

          <Skeleton w={108} h={19}>
            <ProfileHeroFooterItem icon="sms" item={phoneNumber} emptyMessage="No phone number" />
          </Skeleton>
        </Reachability>
      )}
      <Button kind="discreet">
        {isBodyShown ? (
          <Skeleton w={105} h={19}>
            Less details <Icon icon="chevron-up" />
          </Skeleton>
        ) : (
          <Skeleton w={105} h={19}>
            <React.Fragment>
              More details <Icon icon="chevron-down" />
            </React.Fragment>
          </Skeleton>
        )}
      </Button>
    </ProfileFooterContainer>
  )
}
