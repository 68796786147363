// @flow
import * as React from 'react'

import {
  NotificationContent,
  NotificationIcon,
  NotificationMessage,
  NotificationTitle,
  Notification,
  PushPreviewContainer,
  NotificationMedia,
  NotificationBody,
} from 'com.batch/orchestration-journey/ui/components/nodes/push-preview.styles'

export type PushPreviewProps = {
  appIconUrl: string,
  title?: string,
  body?: string,
  mediaThumbnailUrl?: string,
}

export const PushPreview = ({
  appIconUrl,
  title,
  body,
  mediaThumbnailUrl,
}: PushPreviewProps): React.Node => (
  <PushPreviewContainer>
    <Notification>
      <NotificationIcon src={appIconUrl} alt="" as={appIconUrl ? 'img' : 'div'} />
      <NotificationContent hasMedia={!!mediaThumbnailUrl}>
        <NotificationTitle isPlaceholder={!title}>{title || 'Empty title...'}</NotificationTitle>
        <NotificationBody $hasMedia={Boolean(mediaThumbnailUrl)}>
          <NotificationMessage isPlaceholder={!body}>
            {body || 'Empty message...'}
          </NotificationMessage>
        </NotificationBody>
      </NotificationContent>
      {mediaThumbnailUrl && <NotificationMedia src={mediaThumbnailUrl} alt="media-thumbnail" />}
    </Notification>
  </PushPreviewContainer>
)
