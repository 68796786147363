// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { GlobalErrorIcon } from './empty-states.icons'
import { EmptyField } from './empty-states.styles'
import { type OverlayProps } from './overlay'

const reloadWindow = () => window.location.reload()
export const GlobalErrorOverlayProps: OverlayProps = {
  title: 'Couldn’t load data',
  status: 'empty-page',
  description:
    'There is an error during the loading of this page, try to refresh or contact the support if this continue to happen.',
  content: <GlobalErrorIcon />,
  refresh: reloadWindow,
}

type SkeletonProps = {
  w: number,
  h: number,
  display?: 'inline' | 'block' | 'inline-block',
  style?: any,
  children?: React.Node,
  ...
}
export const Skeleton = ({ w, h, children, display, style }: SkeletonProps): React.Node => {
  const theme = useTheme()

  if (!theme.isEmpty && !theme.isLoading) {
    return children
  }
  return <EmptyField _width={w} _height={h} _display={display} style={style} />
}
