// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useClickOutsideOnExistingRefs } from 'components/_hooks'

import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selectors'
import { pushSettingsSelector } from 'com.batch.redux/content.selector'

import { MediaOverlay, OverlayIcon } from 'com.batch/message-builder/ui/components/common'
import { InfoPanelPortaled } from 'com.batch/message-builder/ui/components/info-panel'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

type MediaFieldProps = {
  field: 'media' | 'icon',
  variant: 'a' | 'b',
  children: ({
    url: string,
    overlay: React.Node,
    kind: 'image' | 'audio' | 'video',
    ...
  }) => React.Node,
  ...
}

export const LegacyMediaField: React$AbstractComponent<MediaFieldProps, *> = React.memo(
  ({ field, children, variant }: MediaFieldProps): React.Node => {
    const settings = useSelector(pushSettingsSelector)
    const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
    const dispatch = useDispatch()
    const setEditing = React.useCallback(
      (field: null | 'image' | 'icon') => {
        let newConfig = inlineEditorConfig.set('field', field).set('variant', variant)
        dispatch(setInlineEditor(newConfig))
      },
      [dispatch, inlineEditorConfig, variant]
    )
    const overlay = React.useMemo(() => {
      return (
        <OverlayWithHooks isImage={field === 'media'} variant={variant} setEditing={setEditing} />
      )
    }, [field, setEditing, variant])
    return children(
      field === 'media'
        ? { kind: settings.attachmentKind, url: settings.attachmentUrl, overlay }
        : { kind: 'image', url: settings.iconUrl, overlay }
    )
  }
)

type OverlayWithHooksProps = {
  isImage: boolean,
  variant: 'a' | 'b',
  setEditing: (null | 'image' | 'icon') => void,
  ...
}

const OverlayWithHooks = ({ isImage, variant, setEditing }: OverlayWithHooksProps): React.Node => {
  const [panelOpen, setPanelOpen] = React.useState(false)
  const popperRef = React.useRef(null)
  const triggerRef = React.useRef(null)
  const openPanel = React.useCallback(() => {
    setPanelOpen(true)
    setEditing(isImage ? 'image' : 'icon')
  }, [isImage, setEditing])
  const closePanel = React.useCallback(() => {
    setPanelOpen(false)
    setEditing(null)
  }, [setEditing])
  useClickOutsideOnExistingRefs(() => setPanelOpen(false), [popperRef, triggerRef])
  return (
    <MediaOverlay ref={triggerRef} $hover={panelOpen} onClick={openPanel}>
      <OverlayIcon />
      {panelOpen && (
        <InfoPanelPortaled
          messageId="default"
          ref={popperRef}
          variant={variant}
          canUseTemplating
          field={isImage ? 'image' : 'icon'}
          clearEditing={closePanel}
          value=""
        />
      )}
    </MediaOverlay>
  )
}
