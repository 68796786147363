// @flow

import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { fetchTemplates } from 'com.batch/message/usecases/fetch-templates'

export type UpdateEmailTemplateAction = {
  type: 'UPDATE_EMAIL_TEMPLATE',
  payload: {
    messageId: string,
    lang: string,
    html?: string,
    subject?: string,
  },
}
export const updateEmailTemplate = ({
  messageId,
  lang,
}: {
  messageId: string,
  lang: string,
}): DispatchBoundFn<UpdateEmailTemplateAction> => {
  return (dispatch, getState) => {
    const state = getState()
    const email = state.message.email.getIn([messageId, lang])

    const action: UpdateEmailTemplateAction = {
      type: 'UPDATE_EMAIL_TEMPLATE',
      payload: {
        messageId,
        lang,
      },
    }

    const contentsToTemplate = []

    if (textUsesTemplating(email?.html)) {
      contentsToTemplate.push({
        messageId,
        lang,
        field: 'html',
        value: email.html ?? '',
        type: 'HTML_TEMPLATE',
      })
    }

    if (textUsesTemplating(email?.subject)) {
      contentsToTemplate.push({
        messageId,
        lang,
        field: 'subject',
        value: email.subject ?? '',
        type: 'TEMPLATE',
      })
    }

    if (contentsToTemplate.length === 0) {
      return dispatch(action)
    }

    return dispatch(fetchTemplates(contentsToTemplate))
      .then(res => {
        res.forEach(({ field, template, value }) => {
          action.payload[field] = template ?? value
        })
        dispatch(action)
      })
      .catch(e => {
        console.error(
          'Error while fetching templates:\n',
          e.error?.errors?.[0]?.message ?? 'Unknown error'
        )
      })
  }
}
