// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import {
  AndroidAppName,
  AndroidBody,
  AndroidContainer,
  AndroidDateLabel,
  AndroidIcon,
  AndroidMessageContainer,
  AndroidTitle,
  ChromeIconContainer,
  Thumb,
} from './android-preview.styles'

import { type ThemeProps } from 'com.batch/message-builder/ui/components/common'
import { EditableField } from 'com.batch/message-builder/ui/components/editable-field'
import { MediaField } from 'com.batch/push/ui/media-field'

type Props = { messageId: string, ... }
export const AndroidPreview = ({ messageId }: Props): React.Node => {
  const { previewPlatform, isExpanded, app } = useTheme<ThemeProps>()
  return (
    <AndroidContainer>
      {previewPlatform === 'webAndroid' ? (
        <ChromeIconContainer>
          <img src="/medias/icons/chrome-colored.svg" />
        </ChromeIconContainer>
      ) : (
        <AndroidIcon />
      )}

      <AndroidAppName>
        {previewPlatform === 'webAndroid' ? 'Google Chrome' : app?.name}
      </AndroidAppName>
      <AndroidDateLabel>Now</AndroidDateLabel>

      <Icon icon="chevron-up" />

      <AndroidMessageContainer>
        <AndroidTitle>
          <EditableField field="pushTitle" messageId={messageId} />
        </AndroidTitle>
        <AndroidBody>
          <EditableField field="pushBody" messageId={messageId} />
        </AndroidBody>
      </AndroidMessageContainer>
      <MediaField messageId={messageId} field="icon">
        {iconProps => (
          <MediaField messageId={messageId} field="media">
            {({ url, overlay }) => (
              <Thumb $url={!isExpanded && iconProps.url ? iconProps.url : url}>
                {!isExpanded && iconProps.url ? iconProps.overlay : overlay}
              </Thumb>
            )}
          </MediaField>
        )}
      </MediaField>
    </AndroidContainer>
  )
}
