// @flow

import { type Dayjs } from 'dayjs'
import { type List } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { useClickOutsideOnExistingRefs, usePopper } from 'components/_hooks'
import { Icon } from 'components/common/svg-icon'
import { type dateShortcut } from 'components/form/fields/date-picker/date-picker-shortcuts'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { InputWrapper } from './../../input-wrapper'
import { InputContainer } from './../../styles'
import { DatePickerPopover } from './date-picker-popover'
import { DayPickerContainer } from './date-picker.styles'

import { type DateInputRecord, DateInputFactory } from 'com.batch.redux/query/query.records'

export type DatePickerProps = {
  shortcuts?: List<dateShortcut>,
  date: DateInputRecord,
  invalid?: boolean,
  hideFeedback?: boolean,
  invalid?: boolean,
  position?: 'left' | 'right',
  onChange: (date: DateInputRecord) => any,
  onBlur?: (date: DateInputRecord) => any,
  onFocus?: () => any,
  disabledDays?: (day: Date) => boolean,
  ...
}

// ====================== DATE PICKER COMPONENTS
export const DatePicker = ({
  shortcuts,
  disabledDays,
  invalid,
  hideFeedback,
  position,
  onChange,
  onBlur,
  date,
  ...rest
}: DatePickerProps): React.Node => {
  // ====================== Component state
  const [opened, setOpened] = React.useState<boolean>(false)

  // ====================== Component constants
  const datePikerProps: { disabledDays?: Date => boolean, ... } = React.useMemo(() => {
    return disabledDays ? { disabledDays: (day: Date) => disabledDays(day) } : {}
  }, [disabledDays])
  const close = React.useCallback(() => setOpened(false), [])
  // ====================== Component Ref
  const containerRef = React.useRef(null)
  const [inputRef, popperRef] = usePopper({
    modifiers: [
      { name: 'applyStyles' },
      { name: 'eventListeners' },
      {
        name: 'offset',
        options: {
          offset: [28, 4],
        },
      },
    ],
    strategy: 'fixed',
    placement: 'bottom',
  })
  const theme = useTheme()
  const clickOutsideRefs = React.useMemo(() => [containerRef, popperRef], [popperRef])
  useClickOutsideOnExistingRefs(close, clickOutsideRefs)
  // ====================== Callbacks
  const setDay = React.useCallback(
    (day: Dayjs) => {
      onChange(
        DateInputFactory({
          inputValue: day.format('DD/MM/YYYY').toString(),
          value: day,
          valid: true,
        })
      )
      setOpened(false)
      if (inputRef.current) inputRef.current.blur()
    },
    [onChange, inputRef]
  )

  const handleOnBlur = React.useCallback(
    e => {
      const dateIsValid =
        dayjs(e.target.value, 'DD/MM/YYYY', 'fr', true).isValid() &&
        (!disabledDays || !disabledDays(dayjs(e.target.value, 'DD/MM/YYYY', 'fr', true).toDate()))
      return (
        onBlur &&
        onBlur(
          DateInputFactory({
            inputValue: e.target.value,
            valid: dateIsValid,
            value: dateIsValid ? dayjs.utc(e.target.value, 'DD/MM/YYYY', 'fr', true) : null,
          })
        )
      )
    },
    [disabledDays, onBlur]
  )

  const handleChangeInput = React.useCallback(
    e => {
      const dateIsValid = dayjs(e.target.value, 'DD/MM/YYYY', 'fr', true).isValid()
      return onChange(
        DateInputFactory({
          inputValue: e.target.value,
          value: dateIsValid ? dayjs.utc(e.target.value, 'DD/MM/YYYY', 'fr', true) : null,
        })
      )
    },
    [onChange]
  )
  const toggleOpen = React.useCallback(
    open => () => {
      setOpened(open)
    },
    []
  )
  // ====================== Render
  return (
    <DayPickerContainer {...rest} ref={containerRef} position={position ?? 'left'}>
      <InputWrapper
        feedback={!hideFeedback && (invalid || !date.valid) ? 'Wrong date format DD/MM/YYYY' : ''}
      >
        <InputContainer invalid={!date.valid || invalid} isDisabled={theme?.disabledMode}>
          <input
            disabled={theme?.disabledMode}
            onBlur={handleOnBlur}
            ref={inputRef}
            data-testid="DayPicker_input"
            placeholder="DD/MM/YYYY"
            value={date.inputValue}
            onChange={handleChangeInput}
            onFocus={toggleOpen(true)}
          />
          <div
            className="styled-dp-dropdown-icon"
            data-testid="DayPicker_button"
            onClick={toggleOpen(!opened)}
          >
            <Icon icon="select" />
          </div>
        </InputContainer>
      </InputWrapper>

      {opened && (
        <DatePickerPopover
          ref={popperRef}
          position={position}
          datePikerProps={datePikerProps}
          value={date.value}
          setDay={setDay}
          shortcuts={shortcuts}
        />
      )}
    </DayPickerContainer>
  )
}
