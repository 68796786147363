// @flow

import { parseBaseStats } from './parse-base-stats'
import { parseBounceCategories } from './parse-bounce-categories'
import { parseBouncesByProvider } from './parse-bounces-by-provider'

import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  type BounceDetail,
  type BaseOrchestrationStats,
  type MailboxProviderDetail,
} from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseEmailStats = ({
  base,
  bounceDetails,
  mailboxProviderDetails,
}: {
  base: BaseOrchestrationStats,
  bounceDetails: ?BounceDetail,
  mailboxProviderDetails: ?MailboxProviderDetail,
}): StatsRecord => {
  const stats = parseBaseStats(base)
  const categories = parseBounceCategories({ base, bounceDetails })
  const providers = parseBouncesByProvider(mailboxProviderDetails)

  return stats.setIn(['bounce', 'categories'], categories).setIn(['bounce', 'providers'], providers)
}
