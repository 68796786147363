// @flow

import * as React from 'react'

import { TableRow, TableCell } from 'components/common/table'

import { kformat } from 'com.batch.common/utils'

import { BounceProviderCell } from './bounce-per-mailbox-provider.styles'

import { formatRate } from '../format-rate'

import { type BounceProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'

type BouncePerMailboxProviderRowProps = {
  provider: BounceProviderRecord,
}

export const BouncePerMailboxProviderRow = ({
  provider,
}: BouncePerMailboxProviderRowProps): React.Node => (
  <TableRow>
    <TableCell style={{ overflowX: 'hidden' }}>
      <BounceProviderCell>{provider.name}</BounceProviderCell>
    </TableCell>
    <TableCell align="right" sortable>
      {kformat(provider.sent)}
    </TableCell>
    <TableCell align="right" sortable>
      {formatRate(provider.rate)}
    </TableCell>
    <TableCell align="right">{kformat(provider.categories.HARD.count)}</TableCell>
    <TableCell align="right">{kformat(provider.categories.SOFT.count)}</TableCell>
    <TableCell align="right">{kformat(provider.categories.BLOCK.count)}</TableCell>
  </TableRow>
)
