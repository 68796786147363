// @flow

import { colors } from 'components/styled/tokens'
import { orange, darklucent } from 'components/styled/tokens/schemes'

import { type StatType } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { type ChartColor } from 'com.batch/shared/infra/types/chart-data'

export const statTypeColors: {
  [key: StatType]: { background: string, text: string, chart: ChartColor, ... },
  ...
} = {
  delivered: {
    background: colors.opacifySuccess,
    text: colors.textSuccess,
    chart: {
      default: '#73C991',
      hover: '#63BD82',
      active: '#63BD82',
    },
  },
  opened: { background: colors.opacifyAction, text: colors.textAction, chart: {} },
  clicked: { background: colors.opacifyFeature, text: colors.textFeature, chart: {} },
  bounced: {
    background: colors.opacifyWarning,
    text: colors.textWarning,
    chart: { default: '#FFB477', hover: '#FF9F51', active: '#FF9F51' },
  },
  unsubscribed: { background: darklucent['05'], text: colors.textNeutral, chart: {} },
  skipped: {
    background: '',
    text: '',
    chart: { default: '#E0E0E0', hover: '#C9C9C9', active: '#C9C9C9' },
  },
  sending: {
    background: '',
    text: '',
    chart: { default: '#FED890', hover: '#F7C758', active: '#F7C758' },
  },
}

export const bouncesColors = {
  hard: orange[30],
  soft: orange[10],
  block: orange[50],
  undetermined: '#E0E0E0',
}
