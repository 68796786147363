// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Grid } from 'components/common/grid'
import * as colors from 'components/styled/tokens/colors'

import { type NodeType } from 'com.batch/orchestration-journey/models/journey.records'

type ImgStepProps = { $nodeType: NodeType, $channel: ?ChannelUntilCleanup, ... }
export const ImgStep: StyledComponent<ImgStepProps, *, *> = styled.div`
  width: 122px;
  height: 122px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 6px;
  background-image: ${(p: ImgStepProps) =>
    p.$nodeType === 'YESNO'
      ? "url('/medias/img/illustrations/multistep/yesno.png')"
      : p.$nodeType === 'MESSAGE'
        ? `url('/medias/img/illustrations/multistep/${p.$channel ?? ''}.png')`
        : p.$nodeType === 'RANDOM'
          ? "url('/medias/img/illustrations/multistep/random.png')"
          : "url('/medias/img/illustrations/multistep/delay.png')"};
`

export const GridToolTip: StyledComponent<{ ... }, *, *> = styled(Grid)`
  p {
    opacity: 0.92;
    font-size: 14px;
    text-align: left;
    font-style: normal;
    color: var(--${colors.textContrast},rgba(255, 255, 255, 0.92 );
  }
`
