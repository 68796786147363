// @flow

import { type Set } from 'immutable'

import { type AppRecord } from 'com.batch.redux/_records'
import { removeInvalidCondition } from 'com.batch.redux/query/query.api'
import { type QueryRecord } from 'com.batch.redux/query/query.records'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'

export function buildEstimatehashKey(
  target: TargetStateRecord,
  query: QueryRecord,
  apps: Set<AppRecord>
): number {
  const queryHash = removeInvalidCondition(query).hashCode()
  return (
    apps.hashCode() +
    (target.subscriptionStatus === 'marketing' ? 0 : 1) +
    target.languages.hashCode() +
    target.regions.hashCode() +
    target.segments.hashCode() +
    queryHash
  )
}
