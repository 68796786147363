// @flow

import { HEXtoRGB } from 'components/styled/tokens/utils'

export const blue = {
  '10': '#a8d4f9',
  '20': '#69b6f7',
  '30': '#2a99f4',
  '40': '#0b7ad5',
  '50': '#0968ac',
  '60': '#075288',
  '70': '#064674',
  '80': '#053b61',
  '90': '#042f4e',
  '00': '#e7f1fc',
}
export const grayscale = {
  '10': '#ececec',
  '20': '#e0e0e0',
  '30': '#c9c9c9',
  '40': '#a3a3a3',
  '50': '#8c8c8c',
  '60': '#707070',
  '70': '#4F4F4F',
  '80': '#383838',
  '90': '#262626',
  '100': '#0F0F0F',
  '00': '#ffffff',
  '01': '#fdfdfd',
  '02': '#fbfbfb',
  '05': '#f8f8f8',
}

export const red = {
  '10': '#fabfba',
  '20': '#f37f73',
  '30': '#f06052',
  '40': '#ed402e',
  '50': '#dc2613',
  '60': '#be1f10',
  '70': '#a0190d',
  '80': '#82130a',
  '90': '#640e07',
  '00': '#fde5e3',
}
export const orange = {
  '10': '#FFC99D',
  '20': '#FFB477',
  '30': '#FF9F51',
  '40': '#FF8929',
  '50': '#FA750A',
  '60': '#DE6400',
  '70': '#BA5300',
  '80': '#954200',
  '90': '#6B3000',
  '00': '#FCEBDE',
}
export const purple = {
  '10': '#D4C2F3',
  '20': '#B39BE9',
  '30': '#9476DE',
  '40': '#7552D5',
  '50': '#5B33CC',
  '60': '#4D31A9',
  '70': '#402E88',
  '80': '#322A68',
  '90': '#242549',
  '00': '#F6EAFE',
}
export const yellow = {
  '10': '#FFE6B7',
  '20': '#FED890',
  '30': '#F7C758',
  '40': '#F5B729',
  '50': '#DBA731',
  '60': '#C09126',
  '70': '#A77C1B',
  '80': '#8D6710',
  '90': '#755305',
  '00': '#FDF4DE',
}

export const darklucent = {
  '10': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.08)`,
  '20': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.12)`,
  '30': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.22)`,
  '40': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.38)`,
  '50': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.48)`,
  '60': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.59)`,
  '70': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.73)`,
  '80': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.83)`,
  '90': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.9)`,
  '100': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 1)`,
  '00': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0)`,
  '01': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.01)`,
  '02': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.02)`,
  '05': `rgba(${HEXtoRGB(grayscale[100]).toString()}, 0.06)`,
}

export const lightlucent = {
  '10': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.10)`,
  '20': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.17)`,
  '30': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.27)`,
  '40': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.41)`,
  '50': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.52)`,
  '60': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.62)`,
  '70': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.78)`,
  '80': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.88)`,
  '90': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.92)`,
  '100': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 1)`,
  '05': `rgba(${HEXtoRGB(grayscale['00']).toString()}, 0.05)`,
}

export const bluelucent = {
  '02': `rgba(${HEXtoRGB(blue[50]).toString()}, 0.02)`,
  '04': `rgba(${HEXtoRGB(blue[50]).toString()}, 0.04)`,
}
