// @flow

import { type List } from 'immutable'
import { memoize } from 'lodash-es'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import { useFetchAppFromRouter } from 'components/_hooks'
import { BarChart } from 'components/charts/bar-chart'
import {
  type DataSetRecord,
  DataSetFactory,
  getChartFakeData,
} from 'components/charts/chart-helper'
import { Box, BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { Wrapper, AnalyticsEmptyIcon, GlobalErrorIcon } from 'components/common/empty-states'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { ReachTable } from './reach-table'
import { useRange } from './use-range'

import { Icon } from '../common/svg-icon'
import { setAnalyticsTab } from 'com.batch.redux/stat'
import { type ReachByDayRecord, type loadingState } from 'com.batch.redux/stat.records'
import {
  reachSelector,
  reachCsvSelector,
  reachIsEmptySelector,
  loadingReachSelector,
  reachSeriesSelector,
} from 'com.batch.redux/stat.selector.analytics'

import { STATUS } from 'constants/common'

type sortableFields =
  | 'period'
  | 'tokens'
  | 'tokensNotifOn'
  | 'optOuts'
  | 'newOptins'
  | 'deletedTokens'

function sortData(
  data: List<ReachByDayRecord>,
  sortBy: sortableFields,
  sortOrder: 'asc' | 'dsc'
): List<ReachByDayRecord> {
  return data.sort((a, b) => {
    const bigger = sortOrder === 'asc' ? 1 : -1
    const smaller = sortOrder === 'asc' ? -1 : 1
    if (sortBy === 'period') {
      return a.date.isBefore(b.date) ? smaller : bigger
    } else if (sortBy === 'tokens') {
      return a.data.count.tokens < b.data.count.tokens ? smaller : bigger
    } else if (sortBy === 'tokensNotifOn') {
      return a.data.count.tokensNotifOn < b.data.count.tokensNotifOn ? smaller : bigger
    } else if (sortBy === 'newOptins') {
      return a.data.changes.newTokensNotifOn + a.data.changes.toNotifOn <
        b.data.changes.newTokensNotifOn + b.data.changes.toNotifOn
        ? smaller
        : bigger
    } else if (sortBy === 'deletedTokens') {
      return a.data.changes.deletedTokens < b.data.changes.deletedTokens ? smaller : bigger
    } else {
      return a.data.changes.deletedTokensNotifOn + a.data.changes.toNotifOff <
        b.data.changes.deletedTokensNotifOn + b.data.changes.toNotifOff
        ? smaller
        : bigger
    }
  })
}

const sortDataMemoized = memoize(
  sortData,
  (data: List<ReachByDayRecord>, sortBy: sortableFields, sortOrder: 'asc' | 'dsc') => {
    return data.hashCode() + sortBy + sortOrder
  }
)

const ReachBase = (): React.Node => {
  // ====================== STATE
  const [overIndex, setOverIndex] = React.useState<null | number>(null)
  const [sortBy, setSortBy] = React.useState<sortableFields>('period')
  const [sortOrder, setSortOrder] = React.useState<'dsc' | 'asc'>('dsc')
  const app = useFetchAppFromRouter()
  // ====================== REDUX
  const dispatch = useDispatch()
  const { range } = useRange()
  const data: List<ReachByDayRecord> = useSelector(reachSelector)
  const csv: string = useSelector(reachCsvSelector)
  const isEmpty: boolean = useSelector(reachIsEmptySelector)
  const status: loadingState = useSelector(loadingReachSelector)
  const series: List<List<DataSetRecord>> = useSelector(reachSeriesSelector)

  const [fakeData, setFakeData] = React.useState<List<DataSetRecord>>(
    getChartFakeData({
      total: isEmpty ? 100 : 0,
      periods: Math.round(dayjs.duration(range.to.diff(range.from)).asDays()),
    })
  )
  // ====================== EMPTY STATE
  const isError: boolean = status === STATUS.ERROR
  const isLoading: boolean = status === STATUS.LOADING || status === STATUS.INIT || !app
  const isEmptyAfterLoading: boolean = isEmpty && !isLoading

  // // update active tab on first render
  React.useEffect(() => {
    dispatch(setAnalyticsTab('reach'))
  }, [dispatch])
  const fromUnix = range.from.unix()
  const toUnix = range.to.unix()

  // update fake empty data when range changes
  React.useEffect(() => {
    const numberNeeded = Math.floor((toUnix - fromUnix) / (3600 * 24))

    setFakeData(getChartFakeData({ periods: numberNeeded, total: isEmpty ? 100 : 0 }))

    // je ne passe pas directement range car l'injector est mal foutu (pas immutbale, pointer diff)
  }, [fromUnix, isEmpty, toUnix])

  // update table sort
  const updateSort = React.useCallback(
    (field: sortableFields) => {
      let order = sortOrder === 'asc' ? 'dsc' : 'asc'
      if (sortBy !== field) order = 'dsc'
      setSortBy(field)
      setSortOrder(order)
    },
    [setSortBy, setSortOrder, sortBy, sortOrder]
  )

  const download = React.useCallback(() => {
    const filename = `app_${app?.id ?? 0}_reach_${range.from.format(
      'YYYY-MM-DD'
    )}_${range.to.format('YYYY-MM-DD')}.csv`
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

    // $FlowFixMe it's fine
    if (navigator && typeof navigator.msSaveBlob === 'function') {
      // $FlowExpectedError IE 10+
      navigator.msSaveBlob(blob, filename)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        if (document && document.body) document.body.appendChild(link)
        link.click()
        if (document && document.body) document.body.removeChild(link)
      }
    }
  }, [app?.id, csv, range])

  const list = sortDataMemoized(data, sortBy, sortOrder)
  return (
    <Wrapper
      isEmpty={isEmptyAfterLoading || isError}
      isLoading={isLoading}
      isOverlayShown={isEmptyAfterLoading || isError}
      overlayProps={
        isError
          ? {
              status: 'empty-page',
              title: "Couldn't load data",
              description:
                'There was an error during the loading of this page, try to refresh or contact the support if this continue to happen.',
              content: <GlobalErrorIcon />,
              refresh: () => dispatch(setAnalyticsTab('reach')),
            }
          : {
              status: 'empty-page',
              title: 'No data for this date range',
              description:
                'We haven’t been able to find any data for the selected dates. If the SDK is up and running, you may need to select a different/larger date range.',
              content: <AnalyticsEmptyIcon />,
              links: [
                {
                  name: 'Troubleshooting',
                  href: 'https://doc.batch.com/dashboard/analytics/troubleshooting.html',
                },
                {
                  name: 'Install the SDK',
                  href: 'https://batch.com/download',
                },
              ],
            }
      }
    >
      <Box style={{ overflow: 'hidden' }}>
        <Helmet>
          <title>Reach — {app?.name}</title>
        </Helmet>
        <BoxBody>
          {series.map((serie, index) => {
            const total = serie.reduce((sum, set) => sum + set.total, 0)
            return (
              <BarChart
                clamp
                height={70}
                key={index}
                label={serie
                  .get(0, DataSetFactory())
                  .label.toLowerCase()
                  .replace(' ', '')
                  .replace('-', '')}
                overIndex={overIndex}
                loading={isLoading}
                setOverIndex={setOverIndex}
                sets={isLoading || list.size <= 0 || total === 0 ? fakeData : serie}
                timelineMode={index === 0 ? 'top' : 'none'}
                hasSeparator={index !== series.size - 1}
              />
            )
          })}
        </BoxBody>
      </Box>
      <div style={{ margin: '36px 0 -8px 0', textAlign: 'right' }}>
        <Button
          intent="action"
          kind="secondary"
          addOn="prefix"
          onClick={download}
          disabled={isLoading}
        >
          <Icon icon="download" />
          Download CSV
        </Button>
      </div>
      <Box style={{ margin: '24px 0 0 0', overflow: 'hidden' }}>
        <ReachTable
          list={list}
          isWeb={app?.platform === 'webpush'}
          isLoading={isLoading}
          updateSort={updateSort}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      </Box>
    </Wrapper>
  )
}
export const Reach = (React.memo<{}>(ReachBase): React.AbstractComponent<{}>)
