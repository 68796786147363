// @flow

import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { fetchTemplates } from 'com.batch/message/usecases/fetch-templates'

export type UpdateSmsTemplateAction = {
  type: 'UPDATE_SMS_TEMPLATE',
  payload: {
    messageId: string,
    lang: string,
    template: string,
  },
}

export const updateSmsTemplate = ({
  messageId,
  lang,
}: {
  messageId: string,
  lang: string,
}): DispatchBoundFn<UpdateSmsTemplateAction> => {
  return (dispatch, getState) => {
    const state = getState()
    const smsMessage = state.message.sms.get(messageId)?.getIn([lang, 'smsMessage']) ?? ''

    if (!textUsesTemplating(smsMessage)) {
      return dispatch({
        type: 'UPDATE_SMS_TEMPLATE',
        payload: {
          messageId,
          lang,
          template: smsMessage,
        },
      })
    }

    const contents = [{ messageId, lang, field: 'smsMessage', value: smsMessage, type: 'TEMPLATE' }]
    return dispatch(fetchTemplates(contents))
      .then(res => {
        const { template, value } = res[0]
        dispatch({
          type: 'UPDATE_SMS_TEMPLATE',
          payload: {
            messageId,
            lang,
            template: template ?? value,
          },
        })
      })
      .catch(e => {
        console.error(
          'Error while fetching templates:\n',
          e.error?.errors?.[0]?.message ?? 'Unknown error'
        )
      })
  }
}
