// @flow
import Immutable, { type Map } from 'immutable'

import {
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { type FetchBounceByDaysActions } from 'com.batch/orchestration-analytics/usecases/fetch-bounce-by-days'
import { type FetchOrchestrationAnalyticsActions } from 'com.batch/orchestration-analytics/usecases/fetch-campaign-analytics'
import { type FetchClicksByUrlActions } from 'com.batch/orchestration-analytics/usecases/fetch-clicks-by-url'
import { type FetchOrchestrationStatsByProviderActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-provider'
import { type FetchOrchestrationStatsByStepActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-steps'
import { STATUS } from 'constants/common'

type OrchestrationAnalyticsActions =
  | FetchOrchestrationAnalyticsActions
  | FetchBounceByDaysActions
  | FetchOrchestrationStatsByStepActions
  | FetchOrchestrationStatsByProviderActions
  | FetchClicksByUrlActions

export const orchestrationAnalyticsReducer = (
  state: Map<string, OrchestrationAnalyticsRecord> = Immutable.Map(),
  action: OrchestrationAnalyticsActions
): Map<string, OrchestrationAnalyticsRecord> => {
  switch (action.type) {
    case 'FETCH_ORCHESTRATION_ANALYTICS':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('loadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_ANALYTICS_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('loadingState', STATUS.LOADED)
          .set('stats', action.payload.data?.base)
          .set('expire', Date.now() + 1000 * 60 * 2) // TTL 2 min
          .setIn(['channels', 'email', 'stats'], action.payload.data?.email)
          .setIn(['channels', 'sms', 'stats'], action.payload.data?.sms)
          .setIn(['channels', 'push', 'stats'], action.payload.data?.push)
      )
    case 'FETCH_ORCHESTRATION_ANALYTICS_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('loadingState', STATUS.ERROR)
          .set('expire', Date.now())
      )

    case 'FETCH_BOUNCE_BY_DAYS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('bounceLoadingState', STATUS.LOADING)
      )

    case 'FETCH_BOUNCE_BY_DAYS_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('bounceLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'bounces'], action.payload.data)
      )

    case 'FETCH_BOUNCE_BY_DAYS_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('bounceLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_DATA_BY_STEP':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_DATA_BY_STEP_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.LOADED)
          .set('steps', action.payload.data)
      )
    case 'FETCH_ORCHESTRATION_DATA_BY_STEP_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_DATA_BY_PROVIDER':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_DATA_BY_PROVIDER_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'providers'], action.payload.data ?? new Immutable.List())
      )
    case 'FETCH_ORCHESTRATION_DATA_BY_PROVIDER_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.ERROR)
      )
    case 'FETCH_CLICKS_BY_URL':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.LOADING)
      )
    case 'FETCH_CLICKS_BY_URL_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'links'], action.payload.data)
      )
    case 'FETCH_CLICKS_BY_URL_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.ERROR)
      )

    default:
      return state
  }
}
