// @flow

import { HEXtoRGB } from 'components/styled/tokens/utils'

import * as schemes from './schemes'

/** Defaults **/
// Fill
export const fill = schemes.grayscale['00']
export const fillDepth = schemes.grayscale['01']
export const fillBackground = schemes.grayscale['05']
export const fillDisabled = schemes.grayscale['10']
export const fillContrast = schemes.grayscale['80']
export const fillNeutral = schemes.grayscale['60']
export const fillNeutralHover = schemes.grayscale['70']
export const fillNeutralActive = schemes.grayscale['80']

// Text
export const text = schemes.grayscale['80']
export const textLight = schemes.grayscale['60']
export const textDisabled = schemes.grayscale['40']
export const textContrast = schemes.lightlucent['90']
export const textContrastLight = schemes.lightlucent['70']
export const textContrastDisabled = schemes.lightlucent['30']
export const textNeutral = schemes.grayscale['70']
export const textNeutralContrast = schemes.lightlucent['80']

// Stroke
export const stroke = schemes.darklucent['20']
export const strokeHover = schemes.darklucent['30']
export const strokeContrast = schemes.lightlucent['20']

// Opacify
export const opacifyHover = schemes.darklucent['10']
export const opacifyActive = schemes.darklucent['20']
export const opacifyContrast = schemes.darklucent['20']
export const opacifyContrastHover = schemes.darklucent['30']
export const opacifyContrastActive = schemes.darklucent['40']
export const opacifyDefault = schemes.darklucent['05']

/** Action (blue) **/
export const fillAction = schemes.blue['50']
export const fillActionHover = schemes.blue['60']
export const fillActionActive = schemes.blue['70']
export const textAction = schemes.blue['60']
export const textActionContrast = schemes.blue['30']
export const strokeAction = schemes.blue['50']
export const opacifyAction = `rgba(${HEXtoRGB(schemes.blue['50']).toString()}, 0.09)`

/** Danger (red) **/
export const fillDanger = schemes.red['50']
export const fillDangerHover = schemes.red['60']
export const fillDangerActive = schemes.red['70']
export const textDanger = schemes.red['70']
export const textDangerContrast = schemes.red['40']
export const strokeDanger = schemes.red['70']
export const opacifyDanger = `rgba(${HEXtoRGB(schemes.red['50']).toString()}, 0.10)`

/** Success (green) **/
export const textSuccess = '#246A54' // no color palette for green
export const textSuccessContrast = '#39A784'
export const opacifySuccess = `rgba(${HEXtoRGB(textSuccess).toString()}, 0.12)`

/** Feature (purple) **/
export const fillFeature = schemes.purple['50']
export const textFeature = schemes.purple['70']
export const opacifyFeature = `rgba(${HEXtoRGB(schemes.purple['10']).toString()}, 0.31)`

/** Tech (yellow) **/
export const opacifyTech = `rgba(${HEXtoRGB(schemes.yellow['40']).toString()}, 0.14)`
export const fillTech = schemes.yellow['40']

/** Warning (orange) **/
export const textWarning = schemes.orange['60']
export const fillWarning = schemes.orange['50']
export const opacifyWarning = `rgba(${HEXtoRGB(schemes.orange['50']).toString()}, 0.14)`
export const opacifyOverlay = schemes.darklucent['60']

/** DEPRECATED TOKENS **/

/**
 * @deprecated Not in the design tokens anymore. Use `stroke` instead.
 */
export const strokeDark = schemes.lightlucent['30']

/**
 * @deprecated Not in the design tokens anymore.
 */
export const strokeInput = schemes.darklucent['05']

/**
 * @deprecated Not in the design tokens anymore. Will not be used any further, if yes, talk with design.
 */
export const textTech = schemes.yellow['60']

/**
 * @deprecated Not in the design tokens anymore. Will not be used any further, if yes, talk with design.
 */
export const textTechDark = schemes.yellow['90']

export const analytics = {
  daus: 'HSL(97, 38%, 57%)',
  installs: 'HSL(42, 97%, 66%)',
  starts: 'HSL(199, 68%, 62%)',
  pushes: 'HSL(220, 49%, 43%)',
}
