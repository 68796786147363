import * as React from 'react'
import { connect } from 'react-redux'

import { PushSpecificReview } from 'components/campaign/push-specific-review'

import { currentAppSelector } from 'com.batch.redux/app'
import { currentCampaign } from 'com.batch.redux/campaign'
import { estimateSelector } from 'com.batch.redux/targeting'

const mapStateToProps = state => {
  return {
    campaign: currentCampaign(state),
    estimate: estimateSelector(state),
    app: currentAppSelector(state),
  }
}

class PushSpecificReviewContainer extends React.Component {
  render() {
    return <PushSpecificReview {...this.props} />
  }
}
export default connect(mapStateToProps)(PushSpecificReviewContainer)
