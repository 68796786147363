// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, BoxBody, BoxHeader, BoxSearch, HeaderBoxActions } from 'components/common/box'
import { HeaderBoxTitle } from 'components/common/box/box'
import { Button } from 'components/common/button/button.styles'
import { Loader } from 'components/common/loader/loader'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens/'

import { fetchSegments, type Segment } from '../../infra/segments.api'
import { type fetchingState } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'

type PickSegmentModalProps = {
  dismiss: () => void,
  onUseSegment: (query: string) => void,
}
export const PickSegmentModal = ({ dismiss, onUseSegment }: PickSegmentModalProps): React.Node => {
  const [segments, setSegments] = React.useState<Array<Segment>>([])
  const [search, setSearch] = React.useState('')
  const project = useSelector(currentProjectSelector)
  const dispatch = useDispatch()
  const [fetching, setFetching] = React.useState<fetchingState>('LOADING')
  React.useEffect(() => {
    const loadSegments = async () => {
      setFetching('LOADING')
      try {
        const segments = await fetchSegments(project)
        setSegments(segments.sort((a, b) => ((a.id ?? 0) < (b.id ?? 0) ? 1 : -1)))
        setFetching('LOADED')
      } catch (error) {
        setFetching('ERROR')
        console.error(error)
        dispatch(
          showToast({ type: 'error', message: 'An error occurred while fetching segments.' })
        )
      }
    }
    loadSegments()
  }, [dispatch, project])
  const createOnClickForSegment = React.useCallback(
    (segment: Segment) => () => {
      onUseSegment(segment.query)
    },
    [onUseSegment]
  )
  const matchingSegment = React.useMemo(
    () => segments.filter(s => !search || s.name.toLowerCase().includes(search.toLowerCase())),
    [search, segments]
  )
  return (
    <Popin close={dismiss} opened style={{ width: 480 }}>
      <Box>
        <BoxHeader style={{ height: 56 }} large>
          <HeaderBoxTitle style={{ width: '100%' }} title="Use segment in targeting" />
          <HeaderBoxActions large>
            <Button onClick={dismiss}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        {segments.length > 6 && <BoxSearch value={search} onChange={setSearch} />}
        <BoxBody
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            overflowY: 'auto',
            height: 305,
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
          }}
        >
          {fetching === 'LOADING' ? (
            <Loader loading style={{ marginTop: 40 }} />
          ) : (
            <NoResultWrapper
              isEmpty={matchingSegment.length === 0}
              entityName="segments"
              height={230}
            >
              {matchingSegment.map(segment => (
                <Button
                  style={{
                    flex: '0 0 38px',
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: 8,
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                  }}
                  addon="suffix"
                  key={segment.id}
                  onClick={createOnClickForSegment(segment)}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {segment.name}
                  </span>
                  <Icon icon="arrow-long-right" color={colors.textLight} />
                </Button>
              ))}
            </NoResultWrapper>
          )}
        </BoxBody>
      </Box>
    </Popin>
  )
}
