// @flow

import { type fetchingState } from 'com.batch.redux/_records'

import {
  defaultTemplateCss,
  defaultTemplateHtml,
} from 'com.batch/email/constants/default-stripo-template'
import {
  stripoCodeEditorId,
  stripoPreviewId,
  stripoRedoId,
  stripoSettingsId,
  stripoUndoId,
} from 'com.batch/email/constants/stripo-container-ids'
import { getStripoAuthToken } from 'com.batch/email/infra/drag-and-drop-editor.api'

let stripoLoadingState: fetchingState = 'INIT'
// Si aucun template n'est fourni c'est que c'est un new mail from scratch → Stripo needs a template
export const initStripo = ({
  html = defaultTemplateHtml,
  css = defaultTemplateCss,
  companyId,
  projectId,
  templateId,
  dynamicContentCallback,
  toggleCodeEditorCallback,
  isLoadedCallback,
  viewOnly,
}: {
  html: ?string,
  css: ?string,
  companyId: number,
  projectId: string,
  templateId: string,
  dynamicContentCallback: (callback: function) => void,
  toggleCodeEditorCallback: (isCodeEditorVisible: boolean) => void,
  isLoadedCallback: () => void,
  viewOnly: boolean,
}): void => {
  const stripoProps = {
    settingsId: stripoSettingsId,
    previewId: stripoPreviewId,
    codeEditorButtonId: stripoCodeEditorId,
    undoButtonId: stripoUndoId,
    redoButtonId: stripoRedoId,
    locale: 'en',
    onTemplateLoaded: isLoadedCallback,
    onToggleCodeEditor: toggleCodeEditorCallback,
    localePatch: {
      'mergeTags.label': { en: 'Dynamic content' },
    },
    html,
    css,
    enableXSSSecurity: true,
    apiRequestData: {
      emailId: templateId,
      projectId,
      companyId,
      sessionToken: window.initialData.stripoSessionToken,
    },
    getAuthToken: getStripoAuthToken,
    externalMergeTags: {
      open: dynamicContentCallback,
    },
    conditionsEnabled: true,
    viewOnly,
    allowedScriptSourceDomains: 'static.batch.com',
    specialLinks: [
      {
        category: 'Batch',
        entries: [
          {
            label: 'Unsubscribe',
            value: '{{emailComposerUnsubscribeLink()}}',
            hidden: false,
          },
        ],
      },
    ],
    socialNetworks: [
      { name: 'twitter', href: '' },
      { name: 'facebook', href: '' },
      { name: 'instagram', href: '' },
      { name: 'youtube', href: '' },
    ],
  }
  // Init Stripo : Si déjà initialisé ne recrée pas une instance du plugin
  const isStripoAlreadyInitialized = !!document.getElementById('stripoScript')

  if (!isStripoAlreadyInitialized && stripoLoadingState === 'INIT') {
    stripoLoadingState = 'LOADING'
    const script: HTMLScriptElement = document.createElement('script')
    script.id = 'stripoScript'
    script.type = 'text/javascript'
    script.src = 'https://static.batch.com/vendors/stripo/1.106.0/stripo.js'

    script.onload = () => {
      stripoLoadingState = 'LOADED'
      window.Stripo.init(stripoProps)
    }

    document.body?.appendChild(script)
  } else {
    if (stripoLoadingState === 'LOADED') window.Stripo.init(stripoProps)
  }
}

/**
 * Récupère le HTML ainsi que le CSS "stripo editable"
 * @returns {Promise<{html: string, css: string}>}
 */
export const getStripoTemplate = (): Promise<{ html: string, css: string, ... }> =>
  new Promise(resolve => {
    window.StripoApi?.getTemplate((html: string, css: string) => {
      resolve({ html, css })
    })
  })

/**
 * Récupère le HTML destiné à l'envoi aux clients mail.
 * @returns {Promise<string>} Le HTML optimisé
 */
export const getStripoOptimizedTemplate = (): Promise<string> =>
  new Promise((resolve, reject) => {
    window.StripoApi?.compileEmail((error, html) => {
      if (html) {
        resolve(html)
      } else {
        reject(error)
      }
    })
  })
