// @flow
import * as React from 'react'

import { Button } from 'components/common/button'
import { Well } from 'components/styled/blocs'

import config from 'com.batch.common/config'

import { type AppRecord, type TestDeviceRecord, TestDeviceFactory } from 'com.batch.redux/_records'
import { infos, integrateDone } from 'com.batch.redux/app.api'
import * as tokenApi from 'com.batch.redux/testDevice.api'

type IntegrationCheckerProps = {
  app: AppRecord,
  valid: boolean,
  ...
}

type IntegrationCheckerState = {
  step: 'start' | 'token' | 'send' | 'receive',
  loading: boolean,
  when: string,
  testDevice: ?TestDeviceRecord,
  device: { device: string, brand: string, region: string, ... },
  error: ?string,
  ...
}
const defaultState: IntegrationCheckerState = {
  step: 'start',
  when: '',
  loading: false,
  error: undefined,
  device: { device: '', brand: '', region: '' },
  testDevice: undefined,
}
class IntegrationChecker extends React.PureComponent<
  IntegrationCheckerProps,
  IntegrationCheckerState,
> {
  constructor(props: IntegrationCheckerProps) {
    super(props)
    this.state = defaultState
  }
  checkStart: () => Promise<any> = () => {
    this.setState({ loading: true })

    return infos(this.props.app).then(
      success => {
        const startPassed = success.start.ok
        const tokenPassed = success.token.exist
        const token = success.origin.last_push_token.token
        this.setState({
          step: startPassed ? (tokenPassed && token !== '' ? 'send' : 'token') : 'start',

          when: tokenPassed
            ? success.token.date.duration
            : startPassed
              ? success.start.date.duration
              : '',
          device: tokenPassed
            ? success.token.device
            : startPassed
              ? success.start.device
              : { device: '', brand: '', region: '' },
          error: startPassed
            ? tokenPassed
              ? undefined
              : 'No push token found for this install.'
            : `Hmm... Unable to find a start event from ${this.props.app.name}. This might take a few minutes.`,
          testDevice: tokenPassed
            ? TestDeviceFactory({
                value: 'INTEGRATION_TOKEN',
                kind: 'token',
                distribution: !!success.token.distribution,
              })
            : undefined,
          loading: false,
        })
      },
      error => {
        console.log(error)
        this.setState({ loading: false })
      }
    )
  }

  done: () => void = () => {
    this.setState({ loading: true })
    integrateDone(this.props.app).then(
      () =>
        (window.location.href = config.common.urls.appShow
          .replace('{companyId}', this.props.app.companyId)
          .replace('{appId}', this.props.app.id)),
      () => this.setState({ loading: false })
    )
  }

  reset: () => void = () => {
    this.setState(defaultState)
  }

  test: () => void = () => {
    this.setState({ loading: true })
    if (this.state.testDevice) {
      tokenApi
        .test({
          appId: this.props.app.id,
          device: this.state.testDevice,
          message: { body: '✋ Batch is working!', title: 'Hello stranger!' },
          media: { picture: 'https://static.batch.com/dashboard/push-test-custom-image.jpg' },
          advanced: false,
          landing: false,
        })
        .then(
          res => {
            console.log(res)
            this.setState({
              loading: false,
              step: 'receive',
              error: undefined,
            })
          },
          err => {
            console.log(err)
            this.setState({ loading: false, error: err.error })
          }
        )
    }
  }

  renderStep: () => any = () => {
    const iOS = this.props.app.platform === 'ios'

    switch (this.state.step) {
      case 'start':
        return this.state.error ? (
          <div>
            <p className="text-danger">{this.state.error}</p>
            <br />
            <Button
              isLoading={this.state.loading}
              onClick={this.checkStart}
              intent="action"
              kind="primary"
            >
              Retry
            </Button>
          </div>
        ) : (
          <div>
            {this.props.app.platform === 'webpush' ? (
              <p>
                Ok, lets make sure you visited the website at least once and subscribed to push
                notification.
                <br />
                Then cross your fingers and press the button.
              </p>
            ) : (
              <p>
                Ok, lets make sure you have launched the app on your phone at least once
                {this.props.app.platform === 'ios' ? ' and accepted push notification' : ''}.<br />
                Then lock the phone, cross your fingers and press the button.
              </p>
            )}
            <br />
            <Button
              kind="primary"
              isLoading={this.state.loading}
              onClick={this.checkStart}
              intent="action"
              disabled={!this.props.valid}
            >
              I am ready
            </Button>
          </div>
        )

      case 'token':

      case 'send':
        return (
          <div>
            <p>
              We got a start
              {this.props.app.platform === 'webpush' ? '' : ' for '}
              {iOS ? (
                <span>{this.state.device?.device?.toUpperCase() ?? 'unknown device'}</span>
              ) : (
                <span>
                  {this.state.device.brand && (
                    <strong>{this.state.device.brand.toUpperCase()}</strong>
                  )}
                  {this.state.device.device ? `(${this?.state?.device?.device?.toUpperCase()}` : ''}
                </span>
              )}
              , connecting from{' '}
              {this.state.device.region && (
                <img
                  src={`/medias/img/flags/${this.state.device.region.toLowerCase()}.png`}
                  style={{ margin: '-2px 4px 0 4px' }}
                />
              )}
              <strong>{this.state.when}</strong>
            </p>
            <br />
            {this.state.error && this.state.step === 'start' ? (
              <div>
                <p className="text-danger">
                  {this.state.error}
                  <br />
                </p>
                <p>
                  <Button onClick={this.checkStart} kind="primary" intent="action">
                    Try again
                  </Button>
                </p>
              </div>
            ) : (
              <div>
                {this.state.error && <p className="text-danger">{this.state.error}</p>}
                <br />
                <p>
                  {!this.state.error && (
                    <Button onClick={this.checkStart}>Not me, look again</Button>
                  )}
                  <Button
                    onClick={this.state.error ? this.checkStart : this.test}
                    kind="primary"
                    intent="action"
                    isLoading={this.state.loading}
                  >
                    {this.state.error ? 'Try again' : 'Yes, that would be me!'}
                  </Button>
                </p>
              </div>
            )}
          </div>
        )
      case 'receive':
        return (
          <div>
            <p>
              Message sent! Did you get it ?<br /> (Note that delivery might take up to a minute in
              poor networking conditions).
            </p>
            <br />
            <Button onClick={this.reset} isLoading={this.state.loading}>
              No, I'd like to restart
            </Button>
            <Button onClick={this.done} kind="primary" intent="action">
              Yes! I am done
            </Button>
          </div>
        )
    }
  }

  render(): React.Node {
    const classes = [
      `pb__step ${this.state.step !== 'start' ? 'pb__step--done' : ''}`,
      `pb__step ${
        this.state.step == 'send' || this.state.step == 'receive' ? 'pb__step--done' : ''
      }`,
      `pb__step ${this.state.step === 'receive' ? 'pb__step--done' : ''}`,
    ]
    return (
      <div>
        <ul className="pb">
          <li className={classes[0]}>
            <span className="pb__step__label">Start detected</span>
          </li>
          <li className={classes[1]}>
            <span className="pb__step__label">Token detected</span>
          </li>
          <li className={classes[2]}>
            <span className="pb__step__label">Successfully sent</span>
          </li>
          <li className="pb__step">
            <span className="pb__step__label">Notification received</span>
          </li>
        </ul>

        <Well style={{ marginTop: '-40px' }}>{this.renderStep()}</Well>
      </div>
    )
  }
}

export default IntegrationChecker
