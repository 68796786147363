// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

type LabelProps = { $isSelected: boolean, $isDisabled: ?boolean, ... }
export const BoxedRadioLabel: StyledComponent<LabelProps, *, *> = styled.label`
cursor: pointer;
width: 100%;
height: 78px;
margin-right: 12px;
padding: 12px;

border-radius: 8px;
overflow: hidden;
border: none;
outline: ${(p: LabelProps) =>
  p.$isSelected && !p.$isDisabled
    ? `2px solid ${colors.fillAction};`
    : p.$isDisabled
      ? `1px solid ${colors.stroke};`
      : `1px solid ${colors.stroke};`}
background: ${(p: LabelProps) => (p.$isSelected ? colors.fill : colors.fill)};
.styled-option-symbol{
  flex: 0 0 16px;
}
.styled-boxedradio-title {
  font-size: 14px;
  padding-left:4px;
  font-weight: 500;
  color:${(p: LabelProps) =>
    p.$isDisabled ? colors.textDisabled : !p.$isSelected ? colors.textLight : colors.text};
}
.styled-boxedradio-text {
  font-weight: normal;
  font-size: 14px;
  color:${(p: LabelProps) =>
    p.$isDisabled ? colors.textDisabled : !p.$isSelected ? colors.textLight : colors.text};
  line-height: 16px;
  margin-top:4px;
  }

`
