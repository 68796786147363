// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Select } from 'components/form'

import {
  ConditionContainer,
  ConditionPart,
  ConditionPartSpacer,
  ConditionPartAttribute,
} from './query.styles'

import { currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { type FunctionParamsRecord } from 'com.batch.redux/query/query.records'
import { groupFetchEventData } from 'com.batch.redux/stat.api'

type LabelValue = { label: string, value: string, ... }

type ConditionEventFilterProps = {
  removeFilter: () => void,
  eventId: string,
  withSeparator: ?boolean,
  availableEventFilteringOptions: List<LabelValue>,
  params: FunctionParamsRecord,
  update: FunctionParamsRecord => void,
  ...
}

const stringToLabelValue = (value: string) => ({
  label: value,
  value,
})
const OptFormater = (opt: LabelValue | { ...LabelValue, __isNew__: boolean, ... }) =>
  opt?.__isNew__ ? (
    <React.Fragment>
      <em>Create </em>
      {opt.label}
    </React.Fragment>
  ) : (
    opt.label
  )

export const ConditionEventFilter = ({
  eventId,
  availableEventFilteringOptions,
  withSeparator,
  removeFilter,
  params,
  update,
}: ConditionEventFilterProps): React.Node => {
  const apps = useSelector(currentCampaignAppsSelector)

  const loadOptionsAsLabelValue = React.useCallback(
    (query: string) => {
      return groupFetchEventData({
        appIds: apps.map(app => app.id).toArray(),
        eventId,
        eventDataType:
          params.eventFilterOn === '__LABEL__'
            ? 'label'
            : params.eventFilterOn === '__TAG__'
              ? 'tag'
              : 'attribute',
        eventDataAttributeName:
          params.eventFilterOn === '__LABEL__' || params.eventFilterOn === '__TAG__'
            ? ''
            : params.eventFilterOn,
        query,
      }).then(opts => opts.filter(v => v !== '').map(stringToLabelValue))
    },
    [apps, eventId, params.eventFilterOn]
  )

  return (
    <React.Fragment>
      <ConditionContainer isSub>
        <div className="styled-icon">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1V6C1 9.77124 1 11.6569 2.17157 12.8284C3.34315 14 5.22876 14 9 14H13"
              stroke="currentColor"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <ConditionPartAttribute>
          <Select
            isSearchable
            options={availableEventFilteringOptions}
            value={availableEventFilteringOptions.find(ko => ko.value === params.eventFilterOn)}
            placeholder="Pick an attribute"
            optionFormatter={OptFormater}
            optionToString={opt => opt?.value ?? ''}
            onChange={ko => {
              if (ko) update(params.set('eventFilterOn', ko.value).set('eventFilterValue', ''))
            }}
          />
        </ConditionPartAttribute>
        <ConditionPart style={{ flexGrow: 1 }}>
          <Select
            key={params.eventFilterOn}
            style={{ minWidth: 150 }}
            loadOptions={loadOptionsAsLabelValue}
            isSearchable
            placeholder="Pick a value"
            optionToString={opt => opt?.value ?? ''}
            optionCreator={text => ({ label: text, value: text })}
            optionFormatter={OptFormater}
            value={params.eventFilterValue ? stringToLabelValue(params.eventFilterValue) : null}
            onChange={opt => {
              update(params.set('eventFilterValue', opt?.value ?? ''))
            }}
          />
        </ConditionPart>
        <ConditionPartSpacer />
        <Button kind="discreet" intent="danger" onClick={removeFilter}>
          <Icon icon="delete" />
        </Button>
      </ConditionContainer>
      {withSeparator && <div style={{ borderBottom: '1px dashed #e3e3e3' }} />}
    </React.Fragment>
  )
}
