// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

import { INNER_PADDING } from './sidebar.styles'

export type SidebarSearchProps = {
  cantClose?: boolean,
  onClose?: Function,
  onSearch?: string => void,
  search: string,
  inputRef?: any,
  className?: string,
  style?: any,
  ...
}

export const SidebarSearch = (props: SidebarSearchProps): React.Node => {
  const { cantClose, onClose, onSearch, inputRef, className, style, search } = props

  const onChange = React.useCallback(
    e => {
      onSearch && onSearch(e.target.value.toLowerCase())
    },
    [onSearch]
  )
  const onBlur = React.useCallback(
    () => search && trackEvent('SEARCH_APPS', { location: 'sidebar', keywords: search }),
    [search]
  )

  return (
    <Container className={className} style={style}>
      <input
        placeholder="Search Apps…"
        onChange={onChange}
        ref={inputRef}
        onBlur={onBlur}
        value={search}
      />
      {!cantClose && (
        <Button kind="inline" intent="neutral" onClick={onClose}>
          <Icon icon="close" />
        </Button>
      )}
    </Container>
  )
}

export const Container: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${INNER_PADDING - 2}px;

  input {
    flex: 1 1 auto;
    align-self: stretch;
    font-weight: 500;
    color: ${colors.text};
    border: none;
    outline: none;
    background: transparent;
    padding: 12px ${INNER_PADDING}px;

    &::placeholder {
      color: ${colors.textLight};
    }
  }

  @media (max-width: 980px) {
    background: ${schemes.darklucent['10']};
    border-radius: 4px;
  }
`

export default SidebarSearch
