// @flow
import {
  generateEdge,
  buildSourceAndHandleFromBranchId,
  handleAddOrSourceBranch,
} from '../reactflow.tools'
import { type FormatterProps, NoPosition } from '../reactflow.types'

import { type YesNoNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

export const formatYesNo = ({
  node,
  ...props
}: {
  node: YesNoNodeRecord,
  ...FormatterProps,
}): FormatterProps => {
  props.reactflowNodes.push({
    id: node.id,
    type: 'yesnoNode',
    position: { x: 0, y: 0 },
    sizingType: 'YESNO',
    data: {
      label: node.label,
      errors: node.errors,
      reduxNodeId: node.id,
      onClick: () => props.callbacks.openYesNoSheet(node.id),
      onRemove: () => props.callbacks.onRemoveNode(node.id),
    },
  })
  let addButtonId: ?string = null
  if (props.ownedBy.type !== 'REJOIN') {
    addButtonId = node.id + '_add'
    props.reactflowNodes.push({
      ...NoPosition,
      id: addButtonId,
      sizingType: 'add',
      type: 'buttonNode',
      data: {
        reduxNodeId: node.id,
        flowRestrictions: props.flowRestrictions,
        isActive: false,
        pasteNode: () => props.callbacks.onPasteNode([props.ownedBy]),
        insertNode: (nodeType, channel) => {
          props.callbacks.onInsertNode({
            branchIds: [props.ownedBy],
            nodeType,
            channel,
          })
        },
      },
    })
    props.reactflowEdges.push(
      generateEdge({
        ...buildSourceAndHandleFromBranchId(props.ownedBy),
        target: addButtonId,
      })
    )
  }
  props.reactflowEdges.push(
    generateEdge({
      ...handleAddOrSourceBranch(props.ownedBy, addButtonId),
      target: node.id,
    })
  )

  return props
}
