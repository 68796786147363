// @flow
// ====================== LIBS

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { useClickOutsideOnExistingRefs } from 'components/_hooks/use-click-outside'
import { TokensIdsTooltip } from 'components/app/audience/audience.styles'
import { Button } from 'components/common/button'
import { PermissionButton } from 'components/common/button/permission-button'
import { Code } from 'components/common/code'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableRow, TableCellActions } from 'components/common/table/table.styles'
import { Tooltip } from 'components/common/tooltip'
import { Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'

import { humanizeDayjs, kformat } from 'com.batch.common/utils'

import { coerceAudienceType } from './helpers/coerce-audience-type'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'
import { updateAudience } from 'com.batch/audience/usecases/update-audience'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

type RowProps = {
  aud: ProfileAudienceRecord,
  setEditing: () => any,
  isEditing: boolean,
  closeEditing: () => any,
  remove: (aud: ProfileAudienceRecord) => any,
  estimate: EstimateProfileRecord,
  index: number,
}

export const Row = ({
  aud,
  estimate,
  remove,
  setEditing,
  isEditing,
  closeEditing,
}: RowProps): React.Node => {
  const [currentAud, setCurrentAud] = React.useState<ProfileAudienceRecord>(aud)
  const btnRef = React.useRef(null)
  const inputRef = React.createRef()

  useClickOutsideOnExistingRefs(closeEditing, [inputRef, btnRef])

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (isEditing) setCurrentAud(aud)
  }, [isEditing, aud])

  const renderTokensIds = (
    <React.Fragment>
      <TokensIdsTooltip>
        <div>{estimate.matching.profileCount}</div>
        <div>profile{!!estimate.matching.profileCount && 's'} (estimated)</div>
      </TokensIdsTooltip>
      <TokensIdsTooltip>
        <div>{estimate.matching.reachableProfileCount}</div>
        <div>reachable profile{!!estimate.matching.reachableProfileCount && 's'} (estimated)</div>
      </TokensIdsTooltip>
      <TokensIdsTooltip>
        <div>{estimate.matchingOptin.email.uniqueCount}</div>
        <div>unique optin Email{!!estimate.matchingOptin.email.uniqueCount && 's'} (estimated)</div>
      </TokensIdsTooltip>
      <TokensIdsTooltip>
        <div>{estimate.matchingOptin.sms.uniqueCount}</div>
        <div>unique optin SMS (estimated)</div>
      </TokensIdsTooltip>
      <TokensIdsTooltip>
        <div>{kformat(aud.nbIdentifier)}</div>
        <div>
          {aud.type === 'custom_ids' && 'custom user ID'}
          {aud.type === 'install_ids' && 'installation ID'}
          {aud.type === 'advertising_ids' && 'advertising ID'}
          {aud.nbIdentifier > 1 && 's'}
        </div>
      </TokensIdsTooltip>
    </React.Fragment>
  )

  const handleChangeCurrentAudienceDisplayName = React.useCallback(
    v => {
      setCurrentAud(currentAud.set('displayName', v.target.value))
    },
    [currentAud]
  )

  const onRemove = React.useCallback(() => {
    remove(currentAud)
  }, [currentAud, remove])

  const onUpdateAudienceName = React.useCallback(
    evt => {
      evt?.preventDefault()

      dispatch(updateAudience({ audience: currentAud }))
        .then(closeEditing)
        .catch(e => {
          throw e
        })
    },
    [currentAud, dispatch, closeEditing]
  )

  const isAllowedToUpdate = useIsCurrentUserAllowedTo(['app', 'custom-audiences:write'])
  return (
    <TableRow
      as={isEditing && 'form'}
      ref={btnRef}
      isEditing={isEditing}
      onSubmit={onUpdateAudienceName}
    >
      {isEditing ? (
        <ThemeProvider theme={{ size: 'small' }}>
          <TableCell kind="input" style={{ overflowX: 'unset' }}>
            <Input
              ref={inputRef}
              autoFocus
              value={currentAud.displayName}
              placeholder={currentAud.name}
              onChange={handleChangeCurrentAudienceDisplayName}
            />
          </TableCell>
        </ThemeProvider>
      ) : (
        <TableCell>
          <Ellipsis style={{ whiteSpace: 'pre' }} title={aud.displayName || aud.name}>
            {aud.displayName}
          </Ellipsis>
        </TableCell>
      )}
      <TableCell>
        <Code size="small">{aud.name}</Code>
      </TableCell>
      <TableCell>{coerceAudienceType(aud.type)}</TableCell>

      <TableCell align="right" style={{ marginRight: '28px' }}>
        <Tooltip tooltip={renderTokensIds} key={aud.name} placement="right" minWidth={220}>
          <span>
            {estimate.loading !== 'LOADED' ? (
              <p style={{ display: 'flex' }}>
                <span style={{ width: 20, height: 20 }}>-</span>/ {kformat(aud.nbIdentifier)}
              </p>
            ) : (
              <p>
                {kformat(estimate.matching.reachableProfileCount)} / {kformat(aud.nbIdentifier)}
              </p>
            )}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          tooltip={
            <React.Fragment>
              {currentAud.updated.format('DD/MM/YYYY')}{' '}
              <span style={{ fontWeight: 300 }}>
                at {currentAud.updated.format('HH:mm:ss')} UTC
              </span>
            </React.Fragment>
          }
          placement="right"
        >
          <span>{humanizeDayjs({ date: aud.updated })}</span>
        </Tooltip>
      </TableCell>

      <TableCellActions>
        {isEditing ? (
          <React.Fragment>
            <Button
              style={{ width: 50 }}
              type="submit"
              isLoading={currentAud.loading === 'LOADING'}
              disabled={
                !currentAud.loading === 'LOADING' && currentAud.displayName === aud.displayName
              }
            >
              Save
            </Button>
            <Button onClick={closeEditing} type="button">
              <Icon icon="close" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PermissionButton
              kind="inline"
              intent="neutral"
              onClick={setEditing}
              isAllowed={isAllowedToUpdate}
            >
              <Icon icon="edit" size={13} />
            </PermissionButton>
            <PermissionButton
              kind="inline"
              onClick={onRemove}
              isAllowed={isAllowedToUpdate}
              isLoading={aud.loading === 'LOADING'}
            >
              <Icon icon="delete" />
            </PermissionButton>
          </React.Fragment>
        )}
      </TableCellActions>
    </TableRow>
  )
}
