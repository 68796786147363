// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { campaignConfigSelector } from 'com.batch.redux/campaign.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { updatePreviewProfile } from 'com.batch/email/usecases/update-preview-profile'
import {
  LayerBarDropdown,
  LayerBarButton,
} from 'com.batch/message-builder/ui/components/layerbar-button'
import { restoreValuesFromLocalStorage } from 'com.batch/shared//usecases/save-restore-local'
import { savedProfilesKey } from 'com.batch/shared/ui/component/popins/preview-profile-popin'

type PreviewAsProps = {
  handlePreviewAsClick: () => void,
  updateTemplate: () => void,
}

export const PreviewAs = ({ handlePreviewAsClick, updateTemplate }: PreviewAsProps): React.Node => {
  const dispatch = useDispatch()
  const { id: projectId } = useSelector(currentProjectSelector)
  const scopedSavedProfilesKey = React.useMemo(
    () => `${projectId}-${savedProfilesKey}`,
    [projectId]
  )
  const { previewSourceValue } = useSelector(campaignConfigSelector)
  const savedProfiles = restoreValuesFromLocalStorage<string>(scopedSavedProfilesKey)
  const [isUpdatingWith, setIsUpdatingWith] = React.useState('')

  const previewAsOptions = React.useMemo(
    () => [
      ...savedProfiles.map(profileId => ({
        label: profileId,
        action: () => {
          setIsUpdatingWith(profileId)
          dispatch(
            updatePreviewProfile('user_id', profileId !== previewSourceValue ? profileId : '')
          ).finally(() => {
            setIsUpdatingWith('')
            updateTemplate()
          })
        },
        loading: isUpdatingWith === profileId,
        disabled: !!isUpdatingWith,
        active: profileId === previewSourceValue,
      })),
    ],
    [dispatch, savedProfiles, isUpdatingWith, previewSourceValue, updateTemplate]
  )

  return savedProfiles.length > 0 ? (
    <LayerBarDropdown
      label="Preview as"
      icon={<img src="/medias/img/builders/icons/profile.svg" />}
      options={previewAsOptions}
      defaultOption={{
        label: 'New profile',
        action: handlePreviewAsClick,
        disabled: !!isUpdatingWith,
        isDefault: true,
      }}
    />
  ) : (
    <LayerBarButton
      label="Preview as"
      icon={<img src="/medias/img/builders/icons/profile.svg" />}
      onClick={handlePreviewAsClick}
    />
  )
}
