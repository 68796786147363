// @flow
import { useSpring } from '@react-spring/web'
import Tippy from '@tippyjs/react/headless'
import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { TooltipContainer, TooltipInner } from './tooltip.style'

// const config = { mass: 0.2, tension: 520, friction: 19, velocity: 2, clamp: true }
const springConfig = { mass: 0.2, tension: 520, friction: 19, velocity: 10, clamp: true }

export type placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start'

export type TooltipProps = {
  children: ReactNode,
  tooltip: ReactNode,
  isTooltipEmpty?: boolean,
  toggle?: boolean,
  delay?: number,
  placement?: placement,
  offset?: [number, number],
  maxWidth?: number,
  minWidth?: number,
  renderInPortal?: boolean,
  noPadding?: boolean,
  arrow?: boolean,
}
const initialStyles = { opacity: 0, transform: 'scale(0.8)' }

export function Tooltip({
  children,
  tooltip,
  isTooltipEmpty,
  maxWidth,
  minWidth,
  delay = 0,
  offset = [0, 5],
  placement = 'top',
  noPadding = false,
  toggle = false,
  arrow = true,
}: TooltipProps): React$Node {
  const [styles, api] = useSpring(() => ({ ...initialStyles, config: springConfig }))
  const onMount = React.useCallback(() => {
    api.start({
      opacity: 1,
      transform: 'scale(1)',
      onRest: () => {},
    })
  }, [api])
  const onHide = React.useCallback(
    ({ unmount }: { unmount: () => void, ... }) => {
      api.start({
        ...initialStyles,
        onRest: unmount,
      })
    },
    [api]
  )
  const onRender = React.useCallback(
    attrs => (
      <TooltipContainer style={styles} {...attrs}>
        {arrow && (
          <div className="styled-arrow">
            <svg width="8" height="8" viewBox="0 0 24 24">
              <path d="M21 12l-18 12v-24z" />
            </svg>
          </div>
        )}
        <TooltipInner maxWidth={maxWidth} minWidth={minWidth} noPadding={noPadding}>
          {tooltip}
        </TooltipInner>
      </TooltipContainer>
    ),
    [styles, tooltip, maxWidth, minWidth, noPadding, arrow]
  )
  return isTooltipEmpty ? (
    children
  ) : (
    <Tippy
      placement={placement}
      delay={[delay, 0]}
      animation={true}
      hideOnClick={!!toggle}
      interactive={!!toggle}
      appendTo={document.getElementById('tooltip-root')}
      offset={offset}
      trigger={toggle ? 'click' : 'mouseenter focus'}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '.styled-arrow', // can be a CSS selector too
            },
          },
        ],
      }}
      onMount={onMount}
      onHide={onHide}
      render={onRender}
    >
      {children}
    </Tippy>
  )
}
