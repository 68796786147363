// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { Condition } from 'components/targeting/condition'

type Props = {
  toggleLogical: (id: any) => void,
  canUseEventCountPeriod: boolean,
  canQueryEventData: boolean,
  app: any,
  platform: string,
  ttlRetargeting: number,
  level: number,
  logical: string,
  updateCondition: () => void,
  attributesValues: any,
  attributesValuesLoading: any,
  createAttributeValue: () => void,
  deleteCondition: () => void,
  toggleCondition: () => void,
  root: Map<string, any>,
  openPicker: (id: any) => void,
  addLogical: (id: any) => void,
  deleteLogical: (id: any) => void,
}

type ReactRef<T> = { current: T | null }

export class Logical extends React.PureComponent<Props> {
  static defaultProps: { level: number } = { level: 1 }
  buttonRef: ReactRef<HTMLElement>
  openPicker: () => void
  addLogical: () => void
  deleteLogical: () => void
  toggleLogical: () => void

  constructor(props: Props) {
    super(props)

    this.openPicker = () => {
      this.props.openPicker(this.props.root.get('id'))
    }

    this.buttonRef = React.createRef()
    this.addLogical = () => {
      // Focus pb
      this.props.addLogical(this.props.root.get('id'))
      this.buttonRef.current?.blur()
    }
    this.deleteLogical = () => {
      this.props.deleteLogical(this.props.root.get('id'))
    }
    this.toggleLogical = () => this.props.toggleLogical(this.props.root.get('id'))
  }

  render(): React.Node {
    const {
      root,
      toggleLogical,
      toggleCondition,
      logical,
      updateCondition,
      openPicker,
      level,
      addLogical,
      canQueryEventData,
      deleteLogical,
      deleteCondition,
      createAttributeValue,
      attributesValues,
      attributesValuesLoading,
    } = this.props
    let previousWasLogical = false

    const child: any = root.get('child')

    return (
      <div className="tg">
        <div className="tg__conditionsWrap">
          <div className="tg__conditionsWrap__conditions">
            {child.map(row => {
              const content = row.get('value')
              if (row.get('kind') === 'logical') {
                const charId = row.get('id').charCodeAt(0)
                const schemeId = charId % 7
                previousWasLogical = true
                return (
                  <div
                    className={`tg_logicalAndStruf logicalScheme-${schemeId}`}
                    key={row.get('id')}
                  >
                    {child.last() !== row && (
                      <div
                        className="tg_logicalAndStruf__operator condi__logical condi__logical--bottom"
                        onClick={this.toggleLogical}
                      >
                        {logical}
                      </div>
                    )}
                    <div className="tg_logicalAndStruf__stuff">
                      <Logical
                        root={row}
                        app={this.props.app}
                        canQueryEventData={canQueryEventData}
                        platform={this.props.platform}
                        logical={content}
                        level={level + 1}
                        ttlRetargeting={this.props.ttlRetargeting}
                        openPicker={openPicker}
                        deleteLogical={deleteLogical}
                        toggleCondition={toggleCondition}
                        addLogical={addLogical}
                        toggleLogical={toggleLogical}
                        updateCondition={updateCondition}
                        deleteCondition={deleteCondition}
                        attributesValues={attributesValues}
                        attributesValuesLoading={attributesValuesLoading}
                        createAttributeValue={createAttributeValue}
                        canUseEventCountPeriod={this.props.canUseEventCountPeriod}
                      />
                    </div>
                  </div>
                )
              } else {
                const cond = (
                  <div className="tg_logicalAndStruf" key={row.get('id')}>
                    {child.last() !== row && (
                      <div
                        className="tg_logicalAndStruf__operator condi__logical condi__logical--bottom"
                        onClick={this.toggleLogical}
                      >
                        {logical}
                      </div>
                    )}
                    <div className="tg_logicalAndStruf__stuff">
                      <Condition
                        app={this.props.app}
                        ttlRetargeting={this.props.ttlRetargeting}
                        platform={this.props.platform}
                        canQueryEventData={canQueryEventData}
                        canUseEventCountPeriod={this.props.canUseEventCountPeriod}
                        previousWasLogical={previousWasLogical}
                        logical={root.get('value')}
                        id={row.get('id')}
                        attribute={content.get('attribute')}
                        args={content.get('args')}
                        value={
                          Immutable.isImmutable(content.get('value')) &&
                          content.getIn(['attribute', 'id']).substr(0, 3) !== 'bt.'
                            ? content.get('value').toJS()
                            : content.get('value', '')
                        }
                        operator={content.get('operator') ? content.get('operator').toJS() : null}
                        operators={content.get('operators') ? content.get('operators').toJS() : []}
                        func={content.get('func') ? content.get('func').toJS() : null}
                        inputType={content.get('inputType', 'text')}
                        valid={content.get('valid', false)}
                        functions={content.get('functions') ? content.get('functions').toJS() : []}
                        negate={content.get('negate')}
                        update={updateCondition}
                        toggle={toggleCondition}
                        remove={deleteCondition}
                        toggleLogical={this.toggleLogical}
                        createAttributeValue={createAttributeValue}
                        loading={
                          !!attributesValuesLoading.get(row.getIn(['value', 'attribute', 'id']))
                        }
                        attributeValues={attributesValues
                          .get(row.getIn(['value', 'attribute', 'id']))
                          .toJS()}
                      />
                    </div>
                  </div>
                )
                previousWasLogical = false
                return cond
              }
            })}
          </div>

          <div className="tg__conditionsWrap--actions">
            <FlexLine>
              <FlexLineItem grow={1}>
                <Button
                  kind="primary"
                  intent="action"
                  addOn="prefix"
                  addOnGap={6}
                  height={24}
                  onClick={this.openPicker}
                >
                  <Icon icon="add" />
                  <span style={{ marginBottom: 1 }}>Add conditions</span>
                </Button>
              </FlexLineItem>
              <FlexLineItem style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  height={24}
                  kind="inline"
                  addOn="prefix"
                  addOnGap={6}
                  disabled={level > 3}
                  ref={this.buttonRef}
                  onClick={this.addLogical}
                >
                  <Icon icon="add" />
                  <span style={{ marginBottom: 1 }}>New and/or subgroup</span>
                </Button>

                <Button
                  style={{ marginLeft: 8 }}
                  onClick={this.deleteLogical}
                  disabled={this.props.root.get('id') === 'root'}
                  kind="discreet"
                  intent="danger"
                >
                  <Icon icon="delete" />
                </Button>
              </FlexLineItem>
            </FlexLine>
          </div>
        </div>
      </div>
    )
  }
}
