// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Select } from 'components/form'

import { OperatorAndNegateFormatter, type operatorAndNegate } from './left-helper'

import { QueryBuilderContext } from '../query-builder.context'
import { getDefaultOperator, setInputType } from 'com.batch.redux/query/query.api'
import {
  allFunctions,
  allOperators,
  type ConditionRecord,
} from 'com.batch.redux/query/query.records'
import { AgeFunction } from 'com.batch.redux/query/query.records.functions'
import {
  ExistsOperator,
  InOperator,
  EqualOperator,
  NotInOperator,
  StartsWithOperator,
} from 'com.batch.redux/query/query.records.operators'
import * as Types from 'com.batch.redux/query/query.types'

type LeftBaseProps = {
  condition: ConditionRecord,
  updateCondition: ConditionRecord => void,
  negated: boolean,
  negate: () => void,
  unNegate: () => void,
  ...
}

type option = {
  label: string,
  action: ConditionRecord => ConditionRecord,
  valueMatcher: ConditionRecord => boolean,
}
const optToString = (opt: ?option) => opt?.label ?? ''
const optionOperatorToString = (opt: ?operatorAndNegate) => opt?.operator.value ?? ''

const getLabel = (opt: option) => opt.label
export const LeftBase = ({
  condition,
  updateCondition,
  negated,
  negate,
  unNegate,
}: LeftBaseProps): React.Node => {
  if (!condition.attribute) {
    throw 'invalid condition - no attribute'
  }
  const { context } = React.useContext(QueryBuilderContext)
  const onOperatorChange = React.useCallback(
    opt => {
      if (opt) {
        updateCondition(setInputType(condition.set('operator', opt.operator)))
        if (opt.negate !== negated) {
          if (opt.negate) {
            negate()
          } else {
            unNegate()
          }
        }
      }
    },
    [condition, negate, negated, unNegate, updateCondition]
  )
  const onFunctionChange = React.useCallback(
    option => {
      option && updateCondition(option.action(condition))
    },
    [condition, updateCondition]
  )

  // Flow loses type refinement in the functions bellow, so we need ?.type ?? 'STRING'
  // even if it won't be used
  const availableFunctions = React.useMemo(
    () =>
      allFunctions.filter(
        func =>
          func.accept.has(condition.attribute?.type ?? 'STRING') && func.allowedContext.has(context)
      ),
    [condition.attribute, context]
  )
  const availableOperators = React.useMemo(() => {
    const producedType = condition.functions
      .reverse()
      .reduce((type, func) => func.produce, condition.attribute?.type ?? 'STRING')
    return allOperators.filter(operator => {
      // si on a un de ces 2 attributes, on a pas le droit de prendre d'autre opérateurs
      if (['b.city_code', 'b.device_type'].includes(condition.attribute?.api)) {
        return (
          [ExistsOperator, InOperator, NotInOperator].includes(operator) &&
          operator.accept.has(producedType)
        )
      }

      if (['b.email_domain'].includes(condition.attribute?.api)) {
        return [EqualOperator, StartsWithOperator, InOperator, NotInOperator].includes(operator)
      }

      // si on est en mode position, on prend que exists
      if (condition.attribute?.api === 'b.position') return operator === ExistsOperator
      //  sinon on prend les opérateurs qui marchent avec le type produit
      return operator.accept.has(producedType)
    })
  }, [condition.attribute, condition.functions])
  const operatorWithNegate: List<operatorAndNegate> = React.useMemo(() => {
    let tmp: Array<operatorAndNegate> = []
    availableOperators.forEach(operator => {
      tmp.push({ operator, negate: false })
      if (operator.negate !== '') {
        tmp.push({ operator, negate: true })
      }
    })
    return new Immutable.List().push(...tmp)
  }, [availableOperators])
  const showExistsOperatorInFunctions = React.useMemo(
    () =>
      ExistsOperator.accept.has(condition.attribute?.type ?? 'STRING') &&
      condition.functions.size === 0,
    [condition.attribute?.type, condition.functions.size]
  )
  const fakeFunctionOptions: List<option> = React.useMemo(() => {
    if (availableFunctions.size === 0) return new Immutable.List()
    let arr = []
    availableFunctions.forEach(func =>
      arr.push({
        label: condition.attribute?.type === 'DATE' ? func.label.toLowerCase() : func.label,
        valueMatcher: c =>
          c.functions.getIn([0, 'value']) === func.value ||
          c.functions.getIn([1, 'value']) === func.value,
        action: c =>
          setInputType(
            c
              .set(
                'functions',
                Immutable.List(
                  condition.attribute?.type === Types.EVENT && func.value === 'last'
                    ? [AgeFunction, func]
                    : [func]
                )
              )
              .set('operator', getDefaultOperator(func.produce))
          ),
      })
    )
    if (showExistsOperatorInFunctions)
      arr.push({
        label:
          condition.attribute?.type === 'DATE'
            ? ExistsOperator.label.toLowerCase()
            : ExistsOperator.label,
        valueMatcher: (c: ConditionRecord) => c.operator === ExistsOperator,
        action: (c: ConditionRecord) =>
          setInputType(
            c.set('functions', new Immutable.List().push()).set('operator', ExistsOperator)
          ),
      })
    return new Immutable.List().push(...arr)
  }, [availableFunctions, condition.attribute?.type, showExistsOperatorInFunctions])

  return (
    <FlexLine>
      {fakeFunctionOptions.size > 1 && (
        <FlexLineItem>
          <Select
            style={{
              width: condition.attribute?.type === 'DATE' && context === 'event_filter' ? 100 : 150,
            }}
            options={fakeFunctionOptions}
            value={fakeFunctionOptions.find(option => option.valueMatcher(condition), null)}
            optionToString={optToString}
            optionFormatter={getLabel}
            onChange={onFunctionChange}
          />
        </FlexLineItem>
      )}
      {availableOperators.size > 0 && condition.attribute?.api !== 'b.position' && (
        <FlexLineItem>
          <Select
            style={{ width: 200 }}
            options={operatorWithNegate}
            optionToString={optionOperatorToString}
            optionFormatter={OperatorAndNegateFormatter}
            value={operatorWithNegate.find(
              ({ operator, negate }) =>
                operator.value === condition.operator.value && negated === negate
            )}
            onChange={onOperatorChange}
          />
        </FlexLineItem>
      )}
    </FlexLine>
  )
}
