// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { Label, type LabelVariant } from 'components/common/label/label.styles'
import { colors, schemes } from 'components/styled/tokens'

import { BlockTitle } from './common.styles'

const calculateBackgroundPosition = (channel: ChannelUntilCleanup) => {
  switch (channel) {
    case 'sms':
      return 'center'
    case 'email':
      return 'bottom center'
    case 'push':
      return 'bottom right'
    default:
      return 'bottom right'
  }
}

const calculateBackgroundSize = (channel: ChannelUntilCleanup) => {
  switch (channel) {
    case 'sms':
      return '243px 105px'
    case 'email':
      return '243px 99px'
    case 'push':
      return '278px 118px'
    default:
      return '243px 105px'
  }
}

type msgProps = { isEmpty: boolean, $isShowingStat: boolean, $channel: ChannelUntilCleanup, ... }
export const MessageBlockFrame: StyledComponent<msgProps, {}, *> = styled.div`
  width: 100%;
  pointer-events: ${(p: msgProps) => (p.$isShowingStat ? 'auto' : 'none')};
  cursor: pointer;
  background-color: ${colors.fill};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  ${(p: msgProps) =>
    p.$channel === 'sms' &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  iframe {
    z-index: 1;
    transform-origin: 0 0;
    transform: scale(0.7);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 351px;
    height: 200px;
    margin: 16px 16px 0 16px;
    box-shadow:
      0px 1px 4px 0px rgba(15, 15, 15, 0.06),
      0px 1px 12px 0px rgba(15, 15, 15, 0.08);
    border: 1px solid ${colors.stroke};
  }
  height: 120px;
  border-bottom: 1px solid ${colors.stroke};
  ${(p: msgProps) =>
    p.isEmpty &&
    css`
      background-size: ${calculateBackgroundSize(p.$channel)};
      background-image: url('/medias/img/illustrations/multistep/${p.$channel}-empty@2x.png');
      background-position: ${calculateBackgroundPosition(p.$channel)};
      background-color: ${colors.fill};
      background-repeat: no-repeat;
    `}
`
type MessageBlockContainerProps = {
  $hasError: boolean,
  $isEditing: boolean,
  ...
}
export const MessageBlockContainer: StyledComponent<MessageBlockContainerProps, {}, *> =
  styled.section`
    width: 280px;
    background-color: ${colors.fill};
    border-radius: 10px;
    white-space: pre-line;
    overflow-wrap: anywhere;
    outline: ${(p: MessageBlockContainerProps) => {
      if (p.$isEditing) return `2px solid ${colors.fillAction}`
      else if (p.$hasError) return `2px solid ${colors.strokeDanger}`
      return `1px solid ${colors.stroke}`
    }};
    box-shadow:
      0px 1px 3px 0px rgba(15, 15, 15, 0.08),
      0px 1px 8px 0px rgba(15, 15, 15, 0.02);
    cursor: pointer;
    .placeholder {
      margin-top: 12px;
      text-align: center;
      font-weight: 500;
    }
    &:hover,
    &:hover ${MessageBlockFrame} {
      background-color: ${schemes.grayscale['05']};
    }
    &:active,
    &:active ${MessageBlockFrame} {
      background-color: ${schemes.grayscale['10']};
    }
  `

export const IncompleteFlag: StyledComponent<{ ... }, {}, HTMLDivElement> = styled.div`
  background: ${schemes.orange['40']};
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: -12px;
  right: 12px;
`
export const MessageBlockTitle: StyledComponent<{ ... }, {}, *> = styled.div`
  padding: 12px;
  }
`
export const MessageTitle: StyledComponent<{ ... }, {}, *> = styled.div`
  ${BlockTitle};

  /* truncate text after 2 lines with ellipsis https://css-tricks.com/line-clampin/#aa-the-standardized-way */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
type StatPillProps = { variant?: LabelVariant, ... }
export const StatPill: StyledComponent<StatPillProps, {}, *> = styled(Label)`
  border-radius: 8px;
  display: inline-grid;
  height: 28px;
  margin: 4px;
  grid-template-columns: 16px auto;
  align-items: center;
  text-align: right;
  font-size: 14px;
`
