// @flow

import * as React from 'react'

import { Button, DropdownMenu, useDropdown } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

type LogicalToggleProps = {
  value: 'and' | 'or',
  onChange: ('and' | 'or') => void,
  ...
}

const activeStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.02)',
  color: (colors.textNeutral: string),
}

const LogicalToggleRaw = ({ value, onChange }: LogicalToggleProps): React.Node => {
  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({ placement: 'bottom' })
  return (
    <React.Fragment>
      <Button
        {...triggerProps}
        kind="primary"
        style={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          height: 18,
          paddingLeft: 3,
          paddingRight: 3,
          marginTop: -9,
          fontSize: 11,
          justifyContent: 'flex-start',
          position: 'absolute',
        }}
      >
        <Grid template="20px 10px">
          {value.toUpperCase()}
          <Icon icon="select" size={9} />
        </Grid>
      </Button>
      <DropdownMenu {...dropdownProps}>
        <Button
          addOn="suffix"
          onClick={() => {
            closeDropdown()
            onChange('and')
          }}
          style={value === 'and' ? activeStyle : {}}
        >
          And
          {value === 'and' && <Icon icon="check" />}
        </Button>
        <Button
          addOn="suffix"
          onClick={() => {
            closeDropdown()
            onChange('or')
          }}
          style={value === 'or' ? activeStyle : {}}
        >
          Or
          {value === 'or' && <Icon icon="check" />}
        </Button>
      </DropdownMenu>
    </React.Fragment>
  )
}
export const LogicalToggle = (React.memo(
  LogicalToggleRaw
): React.AbstractComponent<LogicalToggleProps>)
