// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import { currentProjectAppsSelector } from 'com.batch.redux/app'

import { type PushMessageRawRecord } from 'com.batch/message/models/message.records'
import { PushPreview } from 'com.batch/orchestration-journey/ui/components/nodes/push-preview'

export const PushPreviewWrapper = ({ content }: { content: PushMessageRawRecord }): React.Node => {
  const apps = useSelector(currentProjectAppsSelector)

  const appIcon: string = React.useMemo(() => {
    return (
      apps.find(app => app.platform === 'ios')?.icon ??
      apps.find(app => app.platform === 'android')?.icon
    )
  }, [apps])

  return (
    <PushPreview
      body={content.pushBody}
      title={content.pushTitle}
      appIconUrl={appIcon}
      mediaThumbnailUrl={content.pushPicture}
    />
  )
}
