// @flow
import { Map } from 'immutable'
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { pluralize } from 'com.batch.common/utils'

import { platforms as listPlatforms } from 'com.batch/profile/constants/platforms'
import { type InstallRecord } from 'com.batch/profile/models/profile.records'
import { ProfilePlatformsCounterContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  installs: Map<string, InstallRecord>,
  ...
}

export const ProfilePlatformsCounter = ({ installs }: Props): React.Node => (
  <ProfilePlatformsCounterContainer>
    <span>{pluralize('platform', installs.size)}</span>
    {installs.toArray().map(([, install]) => (
      <Icon
        key={listPlatforms[install.platform].icon}
        icon={listPlatforms[install.platform].icon}
      />
    ))}
  </ProfilePlatformsCounterContainer>
)
