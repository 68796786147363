// @flow
import { createSelector } from 'reselect'

import { type State, type CompanyRecord } from 'com.batch.redux/_records'

export function companySelector(state: State): CompanyRecord {
  return state.company
}

export const devApiKeyFeatureSelector: (state: State) => boolean = createSelector(
  companySelector,
  companyState => companyState.additionalFeaturesCode.includes('dev-api-key')
)
