// @flow
import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'

import { TimelineIcon } from '../icons'
import { TimelineContent } from '../timeline/timeline.styles'

type TimelineProps = { ... }

export const Timeline = ({}: TimelineProps): React.Node => {
  return (
    <TimelineContent>
      <Skeleton w={18} h={18} style={{ borderRadius: '50%' }}>
        <TimelineIcon />
      </Skeleton>
    </TimelineContent>
  )
}
