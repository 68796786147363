// @flow
import * as React from 'react'

import * as Themes from 'components/campaign/preview/preview.theme'

import { type AppRecord } from 'com.batch.redux/_records'

type PreviewFitterProps = {
  app: AppRecord,
  previewWeb: string,
  fit: 'width' | 'height',
  render: ({ width: number, height: number, ... }) => any,
  ...
}

type PreviewFitterState = {
  width: number,
  height: number,
  ...
}

export class PreviewFitter extends React.PureComponent<PreviewFitterProps, PreviewFitterState> {
  rqf: ?() => void
  parentNode: ?HTMLElement
  element: ?HTMLElement
  constructor(props: PreviewFitterProps) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
    }
  }
  setRef: (elem: ?HTMLElement) => void = elem => {
    // $FlowFixMe pfff
    this.parentNode = elem ? elem.parentNode : null
    this.element = elem ? elem : null
  }
  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    if (this.rqf) {
      this.rqf = null
      window.cancelAnimationFrame(this.rqf)
    }
  }
  onResize: () => void = () => {
    if (this.rqf) return
    this.rqf = window.requestAnimationFrame(() => {
      this.rqf = null
      this.updateDimensions()
    })
  }
  updateDimensions: () => void = () => {
    if (this.element) {
      this.element.style.width = '0'
      this.element.style.height = '0'
      this.element.style.overflow = 'hidden'
      const st = window.getComputedStyle(this.parentNode, null)
      const pl = parseInt(st.getPropertyValue('padding-left'))
      const pr = parseInt(st.getPropertyValue('padding-right'))
      const pt = parseInt(st.getPropertyValue('padding-top'))
      const pb = parseInt(st.getPropertyValue('padding-bottom'))
      const width = this.parentNode ? this.parentNode.clientWidth - pl - pr : 50
      const height = this.parentNode ? this.parentNode.clientHeight - pt - pb : 50
      if (this.element) {
        this.element.style.width = 'auto'
        this.element.style.height = 'auto'
        this.element.style.overflow = 'visible'
      }
      this.setState({ width, height })
    }
  }

  render(): React.Node {
    const platform: Platforms = this.props.app.platform
    // $FlowFixMe it's fine
    let heightWithPx = Themes[this.props.previewWeb === 'android' ? 'android' : platform].height
    const height = parseInt(heightWithPx.replace('px', ''))
    const width = 400
    const ratio =
      this.props.fit === 'height'
        ? Math.min(1, this.state.height / height)
        : Math.min(1, this.state.width / width)
    const dim = {
      width: Math.floor(width * ratio),
      height: Math.floor(height * ratio),
    }
    return <div ref={this.setRef}>{this.props.render(dim)}</div>
  }
}
