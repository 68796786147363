// @flow

import Immutable, { type List, type RecordFactory, type RecordOf } from 'immutable'

import * as ACT from './action-name'
import { SWITCH_PAGE, UPDATE_CAMPAIGN_CONFIG } from './action-name'

import {
  CampaignConfigFactory,
  CampaignFactory,
  CampaignStateFactory,
  type CampaignRecord,
  type ReduxAction,
  AbTestedThemeCodeFactory,
} from 'com.batch.redux/_records'
import * as actions from 'com.batch.redux/campaign.action'
import { buildActiveFilters } from 'com.batch.redux/campaign.api.filters'
import { type ApiPushCampaign } from 'com.batch.redux/project.api.model'

// re-export
export { actions }
export * from 'com.batch.redux/campaign.selector'

type CtaActionProps = {
  label: string,
  value: 'dismiss' | 'deeplink' | 'custom',
  ...
}
const CtaActionFactory: RecordFactory<CtaActionProps> = Immutable.Record(
  ({
    label: '',
    value: 'dismiss',
  }: CtaActionProps)
)

type CtaActionRecord = RecordOf<CtaActionProps>

export const CTA_ACTIONS: List<CtaActionRecord> = new Immutable.List().push(
  ...[
    CtaActionFactory({
      label: 'Dismiss',
      value: 'dismiss',
    }),
    CtaActionFactory({
      label: 'Deeplink',
      value: 'deeplink',
    }),
    CtaActionFactory({
      label: 'Custom',
      value: 'custom',
    }),
  ]
)

// ====================== TEMPORARY TYPES
// Campaigns actions hasn't been typed with flow yet.
// We typed some of it because we have to use it in messages reducer.
export type setCampaignStateSuccess = {
  type: 'SET_CAMPAIGN_STATE_SUCCESS',
  payload: { state: 'DRAFT' | 'STOPPED' | 'RUNNING', ... },
  ...
}
export type setCampaignStateFailureAction = {
  type: 'SET_CAMPAIGN_STATE_FAILURE',
  payload: any,
  ...
}

export type saveCampaignSuccessAction = {
  type: 'SAVE_CAMPAIGN_SUCCESS',
  payload: any,
  ...
}
export type saveCampaignFailureAction = {
  type: 'SAVE_CAMPAIGN_FAILURE',
  payload: any,
  ...
}

export type fetchCampaignFailureAction = {
  type: 'FETCH_CAMPAIGN_FAILURE',
  payload: any,
  ...
}
export type fetchCampaignsFailureAction = {
  type: 'FETCH_CAMPAIGNS_FAILURE',
  payload: { appId: number, ... },
  ...
}
export type archivedCampaignSuccess = {
  type: 'ARCHIVE_CAMPAIGN_SUCCESS',
  payload: any,
  ...
}
export type saveProjectCampaignAction = {
  type: 'SAVE_PROJECT_CAMPAIGN',
  payload: { ... },
  ...
}
export type saveProjectCampaignSuccessAction = {
  type: 'SAVE_PROJECT_CAMPAIGN_SUCCESS',
  payload: { apiCampaign: ApiPushCampaign, tokens: Array<string>, ... },
  ...
}
export type saveProjectCampaignFailureAction = {
  type: 'SAVE_PROJECT_CAMPAIGN_FAILURE',
  payload: string,
  ...
}
// ====================== END OF TEMPORARY TYPES

const initialCampaignState = CampaignFactory()

const queryString = new URLSearchParams(window.location.search)
function getQParam(key: string, defval: string): string {
  const data = queryString.get(key)
  return data !== null ? data : defval
}

const storedAdvancePane = window.localStorage.getItem('advancedPaneOpened')

const kind = window.location.href.includes('/in-app')
  ? 'in-app'
  : window.location.href.includes('/email')
    ? 'email'
    : 'push'
const schedulingType =
  window.location.href.indexOf('/automations') !== -1 ? 'automations' : 'campaigns'

const initialState = CampaignStateFactory({
  entities: Immutable.Map().set(
    'new',
    CampaignFactory({
      type: kind,
      sendType: schedulingType === 'automations' ? 'trigger' : 'scheduled',
    })
  ),
  config: CampaignConfigFactory({
    type: kind,
    schedulingType,
    sortBy: getQParam('sortBy', 'id'),
    page: parseInt(getQParam('page', '1')),
    sortOrder: getQParam('sortOrder', 'dsc') === 'dsc' ? 'dsc' : 'asc',
    advancedPaneOpened: storedAdvancePane === true || storedAdvancePane === 'true',
    filters: buildActiveFilters({
      queryString,
      schedulingType,
      platform: window?.initialData?.app?.platform ?? 'ios',
      persistedCommaSeparatedFilters: String(
        window.localStorage.getItem(`${schedulingType}-filters`)
      ),
    }),
  }),
})

// ========================================================
// REDUCER
// ========================================================

function singleCampaignReducer(
  state: CampaignRecord = initialCampaignState,
  action: ReduxAction<string, any>
) {
  const payload = action.payload

  switch (action.type) {
    case 'INIT_FORM': {
      return state
        .set('triggerConfig', state.triggerConfig.set('hasExitEvent', false))
        .set('schedulingType', payload.schedulingType)
        .set('sendType', payload.sendType)
        .set('translations', Immutable.OrderedSet(['default']))
        .set('type', payload.type)
        .set('tzAware', true)
        .set('repeatFrequency', payload.sendType === 'recurring' ? 1 : 0)
    }
    case 'CAMPAIGN_TOGGLE_CHANNELS': {
      return state
        .set('channels', payload)
        .set('type', payload.includes('email') ? 'email' : 'push')
    }
    case 'CAMPAIGN_TOGGLE_JIT':
      return state.set('inappJustInTime', !state.inappJustInTime)
    case 'UPDATE_INAPP_PRIORITY':
      return state.set('inAppPriority', payload)
    case 'TARGETING_UPD_AUDIENCE':
      return state.set('customAudiences', payload.audiences)
    case ACT.SAVE_PRESSURE_SUCCESS:
      if (state.pickedLabels.size >= 3) {
        return state
      }
      return state.set('pickedLabels', state.get('pickedLabels').add(payload.categorie))
    case ACT.FETCH_CAMPAIGN_LABELS_COUNTS_FINAL:
      return state.set(
        'pickedLabels',
        state.get('pickedLabels').map(label => {
          return payload.find(l => l.id === label.id)
        })
      )
    case ACT.TOGGLE_CAMPAIGN_LABEL:
      return state.set(
        'pickedLabels',
        state.get('pickedLabels').find(label => label.code === payload.code, false)
          ? state.get('pickedLabels').filter(label => label.id !== payload.id)
          : state.get('pickedLabels').add(payload)
      )
    case ACT.PUSH_ENABLE_ABTESTING:
      return state
        .setIn(['abtesting', 'enabled'], true)
        .setIn(['abtesting', 'activeVariants'], Immutable.Set(['a', 'b']))
    case ACT.PUSH_DISABLE_ABTESTING:
      return state
        .setIn(['abtesting', 'enabled'], false)
        .setIn(['abtesting', 'activeVariants'], Immutable.Set(['a']))
    case ACT.PUSH_ABTESTING_TOGGLE_VARIANT: {
      let variants = state.abtesting.activeVariants
      variants = variants.has(payload.variant)
        ? variants.delete(payload.variant)
        : variants.add(payload.variant)
      if (variants.size === 0) {
        variants = variants.add(payload.variant === 'a' ? 'b' : 'a')
      }
      return state.setIn(['abtesting', 'activeVariants'], variants)
    }

    case ACT.RESET_CAMPAIGN_ATTACHMENT: // $FlowFixMe woké
      return state.set('attachment', Immutable.fromJS(payload))
    case ACT.SET_CAMPAIGN_LANDING:
      return state.set('hasLanding', payload.active)
    case ACT.FETCH_CAMPAIGN:
    case ACT.SET_CAMPAIGN_STATE:
      return state.set('loading', true)
    case ACT.ARCHIVE_CAMPAIGN:
    case ACT.SAVE_CAMPAIGN:
      return state.set('saving', true)
    case ACT.SET_CAMPAIGN_STATE_SUCCESS:
      return state.set('loading', false).set('state', payload.state)
    case ACT.SET_CAMPAIGN_STATE_FAILURE:
    case ACT.FETCH_CAMPAIGN_FAILURE:
    case 'FETCH_CAMPAIGN_FAILURE':
      return state.set('loading', false).set('_id', 'error')
    case ACT.ARCHIVE_CAMPAIGN_FAILURE:
    case ACT.SAVE_CAMPAIGN_FAILURE:
      return state.set('saving', false)
    case 'FETCH_ATTRIBUTES_SUCCESS':
    case 'FETCH_ATTRIBUTES_FAILURE': {
      if (state.token !== 'new' || state.channels.has('email')) return state
      // sur le trigger push, on regarde si on des events pertinents et on désactive sur une new
      const canUseTriggerExitEvent =
        action.type === 'FETCH_ATTRIBUTES_SUCCESS' &&
        action.payload.data.filter(
          attr => (attr.category === 'event' || attr.category === 'user_event') && !attr.hidden
        ).size > 0
      return canUseTriggerExitEvent
        ? state
        : state.set('triggerConfig', state.triggerConfig.set('hasExitEvent', false))
    }
    case ACT.FETCH_CAMPAIGN_SUCCESS:
      return state.merge(state, payload)
    case ACT.FETCH_APP_BY_ID_SUCCESS: {
      // append the default language translation (if not already there)
      const defaultLanguageId = payload.app.defaultLanguageId || 'default'
      return state.get('translations').has(defaultLanguageId)
        ? state
        : state.set('translations', state.get('translations').add(defaultLanguageId))
    }
    case ACT.FETCH_THEMES_SUCCESS: {
      const themesForApp = payload.themes.filter(t => t.apps.has(payload.appId))

      if (state.variantsThemeCodes.a) {
        return state
      } else {
        if (themesForApp.length) {
          return state.set(
            'variantsThemeCodes',
            AbTestedThemeCodeFactory({ a: themesForApp[0].code, b: themesForApp[0].code })
          )
        } else {
          return state.set('variantsThemeCodes', AbTestedThemeCodeFactory({ a: null, b: null }))
        }
      }
    }
    case ACT.UPDATE_IA_TRIGGER:
      return state // $FlowFixMe
        .set('trigger', payload)
        .set('triggerLabel', null)
    case ACT.UPDATE_IA_TRIGGER_LABEL:
      return state.set('triggerLabel', payload)
    case ACT.UPDATE_CAMPAIGN_STATE:
      return state.set('state', payload)
    case ACT.UPDATE_PUSH_WHEN:
      return state.set(payload.what, payload.value)
    case ACT.UPDATE_CAMPAIGN_TZ_AWARE:
      return state.set('tzAware', payload)
    case ACT.UPDATE_IA_CAPPING:
      return state.set('capping', !isNaN(parseInt(payload)) ? parseInt(payload) : null)
    case ACT.UPDATE_IA_GRACE:
      return state.set('gracePeriod', parseInt(payload))
    case ACT.UPDATE_LANDING_THEME:
      return state.setIn(['variantsThemeCodes', payload.variant], payload.theme.code)
    case ACT.UPDATE_CAMPAIGN_START:
      return state.set('start', payload)
    case ACT.UPDATE_CAMPAIGN_END:
      return state.set('end', payload)
    case ACT.ADD_CAMPAIGN_TRANSLATION:
      return state.set('translations', state.get('translations').add(payload.value))
    case ACT.REMOVE_CAMPAIGN_TRANSLATION:
      return state.set('translations', state.get('translations').delete(payload.value))

    default:
      return state
  }
}
// $FlowFixMe one day this will be good
function campaignReducer(state: State = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'T_QUERY_PARSED':
      return state.set('config', state.config.set('queryParsed', true))
    case 'CAMPAIGN_HAS_QUERIES_TO_PARSE':
      return state.set('config', state.config.set('queryParsed', !payload))
    case 'UPDATE_ALL_CONTENT':
      return state.set('config', state.config.set('contentParsed', true))
    case 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_SUCCESS': {
      return state.set(
        'config',
        state.config
          .set('previewSourceKind', payload.kind)
          .set('previewSourceValue', payload.value)
          .set('previewSourceProfileId', payload.profileId)
      )
    }
    case 'FETCH_PROJECT_CAMPAIGN_SUCCESS':
      return state
        .setIn(['config', 'type'], payload.type)
        .setIn(['config', 'contentParsed'], false)
        .setIn(['entities', payload.token], action.payload)
    case 'FETCH_PROJECT_CAMPAIGN': {
      return state
        .setIn(['config', 'editing'], payload)
        .setIn(['entities', payload], CampaignFactory({ token: payload, loading: true }))
    }
    case 'SET_INLINE_EDITING_CONFIG_LEGACY':
      return state.set('config', state.config.set('inlineEditorConfig', payload))
    case 'PT_TEST_DEVICE':
      return state.set('loadingTest', true)

    case 'PT_TEST_DEVICE_SUCCESS':
    case 'PT_TEST_DEVICE_FAILURE':
      return state.set('loadingTest', false)
    case ACT.REPLICATE_CLEAR_RESULTS:
      return state.set('replication', new Immutable.List())
    case ACT.REPLICATE_CAMPAIGNS:
      return state.set('replication', Immutable.List(payload))
    case ACT.REPLICATE_CAMPAIGN_SUCCESS: {
      const index = state.get('replication').findIndex(rec => rec.appId === payload.app)
      return state.set(
        'replication',
        state
          .get('replication')
          .update(index, val => val.set('loading', false).set('token', payload.token))
      )
    }
    case ACT.REPLICATE_CAMPAIGN_FAILURE: {
      const inde = state.get('replication').findIndex(rec => rec.appId === payload.app)
      return state.set(
        'replication',
        state
          .get('replication')
          .update(inde, val => val.set('loading', false).set('error', payload.error))
      )
    }
    case ACT.PUSH_ABTESTING_SHOW_VARIANT:
      return state.setIn(['config', 'abtesting'], payload.variant)
    case ACT.DISPLAY_CAMPAIGN_ADVCANCED:
      window.localStorage.setItem('advancedPaneOpened', !!payload)
      return state.setIn(['config', 'advancedPaneOpened'], !!payload)
    case ACT.FETCH_CAMPAIGNS_INIT:
      return state.setIn(['config', 'type'], payload.type)
    case ACT.SET_CAMPAIGN_ACTIVE_TRANSLATION:
      return state.setIn(['config', 'activeLanguageId'], payload)
    case ACT.SET_EDITING_CAMPAIGN: {
      let trans = state.entities.get('new', CampaignFactory()).translations
      const { schedulingType, recurringLegacyDisabled } = action.payload
      if (payload.token === 'new') {
        const sendType =
          schedulingType === 'automations'
            ? recurringLegacyDisabled
              ? 'trigger'
              : 'recurring'
            : 'scheduled'
        state = state.set(
          'entities',
          state.entities.set(
            'new',
            state.entities
              .get('new', CampaignFactory())
              .set('translations', trans.add('default'))
              .set('type', action.payload.type)
              .set('schedulingType', schedulingType)
              .set('sendType', sendType)
          )
        )
        state = state.setIn(['config', 'activeLanguageId'], 'default')
      }
      return state
        .setIn(['config', 'editing'], payload.token)
        .setIn(['config', 'type'], payload.type)
    }
    case ACT.FETCH_CAMPAIGNS:
      return state.set('loadingCount', true).set('loading', true).set('failure', false)
    case ACT.GET_CAMPAIGNS_FROM_OPTIONS:
      return state.set('entities', payload.merge(state.entities))
    case ACT.FETCH_CAMPAIGNS_FAILURE:
      return state
        .set('loadingCount', false)
        .set('pagerLoading', false)
        .set('loading', false)
        .set('countFiltered', 0)
        .set('countTotal', 0)
        .set('failure', true)
    case ACT.FETCH_CAMPAIGNS_SUCCESS:
      return state
        .set('loadingCount', false)
        .set('pagerLoading', false)
        .set('loading', false)
        .set('countFiltered', payload.countFiltered)
        .set('countTotal', payload.countTotal)
        .set('failure', false)
        .setIn(['config', 'page'], payload.page)
        .setIn(
          ['idsPerPage', payload.page],
          Immutable.List(payload.entities.map(campaign => campaign.get('token')))
        )
        .set(
          'entities',
          state.get('entities').merge(
            // could probably be marginaly faster if only appending new
            Immutable.Map(
              payload.entities.map(campaign => [
                campaign.get('token'),
                singleCampaignReducer(initialCampaignState, {
                  type: ACT.FETCH_CAMPAIGN_SUCCESS,
                  payload: campaign,
                }),
              ])
            )
          )
        )

    case UPDATE_CAMPAIGN_CONFIG: {
      const { config, previousConfig } = action.payload
      const hasEqualsFilters = config.delete('page').equals(previousConfig.delete('page'))
      if (!hasEqualsFilters) {
        state = state
          .set('idsPerPage', Immutable.Map())
          .set('pagerLoading', true)
          .set('loading', true)
        window.localStorage.setItem(
          `${config.schedulingType}-filters`,
          config.filters.commons
            .toArray()
            .map(filter => filter.name)
            .join(',')
        )
      }
      return state.set('config', config)
    }

    case ACT.SAVE_CAMPAIGN_SUCCESS:
      if (state.getIn(['config', 'editing']) === 'new') {
        return state.setIn(['entities', 'new'], CampaignFactory())
      } else {
        return state
      }
    // when we add / remove language, we got stuff here too
    // WARNING : ULTRA UGLY AND BUG PRONE : we don't return in this case statement.
    case ACT.FETCH_APP_BY_ID_SUCCESS:
      state = state.setIn(
        ['config', 'activeLanguageId'],
        payload.app.defaultLanguageId || 'default'
      )
    // TODO à améliorer voir comment ci-dessus
    // eslint-disable-next-line no-fallthrough
    case 'INIT_FORM': {
      return state
        .setIn(['config', 'type'], payload.type)
        .setIn(['config', 'editing'], 'new')
        .setIn(['config', 'contentParsed'], true)
        .setIn(['config', 'queryParsed'], true)
        .setIn(
          ['entities', 'new'],
          singleCampaignReducer(state.getIn(['entities', 'new'], initialCampaignState), action)
        )
    }
    case ACT.FETCH_CAMPAIGN:
    case ACT.SAVE_CAMPAIGN:
    case 'SET_CAMPAIGN_TEMPLATE':
    case ACT.ARCHIVE_CAMPAIGN:
    case ACT.SAVE_CAMPAIGN_FAILURE:
    case ACT.SAVE_PRESSURE_SUCCESS:
    case ACT.ARCHIVE_CAMPAIGN_FAILURE:
    case ACT.ARCHIVE_CAMPAIGN_SUCCESS:
    case ACT.SET_CAMPAIGN_LANDING:
    case ACT.SET_CAMPAIGN_STATE:
    case ACT.SET_CAMPAIGN_STATE_SUCCESS:
    case ACT.SET_CAMPAIGN_STATE_FAILURE:
    case ACT.FETCH_CAMPAIGN_FAILURE:
    case ACT.FETCH_CAMPAIGN_SUCCESS:
    case ACT.FETCH_THEMES_SUCCESS:
    case ACT.UPDATE_IA_TRIGGER:
    case ACT.UPDATE_IA_TRIGGER_LABEL:
    case ACT.UPDATE_CAMPAIGN_TZ_AWARE:
    case ACT.UPDATE_IA_CAPPING:
    case ACT.UPDATE_IA_GRACE:
    case ACT.UPDATE_CAMPAIGN_STATE:
    case ACT.UPDATE_LANDING_THEME:
    case ACT.UPDATE_CAMPAIGN_START:
    case ACT.UPDATE_CAMPAIGN_END:
    case ACT.RESET_CAMPAIGN_ATTACHMENT:
    case ACT.ADD_CAMPAIGN_TRANSLATION:
    case ACT.UPDATE_PUSH_WHEN:
    case ACT.REMOVE_CAMPAIGN_TRANSLATION:
    case ACT.TOGGLE_CAMPAIGN_LABEL:
    case ACT.FETCH_CAMPAIGN_LABELS_COUNTS_FINAL:
    case ACT.PUSH_DISABLE_ABTESTING:
    case ACT.PUSH_ENABLE_ABTESTING:
    case ACT.PUSH_ABTESTING_TOGGLE_VARIANT:
    case 'FETCH_ATTRIBUTES_SUCCESS':
    case 'FETCH_ATTRIBUTES_FAILURE':
    case 'CAMPAIGN_TOGGLE_JIT':
    case 'CAMPAIGN_TOGGLE_CHANNELS':
    case 'TARGETING_UPD_AUDIENCE':
    case 'UPDATE_INAPP_PRIORITY':
    case 'FETCH_PROJECT_CAMPAIGN_FAILURE': {
      const token =
        typeof payload === 'object' && payload !== null && payload.token
          ? payload.token
          : state.getIn(['config', 'editing'])
      if (!token) {
        return state
      }
      return state.setIn(
        ['entities', token],
        singleCampaignReducer(state.getIn(['entities', token], initialCampaignState), action)
      )
    }
    case 'FETCH_CUSTOM_ANALYTICS':
      return state.set('exportLoaded', false)
    case 'FETCH_CUSTOM_ANALYTICS_SUCCESS':
      return state.set('exportLoaded', true).set('data', payload)
    case SWITCH_PAGE:
      if (!state.get('idsPerPage').get(payload.page)) {
        state = state.set('loading', true)
      }
      return state.set('pagerLoading', false)
    default:
      return state
  }
}

export default campaignReducer
