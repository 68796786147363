// @flow
/* globals google */

import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import config from 'com.batch.common/config'

let loadingMap = false
let googleMapCallbacks: any = []
let geocoder: any = null
let reverseCache: any = {}

type Position = {
  lat: number,
  lng: number,
  ...
}

type OptionalProps = {
  initialZoom?: number,
  center?: Position,
  radius?: number | string,
}

type Props = {
  updatePosition: (arg: any) => void,
  updateRadius: (radius: number) => void,
  position?: Position | null,
  ...OptionalProps,
}

type State = {
  loading: boolean,
  query: string,
  text: string,
  ...
}

export class MapPicker extends React.PureComponent<Props, State> {
  mapId: string
  map: any
  marker: any
  circle: any

  static defaultProps: OptionalProps = {
    center: { lat: 48.85341, lng: 2.3488 },
    initialZoom: 9,
    radius: 500,
  }

  constructor(props: Props) {
    super(props)
    this.map = null
    this.mapId = `map${Math.floor(Math.random() * 100000000) + Date.now()}`
    this.state = {
      loading: false,
      query: '',
      text: '',
    }
    this.marker = null
    const bindMe = [
      'onSubmit',
      'drawMap',
      'drawPosition',
      'onQueryChanged',
      'onPositionChanged',
      'onRadiusChanged',
      'reverse',
    ]
    bindMe.forEach(k => {
      // $FlowFixMe yolo
      this[k] = this[k].bind(this)
    })
  }

  onSubmit(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault()
    this.search()
  }

  onQueryChanged(event: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ query: event.target.value })
  }

  onPositionChanged(event: any) {
    this.props.updatePosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    })
  }

  onRadiusChanged(event: any) {
    this.props.updateRadius(event.target.value * 1)
  }

  drawMap() {
    const mapElement = document.getElementById(this.mapId)
    if (!mapElement) {
      window.setTimeout(() => {
        this.drawMap()
      }, 60)
    } else {
      // $FlowIgnore lazy
      this.map = new google.maps.Map(document.getElementById(this.mapId), {
        zoom: this.props.initialZoom,
        center: this.props.center,
        clickableIcons: false,
        mapTypeControl: false,
        streetViewControl: false,
      })
      this.drawPosition(this.props)
      this.map.addListener('click', e => this.onPositionChanged(e))
    }
  }

  static canDrawPosition(props: Props): ?boolean {
    const { position } = props
    return position && position.lat !== undefined && position.lng !== undefined
  }

  drawPosition(props: Props) {
    if (MapPicker.canDrawPosition(props)) {
      const pos = props.position
      if (pos) {
        this.setState({
          text: `${pos.lat.toFixed(4)},${pos.lng.toFixed(4)}`,
        })
      }
      if (this.marker === null) {
        this.marker = new window.google.maps.Marker({
          position: props.position,
          map: this.map,
        })
        this.reverse(props)
        this.circle = new window.google.maps.Circle({
          strokeColor: '#00A1E0',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#00A1E0',
          fillOpacity: 0.25,
          map: this.map,
          center: props.position,
          radius: props.radius,
        })
        this.circle.addListener('click', e => this.onPositionChanged(e))
      } else {
        this.marker.setPosition(props.position)
        this.reverse(props)
        this.map.panTo(props.position)
        this.circle.setCenter(props.position)
        this.circle.setRadius(props.radius)
      }
      this.map.fitBounds(this.circle.getBounds())
    }
  }

  reverse(props: Props) {
    const pos = props.position
    if (pos) {
      const cacheKey = `${pos.lat.toFixed(6)}-${pos.lat.toFixed(6)}`
      if (typeof reverseCache[cacheKey] !== 'undefined') {
        this.setState({ text: reverseCache[cacheKey] })
      }
      geocoder.geocode({ location: props.position }, (results, status) => {
        if (status === 'OK') {
          if (results[1]) {
            reverseCache[cacheKey] = results[1].formatted_address
            this.setState({ text: reverseCache[cacheKey] })
          }
        }
      })
    }
  }

  componentDidMount() {
    if (!window._mapBatchInitialised) {
      if (!loadingMap) {
        loadingMap = true
        window._mapBatchOnInit = () => {
          window._mapBatchInitialised = true
          geocoder = new window.google.maps.Geocoder()
          for (let indice = 0; indice < googleMapCallbacks.length; indice = indice + 1) {
            googleMapCallbacks[indice]()
          }
          googleMapCallbacks = []
        }
        const tag = document.createElement('script')
        tag.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&callback=_mapBatchOnInit`
        document.body?.appendChild(tag)
      }
      googleMapCallbacks.push(() => this.drawMap())
    } else {
      this.drawMap()
    }
  }

  search() {
    this.setState({ loading: true })
    geocoder.geocode({ address: this.state.query }, (results, status) => {
      this.setState({ loading: false })
      if (status === 'OK') {
        this.setState({ query: '' })
        this.onPositionChanged({ latLng: results[0].geometry.location })
      }
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (this.map) {
      if (this.marker === null) {
        this.map.setCenter(this.props.center)
        this.drawPosition(this.props)
      } else {
        this.marker.setPosition(this.props.position)
        this.reverse(this.props)
        this.map.panTo(this.props.position)
        this.circle.setCenter(this.props.position)
        if (this.props.radius) {
          this.circle.setRadius(this.props.radius)
        }

        this.map.fitBounds(this.circle.getBounds())
      }

      if (this.props.radius !== prevProps.radius || this.props.position !== prevProps.position) {
        this.drawPosition(this.props)
      }
    }
  }

  render(): React.Node {
    return (
      <div className="mapPicker">
        <form
          className="mapPicker__header"
          // $FlowIgnore
          onSubmit={this.onSubmit}
        >
          <div className="input-group">
            <input
              type="search"
              // $FlowIgnore
              onChange={this.onQueryChanged}
              placeholder="Search an address, a place..."
              className="form-control"
              disabled={this.state.loading}
            />
            <span className="input-group-btn">
              <Button type="submit" kind="primary" disabled={this.state.loading}>
                <Icon icon={this.state.loading ? 'running-animated' : 'search'} size={10} />
              </Button>
            </span>
          </div>
        </form>
        <div className="mapPicker__map">
          <div className="mapContent" id={this.mapId} />
        </div>
        <div className="mapPicker__controls">
          <div className="mpc__item mpc__item--label">
            <i className="fa fa-country fa-1-5x" />
            {!this.state.text && (
              <span className="review__undef review__undef--inline">Pick a point on the map</span>
            )}
            {this.state.text && <span>{this.state.text}&nbsp;+</span>}
          </div>
          <div className="mpc__item mpc__item--radius">
            <input
              type="number"
              min="50"
              max="1000000"
              value={this.props.radius}
              required
              // $FlowIgnore
              onChange={this.onRadiusChanged}
              className="form-control form-control--sm v"
              placeholder="distance"
            />
          </div>
          <div className="mpc__item mpc__item--around">meters around, in the last 12 hours</div>
        </div>
      </div>
    )
  }
}
