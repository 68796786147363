// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Button, PermissionButton } from 'components/common/button'
import { Code } from 'components/common/code'
import { EmptyField } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { TableRow, TableCell, TableCellActions } from 'components/common/table'
import { Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { randomSize } from 'com.batch.common/utils'

import { type CappingCategoryRecord } from 'com.batch.redux/_records'
import { saveCappingCategory } from 'com.batch.redux/app.action'

type LabelEditorProps = {
  category: CappingCategoryRecord,
  isEditing?: boolean,
  editLine?: () => any,
  editClose?: () => any,
  warnAndDelete?: () => any,
  editCode?: (() => any) => any,
  allowCodeEdition?: boolean,
  loadingLabels: boolean,
  ...
}

const formattedCode = (code: string): string => code.toUpperCase().replace(/[^A-Z0-9]/g, '')

export const LabelEditor = ({
  category,
  isEditing,
  editLine,
  editClose,
  warnAndDelete,
  allowCodeEdition,
  editCode,
  loadingLabels,
}: LabelEditorProps): React.Node => {
  const [localCategory, updateLocalCategory] = React.useState<CappingCategoryRecord>(category)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const [loading, setLoading] = React.useState<boolean>(false)
  const dirty = category.name !== localCategory.name || category.code !== localCategory.code
  const randomSizeMemo = React.useMemo(() => randomSize(38, 60), [])

  const inputCodeRef = React.useRef<?HTMLElement>(null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    updateLocalCategory(category)
    setErrors(Immutable.Map())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const saveThenFixState = () => {
    dispatch(saveCappingCategory(localCategory, 'label')).then(res => {
      setLoading(false)
      if (!res.error) {
        !!editClose && editClose()
      }
    })
  }

  const handleSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    // $FlowExpectedError dunno the correct type
    e.target.reset()

    let err = []

    setLoading(true)

    if (localCategory.name.length < 2)
      err.push(['createName', 'Label name must be at least 2 chars.'])
    if (localCategory.code.length < 2) err.push(['createCode', 'Code must be at least 2 chars.'])

    if (Immutable.Map(err).size === 0) {
      setErrors(Immutable.Map())
      dirty ? saveThenFixState() : !!editClose && editClose()
    } else {
      setLoading(false)
      setErrors(Immutable.Map(err))
    }
  }
  const isAllowedToChangeLabels = useIsCurrentUserAllowedTo(['app', 'labels:write'])
  return (
    <TableRow
      as={isEditing ? 'form' : 'div'}
      isEditing={!!isEditing && isEditing}
      onSubmit={handleSubmit}
    >
      {isEditing ? (
        <TableCell kind="input">
          <Input
            autoFocus
            value={localCategory.name}
            onChange={evt => updateLocalCategory(localCategory.set('name', evt.target.value))}
            invalid={errors.has('createName')}
            invalidMessage={errors.get('createName', '')}
          />
        </TableCell>
      ) : (
        <TableCell>
          <Ellipsis title={category.name}>{category.name}</Ellipsis>
        </TableCell>
      )}

      <TableCell>
        {loadingLabels ? (
          <EmptyField _width={randomSizeMemo} _height={14} forceLoading />
        ) : category.count === 0 ? (
          <div style={{ color: colors.textDisabled }}>—</div>
        ) : (
          `${category.count} campaign${category.count > 1 ? 's' : ''}`
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <Input
            ref={inputCodeRef}
            value={localCategory.code}
            onFocus={() => {
              if (!allowCodeEdition && localCategory.id !== 'new') {
                !!editCode && editCode(() => !!inputCodeRef.current && inputCodeRef.current.focus())
              }
            }}
            onChange={evt =>
              updateLocalCategory(localCategory.set('code', formattedCode(evt.target.value)))
            }
            invalid={errors.has('createCode')}
            invalidMessage={errors.get('createCode') ? errors.get('createCode') : ''}
          />
        ) : (
          <Code size="small">{category.code}</Code>
        )}
      </TableCell>
      <TableCellActions>
        {isEditing ? (
          <React.Fragment>
            <Button disabled={!dirty && !loading} isLoading={loading} style={{ width: 50 }}>
              Save
            </Button>
            <Button
              type="button"
              onClick={() => {
                updateLocalCategory(category)
                setErrors(Immutable.Map())
                !!editClose && editClose()
              }}
            >
              <Icon icon="close" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PermissionButton
              type="button"
              intent="neutral"
              onClick={editLine}
              isAllowed={isAllowedToChangeLabels}
            >
              <Icon icon="edit" size={13} />
            </PermissionButton>
            <PermissionButton
              type="button"
              kind="inline"
              onClick={warnAndDelete}
              isAllowed={isAllowedToChangeLabels}
            >
              <Icon icon="delete" />
            </PermissionButton>
          </React.Fragment>
        )}
      </TableCellActions>
    </TableRow>
  )
}
