// @flow

import * as React from 'react'

import { AppWrapper } from 'components/common/app-wrapper'

import { AuthRouter } from './router'

export const App = (): React.Node => {
  return (
    <AppWrapper sidebarKind="none" epicMode="none">
      <AuthRouter />
    </AppWrapper>
  )
}
