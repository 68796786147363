// @flow

import * as React from 'react'

import { AppWrapper } from 'components/common/app-wrapper'

import { AccountRouter } from './router'

export const App = (): React.Node => {
  return (
    <AppWrapper sidebarKind="account" epicMode="none">
      <AccountRouter />
    </AppWrapper>
  )
}
