// @flow

import * as React from 'react'

export const TimelineIcon = (): React.Node => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill="#0968AC" />
      <path
        d="M3.70929 9.24004L9.92587 2.57942C9.97316 2.52876 10.0393 2.5 10.1086 2.5H10.191C10.5627 2.5 10.8044 2.89116 10.6382 3.22361L8.82761 6.84477C8.67014 7.15972 8.87888 7.53444 9.22956 7.56632L13.0739 7.91581C13.4708 7.95189 13.668 8.41496 13.4191 8.72611L8.07506 15.4062C8.02762 15.4655 7.95579 15.5 7.87984 15.5H7.80902C7.43733 15.5 7.19558 15.1088 7.3618 14.7764L9.16745 11.1651C9.32625 10.8475 9.11263 10.4702 8.75858 10.443L4.03647 10.0797C3.61859 10.0476 3.42332 9.54644 3.70929 9.24004Z"
        fill="white"
      />
    </svg>
  )
}

export const APIIcon = (): React.Node => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: 6 }}
    >
      <rect width="25" height="18" rx="5" fill="#C9C9C9" />
      <path
        d="M3.38135 13L6.13135 5.24951H7.69434L10.4443 13H8.99414L8.34424 10.9966H5.47607L4.83154 13H3.38135ZM6.86719 6.68896L5.8252 9.92773H8.00049L6.9585 6.68896H6.86719ZM11.7764 13V5.24951H14.8701C16.4438 5.24951 17.5073 6.28613 17.5073 7.83838V7.84912C17.5073 9.396 16.4438 10.438 14.8701 10.438H13.1621V13H11.7764ZM14.5317 6.38281H13.1621V9.3208H14.5317C15.5254 9.3208 16.1055 8.78369 16.1055 7.85449V7.84375C16.1055 6.91455 15.5254 6.38281 14.5317 6.38281ZM19.0381 13V5.24951H20.4238V13H19.0381Z"
        fill="white"
      />
    </svg>
  )
}
