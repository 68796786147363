// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Tooltip } from 'components/common/tooltip'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { capitalize } from 'com.batch.common/utils'

import { getConfirmWordings } from './confirm-wording'

import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { changeCampaignState } from 'com.batch/orchestration-list/usecases/change-campaign-state'

type OrchestrationMenuChangeStateProps = {
  partialOrchestration: PartialOrchestrationRecord,
  closeDropdown: () => void,
}

export const OrchestrationMenuChangeState = ({
  partialOrchestration,
  closeDropdown,
}: OrchestrationMenuChangeStateProps): React.Node => {
  const dispatch = useDispatch()
  const start = partialOrchestration.oneTime?.sendDate ?? partialOrchestration.recurring?.start
  const wordings = React.useMemo(() => {
    return getConfirmWordings({ partialOrchestration })
  }, [partialOrchestration])

  const campaignWord = React.useMemo(() => {
    return !partialOrchestration.oneTime ? 'automation' : 'campaign'
  }, [partialOrchestration.oneTime])

  const stateRunWord = React.useMemo(
    () => (!partialOrchestration.oneTime ? 'launch' : 'run'),
    [partialOrchestration.oneTime]
  )

  const stopConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({
      ...wordings.stop,
      sensitive: true,
    })
      .then(() => {
        dispatch(changeCampaignState(partialOrchestration.token, 'STOPPED')).catch(() => {})
      })
      .catch(() => {})
  }, [closeDropdown, wordings.stop, dispatch, partialOrchestration.token])

  const runConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({ ...wordings.run })
      .then(() => {
        dispatch(changeCampaignState(partialOrchestration.token, 'RUNNING'))
      })
      .catch(() => {})
  }, [closeDropdown, wordings.run, dispatch, partialOrchestration.token])
  return (
    <React.Fragment>
      {partialOrchestration.state === 'RUNNING' && (
        <Button onClick={stopConfirmModal}>Stop {campaignWord}</Button>
      )}
      {(partialOrchestration.state === 'STOPPED' || partialOrchestration.state === 'DRAFT') && (
        <Tooltip
          placement="left"
          tooltip={`The ${campaignWord} is incomplete`}
          isTooltipEmpty={!partialOrchestration.incomplete}
        >
          <div>
            <Button
              onClick={runConfirmModal}
              disabled={Boolean(
                partialOrchestration.incomplete ||
                  (partialOrchestration.state === 'DRAFT' && start && start.isBefore(dayjs.utc()))
              )}
            >
              {capitalize(stateRunWord)} {campaignWord}
            </Button>
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  )
}
