// ────────────────────────────────────────────────────────────────────────────────
// SUBSCRIBES TO ACTIONS, AND TRIGGERS NEW ACTIONS
// ────────────────────────────────────────────────────────────────────────────────
import { ofType } from 'redux-observable'
import { map, filter } from 'rxjs/operators'

import { FETCH_CAMPAIGNS_SUCCESS } from 'com.batch.redux/action-name'
import { fetchCampaignsData } from 'com.batch.redux/dataCampaign'
// ────────────────────────────────────────────────────────────────────────────────
// triggers a data API call for each campaign with no data loaded / loading
export const fetchCampaignsStatsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(FETCH_CAMPAIGNS_SUCCESS),
    filter(action => {
      return action.payload.entities.length > 0
    }),
    map(action => {
      const st = state$.value
      return fetchCampaignsData({
        app: st.app.current,
        tokens: action.payload.entities
          .map(c => c.token)
          .filter(token => !st.dataCampaign.campaign.has(token)),
      })
    })
  )
}
