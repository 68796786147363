// @flow
import Immutable from 'immutable'

import {
  type SmsCountriesConfiguration,
  type SmsProviders,
} from 'com.batch/shared/infra/metadata-service/metadata-service.api'
import {
  SspConfigurationFactory,
  type SspConfigurationRecord,
  SspCountryConfigurationFactory,
  SspProviderConfigurationFactory,
  SspStaticKeyFactory,
  TransmissionTypesConfigurationFactory,
} from 'com.batch/sms/infra/models/ssp-configuration.record'

export const parseSspConfiguration = (smsProviders: SmsProviders): SspConfigurationRecord => {
  const senders = new Immutable.List().push(...smsProviders.alphanumericSenderIDs)
  const maxParts = smsProviders.maxParts

  const smsCountriesConfiguration = Immutable.Map(
    Object.entries(smsProviders.smsCountriesConfiguration).map(
      // $FlowIgnore - Flow doesn't understand Object.entries (or maybe im just dumb lmk)
      ([countryCode, config]: [string, SmsCountriesConfiguration]) => [
        countryCode,
        SspCountryConfigurationFactory({
          transmissionTypesConfiguration: TransmissionTypesConfigurationFactory({
            marketing: new Immutable.List().push(
              ...config.transmissionTypesConfiguration.marketing.providerConfigurations.map(
                providerConfig =>
                  SspProviderConfigurationFactory({
                    provider: providerConfig.provider,
                    subaccountID: providerConfig.subaccountID,
                    staticKey: SspStaticKeyFactory({
                      key: providerConfig.staticKey.key,
                      httpHeaderName: providerConfig.staticKey.httpHeaderName,
                    }),
                  })
              )
            ),
            transactional: new Immutable.List().push(
              ...config.transmissionTypesConfiguration.transactional.providerConfigurations.map(
                providerConfig =>
                  SspProviderConfigurationFactory({
                    provider: providerConfig.provider,
                    subaccountID: providerConfig.subaccountID,
                    staticKey: SspStaticKeyFactory({
                      key: providerConfig.staticKey.key,
                      httpHeaderName: providerConfig.staticKey.httpHeaderName,
                    }),
                  })
              )
            ),
          }),
        }),
      ]
    )
  )

  return SspConfigurationFactory({
    senders,
    maxParts,
    smsCountriesConfiguration,
  })
}
