// @flow

import { scaleBand } from 'd3-scale'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { TimelineItem } from './timeline-item'
import { SvgTimeline } from './timeline.styles'

import { type Label } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  hideDisabledLabel?: boolean,
  labels: Array<Label>,
  width: number,
  xScale: typeof scaleBand,
  height?: number,
  hoveredBar?: string | null,
  setHoveredBar?: (e: string | null) => void,
  numberLabels?: number,
  ...
}

export const Timeline = ({
  numberLabels = 5,
  width,
  height = 36,
  xScale,
  labels,
  hoveredBar,
  setHoveredBar,
}: Props): React.Node => {
  const ref = React.useRef<any>(null)
  const [initialLabels, setInitialLabels] = React.useState([])
  const [firstLabelWidth, setFirstLabelWidth] = React.useState(0)
  const [lastLabelWidth, setLastLabelWidth] = React.useState(0)

  const { isLoading } = useTheme()

  const interval = React.useMemo(
    () => Math.round((width - firstLabelWidth - lastLabelWidth) / (numberLabels - 1)),
    [width, firstLabelWidth, lastLabelWidth, numberLabels]
  )

  const bandWidth = React.useMemo(() => width / labels.length, [labels, width])

  const displayedLabels = React.useMemo(() => {
    const result = []
    let nextOne = firstLabelWidth + interval

    labels.forEach((l, i) => {
      const bandStartPst = bandWidth * i
      const bandEndPst = bandStartPst + bandWidth

      if (bandEndPst > nextOne && bandEndPst < width - lastLabelWidth) {
        result.push(l)
        nextOne = Math.round(bandEndPst + interval)
      }
    })

    return result
  }, [labels, interval, firstLabelWidth, bandWidth, lastLabelWidth, width])

  const fetchFirstLabelWidth = React.useCallback(width => setFirstLabelWidth(Math.round(width)), [])
  const fetchLastLabelWidth = React.useCallback(width => setLastLabelWidth(Math.round(width)), [])

  const isFirst = React.useCallback(
    (label: string) => initialLabels.findIndex(f => f.label === label) === 0,
    [initialLabels]
  )
  const isLast = React.useCallback(
    (label: string) => initialLabels.findIndex(f => f.label === label) === initialLabels.length - 1,
    [initialLabels]
  )

  const onBarHover = React.useCallback(
    (label: string | null) => () => setHoveredBar && setHoveredBar(label),
    [setHoveredBar]
  )

  React.useEffect(() => {
    setInitialLabels([labels[0], labels[labels.length - 1]])
  }, [labels])

  return (
    <SvgTimeline width={width} height={36}>
      <defs>
        <linearGradient id="timeline-item-gradient-start">
          <stop className="stop1" offset="0%" />
          <stop className="stop2" offset="30%" />
          <stop className="stop3" offset="70%" />
          <stop className="stop4" offset="100%" />
        </linearGradient>
      </defs>

      <g ref={ref}>
        {[...initialLabels, ...displayedLabels].map((label, i) => (
          <TimelineItem
            key={i}
            isFirst={isFirst(label.label)}
            isLast={isLast(label.label)}
            timelineHeight={height}
            xScale={xScale}
            label={label.label}
            fetchFirstLabelWidth={i === 0 ? fetchFirstLabelWidth : null}
            fetchLastLabelWidth={i === displayedLabels.length - 1 ? fetchLastLabelWidth : null}
            isLoading={isLoading}
          />
        ))}
      </g>

      {hoveredBar && !isLoading && (
        <TimelineItem
          label={hoveredBar}
          isHoveredLabel={true}
          isFirst={isFirst(hoveredBar)}
          isLast={isLast(hoveredBar)}
          xScale={xScale}
          timelineHeight={height}
        />
      )}

      <g>
        {labels
          .filter(f => !f.isDisabled)
          .map((label, i) => (
            <rect
              key={i}
              x={xScale(label.label)}
              y={0}
              width={xScale.bandwidth()}
              height={height}
              fill="transparent"
              onMouseEnter={onBarHover(label.label)}
              onMouseLeave={onBarHover(null)}
            />
          ))}
      </g>
    </SvgTimeline>
  )
}
