// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'

type props = { isActive: boolean, ... }
export const LineButtonWrapper: StyledComponent<props, { ... }, *> = styled.div`
  width: 24px;
  height: 24px;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Button} {
    display: ${(p: props) => (p.isActive ? 'block' : 'none')};
  }
  .styled-dot {
    display: ${(p: props) => (p.isActive ? 'none' : 'block')};
    margin-top: 4px;
    margin-bottom: 1px;
  }
  &:hover {
    ${Button} {
      display: block;
    }
    .styled-dot {
      display: none;
    }
  }
`
