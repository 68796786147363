// @flow

// ====================== LIB
import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { type LoginMethod } from 'components/authentication/login/login'

import { generateUrl } from 'com.batch.common/router'

export const getSSOUrl = (email: string, redirectTo: string): Promise<string> => {
  return request
    .post(generateUrl('api_sso_saml_redirect'))
    .send({ email, redirectTo })
    .then(
      ({ body }) => _get(body, 'url'),
      ({ body }) => {
        return Promise.reject(body.errors)
      }
    )
}

export const getLoginMethod = (email: string): Promise<LoginMethod> => {
  return request
    .post(generateUrl('api_login_method'))
    .send({ email })
    .then(
      ({ body }) => _get(body, 'method', 'classic'),
      ({ body }) => {
        return Promise.reject(body.errors)
      }
    )
}

export const login = ({
  email,
  password,
  rememberMe,
}: {
  email: string,
  password: string,
  rememberMe: boolean,
  ...
}): Promise<boolean> => {
  return request
    .post(generateUrl('api_login'))
    .set('X-RateLimit-Id', email)
    .send({ email, password, _remember_me: rememberMe })
    .then(
      ({ body }) => _get(body, 'mustConfirmTwoFactorAuth', false),
      ({ body, status }) => {
        if (status === 401) {
          return Promise.reject([
            {
              message: "This email/password combination doesn't match any user in our database.",
              field: 'password',
            },
          ])
        } else {
          return Promise.reject(body.errors)
        }
      }
    )
}
export const check2FA = ({
  email,
  code,
  remember,
}: {
  email: string,
  code: string,
  remember: boolean,
  ...
}): Promise<any> => {
  return request
    .post(generateUrl('api_login_2fa_confirm'))
    .set('X-RateLimit-Id', `${email}-2fa`)
    .send({ code, remember })
    .then(
      ({ body }) => body,
      ({ body }) => Promise.reject(body.errors)
    )
}
export const recover2FA = ({ code }: { code: string, ... }): Promise<any> => {
  return request
    .post(generateUrl('api_login_2fa_recover'))
    .send({ code })
    .then(
      ({ body }) => body,
      ({ body }) => Promise.reject(body.errors)
    )
}
