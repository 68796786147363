// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type ChangeCampaignStateAction = ReduxAction<'CHANGE_CAMPAIGN_STATE', string>
export type ChangeCampaignStateSuccessAction = ReduxAction<
  'CHANGE_CAMPAIGN_STATE_SUCCESS',
  { token: string, state: 'STOPPED' | 'RUNNING', ... },
>
export type ChangeCampaignStateFailureAction = ReduxAction<
  'CHANGE_CAMPAIGN_STATE_FAILURE',
  { error: string, token: string, ... },
>

export type ChangeCampaignStateActions =
  | ChangeCampaignStateAction
  | ChangeCampaignStateSuccessAction
  | ChangeCampaignStateFailureAction

export const changeCampaignState = (
  token: string,
  state: 'STOPPED' | 'RUNNING'
): DispatchExtraBoundFn<Promise<{ token: string, state: campaignStateType, ... }>> => {
  return (dispatch, getState, { orchestrationService }) => {
    const reduxState = getState()
    const project = currentProjectSelector(reduxState)

    return promiseActionCreator({
      dispatch,
      promise: orchestrationService
        .changeState({ project, state, token })
        .then(() => ({
          token,
          state,
        }))
        .catch(error => {
          throw { token, error }
        }),
      payload: token,
      actionName: 'CHANGE_CAMPAIGN_STATE',
    })
  }
}
