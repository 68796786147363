// @flow
import * as React from 'react'
import { type Node as ReactNode } from 'react'
import { createPortal } from 'react-dom'

type DumbPortalProps = {
  children: ReactNode,
  ...
}

class DumbPortal extends React.PureComponent<DumbPortalProps> {
  constructor(props: DumbPortalProps) {
    super(props)
  }
  render(): React.Node {
    const dest = document.getElementById('modal-root')
    if (dest !== null) {
      return createPortal(this.props.children, dest)
    } else {
      return null
    }
  }
}

export default DumbPortal
