// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DropdownMenu, Button, ButtonNavLink, useDropdown } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import {
  generateOrchestrationListUrl,
  generateOrchestrationUrl,
} from 'com.batch.common/router/url-generator-helper'

import { getConfirmWordings, type ProjectMode, type ProjectPartialMode } from './confirm-wording'
import { OrchestrationMenuChangeState } from './orchestration-menu-changestate'

import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { deleteCampaign } from 'com.batch/orchestration-list/usecases/delete-campaign'

type OrchestrationMenuProps = {
  schedulingType: schedulingType,
  openReplication: () => void,
  data: ProjectMode | ProjectPartialMode,
  userHasWritePermission: boolean,
}
/*
  menu used in both campaign-list and campaign form pages
    on campaign list, expect a partialCampaign, and will show change state buttons
    on form header, expect an orchestrationStateRecord, will only offer replicate & delete, and will redirect on delete
*/
export const OrchestrationMenu = ({
  data,
  schedulingType,
  openReplication,
  userHasWritePermission,
}: OrchestrationMenuProps): React.Node => {
  const dispatch = useDispatch()
  const project = useSelector(currentProjectSelector)
  const wordings = React.useMemo(() => {
    return getConfirmWordings(data)
  }, [data])
  const isLoading = React.useMemo(() => {
    return data.partialOrchestration?.loadingState === 'LOADING'
  }, [data.partialOrchestration?.loadingState])

  const token = React.useMemo(() => {
    if (data.partialOrchestration) {
      return data.partialOrchestration.token
    } else if (data.orchestration) {
      return data.orchestration.id
    }
    return 'trigger'
  }, [data.partialOrchestration, data.orchestration])

  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({
    forcedWidth: 155,
    placement: data.partialOrchestration ? 'left-start' : 'bottom-end',
    offset: [data.partialOrchestration ? 0 : 60, 4],
  })

  const deleteConfirmModal = React.useCallback(() => {
    closeDropdown()
    confirm({
      ...wordings.archive,
      sensitive: true,
    })
      .then(() => {
        dispatch(deleteCampaign(token))
          .then(() => {
            if (data.orchestration) {
              const url = generateOrchestrationListUrl({
                projectId: project.id,
                channel: data.orchestration.channels.first() ?? 'email',
                companyId: project.companyId,
                scheduling: schedulingType,
              })
              window.location.href = url
            }
          })
          .catch(() => {})
      })
      .catch(() => {})
    // () => {}
  }, [
    closeDropdown,
    wordings.archive,
    dispatch,
    token,
    data.orchestration,
    project.id,
    project.companyId,
    schedulingType,
  ])
  const openReplicationAndCloseDropdown = React.useCallback(() => {
    openReplication()
    closeDropdown()
  }, [openReplication, closeDropdown])

  const orchestrationAnalyticsUrl = React.useMemo(
    () =>
      data.partialOrchestration && data.partialOrchestration.state !== 'DRAFT'
        ? generateOrchestrationUrl({
            channel: data.partialOrchestration.channels.first() ?? 'email',
            page: 'analytics',
            sendType: data.partialOrchestration.sendType,
            companyId: project.companyId,
            projectId: project.id,
            token: data.partialOrchestration.token,
          })
        : null,
    [data.partialOrchestration, project]
  )

  return (
    <React.Fragment>
      <Tooltip tooltip="More actions">
        <Button
          {...triggerProps}
          kind={data.partialOrchestration ? 'inline' : 'secondary'}
          style={
            data.partialOrchestration ? { height: 36, width: 36 } : { marginTop: 0, width: 35 }
          }
        >
          <Icon icon={isLoading ? 'spinner' : 'options'} />
        </Button>
      </Tooltip>
      <DropdownMenu {...dropdownProps}>
        {orchestrationAnalyticsUrl && (
          <ButtonNavLink to={orchestrationAnalyticsUrl}>Go to analytics</ButtonNavLink>
        )}
        {/* Change state option is offered only on listing, with partial object*/}
        {data.partialOrchestration && userHasWritePermission && (
          <OrchestrationMenuChangeState
            partialOrchestration={data.partialOrchestration}
            closeDropdown={closeDropdown}
          />
        )}

        <Button onClick={openReplicationAndCloseDropdown} disabled={!userHasWritePermission}>
          Replicate
        </Button>

        <Button intent="danger" onClick={deleteConfirmModal} disabled={!userHasWritePermission}>
          Delete
        </Button>
      </DropdownMenu>
    </React.Fragment>
  )
}
