// @flow
import { createSelector } from 'reselect'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type SubDistributionRecord,
  type SubDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'

type extract<T> = State => T

const smsSubDistributionSelector = (state: State): SubDistributionStateRecord => {
  return state.profilebase.smsSubDistribution
}

export const smsSubDistribLoadingStateSelector: extract<fetchingState> = createSelector(
  smsSubDistributionSelector,
  smsSubDistribution => smsSubDistribution.loadingState
)

export const smsSubDistribDataSelector: extract<SubDistributionRecord> = createSelector(
  smsSubDistributionSelector,
  smsSubDistribution => smsSubDistribution.distribution
)
