// @flow
import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { Fliper } from 'components/common/fliper'

import { RegisterWizardContainer, RegisterProgress } from './register.styles'

type RegWizardProps = {
  step: number,
  total: number,
  noProgress?: boolean,
  animate?: boolean,
  children: ReactNode,
  ...
}

const RegWizardRaw = ({
  step,
  total,
  noProgress = false,
  children,
  animate = false,
}: RegWizardProps): React.Node => {
  const [localStep, setLocalStep] = React.useState(animate ? step - 1 : step)
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLocalStep(step)
    }, 50)
    return () => clearTimeout(timer)
  }, [step])

  return (
    <RegisterWizardContainer currentStep={localStep} total={total} noProgress={noProgress}>
      {children}
      {!noProgress && (
        <RegisterProgress style={{ opacity: step <= total ? 1 : 0 }}>
          <Fliper>{localStep}</Fliper>
          <hr />
          <Fliper>{total}</Fliper>
        </RegisterProgress>
      )}
    </RegisterWizardContainer>
  )
}

export const RegWizard: React.AbstractComponent<RegWizardProps> =
  React.memo<RegWizardProps>(RegWizardRaw)
