// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'
import { type attachmentKind } from 'com.batch.redux/content.records'

import { type HtmlEditorConfig } from 'com.batch/email/models/html-editor-config'

export type SmsTemplatesProps = {
  smsMessage: string,
}
export const SmsTemplatesFactory: RecordFactory<SmsTemplatesProps> = Immutable.Record({
  smsMessage: '',
})
export type SmsTemplatesRecord = RecordOf<SmsTemplatesProps>
export type SmsContentProps = {
  smsMessage: string,
  templates: SmsTemplatesRecord,
}

export const SmsContentFactory: RecordFactory<SmsContentProps> = Immutable.Record(
  ({
    smsMessage: '',
    templates: SmsTemplatesFactory(),
  }: SmsContentProps)
)

export type SmsContentRecord = RecordOf<SmsContentProps>

export type EmailTemplatesProps = {
  html: ?string,
  subject: ?string,
}
export const EmailTemplatesFactory: RecordFactory<EmailTemplatesProps> = Immutable.Record({
  html: null,
  subject: null,
})
export type EmailTemplatesRecord = RecordOf<EmailTemplatesProps>

export type EmailContentProps = {
  replyTo: ?string,
  fromEmail: ?string,
  name: ?string,
  subject: ?string,
  html: ?string,
  templates: EmailTemplatesRecord,
  senderIdentityId?: number,
  isEmailUploading: boolean,
  loadingState: LoadingState,
  htmlEditorConfig: ?HtmlEditorConfig,
  isPristine: boolean,
}
export const EmailContentFactory: RecordFactory<EmailContentProps> = Immutable.Record(
  ({
    fromEmail: null,
    replyTo: null,
    name: null,
    subject: null,
    html: null,
    templates: EmailTemplatesFactory(),
    senderIdentityId: undefined,
    isEmailUploading: false,
    loadingState: 'INIT',
    htmlEditorConfig: null,
    isPristine: true,
  }: EmailContentProps)
)
export type EmailContentRecord = RecordOf<EmailContentProps>

export const PUSH_PRIORITY = {
  PUSH_PRIORITY_UNSPECIFIED: '',
  PUSH_PRIORITY_NORMAL: 'Normal',
  PUSH_PRIORITY_HIGH: 'High',
}
export type PushPriority = $Keys<typeof PUSH_PRIORITY>

export const PUSH_NOTIF_TYPE = {
  PUSH_TYPE_UNSPECIFIED: '',
  PUSH_TYPE_ALERT: 'Alert',
  PUSH_TYPE_BACKGROUND: 'Background',
}
export type PushNotifType = $Keys<typeof PUSH_NOTIF_TYPE>

type PushContentTemplatesProps = {
  pushTitle: string,
  pushBody: string,
  pushPicture: string,
}
export const PushContentTemplatesFactory: RecordFactory<PushContentTemplatesProps> =
  Immutable.Record({
    pushTitle: '',
    pushBody: '',
    pushPicture: '',
  })
export type PushContentTemplatesRecord = RecordOf<PushContentTemplatesProps>

type PushAndroidContentTemplatesProps = {
  androidIcon: string,
}
export const PushAndroidContentTemplatesFactory: RecordFactory<PushAndroidContentTemplatesProps> =
  Immutable.Record({
    androidIcon: '',
  })
export type PushAndroidContentTemplatesRecord = RecordOf<PushAndroidContentTemplatesProps>

type PushMessageRaw = {
  pushTitle: string,
  pushBody: string,
  pushPicture: string,
  templates: PushContentTemplatesRecord,
  attachmentKind: attachmentKind,
  pushType: PushNotifType,
  deeplink: string,
  customPayload: string,
}
export type PushMessageRawRecord = RecordOf<PushMessageRaw>
export const PushMessageRawFactory: RecordFactory<PushMessageRaw> = Immutable.Record(
  ({
    pushTitle: '',
    pushBody: '',
    pushPicture: '',
    attachmentKind: 'image',
    templates: PushContentTemplatesFactory(),
    pushType: 'PUSH_TYPE_ALERT',
    deeplink: '',
    customPayload: '',
  }: PushMessageRaw)
)
type AndroidPushMessageRaw = {
  deeplink: string, // no deeplink = redirect to app
  androidIcon: string,
  collapseKey: string,
  customPayload: string,
  templates: PushAndroidContentTemplatesRecord,
}
export type AndroidPushMessageRawRecord = RecordOf<AndroidPushMessageRaw>
export const AndroidPushMessageRawFactory: RecordFactory<AndroidPushMessageRaw> = Immutable.Record(
  ({
    deeplink: '',
    androidIcon: '',
    collapseKey: '',
    customPayload: '',
    templates: PushAndroidContentTemplatesFactory(),
  }: AndroidPushMessageRaw)
)
type IosPushMessageRaw = {
  deeplink: string,
  video: string,
  audio: string,
  customPayload: string,
}
export type IosPushMessageRawRecord = RecordOf<IosPushMessageRaw>
export const IosPushMessageRawFactory: RecordFactory<IosPushMessageRaw> = Immutable.Record(
  ({
    deeplink: '',
    video: '',
    audio: '',
    customPayload: '',
  }: IosPushMessageRaw)
)
type WebPushMessageRaw = {
  deeplink?: string,
  icon?: string,
}
export type WebPushMessageRawRecord = RecordOf<WebPushMessageRaw>
export const WebPushMessageRawFactory: RecordFactory<WebPushMessageRaw> = Immutable.Record(
  ({
    deeplink: undefined,
    icon: undefined,
  }: WebPushMessageRaw)
)
type PushContent = {
  priority: PushPriority,
  ttl: AgeRecord,
  ttlEnabled: boolean,
  content: PushMessageRawRecord,
  androidContent: AndroidPushMessageRawRecord,
  iosContent: IosPushMessageRawRecord,
  webContent: WebPushMessageRawRecord,
}
export type PushContentRecord = RecordOf<PushContent>
export const PushContentFactory: RecordFactory<PushContent> = Immutable.Record(
  ({
    priority: 'PUSH_PRIORITY_HIGH',
    ttl: AgeFactory(),
    ttlEnabled: false,
    content: PushMessageRawFactory(),
    androidContent: AndroidPushMessageRawFactory(),
    iosContent: IosPushMessageRawFactory(),
    webContent: WebPushMessageRawFactory(),
  }: PushContent)
)
