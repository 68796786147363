// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf, type RecordFactory } from 'immutable'

import { generateTypedId } from 'com.batch.common/typed-id'

import { type pushCampaignSendType } from 'com.batch.redux/_records'

export type OneTimeProps = { sendDate: ?Dayjs }
export type OneTimeRecord = RecordOf<OneTimeProps>
export const OneTimeFactory: RecordFactory<OneTimeProps> = Immutable.Record(
  ({ sendDate: undefined }: OneTimeProps)
)
export type RecurrentProps = {
  startDate: ?Dayjs,
  endDate: ?Dayjs,
  recurrence: 'DAILY' | 'WEEKLY' | 'MONTHLY',
  frequency: number,
  capping: number,
  hasCapping: boolean,
}
export type RecurrentRecord = RecordOf<RecurrentProps>
export const RecurrentFactory: RecordFactory<RecurrentProps> = Immutable.Record(
  ({
    frequency: 1,
    recurrence: 'WEEKLY',
    startDate: undefined,
    endDate: undefined,
    hasCapping: false,
    capping: 1,
  }: RecurrentProps)
)
type OrchestrationCampaignProps = {
  sendType: pushCampaignSendType,
  oneTime: OneTimeRecord,
  recurrent: RecurrentRecord,
  messageTypedId: string,
  tzAware: boolean,
  channel?: ChannelUntilCleanup,
}

export type OrchestrationCampaignRecord = RecordOf<OrchestrationCampaignProps>
export const OrchestrationCampaignFactory: RecordFactory<OrchestrationCampaignProps> =
  Immutable.Record(
    ({
      sendType: 'trigger',
      tzAware: false,
      oneTime: OneTimeFactory(),
      recurrent: RecurrentFactory(),
      messageTypedId: generateTypedId('message'),
      channel: undefined,
    }: OrchestrationCampaignProps)
  )
