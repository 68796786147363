// @flow

import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { type PushContentRecord } from 'com.batch/message/models/message.records'
import { fetchTemplate } from 'com.batch/message/usecases/fetch-templates'

export type UpdatePushContentRecordAction = {
  type: 'UPDATE_PUSH_CONTENT_RECORD',
  payload: {
    messageId: string,
    lang: string,
    record: PushContentRecord,
  },
}
export const updatePushContentRecord = ({
  messageId,
  lang,
  record,
}: {
  messageId: string,
  lang: string,
  record: PushContentRecord,
}): DispatchBoundFn<UpdatePushContentRecordAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_PUSH_CONTENT_RECORD',
      payload: {
        messageId,
        lang,
        record,
      },
    })
  }
}

export type UpdatePushMessageContentAction = {
  type: 'UPDATE_PUSH_MESSAGE_CONTENT',
  payload: {
    messageId: string,
    lang: string,
    field: 'pushBody' | 'pushTitle' | 'pushPicture' | 'androidIcon',
    value: string,
  },
}

let debounces: { [string]: TimeoutID } = {}
export const updatePushMessageContent = ({
  messageId,
  lang,
  field,
  parent,
  value,
  isInstant = false,
}: {
  messageId: string,
  lang: string,
  field: 'pushBody' | 'pushTitle' | 'pushPicture' | 'androidIcon',
  parent: 'content' | 'androidContent',
  value: string,
  isInstant?: boolean,
}): DispatchBoundFn<void> => {
  const contentIdentifier = `${messageId}_${lang}_${field}`
  clearTimeout(debounces[contentIdentifier])

  return dispatch => {
    dispatch({
      type: 'UPDATE_PUSH_MESSAGE_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
      },
    })

    debounces[contentIdentifier] = setTimeout(
      () => {
        if (textUsesTemplating(value)) {
          dispatch(fetchTemplate({ messageId, field, parent, lang, value, type: 'TEMPLATE' }))
            .then(res => {
              const { template } = res[0]
              dispatch({
                type: 'UPDATE_PUSH_TEMPLATE',
                payload: {
                  messageId,
                  lang,
                  [(parent: string)]: {
                    [(field: string)]: template ?? value,
                  },
                },
              })
            })
            .catch(e => {
              console.error(
                'Error while fetching templates:\n',
                e.error?.errors?.[0]?.message ?? 'Unknown error'
              )
            })
        } else {
          dispatch({
            type: 'UPDATE_PUSH_TEMPLATE',
            payload: {
              messageId,
              lang,
              [(parent: string)]: {
                [(field: string)]: value,
              },
            },
          })
        }
      },
      isInstant ? 0 : 500
    )
  }
}
