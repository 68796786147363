// @flow
import { createSelector } from 'reselect'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type SubDistributionRecord,
  type SubDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'

type extract<T> = State => T

const emailSubDistributionSelector = (state: State): SubDistributionStateRecord => {
  return state.profilebase.emailSubDistribution
}

export const emailSubDistribLoadingStateSelector: extract<fetchingState> = createSelector(
  emailSubDistributionSelector,
  emailSubDistribution => emailSubDistribution.loadingState
)

export const emailSubDistribDataSelector: extract<SubDistributionRecord> = createSelector(
  emailSubDistributionSelector,
  emailSubDistribution => emailSubDistribution.distribution
)
